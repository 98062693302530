<!-- HISTORY:
   V230913.1: 1st release (copied from AsRDLs.vue V230912.1).
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">account_tree</v-icon>
            <span>Accounts</span>
         </h1>
         <div class="flex-grow-1"></div>
      </v-card-title>

      <v-card-text class="ml-2 px-5 pb-0">
         <v-form lazy-validation
            ref="mainForm"
            v-model="isMainFormValid"
         >
            <v-row>
               <v-col xs="12" sm="12" md="3" class="py-2">
                     <!-- prepend-inner-icon="schedule" -->
                  <v-select dense hide-selected persistent-hint
                     hint="* Find Accounts By"
                     :items="findByItems"
                     v-model="findBy"
                     @change="findByChanged"
                  ></v-select>
               </v-col>
               <v-col v-if="findBy === 'id' || findBy === 'name'"
                  xs="12" sm="12" md="3" class="pt-2"
               >
                  <v-text-field v-if="findBy === 'id'"
                     dense persistent-hint required hide-spin-buttons
                     ref="findById"
                     class="py-0"
                     hint="Account ID"
                     placeholder="specify an account id"
                     type="number"
                     :rules="[rules.required]"
                     v-model="findByDetail"
                     @keyup="findByDetailChanged"
                  ></v-text-field>
                  <v-text-field v-if="findBy === 'name'"
                     dense persistent-hint required
                     ref="findByName"
                     class="py-0"
                     hint="Account Name"
                     placeholder="specify an account name"
                     :rules="[rules.required]"
                     v-model="findByDetail"
                     @keyup="findByDetailChanged"
                  ></v-text-field>
               </v-col>
               <v-col xs="12" sm="12" md="2" class="py-5 mt-1">
                  <v-btn small
                     color="primary"
                     :disabled="!isCriteriaChanged"
                     @click="getListItems()"
                  >Get Report
                     <v-icon right dark>summarize</v-icon>
                  </v-btn>
               </v-col>
            </v-row>
         </v-form>
      </v-card-text>

      <v-card-text>
         <v-tabs
            class="elevation-2"
            background-color="grey lighten-2 accent-4"
            slider-color="black"
            v-model="tab"
         >
            <v-tab>List</v-tab>

            <v-tabs-items v-model="tab">
               <v-tab-item class="pt-5"><!-- List -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-text-field clearable
                           class="py-0"
                           append-icon="search"
                           label="Search"
                           :disabled="!listItems.length"
                           v-model="searchList"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header show-expand single-expand
                     class="elevation-1"
                     item-key="id"
                     :expanded.sync="expandedItems"
                     :footer-props="footerPropsList"
                     :headers="listHeaders"
                     :hide-default-footer="listItems.length <= 5"
                     :items="listItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsList"
                     :search="searchList"
                     @update:expanded="itemExpanded"
                  >
                     <template v-slot:[`item.creationdate`]="{ item }">
                        {{ formatDate(item.creationdate) }}
                     </template>
                     <template v-slot:expanded-item="{ item }">
                        <td>&nbsp;</td>
                        <td dense colspan="3" class="px-0 py-2" valign="top" v-if="item.details">
                           <ul>
                              <li>
                                 <span class="expanded-header">Status: </span>
                                 <span class="expanded-content">{{item.details.status}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Service Package: </span>
                                 <span class="expanded-content">{{item.details.servicepackage_id}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Client Type: </span>
                                 <span class="expanded-content">{{item.details.clienttype}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Focus Area: </span>
                                 <span class="expanded-content">{{item.details.focusarea_id}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">UI Access: </span>
                                 <span class="expanded-content">{{item.details.uiaccess}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Is TOF Enabled: </span>
                                 <span class="expanded-content">{{item.details.istofenabled}}</span>
                              </li>
                              <li v-if="item.details.tofuniquefield">
                                 <span class="expanded-header">TOF Unique Field: </span>
                                 <span class="expanded-content">{{item.details.tofuniquefield}}</span>
                              </li>
                           </ul>
                        </td>
                        <td dense colspan="2" class="px-0 py-2" valign="top" v-if="item.details">
                           <ul>
                              <li>
                                 <span class="expanded-header">Credential GUID: </span>
                                 <span class="expanded-content">{{item.details.credentialguid}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Logo URL: </span>
                                 <span class="expanded-content">{{item.details.logourl}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Custom Report Name: </span>
                                 <span class="expanded-content">{{item.details.customreportname}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Data Maintenance: </span>
                                 <span class="expanded-content">{{item.details.datamaintenance}}</span>
                              </li>
                           </ul>
                        </td>
                     </template>
                  </v-data-table>
               </v-tab-item>
            </v-tabs-items>
         </v-tabs>
      </v-card-text>
   </v-card>
</v-container>
</template>

<script>
import { APIService } from '../services/as-api-service.js';
import { format, parseISO } from "date-fns";
// import { sleep } from '../mixins/bt-mixin.js';

const NAME = "AsAccounts";

export default {
   name: NAME,

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         listHeaders: [
            { text: 'Name', value: 'name', align: 'left', sortable: true },
            { text: 'ID', value: 'id', align: 'left', sortable: true },
            { text: 'Parent Name', value: 'parent_name', align: 'left', sortable: true },
            { text: 'Parent ID', value: 'parent_id', align: 'left', sortable: true },
            { text: 'Creation Date', value: 'creationdate', align: 'left', sortable: true }
         ],
         findByItems: [
            { text: 'List Enabled', value: 'listEnabled' },
            { text: 'PURL Enabled', value: 'purlEnabled' },
            { text: 'ID', value: 'id' },
            { text: 'Name', value: 'name' }
         ],
         rules: {
            required: value => !!value || "Value is required!"
         },
         isMainFormValid: false,
         isCriteriaChanged: false,
         findBy: 'listEnabled',
         findByDetail: '',
         apiService: null,
         tab: null,
         loading: false,
         lastItemsPerPage: 10,
         searchList: '',
         footerPropsList: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         optionsList: {},
         listItems: [],
         expandedItems: []
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      }
   },

   watch: {
      token() {
         this.init();
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V230913.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         if (this.token) {
            this.apiService = new APIService(this.token, this.debug, this.isActualEndpoint, '/api/bi/v1/admins');
            setTimeout(() => {
               this.getListItems();
            }, 100);
         }
      },

      async getListItems() {
         // alert(`in getListItems(): status=${this.status}\ndateRange=${JSON.stringify(this.dateRange)}`);
         this.loading = true;
         this.listItems = [];
         this.expandedItems = [];
         let path;
         if (['listEnabled', 'purlEnabled'].includes(this.findBy))
            path = this.findBy;
         else if (this.findBy === 'id')
            path = this.findByDetail;
         else
            path = `name/${this.findByDetail.trim()}`;

         let result = await this.apiService.getListAccounts(path);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.listItems = result.data;
         }

         this.isCriteriaChanged = false;
         this.loading = false;
      },

      formatDate(date) {
         if (date) {
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            return formatteddate;
         }
      },

      async itemExpanded(items) {
         // _alert('in itemExpanded(): items=' + JSON.stringify(items));
         if (items.length === 0 || items[0].details) return;

         this.loading = true;
         // const date = addDate ? convertToISODate(new Date(), true) : null;
         let result = await this.apiService.getListAccount(items[0].id);
         if (result.logout)
            this.logout();
         else if (!result.message) {
            items[0].details = result.data;
            // alert(JSON.stringify(items))
         }
         this.loading = false;
      },

      findByChanged(val) {
         this.findByDetail = '';
         if (val === 'id')
            setTimeout(() => {
               this.$refs.findById.focus();
            }, 100);
         else if (val === 'name')
            setTimeout(() => {
               this.$refs.findByName.focus();
            }, 100);
         else
            this.getListItems();
      },

      findByDetailChanged() {
         this.isCriteriaChanged = this.$refs.mainForm.validate();
      }
   },

   created() {
      this.init();
   }

}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>