<template>
   <!-- HISTORY: no-version
      V230808.1: Added groupbyFieldHasEventData prop and passed it to bt-filter-definer.
      V230630.1: Added parentComponent prop and passed it to bt-filter-definer.
      V230124.1: Applied/Modified styles to display contents properly after Aref's upgrade.
      06/16/22(B0.7): Added and consumed initialFilter prop.
      06/02/22(B0.6): Added and consumed filterResult and debug props + Replaced apiService with btHelpers + Commented v-container +
      05/16/22(B0.5): Added and consumed groupbyId2Required and apiService props.
      08/17/21(B0.4): Added groupbyOperators and showGroupbyId2 props.
      06/21/21(B0.3): All props default & validations were removed.
      06/10/21(B0.2): Added and consumed activeTab, includedTabs, and stdFieldValues props.
      06/08/21(B0.1): 1st release.
   -->
   <div>
      <!-- v-model="showTooltip" -->
      <v-tooltip left>
         <template v-slot:activator="{ on }">
               <!-- v-on="on" -->
            <!-- <v-btn dark x-small
               :color="btnColor"
               :disabled="btnDisabled"
               @click="btnClicked"
            >{{btnTitle}}
               <v-icon dark>{{btnIconName}}</v-icon>
            </v-btn> -->
            <v-btn text icon
               v-on="on"
               @click="btnClicked"
            >
               <v-icon>{{btnIconName}}</v-icon>
            </v-btn>
         </template>
         <span>{{btnTooltip}}</span>
      </v-tooltip>               

      <v-dialog no-click-animation persistent scrollable
         max-width="1280px"
         v-model="dialog"
      >
         <v-card flat tile>
            <v-card-title>Filter Configuration</v-card-title>
            <v-card-text class="px-5 pb-0">
               <bt-filter-definer
                  :active-tab="activeTab"
                  :bt-helpers="btHelpers"
                  :debug="debug"
                  :fields="fields"
                  :filter-result="filterResult"
                  :groupby-field-has-event-data="groupbyFieldHasEventData"
                  :groupby-id2-required="groupbyId2Required"
                  :groupby-operators="groupbyOperators"
                  :included-tabs="includedTabs"
                  :initial-filter="initialFilter"
                  :is-admin="isAdmin"
                  :max="max"
                  :parent-component="parentComponent"
                  :preselected-fields="preselectedFields"
                  :should-init="shouldInit"
                  :show-cancel-btn="true"
                  :show-groupby-id2="showGroupbyId2"
                  :show-test-cases="showTestCases"
                  :std-field-values="stdFieldValues"
                  v-model="myFilter"
                  @filter-cancel="filterCancelled"
                  @filter-change="filterChanged"
               ></bt-filter-definer>
            </v-card-text>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import BtFilterDefiner from './BtFilterDefiner.vue';
const NAME = "BtFilterWrapperWithBtn";

function Alert(msg) {
   // alert(NAME + '\n' + msg);
}

export default {
   name: NAME,

   components: {
      BtFilterDefiner
   },

   props: {
      value: {
         type: Object
      },
      activeTab: {
         type: String
      },
      behFieldValues: {
         type: Object
      },
      behOperators: {
         type: Array
      },
      btHelpers: {
         type: Object
      },
      database: {
         type: String
      },
      debug: {
         type: Boolean
      },
      dense: {
         type: Boolean
      },
      fields: {
         type: Array
      },
      filterResult: {
         type: Array
      },
      groupbyFieldHasEventData: {
         type: Boolean
      },
      groupbyId2Required: {
         type: Boolean
      },
      groupbyOperators: {
         type: Array
      },
      includedTabs: {
         type: Array
      },
      initialFilter: {
         type: Object
      },
      isAdmin: {
         type: Boolean
      },
      max: {
         type: Number
      },
      //TODO: how to validate values with names
      //TODO: default value should be moved to the wrappers.
      operators: {
         type: Array
         // default: () => [
         //    { text: "Equals (=)", value: "=" },
         //    { text: "Contains", value: "contains" },
         //    { text: "Starts with", value: "starts" },
         //    { text: "Ends with", value: "ends" },
         //    { text: "Greater than (>)", value: ">" },
         //    { text: "Greater than or equal (>=)", value: ">=" },
         //    { text: "Less than (<)", value: "<" },
         //    { text: "Less than or equal (<=)", value: "<=" },
         //    { text: "In", value: "in" },
         //    { text: "Not in", value: "nin" },
         //    { text: "Regex", value: "regex" },
         // ],
         // validator: value => { return value && value.length > 0 && value.length <= 11 }
      },
      parentComponent: {
         type: String
      },
      preselectedFields: {
         type: Array
      },
      removeIconName: {
         type: String
      },
      removeIconColor: {
         type: String
      },
      //TODO: is required?
      required: {
         type: Boolean
      },
      showGroupbyId2: {
         type: Boolean
      },
      stdFieldValues: {
         type: Object
      },
      types: {
         type: Array
      },

      //for the wrapper
      btnColor: {
         type: String,
         default: "grey lighten-2"
      },
      btnDisabled: {
         type: Boolean,
         default: false
      },
      btnIconName: {
         type: String,
         default: "more_vert"
      },
      btnSize: {
         type: String,
         default: "small"
      },
      btnTitle: {
         type: String,
         default: ""
      },
      btnTooltip: {
         type: String,
         default: "click to set filters"
      },
      //for my testing purposes only
      showTestCases: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         dialog: false,
         shouldInit: false,
         myFilter: {},
         // firstLoad: true,
         startupFilter: ''
      }
   },

   computed: {},

   watch: {
      value: {
         immediate: true,
         handler(val) {
            Alert(`in v-model watch: value=${JSON.stringify(val)}`);
            this.myFilter = val;
         }
      },
      // shouldInit: {
      //    immediate: true,
      //    handler(val) {
      //       // alert('in BtFilterWrapperWithBtn.shouldInit(): value=' + val);
      //       if (val) {
      //          this.myFilter = this.value;
      //          alert('myFilter=' + JSON.stringify(this.myFilter))
      //       }
      //    }
      // },
   },

   methods: {
      btnClicked() {
         // alert('in btnClicked');
         this.shouldInit = true;
         this.dialog = true;
         this.myFilter = JSON.parse(JSON.stringify(this.value));
         // alert('value in btnClick:' + JSON.stringify(this.value));
         // alert('myFilter in btnClick:' + JSON.stringify(this.myFilter));
         this.startupFilter = JSON.stringify(this.value);
      },

      filterCancelled() {
         // alert('in ' + NAME + '.filterCancelled');
         this.dialog = false;
         setTimeout(() => {
            // alert('in setTimeout');
            // alert('value in filterCancelled:' + JSON.stringify(this.value));
            // alert('value in filterCancelled:' + JSON.stringify(this.myFilter));
            this.shouldInit = false;
            // this.firstLoad = false;
            // this.myFilter = JSON.parse(JSON.stringify(this.value));
         }, 100);

      },
      filterChanged(filter) {
         // alert(`in filterChanged(): filter=${JSON.stringify(filter)}`);
         this.myFilter = filter;
         this.$emit('filter-change', filter);
         this.$emit('input', filter);
         // // // if (this.firstLoad)
            // this.firstLoad = false;
         // // // else {
            this.dialog = false;
         // // // }
      }
   },

   created() {},
   mounted() {}
}
</script>

