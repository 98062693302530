<template>
<!-- TODO:
   - Consume btmixins.getExportFields(): 3/15/2023
   - Add values for httpRequestStatus and sftpStatus in filter.
   - NEW: Support pagination + Open filter and notifications for power admin.
   - NEW: Add validation for endpoints(the url) + add placeholder.
   - Move the v-form inside dialog v-card-text

   BUSINESS LOGICS:
   - An export can be created only when, at least, one completed import is available.
-->
<!-- HISTORY:
   V250117.1: Added event_id to exportFields.
   V240327.2: Updated canViewActionsTab to consider contact-action-view policy as well +
      Filtered actions based on their apps' hasExportAssociation property and the logged-in user.
   V240301.1: Consumed getExportsStats() - instead of getExportsCount() - and displayed them under the table.
   V240228.2: Modified filterChanged() again to consume getCollectionInfo() instead of bypassing getOffersCount() for the empty filter.
   V240227.1: Modified filterChanged() to bypass getOffersCount() for the empty filter.
   V240226.1: Modified filterChanged() to consume result.timeout of getOffersCount().
   V240222.1: Moved getFieldValues() in newExportClicked() to prevent the unnecessary call + Fixed the loading issue of dialogExport.
   V240201.1: Consumed getImportData() and modified getExportData() to add dateFormat where is applicable.
   V240126.1: Made Actions tab available to developers as well.
   V240103.1: Made Actions tab available to the power admin only.
   V231129.1: Changed the design to be tab based (similar to CsImports.vue) + Made sure that Actions can be set only if purl & _id fields are selected.
   V231115.1: [on Aref side] Removed consumerCode from actions and added actionName instead + Modified getExportActions() accordingly.
   V231113.1: Added actions to the new expoerts and displayed them in the expanded row.
   V230814.2: in getExportData(), Fixed a bug to bypass purlNumberStr as unknown fields are considered as eventData.
   V230814.1: in getExportData(), extended $project to resolve the PURLs case-sensitivity issue at login.
   V230630.2: Passed parentComponent prop to bt-filter-wrapper-with-btn to eliminate regex operators from behavioral fields.
   V230630.1: Added browser, device, ip, os and platform to the exportFields.
   V230627.2: Removed GROUPBY tab from bt-filter-wrapper-with-btn + Modified getExportData() to fix 2 bugs:
      Commented _id logic + Added non-event fields from the new $project to the existing $project as well.
   V230627.1: Modified getExportData() to add event data fields into filter.
   V230420.1: Considered shouldZip in the new fileName + Added isZipped to the table rows + Cleaned-up the code.
   V230418.1: Changed the file download method to set the export name as the original file name.
   V230202.1: Passed 'true' param in getFieldValues() to included the rejected imports as well.
   V230106.1: Enabled shouldZip switch.
   V221130.1: Replaced the password and pgpPublicKey values of sftp notifications with "***".
   V221128.1: Removed PGP Public Key field from UI as it was moved into the BtNotifications component.
   V221118.1: Passed show-offset prop always true as we found that scheduler needs dynamic date range (fixed w/Aref).
   V221117.1: Consumed showOffset prop in the BtCronSchedulerChild component.
   V221110.1: Applied/Modified styles to display contents properly after Aref's upgrade + Fixed the bug with expanded-item that didn't wrap the lines +
      Changed debug switch permission from pa to au + Changed the approach of parsing JWT token.
   V221021.2: Changed item-key from 'name' to '_id'.
   V221021.1: Added creator to the table's headers.
   V221011.1: Changed importId's text property of exportFields from 'Import' to 'importId'.
   V221007.1: Uncommented exportFields variable in data.
   08/19/22(B1.13): Added debug switch for power admin + Changed the error message + Showed field definitions in the expanded row.
   08/19/22(B1.12): Consumed BtExportFieldDefinitions component and removed exportFields property +
      Added _id to exportFields and changed getExportData() logic based on new field definitions.
   08/11/22(B1.11): In init(), replaced events name property of exportFields with their values (i.e., App Code to app_code) +
      Removed text-capitalize class from expanded-item template.
   08/11/22(B1.10): Consumed bt-helpers prop in the bt-filter-wrapper-with-btn component.
   05/02/22(B1.9): Implemented logout feature + Applied Aref changes (disabled shouldZip & publicKey + Removed .gz).
   04/08/22(B1.8): Added event fields to the Bt-Autocomplete + Added $unwind to the exportData if an event field is selected.
   04/07/22(B1.7): Eliminated rowNumber as well (Aref fixed it on his side).
   04/06/22(B1.6): Eliminated purlNumber, basePurl, dedupHash, and __v as well if all fields were selected.
   04/06/22(B1.5): Moved the converting formData to exportData to a function to be used in scheduleClicked() as well + Eliminated events only if all fields were selected.
   04/06/22(B1.4): Instead of only adding $project for selected fields, passed it always + Eliminated _id and events fields in $project.
   03/24/22(B1.3): Added toLowerCase() in rules.duplicate + Added return-object prop to bt-autocomplete-with-all +
      Changed exportFields to be the original case rather lower case + In saveExport(), added $project to aggregateQuery +
      Removed requiredJsonArray rule from the notifications textarea.
   03/18/22(B1.2): Applied changes by Aref: Changed values of delimiterItems + Added extension in downloadExport().
   03/17/22(B1.1): Showed New Export btn only if the user has contact-export-create policy + Changed the icon.
   02/25/22(B1.0): Moved some api calls from newExportClicked() to init() + Made New Export btn disabled until a completed import is available +
      Made Refresh & Manage Schedules buttons hidden until an export is available + Added hide-default-footer prop to v-data-table +
      Fixed the bug that didn't show the 1st item (new export) + Fixed the bug that didn't show exports when account was changed.
   02/17/22(B0.8): Added Tooltip to the processedRecords column to show the processing time and speed + Added isIndexed property to searchFields +
      Fixed the problem with the standard fields in the new export filter.
   07/28/21(B0.7): Consumed BtCronSchedulerChild instead of BtCronScheduler + Consumed small and x-small props in BtCronScheduler +
      Handled @cancel and @submit schedules + Added canCreateCron computed field.
   07/26/21(B0.6): Added Creation Date, Processed Records, Start Date, Last Activity Date, HTTP Request Status and SFTP Status to searchFields.
   07/26/21(B0.5): Consumed BtAutocompleteWithAll component for export fields + Consumed BtFilterDefiner to provide search option + Added pagination logic.
   07/19/21(B0.4): Added Schedule and Manage Schedules buttons + Added httpRequest and sftp properties to the expanded rows.
   07/07/21(B0.3): Added offerCounts + Switched notifications with formData.notifications to make it an object +
      Made notifications non-required + Added placeholder for all the fields.
   07/06/21(B0.2): Fixed delimiter text and single expand bugs + Performed import calls when new export is clickd + 
      Set filter portion only to aggregateQuery + Removed columns tab from filter.
   07/02/21(B0.1): 1st version/release.
-->
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title class="pl-2 pb-2">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">file_upload</v-icon>
            <span>Exports</span>
         </h1>
         <div class="flex-grow-1"></div>
         <bt-cron-scheduler v-if="exports.length"
            get-crons-on-btn-click
            btn-label="Manage Schedules"
            consumer-type="export"
            :debug="debug"
            :disabled="loadingExports"
            :is-authorized="canViewCron"
            :is-json-server="!isActualEndpoint"
            :x-small="true"
            :small="false"
         ></bt-cron-scheduler>
         <v-btn v-if="exports.length"
            x-small
            class="mr-2 mt-1"
            color="gray darken-1"
            :disabled="btnRefreshExportsDisabled"
            @click="getExports()"
         >REFRESH{{exportsDisabledDuration ? ' in ' + exportsDisabledDuration + ' sec' : ''}}
            <v-icon right dark>refresh</v-icon>
         </v-btn>
         <v-btn v-if="$store.getters.user.policies.includes(`contact-export-create`)"
            x-small
            class="mr-2 mt-1"
            color="gray darken-1"
            :disabled="loadingExports || !importHeaders.length"
            @click="newExportClicked()"
         >NEW EXPORT
            <v-icon right dark>add</v-icon>
         </v-btn>
      </v-card-title>
      <!-- <v-card-text v-if="loadingConfig" class="v-data-table__empty-wrapper">Loading account configuration. Please wait...</v-card-text> -->
      <v-card-text class="py-0">
         <bt-filter-wrapper-with-panel
            :closed-on-load="true"
            :fields="searchFields"
            :included-tabs="['standard']"
            :is-admin="jwt.pa"
            :max="searchFields.length"
            :preselected-fields="[]"
            :should-init="shouldInitFilterDefiner2"
            :std-field-values="searchFieldsValues"
            v-model="filter2"
            @filter-change="filterChanged2"
         ></bt-filter-wrapper-with-panel>
      </v-card-text>
      <v-card-text class="pt-2">
         <v-data-table dense fixed-header show-expand single-expand
            class="elevation-1"
            item-key="_id"
            :footer-props="{
               itemsPerPageOptions: [5, 10, 20],
               showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="exportsStats.count <= 5"
            :items="exports"
            :items-per-page="5"
            :loading="loadingExports"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'exports' })"
            :no-results-text="$t('no-results-text', { value: 'exports' })"
            :options.sync="options"
            :search="search"
            :server-items-length="exportsStats.count"
            @update:expanded="exportExpanded"
         >
            <template v-slot:[`item.creationDate`]="{ item }">
               {{ formatDate(item.creationDate, true) }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
               <v-icon :color="getIcon(item.status).color">{{getIcon(item.status).icon}}</v-icon><span :text--color="getIcon(item.status).color"> {{ item.status }}</span>
               <!-- <v-icon v-if="item.status==='processing'" color="blue">hourglass_empty</v-icon> {{ item.status }} -->
            </template>
            <template v-slot:[`item.processedRecords`]="{ item }">
               <v-tooltip v-if="item.processedRecords"
                  top color="black"
               >
                  <template v-slot:activator="{ on, attrs }">
                     <span v-bind="attrs" v-on="on">{{item.processedRecords}}</span>
                  </template>
                  <span>Processing Time: {{calculateProcessingTime(item.lastActivityDate, item.startDate)}}</span>
                  <br>
                  <span>Processing Speed: {{calculateProcessingSpeed(item.processedRecords, item.lastActivityDate, item.startDate)}} Rec/Hr</span>
               </v-tooltip>
               <span v-else>{{item.hasOwnProperty('processedRecords') ? new Intl.NumberFormat().format(item.processedRecords) : ''}}</span>
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
               {{ formatDate(item.startDate, true) }}
            </template>
            <template v-slot:[`item.lastActivityDate`]="{ item }">
               {{ formatDate(item.lastActivityDate, true) }}
            </template>
            <template v-slot:[`item.shouldZip`]="{ item }">
               {{ item.shouldZip ? 'Yes' : 'No' }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
               <v-icon small
                  :disabled="item.status !='completed'"
                  @click="downloadExportClicked(item)"
               >file_download</v-icon>
            </template>

            <template v-slot:expanded-item="{ item }">
               <td colspan="4" class="py-2" valign="top" dense>
                  <ul>
                     <li>
                        <span class="expanded-header">ID: </span>
                        <span class="expanded-content">{{item._id}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Delimiter: </span>
                        <span class="expanded-content">{{getDelimiterDesc(item.delimiter)}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Debug: </span>
                        <span class="expanded-content">{{item.debug ? 'true' : 'false'}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">DB Name: </span>
                        <span class="expanded-content">{{item.databaseName}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Recovery Count: </span>
                        <span class="expanded-content">{{item.recoveryCount}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Recovery Error: </span>
                        <span class="expanded-content">{{item.recoveryError}}</span>
                     </li>
                     <li v-if="hasHttpRequest">
                        <span class="expanded-header">HTTP Request Status: </span>
                        <span class="expanded-content">{{item.httpRequestStatus}}</span>
                     </li>
                     <li v-if="hasHttpRequest">
                        <span class="expanded-header">HTTP Request Recovery Count: </span>
                        <span class="expanded-content">{{item.httpRequestRecoveryCount}}</span>
                     </li>
                     <li v-if="hasHttpRequest">
                        <span class="expanded-header">HTTP Request Result: </span>
                        <span class="expanded-content">{{item.httpRequestResult }}</span>
                     </li>
                     <li v-if="hasFtp">
                        <span class="expanded-header">SFTP Status: </span>
                        <span class="expanded-content">{{item.sftpStatus}}</span>
                     </li>
                     <li v-if="hasFtp">
                        <span class="expanded-header">SFTP Recovery Count: </span>
                        <span class="expanded-content">{{item.sftpRecoveryCount}}</span>
                     </li>
                     <li v-if="hasFtp">
                        <span class="expanded-header">SFTP Result: </span>
                        <span class="expanded-content">{{item.sftpResult }}</span>
                     </li>
                  </ul>
               </td>
               <td colspan="5" class="py-2" valign="top" dense>
                  <ul>
                     <li v-if="item.hasOwnProperty('fieldDefinitions')">
                        <span class="expanded-header">Field Definitions: </span>
                        <span class="expanded-content">{{JSON.stringify(item.fieldDefinitions, null, 2)}}</span>
                     </li>
                     <li v-else>
                        <span class="expanded-header">Fields: </span>
                        <span class="expanded-content">{{item.exportFields ? item.exportFields.join(', ') : ''}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Filter: </span>
                        <span class="expanded-content">{{JSON.stringify(JSON.parse(item.aggregateQuery), null, 2)}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Notifications: </span>
                        <span class="expanded-content">{{getNotifications(item.notifications)}}</span>
                     </li>
                     <li v-if="item.actions"><!-- V231113 -->
                        <span class="expanded-header">Actions: </span>
                        <span class="expanded-content">{{getExportActions(item.actions)}}</span>
                     </li>
                  </ul>
               </td>
            </template>

            <template v-slot:[`body.append`] v-if="exportsStats.count">
               <tr class="font-weight-bold">
                  <td colspan="5">Totals:</td>
                  <td colspan="4" align="left">{{formatNumber(exportsStats.processedTotal)}}</td>
               </tr>
            </template>
         </v-data-table>
      </v-card-text>
   </v-card>

   <v-form lazy-validation
      ref="mainForm"
      v-model="isMainFormValid"
   >
      <v-dialog no-click-animation persistent
         max-width="1080px"
         v-model="dialogExport"
      >
         <v-card                :loading="loadingNewExport" flat>
            <v-card-title class="title grey--text darken-4 font-weight-bold pb-2">Create a New Export:</v-card-title>
               <!-- :loading="loadingNewExport" -->
            <v-card-text
               class="pt-2 pb-0"
            >
               <v-tabs
                  class="elevation-2"
                  background-color="grey lighten-2 accent-4"
                  slider-color="black"
                  v-model="tab"
               >
                  <v-tab 
                     :class="isGeneralFormValid ? '' : 'red--text'"
                  >General</v-tab>
                  <v-tab :disabled="loadingNewExport">Filter</v-tab>
                  <v-tab
                     :class="isFieldsFormValid ? '' : 'red--text'"
                  >Field Definitions</v-tab>
                  <v-tab>Notifications</v-tab>
                  <v-tab v-if="canViewActionsTab"
                     :class="isActionsFormValid ? '' : 'red--text'"
                  >Actions</v-tab>

                  <v-tabs-items v-model="tab">
                     <v-tab-item><!-- General tab -->
                        <v-card flat tile>
                           <v-card-text>
                              <v-form lazy-validation
                                 ref="generalForm"
                                 v-model="isGeneralFormValid"
                              >
                                 <v-row>
                                    <v-col xs="12" sm="12" md="6" class="pt-1 pb-0">
                                       <v-text-field autofocus dense persistent-hint required
                                          v-model="formData.name"
                                          ref="name"
                                          autocomplete="off"
                                          hint="* Export Name"
                                          :counter="100"
                                          :maxlength="100"
                                          :placeholder="`${hasError('name') ? 'export name' : ''}`"
                                          :rules="[rules.required, rules.minLength, rules.duplicate]"
                                       ></v-text-field>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="4" class="pt-1 pb-0">
                                       <v-select dense persistent-hint hide-selected required
                                          v-model="formData.delimiter"
                                          ref="delimiter"
                                          hint="* File Delimiter"
                                          :items="delimiterItems"
                                       ></v-select>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="2" class="py-0">
                                       <v-switch
                                          :label="`Zip: ${formData.shouldZip?'Yes':'No'}`"
                                          v-model="formData.shouldZip"
                                       ></v-switch>
                                    </v-col>
                                 </v-row>
                              </v-form>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item><!-- Filter tab -->
                        <v-card flat class="py-0">
                           <v-card-title class="mx-0 my-0 px-0 py-0">
                              <div class="flex-grow-1"></div>
                              <bt-filter-wrapper-with-btn
                                 :activeTab="activeTab"
                                 :bt-helpers="btHelpers"
                                 :fields="importHeaders"
                                 :included-tabs="['standard', 'behavioral', 'bylocation', 'fulltext', 'predefined']"
                                 :is-admin="jwt.pa"
                                 :max="4"
                                 parent-component="CsExports"
                                 :preselected-fields="preselectedHeaders"
                                 :should-init="shouldInitFilterDefiner"
                                 :show-test-cases="!isActualEndpoint"
                                 :std-field-values="stdFieldValues"
                                 v-model="filter"
                                 @filter-change="filterChanged"
                              ></bt-filter-wrapper-with-btn>
                           </v-card-title>
                           <v-card-text>
                                 <!-- :hint="`* Filter: ${filterMsg}`" -->
                              <v-textarea dense outlined persistent-hint readonly required
                                 ref="aggregateQuery"
                                 :hint="filterMsg"
                                 rows="4"
                                 :rules="[rules.required]"
                                 v-model="formData.aggregateQuery"
                              ></v-textarea>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item><!-- Field Definitions tab -->
                        <v-card flat class="py-0"><!-- tile -->
                           <v-card-title class="mx-0 my-0 px-0 py-0">
                              <div class="flex-grow-1"></div>
                              <bt-export-field-definer
                                 :debug="debug"
                                 :items="exportFields"
                                 v-model="formData.fieldDefinitions"                        
                                 @change="fieldDefinitionsChanged"
                              ></bt-export-field-definer>
                           </v-card-title>
                           <v-card-text>
                              <v-form lazy-validation
                                 ref="fieldsForm"
                                 v-model="isFieldsFormValid"
                              >
                                 <v-textarea dense outlined persistent-hint readonly required
                                    ref="fieldDefinitions"
                                    hint="* Field Definitions"
                                    rows="4"
                                    :rules="[rules.requiredStringArray]"
                                    v-model="fieldDefinitions"
                                 ></v-textarea>
                              </v-form>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item><!-- Notifications tab -->
                        <v-card flat class="py-0"><!-- tile -->
                           <v-card-title class="mx-0 my-0 px-0 py-0">
                              <div class="flex-grow-1"></div>
                              <bt-notifications
                                 v-model="formData.notifications"
                                 :debug="debug"
                                 :max="1"
                                 :required="false"
                                 @change="notificationsChanged"
                              ></bt-notifications>
                           </v-card-title>
                           <v-card-text>
                              <v-textarea dense outlined persistent-hint readonly required
                                 class="py-0 my-0 caption"
                                 v-model="notifications"
                                 ref="notifications"
                                 hint="Notifications"
                                 :placeholder="`${hasError('notifications') ? '* notifications:' : ''}`"
                                 rows="4"
                              ></v-textarea>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item v-if="canViewActionsTab"><!-- Actions tab -->
                        <v-card flat class="py-0"><!-- tile -->
                           <v-card-text>
                              <div v-if="!canSelectActions">
                                 Actions can only be set if both 'purl' and '_id' fields are selected in the FIELD DEFINITIONS tab.
                              </div>
                              <v-autocomplete multiple persistent-hint small-chips deletable-chips
                                 ref="actions"
                                 placeholder="select 1 to 5 actions to be performed"
                                 hint="Actions: select up to 5 items"
                                 :disabled="!actionIds.length && !canSelectActions"
                                 :items="actionItems"
                                 :counter="actionItems.length"
                                 :rules="[rules.tooMany(actionIds, 5)]"
                                 v-model="actionIds"
                              ></v-autocomplete>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>
                  </v-tabs-items>
               </v-tabs>
            </v-card-text>

            <v-card-actions class="pt-0 pr-4">
               <v-switch v-if="jwt.au"
                  class="mx-0 my-0 pl-5 pt-3 pb-0"
                  label="Debug"
                  v-model="formData.debug"
               ></v-switch>
               <div class="flex-grow-1"></div>
               <v-btn text
                  color="blue darken-1"
                  @click="cancelExport"
               >Cancel</v-btn>
               <bt-cron-scheduler v-if="!isActualEndpoint"
                  btn-label="BI Scheduler (for me)"
                  consumer-type="bi"
                  :debug="debug"
                  :instruction="{url: '/api/bi/v1/reports/account-download-contacts?p1=v1'}"
                  :is-authorized="canCreateCron"
                  :is-json-server="!isActualEndpoint"
                  :rdls="['account-filter-servicetype','account-filter-event','admin-groupby-servicetype-account','account-download-events','parent-groupby-event-hour']"
                  v-model="cron"
                  @click="scheduleClicked"
               ></bt-cron-scheduler>
               <bt-cron-scheduler-child
                  btn-label="Schedule"
                  btn-icon="schedule"
                  btn-align="right"
                  btn-role="edit"
                  consumer-type="export"
                  dialogTitle="Add a New Schedule:"
                  :debug="debug"
                  :btn-disabled="!isExportFormValid"
                  :is-authorized="canCreateCron"
                  :is-json-server="!isActualEndpoint"
                  :small="true"
                  :xSmall="false"
                  :show-offset="true"
                  v-model="cron"
                  @click="scheduleClicked"
                  @cancel="scheduleCancelled"
                  @submit="scheduleSubmitted"
               ></bt-cron-scheduler-child>
               <v-btn text
                  color="blue darken-1"
                  :disabled="!isExportFormValid"
                  @click="saveExport"
               >Submit</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-form>
</v-container>
</template>

<script>
import BtFilterWrapperWithPanel from './BtFilterWrapperWithPanel.vue';
import BtFilterWrapperWithBtn from './BtFilterWrapperWithBtn.vue';
import BtNotifications from './BtNotifications.vue';
import { APIService } from '../services/cs-api-service.js';
import { addSeconds, differenceInHours, differenceInMinutes, differenceInSeconds, format, parseISO } from "date-fns";
import { BtHelpers } from '../services/bt-helpers.js';
import BtCronScheduler from "./BtCronScheduler.vue";
import BtCronSchedulerChild from "./BtCronSchedulerChild.vue";
import BtExportFieldDefiner from "./BtExportFieldDefiner.vue";
import { developersAccess, appList } from '../mixins/cs-apps.js';
// import { formatNumber } from '../mixins/bt-mixin.js';

const TABS = { general: 0, filter: 1, fields: 2, notifications: 3, actions: 4 };

class FormData {
   constructor(initData) {
      this.name = initData.name || '';
      this.delimiter = initData.delimiter || 'csv';
      // this.exportFields = initData.exportFields || [];
      this.aggregateQuery = initData.aggregateQuery || [];
      this.notifications = initData.notifications || [];
      this.fieldDefinitions = initData.fieldDefinitions || [];
      this.debug = false;
      this.shouldZip = initData.shouldZip || false;
      // this.publicKey = initData.publicKey || '';
      // this.actionIds = initData.actions ? initData.actions.map(a => a.actionId) : [];
   }
}

const NAME = 'CsExports';

export default {
   name: NAME,

   components: {
      BtFilterWrapperWithPanel,
      BtFilterWrapperWithBtn,
      BtNotifications,
      BtCronScheduler,
      BtCronSchedulerChild,
      BtExportFieldDefiner
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         jwt: {},
         apiService: null,
         btHelpers: null,
         formData: new FormData({}),
         exports: [],
         rules: {
            required: value => !!value || "Value is required!",
            requiredStringArray: value => !!JSON.parse(value).length || "Field Definitions are required!",
            minLength: value => value.length >= 5 || "Name should have more than 5 charecters",
            duplicate: value => this.exports.filter(exprt => exprt.name.toLowerCase() === value.toLowerCase()).length === 0 || 'Value is duplicate!',
            tooMany: (value, max) => !value || value.length <= max || `Not more than ${max} items can be selected!`
         },
         headers: [
            { text: 'Name', value: 'name', align: 'left', sortable: true },
            { text: 'Creation Date', value: 'creationDate', align: 'left', sortable: false },
            { text: 'Creator', value: 'creator', align: 'left', sortable: true },
            { text: 'Status', value: 'status', align: 'left', sortable: true },
            { text: 'Processed Records', value: 'processedRecords', align: 'left', sortable: true },
            { text: 'Start Date', value: 'startDate', align: 'left', sortable: true },
            { text: 'Last Activity Date', value: 'lastActivityDate', align: 'left', sortable: true },
            { text: 'Is Zip?', value: 'shouldZip', align: 'left', sortable: true },
            { text: '', value: 'action', align: 'right', sortable: false }
         ],
         delimiterItems: [
            { text: 'Comma', value: 'csv' },
            { text: 'Tab', value: 'tab' },
            { text: 'Pipe', value: 'pipe' }
         ],
         search: '',
         tdHeader: 'font-weight-bold font-italic',
         tdContent: 'font-italic',
         exportsDisabledDuration: 0,
         btnRefreshExportsDisabled: true,
         loadingExports: false,
         loadingNewExport: false,
         dialogExport: false,
         isMainFormValid: false,
         activeTab: 'standard',  //TODO: set to the first tab with content
         importHeaders: [],
         preselectedHeaders: [],
         shouldInitFilterDefiner: false,
         stdFieldValues: {},
         filter: {},
         notifications: "[]",
         firstLoad: true,
         // offersCount: 0,
         cron: {},
         cronInstruction: {},
         hasHttpRequest: false,
         hasFtp: false,
         // exportsCount: 0,
         options: {},
         shouldInitFilterDefiner2: false,
         filter2: {
            standard: [{ $match: {} }],
            // columns: []
         },
         searchFields: [
            { text: 'Name', value: 'name', type: 'string', isIndexed: true },
            { text: 'Creation Date', value: 'creationDate', type: 'date', isIndexed: true },
            { text: 'Status', value: 'status', type: 'string', isIndexed: true },
            { text: 'Processed Records', value: 'processedRecords', type: 'number', isIndexed: true },
            { text: 'Start Date', value: 'startDate', type: 'date', isIndexed: true },
            { text: 'Last Activity Date', value: 'lastActivityDate', type: 'date', isIndexed: true },
            { text: 'HTTP Request Status', value: 'httpRequestStatus', type: 'string', isIndexed: true },
            { text: 'SFTP Status', value: 'sftpStatus', type: 'string', isIndexed: true }
         ],
         searchFieldsValues: {
            status: ['waiting','processing','completed','recovery','rejected']
         },
         exportFields: [],
         fieldDefinitions: "[]",
         showOffset: false,
         actions: [],
         actionItems: [],
         actionIds: [],
         tab: null,
         isGeneralFormValid: false,
         isFieldsFormValid: false,
         canSelectActions: false,
         developers: developersAccess(),
         canViewActionsTab: false,
         importValidations: [],
         filterMsg: '',
         exportsStats: {},
         appCodes: []
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      isExportFormValid() {
         // return this.formData.name && this.isMainFormValid
         return this.isGeneralFormValid && this.isFieldsFormValid && this.isActionsFormValid;
      },

      canViewCron() {
         return (this.$store.getters.user.policies && this.$store.getters.user.policies.indexOf('scheduler-crons-list') > -1) || false;
      },

      canCreateCron() {
         return (this.$store.getters.user.policies && this.$store.getters.user.policies.indexOf('scheduler-crons-create') > -1) || false;
      },

      isActionsFormValid() {
         if (!this.formData.fieldDefinitions.length || this.canSelectActions) return true;
         else if (!this.actionIds.length) return true;
         else return false;
      }
   },

   watch: {
      token() {
         this.init();
         this.nextAction();
      },

      options: {
         handler (val) {
            // alert('in watch: options=' + JSON.stringify(val));
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
               this.filter2.sort = sort;
            }
            this.getExports();
         }
      },

      tab: {
         handler (val) {
            // alert('in watch: tab=' + val);
            if (val === TABS.general) {
               setTimeout(() => {
                  this.$refs.name.focus();
               }, 5);
            } else if (val === TABS.fields) {
               setTimeout(() => {
                  if (this.$refs.fieldsForm)
                     this.$refs.fieldsForm.validate();
               }, 5);
            } else if (val === TABS.actions) {
               setTimeout(() => {
                  this.$refs.actions.focus();
               }, 5);
            }
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V250117.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      hasError(ref) {
         let res = false;
         if (!this.isMainFormValid && this.$refs[ref]) {
            // if (ref === 'name') alert('isDirty=' + this.$refs[ref].isDirty);
            res = this.$refs[ref].hasError;
         }
         return res;
      },

      async init() {
         try {
            // alert('in init(): filter2=' + JSON.stringify(this.filter2));
            this.exports = [];

            if (this.token) {
               // const JWT = JSON.parse(atob(this.token.split('.')[1]));
               // this.jwt = {
               //    aid: JWT.aid,
               //    paid: JWT.paid,
               //    pa: JWT.pa,
               //    pu: JWT.pu
               // };
               this.jwt = JSON.parse(Buffer.from(this.token.split('.')[1], 'base64'));
               this.log(`in init(): jwt=${JSON.stringify(this.jwt)}`);
               this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);

               //B1.0: Moved this section from newExportClicked()
               this.btHelpers = new BtHelpers(this.token, this.isActualEndpoint, this.debug);
               // this.importHeaders = await this.btHelpers.getImportHeaders(this.filter2, true);
               // console.warn(`importHeaders=${JSON.stringify(this.importHeaders)}`);
               const importData = await this.btHelpers.getImportData(this.filter2, true);
               // console.warn(`importData=${JSON.stringify(importData)}`);
               this.importValidations = importData.fieldValidations.filter(v => v.type === 'date');
               this.importHeaders = importData.headers;
               this.exportFields = [...this.importHeaders, ...[
                  { text: '_id', value: '_id', type: 'string', isIndexed: true, internalField: true },
                  { text: 'app_code', value: 'app_code', type: 'string', isIndexed: true, isEventField: true },
                  { text: 'event_code', value: 'event_code', type: 'string', isIndexed: true, isEventField: true },
                  { text: 'event_date', value: 'event_date', type: 'date', isIndexed: true, isEventField: true },
                  { text: 'event_element', value: 'event_element', type: 'string', isIndexed: true, isEventField: true },
                  { text: 'event_path', value: 'event_path', type: 'string', isIndexed: true, isEventField: true },
                  { text: 'browser', value: 'browser', type: 'string', isIndexed: true, isEventField: true },  //V230630.1
                  { text: 'device', value: 'device', type: 'string', isIndexed: true, isEventField: true }, //V230630.1
                  { text: 'ip', value: 'ip', type: 'string', isIndexed: true, isEventField: true },   //V230630.1
                  { text: 'os', value: 'os', type: 'string', isIndexed: true, isEventField: true },   //V230630.1
                  { text: 'platform', value: 'platform', type: 'string', isIndexed: true, isEventField: true },   //V230630.1
                  { text: 'event_data', value: 'event_data', type: 'number', isIndexed: false, isEventField: true },
                  { text: 'event_id', value: 'event_id', type: 'string', isIndexed: true, isEventField: true }   //V250117.1
               ]];

               const importField = this.exportFields.find(f => f.value === 'importId');
               if (importField)
                  importField.text = importField.value;

               // alert('in init(): exportFields=' + JSON.stringify(this.exportFields));
               if (this.importHeaders.length > 0) {
                  this.preselectedHeaders = await this.btHelpers.getSelectedHeaders(this.importHeaders, 7);
                  // this.stdFieldValues = await this.btHelpers.getFieldValues(this.filter2, true);
               }

               // this.exportsCount = await this.getExportsCount();
               await this.getExportsStats();
               this.shouldInitFilterDefiner2 = true;
               this.firstLoad = true;
            } else
               this.jwt = {};

            this.canViewActionsTab = this.jwt.pa || this.developers.includes(this.jwt.email) || this.$store.getters.user.policies.includes('contact-action-view');
            let appItems = appList().filter(app => app.hasExportAssociation);
            // alert(`in ${NAME}.init(): appItems=${JSON.stringify(appItems)}`);
            if (!this.developers.includes(this.jwt.email))
               appItems = appItems.filter(app => app.onProduction);
            this.appCodes = appItems.map(app => app.value);
            // alert(`in ${NAME}.init(): appCodes=${JSON.stringify(this.appCodes)}`);
         } catch (error) {
            alert('Exception while parsing token: ' + error.message);
         }
      },

      // async getExportsCount() {
      //    this.loadingExports = true;
      //    let result = await this.apiService.getExportsCount(this.filter2);
      //    this.loadingExports = false;
      //    if (result.logout)
      //       this.logout();

      //    return result.message ? 0 : result.data;
      // },

      //V240301
      async getExportsStats() {
         this.loadingExports = true;
         let result = await this.apiService.getExportsStats(this.filter2);
         this.loadingExports = false;
         if (result.logout)
            this.logout();

         this.exportsStats = result.message ? { count: 0 } : result.data;
      },

      async getExports() {
         this.loadingExports = true;
         this.btnRefreshExportsDisabled = true;
         this.exportsDisabledDuration = 30;
         let result = await this.apiService.getExports(this.filter2, this.options.page, this.options.itemsPerPage);
         this.loadingExports = false;

         if (result.logout)
            this.logout();
         else if (result.message)
            this.exports = [];
         else {
            this.exports = result.data;
            this.$forceUpdate();
         }

         if (this.exports.length) {
            setTimeout(() => {
               this.btnRefreshExportsDisabled = false;
            }, 1000 * 30);

            const setIntervalExports = setInterval(() => {
               if (this.exportsDisabledDuration > 0)
                  this.exportsDisabledDuration--;
               else
                  clearInterval(setIntervalExports);
            }, 1000);
         }
      },

      getIcon(status) {
         switch (status) {
            case 'waiting':
               return { color: 'grey', icon: 'schedule' };
            case 'processing':
               return { color: 'blue', icon: 'hourglass_empty' };
            case 'completed':
               return { color: 'green', icon: 'done' };
            case 'recovery':
               return { color: 'orange', icon: 'restore' };
            case 'rejected':
               return { color: 'red', icon: 'clear' };
            default:
               return { color: '', icon: '' };
         }
      },

      getDelimiterDesc(delimiter) {
         const item = this.delimiterItems.find(d => d.value === delimiter);
         return item ? item.text : delimiter;
      },

      async downloadExportClicked(exportData) {
         this.log('in downloadExportClicked(): exportData=' + JSON.stringify(exportData));
         this.loadingExports = true;

         // from Aref
         let fileExtension = '.csv';
         if(exportData.delimiter.toLowerCase() != 'csv'){
               fileExtension = '.txt';
         }

         if(exportData.shouldZip){
            fileExtension += ".gz";
         }

         const result = await this.apiService.getExportUrl(exportData._id + fileExtension);
         this.loadingExports = false;
         if (result.logout)
            this.logout();
         else if (!result.message) {
            // window.location = result.data.url;
            // window.open(result.data.url, '_blank', 'download="behzad1.csv"');

            // const fileName = `${exportData.name}.${exportData.delimiter === 'csv' ? 'csv' : 'txt'}`;
            const fileName = exportData.name + fileExtension;

            this.downloadExport(result.data.url, fileName);
         }
      },

      async downloadExport(url, name) {
         const file = await fetch(url);
         const fileBlob = await file.blob();
         const fileUrl = URL.createObjectURL(fileBlob);

         const anchor = document.createElement("a");
         anchor.href = fileUrl;
         anchor.download = name;

         document.body.appendChild(anchor);
         anchor.click();
         document.body.removeChild(anchor);
      },

      formatDate(date, withTime) {
         if (date) {
            // alert('in formatDate(): date=' + date + '\nwithTime=' + withTime + '\nparseISO=' + parseISO(date));
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            if (withTime) return formatteddate;
            else return formatteddate.split(' ')[0];
         }
      },

      formatNumber(number) {
         return new Intl.NumberFormat().format(number);
      },

      calculateProcessingTime(dateEnded, dateStarted, extraSeconds) {
         const isoEnded = parseISO(dateEnded);
         const isoStarted = addSeconds(parseISO(dateStarted), extraSeconds || 0);
         const h = differenceInHours(isoEnded, isoStarted);
         const min = differenceInMinutes(isoEnded, isoStarted);
         const m = min - h * 60;
         const s = differenceInSeconds(isoEnded, isoStarted) - h * 3600 - m * 60;
         let diff = '';
         if (h) diff = h + 'h ';
         if (min) diff += m + 'm ';
         diff += s + 's';
         return diff;
      },

      calculateProcessingSpeed(processedRecs, dateEnded, dateStarted, extraSeconds) {
         const cntPerHour = new Intl.NumberFormat().format(Math.round(processedRecs * 3600 / differenceInSeconds(parseISO(dateEnded), addSeconds(parseISO(dateStarted), extraSeconds || 0))));
         // alert(`processedRecs=${processedRecs}\ndateEnded=${dateEnded}\ndateStarted=${dateStarted}\ncntPerHour=${cntPerHour}`);
         return cntPerHour;
      },

      exportExpanded(item) {
         // alert(JSON.stringify(item) + '\n' +
         //    JSON.stringify(item[0].notifications) + '\n' + 
         //    typeof item[0].notifications);
         this.hasHttpRequest = item && item.length === 1 ?
            item[0].notifications.find(n => n.type === 'http') :
            false;
         this.hasFtp = item && item.length === 1 ?
            item[0].notifications.find(n => n.type === 'sftp') :
            false;
      },

      getNotifications(notifications) {
         const result = [];
         if (notifications && Array.isArray(notifications))
            notifications.forEach(element => {
               if (element.type === 'sftp')
                  element.password = '***';
               if (element.pgpPublicKey)
                  element.pgpPublicKey = '***';
               result.push(element);
            });

         return JSON.stringify(result, null, 2);
      },

      async newExportClicked() {
         this.dialogExport = true;
         this.formData = new FormData({});
         this.filterChanged(this.btHelpers.getInitialFilter(this.preselectedHeaders));
         this.notifications = "[]";
         this.shouldInitFilterDefiner = true;
         this.fieldDefinitions = "[]";

         //V231113
         this.actionIds = [];
         if (!this.actions.length)
            await this.getActions();

         setTimeout(async() => {
            if (this.firstLoad) {
               this.firstLoad = false;

               if (this.importHeaders.length > 0) {
                  this.loadingNewExport = true;
                  this.stdFieldValues = await this.btHelpers.getFieldValues(this.btHelpers.getInitialFilter(), true);
                  this.loadingNewExport = false;
               }

               this.tab = TABS.fields;

               setTimeout(() => {
                  this.tab = TABS.general;
               }, 10);
            } else
               this.tab = TABS.general;
         }, 5);
      },

      async filterChanged(filter) {
         this.filterMsg = 'processing...';
         this.filter = filter;
         this.formData.aggregateQuery = JSON.stringify(filter.standard);
         let result;
         if (this.formData.aggregateQuery === JSON.stringify(this.btHelpers.getInitialFilter().standard)) {  //V240227
            result = await this.apiService.getCollectionInfo();
            this.offersCount = result.data.db_records || 0;
         } else {
            result = await this.apiService.getOffersCount(this.filter);
            this.offersCount = result.data;
         }

         if (result.logout)
            this.logout();
         else
            this.filterMsg = result.timeout ? 'The number of returning records could not be shown!' : `Returns ${this.formatNumber(this.offersCount)} records`;
      },

      notificationsChanged(notifications) {
         this.formData.notifications = notifications;
         this.notifications = JSON.stringify(notifications);
      },

      fieldDefinitionsChanged(fieldDefinitions) {
         // this.formData.fieldDefinitions = fieldDefinitions;
         this.showOffset = fieldDefinitions.filter(f => f.value === 'event_date').length > 0;
         this.fieldDefinitions = JSON.stringify(fieldDefinitions);
         const purlAndId = fieldDefinitions.filter(f => f.value === 'purl' || f.value === '_id');
         this.canSelectActions = purlAndId && purlAndId.length === 2;
      },

      cancelExport() {
         this.dialogExport = false;
         this.formData = new FormData({});
      },

      scheduleClicked() {
         setTimeout(() => {
            this.cron = {
               instruction: this.getExportData()
            };
            this.cron.instruction.timeZone = new Date().getTimezoneOffset();
         }, 10);
      },

      scheduleCancelled() {
         this.$emit('snackbar-event', `Scheduling process was cancelled.`);
      },

      scheduleSubmitted() {
         this.$emit('snackbar-event', `'${this.formData.name}' was scheduled.`);
         this.cancelExport();
      },

      async saveExport() {
         if (this.$refs.mainForm.validate()) {
            this.loadingNewExport = true;
            this.log('in saveExport(): formData=' + JSON.stringify(this.formData));
            
            // const result = await this.apiService.createExport(this.formData);

            const exportData = this.getExportData();
            const result = await this.apiService.createExport(exportData);
            //V250117: const result = await this.apiService.createExport(this.getExportData());
            this.loadingNewExport = false;
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$emit('snackbar-event', `'${this.formData.name}' was submitted.`);
               // this.getExports(); TODO: think when implementing pagination
               this.cancelExport();
               this.exports.unshift(result.data);
               this.exportsStats.count++;
            }
         }
      },

      // getExportData() {
      //    let hasEventField = false;
      //    const exportData = JSON.parse(JSON.stringify(this.formData));
      //    const $project = { _id: 0 };
      //    if (exportData.exportFields.length < this.exportFields.length) {
      //       exportData.exportFields.forEach(fld => {
      //          if (fld.isEventField) {
      //             $project[fld.value] = '$events.' + fld.value;
      //             hasEventField = true;
      //          }
      //          else
      //             $project[fld.value] = 1;
      //       });
      //    } else {
      //       $project['events'] = 0;
      //       $project['rowNumber'] = 0;
      //       $project['purlNumber'] = 0;
      //       $project['basePurl'] = 0;
      //       $project['dedupHash'] = 0;
      //       $project['__v'] = 0;
      //    }
      //    const aggregateQuery = JSON.parse(exportData.aggregateQuery);
      //    if (hasEventField)
      //       aggregateQuery.push({ $unwind: '$events' });

      //    aggregateQuery.push({ $project: $project });
      //    exportData.aggregateQuery = JSON.stringify(aggregateQuery);
      //    exportData.exportFields = exportData.exportFields.map(fld => fld.text);
      //    // alert('in getExportData(): exportData=' + JSON.stringify(exportData));
      //    return exportData;
      // },


      //V230627.1
      // getExportData() {
      //    let hasEventField = false;
      //    const exportData = JSON.parse(JSON.stringify(this.formData));
      //    // const $project = { _id: 0 };
      //    const $project = {};
      //    const vars = [];
      //    exportData.fieldDefinitions.forEach(fld => {
      //       if (fld.value)
      //          vars.push(fld.value);
      //       else 
      //          vars.push(...fld.format.slice(1));
      //    });
      //    // alert('vars='+JSON.stringify(vars));
      //    const uniqueVars = [...new Set(vars)];
      //    // alert('uniqueVars='+JSON.stringify(uniqueVars));
      //    uniqueVars.forEach(v => {
      //       const field = this.exportFields.find(f => f.value === v);
      //       if (field.isEventField) {
      //          $project[field.value] = '$events.' + field.value;
      //          hasEventField = true;
      //       } else
      //          $project[field.value] = 1;
      //    });

      //    if (!uniqueVars.find(v => v === '_id'))
      //       $project['_id'] = 0;

      //    // alert('$project='+JSON.stringify($project));
      //    const aggregateQuery = JSON.parse(exportData.aggregateQuery);
      //    if (hasEventField)
      //       aggregateQuery.push({ $unwind: '$events' });

      //    aggregateQuery.push({ $project: $project });
      //    exportData.aggregateQuery = JSON.stringify(aggregateQuery);
      //    // exportData.exportFields = exportData.exportFields.map(fld => fld.text);
      //    // alert('in getExportData(): exportData=' + JSON.stringify(exportData));
      //    return exportData;
      // },

      getExportData() {
         // this.log('clear console', true);
         // let hasEventField = false;
         const exportData = JSON.parse(JSON.stringify(this.formData));
         
         // console.warn('exportData.fieldDefinitions='+JSON.stringify(exportData.fieldDefinitions));
         // this.log('importValidations=' + JSON.stringify(this.importValidations), true);
         this.importValidations.forEach(v => {
            const fld = exportData.fieldDefinitions.find(f => f.label === v.column);
            if (fld) {
               fld.dateFormat = v.format;
            }
         });

         // const $project = { _id: 0 };
         // const $project = {};
         const eventFields = {};
         const otherFields = {};
         const vars = [];
         exportData.fieldDefinitions.forEach(fld => {
            if (fld.value)
               vars.push(fld.value);
            else 
               vars.push(...fld.format.slice(1));
         });
         // alert('vars='+JSON.stringify(vars));
         const uniqueVars = [...new Set(vars)];
         // alert('uniqueVars='+JSON.stringify(uniqueVars));
         uniqueVars.forEach(v => {
            const field = this.exportFields.find(f => f.value === v);

            //V230627.1
            if (field) {
               if (field.isEventField) {
                  // $project[field.value] = '$events.' + field.value;
                  // hasEventField = true;
                  //V250117: eventFields[field.value] = '$events.' + field.value;
                  eventFields[field.value] = `$events.${field.value === 'event_id' ? '_id' : field.value}`;
               } else {
                  // $project[field.value] = 1;
                  otherFields[field.value] = 1;
               }
            } else {
               const edField = exportData.fieldDefinitions.find(f => f.value === v);
               // $project[edField.value] = '$events.event_data.' + edField.value;
               // hasEventField = true;
               if (edField)   //V230814.2
                  eventFields[edField.value] = '$events.event_data.' + edField.value;
            }
         });

         //V230627.2
         // if (!uniqueVars.find(v => v === '_id'))
         //    $project['_id'] = 0;

         this.log('original aggregateQuery=' + exportData.aggregateQuery, true);
         const aggregateQuery = JSON.parse(exportData.aggregateQuery);

         //V230627.2
         const otherFieldsKeys = Object.keys(otherFields);
         if (otherFieldsKeys.length) {
            const projectObj = aggregateQuery.find(q => q.$project);
            if (projectObj) {
               const $project = projectObj.$project;
               const $projectKeys = Object.keys($project);
               otherFieldsKeys.forEach(key => {
                  if (!$projectKeys.includes(key))
                     $project[key] = 1;
               });
            }
         }

         if (Object.keys(eventFields).length)
            aggregateQuery.push({ $unwind: '$events' });

         //V230814.1: Extending $project to resolve PURLs case-sensitivity issue
         if (!Object.keys(otherFields).includes('purlNumber'))
            otherFields.purlNumber = 1;

         otherFields.purlNumberStr = {
            "$cond": {
               "if": {"$eq": ["$purlNumber", 1]},
               "then": "",
               "else": {"$toString": {"$subtract": ["$purlNumber", 1]}}
            }
         };
         aggregateQuery.push({ $project: this.mergeJSONObjects(otherFields, eventFields) });
         exportData.aggregateQuery = JSON.stringify(aggregateQuery);
         // exportData.exportFields = exportData.exportFields.map(fld => fld.text);
         this.log('modified aggregateQuery=' + exportData.aggregateQuery, true);

         //V231113
         if (this.actionIds.length) {
            exportData.actions = [];
            this.actionIds.forEach(id => {
               const action = this.actions.find(a => a._id === id);
               // if (action)
                  exportData.actions.push({
                     actionId: action._id,
                     appCode: action.appCode,
                     actionName: action.name
                     // consumerCode: action.consumerCode   //V231115
                  });
            });
         }

         this.log('in getExportData(): exportData=' + JSON.stringify(exportData));
         return exportData;
      },

      mergeJSONObjects(obj1, obj2) {
         if (Object.keys(obj1).length === 0) {
            return obj2;
         }
         if (Object.keys(obj2).length === 0) {
            return obj1;
         }
         return { ...obj1, ...obj2 };
      },

      async filterChanged2(filter) {
         // alert('in filterChanged2(): filter=' + JSON.stringify(filter) + '\noptions=' + JSON.stringify(this.options));
         this.filter2 = filter;
         // this.exportsCount = await this.getExportsCount();
         await this.getExportsStats();
         this.nextAction();
      },

      async nextAction() {
         const currOptions = JSON.stringify(this.options);
         const newOptions = JSON.parse(currOptions);
         newOptions.page = 1;
         if (JSON.stringify(newOptions) === currOptions)
            await this.getExports();
         else
            this.options = newOptions;
      },

      async getActions() {
         this.loadingNewExport = true;
         let result = await this.apiService.getActions(
            this.btHelpers.getInitialFilter(),
            100,  //limit
            0     //page
         );
         if (result.logout)
            this.logout();
         else if (result.message) {
            this.actions = [];
            this.actionItems = [];
         } else {
            // alert(`in getActions(): actions=${JSON.stringify(result.data)}`);
            this.actions = result.data.filter(d => this.appCodes.includes(d.appCode));
            // alert(`in getActions(): actions=${JSON.stringify(this.actions)}`);
            this.actionItems = this.actions.map(d => {
               return { text: d.name, value: d._id }
            });
         }
         this.loadingNewExport = false;
      },

      //V231113, V231115
      getExportActions(actions) {
         return JSON.stringify(
            actions.map(a => ({actionId: a.actionId, actionName: a.actionName, appCode: a.appCode})),
            null,
            2
         );
      }
   },

   created() {
      this.init();
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>
