<template>
   <!-- HISTORY: no version
   - V230202.1: Removed .tsv extension + Modified some styles.
   - 01/24/23(B0.4): Added v-on to v-btn and commented <template> in <dialog>.
   - 12/15/20(B0.3): Commented Multiple Files switch and changed col size accordingly.
   - 10/23/20(B0.2): In submit(), added extension to the file name based on the selected delimiter if user didn't specify one.
   - 08/21/20(B0.1): Added logic to handle tooltip functionality for unauthorized users.
   -->
<div>
   <v-col>
      <v-tooltip v-model="showTooltip" top>
         <template v-slot:activator="{ on }">
            <v-btn small
               v-on="on"
               :color="btnColor"
               :disabled="isBtnDisabled"
               :loading="isBtnLoading"
               @click="btnClicked"
            >{{ btnLabel }}
               <v-icon right dark>cloud_download</v-icon>
            </v-btn><br>
         </template>
         <span>{{noPermissionMessage}}</span>
      </v-tooltip>               
   </v-col>

   <v-dialog v-model="dialog" persistent no-click-animation max-width="640px">
      <!-- <template v-slot:activator="{ on }"></template> -->
      <v-card>
         <v-card-title class="title grey--text darken-4 font-weight-bold pb-0">{{ dialogTitle }}</v-card-title>

         <v-card-text class="pb-0">
            <v-container class="pb-0">
               <v-form ref="myForm" v-model="isMyFormValid" lazy-validation>
                  <v-row>
                     <v-col cols="12" xs="12" sm="12" md="8" lg="9" class="mt-0 pt-5">
                        <v-text-field counter dense persistent-hint
                           ref="fileName"
                           prepend-icon="folder"
                           v-model="downloadSettings.fileName"
                           :label="fileNameLabel"
                           :placeholder="fileNamePlaceholder"
                           :hint="fileNameHint"
                           :outlined="fileNameOutlined"
                           :rules="fileNameRules"
                           @input="fileNameChanged"
                        ></v-text-field>
                     </v-col>
                     <v-col cols="12" xs="12" sm="12" md="4" lg="3" class="pt-5 pb-0 mb-0">
                        <v-select dense hide-selected
                           ref="delimiter"
                           label="Delimiter:"
                           v-model="downloadSettings.delimiter"
                           :items="delimiterItems"
                        ></v-select>
                     </v-col>
                  </v-row>

                  <v-row>
                     <v-col cols="12" xs="12" sm="12" md="4" lg="3" class="py-0 my-0">
                        <v-switch dense small hide-details
                           v-model="downloadSettings.addQuotes"
                        >
                           <template v-slot:label>
                              <div class="caption">Add Quotes</div>
                           </template>
                        </v-switch>
                     </v-col>
                     <!-- <v-col cols="12" xs="12" sm="12" md="6" lg="3" class="py-0 my-0">
                        <v-switch dense small hide-details
                           v-model="downloadSettings.multipleFiles"
                        >
                           <template v-slot:label>
                              <div class="caption">Multiple Files</div>
                           </template>
                        </v-switch>
                     </v-col> -->
                     <v-col cols="12" xs="12" sm="12" md="4" lg="3" class="py-0 my-0">
                        <v-switch dense small hide-details
                           v-model="downloadSettings.zipped"
                        >
                           <template v-slot:label>
                              <div class="caption">Zip File(s)</div>
                           </template>
                        </v-switch>
                     </v-col>
                     <v-col cols="12" xs="12" sm="12" md="4" lg="3" class="pt-0 mt-0">
                        <v-switch dense small hide-details
                           v-model="downloadSettings.overwrite"
                        >
                           <template v-slot:label>
                              <div class="caption">Overwrite</div>
                           </template>
                        </v-switch>
                     </v-col>
                  </v-row>

               </v-form>
            </v-container>
         </v-card-text>

         <v-card-actions class="pr-4">
            <div class="flex-grow-1"></div>
            <v-btn text
               color="blue darken-1"
               @click="cancel"
            >Cancel</v-btn>
            <v-btn text
               class="pr-4"
               color="blue darken-1"
               :disabled="false"
               @click="submit"
            >Submit</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</div>
</template>

<script>

export class DownloadSettings {
   constructor() {
      this.fileName = '';
      this.delimiter = ',';
      this.multipleFiles = false;
      this.zipped = false;
      this.addQuotes = false;
      this.overwrite = false;
   }
}

export default {
   name: "BtDownloadSettings",

   props: {
      btnLabel: {
         type: String,
         default: "Download"
      },
      btnColor: {
         type: String,
         default: "gray darken-1"
      },
      dialogTitle: {
         type: String,
         default: "Download Settings"
      },
      fileNameLabel: {
         type: String,
         default: "File Name"
      },
      fileNamePlaceholder: {
         type: String,
         default: ""
      },
      fileNameOutlined: {
         type: Boolean,
         default: false
      },
      fileNamePattern: {
         type: RegExp,
         default: () => { return /^([A-Za-z0-9'\!\-\_\.\*\(\))]){5,100}$/ }
      },
      fileNameHint: {
         type: String,
         default: "valid characters: A-Z a-z 0-9 ( ' ! - _ . * )"
      },
      message: {
         type: String,
         default: ""
      },
      loadingMessage: {
         type: String,
         default: "please wait..."
      },
      isAuthorized: {
         type: Boolean,
         required: true
      },
      noPermissionMessage: {
         type: String,
         default: "Contact your admin to see how you can get this feature."
      }
   },

   data() {
      return {
         dialog: false,
         rules: {
            required: value => !!value || "Value is required!",
            fileName: value => this.fileNamePattern.test(value) || "Value is invalid!",
         },
         downloadSettings: new DownloadSettings(),
         fileNameRules: [],
         isMyFormValid: true,
         isBtnDisabled: false,
         isBtnLoading: false,
         myMsg: this.message,
         delimiterItems: [
            { text: "Comma (,)", value: "," },
            { text: "Pipe (|)", value: "|" },
            { text: "Tab (\\t)", value: "\\t" }
         ],
         showTooltip: false
      };
   },

   watch: {
      dialog(val) {
         if (val) {
            setTimeout(() => {
               this.downloadSettings = new DownloadSettings();
               this.$refs.fileName.focus();
            }, 10);
         } else {
            setTimeout(() => {
               this.fileNameRules = [];
            }, 100);
         }
      },

      message(msg) {
         this.myMsg = msg;
         this.isBtnLoading = false;
         this.isBtnDisabled = false;
      }
   },

   methods: {
      btnClicked () {
         if (this.isAuthorized)
            this.dialog = true;
         else {
            this.showTooltip = true;
            setTimeout(() => {
               this.showTooltip = false;
            }, 5000);
         }
      },

      fileNameChanged () {
         if (this.fileNameRules.length === 0) {
            this.fileNameRules.push(this.rules.required);
            this.fileNameRules.push(this.rules.fileName);
         }
      },

     cancel () {
        //this.downloadSettings = new DownloadSettings();
        this.dialog = false;
     },
     async submit() {
         this.isMyFormValid = true;
         this.fileNameChanged();

         setTimeout(() => {
            if (this.$refs.myForm.validate()) {
               const fileName = this.downloadSettings.fileName.split('.');
               // if (fileName.length === 1 || (fileName.length === 2 && !fileName[1])) {
               if (fileName.length === 1 || !fileName[fileName.length - 1]) {
                  // let ext;
                  // if (this.downloadSettings.delimiter === ',') ext = '.csv';
                  // else if (this.downloadSettings.delimiter === '|') ext = '.txt';
                  // else ext = '.tsv';
                  const ext = this.downloadSettings.delimiter === ',' ? '.csv' : '.txt';
                  this.downloadSettings.fileName = fileName[0] + ext;
               }
               this.$emit("downloadSettings-changed", this.downloadSettings);
               this.myMsg = this.loadingMessage;
               this.isBtnDisabled = true;
               this.isBtnLoading = true;
               this.dialog = false;
            }
         }, 10);
      },
   },
}

</script>
