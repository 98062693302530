<template>
    <v-container style="height: 100%; width: 100%;background: white;">
        <v-row class="mt-0">
            <v-col class="pt-2" cols=9 style="display: flex;">
                <template v-if="standAlone">
                    <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
                        <span><v-icon>schedule_send</v-icon></span>
                        <span style="vertical-align: middle;" class="pl-1">Push Message History</span>
                    </h1>
                    <v-text-field v-model="passKey" placeholder="Pass Key" hide-details dense style="margin-left: 10px; max-width: 200px;"></v-text-field>
                    <v-btn :disabled="passKey==''" small class="btn-icon mt-1 mr-2" @click="loadPushMessage(passKey)" style="margin-left: 10px;">Load</v-btn>
                </template>
            </v-col>
            <v-col cols=3 class="text-right">
                <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on}">
                        <v-btn v-on="on" small class="btn-icon mt-1 mr-2" @click="loadPushMessage()"><v-icon>refresh</v-icon></v-btn>
                    </template>
                    Reload
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row class="py-1">
            <v-col cols=12>
                <v-data-table v-model="selected" :loading="pushMessageDataLoading" dense :hide-default-footer="false" :headers="pushMessageDataTable.headers" :items="pushMessageDataTable.items" :items-per-page="5" :single-select="true" sort-by="dateTime" sort-desc class="elevation-1">
                    <template v-slot:[`item.dateTime`]="props">
                        <div>{{ fromNow(props.item.dateTime) }}<!-- | {{ props.item.dateTime }} --></div>
                        <!-- {{props.item.lastModified}} -->
                    </template>
                    <template v-slot:[`item.message`]="props">
                        <div style="white-space: pre-wrap;">{{ props.item.message }}</div>
                    </template>
                    <template v-slot:[`item.target`]="props">
                        <div>{{ displayTarget(props.item.target) }}</div>
                    </template>
                    <template v-slot:[`item.action`]="props">
                        <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                            <template v-slot:activator="{ on , attrs}">
                                <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="pushMessageClick(props)">
                                    send
                                </v-icon> 
                            </template>
                            <span>Send message</span>
                        </v-tooltip>
                    </template>                        
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<style>
</style>
<script>
 import axios from "axios";
 import utils from '../services/KCUtils.js';
 import moment from 'moment-timezone';
var crypto = require('crypto');

const NAME = "KCPushMessageHistory";

const serverMode="pass";
import csvUtils from "../services/KCCSVUtils.js";
import kcMixins from "../services/KCMixins.js";


export default {
    name: NAME,
    mixins: [kcMixins],
    components: {
    },
    props: {
        masterHeader: {
            type: Object,
            default() {
                return {}
            }
        },
        showCreateAction: {
            default: false,
        },
        standAlone: {
            default: false,
        }
    },
    data() {
        return {
            /* mixins start */
            appName: "passUI",
            /* mixins end */
            davinciUrl: null,
            pushMessageDataTable: {
                headers: [
                    { text: "Schedule Date", value: "dateTime", sortable: true },
                    { text: "Message", value: "message", sortable: true },
                    { text: "Device", value: "fromDevice", sortable: false },
                    { text: "Target", value: "target", sortable: false },                    
                    { text: "", value: "action", sortable: false },

                ],
                items: [],
            },
            selected: [],
            pushMessageDataLoading: false,
            passKey: '2X76AtU=',
            targets: [{"value": "all", "text": "Everyone who have the pass installed"}, {"value": "visitor", "text": "Specific visitors to your microsite who have the pass installed"}, {"value": "geofencing", "text": "People within a geofenced area"}],
        };
    },
    computed: {
        account: function () {
            //console.log('user object: ', this.$store.getters.user);
            return this.$store.getters.user.account;
        },
    },
    methods: {
        async loadPushMessage(passKey=''){
            this.pushMessageDataTable.items = [];
            if (passKey != '') this.passKey = passKey;
            if (this.passKey == '') return;
            this.pushMessageDataLoading = true;
            let ret = await this.appUIGet(this.app.passUI.pushMessageHistory,{name: this.passKey},{},this.masterHeader,this.getListServiceAxios());
            console.log(ret);
            let data = [];
            for(let x in ret){
                let pushMessageData = ret[x].definition;
                let scheduleDT = moment.tz(pushMessageData['sendDate']+' '+pushMessageData['sendTime'],'YYYY-MM-DD hh:mmA',pushMessageData.timezone);
                data.push({
                    //dateTime: scheduleDT.tz(pushMessageData.timezone).format('YYYY-MM-DD HH:mm')+' '+pushMessageData.timezone,
                    dateTime: scheduleDT.utc().format("YYYY-MM-DD[T]HH:mm:ss[Z]"),
                    message: pushMessageData.line1Text+"\n"+pushMessageData.line2Text+'\n'+pushMessageData.line3Text,
                    fromDevice: (pushMessageData.fromDevice == 'mobile') ? 'Mobile' : 'Desktop',
                    target: pushMessageData.target,
                    pushMessage: pushMessageData
                });
            }
            // fake data
            // let data =  [
            //     {dateTime: "date time 1",message: "message 1", type:"type", status:"status"},
            //     {dateTime: "date time 2",message: "message 2", type:"type", status:"status"},
            //     {dateTime: "date time 3",message: "message 3", type:"type", status:"status"},
            // ];
            // for(let x in data){

            // }
            this.pushMessageDataLoading = false;
            this.pushMessageDataTable.items = data;
        },
        async deleteClick(prop){
            console.log("deleteClick",prop);
            let ret = await this.appUIDelete(prop.item._id);
            console.log(ret);
            await this.loadPushMessage();
        },
        async pushMessageClick(props){
            console.log('pushMessageClick: ', props);
            let pushMessageData = props.item.pushMessage;
            if (pushMessageData.passKey == '') {
                this.$swal.fire('Pass Key not found.');
                return;
            }
            this.$emit("pushMessage", pushMessageData);
        },
        
        getListServiceAxios(){
            if(Object.keys(this.masterHeader).length ==0){
                return null;
            }else{
                return this.getCleanAxios();
            }
        },
        fromNow(date){
            return utils.fromNow(date);
        },
        displayTarget(target) {
            //if (!(redirect && redirect=='1' && redirect=='2' && redirect=='3')) return '';
            let index = this.targets.findIndex(option => option.value == target);
            return (index>=0 && this.targets[index].text) ? this.targets[index].text : '';
        }, 
    },
    created() {
        this.davinciUrl = this.$store.getters.deployment.dvURL;
        // alert(`in created(): davinciUrl=${this.davinciUrl}`);
        if (this.davinciUrl.indexOf("davincistage") >= 0)
            this.server = "stage";
        else
            this.server = "prod";
        // this.endPoint = config.endPoints.mindfireOne + `/api/card/${this.server}`;
    },
    destroyed() {
    },
    async mounted() {
    },
    beforeUnmount() { 
    },
    watch: {
        account: {
            async handler(val) {
            },
            deep: true
        },
    },
};
</script>