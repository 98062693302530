<template>
    <v-dialog v-model="dialog" width="800">
        <v-sheet class="rounded-lg mx-auto pa-5" elevation="12" height="500" width="100%">
            <v-row>
                <v-col xl="2" lg="2">FileName:</v-col>
                <v-col xl="10" lg="10"><span style="display:contents;font-size: 0.8em;">{{url}}</span></v-col>
            </v-row>
            <v-row>
                 <v-col class="text-right">
                    <v-btn @click="addProcessClick()">Add Process</v-btn>
                 </v-col>
                
            </v-row>
        </v-sheet>
        <KCAddCSVProcessDialog v-model="addDlg"></KCAddCSVProcessDialog>
    </v-dialog>
</template>
<script>
import axios from "axios";
import utils from '../services/KCUtils.js';
import KCAddCSVProcessDialog from "./KCAddCSVProcessDialog.vue";
export default {
    name: "Kwang-CreateComponent",
    components: {
        KCAddCSVProcessDialog,
    },
    props: {
        url: {
            default: undefined,
        },
        value: {    // v-model need this 
            default: false,
        },
    },
    data() {
        return {
            dialog: this.value,     // internal var to control dialog visibility
            addDlg: false,
        };
    },
    created() {
        console.log("KCCreateComponent:created()",this.value,this.url)
    },    
    methods: {
        addProcessClick() {
            this.addDlg = true;
        }
    },
    watch: {
        // whenever question changes, this function will run
        value(newValue, oldValue) {     // v-model need this  to detect change
            //console.log("value change")
            this.dialog = newValue;
        },
        dialog(newValue, oldValue){    
            this.$emit('input', newValue)  // v-model need this to tell parent that value have change
            //console.log("dialog change",this.dialog)
        }
    },
}
</script>