<template>

    <div class="file">
        <!-- <form @submit.prevent="onSubmit" enctype="multipart/form-data" >
            <div class="fields">
                <label>Upload File</label><br>
                <input 
                    type="file"
                    ref="file"
                    @change="onSelect"
                />
            </div>
            <div class="fields">
                <button>Submit</button>
            </div>
            <div class="message">
                <h5>{{ message }}</h5>
            </div>
        </form> -->

        <v-container>
            <v-layout row align-center>
                <upload-btn ref="uploadButton" @file-update="update" title="File to Upload" >
                    <template slot="icon-left">
                        <v-icon left>add</v-icon>
                    </template>
                </upload-btn>
                <v-btn color="success" @click="onSubmit" :disabled="inProgress || !file">Submit</v-btn>
                <v-progress-circular v-if="inProgress"
                    
                    color="primary"
                    :value="progress"
                >{{ progress }}</v-progress-circular>
                <h5>{{ message }}</h5>
            </v-layout>
        </v-container>

    </div>
    
</template>

<script>
import axios from 'axios';
import UploadButton from 'vuetify-upload-button';

export default {
    name: 'FileUpload',
    components: {
        'upload-btn': UploadButton
    },
    props: {
        fileName: String,
        isPublic: Boolean,
        folder: String,
        serviceType: {
            type: Number,
            required: true
        }
    },
    data: function() {
        return {
            file: '',
            message: '',
            inProgress: false,
            progress: 0
        }
    },
    methods: {
        update: function(file){
            this.file = file;
            if(file){
                this.message = "";
                console.log(this.isPublic);
                console.log(this.folder);
                console.log(this.fileName);
            }
        },

        // onSelect: function() {
        //     const file = this.$refs.file.files[0];
        //     this.file = file;
        //     this.message = "";
        //     console.log(this.file);
        // },

        onSubmit: async function() {
            const formdata = new FormData();
            formdata.append('file', this.file);

            try{
                
                const result = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/app-data/v1/uploads/' + this.serviceType + '/'  + ((this.fileName) ? this.fileName : this.file.name) + "?type=" + this.file.type + ((this.folder) ? "&folder=" + this.folder : "") + ((this.isPublic) ? "&isPublic=" + this.isPublic : ""), method: 'GET' })
                
                this.inProgress = true;
                
                let xhr = new XMLHttpRequest();
                let self = this;
                xhr.upload.addEventListener("progress", function(evt){
                    if (evt.lengthComputable) {
                        //console.log("add upload event-listener " + evt.loaded + "/" + evt.total);
                        self.progress = ((evt.loaded / evt.total) * 100).toFixed(0);
                    }
                }, false);

                xhr.onloadstart = function (e) {
                    console.log("start")
                }
                xhr.onloadend = function (e) {
                    self.$emit("uploaded", {fileName: self.file.name, fileSize: self.file.size, fileType: self.file.type});
                    self.inProgress = false;
                    let elapsed = new Date().getTime() - start;
                    self.message = "Uploaded " + (self.file.size/1024).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " KB in " + elapsed/1000 + " seconds.";
                    try{
                        self.$refs.uploadButton.clear();
                    }
                    catch(err){

                    }
                    self.progress =0;
                    console.log("end")
                }
                let start = new Date().getTime();
                xhr.open('PUT', result.data.url);
                xhr.send(formdata);

                // await fetch(result.data.url, {
                //     method: 'PUT',
                //     body: formdata
                // });

                
                
            }
            catch(err){
                console.log(err);
                this.message = "Something went wrong!"
            }

            //this.$refs.uploadButton.clear();

        }
    }
    
}
</script>

