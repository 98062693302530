<!-- HISTORY:
   V240805.1: Moved accounts population logic from watch.tab() into getTotalImports().
   V240802.1: Set dp-max-range to 365 (from 31) + Added recordsDeleted and actualRecords fields to both tables.
   V240229.1: Fixed Totals row alignment that was shifted one column to the left.
   V240110.2: Reset expandedItem in getReports() to closed the expanded item.
   V240110.1: Made the total import table expandable to show events data.
   V231130.1: Added totalDeletes and totalDeletesDelete to the table.
   V230922.1: Added logic to hide date range dropdown for sftp and set it automatically to 2 years.
   V230912.1: Added new headers to the totals table: db_size_GB, db_records and db_sharded.
   V230906.1: Made list table unsortable.
   V230901.1: In getListImportsCount(), reset pagination.
   V230831.1: Sorted accounts list.
   V230830.1: Added List tab and moved search inside the totals tab.
   V230825.1: Added search and total row.
   V230824.2: Set defaults for the criteria.
   V230824.1: 1st release.
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">file_download</v-icon>
            <span>Imports</span>
         </h1>
      </v-card-title>

      <v-card-text class="ml-2 px-5 pb-0">
         <v-form lazy-validation
            ref="mainForm"
            v-model="isMainFormValid"
         >
            <v-row>
               <v-col xs="12" sm="12" md="3" class="py-2">
                  <v-select dense persistent-hint hide-selected required
                     ref="status"
                     placeholder="select a status..."
                     hint="* Status"
                     :items="statusItems"
                     :prepend-inner-icon="statusIcon"
                     v-model="status"
                     @change="statusChanged"
                  >
                     <!-- <template v-slot:prepend-inner-icon>
                        <v-icon :color="statusIcon.color"
                        >{{statusIcon.icon}}
                        </v-icon>
                     </template> -->
                     <!-- <template v-slot:prepend>
                        <v-icon :color="statusIcon.color"
                        >{{statusIcon.icon}}
                        </v-icon>
                     </template> -->
                  </v-select>
               </v-col>
               <v-col v-show="!isSftp"
                  xs="12" sm="12" md="3" class="py-2">
                  <bt-date-picker
                     :debug="debug"
                     :dd-custom-label="true"
                     dd-label="select a date range..."
                     :dd-monthly-items="datePickerMonthlyItems"
                     dd-value="TM"
                     :dp-no-title="false"
                     :dp-max-range="365"
                     v-model="dateRange"
                     @date-range-change="dateRangeChanged"
                  ></bt-date-picker>
               </v-col>
               <v-col xs="12" sm="12" md="2" class="py-5 mt-1">
                  <v-btn small
                     color="primary"
                     :disabled="!isCriteriaChanged"
                     @click="getReports()"
                  >Get Report
                     <v-icon right dark>summarize</v-icon>
                  </v-btn>
               </v-col>
            </v-row>
         </v-form>
      </v-card-text>

      <v-card-text>
         <v-tabs
            class="elevation-2"
            background-color="grey lighten-2 accent-4"
            slider-color="black"
            v-model="tab"
         >
            <v-tab>Totals</v-tab>
            <v-tab>List</v-tab>

            <v-tabs-items v-model="tab">
               <v-tab-item class="pt-5"><!-- Totals -->
                  <v-row class="pb-5">
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-text-field clearable
                           class="py-0"
                           append-icon="search"
                           label="Search"
                           :disabled="!totalImports.length"
                           v-model="searchString"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header show-expand single-expand
                     class="elevation-1"
                     :expanded="expandedItem"
                     :footer-props="footerPropsTotalImports"
                     :headers="totalHeaders"
                     :hide-default-footer="totalImports.length <= 5"
                     item-key="accountid"
                     :items="totalImports"
                     :items-per-page="10"
                     :loading="loadingTotalImports"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsTotalImports"
                     :search="searchString"
                     @current-items="tableCurrItems"
                     @update:expanded="importExpanded"
                  >
                     <template v-slot:[`item.totalImports`]="{item}">
                        {{ formatNumber(item.totalImports) }}
                     </template>
                     <template v-slot:[`item.totalDeletes`]="{item}">
                        {{ formatNumber(item.totalDeletes) }}
                     </template>
                     <template v-slot:[`item.actualRecords`]="{item}">
                        {{ formatNumber(item.actualRecords) }}
                     </template>
                     <template v-slot:[`item.db_records`]="{item}">
                        {{ formatNumber(item.db_records) }}
                     </template>
                     <!-- <template v-slot:[`item.sharded`]="{item}">
                        {{ item.sharded.toString() }}
                     </template> -->
                     <template v-slot:[`expanded-item`]>
                        <td>&nbsp;</td>
                        <td dense
                           class="py-4"
                           valign="top"
                           colspan="2"
                        >
                           <v-data-table dense fixed-header
                              class="elevation-1 font-italic"
                              :footer-props="footerPropsEvents"
                              :headers="eventHeaders"
                              :hide-default-footer="eventsData.length <= 5"
                              :items="eventsData"
                              :items-per-page="5"
                              :loading="loadingEventsData"
                              :loading-text="$t('loading-text')"
                              :no-data-text="$t('no-data-text', { value: 'events' })"
                              :no-results-text="$t('no-results-text', { value: 'events' })"
                              :options.sync="optionsEvents"
                           >
                              <template v-slot:[`item.count`]="{item}">
                                 {{ formatNumber(item.count) }}
                              </template>
                           </v-data-table>
                        </td>
                     </template>
                     <template v-slot:[`body.append`] v-if="totalImportsTotal">
                        <tr class="font-weight-bold">
                           <td>Totals:</td>
                           <td colspan="5" align="right">{{formatNumber(totalImportsTotal)}}</td>
                           <td align="right">{{formatNumber(totalDeletesTotal)}}</td>
                           <td align="right">{{formatNumber(totalImportsTotal - totalDeletesTotal)}}</td>
                           <td colspan="3"></td>
                        </tr>
                     </template>
                  </v-data-table>
               </v-tab-item>

               <v-tab-item class="pt-5"><!-- List -->
                  <v-row class="pb-5">
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-autocomplete clearable dense hide-selected persistent-hint
                           append-icon="search"
                           placeholder="search for a specific account"
                           :hint="`${accounts.length} accounts`"
                           :items="accounts"
                           v-model="selectedAccount"
                           @change="getListReport"
                        ></v-autocomplete>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header show-expand single-expand
                     class="elevation-1"
                     item-key="_id"
                     :footer-props="footerPropsListImports"
                     :headers="listHeaders"
                     :hide-default-footer="listImportsCount.length <= 5"
                     :items="listImports"
                     :items-per-page="10"
                     :loading="loadingListImports"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsListImports"
                     :server-items-length="listImportsCount"
                  >
                     <template v-slot:[`item.processedRecords`]="{item}">
                        {{ formatNumber(item.processedRecords) }}
                     </template>
                     <template v-slot:[`item.recordsDeleted`]="{item}">
                        {{ formatNumber(item.recordsDeleted) }}
                     </template>
                     <template v-slot:[`item.actualRecords`]="{item}">
                        {{ formatNumber(item.actualRecords) }}
                     </template>
                     <template v-slot:[`item.creationDate`]="{ item }">
                        {{ formatDate(item.creationDate) }}
                     </template>
                     <template v-slot:expanded-item="{ item }">
                        <td>&nbsp;</td>
                        <td colspan="4" class="py-2" valign="top" dense>
                           <ul class="pl-3">
                              <li>
                                 <span class="expanded-header">ID: </span>
                                 <span class="expanded-content">{{item._id}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Creator: </span>
                                 <span class="expanded-content">{{item.creator}}</span>
                              </li>
                           </ul>
                        </td>
                        <td colspan="3" class="py-2" valign="top" dense>
                           <ul class="pl-3">
                              <li>
                                 <span class="expanded-header">Start Date: </span>
                                 <span class="expanded-content">{{formatDate(item.startDate)}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Last Activity: </span>
                                 <span class="expanded-content">{{formatDate(item.lastActivityDate)}}</span>
                              </li>
                           </ul>
                        </td>
                     </template>
                  </v-data-table>
               </v-tab-item>
            </v-tabs-items>
         </v-tabs>
      </v-card-text>
   </v-card>
</v-container>
</template>

<script>
import BtDatePicker from "./BtDatePicker.vue";
import { APIService } from '../services/as-api-service.js';
import { format, parseISO } from "date-fns";
// import { sleep } from '../mixins/bt-mixin.js';

const NAME = "AsImports";
const TABS = { totals: 0, list: 1 };

function _compareItems (e1, e2) {
   return e1.text > e2.text ? 1 : -1;
}

export default {
   name: NAME,

   components: {
      BtDatePicker
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         statusItems: [ 'waiting', 'processing', 'completed', 'recovery', 'rejected', 'sftp', 'sftp-processing' ],
         datePickerMonthlyItems: [
            { text: "Current Month", value: "TM" },
            { text: "Last Month", value: "LM" }
         ],
         totalHeaders: [
            { text: 'Account Name', value: 'accountname', align: 'left', sortable: true },
            { text: 'Account ID', value: 'accountid', align: 'left', sortable: true },
            { text: 'Parent Name', value: 'parentname', align: 'left', sortable: true },
            { text: 'Parent ID', value: 'parentid', align: 'left', sortable: true },
            { text: 'Total Imports', value: 'totalImports', align: 'right', sortable: true },
            { text: 'Total Deletes', value: 'totalDeletes', align: 'right', sortable: true },
            { text: 'Actual Records', value: 'actualRecords', align: 'right', sortable: true },
            { text: 'DB Size (GB)', value: 'db_size_GB', align: 'right', sortable: true },
            { text: 'DB Records', value: 'db_records', align: 'right', sortable: true },
            { text: 'Sharded?', value: 'db_sharded', align: 'left', sortable: true }
         ],
         listHeaders: [
            { text: 'Account Name', value: 'accountname', align: 'left', sortable: false },
            { text: 'Account ID', value: 'databaseName', align: 'left', sortable: false },
            { text: 'Parent Name', value: 'parentname', align: 'left', sortable: false },
            { text: 'Parent ID', value: 'parentid', align: 'left', sortable: false },
            { text: 'Name', value: 'name', align: 'left', sortable: false },
            { text: 'Processed Records', value: 'processedRecords', align: 'right', sortable: false },
            { text: 'Deleted Records', value: 'recordsDeleted', align: 'right', sortable: false },
            { text: 'Actual Records', value: 'actualRecords', align: 'right', sortable: false },
            { text: 'Creation Date', value: 'creationDate', align: 'left', sortable: false }
         ],
         apiService: null,
         isMainFormValid: false,
         isCriteriaChanged: false,
         status: 'completed',
         statusIcon: '',
         dateRange: [],
         tab: null,
         loadingTotalImports: false,
         searchString: '',
         optionsTotalImports: {},
         lastItemsPerPage: 10,
         footerPropsTotalImports: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         totalImports: [],
         totalDeletes: [],
         totalImportsTotal: 0,
         totalDeletesTotal: 0,
         loadingListImports: false,
         footerPropsListImports: {
            itemsPerPageOptions: [5, 10, 15, 20],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         optionsListImports: {},
         listImportsCount: 0,
         listImports: [],
         accounts: [],
         selectedAccount: 0,
         currDateRange: null,
         footerPropsEvents: {
            // itemsPerPageOptions: [5, 10, 15, 20],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         optionsEvents: {},
         expandedItem: [],
         eventHeaders: [
            { text: 'Event Code', value: "eventCode", align: 'left', sortable: true },
            { text: 'Count', value: 'count', align: 'right', sortable: true }
         ],
         loadingEventsData: false,
         eventsData: [],
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      isSftp() {
         return this.status.startsWith('sftp');
      }
   },

   watch: {
      token() {
         this.init();
      },

      async tab(val) {
         if (val === TABS.list && !this.listImportsCount && this.totalImports.length) {

            await this.getListReport();

            /* V240805 */
            // const accounts = [];
            // this.totalImports.forEach(imprt => {
            //    accounts.push({
            //       text: `${imprt.accountname}: ${imprt.accountid}`,
            //       value: imprt.accountid
            //    });
            // });
            // this.accounts = accounts.sort(_compareItems);
         }
      },

      optionsListImports: {
         handler(val) {
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
            }
            this.getListImports();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V240805.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         // _alert('in init(): token=' + this.token);
         if (this.token) {
            this.loadingOffers = true;
            this.apiService = new APIService(this.token, this.debug, this.isActualEndpoint);
            this.statusChanged(this.status);
            setTimeout(() => {
               this.getTotalImports();
            }, 100);
         }
      },

      async statusChanged(val) {
         // alert(`in statusChanged(): val=${val}`);
         this.statusIcon = this.getIcon(val).icon;
         this.isCriteriaChanged = true;
         // if (this.isSftp) {
         //    await this.getReports();
         // }
      },

      dateRangeChanged(val) {
         // alert(`in dateRangeChange(): val=${JSON.stringify(val)}`);
         this.isCriteriaChanged = true;
      },

      getIcon(status) {
         switch (status) {
            case 'waiting':
               return { color: 'grey', icon: 'schedule' };
            case 'processing':
               return { color: 'blue', icon: 'hourglass_empty' };
            case 'sftp':
               return { color: 'grey lighten-2', icon: 'cloud' };  //mdi-cloud-outline
            case 'sftp-processing':
               return { color: 'blue', icon: 'cloud_off' }; //mdi-cloud-off-outline
            case 'completed':
               return { color: 'green', icon: 'done' };
            case 'recovery':
               return { color: 'orange', icon: 'restore' };
            case 'rejected':
               return { color: 'red', icon: 'clear' };
            default:
               return { color: '', icon: '' };
         }
      },

      async getTotalImports() {
         // alert(`in getTotalImports(): status=${this.status}\ndateRange=${JSON.stringify(this.dateRange)}`);
         this.loadingTotalImports = true;
         this.totalImports = [];
         this.totalDeletes = [];

         let resultImports = await this.apiService.getTotalImports(this.status, this.getDateRange());
         if (resultImports.logout)
            this.logout();
            
         if (!resultImports.message) {
            let resultDeletes = await this.apiService.getTotalDeletes(this.status, this.dateRange);
            if (resultDeletes.logout)
               this.logout();
            else if (!resultDeletes.message) {
               resultImports.data.forEach(imprt => {
                  imprt.db_sharded = imprt.db_sharded ? 'true' : 'false';
                  imprt.actualRecords = imprt.totalImports;
                  const totalDelete = resultDeletes.data.find(dlt => dlt.accountid === imprt.accountid);
                  if (totalDelete) {
                     imprt.totalDeletes = totalDelete.totalDeletes;
                     imprt.actualRecords -= imprt.totalDeletes;
                  }
               });
               this.totalImports = resultImports.data;

               //V240805: Moved this block from watch.tab() to here
               const accounts = [];
               this.totalImports.forEach(imprt => {
                  accounts.push({
                     text: `${imprt.accountname}: ${imprt.accountid}`,
                     value: imprt.accountid
                  });
               });
               this.accounts = accounts.sort(_compareItems);
            }
         }

         if (!this.sftpDateRange) {
            const currYear = this.dateRange[1].split('-')[0];
            this.sftpDateRange = [
               this.dateRange[1].replace(currYear, Number(currYear - 2)),
               this.dateRange[1]
            ];
            // alert('sftpDateRange='+JSON.stringify(this.sftpDateRange));
         }

         this.isCriteriaChanged = false;
         this.loadingTotalImports = false;
      },

      formatNumber(number, maxFractionDigits) {
         if (number)
            return new Intl.NumberFormat('en-US', 
               { 
                  maximumFractionDigits: maxFractionDigits || 0
               }).format(number);
         else
            return 0;
      },

      tableCurrItems(val) {
         this.totalImportsTotal = 0;
         this.totalDeletesTotal = 0;
         if (this.searchString) {
            if (!this.lastItemsPerPage) {
               this.lastItemsPerPage = this.optionsTotalImports.itemsPerPage;
               this.optionsTotalImports.itemsPerPage = -1;
               this.footerPropsTotalImports.disableItemsPerPage = true;
            }
            if (val && val.length) {
               val.forEach(v => {
                  this.totalImportsTotal += v.totalImports;
                  if (v.totalDeletes)
                     this.totalDeletesTotal += v.totalDeletes;
               });
            }
         } else {
            if (this.lastItemsPerPage) {
               this.footerPropsTotalImports.disableItemsPerPage = false;
               this.optionsTotalImports.itemsPerPage = this.lastItemsPerPage;
               this.lastItemsPerPage = 0;
            }
            this.totalImports.forEach(ti => {
               this.totalImportsTotal += ti.totalImports;
               if (ti.totalDeletes)
                  this.totalDeletesTotal += ti.totalDeletes;
            });
         }
      },

      async getListImportsCount() {
         this.loadingListImports = true;
         let result = await this.apiService.getListImportsCount(this.status, this.getDateRange(), this.selectedAccount);
         if (result.logout)
            this.logout();

         this.listImportsCount = result.message ? 0 : result.data;
         this.optionsListImports.page = 1;
         // this.footerPropsListImports.disableItemsPerPage = this.listImportsCount <= 5;
      },

      async getListImports() {
         // alert(`in getListImports(): status=${this.status}\ndateRange=${JSON.stringify(this.dateRange)}`);
         this.loadingListImports = true;
         this.listImports = [];
         if (this.listImportsCount) {
            let result = await this.apiService.getListImports(this.status, this.getDateRange(), this.selectedAccount, this.optionsListImports.page, this.optionsListImports.itemsPerPage);
            if (result.logout)
               this.logout();
               
            if (!result.message) {
               this.listImports = [];
               result.data.forEach(lImport => {
                  //NOTE: keep == as accountid is number but databaseName is string.
                  const tImport = this.totalImports.find(imprt => imprt.accountid == lImport.databaseName);
                  lImport.accountname = tImport.accountname;
                  lImport.parentname = tImport.parentname;
                  lImport.parentid = tImport.parentid;
                  lImport.actualRecords = lImport.processedRecords - lImport.recordsDeleted
                  this.listImports.push(lImport);
               });
            }
         }

         this.isCriteriaChanged = false;
         this.loadingListImports = false;
      },

      async getReports() {
         await this.getTotalImports();
         this.expandedItem = [];
         this.listImportsCount = 0;
         this.listImports = [];
         if (this.totalImports.length && this.tab === TABS.list) {
            await this.getListReport();
         }
      },

      formatDate(date) {
         if (date) {
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            return formatteddate;
         }
      },

      async getListReport() {
         await this.getListImportsCount();
         await this.getListImports();
      },

      getDateRange() {
         return this.isSftp ? this.sftpDateRange : this.dateRange;
      },

      async importExpanded(items) {
         // _alert('in importExpanded(): offer=' + JSON.stringify(offer));
         if (items.length === 0) return;

         this.loadingEventsData = true;
         // const date = convertToISODate(new Date(), true);
         let result = await this.apiService.getEvents(items[0].accountid, this.getDateRange());
         if (result.logout)
            this.logout();
         else if (result.message) {
            this.eventsData = [];
         } else {
            this.eventsData = result.data;
         }
         this.loadingEventsData = false;
         this.log('in importExpanded(): eventsData=' + JSON.stringify(this.eventsData));
      }
   },

   created() {
      this.init();
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>