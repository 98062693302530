<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>List Service: Configuration</v-card-title>
      <v-card-text>
         <v-row>
            <v-col xs="12" sm="12" md="4">
               <v-expansion-panels multiple focusable v-model="panelPurlCols">
                  <v-expansion-panel readonly>
                     <v-expansion-panel-header hide-actions>
                        <strong>PURL Columns</strong>
                        <v-spacer></v-spacer>
                        # of Fields: {{purlColumns.length}}
                     </v-expansion-panel-header>
                     <v-expansion-panel-content>
                        <bt-dynamic-text-fields
                           v-model="purlColumns"
                           :field-dense="true"
                           field-label=""
                           :field-outlined="false"
                           field-placeholder="PURL Column"
                           :field-required="true"
                           field-type="string"
                           field-validation=""
                        ></bt-dynamic-text-fields>
                     </v-expansion-panel-content>
                  </v-expansion-panel>
               </v-expansion-panels>
            </v-col>
            <v-col xs="12" sm="12" md="4">
               <v-expansion-panels multiple focusable v-model="panelDedupCols">
                  <v-expansion-panel readonly>
                     <v-expansion-panel-header hide-actions>
                        <strong>Dedup Columns</strong>
                        <v-spacer></v-spacer>
                        # of Fields: {{dedupColumns.length}}
                     </v-expansion-panel-header>
                     <v-expansion-panel-content>
                        <bt-dynamic-text-fields
                           v-model="dedupColumns"
                           field-label="Dedup Column"
                           field-type="string"
                        ></bt-dynamic-text-fields>
                     </v-expansion-panel-content>
                  </v-expansion-panel>
               </v-expansion-panels>
            </v-col>
            <v-col xs="12" sm="12" md="4">
               <v-expansion-panels multiple focusable v-model="panelEmails">
                  <v-expansion-panel>
                     <v-expansion-panel-header>
                        <strong>Email Notifications</strong>
                        <v-spacer></v-spacer>
                        emails: {{emails.length}}
                     </v-expansion-panel-header>
                     <v-expansion-panel-content>
                        <bt-dynamic-text-fields
                           v-model="emails"
                           field-label="Email"
                           field-type="email"
                        ></bt-dynamic-text-fields>
                     </v-expansion-panel-content>
                  </v-expansion-panel>
               </v-expansion-panels>
            </v-col>
         </v-row>
      </v-card-text>
      <!-- <h1>Contact Service > Account Configuration</h1> -->
   </v-card>
</v-container>
</template>

<script>
import BtDynamicTextFields from './BtDynamicTextFields.vue';

export default {
   name: "CsConfig",
   components: { BtDynamicTextFields },
   data() {
      return {
         panelPurlCols: [0],
         panelDedupCols: [0],
         panelEmails: [0],
         purlColumns: [],
         dedupColumns: [],
         emails: []
      }
   }
}
</script>