<template>
   <!-- HISTORY: no-version
      V240213: Added expansionPanelHeader prop + Raised panel-open event.
      V230511: Added showExtraFields prop and passed it to BtFilterDefiner.
      V230221.1: Added <style> section.
      V230124.1: Applied/Modified styles to display contents properly after Aref's upgrade.
      06/02/22(B0.6): Added and consumed filterResult and debug props + Replaced apiService with btHelpers.
      05/03/22(B0.5): Added and consumed initialFilter prop.
      04/27/22(B0.4): Added and consumed apiService prop.
      07/21/21(B0.3): Added and consumed activeTab and includedTabs props.
      06/14/21(B0.2): stdFieldValues prop was added + All props default & validations were removed.
      06/08/21(B0.1): 1st release.
   -->
   <v-expansion-panels focusable multiple v-model="panelFilter">
      <v-expansion-panel>
         <v-expansion-panel-header><strong>{{expansionPanelHeader}}</strong></v-expansion-panel-header>
         <v-expansion-panel-content>
            <bt-filter-definer
               :active-tab="activeTab"
               :bt-helpers="btHelpers"
               :debug="debug"
               :fields="myFields"
               :filter-result="filterResult"
               :included-tabs="includedTabs"
               :initial-filter="initialFilter"
               :is-admin="isAdmin"
               :max="max"
               :preselected-fields="preselectedFields"
               :should-init="shouldInit"
               :show-cancel-btn="false"
               :show-extra-fields="showExtraFields"
               :show-test-cases="showTestCases"
               :std-field-values="stdFieldValues"
               v-model="myFilter"
               @filter-change="filterChanged"
            ></bt-filter-definer>
         </v-expansion-panel-content>
      </v-expansion-panel>
   </v-expansion-panels>
</template>

<script>
import BtFilterDefiner from './BtFilterDefiner.vue';

const NAME = "BtFilterWrapperWithPanel";

function Alert(msg) {
   // alert(NAME + '\n' + msg);
}

export default {
   name: NAME,

   components: {
      BtFilterDefiner
   },

   props: {
      value: {
         type: Object
      },
      activeTab: {
         type: String
      },
      behFieldValues: {
         type: Object
      },
      btHelpers: {
         type: Object
      },
      database: {
         type: String
      },
      debug: {
         type: Boolean
      },
      dense: {
         type: Boolean
      },
      expansionPanelHeader: {
         type: String,
         default: 'FILTERS'
      },
      fields: {
         type: Array
      },
      filterResult: {
         type: Array
      },
      includedTabs: {
         type: Array
      },
      initialFilter: {
         type: Object
      },
      isAdmin: {
         type: Boolean
      },
      max: {
         type: Number
      },
      //TODO: how to validate values with names
      //TODO: default value should be moved to the wrappers.
      operators: {
         type: Array
         // default: () => ["=", ">", ">=", "<", "<=", "in", "nin", "regex"],
         // default: () => [
         //    { text: "Equals (=)", value: "=" },
         //    { text: "Contains", value: "contains" },
         //    { text: "Starts with", value: "starts" },
         //    { text: "Ends with", value: "ends" },
         //    { text: "Greater than (>)", value: ">" },
         //    { text: "Greater than or equal (>=)", value: ">=" },
         //    { text: "Less than (<)", value: "<" },
         //    { text: "Less than or equal (<=)", value: "<=" },
         //    { text: "In", value: "in" },
         //    { text: "Not in", value: "nin" },
         //    { text: "Regex", value: "regex" },
         // ],
         // validator: value => { return value && value.length > 0 && value.length <= 11 }
      },
      preselectedFields: {
         type: Array
      },
      removeIconName: {
         type: String
      },
      removeIconColor: {
         type: String
      },
      //TODO: is required?
      required: {
         type: Boolean
      },
      shouldInit: {
         type: Boolean
      },
      showExtraFields: {
         type: Boolean
      },
      types: {
         type: Array
      },
      stdFieldValues: {
         type: Object
      },

      //for the wrapper
      closedOnLoad: {
         type: Boolean,
         default: true
      },
      //for my testing purposes only
      showTestCases: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         panelFilter: [],
         myFields: [],
         myFilter: {}
      }
   },

   computed: {},

   watch: {
      value: {
         immediate: true,
         handler(val) {
            Alert(`in v-model watch: value=${JSON.stringify(val)}`);
            this.myFilter = val;
         }
      },
      shouldInit: {
         immediate: true,
         handler(val) {
            // alert(`in ${NAME}.shouldInit(): value=${val}, fields.length=${this.fields.length}`);
            if (val && this.fields.length > 0) {
               this.panelFilter = this.closedOnLoad ? [] : [0];
               this.myFilter = this.value;
            }
         }
      },
      fields: {
         immediate: true,
         deep: true,
         handler(val) {
            Alert(`in fields() watch: value=${val}`);
            if (val && val.length > 0) {
               this.myFields = [...val];
            }
         }
      },

      panelFilter: {
         immediate: true,
         handler(val) {
            // alert(`in ${NAME}.panelFilter(): value=${JSON.stringify(val)}`);
            // this.myFields = [...val];
            this.$emit('panel-open', this.panelFilter?.length > 0)
         }
      },

      // myFilter: {
      //    immediate: true,
      //    handler(val) {
      //       // alert(`in ${NAME}.shouldInit(): value=${val}`);
      //       if (val) {
      //          this.panelFilter = this.closedOnLoad ? [] : [0];
      //          this.myFilter = this.value;
      //       }
      //    }
      // }
   },

   methods: {
      filterChanged(filter) {
         Alert(`in filterChanged(): filter=${JSON.stringify(filter)}`);
         this.myFilter = filter;
         this.$emit('filter-change', filter);
         this.$emit('input', filter);
      }
   },

   created() {
      // alert('fields='+JSON.stringify(this.fields));
   },
   
   mounted() {}
}
</script>

<style scoped>
.v-expansion-panel-header, .v-expansion-panel-header--active {
   padding: 0 16px;
   min-height: 40px !important;
}
div >>> .v-expansion-panel-content__wrap {
   padding: 12px 16px 0px 16px !important;
}
</style>