<template>
<!-- HISTORY:
   V230501.1: Removed duplicate policies from userPolicies.
   V230428.2: Added Settings switch + Obtained user policies from the store instead of API.
   V230428.1: Removed "With Workflow" and "With Blueprint" then added Campaigns, Assets, CRM, Market Intel, and CSV Processor switches.
   V230118.1: Applied/Modified styles to display contents properly after Aref's upgrade.
   05/02/22(B0.3): Implemented logout feature.
   09/14/20(B0.2): Added validation to make sure token returns + Reset url before submit.
   09/14/20(B0.1): 1st release.
-->
<v-container fluid class="px-3 pt-3 pb-0" v-if="isAuthorizedUser">
   <v-card :loading="loading" class="py-0">

      <v-toolbar flat color="white" class="py-0">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2 py-0" style="color:#757575 !important">
            <v-icon class="pr-1">supervised_user_circle</v-icon>
            <span>Impersonation</span>
         </h1>
      </v-toolbar>

      <v-card-text class="py-0">
         <v-form ref="inputForm" lazy-validation @input="formChanged">
         <v-expansion-panels multiple focusable               
            v-model="panels"
         >
            <v-expansion-panel>
               <v-expansion-panel-header>General</v-expansion-panel-header>
               <v-expansion-panel-content class="pb-2">
                  <v-row dense class="pt-2">
                     <v-col xs="12" sm="2" md="2" lg="2" class="pt-4 pb-2">
                        <v-switch
                           class="mx-0 mt-3 mb-0 pb-0"
                           :label="allPermissionsLabel"
                           v-model="allPermissions"
                           @change="allPermissionsChanged"
                        ></v-switch>
                     </v-col>
                     <v-col xs="12" sm="6" md="6" lg="6" class="mt-0 pt-3">
                        <v-slider thumb-label
                           class="pt-4" style="font-size: 14px"
                           min="1"
                           max="48"
                           step="1"
                           tick-size="10"
                           v-model="expiry"
                        >
                           <template v-slot:label>
                              <div class="body-2 my-0">Expiry Hours: {{expiry}}</div>
                           </template>
                        </v-slider>
                     </v-col>
                     <v-col xs="12" sm="4" md="4" lg="4" class="mt-0 pt-3">
                        <v-text-field auto-focus required
                           class="mt-0 pt-0"
                           ref="ip"
                           label="* Consumer IP Address"
                           counter="15"
                           :rules="[rules.required,rules.ip4]"
                           v-model="ip"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-row dense class="pt-0 mt-0">
                     <v-col xs="12" sm="2" md="2" lg="2" class="pb-0">
                        <v-switch dense
                           class="mx-0 mt-1 mb-0 pb-0"
                           :label="noCampaignsLabel"
                           :disabled="allPermissions"
                           v-model="noCampaigns"                                 
                        ></v-switch>
                     </v-col>
                     <v-col xs="12" sm="2" md="2" lg="2" class="pb-0">
                        <v-switch dense
                           class="mx-0 mt-1 mb-0 pb-0"
                           :label="noAssetsLabel"
                           :disabled="allPermissions"
                           v-model="noAssets"                                 
                        ></v-switch>
                     </v-col>
                     <v-col xs="12" sm="2" md="2" lg="2" class="pb-0">
                        <v-switch dense
                           class="mx-0 mt-1 mb-0 pb-0"
                           :label="noCrmLabel"
                           :disabled="allPermissions"
                           v-model="noCrm"                                 
                        ></v-switch>
                     </v-col>
                     <v-col xs="12" sm="2" md="2" lg="2" class="pb-0">
                        <v-switch dense
                           class="mx-0 mt-1 mb-0 pb-0"
                           :label="noMarketIntelLabel"
                           :disabled="allPermissions"
                           v-model="noMarketIntel"                                 
                        ></v-switch>
                     </v-col>
                     <v-col xs="12" sm="2" md="2" lg="2" class="pb-0">
                        <v-switch dense
                           class="mx-0 mt-1 mb-0 pb-0"
                           :label="noCsvProcessorLabel"
                           :disabled="allPermissions"
                           v-model="noCsvProcessor"                                 
                        ></v-switch>
                     </v-col>
                     <v-col xs="12" sm="2" md="2" lg="2" class="pb-0">
                        <v-switch dense
                           class="mx-0 mt-1 mb-0 pb-0"
                           :label="noSettingsLabel"
                           :disabled="allPermissions"
                           v-model="noSettings"                                 
                        ></v-switch>
                     </v-col>
                  </v-row>
                  <!-- <v-row dense class="pt-1 mt-0">
                     <v-col xs="12" sm="8" md="8" lg="8" class="mt-0 pt-0">
                        <v-slider thumb-label
                           class="pt-4" style="font-size: 14px"
                           min="1"
                           max="48"
                           step="1"
                           tick-size="10"
                           v-model="expiry"
                        >
                           <template v-slot:label>
                              <div class="body-2 my-0">Expiry Hours: {{expiry}}</div>
                           </template>
                        </v-slider>
                     </v-col>
                     <v-col xs="12" sm="4" md="4" lg="4" class="mt-0 pt-0">
                        <v-text-field required
                           class="mt-0 pt-0"
                           ref="ip"
                           label="* Consumer IP Address"
                           counter="15"
                           :rules="[rules.required,rules.ip4]"
                           v-model="ip"
                        ></v-text-field>
                     </v-col>
                  </v-row> -->
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
               <v-expansion-panel-header>Permissions <span class="ml-2 red--text">{{permissionsMsg}}</span></v-expansion-panel-header>
               <v-expansion-panel-content class="pt-2 pb-1">
                  <v-card v-for="(ms, i) in microservices" :key="ms"
                     dense flat
                  >
                     <v-divider v-if="i > 0 && i < microservices.length">{{i}}</v-divider>
                     <v-row class="pt-2" dense>
                        <v-col cols="1" class="font-weight-bold">{{ms}}:</v-col>
                        <v-col cols="11" class="my-0 py-0">
                           <v-checkbox multiple hide-details
                              class="my-0"
                              label="All Permissions"
                              :value="ms"
                              :disabled="allMicroservicesChecked.indexOf(ms) > -1"
                              v-model="allMicroservicesChecked"
                              @change="allPoliciesChecked"
                           ></v-checkbox>
                        </v-col>
                     </v-row>
                     <v-row class="pt-1 pb-3" dense>
                        <v-col cols="1" class="my-0 py-0"></v-col>
                        <v-col cols="11" class="my-0 py-0">
                           <v-row dense>
                              <v-col v-for="policy in getPolicies(userPolicies,ms)" :key="policy"
                                 cols="4" class="mt-0 pt-0"
                              >
                                 <v-checkbox multiple hide-details
                                    class="mt-0 pt-0"
                                    :label="policy"
                                    :value="policy"
                                    v-model="checkedPolicies"
                                    @change="checkedPoliciesChanged"
                                 ></v-checkbox>
                              </v-col>
                           </v-row>
                        </v-col>
                     </v-row>
                     <!-- <v-divider v-if="microservices.length === 1 || i!=microservices.length-1"></v-divider> -->
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
         </v-form>

         <v-row class="mt-2" v-if="url">
            <v-col xs="12" sm="12" :md="textareaCol" :lg="textareaCol" class="pb-0">
               <v-textarea dense outlined persistent-hint
                  class="caption"
                  label=" Impersonation URL"
                  rows="2"
                  :hint="textareaHint"
                  v-model="url"
               ></v-textarea>
            </v-col>
            <v-col xs="12" sm="12" md="2" lg="2" v-if="isNavigator">
               <v-btn dark block small
                  color="primary"
                  @click="copyToClipboard"
               >
                  <span class="caption">Copy to Clipboard</span>
                  <v-icon dark right>content_copy</v-icon>
               </v-btn>
            </v-col>
         </v-row>
      </v-card-text>

      <v-card-actions class="py-0">
         <div class="flex-grow-1"></div>
            <!-- :disabled="isSubmitDisabled || (checkedPolicies.length===0)" -->
         <v-btn text
            color="blue darken-1"
            @click="submit"
         >Submit</v-btn>
      </v-card-actions>

   </v-card>
</v-container>
<v-container v-else>
   <div class="red--text">
      <p>Sorry, you are not authorized to access this page!</p>
      <!-- TODO: temporary -->
      <ul>
         <li>Power Admin: {{ jwt.pa }}</li>
         <li>Power User: {{ jwt.pu }}</li>
         <li>Parent Account: {{ jwt.paid }}</li>
      </ul>
   </div>
</v-container>
</template>

<script lang="ts">
import { APIService } from '../services/iam-api-service.js';
import { IamSettings } from '../models/iam-settings.js';

const IP4_PATTERN = /^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/;
// const IP4_PATTERN = /^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/;

export default {
   props: {
      debug: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         jwt: {},
         apiService: null,
         settings: new IamSettings(this.debug),
         panels: [0, 1],
         loading: true,
         allPermissions: false,
         noCampaigns: true,
         noAssets: true,
         noCrm: true,
         noMarketIntel: true,
         noCsvProcessor: true,
         noSettings: true,
         expiry: 1,
         ip: '',
         microservices: [],
         userPolicies: [],
         allMicroservicesChecked: [],
         checkedPolicies: [],
         rules: {
            required: value => !!value || 'Value is required!',
            ip4: value => value === '::1' || IP4_PATTERN.test(value) || 'Value is invalid!'
         },
         isSubmitDisabled: true,
         url: '',
         everyonePolicies: [],
         textareaHint: '',
         textareaCol: 10,
         isNavigator: false
      }
   },

   computed: {
      user() {
         //alert('in computed user: user=' + JSON.stringify(this.$store.getters.user));
         return this.$store.getters.user;
      },

      token() {
         //alert('in computed token: token=' + JSON.stringify(this.$store.getters.token));
         return this.$store.getters.token;
      },

      isAuthorizedUser() {
         // user is authorized to view if it is power admin or power user of any account.
         // return this.jwt.pa || this.jwt.pu;
         return (this.jwt.pa || this.jwt.pu) && this.user.policies && this.user.policies.length > 0;
      },

      allPermissionsLabel() {
         return this.allPermissions ? 'All Permissions' : 'Custom Permissions';
      },

      noCampaignsLabel() {
         return this.noCampaigns ? 'Campaigns' : 'No Campaigns';
      },

      noAssetsLabel() {
         return this.noAssets ? 'Assets' : 'No Assets';
      },

      noCrmLabel() {
         return this.noCrm ? 'CRM' : 'No CRM';
      },

      noMarketIntelLabel() {
         return this.noMarketIntel ? 'Market Intel' : 'No Market Intel';
      },

      noCsvProcessorLabel() {
         return this.noCsvProcessor ? 'CSV Processor' : 'No CSV Processor';
      },

      noSettingsLabel() {
         return this.noSettings ? 'Settings' : 'No Settings';
      },

      permissionsMsg() {
         return (this.userPolicies.length === 0 || this.checkedPolicies && this.checkedPolicies.length > 0) 
            ? '' : 'Please select one or more permissions.';
      }
   },

   watch: {
      token() {
         this.init();
      },

      // user: {
      //    deep: true,
      //    immediate: true,
      //    handler() {
      //       this.init();

      //       //alert('in watch: user=' + JSON.stringify(val));
      //       // this.microservices = [];
      //       // val.policies.forEach(policy => {
      //       //    const ms = this.$t(policy.split('-')[0]);
      //       //    if (this.microservices.indexOf(ms) === -1) this.microservices.push(ms);
      //       // });
      //       // this.microservices.sort();
      //       // //alert('microservices AFTER sort: ' + JSON.stringify(this.microservices));
      //       // this.userPolicies = JSON.parse(JSON.stringify(val.policies));
      //       // //alert('policies BEFORE sort: ' + JSON.stringify(this.userPolicies));
      //       // this.userPolicies.sort();
      //       // // alert('policies AFTER sort: ' + JSON.stringify(this.userPolicies));
      //    }
      // },

      allPermissions(val) {
         if (val) {
            this.noCampaigns = true;
            this.noAssets = true;
            this.noCrm = true;
            this.noMarketIntel = true;
            this.noCsvProcessor = true;
            this.noSettings = true;
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`IamImpersonation V230501.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      init() {
         if (!this.token || !this.user.policies || this.user.policies.length === 0) return;

         this.loading = true;
         try {
            const JWT = JSON.parse(atob(this.token.split('.')[1]));
            this.jwt = {
               aid: JWT.aid,
               paid: JWT.paid,
               pa: JWT.pa,
               pu: JWT.pu
            };
            // this.log('in IamImpersonation: jwt=' + JSON.stringify(this.jwt));
            if (this.isAuthorizedUser) {
               this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
               this.getUserPolicies();
            }
         } catch (error) {
            alert('Exception in IamImpersonation while parsing token: ' + JSON.stringify(error));
         }
      },

      // async getUserPolicies() {
      //    let result = await this.apiService.getGroups();
      //    if (result.logout)
      //       this.logout();
      //    else if (!result.message) {
      //       this.everyonePolicies = result.data.filter(g => g.name === 'everyone')[0].policies.map(p => { return p.name; });
      //       const userpoliciesSet = new Set(this.user.policies);
      //       const everyonePoliciesSet = new Set(this.everyonePolicies);
      //       const difference = new Set([...userpoliciesSet].filter(p => !everyonePoliciesSet.has(p.toString())));
      //       this.userPolicies = Array.from(difference);

      //       // this.userPolicies = this.user.policies.filter(item => !this.everyonePolicies.includes(item));
      //       this.userPolicies.sort();
      //       alert(`policies len=${this.user.policies.length}, userPolicies=${JSON.stringify(this.userPolicies)}`);

      //       this.microservices = [];
      //       this.userPolicies.forEach(policy => {
      //          const ms = this.$t(policy.split('-')[0]);
      //          if (this.microservices.indexOf(ms) === -1) this.microservices.push(ms);
      //       });
      //       this.microservices.sort();
      //    }
      //    this.loading = false
      //    // alert('everyonePolicies=' + JSON.stringify(this.everyonePolicies));
      // },

      getUserPolicies() {
         // this.userPolicies = [...this.$store.getters.user.policies];
         this.userPolicies = [...new Set(this.$store.getters.user.policies)];
         this.userPolicies.sort();
         //alert(`2:policies len=${this.user.policies.length}, userPolicies=${JSON.stringify(this.userPolicies)}`);

         this.microservices = [];
         this.userPolicies.forEach(policy => {
            const ms = this.$t(policy.split('-')[0]);
            if (this.microservices.indexOf(ms) === -1) this.microservices.push(ms);
         });
         this.microservices.sort();

         this.loading = false
      },

      formChanged() {
         this.isSubmitDisabled = false;
      },

      getPolicies(policies, ms) {
         return policies.filter(p => this.$t(p.split('-')[0]) === ms);
      },

      allPermissionsChanged(val) {
         if (val) {
            this.allMicroservicesChecked = [...this.microservices];
            this.checkedPolicies = [...this.userPolicies];
         } else {
            this.allMicroservicesChecked = [];
            this.checkedPolicies = [];
         }
      },

      allPoliciesChecked(val) {
         // alert('in allPoliciesChecked: val=' + val);
         // alert('checkedPolicies before: '+JSON.stringify(this.checkedPolicies));
         val.forEach(ms => {
            this.getPolicies(this.userPolicies, ms).forEach(policy => {
               if (this.checkedPolicies.findIndex(p => p === policy) === -1)
                  this.checkedPolicies.push(policy);
            });
         });

         this.allPermissions = this.checkedPolicies.length === this.userPolicies.length;
         // alert('checkedPolicies after: '+JSON.stringify(this.checkedPolicies));
      },

      // isAllPoliciesDisabled(ms) {
      //    if (this.checkedPolicies.findIndex(p => p === policy) === -1)
      // }

      checkedPoliciesChanged() {
         // alert('in checkedPoliciesChanged: val=' + val);
         this.allMicroservicesChecked = [];
         this.microservices.forEach(ms => {
            const cPolicies = this.getPolicies(this.checkedPolicies, ms);
            const uPolicies = this.getPolicies(this.userPolicies, ms);
            if (cPolicies.length === uPolicies.length)
               this.allMicroservicesChecked.push(ms);
         });
         
         this.allPermissions = this.checkedPolicies.length === this.userPolicies.length;
         // alert(this.checkedPolicies.length + '\n' + this.userPolicies.length);
      },

      async submit() {
         if (this.checkedPolicies.length === 0 || !this.$refs.inputForm.validate()) return;
         this.loading = true;
         this.url = '';
         // alert('this.allPermissions='+this.allPermissions);
         let policies;
         if (this.allPermissions)
            policies = [];
         else {
            // policies = [...this.checkedPolicies, ...this.everyonePolicies];
            policies = [...this.checkedPolicies];
            if (!this.noCampaigns)
               policies.push('no-campaigns');
            if (!this.noAssets)
               policies.push('no-assets');
            if (!this.noCrm)
               policies.push('no-crm');
            if (!this.noMarketIntel)
               policies.push('no-marketintel');
            if (!this.noCsvProcessor)
               policies.push('no-csvprocessor');
            if (!this.noSettings)
               policies.push('no-settings');
         }
         let result = await this.apiService.getImpersonationUrl(this.ip, this.expiry, policies);
         if (result.logout)
            this.logout();
         else if (!result.message) {
            if (result.data.token) {
               this.url = `${window.location.origin}?token=${result.data.token}&aid=${this.jwt.aid}`;
               this.copyToClipboard();
               // this.isSubmitDisabled = true;
               // this.$refs.inputForm.resetValidation();
            } else
               alert('No token could be found!');
         }
         this.loading = false;
      },

      async copyToClipboard() {
         if (this.isNavigator) {
            await navigator.clipboard.writeText(this.url);
            this.textareaHint = 'URL was copied to clipboard at ' + new Date().toString().split(' ')[4];
         }
      }
   },

   created() {
      //alert('in created: user=' + JSON.stringify(this.user));
      this.isNavigator = navigator.clipboard;
      if (this.isNavigator) {
         this.textareaCol = 10;
      } else {
         this.textareaHint = 'copy-to-clipboard is not supported.';
         this.textareaCol = 12;
      }
      this.init();
      this.settings.saveLastPageViewed('impersonation');
      // this.$refs.ip.focus();
   },
   
   // beforeRouteLeave (to, from, next) {
   //    if (this.isSubmitDisabled || window.confirm('Do you really want to leave? you have unsaved changes!'))
   //       next();
   //    else
   //       next(false);
   // }
}
</script>

<style scoped>
   .v-input__slot {
      margin-bottom: 0px;
   }
   >>>.v-toolbar__content {
      padding: 0 16px;
   }
   .v-expansion-panel-header, .v-expansion-panel-header--active {
      padding: 0 16px;
      min-height: 40px !important;
   }
   .v-expansion-panel-content__wrap {
      padding: 0 16px !important;
   }
   .theme--light.v-divider {
      border-color: rgba(0,0,0,.12);
      display: block;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
      flex: 1 1 0px;
      max-width: 100%;
      height: 0;
      max-height: 0;
      border: solid;
      border-width: thin 0 0 0;
      -webkit-transition: inherit;
      transition: inherit;      
   }
</style>
