/*jshint esversion: 6 */
/* HISTORY:
 * 02/04/21(B0.7): Modified the logic for last-quarter that didn't change the year + Modified resolveDateRangeTester().
 * 06/08/20(B0.6): Modified resolveDateRange() to exclude today from x days before options + accepted a timezoneOffset.
 * 05/11/20(B0.4): Added resolveDateRange() with pure JS for RF to use in his node.js.
 * 05/08/20(B0.4): Added support for BtCronScheduler's offset values.
 * 03/25/20(B0.3): Added TW, LW, TM, LM, TQ, LQ, TY and LY to the rangeNames as well.
 * 03/25/20(B0.2): Added T and Y to the rangeNames for the purpose of dashboard designer component.
*/

import {
   format, addSeconds, addDays, addWeeks, addMonths, addQuarters,
   startOfDay, startOfYesterday, startOfWeek, startOfMonth, startOfQuarter, startOfYear,
   endOfDay, endOfYesterday, endOfMonth, endOfQuarter
} from "date-fns";

const DEBUG = false;
const isoFormat = "yyyy-MM-dd";

function _log(msg) {
  if (DEBUG) console.log(`BtDateRange B0.7 says => ${msg}`);
}

export class BtDateRange {
   constructor() {
      this.rangeNames = {
         today: 'T',                //today
         thisLastWeek: 'TLW',       //this day of last week
         thisLastMonth: 'TLM',      //this day of last month
         thisWeek: 'TW',            //from Monday till today
         thisMonth: 'TM',           //from first day of this month till today
         thisQuarter: 'TQ',         //from start of this quarter till today
         thisYear: 'TY',            //from start of this year till today
         yesterday: 'Y',            //yesterday
         lastWeek: 'LW',            //from Monday to Sunday of previous week
         lastMonth: 'LM',           //from first to last day of previous month
         lastQuarter: 'LQ',         //from first to last day of previous quarter
         lastYear: 'LY',            //from first to last day of previous year
         T: 'T',
         Y: 'Y',
         TW: 'TW',
         LW: 'LW',
         TM: 'TM',
         LM: 'LM',
         TQ: 'TQ',
         LQ: 'LQ',
         TY: 'TY',
         LY: 'LY',
         offsetCurrentDay: 'CURRENT-DAY',
         offsetDayBefore1: 'DAY-BEFORE-1',
         offsetDayBefore2: 'DAY-BEFORE-2',
         offsetDayBefore3: 'DAY-BEFORE-3',
         offsetDayBefore7: 'DAY-BEFORE-7',
         offsetDayBefore15: 'DAY-BEFORE-15',
         offsetDayBefore30: 'DAY-BEFORE-30',
         offsetLastWeek: 'LAST-WEEK',
         offsetLastMonth: 'LAST-MONTH',
         offsetLastQuarter: 'LAST-QUARTER',
         offsetCurrentWeek: 'CURRENT-WEEK',
         offsetCurrentMonth: 'CURRENT-MONTH',
         offsetCurrentQuarter: 'CURRENT-QUARTER'
      };
   }

   get rangeCodes() {
      return this.rangeNames;
      // let codes = {};
      // Object.keys(this.rangeNames).forEach(key => {
      //    if (key.indexOf('offset') === -1)
      //       codes[key] = this.rangeNames[key];
      // });
      // return codes;
   }
   
   getRange(name) {
      _log("in bt_getRange(): name=" + name);
      var nameUpper = name.toUpperCase();
      var today = startOfDay(new Date());
      var tonight = endOfDay(new Date());
      var fd;
      var td;
      var daysBefore;

      switch (nameUpper) {
         case this.rangeNames.today:
         case this.rangeNames.offsetCurrentDay:
            fd = today;
            break;
         case this.rangeNames.yesterday:
         case this.rangeNames.offsetDayBefore1:
            fd = startOfYesterday();
            td = endOfYesterday();
            break;
         case this.rangeNames.thisWeek:
         case this.rangeNames.offsetCurrentWeek:
            fd = startOfWeek(today, { weekStartsOn: 1 });
            break;
         case this.rangeNames.thisMonth:
         case this.rangeNames.offsetCurrentMonth:
            fd = startOfMonth(today);
            break;
         case this.rangeNames.thisLastWeek:
            fd = addDays(today, -7);
            td = endOfDay(fd);
            break;
         case this.rangeNames.thisLastMonth:
            fd = addWeeks(today, -4);
            td = endOfDay(fd);
            break;
         case this.rangeNames.thisQuarter:
         case this.rangeNames.offsetCurrentQuarter:
            fd = startOfQuarter(today);
            break;
         case this.rangeNames.thisYear:
            fd = startOfYear(today);
            break;
         case this.rangeNames.lastWeek:
         case this.rangeNames.offsetLastWeek:
            td = addSeconds(startOfWeek(today, { weekStartsOn: 1 }), -1);
            fd = endOfDay(startOfWeek(td, { weekStartsOn: 1 }));
            break;
         case this.rangeNames.lastMonth:
         case this.rangeNames.offsetLastMonth:
            fd = startOfMonth(addMonths(today, -1));
            td = endOfMonth(fd);
            break;
         case this.rangeNames.lastQuarter:
         case this.rangeNames.offsetLastQuarter:
            fd = startOfQuarter(addQuarters(today, -1));
            td = endOfQuarter(fd);
            break;
         case this.rangeNames.lastYear:
            td = addSeconds(startOfYear(today), -1);
            fd = startOfYear(td);
            break;
         default:
            // fd = startOfDay(addDays(today, -parseInt(this.selectValue)));
            if (nameUpper.indexOf('DAY-BEFORE-') === 0) {
               daysBefore = nameUpper.substring(11);
               // alert('daysBefore='+daysBefore);
               td = endOfYesterday();
               fd = startOfDay(addDays(today, -parseInt(daysBefore)));
            }
            break;
      }
   
      if (!td) td = tonight;

      td = addDays(td, 1);
   
      _log("in bt_getRange(): fd=" + fd + ", td=" + td);
      return [format(fd, isoFormat), format(td, isoFormat)];
   }
   
   resolveDateRange(offset, timezoneOffset) {
      var fromDate = new Date();
      var toDate = new Date();

      fromDate.setMinutes(fromDate.getMinutes() - timezoneOffset);
      toDate.setMinutes(toDate.getMinutes() - timezoneOffset);
      var daysBefore;
      var oMonth;

      switch (offset) {
         case '':
            return null;
         case 'current-day':
            break;
         case 'current-week':  //from Monday till today
            while (fromDate.getDay() != 1) {
               fromDate.setDate(fromDate.getDate() - 1);
            }
            break;
         case 'current-month':  //from first day of this month till today
            fromDate.setDate(1);
            break;
         case 'current-quarter':  //from first day of this quarter till today
            while (fromDate.getMonth() % 3 != 0) {
               fromDate.setMonth(fromDate.getMonth() - 1);
            }
            fromDate.setDate(1);
            break;
         case 'last-week':  //from Monday to Sunday of previous week
            while (fromDate.getDay() != 0) {
               fromDate.setDate(fromDate.getDate() - 1);
               toDate.setDate(toDate.getDate() - 1);
            }
            fromDate.setDate(fromDate.getDate() - 6);
            break;
         case 'last-month':  //from first to last day of previous month
            var cMonth = fromDate.getMonth();
            while (fromDate.getMonth() == cMonth) {
               fromDate.setDate(fromDate.getDate() - 1);
               toDate.setDate(toDate.getDate() - 1);
            }
            fromDate.setDate(1);
            break;
         case 'last-quarter':  //from first to last day of previous quarter
            while (toDate.getMonth() % 3 != 0) {
               toDate.setMonth(toDate.getMonth() - 1);
            }
            toDate.setDate(0);
            oMonth = fromDate.getMonth();
            fromDate.setMonth(toDate.getMonth() - 2);
            if (fromDate.getMonth() > oMonth)
               fromDate.setFullYear(fromDate.getFullYear() - 1);
            fromDate.setDate(1);
            break;
         default:
            if (offset.indexOf('day-before-') === 0) {
               daysBefore = offset.substring(11);
               // fromDate.setDate(fromDate.getDate() - parseInt(daysBefore) + 1);
               fromDate.setDate(fromDate.getDate() - parseInt(daysBefore));
            }
            break;
      }

      if (offset.indexOf('day-before-') === -1)
         toDate.setDate(toDate.getDate() + 1);
      var dates = [];
      [fromDate, toDate].forEach(longDate => {
         var d = longDate.getDate();
         var m = parseInt(longDate.getMonth()) + 1;
         var y = longDate.getFullYear();
         dates.push(y + '-' + (m > 9 ? m : '0' + m) + '-' + (d > 9 ? d : '0' + d));
      });
      return dates;
   }

   getFirstOfNextMonth(yearMonth) {
      const ym = yearMonth.split('-');
      return format(addMonths(new Date(ym[0], ym[1] - 1, 1), 1), 'yyyy-MM-dd');
   }

   resolveDateRangeTester(arrOffset) {
      if (!Array.isArray(arrOffset) || arrOffset.length === 0)
         arrOffset = ['current-day','current-week','current-month','current-quarter','last-week','last-month','last-quarter','day-before-1','day-before-2','day-before-3','day-before-7','day-before-45'];
      arrOffset.forEach(offset => {
         alert('offset=' + offset + '\n' + JSON.stringify(this.resolveDateRange(offset, 420)));
      });
   }
}

