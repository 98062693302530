<template>
<!--TODO: 1/11/2023
- Remove the getIndex() call in the createIndex() method as it returns all the indexes. Instead, rebuild the indexes var.
-->
<!-- BUSINESS LOGIC:
   1. fieldName1 and fieldName2 items are populated based on the existing indexes and possibility of the new indexes.
   2. If a composite index exists, a single index with the same fieldName1 cannot be created (it's unnecessary).
   3. If a single index exists and a composite index of such field is required, the single index should be removed 1st.
-->
<!-- HISTORY:
   V230517.1: Replaced v-autocomplete with v-combobox to allow text entry and added related logic/validations +
      Changed jwt parsing + Replaced hasOwnProperty with hasOwn from mixins +
      Moved singleIndexFields & indexFields1 settings in getIndexes() + Called getIndexes() in deleteIndex().
   V230206.1: Disabled delete btn if the user didn't have contact-indexes-delete policy.
   09/09/22(B1.6): Added a switch for adding importId to the index optionality.
   06/24/22(B1.5): Made importId desc (-1) and other fields asc (1): ***AREF DIDN'T HAVE THIS VERSION***
   06/24/22(B1.4): Modified getIndexName() to remove added index name prefixes + In createIndex(), passed an extra param +
      Hid unique option as it wasn't in use.
   05/26/22(B1.3): Instead of validating indexData on create, populated fieldName1 and fieldName2 with the proper values only +
      validateIndexData() and validationMsg were commented out.
   05/02/22(B1.2): Implemented logout feature.
   03/16/22(B1.1): Showed New Index btn only if the user has contact-indexes-create policy + Changed the icon.
   02/25/22(B1.0): Called getIndexes() only if the account has imports + Changed New Index btn disabled condition from && to || +
      Added hide-default-footer prop to v-data-table + Made refresh btn available only if there is any indexes.
   07/26/21(B0.3): Replaced getIndexFields() with getImportHeadersWithoutType() from BtHelpers. 
   06/22/21(B0.2): Set index name based on selected values.
   06/21/21(B0.1): 1st release.
-->
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <!-- <v-icon class="pr-1">search</v-icon> -->
            <v-icon class="pr-1">sort</v-icon>
            <span>Indexes</span>
         </h1>
         <div class="flex-grow-1"></div>
         <v-btn v-if="indexes.length"
            x-small
            class="mr-2 mt-1"
            color="gray darken-1"
            :disabled="btnRefreshIndexesDisabled"
            @click="getIndexes()"
         >REFRESH{{indexesDisabledDuration ? ' in ' + indexesDisabledDuration + ' sec' : ''}}
            <v-icon right dark>refresh</v-icon>
         </v-btn>
         <v-btn v-if="$store.getters.user.policies.includes(`contact-indexes-create`)"
            x-small
            class="mr-2 mt-1"
            color="gray darken-1"
            :disabled="loadingIndexes || !indexFields1.length"
            @click="newIndexClicked()"
         >NEW INDEX
            <v-icon right dark>add</v-icon>
         </v-btn>
      </v-card-title>

      <v-card-text :loading="loadingIndexes">
            <!-- :footer-props="{
               itemsPerPageOptions:[5, 10, 20],
               showFirstLastPage: true
            }"
            :items-per-page="5" -->
         <v-data-table dense
            class="elevation-1 mb-2"
            :headers="indexHeaders"
            :hide-default-footer="indexes.length <= 5"
            item-key="name"
            :items="indexes"
            :loading="loadingIndexes"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'indexes' })"
            :no-results-text="$t('no-results-text', { value: 'indexes' })"
            :search="search"
         >
            <!-- <template v-slot:[`item.name`]="{ item }">
               {{ getIndexName(item.name) }}
            </template> -->
            <template v-slot:[`item.hasImport`]="{ item }">
               {{ item.hasImport ? 'Yes' : 'No' }}
            </template>
            <template v-slot:[`item.fieldName1`]="{ item }">
               {{ getIndexFieldName(item.fieldName1) }}
            </template>
            <template v-slot:[`item.sortOrder1`]="{ item }">
               {{ getSortOrderDesc(item.sortOrder1) }}
            </template>
            <template v-slot:[`item.fieldName2`]="{ item }">
               {{ getIndexFieldName(item.fieldName2) }}
            </template>
            <template v-slot:[`item.sortOrder2`]="{ item }">
               {{ item.fieldName2 ? getSortOrderDesc(item.sortOrder2) : '' }}
            </template>
            <!-- <template v-slot:[`item.unique`]="{ item }">
               {{ uniqueItems.filter(u => u.value === item.unique)[0].text }}
            </template> -->
            <template v-slot:[`item.actions`]="{ item }">
               <v-icon small
                  :disabled="!$store.getters.user.policies.includes(`contact-indexes-delete`) || item.name.indexOf('cIndex_') === -1"
                  @click="deleteIndex(item)"
               >delete</v-icon>
            </template>
         </v-data-table>
      </v-card-text>
   </v-card>

   <v-dialog no-click-animation persistent
      max-width="680px"
      v-model="dialogIndex"
   >
      <v-card flat>
         <v-card-title class="title grey--text darken-4 font-weight-bold pb-2">Create a New Index:</v-card-title>

         <v-card-text :loading="loadingNewIndex" class="pb-0">
            <v-form lazy-validation
               ref="indexForm"
               v-model="isIndexFormValid"
            >
               <!-- <v-row>
                  <v-col xs="12" sm="12" md="6" class="py-0">
                     <v-text-field autofocus dense persistent-hint required
                        v-model="indexData.name"
                        ref="indexNme"
                        autocomplete="off"
                        hint="* Index Name"
                        :counter="50"
                        :maxlength="50"
                        :placeholder="`${hasError('indexName') ? 'index name' : ''}`"
                        :rules="[rules.required, rules.minLength, rules.duplicate]"
                     ></v-text-field>
                  </v-col>
               </v-row> -->
               <v-row>
                  <v-col xs="12" sm="8">
                     <!-- <v-autocomplete dense hide-selected persistent-hint required
                        ref="indexFieldName1"
                        autocomplete="off"
                        hint="* Field Name 1"
                        :items="indexFields1"
                        :rules="[rules.required]"
                        v-model="indexData.fieldName1"
                        @change="fieldName1Changed"
                     ></v-autocomplete> -->
                     <v-combobox dense hide-selected persistent-hint required
                        ref="indexFieldName1"
                        autocomplete="off"
                        placeholder="enter a name if your field is not in the list"
                        hint="* Field Name 1"
                        :items="indexFields1"
                        :rules="[rules.required, rules.fieldName1]"
                        v-model="indexData.fieldName1"
                        @change="fieldName1Changed"
                     ></v-combobox>
                  </v-col>
                  <v-col xs="12" sm="4">
                     <v-select dense hide-selected persistent-hint required
                        ref="indexSortOrder1"
                        hint="* Sort Order 1"
                        :items="sortOrderItems"
                        v-model="indexData.sortOrder1"
                        @change="setIndexName"
                     ></v-select>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col xs="12" sm="8">
                     <!-- <v-autocomplete clearable dense hide-selected persistent-hint
                        ref="indexFieldName2"
                        autocomplete="off"
                        hint="Field Name 2"
                        :disabled="!indexData.fieldName1"
                        :items="indexFields2"
                        :rules="fieldName2Rules"
                        v-model="indexData.fieldName2"
                        @change="fieldName2Changed"
                     ></v-autocomplete> -->
                     <v-combobox clearable dense hide-selected persistent-hint
                        ref="indexFieldName2"
                        autocomplete="off"
                        hint="Field Name 2"
                        :disabled="!indexData.fieldName1"
                        :items="indexFields2"
                        :rules="fieldName2Rules"
                        v-model="indexData.fieldName2"
                        @change="fieldName2Changed"
                     ></v-combobox>
                  </v-col>
                  <v-col xs="12" sm="4">
                     <v-select dense hide-selected persistent-hint
                        ref="indexSortOrder2"
                        hint="Sort Order 2"
                        :disabled="!indexData.fieldName1"
                        :items="sortOrderItems"
                        v-model="indexData.sortOrder2"
                        @change="setIndexName"
                     ></v-select>
                  </v-col>
               </v-row>
               <v-row>
                  <!-- <v-col xs="6" sm="6" md="6">
                     <v-select dense persistent-hint hide-selected required
                        ref="indexUnique"
                        hint="* Is Unique"
                        :items="uniqueItems"
                        v-model="indexData.unique"
                     ></v-select>
                  </v-col> -->
                  <v-col xs="12" sm="8">
                     <v-text-field autofocus dense persistent-hint required
                        v-model="indexData.name"
                        ref="indexName"
                        autocomplete="off"
                        hint="* Index Name"
                        :placeholder="`${hasError('indexName') ? 'index name' : ''}`"
                        :rules="[rules.required, rules.duplicateName]"
                        @keyup="isIndexNameChanged = true"
                     ></v-text-field>
                  </v-col>
                  <v-col xs="12" sm="4">
                     <v-switch
                        class="mx-0 mt-3 mb-0 pb-0"
                        :label="` Has Import: ${indexData.hasImport ? 'Yes' : 'No'}`"
                        v-model="indexData.hasImport"
                     ></v-switch>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>

         <v-card-actions>
            <!-- <div class="error--text pl-4">{{validationMsg}}</div> -->
            <div class="flex-grow-1"></div>
            <v-btn text
               class="px-0"
               color="blue darken-1"
               @click="cancelIndex"
            >Cancel</v-btn>
               <!-- :disabled="!this.isIndexFormValid || !!this.validationMsg" -->
            <v-btn text
               class="pl-0 pr-4"
               color="blue darken-1"
               :disabled="!this.isIndexFormValid"
               @click="createIndex"
            >Create</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>

</v-container>
</template>

<script>
import { APIService } from '../services/cs-api-service.js';
import { BtHelpers } from '../services/bt-helpers.js';
import { getAllBehavioralFields } from '../mixins/bt-mixin.js';
import { hasOwn } from '../mixins/bt-mixin.js';

class IndexData {
   constructor(initData) {
      if (!initData) initData = {};
      this.fieldName1 = initData.fieldName1 || '';
      this.sortOrder1 = initData.sortOrder1 || 1;
      this.fieldName2 = initData.fieldName2 || '';
      this.sortOrder2 = initData.sortOrder2 || 1;
      // this.unique = initData.unique || false;
      this.name = initData.name || '';
      this.text = initData.text || '';
      // this.hasImport = initData.hasOwnProperty('hasImport') ? initData.hasImport : true;
      this.hasImport = hasOwn(initData, 'hasImport') ? initData.hasImport : true;
      //importSortOrder shouldn't be added here.
   }
}

const NAME = "CsIndexes";

export default {
   name: NAME,

   components: {},

   props: {
      debug: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         rules: {
            required: value => !!value || "Value is required!",
            // minLength: value => value.length >= 5 || "Name should have more than 5 charecters",
            duplicateName: value => this.indexes.filter(index => index.name === value).length === 0 || 'Value is duplicate!',
            // duplicateIndex2: value => this.indexes.filter(index => index.fieldName1 === this.indexData.fieldName1 && index.fieldName2 === this.indexData.fieldName2).length === 0 || 'Index already exists!',
            // duplicateIndex: value => {
            //    const result = this.indexes.filter(index => index.fieldName1 === this.indexData.fieldName1 && index.fieldName2 === this.indexData.fieldName2);
            //    alert('result='+JSON.stringify(result));
            //    return result.length ? 'Index already exists!' : true;
            // }
            // valid: value => !this.singleIndexFields.includes(value) || 'Field is already indexed!'
            fieldName1: value => {
               let result = '';
               const v = value ? value.trim().toLowerCase() : '';
               // alert('value='+value+', v='+v);

               if (!v)
                  result = 'Field name is required!';
               else if (this.singleIndexFields.includes(v))
                  result = 'Field name is already indexed!';
               return result === '' || result;
            },
            fieldName2: value => {
               const v = value ? value.trim().toLowerCase() : '';
               // alert('value='+value+', v='+v);
               return !!v || 'Field name is required!';
            }
         },
         indexHeaders: [
            { text: 'Name', value: 'text', align: 'center', sortable: true },
            { text: 'Has Import?', value: 'hasImport', align: 'center', sortable: true },
            { text: 'Field Name 1', value: 'fieldName1', align: 'center', sortable: true },
            { text: 'Sort Order 1', value: 'sortOrder1', align: 'center', sortable: true },
            { text: 'Field Name 2', value: 'fieldName2', align: 'center', sortable: true },
            { text: 'Sort Order 2', value: 'sortOrder2', align: 'center', sortable: true },
            // { text: 'Is Unique?', value: 'unique', align: 'center', sortable: true },
            { text: 'Actions', value: 'actions', align: 'right', sortable: false, width: "10%" }
         ],
         sortOrderItems: [
            { text: 'Ascending', value: 1 },
            { text: 'Descending', value: -1 }
         ],
         // uniqueItems: [
         //    { text: 'False', value: false },
         //    { text: 'True', value: true }
         // ],
         jwt: {},
         apiService: null,
         btHelpers: null,
         dialogIndex: false,
         isIndexFormValid: false,
         loadingIndexes: false,
         loadingNewIndex: false,
         indexesDisabledDuration: 0,
         btnRefreshIndexesDisabled: true,
         importHeaders: [],
         indexFields1: [],
         indexFields2: [],
         indexes: [],
         search: "",
         indexData: new IndexData(),
         indexFieldName2Rules: [],
         // validationMsg: '',
         isIndexNameChanged: false,
         fieldName2Rules: [],
         behFields: [],
         singleIndexFields: []
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      // isIndexFormValid() {
      //    return this.$refs.indexForm.validate();
      //    // return this.indexData.name && this.isMainFormValid && this.isEmailsFormValid &&
      //    //    (this.formData.shouldGeneratePurl ?
      //    //       this.isPurlsFormValid && this.isDedupsFormValid : true);
      // }
   },

   watch: {
      token: {
         immediate: true,
         handler() {
            this.init();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V230517.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      hasError(ref) {
         let res = false;
         if (this.$refs[ref]) {
            res = this.$refs[ref].hasError;
         }

         return res;
      },

      async init() {
         // alert('in init(): token=' + this.token);
         this.importHeaders = [];
         this.indexFields1 = [];
         this.indexes = [];

         if (this.token) {
            // const JWT = JSON.parse(atob(this.token.split('.')[1]));
            // this.jwt = {
            //    aid: JWT.aid,
            //    paid: JWT.paid,
            //    pa: JWT.pa,
            //    pu: JWT.pu
            // };
            this.jwt = JSON.parse(Buffer.from(this.token.split('.')[1], 'base64'));
            this.log(`in ${NAME}.init(): jwt=${JSON.stringify(this.jwt)}`);
            this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
            this.btHelpers = new BtHelpers(this.token, this.isActualEndpoint, this.debug);
            this.importHeaders = await this.btHelpers.getImportHeadersWithoutType();
            // alert('in init(): importHeaders=' + JSON.stringify(this.importHeaders));
            if (this.importHeaders.length) {
               await this.getIndexes();
               //V230517.1
               // this.singleIndexFields = this.indexes.filter(ind => !ind.fieldName2).map(ind => ind.fieldName1);
               // this.indexFields1 = this.importHeaders.filter(h => !this.singleIndexFields.includes(h.value));
               // // this.indexFields1 = this.getIndexField1Items();
            }
         } else
            this.jwt = {};
      },

      async getIndexes() {
         this.loadingIndexes = true;
         this.btnRefreshIndexesDisabled = true;
         const result = await this.apiService.getIndexes();
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.indexes = [...result.data];
            if (this.indexes.length) {
               //V230517.1: Moved from init()
               this.singleIndexFields = this.indexes.filter(ind => !ind.fieldName2).map(ind => ind.fieldName1);
               this.indexFields1 = this.importHeaders.filter(h => !this.singleIndexFields.includes(h.value));

               this.indexesDisabledDuration = 60;
               setTimeout(() => {
                  this.btnRefreshIndexesDisabled = false;
               }, 1000 * 60);

               const setIntervalIndexes = setInterval(() => {
                  if (this.indexesDisabledDuration > 0)
                     this.indexesDisabledDuration--;
                  else
                     clearInterval(setIntervalIndexes);
               }, 1000);
            } 
         }

         this.loadingIndexes = false;
         this.log(`in ${NAME}.getIndexes(): indexes=${JSON.stringify(this.indexes)}`);
      },

      // getIndexName(indexName) {
      //    if (indexName.indexOf('cIndex_importId_-1_') === 0)
      //       return indexName.substr(19);
      //    else if (indexName.indexOf('cIndex_') === 0)
      //       return indexName.substr(7);
      //    else
      //       return indexName;
      // },

      getIndexFieldName(fieldName) {
         // alert(fieldName);
         const indexField = this.importHeaders.find(f => f.value === fieldName);
         if (indexField) return indexField.text;
         else return fieldName;
         // return indexField?.text || fieldName;
      },

      getSortOrderDesc(sortOrder) {
         const item = this.sortOrderItems.find(so => so.value === sortOrder);
         return item ? item.text : sortOrder;
      },

      async deleteIndex(indexData) {
         // if (confirm(`Are you sure to delete '${this.getIndexName(indexData.name)}' index?`)) {
         if (confirm(`Are you sure to delete '${indexData.text}' index?`)) {
            this.loadingIndexes = true;
            const result = await this.apiService.deleteIndex(indexData);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               // this.$emit('snackbar-event', `'${this.getIndexName(indexData.name)}' index is being deleted...`);
               this.$emit('snackbar-event', `'${indexData.text}' index is being deleted...`);

               //V230517.1
               // const i = this.indexes.indexOf(indexData);
               // this.indexes.splice(i, 1);
               await this.getIndexes();
            }
            this.loadingIndexes = false;
         }
      },

      async newIndexClicked() {
         this.dialogIndex = true;
         this.isIndexNameChanged = false;
         setTimeout(() => {
            this.$refs.indexFieldName1.focus();
         }, 100);

         // this.validationMsg = '';
         // // this.$refs.indexForm.resetValidation();
         // this.indexData = new IndexData();
         // alert(JSON.stringify(this.indexData));
         // this.$refs.importName.focus();

         // alert('in newImportClicked(): formData=' + JSON.stringify(this.formData));
      },

      fieldName1Changed(val) {
         // alert(`in fieldName1Changed(): val=${JSON.stringify(val)}`);

         let fieldName = this.getFieldName(val);

         // alert(`in fieldName1Changed(): fieldName=${fieldName}`);

         // this.indexFields2 = this.indexFields.filter(f => f.value != fieldName);
         // if (this.indexData.fieldName2 === fieldName)
         //    this.indexData.fieldName2 = '';
         const compositeIndexFields = this.indexes.filter(ind => ind.fieldName1 === fieldName && ind.fieldName2).map(ind => ind.fieldName2);
         // alert(JSON.stringify(compositeIndexFields));
         this.indexFields2 = [
            ...this.importHeaders.filter(h => h.value != fieldName && !compositeIndexFields.includes(h.value)),
            ...this.behFields
         ];
         // alert(JSON.stringify(this.indexFields2));
         if (!this.indexFields2.find(ind => ind.value === this.indexData.fieldName2))
            this.indexData.fieldName2 = '';
         if (this.indexes.find(ind => ind.fieldName1 === fieldName && ind.fieldName2))
            this.fieldName2Rules = [this.rules.required, this.rules.fieldName2];
         else
            this.fieldName2Rules = [];

         this.indexData.fieldName1 = fieldName;
         this.setIndexName();
         this.$refs.indexForm.validate();
         // this.validateIndexData();
      },

      fieldName2Changed(val) {
         // alert('val2='+JSON.stringify(val));
         //this is because when the value is cleared, the property is deleted.
         this.indexData.fieldName2 = this.getFieldName(val);
         this.indexData = new IndexData(this.indexData);
         this.setIndexName();
         this.$refs.indexForm.validate();
         // this.validateIndexData();
      },

      // validateIndexData() {
      //    const result1 = this.indexes.find(index => 
      //       index.fieldName1 === this.indexData.fieldName1 && 
      //       (!index.fieldName2 || index.fieldName2 === this.indexData.fieldName2));
      //    if (result1) {
      //       this.validationMsg = 'Index already exists!';
      //       this.isIndexFormValid = false;
      //    } else {
      //       const result2 = this.indexes.filter(index => 
      //          index.name.indexOf('cIndex_') === 0 &&
      //          index.fieldName1 === this.indexData.fieldName1 &&
      //          !index.fieldName2
      //       );
      //       alert('result2=' + JSON.stringify(result2));
      //       if (result2.length) {
      //          this.validationMsg = `This index will be duplicated. Please remove '${this.getIndexName(result2[0].name)}' index and try again!`;
      //          this.isIndexFormValid = false;
      //       } else {
      //          this.validationMsg = '';
      //          this.$refs.indexForm.validate();
      //       }
      //    }
      // },

      getFieldName(val) {
         if (val === null)
            return '';
         else if (typeof val === 'object')
            return val.value;
         else
            return val?.trim().toLowerCase();
      },

      setIndexName() {
         if (!this.isIndexNameChanged) {
            this.indexData.name = `${this.indexData.fieldName1}_${this.indexData.sortOrder1}`;
            if (this.indexData.fieldName2)
               this.indexData.name += `_${this.indexData.fieldName2}_${this.indexData.sortOrder2}`;
         }
      },

      cancelIndex() {
         this.dialogIndex = false;
         this.indexData = new IndexData();
      },

      async createIndex() {
         // this.isIndexFormValid = this.$refs.indexForm.validate();
         // if (this.isIndexFormValid) {
         // if (this.$refs.indexForm.validate() && !this.validationMsg) {
            // alert('in saveImport(): formData.mappings=' + JSON.stringify(this.formData));
            this.loadingNewIndex = true;

            this.log(`in ${NAME}.createIndex(): indexData=${JSON.stringify(this.indexData)}`);
            const result = await this.apiService.createIndex(
               this.indexData,
               this.behFields.find(f => f.value === this.indexData.fieldName2)
            );
            this.loadingNewIndex = false;
            if (result.logout)
               this.logout();
            else if (!result.message) {
               // this.$emit('snackbar-event', `'${this.getIndexName(this.indexData.name)}' index is being created...`);
               this.$emit('snackbar-event', `'${this.indexData.text}' index is being created...`);
               this.getIndexes();
               this.cancelIndex();
            }
         // }
      }

   },

   created() {
      this.behFields = getAllBehavioralFields();
      // this.init();
   }
}
</script>

<style scoped>
/* .expanded-header {
   font-style: italic;
   font-weight: bold;
} */
</style>
