<!-- HISTORY:
   V230920.1: 1st release (copied file from AsExports.vue V230906.2).
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">vpn_key</v-icon>
            <span>Remote Accesses</span>
         </h1>
      </v-card-title>

      <v-card-text>
         <v-tabs
            class="elevation-2"
            background-color="grey lighten-2 accent-4"
            slider-color="black"
            v-model="tab"
         >
            <v-tab>Totals</v-tab>
            <v-tab>List</v-tab>

            <v-tabs-items v-model="tab">
               <v-tab-item class="pt-5"><!-- Totals -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-text-field clearable
                           class="py-0"
                           append-icon="search"
                           label="Search"
                           :disabled="!totalItems.length"
                           v-model="searchString"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header
                     class="elevation-1"
                     :footer-props="footerPropsTotals"
                     :headers="totalHeaders"
                     :hide-default-footer="totalItems.length <= 5"
                     :items="totalItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsTotals"
                     :search="searchString"
                     @current-items="tableCurrItems"
                  >
                     <template v-slot:[`item.totalItems`]="{item}">
                        {{ formatNumber(item.totalItems) }}
                     </template>
                     <template v-slot:[`body.append`] v-if="totalItemsTotal">
                        <tr class="font-weight-bold">
                           <td>Totals:</td>
                           <td></td>
                           <td></td>
                           <td></td>
                           <td align="right">{{formatNumber(totalItemsTotal)}}</td>
                        </tr>
                     </template>
                  </v-data-table>
               </v-tab-item>

               <v-tab-item class="pt-5"><!-- List -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-autocomplete clearable dense hide-selected
                           append-icon="search"
                           placeholder="search for a specific account"
                           :items="accounts"
                           v-model="selectedAccount"
                           @change="getListReport"
                        ></v-autocomplete>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header show-expand single-expand
                     class="elevation-1"
                     item-key="_id"
                     :expanded.sync="expandedItems"
                     :footer-props="footerPropsList"
                     :headers="listHeaders"
                     :hide-default-footer="listItemsCount.length <= 5"
                     :items="listItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsList"
                     :server-items-length="listItemsCount"
                  >
                     <template v-slot:[`item.creationDate`]="{ item }">
                        <span style="white-space:pre">{{ formatDate(item.creationDate) }}</span>
                     </template>
                     <template v-slot:expanded-item="{ item }">
                        <td>&nbsp;</td>
                        <td colspan="3" class="py-2" valign="top" dense>
                           <ul class="pl-3">
                              <li>
                                 <span class="expanded-header">ID: </span>
                                 <span class="expanded-content">{{item._id}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Include Subdomains: </span>
                                 <span class="expanded-content">{{item.includeSubDomains}}</span>
                              </li>
                           </ul>
                        </td>
                        <td colspan="5" class="py-2" valign="top" dense>
                           <ul class="pl-3">
                              <li>
                                 <span class="expanded-header">Filter: </span>
                                 <span class="expanded-content">{{item.filter}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Public Columns: </span>
                                 <span class="expanded-content">{{item.publicColumns.split(' ').join(', ')}}</span>
                              </li>
                           </ul>
                        </td>
                     </template>
                  </v-data-table>
               </v-tab-item>
            </v-tabs-items>
         </v-tabs>
      </v-card-text>
   </v-card>
</v-container>
</template>

<script>
import { APIService } from '../services/as-api-service.js';
import { format, parseISO } from "date-fns";
// import { sleep } from '../mixins/bt-mixin.js';

const NAME = "AsRemoteAccesses";
const TABS = { totals: 0, list: 1 };
function _compareItems (e1, e2) {
   return e1.text > e2.text ? 1 : -1;
}

export default {
   name: NAME,

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         totalHeaders: [
            { text: 'Account Name', value: 'accountname', align: 'left', sortable: true },
            { text: 'Account ID', value: 'accountid', align: 'left', sortable: true },
            { text: 'Parent Name', value: 'parentname', align: 'left', sortable: true },
            { text: 'Parent ID', value: 'parentid', align: 'left', sortable: true },
            { text: 'Count', value: 'count', align: 'right', sortable: true }
         ],
         listHeaders: [
            { text: 'Account Name', value: 'accountname', align: 'left', sortable: false },
            { text: 'Account ID', value: 'databaseName', align: 'left', sortable: false },
            { text: 'Parent Name', value: 'parentname', align: 'left', sortable: false },
            { text: 'Parent ID', value: 'parentid', align: 'left', sortable: false },
            { text: 'Domain / IP', value: 'domainOrIp', align: 'left', sortable: false },
            { text: 'Type', value: 'type', align: 'left', sortable: false },
            { text: 'Creator', value: 'creator', align: 'left', sortable: false },
            { text: 'Creation Date', value: 'creationDate', align: 'left', sortable: false }
         ],
         apiService: null,
         tab: null,
         loading: false,
         searchString: '',
         optionsTotals: {},
         lastItemsPerPage: 10,
         footerPropsTotals: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         totalItems: [],
         totalItemsTotal: 0,
         footerPropsList: {
            itemsPerPageOptions: [5, 10, 15, 20],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         optionsList: {},
         listItemsCount: 0,
         listItems: [],
         accounts: [],
         selectedAccount: 0,
         expandedItems: []
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      }
   },

   watch: {
      token() {
         this.init();
      },

      async tab(val) {
         if (val === TABS.list && !this.listItemsCount && this.totalItems.length) {
            await this.getListReport();
            const accounts = [];
            this.totalItems.forEach(item => {
               accounts.push({
                  text: `${item.accountname}: ${item.accountid}`,
                  value: item.accountid
               });
            });
            this.accounts = accounts.sort(_compareItems);
         }
      },

      optionsList: {
         handler(val) {
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
            }
            this.getListItems();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V230920.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         // _alert('in init(): token=' + this.token);
         if (this.token) {
            this.apiService = new APIService(this.token, this.debug, this.isActualEndpoint);
            setTimeout(() => {
               this.getTotalItems();
            }, 100);
         }
      },

      async getTotalItems() {
         // alert(`in getTotalItems()`);
         this.loading = true;
         this.totalItems = [];
         let result = await this.apiService.getTotalRemoteAccesses();
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.totalItems = result.data;
         }

         this.loading = false;
      },

      formatNumber(number, maxFractionDigits) {
         return new Intl.NumberFormat('en-US', 
            { 
               maximumFractionDigits: maxFractionDigits || 0
            }).format(number);
      },

      tableCurrItems(val) {
         this.totalItemsTotal = 0;
         if (this.searchString) {
            if (!this.lastItemsPerPage) {
               this.lastItemsPerPage = this.optionsTotals.itemsPerPage;
               this.optionsTotals.itemsPerPage = -1;
               this.footerPropsTotals.disableItemsPerPage = true;
            }
            if (val && val.length) {
               val.forEach(v => {
                  this.totalItemsTotal += v.totalItems;
               });
            }
         } else {
            if (this.lastItemsPerPage) {
               this.footerPropsTotals.disableItemsPerPage = false;
               this.optionsTotals.itemsPerPage = this.lastItemsPerPage;
               this.lastItemsPerPage = 0;
            }
            this.totalItems.forEach(item => {
               this.totalItemsTotal += item.count;
            });
         }
      },

      async getListItemsCount() {
         this.loading = true;
         let result = await this.apiService.getListRemoteAccessesCount(this.selectedAccount);
         if (result.logout)
            this.logout();

         this.listItemsCount = result.message ? 0 : result.data;
         this.optionsList.page = 1;
      },

      async getListItems() {
         // alert(`in getListItems()`);
         this.loading = true;
         this.listItems = [];
         this.expandedItems = [];
         if (this.listItemsCount) {
            let result = await this.apiService.getListRemoteAccesses(this.selectedAccount, this.optionsList.page, this.optionsList.itemsPerPage);
            if (result.logout)
               this.logout();
               
            if (!result.message) {
               this.listItems = [];
               result.data.forEach(d => {
                  //NOTE: keep == as accountid is number but databaseName is string.
                  const totItem = this.totalItems.find(item => item.accountid == d.databaseName);
                  d.accountname = totItem.accountname;
                  d.parentname = totItem.parentname;
                  d.parentid = totItem.parentid;
                  this.listItems.push(d);
               });
            }
         }

         this.loading = false;
      },

      async getReports() {
         await this.getTotalItems();
         this.listItemsCount = 0;
         this.listItems = [];
         if (this.totalItems.length && this.tab === TABS.list) {
            await this.getListReport();
         }
      },

      formatDate(date) {
         if (date) {
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            return formatteddate;
         }
      },

      async getListReport() {
         await this.getListItemsCount();
         await this.getListItems();
      }
   },

   created() {
      this.init();
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>