/*jshint esversion: 6 */
/* HISTORY:
 * 08/26/20(B0.2): Changed cookies with local storage.
*/
const DEFAULT_PAGE = 'policies';
const LS_ITEM_NAME = 'MindFireInc_IamSettings';
let DEBUG;

function log(msg) {
   if (DEBUG)
      console.log(`IamSettings B0.2 says => ${msg}`);
}

export class IamSettings {
   constructor(debug) {
      DEBUG = debug;
      const ls = localStorage.getItem(LS_ITEM_NAME) ? JSON.parse(localStorage.getItem(LS_ITEM_NAME)) : {};
      log('in constructor(): ls=' + JSON.stringify(ls));

      this.keepLastPageViewed = ls.keepLastPageViewed || false;
      this.lastPageViewed = ls.lastPageViewed || DEFAULT_PAGE;
      this.keepMenuExpanded = ls.keepMenuExpanded || false;
      this.areTablesDensed = ls.areTablesDensed || false;
      this.singleExpand = ls.singleExpand || false;
      this.rowsPerPage = ls.rowsPerPage || 10;

      log('in constructor(): keepLastPageViewed=' + this.keepLastPageViewed + ', lastPageViewed=' + this.lastPageViewed + 
         ', areTablesDensed=' + this.areTablesDensed + ', singleExpand=' + this.singleExpand + ', rowsPerPage=' + this.rowsPerPage);
   }

   save() {
      // log('in save(): keepLastPageViewed=' + this.keepLastPageViewed + ', lastPageViewed=' + this.lastPageViewed + 
      //    ', areTablesDensed=' + this.areTablesDensed + ', singleExpand=' + this.singleExpand + ', rowsPerPage=' + this.rowsPerPage);

      const ls = {
         keepLastPageViewed: this.keepLastPageViewed,
         lastPageViewed: this.keepLastPageViewed ? this.lastPageViewed : DEFAULT_PAGE,
         keepMenuExpanded: this.keepMenuExpanded,
         areTablesDensed: this.areTablesDensed,
         singleExpand: this.singleExpand,
         rowsPerPage: this.rowsPerPage
      };

      localStorage.setItem(LS_ITEM_NAME, JSON.stringify(ls));
   }

   saveLastPageViewed(pageName) {
      log('in saveLastPageViewed(): pageName=' + pageName);
      if (this.keepLastPageViewed) {
         this.lastPageViewed = pageName;
         this.save();
      }
   }
}