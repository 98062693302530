<template>
   <!-- TODO:
   - Remove showExtraFields prop in this file, CsDocuments and BtFilterWrappedWithPanel.
   - BUG: if field doesn't have type n operators (extra_field_1), operators dropdown is empty.
   - In reset, reset address fields as well.
   - Try to go in the 1st tab that has been set (i.e., to GROUPBY tab if there is no STANDARD n BEHAVIORAL tabs).
   - groupbyIdItems and groupbyId2Items are not updated properly when groupby.id/id2 are changed + only remove id2 if its value doesn't exist in the list. IT REMOVES THE SELECTED ID2 FROM ID1?
   - Add a flag (maybe in Misc tab) to save the settings in local storage.
   - if there is groupby, add chart w/dropdown to select the type of chart: chart types are based on the result
   - Add Form to all tab items in order to move panel to the wrapper consumer.
   - In groupby: when 2nd d/d is selected, change the items of d/d 1 or vice versa. If the selected value is not in the new items, clear the v-model.
   - groupby => add and option for switching between standard and behaviroal fields ($groupby rendering will be different).
   - Add hint to filters to show x of max
   - reset behOperator only if the value doesn't exist.
   - Implement getting coordinates from an address
   x Add seq to the records
   - convert string==='' to notexist in mongodb
   - Add Indexes menu
   x Apply btn should be clickable if form is valid and filters exists or reset clicks (1st time query w/out filter)
   x disable non-numeric keys for range
   -->
   <!-- LOGIC:
      Adding $project:
      1. $project is added when one or more behavioral (event) fields are selected.
      2. All the fields from the groupby tab should be added to $project if it doesn't already exist.
   -->
   <!-- HISTORY:
      V2408xx.1: NOT DELIVERED YET. Modified setTabSettings() to fix the tabs click problem. Saved in export-in-pagina-table branch.
      V240808.1: Modified the groupby tab to allow specifying event_data fields for the aggregation operations.
      V240709.1: Added Yearly-Monthly and Monthly-Daily options to groupbyDateTypeItems and modified parseFilterKey() to support them.
      V240626.1: [Not given to Aref] Fixed a bug in groupbyId2Changed() + Implemented initialFilter for the groupby tab but didn't finish it.
      V240617.1: Allowed all fields to be shown in the Standard tab's autocomplete and groupbyId & groupbyId2 dropdowns.
      V240214.1: Modified watch.includedTabs() by adding immediate and uncommenting setTabSettings() +
         Called getSortItems() in mounted() + Reset selectedFields in parseFilterKey.
      V240208.1: Removed :max prop from v-date-picker to allow selecting future dates.
      V240116.1: Modified getFiltersForMongodb() to properly add the between operator to $project.
      V240112.1: Supported between operator (2-way) with validations for the 2nd value (greaterThanNumber() and greaterThanDate() rules) for the standard and behavioral tabs +
         Added formatDate() to consildate setting formattedDate (also, only removed the time portion of the as it made dates 1 day behind).
      V230817.1: Implemented support for '_mfi_studio_sync' and '_mfi_studio_added_record' to have false value for proper search (added { $exists: false } to the filter) +
         fixed a bug that didn't make types dropdown disabled for systen fields (added systemFiled property to the filter).
      V230630.1: Moved setTabSettings() from includedTabs watch to the mount() to resolve the issue that tabs switched unnecessarily +
         Added parentComponent prop and used it to set different operators for behavioral fields with string type (i.e., to eliminate regex operators).
      V230608.1: Modified parseFilterKey() by making boolean value a string to resolve the bug that didn't show the value in the dropdown.
      V230607.2: Fixed bugs from V1 that didn't resolve array values ($in) & sometimes didn't disable type d/d [all in parseFilterKey()].
      V230607.1: Added logic to persist type of custom event data fields + Fixed a bug that couldn't resolve regex operator after we had added $options +
         Made booleanItems to be boolean rather string as it didn't set the value properly in the BEHAVIORAL tab.
      V230526.1: Modified parseFilterKey() to handle event_data 2-way binding in the BEHAVIORAL and GROUPBY tabs.
      V230523.1: Fixed a bug in processFiltersForMongodb() to get the eventData correctly and based on its type.
      V230522.1: Handled Event Data functionalities in th GROUPBY and BEHAVIORAL tabs.
      V230515.1: Fixed a bug that didn't update sortItems with extraFields in the BtChart component.
      V230512.2: Changed noSpace rule to extraFields and made sure that the specified fields don't exist in the dropdown or duplicate +
         Converted fields to lower case.
      V230512.1: Ignored showExtraFields prop and used tabSettings[4] instead to always show extraFields + Handdled initiating extraColumns in parseFilterKey().
      V230511.1: Added showExtraFields prop + Added extraFields textfield in the COLUMNS tab and included specified fields to sortItems.
      V230202.1: Added event_path and event_element in the getBehOperators() method.
      V230124.1: Applied/Modified styles to display contents properly after Aref's upgrade.
         Replaced all dense props to :dense="dense" and made dense default value to false (w/Aref).
      09/16/22(B2.17): Modified fieldsChanged() to set importId's operators differently.
      09/16/22(B2.16): Implemented multiple import selection in the standard tab (then fixed a bug after giving it to Aref).
      07/27/22(B2.15): Called getPredefinedFilters() only when includedTabs prop includes 'predefined' tab.
      07/07/22(B2.14): In Predfined tab, added isRangeQuery switch and rowDimension autocomplete.
      06/28/22(B2.13): Fixed bugs from B2.12.
      06/27/22(B2.12): Modified getFiltersForMongodb() to always add $project if $groupby exists.
      06/14/22(B2.11): In prepareChartData(), handled null value before applying toString() +
         Added device & platform in getBehOperators().
      06/13/22(B2.10): Fixed a bug that couldn't set/init groupbyId2 for the 1st time.
      06/10/22(B2.9): Added required logic in parseFilterKey() to ignore event_date range that was injected by date picker.
      06/03/22(B2.8): Implemented required logic to parse predefined filter in shouldInit.
      06/02/22(B2.7): Applied predefined filter + Added filterResult prop to show filter's result (data) after APPLY is clicked.
      05/27/22(B2.6): In getFiltersForMongodb(), fixed the bug that couldn't parse equal operator properly.
      05/27/22(B2.5): In getFiltersForMongodb(), fixed the bug that didn't add $elemMatch to the filter.
      05/24/22(B2.4): In groupbyIdChanged(), set groupbyId2Items to only include indexed fields + Obtained behFields from bt-mixin +
         Removed != and nin operators in the Behavioral tab + Added $project to the outFilter if Behavioral fields are selected.
      05/16/22(B2.3): Added groupbyId2Required prop + Called getPredefinedFilters() only if apiService was available +
         Fixed a bug that caused error to init Groupby filter w/out $limit.
      05/02/22(B2.2): Called getPredefinedFilters() only if apiService was provided.
      05/04/22(B2.1): Commented firstLoad as it wasn't in use + Implemented initialFilter and its related logic.
      04/18/22(B2.0): Implemented PREDEFINED tab + Moved Predefined & Fulltext tabs to the end.
      03/25/22(B1.14): In processFiltersForMongodb(), changed the logic for boolean as it always returned true.
      03/25/22(B1.13): Fixed a bug that couldn't parse filter (used find instead of index).
      03/08/22(B1.12): Applied filter automatically after reset is clicked.
      02/22/22(B1.11): Brought back the limit dropdown in the groupby tab with different values.
      02/03/22(B1.10): In the groupby tab, only added indexed standard fields.
      02/01/22(B1.9): In the standard tab, only added indexed fields.
      08/16/21(B1.8): Added groupbyOperators and showGroupbyId2 props.
      08/11/21(B1.7): Added Not Equals (!=) operator.
      08/02/21(B1.6.1): Added an alert in the fields' watcher to debug the issue on Aref side.
      07/28/21(B1.6): Used <pre> tag to show the filter result.
      07/09/21(B1.5): Modified logic to fix a bug that caused fields disappear from groupbyIdItems.
      07/08/21(B1.4): Added some of the behFields to the groupbyItems and supported rendering their $groupby accordingly. 
      06/21/21(B1.3): Disabled operator dropdown for internal fields + Fixed a bug that didn't set operators for fields with no data +
         Fixed a bug with empty behFieldValues case.
      06/10/21(B1.2): In groupbyId2Changed(), removed sort setting + Added activeTab and includedTabs props and implemented required logic to consume them +
         Implemented logic to get latitude/longitude from address + Added import_id to the standard fields and added stdFieldValues prop.
      06/08/21(B1.1): Removed limit dropdown from groupby tab + Removed leftover alerts
      06/08/21(B1.0): Supported 2-way binding + Removed <v-expansion-panels> + Renamed applyClicked() to filterChanged() + 
         Added TestCases dropdown + Changed isFormValid logic + Removed 1st time filter change trigger.
      05/25/21(B0.16): Added limit dropdown in the groupby tab.
      05/20/21(B0.15): Added autocomplete="off" to all autocomplete components.
      05/12/21(B0.14): Added sort option in the Columns tab + Added Cancel btn + In groupby: Added distinct to accumulator operators; Added the 2nd dropdown.
      05/06/21(B0.13): In Behavioral: Removed list_id and purl fields + Supported custom fields +
         In Groupby: Added a switch to set the sort field.
      05/04/21(B0.12): Implemented Behavioral tab + Fixed operator issue in standard + Added Full Text tab.
      04/29/21(B0.11): Fixed location bug + Added ... in location tab.
      04/28/21(B0.10): Fixed bug with location filter + In groupby, applied sort on the _id field.
      04/28/21(B0.9): Implemented By Location tab + In groupby, added extra dropdown for date fields.
      04/26/21(B0.8): In groupby: Removed purl and basePurl fields from dropdown + Fixed the issue with count + Implemented reset.
      04/23/21(B0.7): Repositioned Columns tab to the end + In groupby: Included all fields in groupby dropdown.
      04/23/21(B0.6): Implemented groupby tab + Added Predefined tab + Repositioned tabs + Fixed the bug that couldn't close Filters on start.
      04/20/21(B0.5): Displayed filterOut.standard only + Added closedOnLoad prop + No error on not found + Changed tab colors +
         Added contains, starts with and ends with operators.
      04/19/21(B0.4): Wrapped dates in filter w/new Date() + Allowed dates to be entered manually + 
         Moved regex to operators and made the dropdown dynamic
      04/12/21(B0.3): Accepted types for fields + Distinguished fields types automatically.
      04/08/21(B0.2): Reset skip on Apply click + Show/hide filter text for admin only + Added date to the operators plus date picker.
      03/25/21(B0.1): Development started.
   -->
   <v-form lazy-validation
      ref="form"
      v-model="isFilterFormValid"
   >
      <v-tabs dark
         class="elevation-2 pb-2"
         background-color="grey lighten-2 accent-4"
         slider-color="black"
         v-model="tab"
      >
         <v-tab
            :class="isStandardFormValid ? 'black--text' : 'red--text'"
            v-show="tabSettings[0]"
         >Standard</v-tab><!-- shouldShowTab('standard') -->
         <v-tab
            :class="isBehavioralFormValid ? 'black--text' : 'red--text'"
            v-show="tabSettings[1]"
         >Behavioral</v-tab><!-- shouldShowTab('behavioral') -->
         <v-tab
            :class="isLocationFormValid ? 'black--text' : 'red--text'"
            v-show="tabSettings[2]"
         >By Location</v-tab><!-- shouldShowTab('bylocation') -->
         <v-tab
            :class="isGroupbyFormValid ? 'black--text' : 'red--text'"
            v-show="tabSettings[3]"
         >Groupby</v-tab><!-- shouldShowTab('groupby') -->
         <v-tab v-if="true"
            :class="isColumnsFormValid ? 'black--text' : 'red--text'"
            v-show="tabSettings[4]"
         >Columns</v-tab><!-- shouldShowTab('columns') -->
         <!-- :disabled="!isAdmin"
         :class="predefinedClass" -->
         <v-tab
            :class="isPredefinedFormValid ? 'black--text' : 'red--text'"
            v-show="tabSettings[5]"
         >Predefined</v-tab><!-- shouldShowTab('predefined') -->
         <v-tab disabled
            :class="isFulltextFormValid ? 'grey--text' : 'red--text'"
            v-show="tabSettings[6]"
         >Full Text</v-tab><!-- shouldShowTab('fulltext') -->

         <v-tabs-items v-model="tab">
            <v-tab-item><!-- Standard tab -->
               <v-card flat tile>
                  <v-card-text>
                     <v-row v-for="(item, i) in filters" :key="i">
                        <v-col xs="12" sm="12" md="3" class="py-0">
                              <!-- V240617: :items="getFields(fields, filters, i).filter(f => f.isIndexed)" -->
                           <v-autocomplete persistent-hint
                              v-model="item.field"
                              :ref="`fieldName${i}`"
                              :placeholder="`field name ${i + 1}`"
                              autocomplete="off"
                              :dense="dense"
                              :disabled="initialFilter.standard && initialFilter.standard.indexOf(item.field) > -1"
                              :items="getFields(fields, filters, i)"
                              :rules="getFieldRules(i)"
                              @change="addStdFilter(item)"
                           >
                              <template v-slot:prepend>
                                 <v-icon
                                    class="py-2"
                                    :color="removeIconColor"
                                    :disabled="!item.field || (initialFilter.standard && initialFilter.standard.indexOf(item.field) > -1)"
                                    @click="removeFilter(i)"
                                 >{{removeIconName}}</v-icon>
                              </template>
                           </v-autocomplete>
                        </v-col>
                        <v-col xs="12" sm="12" md="2" class="py-0" v-if="item.field">
                           <v-autocomplete persistent-hint required
                              v-model="item.type"
                              :ref="`type${i}`"
                              :placeholder="`type ${i + 1}`"
                              autocomplete="off"
                              :dense="dense"
                              :disabled="item.internalField || item.systemField"
                              :items="types"
                              :rules="[rules.required]"
                              @change="stdTypeChanged(item)"
                           ></v-autocomplete>
                        </v-col>
                        <v-col xs="12" sm="12" md="3" class="py-0" v-if="item.field">
                              <!-- :items="fields.find(f => f.value === item.field).operators" -->
                              <!-- :items="getOperatorItems(item)" -->
                              <!-- :items="(item.operators && item.operators.length > 0) ? item.operators : getOperatorItems(item)" -->
                           <v-autocomplete persistent-hint required
                              v-model="item.operator"
                              :ref="`operator${i}`"
                              :placeholder="`operator ${i + 1}`"
                              autocomplete="off"
                              :dense="dense"
                              :disabled="item.type.toLowerCase() === 'boolean' || Boolean(stdFieldValues[item.field])"
                              :items="item.operators"
                              :rules="[rules.required]"
                           ></v-autocomplete>
                        </v-col>
                        <v-col v-if="item.field"
                           xs="12" sm="12" :md="item.operator==='between'?2:4"
                           class="py-0"
                         >
                           <!-- :min="pickerMin" -->
                           <v-menu v-if="item.type.toLowerCase()==='date'"
                              close-on-content-click offset-y
                              class="pt-0"
                              min-width="auto"
                              transition="scale-transition"
                              v-model="item.menu"
                           >
                              <template v-slot:activator="{ on, attrs }">
                                 <v-text-field persistent-hint required
                                    class="pt-3"
                                    append-icon="mdi-calendar"
                                    placeholder="in mm/dd/yyyy format"
                                    :hint="item.operator === 'between' ? 'From date' : ''"
                                    :dense="dense"
                                    :rules="[rules.required, rules.date]"
                                    v-model="item.formattedDate"
                                    v-bind="attrs"
                                    v-on="on"
                                    @change="formattedDateChanged(item)"
                                 ></v-text-field>
                              </template>
                              <!-- :max="pickerMax" -->
                              <v-date-picker no-title
                                 class="mt-0"
                                 locale="en-us"
                                 v-model="item.value"
                                 @change="dateChanged(item)"
                              ></v-date-picker>
                           </v-menu>
                           <v-autocomplete v-else-if="item.type.toLowerCase()==='boolean' || (stdFieldValues[item.field] && item.field != 'importId' )"
                              persistent-hint required
                              v-model="item.value"
                              :ref="`stdValue${i}`"
                              :placeholder="`select value ${i + 1}`"
                              autocomplete="off"
                              :dense="dense"
                              :items="getStdValueItems(item)"
                              :rules="[rules.required]"
                           ></v-autocomplete>
                           <v-autocomplete v-else-if="stdFieldValues[item.field] && item.field === 'importId'"
                              deletable-chips multiple persistent-hint required small-chips
                              v-model="item.value"
                              :ref="`stdValue${i}`"
                              :placeholder="`select up to ${maxNumOfImports} imports`"
                              autocomplete="off"
                              :dense="dense"
                              :items="getStdValueItems(item)"
                              :rules="[rules.requiredImport, rules.tooManyImports]"
                           ></v-autocomplete>
                           <v-text-field v-else
                              persistent-hint
                              v-model="item.value"
                              :ref="`stdValue${i}`"
                              :placeholder="`value ${i + 1}`"
                              autocomplete="off"
                              :dense="dense"
                              :hint="isRangeOperator(item.operator) ? 'separate values with comma' : (item.operator === 'between' ? 'From value' : '')"
                              :type="(item.type.toLowerCase() === 'number' && !isRangeOperator(item.operator)) ? 'number' : 'text'"
                              :rules="getValueRules(item)"
                           ></v-text-field>
                        </v-col>
                        <v-col xs="12" sm="12" md="2" class="py-0" v-if="item.field && item.operator === 'between'">
                           <v-menu v-if="item.type.toLowerCase()==='date'"
                              close-on-content-click offset-y
                              class="pt-0"
                              min-width="auto"
                              transition="scale-transition"
                              v-model="item.menu2"
                           >
                              <template v-slot:activator="{ on, attrs }">
                                 <v-text-field persistent-hint required
                                    class="pt-3"
                                    append-icon="mdi-calendar"
                                    placeholder="in mm/dd/yyyy format"
                                    hint="To date"
                                    :dense="dense"
                                    :rules="[rules.required, rules.date, rules.greaterThanDate(item.value2, item.value)]"
                                    v-model="item.formattedDate2"
                                    v-bind="attrs"
                                    v-on="on"
                                    @change="formattedDateChanged(item, '2')"
                                 ></v-text-field>
                              </template>
                              <!-- :max="pickerMax" -->
                              <v-date-picker no-title
                                 class="mt-0"
                                 locale="en-us"
                                 v-model="item.value2"
                                 @change="dateChanged(item, '2')"
                              ></v-date-picker>
                           </v-menu>
                           <v-text-field v-else persistent-hint
                              :ref="`stdValue2${i}`"
                              hint="To value"
                              :placeholder="`greater than ${item.value === '' ? 'To value' : item.value}`"
                              autocomplete="off"
                              :dense="dense"
                              type="number"
                              :rules="[rules.required, rules.greaterThanNumber(item.value2, item.value)]"
                              v-model="item.value2"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                  </v-card-text>
               </v-card>
            </v-tab-item>

            <!-- Behavioral tab -->
            <v-tab-item>
               <v-card flat tile>
                  <v-card-text>
                     <v-row v-for="(item, i) in behFilters" :key="i">
                        <v-col xs="12" sm="12" :md="item.colSize" class="py-0">
                           <v-autocomplete persistent-hint
                              v-model="item.field"
                              :ref="`behFieldName${i}`"
                              :placeholder="`field name ${i + 1}`"
                              autocomplete="off"
                              :dense="dense"
                              :items="getFields(behFields, behFilters, i)"
                              :rules="getBehFieldRules(i)"
                              @change="behFieldNameChanged(item, i)"
                           >
                              <template v-slot:prepend>
                                 <v-icon
                                    class="py-2"
                                    :color="removeIconColor"
                                    :disabled="!item.field"
                                    @click="removeBehFilter(i)"
                                 >{{removeIconName}}</v-icon>
                              </template>
                           </v-autocomplete>
                        </v-col>
                        <v-col v-if="item.field==='__custom__'"
                           xs="12" sm="12" md="2" class="py-0"
                        >
                           <v-text-field persistent-hint
                              v-model="item.custom"
                              :ref="`behCustom${i}`"
                              :placeholder="`custom field ${i + 1}`"
                              autocomplete="off"
                              :dense="dense"
                              :hint="isRangeOperator(item.operator) ? 'separate values with comma' : ''"
                              :type="(item.type.toLowerCase() === 'number' && !isRangeOperator(item.operator)) ? 'number' : 'text'"
                              :rules="getBehCustomRules(item)"
                           ></v-text-field>
                        </v-col>
                        <v-col v-if="item.field && item.field === 'event_data'"
                           xs="12" sm="12" md="2" class="py-0"
                        >
                              <!-- :rules="getGroupbyEventDataRules" -->
                           <v-combobox persistent-hint
                              ref="`behEventData`"
                              autocomplete="off"
                              placeholder="select or type..."
                              :dense="dense"
                              :items="eventDataFields"
                              :rules="[rules.required]"
                              v-model="item.eventData"
                              @change="behEventDataChanged(item)"
                           ></v-combobox>
                        </v-col>
                        <v-col v-if="item.field"
                           xs="12" sm="12" md="2" class="py-0"
                        >
                           <v-autocomplete persistent-hint required
                              v-model="item.type"
                              :ref="`behType${i}`"
                              autocomplete="off"
                              :dense="dense"
                              :disabled="item.isTypeDisabled"
                              :items="types"
                              :rules="[rules.required]"
                              @change="behTypeChanged(item)"
                           ></v-autocomplete>
                        </v-col>
                        <v-col v-if="item.type"
                           xs="12" sm="12" md="2" class="py-0"
                        >
                              <!-- :disabled="getOperatorItems(behFields, item).length === 1" -->
                              <!-- :items="behFields.find(f => f.value === item.field).operators" -->
                              <!-- :items="getOperatorItems(behFields, item)" -->
                           <v-autocomplete persistent-hint required
                              v-model="item.operator"
                              :ref="`behOperator${i}`"
                              autocomplete="off"
                              :dense="dense"
                              :disabled="item.operators && item.operators.length === 1"
                              :items="item.operators"
                              :rules="[rules.required]"
                           ></v-autocomplete>
                        </v-col>
                        <v-col v-if="item.operator"
                           xs="12" sm="12" :md="item.operator==='between'?2:4" class="py-0"
                        >
                           <v-menu v-if="item.type.toLowerCase()==='date'"
                              close-on-content-click offset-y
                              class="pt-0"
                              min-width="auto"
                              transition="scale-transition"
                              v-model="item.menu"
                           >
                              <template v-slot:activator="{ on, attrs }">
                                 <v-text-field persistent-hint required
                                    class="pt-3"
                                    append-icon="mdi-calendar"
                                    placeholder="in mm/dd/yyyy format"
                                    :hint="item.operator === 'between' ? 'From date' : ''"
                                    :dense="dense"
                                    :rules="[rules.required, rules.date]"
                                    v-model="item.formattedDate"
                                    v-bind="attrs"
                                    v-on="on"
                                    @change="formattedDateChanged(item)"
                                 ></v-text-field>
                              </template>
                              <v-date-picker no-title
                                 class="mt-0"
                                 locale="en-us"
                                 v-model="item.value"
                                 @change="dateChanged(item)"
                              ></v-date-picker>
                           </v-menu>
                           <v-autocomplete v-else-if="item.type.toLowerCase() === 'boolean' || Boolean(behFieldValues[item.field])"
                              persistent-hint required
                              v-model="item.value"
                              :ref="`behValue${i}`"
                              :placeholder="`select value ${i + 1}`"
                              autocomplete="off"
                              :dense="dense"
                              :items="getBehValueItems(item)"
                              :rules="[rules.required]"
                           ></v-autocomplete>
                           <v-text-field v-else
                              persistent-hint
                              v-model="item.value"
                              :ref="`behValue${i}`"
                              :placeholder="`value ${i + 1}`"
                              autocomplete="off"
                              :dense="dense"
                              :hint="isRangeOperator(item.operator) ? 'separate values with comma' : (item.operator === 'between' ? 'From value' : '')"
                              :type="(item.type.toLowerCase() === 'number' && !isRangeOperator(item.operator)) ? 'number' : 'text'"
                              :rules="getValueRules(item)"
                           ></v-text-field>
                        </v-col>
                        <v-col v-if="item.operator === 'between'"
                           xs="12" sm="12" md="2" class="py-0"
                        >
                           <v-menu v-if="item.type.toLowerCase()==='date'"
                              close-on-content-click offset-y
                              class="pt-0"
                              min-width="auto"
                              transition="scale-transition"
                              v-model="item.menu2"
                           >
                              <template v-slot:activator="{ on, attrs }">
                                 <v-text-field persistent-hint required
                                    class="pt-3"
                                    append-icon="mdi-calendar"
                                    placeholder="in mm/dd/yyyy format"
                                    hint="To date"
                                    :dense="dense"
                                    :rules="[rules.required, rules.date, rules.greaterThanDate(item.value2, item.value)]"
                                    v-model="item.formattedDate2"
                                    v-bind="attrs"
                                    v-on="on"
                                    @change="formattedDateChanged(item, '2')"
                                 ></v-text-field>
                              </template>
                              <!-- :max="pickerMax" -->
                              <v-date-picker no-title
                                 class="mt-0"
                                 locale="en-us"
                                 v-model="item.value2"
                                 @change="dateChanged(item, '2')"
                              ></v-date-picker>
                           </v-menu>
                           <v-text-field v-else persistent-hint
                              :ref="`behValue${i}`"
                              hint="To value"
                              :placeholder="`greater than ${item.value === '' ? 'To value' : item.value}`"
                              autocomplete="off"
                              :dense="dense"
                              type="number"
                              :rules="[rules.required, rules.greaterThanNumber(item.value2, item.value)]"
                              v-model="item.value2"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                  </v-card-text>
               </v-card>
            </v-tab-item>

            <!-- By Location tab -->
            <v-tab-item>
               <v-card flat tile>
                  <v-card-text>
                     <v-row>
                        <v-col xs="12" sm="3" md="2" class="py-0">
                           <v-text-field autofocus clearable persistent-hint
                              v-model="byLocation.distance"
                              ref="distance"
                              :dense="dense"
                              :hint="byLocation.distance ? 'distance in miles' : ''"
                              placeholder="Distance in Miles"
                              autocomplete="off"
                              type="number"
                              @click:clear="byLocationDistanceChanged"
                              @keydown="byLocationDistanceChanged"
                           ></v-text-field>
                        </v-col>
                        <v-col xs="12" sm="3" md="2" class="py-0">
                           <v-text-field v-if="byLocation.distance"
                              persistent-hint required
                              v-model="byLocation.latitude"
                              ref="latitude"
                              :dense="dense"
                              :hint="byLocation.latitude ? 'latitude' : ''"
                              placeholder="Latitude"
                              type="number"
                              autocomplete="off"
                              :rules="[rules.required, rules.latitude]"
                              @change="byLocationChanged"
                           ></v-text-field>
                        </v-col>
                        <v-col xs="12" sm="3" md="2" class="py-0">
                           <v-text-field v-if="byLocation.distance"
                              persistent-hint required
                              v-model="byLocation.longitude"
                              ref="longitude"
                              :dense="dense"
                              :hint="byLocation.longitude ? 'longitude' : ''"
                              placeholder="Longitude"
                              type="number"
                              autocomplete="off"
                              :rules="[rules.required, rules.longitude]"
                              @change="byLocationChanged"
                           ></v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col xs="1" sm="1" md="1" class="py-2 pr-0">
                           <v-card flat class="py-0 px-0">
                              <v-card-title class="py-0 px-0">
                                 <div class="flex-grow-1"></div>
                                 <v-tooltip left v-if="byLocation.distance">
                                    <template v-slot:activator="{ on }">
                                       <!-- more_horiz icon -->
                                       <v-btn text icon
                                          v-on="on"
                                          @click="btnAddressClicked"
                                       >
                                          <v-icon>more_vert</v-icon>
                                       </v-btn>
                                    </template>
                                    <span>click to get the coordinates from an address</span>
                                 </v-tooltip>
                              </v-card-title>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card-text>
               </v-card>

               <v-dialog no-click-animation persistent scrollable
                  max-width="480px"
                  v-model="dialogAddress"
               >
                  <v-card :loading="addressLoading">
                     <v-card-title class="title grey--text darken-4 font-weight-bold pb-2">Address:</v-card-title>

                     <v-card-text class="pb-0">
                        <v-form lazy-validation
                           ref="formAddress"
                           v-model="isAddressFormValid"
                        >
                           <v-text-field persistent-hint required
                              class="pt-0"
                              ref="address1"
                              hint="Address 1"
                              :dense="dense"
                              :rules="[rules.required]"
                              v-model="address.address1"
                           ></v-text-field>
                           <v-text-field clearable persistent-hint
                              class="pt-0"
                              ref="address2"
                              hint="Address 2"
                              :dense="dense"
                              v-model="address.address2"
                           ></v-text-field>
                           <v-text-field persistent-hint required
                              class="pt-0"
                              ref="city"
                              hint="City"
                              :dense="dense"
                              :rules="[rules.required]"
                              v-model="address.city"
                           ></v-text-field>
                           <v-text-field clearable persistent-hint
                              class="pt-0"
                              ref="zipcode"
                              hint="Zip/Postal Code"
                              :dense="dense"
                              v-model="address.zip"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>

                     <v-card-actions class="pt-0 pb-2 pr-3">
                        <div class="flex-grow-1"></div>
                        <v-btn text
                           class="px-0"
                           color="blue darken-1"
                           @click="cancelAddress"
                        >Cancel</v-btn>
                        <v-btn text
                           class="px-0 ml-0"
                           color="blue darken-1"
                           :disabled="!isAddressFormValid"
                           @click="getLatLng"
                        >Save</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-dialog>

            </v-tab-item>

            <!-- Groupby tab -->
            <v-tab-item>
               <v-card flat tile>
                  <v-card-text :class="`${groupby.id ? 'pb-2' : ''}`">
                     <v-row v-if="showTestCases">
                        <v-col>
                           <v-select hide-selected persistent-hint required
                              class="pt-0 mt-0"
                              ref="testCase"
                              hint="Populate a Test Case"
                              :dense="dense"
                              :items="[
                                 { text: 'Distinct', value: 'distinct' },
                                 { text: 'One-level: sum', value: 'sum' },
                                 { text: 'One-level: avg', value: 'avg' },
                                 { text: 'One-level: max', value: 'max' },
                                 { text: 'Two-level: non-date', value: 'two-level_non-date' },
                                 { text: 'Two-level: event date', value: 'two-level_event_date' },
                                 { text: 'Two-level: event code', value: 'two-level_event_code' }
                              ]"
                              v-model="testCase"
                              @change="testCaseChanged"                                 
                           ></v-select>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col xs="12" sm="12" :md="groupbyColSize" class="py-0">
                              <!-- :disabled="initialFilter.groupbyId" -->
                           <v-autocomplete persistent-hint clearable
                              v-model="groupby.id"
                              ref="groupbyId"
                              placeholder="groupby 1"
                              autocomplete="off"
                              :dense="dense"
                              :items="groupbyIdItems"
                              @change="groupbyIdChanged"
                           ></v-autocomplete>
                        </v-col>
                        <v-col v-if="groupby.id && groupbyShowDateType"
                           xs="12" sm="12" :md="groupbyColSize" class="py-0">
                           <v-autocomplete persistent-hint
                              v-model="groupby.dateType"
                              ref="groupbyDateType"
                              autocomplete="off"
                              :dense="dense"
                              :items="groupbyDateTypeItems"
                              @change="formChanged"
                           ></v-autocomplete>
                        </v-col>
                        <v-col v-if="groupby.id && groupbyShowEventData"
                           xs="12" sm="12" :md="groupbyColSize" class="py-0"
                        >
                              <!-- :rules="getGroupbyEventDataRules" -->
                           <v-combobox persistent-hint
                              ref="groupbyEventData"
                              autocomplete="off"
                              placeholder="select or type..."
                              :dense="dense"
                              :items="eventDataFields"
                              :rules="[rules.required]"
                              v-model="groupby.eventData"
                              @change="formChanged"
                           ></v-combobox>
                        </v-col>
                        <v-col v-if="groupby.id && showGroupbyId2"
                           xs="12" sm="12" :md="groupbyColSize2" class="py-0"
                        >
                           <v-autocomplete persistent-hint clearable
                              v-model="groupby.id2"
                              ref="groupbyId2"
                              placeholder="groupby 2"
                              autocomplete="off"
                              :dense="dense"
                              :items="groupbyId2Items"
                              :rules="groupbyId2Rules"
                              @change="groupbyId2Changed"
                           ></v-autocomplete>
                        </v-col>
                        <v-col v-if="groupby.id2 && groupbyShowDateType2"
                           xs="12" sm="12" :md="groupbyColSize2" class="py-0"
                        >
                           <v-autocomplete persistent-hint
                              v-model="groupby.dateType2"
                              ref="groupbyDateType2"
                              autocomplete="off"
                              :dense="dense"
                              :items="groupbyDateTypeItems"
                              @change="formChanged"
                           ></v-autocomplete>
                        </v-col>
                        <v-col v-if="groupby.id2 && groupbyShowEventData2"
                           xs="12" sm="12" :md="groupbyColSize2" class="py-0"
                        >
                           <v-combobox persistent-hint
                              ref="groupbyEventData2"
                              autocomplete="off"
                              :dense="dense"
                              :items="eventDataFields"
                              :rules="[rules.required]"
                              v-model="groupby.eventData2"
                              @change="formChanged"
                           ></v-combobox>
                        </v-col>
                        <v-col v-if="groupby.id"
                           xs="12" sm="12" md="2" class="py-0">
                           <v-autocomplete persistent-hint required
                              v-model="groupby.operator"
                              ref="groupbyOperator"
                              placeholder="accumulator operator"
                              autocomplete="off"
                              :dense="dense"
                              :items="groupbyOperators"
                              :rules="[rules.required]"
                              @change="groupbyOperatorChanged"
                           ></v-autocomplete>
                        </v-col>
                        <v-col v-if="groupby.operator && groupby.operator != 'count' && groupby.operator != 'distinct'"
                           xs="12" sm="12" md="2" class="py-0"
                        >
                           <v-text-field v-if="groupbyFieldHasEventData"
                              clearable persistent-hint
                              class="pt-3"
                              ref="groupbyField"
                              placeholder="on event_data column"
                              :dense="dense"
                              :rules="[rules.required]"
                              v-model="groupby.field"
                           ></v-text-field>
                           <v-autocomplete v-else
                              persistent-hint required
                              v-model="groupby.field"
                              ref="groupbyField"
                              placeholder="on column"
                              autocomplete="off"
                              :dense="dense"
                              :items="groupbyFieldItems"
                              :rules="[rules.required]"
                           ></v-autocomplete>
                        </v-col>
                        <!-- <v-col v-if="groupby.id && groupbyShowEventData3"
                           xs="12" sm="12" :md="groupbyColSize3" class="py-0"
                        >
                           <v-combobox persistent-hint
                              ref="groupbyEventData3"
                              autocomplete="off"
                              :dense="dense"
                              :items="eventDataFields"
                              :rules="[rules.required]"
                              v-model="groupby.eventData3"
                              @change="formChanged"
                           ></v-combobox>
                        </v-col> -->
                     </v-row>
                     <v-row v-if="groupby.id">
                        <v-col xs="12" sm="4" md="2" class="py-0">
                           <v-switch
                              class="mx-0 mt-4 mb-0 pb-0"
                              label="Sort by ID"
                              :dense="dense"
                              v-model="groupby.sort"
                              @change="formChanged"                                 
                           ></v-switch>
                        </v-col>
                        <v-col xs="12" sm="4" md="2" class="pt-2 pb-0">
                              <!-- :items="[50, 100, 150, 200, 250, 300]" -->
                              <!-- :items="[5, 10, 15, 20, 25, 30, 40, 50, 75, 100, 150, 200, 250, 300]" -->
                           <v-select hide-selected persistent-hint required
                              class="pt-0 mt-0"
                              ref="limit"
                              hint="Max number of records"
                              :dense="dense"
                              :items="[5, 10, 15, 20, 25, 30, 40, 50, 75, 100, 150, 200, 250, 300]"
                              :rules="[rules.required]"
                              v-model="groupby.limit"
                              @change="formChanged"                                 
                           ></v-select>
                        </v-col>
                     </v-row>
                  </v-card-text>
               </v-card>
            </v-tab-item>

            <!-- Columns tab -->
            <v-tab-item>
               <v-card flat tile>
                  <v-card-text>
                     <v-row>
                        <v-col xs="12" sm="12" md="12" class="py-0">
                           <v-autocomplete chips deletable-chips multiple persistent-hint small-chips required
                              v-model="selectedFields"
                              hint="select columns that you'd like to see their values"
                              autocomplete="off"
                              :counter="fields.length"
                              :dense="dense"
                              :items="fields"
                              :rules="[rules.required]"
                           ></v-autocomplete>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col xs="12" sm="12" md="12" class="pt-0 pb-2">
                           <v-text-field autofocus persistent-hint 
                              ref="extraFields"
                              :dense="dense"
                              hint="specify comma-separated-columns that you'd like to see their values but don't exist in the above dropdown"
                              :rules="[rules.extraFields]"
                              v-model="extraFields"
                              @keyup="extraFieldsChanged"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col xs="12" sm="12" md="4" class="py-0"><!-- pt-2 pb-0 -->
                           <v-autocomplete clearable persistent-hint
                              v-model="sortField"
                              hint="select the column that you'd like to sort results on"
                              autocomplete="off"
                              :dense="dense"
                              :items="sortItems"
                              @change="formChanged"
                           ></v-autocomplete>
                        </v-col>
                        <v-col v-if="sortField"
                           xs="12" sm="12" md="2"
                           class="py-0"
                        >
                           <v-select persistent-hint
                              v-model="sortOrder"
                              hint="sort order"
                              :dense="dense"
                              :items="sortOrderItems"
                              @change="formChanged"
                           ></v-select>
                        </v-col>
                     </v-row>
                  </v-card-text>
               </v-card>
            </v-tab-item>

            <!-- Predefined tab -->
            <v-tab-item>
               <v-card flat tile>
                  <v-card-text class="pt-2 pb-5">
                     <div class="font-weight-bold font-italic">{{predefinedTitle}}</div>
                     <v-row v-if="!action" class="pt-2">
                        <!-- <v-col :xs="isAdmin?8:12" :sm="isAdmin?8:12" :md="isAdmin?9:12" class="py-0">Under construction... -->
                        <v-col cols="12" class="pt-3 pb-0">
                           <v-layout>
                              <v-select clearable hide-selected persistent-hint return-object
                                 class="pt-0 mt-0"
                                 ref="predefined"
                                 :dense="dense"
                                 :hint="`${predefinedItems.length} Predefined Filters`"
                                 :placeholder="`${predefinedItems.length ? 'select a filter' : $t('no-data-text', { value: 'filter' })}`"
                                 :items="predefinedItems"
                                 v-model="selectedPredefined"
                                 @change="predefinedFilterChanged"                                 
                              ></v-select>
                              <div v-if="isAdmin"
                                 class="pl-2 pr-0 pt-4"
                              >
                                 <v-btn small icon
                                    color="warning"
                                    @click="btnAddPredefinedClicked"
                                 >
                                    <v-icon>add</v-icon>
                                 </v-btn>
                                 <v-btn small icon
                                    color="primary"
                                    :disabled="!selectedPredefined"
                                    @click="btnEditPredefinedClicked"
                                 >
                                    <v-icon>edit</v-icon>
                                 </v-btn>
                                 <v-btn small icon
                                    color="error"
                                    :disabled="!selectedPredefined"
                                    @click="btnDeletePredefinedClicked"
                                 >
                                    <v-icon>delete</v-icon>
                                 </v-btn>
                              </div>
                           </v-layout>
                        </v-col>
                     </v-row>
                  </v-card-text>
               </v-card>

               <v-card v-if="selectedPredefined || action === 'add'"
                  :loading="predefinedLoading"
               >
                  <v-card-text class="py-0">
                     <v-form lazy-validation
                        ref="predefinedForm"
                        v-model="isPredefinedFormValid"
                     >
                        <v-row v-if="action">
                           <v-col class="pt-0">
                              <v-layout>
                                 <v-text-field autofocus required
                                    ref="predefinedName"
                                    class="pt-2"
                                    :dense="dense"
                                    :placeholder="`${action==='delete' ? 'enter the name of the filter to be deleted' : 'enter a name for the filter'}`"
                                    :hint="`${predefined.name ? 'Filter Name' : ''}`"
                                    :rules="predefinedNameRules"
                                    v-model="predefined.name"
                                 ></v-text-field>
                              </v-layout>
                           </v-col>
                        </v-row>
                        <v-row v-if="predefined">
                           <v-col xs="12" sm="12" md="12" lg="12" class="py-0">
                              <v-textarea required outlined
                                 ref="predefinedQuery"
                                 class="pt-5 mt-0"
                                 label="Aggregate Query in JSON Format"
                                 :dense="dense"
                                 :readonly="action != 'add' && action != 'edit'"
                                 rows="6"
                                 :rules="[rules.required, rules.validJsonArray]"
                                 v-model="predefined.query"
                              ></v-textarea>
                           </v-col>
                           <!-- <v-col xs="12" sm="12" md="3" lg="2" class="py-0">
                              <v-switch
                                 class="mt-4 mb-0 pb-0"
                                 :disabled="action != 'add' && action != 'edit'"
                                 label="Show in Subaccounts"
                                 v-model="predefined.showInSubaccounts"
                              ></v-switch>
                           </v-col> -->
                        </v-row>
                        <v-row v-if="predefined">
                           <v-col xs="12" sm="12" md="3" lg="3" class="py-0">
                              <v-switch
                                 class="my-0 pb-0 pt-3"
                                 label="Show in Subaccounts"
                                 :dense="dense"
                                 :disabled="action != 'add' && action != 'edit'"
                                 v-model="predefined.showInSubaccounts"
                              ></v-switch>
                           </v-col>
                           <v-col xs="12" sm="12" md="3" lg="3" class="py-0">
                              <v-switch
                                 class="my-0 pb-0 pt-3"
                                 label="Is Range Query?"
                                 :dense="dense"
                                 :disabled="action != 'add' && action != 'edit'"
                                 @change="rangeQueryChanged"
                                 v-model="predefined.isRangeQuery"
                              ></v-switch>
                           </v-col>
                           <v-col v-if="predefined.isRangeQuery"
                              xs="12" sm="12" md="6" lg="6" class="py-0"
                           >
                              <v-autocomplete persistent-hint
                                 ref="rowDimension"
                                 placeholder="select a field name"
                                 autocomplete="off"
                                 :dense="dense"
                                 :disabled="action != 'add' && action != 'edit'"
                                 :items="rowDimensionItems"
                                 :rules="rowDimensionRules"
                                 v-model="predefined.rowDimension"
                              ></v-autocomplete>
                           </v-col>
                        </v-row>
                     </v-form>
                  </v-card-text>
                  <v-card-actions v-if="action"
                     class="py-0 pr-3"
                  >
                     <div class="flex-grow-1"></div>
                     <v-btn text
                        class="px-0"
                        color="blue darken-1"
                        @click="cancelPredefined"
                     >Cancel</v-btn>
                        <!-- class="px-0 ml-0" -->
                     <v-btn text
                        color="blue darken-1"
                        :disabled="!isPredefinedFormValid"
                        @click="actionFunc"
                     >{{actionText}}</v-btn>
                  </v-card-actions>
               </v-card>
            </v-tab-item>

            <!-- Full Text tab -->
            <v-tab-item>
               <v-card flat tile>
                  <v-card-text>
                     <v-row>
                        <v-col xs="12" sm="12" md="12" class="py-0">Under construction...
                        </v-col>
                     </v-row>
                  </v-card-text>
               </v-card>
            </v-tab-item>
         </v-tabs-items>
      </v-tabs>

      <v-layout class="pt-4 px-0">
         <v-btn v-if="isAdmin && !selectedPredefined"
            text small
            color="blue darken-1"
            @click="showFilter=!showFilter"
         >{{showFilter ? 'Hide' : 'Show'}} Filter</v-btn>
         <v-btn v-if="isAdmin && selectedPredefined"
            text small
            color="blue darken-1"
            @click="showFilterResult=!showFilterResult"
         >{{showFilterResult ? 'Hide' : 'Show'}} Filter's Result</v-btn>
         <div class="flex-grow-1"></div>
         <v-btn text small
            color="blue darken-1"
            :disabled="filters.length === 1 && behFilters.length === 1 && !groupby.id && !byLocation.distance"
            @click="resetClicked"
         >Reset</v-btn>
         <v-btn v-if="showCancelBtn"
            text small
            color="blue darken-1"
            @click="cancelClicked"
         >Cancel</v-btn>
         <v-btn text small
            color="blue darken-1"
            :disabled="isFormValid"
            @click="filterChanged"
         >Apply
            <!-- <v-icon v-if="isStandardFormValid && isColumnsFormValid && isApplyDisabled">check</v-icon> -->
            <v-icon v-if="isFormValid">check</v-icon>
         </v-btn>
      </v-layout>

      <v-card v-if="showFilter">
         <v-card-text><pre>{{filterOut.standard}}</pre></v-card-text>
      </v-card>

      <br />

      <v-card v-if="showFilterResult">
         <v-card-text>
            <v-row v-for="(item, i) in filterResult" :key="i" class="py-0">
               <v-col class="py-0">{{JSON.stringify(item)}}</v-col>
            </v-row>
         </v-card-text>
      </v-card>

      <v-overlay :value="overlay">
         <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
   </v-form>
</template>

<script>
import { isIncluded, isValidDate, getAllBehavioralFields, getEventDataFields } from '../mixins/bt-mixin.js';
import { format, isAfter, parseISO } from "date-fns";

const NAME = "BtFilterDefiner";
const VERSION = 'V240808.1';
const TABS = ['standard', 'behavioral', 'bylocation', 'groupby', 'columns', 'predefined', 'fulltext'];
const GROUPBY_OPERATORS = ['sum', 'max', 'min', 'avg', 'count', 'distinct'];
const ISO_FORMAT = "yyyy-MM-dd";
const DISPLAY_FORMAT = "MM/dd/yyyy";
// const TODAY = format(new Date(), ISO_FORMAT);
const GEOCODE_URL = "https://maps.googleapis.com/maps/api/geocode/json?address=";
const DATE_PATTERN = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

class StdFilter {
   constructor(type, operator) {
      this.field = '';
      this.type = type || ''
      this.operator = operator || '';
      this.value = '';
      this.formattedDate = '';
      this.menu = false;
      this.value2 = '';
      this.formattedDate2 = '';
      this.menu2 = false;
   }
}

class BehFilter {
   constructor() {
      this.field = '';
      this.custom = '';
      this.eventData = '';
      this.type = '';
      this.isTypeDisabled = false;
      this.operator = '';
      this.value = '';
      this.formattedDate = '';
      this.menu = false;
      this.colSize = 4; //5;
      this.operators = [];
      this.value2 = '';
      this.formattedDate2 = '';
      this.menu2 = false;
   }
}

class ByLocation {
   constructor() {
      this.distance = '';
      this.latitude = '';
      this.longitude = '';
   }
}

class Address {
   constructor() {
      this.address1 = '';
      this.address2 = '';
      this.city = '';
      this.state = '';
      this.zip = '';
      this.country = '';
   }
}

class Groupby {
   constructor(initialFilter) {
      this.id = initialFilter?.groupby || '';
      this.id2 = '';
      this.dateType = ''
      this.dateType2 = ''
      this.eventData = ''
      this.eventData2 = ''
      this.eventData3 = ''
      this.operator = '';
      this.field = '';
      this.sort = false;
      this.limit = 50;  //300;
   }
}

class Predefined {
   constructor(initData) {
      // alert(JSON.stringify(initData));
      this.query = initData.aggregateQuery ? initData.aggregateQuery : '';
      this.name = initData.name || '';
      this.showInSubaccounts = initData.showInSubaccounts || false;
      this.isRangeQuery = initData.isRangeQuery || false;
      this.rowDimension = initData.rowDimension || '';
      // this.colDim = initData.colDim || '';
   }
}

export default {
   name: NAME,

   props: {
      value: {
         type: Object,
         default: () => ({})
      },
      activeTab: {
         type: String,
         default: "standard",
         validator: value => { return TABS.indexOf(value.toLowerCase()) !== -1 }
      },
      behFieldValues: {
         type: Object,
         default: () => ({}) //{'field-name-1': [], ..., 'field-name-2': []}
      },
      btHelpers: {
         type: Object,
         //how to check conditional required
      },
      // closedOnLoad: {
      //    type: Boolean,
      //    default: true
      // },
      database: {
         type: String,
         default: "mongo-db",
         validator: value => { return ["mongo-db"].indexOf(value.toLowerCase()) !== -1 }
      },
      debug: {
         type: Boolean,
         default: false
      },
      dense: {
         type: Boolean,
         default: false
      },
      fields: {
         type: Array,
         required: true,
         default: () => []
      },
      filterResult: {
         type: Array,
         required: false,
         default: () => []
      },
      // V240808
      groupbyFieldHasEventData: {
         type: Boolean,
         default: false
      },
      groupbyId2Required: {
         type: Boolean,
         default: false
      },
      groupbyOperators: {
         type: Array,
         default: () => GROUPBY_OPERATORS,
         validator: value => { 
            value.forEach(v => {
               if (GROUPBY_OPERATORS.indexOf(v.toLowerCase()) !== -1)
                  return false;
            });
            return true;
         }
      },
      includedTabs: {
         type: Array,
         default: () => TABS,
         validator: value => { 
            value.forEach(v => {
               if (TABS.indexOf(v.toLowerCase()) !== -1)
                  return false;
            });
            return true;
         }
      },
      //{ standard: [field names] }
      initialFilter: {
         type: Object,
         default: () => ({})
      },
      isAdmin: {
         type: Boolean,
         default: false
      },
      max: {
         type: Number,
         default: Number.MAX_VALUE
      },
      maxNumOfImports: {
         type: Number,
         default: 6
      },
      //TODO: how to validate values with names
      operators: {
         type: Array,
         default: () => [
            { text: "Equals (=)", value: "=" },
            { text: "Not Equals (!=)", value: "!=" },
            { text: "Contains", value: "contains" },
            { text: "Starts with", value: "starts" },
            { text: "Ends with", value: "ends" },
            { text: "Greater than (>)", value: ">" },
            { text: "Greater than or equal (>=)", value: ">=" },
            { text: "Less than (<)", value: "<" },
            { text: "Less than or equal (<=)", value: "<=" },
            { text: "In", value: "in" },
            { text: "Not in", value: "nin" },
            { text: "Regex", value: "regex" },
            { text: "Between", value: "between" }
         ],
         validator: value => { return value && value.length > 0 && value.length <= 13 }
      },
      parentComponent: {
         type: String
      },
      predefinedFilters: {
         type: Array,
         default: () => [] //{ _id: '', name: '' }
      },
      preselectedFields: {
         type: Array,
         default: () => []
      },
      removeIconName: {
         type: String,
         default: "delete_forever"
      },
      removeIconColor: {
         type: String,
         default: "grey darken-1"
      },
      //TODO: is required?
      required: {
         type: Boolean,
         default: false
      },
      shouldInit: {
         type: Boolean,
         default: false
      },
      shouldValidate: {
         type: Boolean,
         default: false
      },
      showCancelBtn: {
         type: Boolean,
         default: true
      },
      showExtraFields: {
         type: Boolean,
         default: false
      },
      showGroupbyId2: {
         type: Boolean,
         default: true
      },
      stdFieldValues: {
         type: Object,
         default: () => ({}) //{'field-name-1': [], ..., 'field-name-2': []}
      },
      //TODO: how to validate values with names
      types: {
         type: Array,
         default: () => ["string", "number", "boolean", "date"]
         // validator:
      },
      //for my testing purposes only
      showTestCases: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         rules: {
            required: value => !!value || 'Value is required!',
            noComma: value => !value || value.indexOf(',') === -1 || 'Value cannot include comma',
            list(filter) {
               return value => {
                  let result = true;
                  value.split(',').forEach(val => {
                     const v = val.trim();
                     if (!v || (filter.type === 'number' && v != Number(v)))
                        result = false;
                  });
                  return result || 'Value is invalid!';
               }
            },
            date(value) {
               return isValidDate(value) || 'Value is invalid!';
            },
            latitude: value => (value >= -90 && value <= 90) || 'Value should be between -90 and 90!',
            longitude: value => (value >= -180 && value <= 180) || 'Value should be between -180 and 180!',
            duplicate: value => this.behFilters.filter(f => f.custom.toLowerCase() === value.toLowerCase()).length <= 1 || 
               'Value is duplicate!',
            validJson: value => {
               try {
                  if (value) {
                     if (value.trim().indexOf('"') === 0)
                        return 'SyntaxError: Unexpected token in JSON at position 0';
                     JSON.parse(value);
                  }
                  return true;
               } catch (error) {
                  return error.toString();
               }
            },
            validJsonArray: value => {
               try {
                  let json;
                  if (value) {
                     json = JSON.parse(value);
                     return Array.isArray(json) || 'Should be an array';
                  } else return true;
               } catch (error) {
                  return error.toString();
               }
            },
            same: value => value === this.selectedPredefined.text || "Name doesn't match!",
            requiredImport: value => value.length > 0 || "At lease one Import should be selected!",
            tooManyImports: value => value.length <= this.maxNumOfImports || `Not more than ${this.maxNumOfImports} Imports can be selected!`,
            extraFields: value => {
               let result = '';
               const items = this.getListItems(value);
               if (items.length) {
                  const processedItems = [];
                  items.forEach(v => {
                     if (!v)
                        result += 'Field cannot be empty! ';
                     else if (v.includes(' '))
                        result += `Field cannot have spaces: ${v}! `;
                     else if (this.fields.find(f => f.value === v))
                        result += `Field cannot exist in the dropdown: ${v}! `;
                     else if (processedItems.find(f => f === v))
                        result += `Field is duplicate: ${v}! `;
                     else processedItems.push(v);
                  });
               }
               return result === '' || result;
            },
            greaterThanNumber(to, from) {
               // alert(`from=${from}, to=${to}`)
               return +to == 0 || Number(to) > Number(from) || `Value should be greater than ${from}!`;
            },
            greaterThanDate(to, from) {
               // alert(`from=${from}, to=${to}`)
               return !to || isAfter(parseISO(to), parseISO(from)) || `To date should be greater than From date!`;
            }
         },
         booleanItems: ['true', 'false'],
         tab: null,
         tabSettings: [],
         isFilterFormValid: true,
         isStandardFormValid: true,
         isBehavioralFormValid: true,
         isLocationFormValid: true,
         isFulltextFormValid: true,
         isPredefinedFormValid: true,
         isGroupbyFormValid: true,
         isColumnsFormValid: true,
         isApplied: true,  //false,
         filters: [],
         filtersMax: 0,
         selectedFields: [],
         // pickerMax: TODAY,
         showFilter: false,
         showFilterResult: false,
         filterOut: '',
         // operatorItems: [],
         groupby: new Groupby(),
         // groupby: new Groupby(this.initialFilter),
         groupbyColSize: 4,
         groupbyColSize2: 4,
         groupbyColSize3: 4,
         // originalGroupbyIdItems: [],
         groupbyIdItems: [],
         groupbyId2Items: [],
         groupbyShowDateType: false,
         groupbyShowDateType2: false,
         groupbyDateTypeItems: [
            { text: 'Yearly', value: 'year'},
            { text: 'Monthly', value: 'month'},
            { text: 'Daily', value: 'dayOfMonth'},
            { text: 'Hourly', value: 'hour'},
            { text: 'Day of the Week', value: 'dayOfWeek'},
            { text: 'Yearly-Monthly', value: 'year-month'},
            { text: 'Monthly-Daily', value: 'month-dayOfMonth'}
         ],
         groupbyShowEventData: false,
         groupbyShowEventData2: false,
         // eventDataItems: [
         //    { text: 'Program', value: '__program'},
         //    { text: 'Campaign', value: '__campaign'},
         //    { text: 'Outbound', value: '__outbound'},
         //    { text: 'Schedule', value: '__schedule'},
         //    { text: 'Goal', value: '__goal'},
         //    { text: 'Inbound', value: '__inbound'},
         //    { text: 'Inbound Child', value: '__inboundChild'}
         // ],
         eventDataFields: getEventDataFields(),

         byLocation: new ByLocation(),

         // behFields: [
         //    { text: 'App Code', value: 'app_code', type: 'string' },
         //    { text: 'Browser', value: 'browser', type: 'string' },
         //    { text: 'Event Code', value: 'event_code', type: 'string' },
         //    { text: 'Event Date', value: 'event_date', type: 'date' },
         //    { text: 'IP', value: 'ip', type: 'string' },
         //    { text: 'Is Trigger', value: 'is_trigger', type: 'boolean' },
         //    { text: 'Operating System', value: 'os', type: 'string' },
         //    { text: 'Process Definition ID', value: 'pdid', type: 'string' },
         //    { text: 'Custom...', value: '__custom__', type: '' }
         // ],
         behFields: getAllBehavioralFields(),
         behFilters: [],
         behFiltersMax: 0,
         sortItems: [],
         sortField: '',
         sortOrderItems: [
            { text: 'Ascending', value: 1 },
            { text: 'Descending', value: -1 }
         ],
         sortOrder: 1,
         groupbyFieldItems: [],
         testCase: '',
         dialogAddress: false,
         addressLoading: false,
         isAddressFormValid: true,
         address: new Address(),
         overlay: false,
         predefined: null,
         predefinedItems: [],
         selectedPredefined: '',
         action: '',
         predefinedLoading: false,
         actionText: '',
         actionFunc: null,
         predefinedTitle: '',
         predefinedNameRules: [],
         rowDimensionItems: [],
         rowDimensionRules: [],
         extraFields: ''
      }
   },

   computed: {
      isFormValid() {
         // alert(`in isFormValid: isApplied=${this.isApplied}, isFilterFormValid=${this.isFilterFormValid}`);
         const result = this.isApplied && this.isFilterFormValid;
         return result;
      },

      // actionText() {
      //    switch (this.action) {
      //       case 'add':
      //          return 'SAVE';
      //       case 'edit':
      //          return 'UPDATE';
      //       case 'delete':
      //          return 'DELETE';
      //       default:
      //          return '';
      //    }
      // },

      // actionFunc() {
      //    switch (this.action) {
      //       case 'add':
      //          return this.savePredefined;
      //       case 'edit':
      //          return this.updatePredefined;
      //       case 'delete':
      //          return this.deletePredefined;
      //       default:
      //          return (() => {});
      //    }
      // },

      // predefinedClass() {
         // if (this.isAdmin)
            // return this.isPredefinedFormValid ? 'black--text' : 'red--text';
         // else
         //    return 'grey--text';
      // },

      groupbyId2Rules() {
         return this.groupbyId2Required ? [this.rules.required] : [];
      }
   },

   watch: {
      shouldInit: {
         immediate: true,
         async handler(val) {
            // this.log('in ' + NAME + '.shouldInit(): shouldInit=' + val + '\nthis.value=' + JSON.stringify(this.value));
            // this.alert('in ' + NAME + '.shouldInit(): shouldInit=' + val + '\nthis.value=' + JSON.stringify(this.value));
            this.filters = [];
            if (!val) return;

            this.filterOut = this.value;
            this.isApplied = true;
            this.isFilterFormValid = true;

            this.fieldsChanged();
            this.behFiltersMax = Math.min(this.behFields.length, this.max);
            this.behFields.forEach(field => {
               if (field.value != '__custom__')
                  field.operators = this.getBehOperators(field.value, field.type);
            });

            const filterKeys = Object.keys(this.value);

            if (this.includedTabs.includes('predefined'))
               await this.getPredefinedFilters();

            if (filterKeys.includes('predefined'))
               this.parseFilterKey('predefined');
            else if (filterKeys.includes('standard'))
               this.parseFilterKey('standard');

            if (filterKeys.includes('columns'))
               this.parseFilterKey('columns');

            if (filterKeys.includes('sort'))
               this.parseFilterKey('sort');
            
            // // // if (filterKeys.length === 0)
            // // //    this.addStdFilter();
            // // // this.addBehFilter();
            // // // this.selectedFields = [...this.preselectedFields];

            this.isApplied = true;
            // this.log('in shouldInit(): myFieldMax=' + this.myFieldMax);
         }
      },

      fields: {
         immediate: true,
         deep: true,
         handler(val) {
            // alert('in ' + NAME + '.fields watcher: len=' + val.length + '\nval=' + JSON.stringify(val));
            this.fieldsChanged();
            this.groupbyIdItems = [
               ...val.filter(f => f.value != 'purl' && f.value != 'basePurl'),   //V240617:  && f.isIndexed
               ...this.behFields.filter(f => f.value != 'ip' && f.value != 'pdid' && f.value != '__custom__')
            ];

            this.rowDimensionItems = val.filter(f => f.isIndexed).map(f => ({ text: f.text, value: f.value }));
            // alert('rowDimensionItems=' + JSON.stringify(this.rowDimensionItems));

            //B2.10: Was moved into parseFilterKey()
            // if (this.groupby.id) {
            //    this.groupbyIdChanged(this.groupby.id, true);
            //    if (this.groupby.id2)
            //       this.groupbyId2Changed(this.groupby.id2, true);
            // }
         }
      },

      filters: {
         deep: true,
         handler(val) {
            // alert(`in ${NAME}.filters watch: val=${JSON.stringify(val)}`);
            if (val.length > 1) {
               this.$refs.form.validate();
            }
            this.formChanged();
         }
      },

      behFilters: {
         deep: true,
         handler(val) {
            // alert(`in ${NAME}.behFilters watch: val=${JSON.stringify(val)}`);
            if (val.length > 1) {
               this.$refs.form.validate();
            }
            this.formChanged();
         }
      },

      selectedFields: {
         immediate: true,
         deep: true,
         handler(val) {
            // alert(`in ${NAME}.selectedFields watch: val=${JSON.stringify(val)}`);
            if (val.length > 1 && this.$refs && this.$refs['form']) { 
               this.$refs.form.validate();
            }

            // this.sortItems = this.fields.filter(field => isIncluded(this.selectedFields, field.value));
            // if (!isIncluded(this.selectedFields, this.sortField))
            //    this.sortField = '';

            this.getSortItems();
            this.formChanged();
         }
      },

      predefinedFilters: {
         immediate: true,
         deep: true,
         handler(val) {
            this.predefinedItems = [...val];
         }
      },

      action: {
         immediate: true,
         deep: false,
         handler(val) {
            switch (val) {
               case 'add':
                  this.actionText = 'SAVE';
                  this.actionFunc = this.savePredefined;
                  this.predefinedTitle = 'Adding a new filter...';
                  this.predefinedNameRules = [this.rules.required, this.rules.duplicate];
                  break;
               case 'edit':
                  this.actionText = 'UPDATE';
                  this.actionFunc = this.updatePredefined;
                  this.predefinedTitle = `Editing '${this.predefined.name}' filter...`;
                  this.predefinedNameRules = [this.rules.required, this.rules.duplicate];
                  break;
               case 'delete':
                  this.actionText = 'DELETE';
                  this.actionFunc = this.deletePredefined;
                  this.predefinedTitle = `Type '${this.selectedPredefined.text}' to delete the filter...`;
                  this.predefinedNameRules = [this.rules.required, this.rules.same];
                  break;
               default:
                  // return '';
                  // return (() => {});
                  this.predefinedTitle = 'Please note that if you select a predefined filter, your selections in the other tabs will be ignored.';
                  this.predefinedNameRules = [];
            }
         }
      },

      includedTabs: {
         immediate: false,//true,
         deep: true,
         handler(val) {
            // this.alert('in ' + NAME + '.includedTabs watcher: val=' + JSON.stringify(val));
            if (val) {
               // console.log('in includedTabs');
                     console.warn('calling setTabSettings from watch');

               this.setTabSettings();
               // alert('getSortItems')
               this.getSortItems();
            }
         }
      }
   },

   methods: {
      alert(msg) {
         if (this.debug) {
            alert(`${NAME} ${VERSION} says:\n${msg}`);
         }
      },

      log(msg, always =false) {
         if (this.debug || always) {
            console.log(`-----${NAME} ${VERSION} says => ${msg}`);
         }
      },

      setTabSettings() {
         // console.log('in setTabSettings');
         this.tabSettings = [];
         const myIncludedTabs = this.includedTabs.join(',').toLowerCase().split(',');
         const myActiveTab = this.activeTab.toLowerCase();
         TABS.forEach((t, i) => {
            const included = myIncludedTabs.indexOf(t) > -1;
            this.tabSettings.push(included);
            if (included && t === myActiveTab) this.tab = i;
         });
         // alert(JSON.stringify(this.tabSettings));
      },

      parseFilterKey(key) {
         let keyVal = this.value[key];
         // alert(`in parseFilterKey(): key=${key}, val=${JSON.stringify(keyVal)}`);
         if (key === 'predefined') {
            this.addStdFilter();
            this.addBehFilter();
            const selectedItem = this.predefinedItems.find(item => item.value === keyVal);
            if (selectedItem) {
               this.selectedPredefined = selectedItem;
               this.predefinedFilterChanged(selectedItem);
            }
         } else if (key === 'standard') {
            let item;
            const filters = [];
            const behFilters = [];
            const $match = keyVal.find(kv => kv.$match).$match;
            this.log('new $match=' + JSON.stringify($match) +
               '\nold $match=' + JSON.stringify(keyVal[0].$match));
            Object.keys($match).forEach(matchKey => {
               const matchVal = $match[matchKey];
               this.log('matchKey=' + matchKey + ', matchVal=' + JSON.stringify(matchVal));
               if (matchKey === 'events') {  //behavioral tab
                  // this.log('matchKey=' + matchKey + ', matchVal=' + JSON.stringify(matchVal));
                  const events = matchVal.$elemMatch;
                  // this.log('events=' + JSON.stringify(events));
                  Object.keys(events).forEach(eventKey => {
                     // this.log('eventKey=' + eventKey);
                     const results = this.parseSubfilter(eventKey, events[eventKey]);
                     // this.log('results=' + JSON.stringify(results) + '\nbehFields='+JSON.stringify(this.behFields));

                     // we skip parsing date picker injection as it has not been supported in the UI.
                     if (!results[0] && eventKey === 'event_date')
                        return;

                     item = new BehFilter();
                     item.operator = results[0];
                     item.value = results[1];
                     if (results.length === 3)
                        item.value2 = results[2];

                     //V230526.1
                     let newEventKey;
                     if (eventKey.startsWith('event_data.')) {
                        newEventKey = 'event_data';
                        const edName = eventKey.replace('event_data.', '');
                        const edField = this.eventDataFields.find(f => f.value === edName);
                        // item.eventData = edField ? edField : edName;
                        if (edField) {
                           item.eventData = edField;
                           // item.type = 'number';
                           item.isTypeDisabled = true;
                        } else {
                           item.eventData = edName;
                           const oVal = item.operator === 'in'
                              ? events[eventKey][Object.keys(events[eventKey])[0]][0]
                              : item.value;
                           item.type = typeof oVal;

                           if (item.type === 'string' && DATE_PATTERN.test(oVal))
                              item.type = 'date';

                           // item.operators = this.getOperators(item.type);
                           item.isTypeDisabled = false;
                        }
                        item.colSize = 2; //3;
                     } else {
                        newEventKey = eventKey;
                        item.isTypeDisabled = true;
                     }

                     const myField = this.behFields.find(f => f.value === newEventKey);
                     // this.log('myField=' + JSON.stringify(myField));
                     // alert('myField=' + JSON.stringify(myField));

                     if (myField) {
                        item.field = newEventKey;

                        // item.type = myField.type;
                        if (!item.type)
                           item.type = myField.type;

                        item.operators = myField.operators;
                     } else {
                        item.field = '__custom__';
                        item.custom = eventKey;
                        item.type = typeof item.value;
                        item.operators = this.getOperators(item.type);
                        item.colSize = 2; //3;
                     }

                     if (item.type === 'date') {
                        // item.formattedDate = format(parseISO(item.value), DISPLAY_FORMAT);
                        item.formattedDate = this.formatDate(item.value);
                        if (item.value2)
                           item.formattedDate2 = this.formatDate(item.value2);
                     } else if (item.type === 'boolean')   //V230608.1
                        item.value = item.value.toString();

                     // this.log('item='+JSON.stringify(item));
                     behFilters.push(item);
                  });
               } else if (matchKey === 'location') {  //by location tab
                  const locVal = matchVal.$geoWithin.$centerSphere;
                  this.byLocation = new ByLocation();
                  this.byLocation.distance = locVal[1] * 3963.2;
                  this.byLocation.latitude = locVal[0][0];
                  this.byLocation.longitude = locVal[0][1];
               } else { //standard tab
                  const results = this.parseSubfilter(matchKey, matchVal);
                  // this.log('result=' + JSON.stringify(results) + '\nfields='+JSON.stringify(this.fields));
                  const myField = this.fields.find(f => f.value === matchKey);
                  // this.log('myField=' + JSON.stringify(myField));
                  if (myField) {
                     item = new StdFilter(myField.type, results[0]);
                     item.field = matchKey;
                     item.value = results[1];
                     if (results.length === 3)
                        item.value2 = results[2];
                     item.operators = myField.operators?.length > 0 ? myField.operators : this.getOperators(item.type); //B2.1                     
                     item.internalField = Boolean(myField.internalField);
                     item.systemField = Boolean(myField.systemField);   //V230817
                     if (myField.type === 'date') {
                        // item.formattedDate = format(parseISO(item.value), DISPLAY_FORMAT);
                        item.formattedDate = this.formatDate(item.value);
                        if (item.value2)
                           item.formattedDate2 = this.formatDate(item.value2);
                     } else if (myField.type === 'boolean')   //V230608.1
                        item.value = item.value.toString();

                     // this.log('item=' + JSON.stringify(item));
                     filters.push(item);
                  } else
                     alert(`Unexpected field in standard filter: "${matchKey}"`);
               }
            });

            this.filters = filters;
            this.addStdFilter();
            this.behFilters = behFilters;
            this.addBehFilter();

            const $groupObj = keyVal.find(kv => kv.$group);
            if ($groupObj) {
               this.groupby = new Groupby();
               // this.groupby = new Groupby(this.initialFilter);
               const $limitObj = keyVal.find(kv => kv.$limit);
               if ($limitObj)
                  this.groupby.limit = $limitObj.$limit;
               
               const $group = keyVal.find(kv => kv.$group).$group;
               // alert('$group=' + JSON.stringify($group));
               const $sort = keyVal.find(kv => kv.$sort).$sort;
               if (Object.keys($sort)[0].toString() === '_id')
                  this.groupby.sort = true;

               Object.keys($group).forEach(groupKey => {
                  const groupVal = $group[groupKey];
                  // alert('groupKey=' + groupKey + ', groupVal=' + JSON.stringify(groupVal));
                  if (groupKey === '_id') {
                     /* V240709.1 - start */
                     let newGroupVal = {};
                     const groupValKeys = Object.keys(groupVal);
                     if (groupValKeys.includes('month')) {
                        const yearIndex = groupValKeys.findIndex(k => k === 'year');
                        if (yearIndex === 0) {
                           newGroupVal['year-month'] = { '$year-month': groupVal.month.$month };
                           if (groupValKeys.length === 3)
                              newGroupVal[groupValKeys[2]] = groupVal[groupValKeys[2]];
                        } else if (yearIndex === 1) {
                           newGroupVal[groupValKeys[0]] = groupVal[groupValKeys[0]];
                           newGroupVal['year-month'] = { '$year-month': groupVal.month.$month };
                        } else {
                           const dayIndex = groupValKeys.findIndex(k => k === 'dayOfMonth');
                           if (dayIndex === 1) {
                              newGroupVal['month-dayOfMonth'] = { '$month-dayOfMonth': groupVal.month.$month };
                              if (groupValKeys.length === 3)
                                 newGroupVal[groupValKeys[2]] = groupVal[groupValKeys[2]];
                           } else if (dayIndex === 2) {
                              newGroupVal[groupValKeys[0]] = groupVal[groupValKeys[0]];
                              newGroupVal['month-dayOfMonth'] = { '$month-dayOfMonth': groupVal.month.$month };
                           }
                        }
                     }

                     if (!Object.keys(newGroupVal).length)
                        newGroupVal = groupVal;
                     /* V240709.1 - end */

                     Object.keys(newGroupVal).forEach((idKey, i) => {
                        const postfix = (i === 0 ? '' : '2');
                        const dateTypeItem = this.groupbyDateTypeItems.find(dt => dt.value === idKey);
                        let fldName;
                        if (dateTypeItem) {
                           const dateTypeVal = dateTypeItem.value;
                        
                           this.groupby['dateType' + postfix] = dateTypeVal;
                           fldName = newGroupVal[idKey]['$' + dateTypeVal];
                        } else
                           fldName = newGroupVal[idKey];

                        //V230526.1
                        if (fldName.startsWith('$events.event_data.')) {
                           this.groupby['id' + postfix] = 'event_data';
                           const edName = fldName.replace('$events.event_data.', '').replace('$', '');
                           const edField = this.eventDataFields.find(f => f.value === edName);
                           this.groupby['eventData' + postfix] = edField ? edField : edName;
                        } else
                           this.groupby['id' + postfix] = fldName.replace('$events.', '').replace('$', '');
                     });

                     //B2.10: moved this snipet from within fields watch
                     if (this.groupby.id) {
                        this.groupbyIdChanged(this.groupby.id, true);
                        if (this.groupby.id2) {
                           this.groupbyId2Changed(this.groupby.id2, true);
                        }
                     }
                  } else {
                     const operator = Object.keys(groupVal)[0];
                     //alert('in else: groupVal=' + JSON.stringify(groupVal));
                     if (groupVal[operator] != 1) {
                        this.groupby.operator = operator.replace('$', '');
                        this.groupby.field = this.groupbyFieldHasEventData 
                           ? groupVal[operator].replace('$events.event_data.$', '')
                           : groupVal[operator].replace('$', '');
                     } else
                        this.groupby.operator = 'count';
                  }
               });

               if (!this.groupby.operator)
                  this.groupby.operator = 'distinct';

               // alert('groupby=' + JSON.stringify(this.groupby));
            } 
            // // else if (this.initialFilter?.groupby) {
            // //    this.groupby = new Groupby(this.initialFilter);
            // //    this.groupbyIdChanged(this.groupby.id, true);
            // // }
         } else if (key === 'columns') {
            // alert(JSON.stringify(this.fields));
            // keyVal = ['transaction_date','price','payment_type','extra-field-1','Product'];
            if (keyVal.length > 0) {
               // this.selectedFields = [...keyVal];
               this.selectedFields = [];  //V240214
               //V230512.1
               const extraFields = [];
               keyVal.forEach(v => {
                  // alert(v+'\n'+JSON.stringify(this.selectedFields));
                  if (this.fields.find(f => f.value === v))
                     this.selectedFields.push(v);
                  else
                     extraFields.push(v);
               });
               this.extraFields = extraFields.join(',');
            } else
               this.selectedFields = [...this.preselectedFields];
         } else if (key === 'sort') {
            const keyValPair = this.getKeyValPair(keyVal);
            this.sortField = keyValPair.key;
            this.sortOrder = keyValPair.val;
         }
      },

      parseSubfilter(subfilterKey, subfilterVal) {
         // alert(`in parseSubfilter(): subfilterKey=${subfilterKey}, subfilterVal=${JSON.stringify(subfilterVal)}`);
         let operator, finalVal;
         if (typeof subfilterVal === 'object') {
            // const key = Object.keys(subfilterVal)[0]; //V230606.1
            const keys = Object.keys(subfilterVal);
            const key = keys[0];
            const val = subfilterVal[key];
            let sInd, eInd, val2 = null;
            switch (key.toString()) {
               case '$ne':
                  operator = '!=';
                  break;
               case '$gt':
                  operator = '>';
                  break;
               case '$gte':
                  if (keys.length === 2) {
                     operator = 'between';
                     val2 = subfilterVal[keys[1]];
                  } else
                     operator = '>=';
                  break;
               case '$lt':
                  operator = '<';
                  break;
               case '$lte':
                  operator = '<=';
                  break;
               case '$in':
                  operator = 'in';
                  if (subfilterKey != 'importId')  //B2.16
                     finalVal = val.join(',');
                  break;
               case '$nin':
                  operator = 'nin';
                  finalVal = val.join(',');
                  break;
               case '$regex':
                  sInd = val.indexOf('^');
                  eInd = val.indexOf('$');
                  // if (sInd === -1 && eInd === -1)
                  //    operator = 'contains';
                  if (sInd === 0 && eInd === -1) {
                     operator = 'starts';
                     finalVal = val.substr(1, val.length - 1);
                  } else if (sInd === -1 && eInd > -1) {
                     operator = 'ends';
                     finalVal = val.substr(0, val.length - 1);
                  } else {
                     operator = 'regex';
                     if (sInd === -1 && eInd === -1)
                        finalVal = val;
                     else
                        finalVal = val.substr(1, val.length - 2);
                  }
                  break;
               case '$exists':
                  operator = '=';
                  break;
               default:
                  operator = '';
                  if (subfilterKey === 'event_date')
                     this.log(`Unknown Operator: "${key}"`, true);
                  else
                     alert(`Unknown Operator: "${key}"`);
                  break;
            }

            if (!finalVal)
               finalVal = val;

            const result = [operator, finalVal];
            if (val2 != null) result.push(val2);
            return result;
         } else {
            // operator = '=';
            // finalVal = subfilterVal;
            return ['=', subfilterVal]
         }

         // return [operator, finalVal];
      },

      getKeyValPair(json) {
         const keys = Object.keys(json);
         return {
            key: keys[0],
            val: json[keys[0]]
         }
      },

      getFieldRules(ind) {
         const fieldRules = [];
         if (ind < this.filters.length - 1 
            || (this.required && ind === 0) 
            || (this.filters.length === this.filtersMax && this.filters[ind].field)
         )
            fieldRules.push(this.rules.required);

         return fieldRules;
      },

      getValueRules(filter) {
         const fieldRules = [];

         if (filter.type.toLowerCase() != 'string' || (filter.operator != '=' && filter.operator != '!='))
            fieldRules.push(this.rules.required);
         if (filter.operator === 'in' || filter.operator === 'nin')
            fieldRules.push(this.rules.list(filter));
         // // // else
         // // //    fieldRules.push(this.rules.noComma);

         // alert('fieldRules=' + JSON.stringify(fieldRules));
         return fieldRules;
      },

      getFields(fields, filters, ind) {
         const remainingFields = [];
         if (fields && filters)
            fields.forEach(fld => {
               if (fld.value === filters[ind].field ||
                  filters.filter(f => f.field === fld.value).length === 0 ||
                  ['__custom__', 'event_data'].includes(fld.value))
                  remainingFields.push(fld);
            });

         return remainingFields;
      },

      getOperators(type) {
         // alert('in getOperators(): type' + type);
         switch (type) {
            case 'string':
               // return [...this.operators];
               return this.operators.filter(o => o.value != 'between');
            case 'number':
               return this.operators.filter(o => ['=', '!=', '<', '<=', '>', '>=', 'in', 'nin', 'between'].indexOf(o.value) !== -1);
            case 'date':
               return this.operators.filter(o => ['=', '!=', '<', '<=', '>', '>=', 'between'].indexOf(o.value) !== -1);
            case 'boolean':
               // return this.operators.filter(o => o.value === '=');
               return this.operators.filter(o => ['=', '!='].indexOf(o.value) !== -1);
            case 'list':
               return this.operators.filter(o => ['in', 'nin'].indexOf(o.value) !== -1);
            case 'beh-string':   //V230630.1
               return this.operators.filter(o => ['=', '!=', 'in', 'nin'].indexOf(o.value) !== -1);
            case 'object':
               return [];
            default:
               alert(`in ${NAME}.getOperators(): "${type}" is an unsupported type!`);
               return [];
         }
      },

      // B1.3
      // stdTypeChanged(ind) {
      //    // // const type = this.filters[ind].type.toLowerCase();
      //    // // this.operatorItems = this.getOperators(type);
      //    // // if (type === 'boolean' || type === 'date')
      //       // // this.filters[ind].operator = '=';

      //    const field = this.fields.find(f => f.value === this.filters[ind].field);
      //    if (field.operators)
      //       this.filters[ind].operator = field.operators[0].value;
      //    else
      //       this.filters[ind].operator = '';
      //    this.filters[ind].value = '';
      // },

      stdTypeChanged(item) {
         // alert('in ' + NAME + '.stdTypeChanged(): item=' + JSON.stringify(item));
         item.operators = this.getOperators(item.type);
         item.operator = item.operators[0].value;
         item.value = '';
      },

      isRangeOperator(operator) {
         return ['in', 'nin'].indexOf(operator.toLowerCase()) > -1;
      },

      addStdFilter(item) {
         // alert('in addStdFilter(): input item=' + JSON.stringify(item));
         if (item) {
            const field = this.fields.filter(f => f.value === item.field)[0];
            // this.alert('in addStdFilter(): field=' + JSON.stringify(field));

            item.internalField = Boolean(field.internalField);
            item.systemField = Boolean(field.systemField);  //V230817
            if (field.type && isIncluded(this.types, field.type)) {
               item.type = field.type;
               item.operators = field.operators;
               item.operator = field.operators[0].value;
               // this.operatorItems = this.getOperators(item.type.toLowerCase());
               // alert('in addStdFilter(): modified item=' + JSON.stringify(item));
            } else {
               item.type = 'string';
               item.operators = this.getOperators(item.type);
               item.operator = item.operators[0].value;
            }
         }

         const len = this.filters.length;
         if (len === 0 || (this.filters[len - 1].field && len < this.filtersMax)) {
            this.filters.push(new StdFilter(
               this.setType(),
               isIncluded(this.operators.map(o => o.value), '=') ? '=' : ''
            ));
         }

         // this.alert('in addStdFilter(): filters=' + JSON.stringify(this.filters));
      },

      removeFilter(ind) {
         if (confirm('Are you sure?')) {
            this.filters.splice(ind, 1);
            this.addStdFilter()
         }
      },

      setType() {
         if (isIncluded(this.types, 'string', true))
            return 'string';
         else if (isIncluded(this.types, 'String', true))
            return 'String';
         else
            return '';
      },

      formattedDateChanged(item, seq) {
         // alert(`in formattedDateChanged(): item=${JSON.stringify(item)}\nseq=${seq}`);
         let dateVal, valueFld;

         if (seq) {
            dateVal = item.formattedDate2;
            valueFld = 'value2';
         } else {
            dateVal = item.formattedDate;
            valueFld = 'value';
         }

         if (isValidDate(dateVal))
            item[valueFld] = format(new Date(dateVal), ISO_FORMAT);
      },

      formatDate(date) {
         // date is in the yyyy-mm-dd format. Parsing 2024-01-01T00:00:00.000Z will cause the formatted date to be 1 day behind.
         return format(parseISO(date.split('T')[0]), DISPLAY_FORMAT);
      },

      dateChanged(item, seq) {
         // alert('in dateChanged(): item.value=' + item.value + ', seq=' + seq);
         if (seq)
            item.formattedDate2 = this.formatDate(item.value2);
         else
            item.formattedDate = this.formatDate(item.value);
      },

      getStdValueItems(item) {
         if (isIncluded(Object.keys(this.stdFieldValues), item.field))
            return this.stdFieldValues[item.field];
         else
            return this.booleanItems;
      },

      /* Behavioral */
      getBehFieldRules(ind) {
         const fieldRules = [];
         if (ind < this.behFilters.length - 1 
            || (this.behFilters.length === this.behFiltersMax && this.behFilters[ind].field)
         )
            fieldRules.push(this.rules.required);

         return fieldRules;
      },

      getBehCustomRules(item) {
         if (item && item.field === '__custom__')
            return [this.rules.required, this.rules.duplicate];
         else
            return [];
      },

      addBehFilter(item) {
         // alert('in addBehFilter(): item=' + JSON.stringify(item));
         if (item) {
            item.colSize = ['__custom__', 'event_data'].includes(item.field) ? 2 : 4;
            const field = this.behFields.filter(f => f.value === item.field)[0];
            // alert('in addBehFilter(): field=' + JSON.stringify(field));

            if (field && field.type && isIncluded(this.types, field.type)) {
               // this.behOperators = this.getBehOperators(item.field);
               item.custom = ''
               item.type = field.type;
               item.operators = field.operators;
               item.operator = field.operators[0].value;
               item.value = '';  //new
            } else {
               // custom field
               item.type = this.types[0];
               this.behTypeChanged(item);
            }
         }

         const len = this.behFilters.length;
         if (len === 0 || (this.behFilters[len - 1].field && len < this.behFiltersMax))
            this.behFilters.push(new BehFilter());
      },

      behFieldNameChanged(item, ind) {
         this.addBehFilter(item);
         if (item.field === 'event_data') {
            item.eventData = '';
            item.isTypeDisabled = true;
         } else
            item.isTypeDisabled = item.field != '__custom__';
      },

      behEventDataChanged(item) {
         // !['__custom__', 'event_data'].includes(item.field)
         const edName = typeof item.eventData === 'string' ? item.eventData : item.eventData.value;
         const edField = this.eventDataFields.find(f => f.value === edName);

         if (edField) {
            item.type = 'number';
            item.isTypeDisabled = true;
         } else {
            item.type = 'string';
            item.isTypeDisabled = false;
         }
         this.formChanged();
      },

      behTypeChanged(item) {
         // alert('in behTypeChanged');
         item.operators = this.getBehOperators(item.field, item.type);
         item.operator = item.operators[0].value;
         item.value = '';
         // alert(JSON.stringify(item))
      },

      getBehOperators(fieldName, fieldType) {
         // alert('in getBehOperators(): fieldName=' + fieldName + ', fieldType=' + fieldType);
         let result;
         switch (fieldName) {
            case 'browser':
            case 'device':
            case 'ip':
            case 'os':
            case 'platform':
            case 'event_path':      //V230202.1
            case 'event_element':   //V230202.1
            // case 'event_data':   //V2305xx.1
               //string: all operators
               result = this.getOperators(this.parentComponent === 'CsExports' ? 'beh-string' : 'string');  //V230630.1
               break;
            case 'event_data':   //V230606.1
               result = this.getOperators(fieldType || 'number');
               break;
            case 'event_date':
               //date: all operators
               result = this.getOperators('date');
               break;
            case 'app_code':
            case 'event_code':
            case 'pdid':
               //string: in, nin
               result = this.getOperators('list');
               break;
            // case 'import_id':
            case 'is_trigger':
               //dropdown: equal only
               result = this.getOperators('boolean');
               break;
            // case '__custom__':
            //    //based on the selected type
            //    return this.getOperators(fieldType);
            default:
               alert(`in ${NAME}.getBehOperators(): "${fieldName}" is an unsupported field!`);
               result = [];
               break;
         }
         return result.filter(o => !['!=', 'nin'].includes(o.value));
      },

      getBehValueItems(item) {
         // alert('in ' + NAME + '.getBehValueItems(): item=' + JSON.stringify(item));
         if (isIncluded(Object.keys(this.behFieldValues), item.field))
            return this.behFieldValues[item.field];
         else
            return this.booleanItems;
      },

      removeBehFilter(ind) {
         if (confirm('Are you sure?')) {
            this.behFilters.splice(ind, 1);
            this.addBehFilter();
         }
      },

      /* By Location */
      cancelAddress() {
         this.address = new Address();
         this.$refs["formAddress"].resetValidation();
         this.dialogAddress = false;
      },

      btnAddressClicked() {
         this.dialogAddress = true;
      },

      async getLatLng() {
         // this.byLocation.latitude = 0;
         // this.byLocation.longitude = 0;
         // this.formattedAddress = '';
         this.addressLoading = true;

         try {
            const address = `${this.address.address1}, ${this.address.address2}, ${this.address.city}, ${this.address.country}`;
            // alert(`in getLatLng(): address=${address}`);

            let components = '';
            if (this.address.zip)
               components = '&components=' + encodeURIComponent('postal_code:' + this.address.zip);

            let response = await fetch(`${GEOCODE_URL}${encodeURIComponent(address)}${components}&key=${process.env.VUE_APP_GOOGLE_API_KEY}`);
            response = await response.json();
            // alert(`in getLatLng(): response=${JSON.stringify(response)}`);

            if (response.results.length > 0) {
               const r = response.results[0];
               this.byLocation.latitude = r.geometry.location.lat;
               this.byLocation.longitude = r.geometry.location.lng;
               // this.formattedAddress = r.formatted_address;
               this.addressErrMsg = '';
               this.cancelAddress();
               this.byLocationChanged();
            } else if (response.status === 'ZERO_RESULTS')
               this.addressErrMsg = ('No results could be found!');
            else
               this.addressErrMsg = `${response.error_message} (status=${response.status})`;
         } catch (ex) {
            this.addressErrMsg = `${ex}`;
         } finally {
            this.addressLoading = false;
         }
      },
      
      /* Groupby */
      groupbyIdChanged(val, isInit) {
         // alert('in groupbyIdChanged(): val=' + val + ', isInit=' + isInit);
         if (val) {
            this.groupbyFieldItems = this.fields.filter(f => f.type === 'number' && f.value != val && f.value != this.groupby.id2);
            // // if (this.groupbyIdItems.filter(f => f.value === val)[0].type.toLowerCase() === 'date') {
            // //    this.groupbyColSize = 2;
            // //    this.groupbyShowDateType = true;
            // //    this.groupbyShowEventData = false;
            // //    this.groupby.eventData = '';
            // //    if (!isInit) {
            // //       this.groupby.dateType = 'month';
            // //       this.groupby.sort = true;
            // //    } 
            // // } else {
            // //    this.groupbyShowDateType = false;
            // //    this.groupby.dateType = '';
            // //    this.groupby.sort = false;
            // //    if (val === 'event_data') {
            // //       this.groupbyColSize = 2;
            // //       this.groupbyShowEventData = true;
            // //    } else {
            // //       this.groupbyColSize = 4;
            // //       this.groupbyShowEventData = false;
            // //       this.groupby.eventData = '';
            // //    }
            // // }

            // alert(JSON.stringify(this.groupbyIdItems.filter(f => f.value === val)[0]))
            // this.groupbyShowDateType = this.groupbyIdItems.filter(f => f.value === val)[0]?.type.toLowerCase() === 'date';
            const groupbyIdItem = this.groupbyIdItems.find(f => f.value === val);
            this.groupbyShowDateType = groupbyIdItem?.type?.toLowerCase() === 'date';
            this.groupbyShowEventData = val === 'event_data';
            this.groupbyColSize = this.groupbyShowDateType || this.groupbyShowEventData ? 2 : 4;
            if (this.groupbyShowDateType) {
               this.groupby.eventData = '';
               if (!isInit) {
                  this.groupby.dateType = 'month';
                  this.groupby.sort = true;
               } 
            } else {
               this.groupby.dateType = '';
               this.groupby.sort = false;
               if (!this.groupbyShowEventData)
                  this.groupby.eventData = '';
            }
            // alert('dateType=' + this.groupby.dateType + ', eventData=' + this.groupby.eventData)

            /* V240617 - start */
            // let indexedFields;
            // const selectedItem = this.groupbyIdItems.find(item => item.value === val);
            // if (selectedItem.secondIndexes)
            //    indexedFields = this.fields.filter(f => selectedItem.secondIndexes.includes(f.value))
            // else
            //    indexedFields = [];

            // this.groupbyId2Items = [
            //    ...indexedFields,
            //    ...this.behFields.filter(f => f.value != 'ip' && f.value != 'pdid' && f.value != '__custom__' && f.value != val)
            // ];

            this.groupbyId2Items = [
               ...this.groupbyIdItems.filter(item => item.value != val),
               ...this.behFields.filter(f => f.value != 'ip' && f.value != 'pdid' && f.value != '__custom__' && f.value != val)
            ];
            /* V240617 - end */

            if (!this.groupbyId2Items.find(item => item.value === this.groupby.id2))
               this.groupby.id2 = '';

            if (!isInit)
               setTimeout(() => {
                  if (this.$refs.groupbyEventData)
                     this.$refs.groupbyEventData.focus();
               }, 10);
         } else {
            this.groupby = new Groupby(this.initialFilter);
         }

         this.formChanged();
      },

      groupbyId2Changed(val, isInit) {
         // alert('in groupbyId2Changed(): val=' + val + ', isInit=' + isInit);
         // this.groupbyIdItems = this.originalGroupbyIdItems.filter(f => f.value != val);
         if (val) {
            this.groupbyFieldItems = this.fields.filter(f => f.type === 'number' && f.value != val && f.value != this.groupby.id);
            // // if (this.groupbyId2Items.filter(f => f.value === val)[0].type.toLowerCase() === 'date') {
            // //    this.groupbyColSize2 = 2;
            // //    this.groupbyShowDateType2 = true;
            // //    if (!isInit)
            // //       this.groupby.dateType2 = 'month';
            // // } else {
            // //    this.groupbyShowDateType2 = false;
            // //    this.groupby.dateType2 = '';
            // //    if (val === 'event_data') {
            // //       this.groupbyColSize2 = 2;
            // //       this.groupbyShowEventData2 = true;
            // //    } else {
            // //       this.groupbyColSize2 = 4;
            // //       this.groupbyShowEventData2 = false;
            // //       this.groupby.eventData2 = '';
            // //    }
            // // }

            // this.groupbyShowDateType2 = this.groupbyId2Items.filter(f => f.value === val)[0].type.toLowerCase() === 'date';
            this.groupbyShowDateType2 = this.groupbyId2Items.filter(f => f.value === val)[0]?.type?.toLowerCase() === 'date' || false; //V240626
            this.groupbyShowEventData2 = val === 'event_data';
            this.groupbyColSize2 = this.groupbyShowDateType2 || this.groupbyShowEventData2 ? 2 : 4;
            if (this.groupbyShowDateType2) {
               this.groupby.eventData2 = '';
               if (!isInit) {
                  this.groupby.dateType2 = 'month';
                  // this.groupby.sort = true;
               } 
            } else {
               this.groupby.dateType2 = '';
               // this.groupby.sort = false;
               if (!this.groupbyShowEventData2)
                  this.groupby.eventData2 = '';
            }
            // alert('dateType2=' + this.groupby.dateType2 + ', eventData2=' + this.groupby.eventData2);

         }
         this.formChanged();
      },

      groupbyOperatorChanged(val) {
         if (val === 'count' || val === 'distinct')
            this.groupby.field = '';

         if (this.groupbyFieldHasEventData)
            this.$refs.groupbyField.focus();

         this.formChanged();
      },

      formChanged() {
         this.isApplied = false;
         // alert('in formChanged(): isApplied=' + this.isApplied);
      },

      byLocationDistanceChanged() {
         if (this.byLocation.distance == '') {
            this.byLocation = new ByLocation();
            // alert('byLocation=' + JSON.stringify(this.byLocation));
         }
         this.byLocationChanged();
      },

      byLocationChanged() {
         this.formChanged();
      },

      getOperatorItems(item) {
         const result = this.fields.find(f => f.value === item.field);
         return result ? result.operators : [];

      //    alert('in getOperatorItems(): fields=' + fields + '\n\nitem=' + JSON.stringify(item));
      //    let operators = [];
      //    if (item) {
      //       const result = fields.find(f => f.value === item.field);
      //       if (result)
      //          operators = result.operators;

      //       if (item.field === '__custom__' && item.type)
      //          operators = this.getOperators(item.type);
      //    }
         
      //    // return [];
      //    item.operators = operators;
      },

      resetClicked() {
         if (confirm('Are you sure to remove all filters?')) {
            if (this.initialFilter.standard)
               this.filters = this.filters.filter(f => this.initialFilter.standard.includes(f.field));
            else
               this.filters = [];

            this.addStdFilter();
            this.behFilters = [];
            this.addBehFilter();
            this.byLocation = new ByLocation();
            // // if (!this.initialFilter?.groupby)
               this.groupby = new Groupby();

            // added in B1.12
            this.filterChanged();
            this.$nextTick(() => {
               this.isApplied = true;
            });
         }
      },
      cancelClicked() {
         this.$emit('filter-cancel');
      },

      filterChanged() {
         // alert('in filterChanged(): form=' + this.$refs['form']);
         this.isApplied = this.$refs.form.validate();

         if (this.isApplied) {
            let standardFilter;

            if (this.selectedPredefined) {
               standardFilter = JSON.parse(this.predefined.query);
            } else {
               const stdFilters = this.filters[this.filters.length - 1].field 
                  ? this.filters : this.filters.slice(0, this.filters.length - 1);
               const behFilters = this.behFilters[this.behFilters.length - 1].field 
                  ? this.behFilters : this.behFilters.slice(0, this.behFilters.length - 1);

               standardFilter = this.getFiltersForMongodb(stdFilters, behFilters);
            }

            let extraFields;
            // if (this.showExtraFields)
            if (this.tabSettings[4])
               extraFields = this.getListItems(this.extraFields);
            else
               extraFields = [];

            this.filterOut = {
               standard: standardFilter,
               columns: [...this.selectedFields, ...extraFields]
            };

            //V230515.1 if (this.sortField) {
            if (this.sortField && this.sortItems.find(item => item.value === this.sortField)) {
               this.filterOut.sort = {};
               this.filterOut.sort[this.sortField] = this.sortOrder;
            }

            if (this.selectedPredefined)
               this.filterOut.predefined = this.selectedPredefined.value;

            this.log('in filterChanged(): filterOut=' + JSON.stringify(this.filterOut));
            // this.log('in filterChanged(): behFilters=' + JSON.stringify(this.behFilters));
            // alert('in filterChanged(): filterOut=' + JSON.stringify(this.filterOut));
            this.$emit('filter-change', this.filterOut);
            this.$emit('input', this.filterOut);
         }
      },

      getFiltersForMongodb(stdFilters, behFilters) {
         let elemMatch, hasBehField, project, and;
         const match = this.processFiltersForMongodb(stdFilters);
         // alert('match=' + JSON.stringify(match));

         if (behFilters.length > 0) {
            elemMatch = this.processFiltersForMongodb(behFilters);
            // alert('elemMatch=' + JSON.stringify(elemMatch));
            match.events = {
               $elemMatch: this.processFiltersForMongodb(behFilters)
            };
            project = {};
            and = [];

            /* V240116 - old
            Object.keys(elemMatch).forEach(key => {
               let operator, operand;
               const val = elemMatch[key];

               const valKeys = Object.keys(val);
               if (valKeys && valKeys.length && valKeys[0].indexOf('$') === 0) {
                  operator = Object.keys(val)[0];
                  operand = val[operator];
                  alert('operator='+operator+'\noperand='+operand+'\n'+Object.keys(val).length)
               } else {
                  operator = '$eq';
                  operand = val;
               }

               const andItem = {};
               andItem[operator] = [
                  '$$event.' + key,
                  operand
               ];
               and.push(andItem);
            });
            */

            // V240116 - new
            Object.keys(elemMatch).forEach(key => {
               const operators = [];
               const operands = [];
               const val = elemMatch[key];

               const valKeys = Object.keys(val);
               if (valKeys && valKeys.length && valKeys[0].indexOf('$') === 0) {
                  const keys = Object.keys(val);
                  keys.forEach(k => {
                     operators.push(k);
                     operands.push(val[k]);
                  });
               } else {
                  operators.push('$eq');
                  operands.push(val);
               }

               for (let i = 0; i < operators.length; i++) {
                  const andItem = {};
                  andItem[operators[i]] = [
                     '$$event.' + key,
                     operands[i]
                  ];
                  and.push(andItem);
               }               
            });
         }

         if (this.byLocation.distance) {
            const centerSphere = [
               [Number(this.byLocation.longitude), Number(this.byLocation.latitude)],
               this.byLocation.distance/3963.2
            ];
            const location = { $geoWithin: { $centerSphere: centerSphere } };
            // outFilters.push({ location: location });

            match.location = location;
         }

         const outFilters = [{ $match: match }];

         if (this.groupby.id) {
            // { $group: {_id : "$country", totalPrice : { $sum : "$price" }}} OR "Count": { "$sum": 1 } OR nothing for distinct
            const groupVal = {};
            const sort = {};
            hasBehField = this.buildGroupbyId(this.groupby.id, this.groupby.dateType, this.groupby.eventData, groupVal);
            // alert('group='+JSON.stringify(groupVal));
            if (this.groupby.id2) {
               hasBehField = this.buildGroupbyId(this.groupby.id2, this.groupby.dateType2, this.groupby.eventData2, groupVal) || hasBehField;
               // alert('groupVal='+JSON.stringify(groupVal));
            } 

            // alert('groupVal='+JSON.stringify(groupVal));
            // alert('groupby.id=' + this.groupby.id + '\ngroupby.id2=' + this.groupby.id2);

            if (project) {
               if (!Object.keys(elemMatch).includes(this.groupby.id))
                  project[this.groupby.id] = 1;
               if (this.groupby.id2 && !Object.keys(elemMatch).includes(this.groupby.id2))
                  project[this.groupby.id2] = 1;
            } else {
               project = {};
               project[this.groupby.id] = 1;
               if (this.groupby.id2) {
                  if (!hasBehField)
                     project[this.groupby.id2] = 1;
               }
            }           

            if (this.groupby.field)
               project[(this.groupbyFieldHasEventData ? 'event_data.' : '') + this.groupby.field] = 1;

            // alert('project=' + JSON.stringify(project) + '\n\nhasBehField=' + hasBehField);
            // alert(JSON.stringify(groupVal));
            const group = {_id: groupVal};

            if (this.groupby.operator === 'distinct')
               sort['_id'] = -1;
            else {
               let fldName;           
               switch (this.groupby.operator) {
                  case 'sum':
                     fldName = 'Total';
                     break;
                  case 'max':
                     fldName = 'Maximum';
                     break;
                  case 'min':
                     fldName = 'Minimum';
                     break;
                  case 'avg':
                     fldName = 'Average';
                     break;
                  case 'count':
                     fldName = 'Count';
                     break;
                  default: //unknown
                     fldName = this.groupby.operator;
                     break;
               }

               const fldNameVal = {};
               if (fldName === 'Count')
                  fldNameVal["$sum"] = 1;
               else
                  fldNameVal["$" + this.groupby.operator] = '$' +
                     (this.groupbyFieldHasEventData ? 'events.event_data.' : '') +
                     this.groupby.field;

               group[fldName] = fldNameVal;
               if (this.groupby.sort) sort['_id'] = 1;
               else sort[fldName] = -1;
            }

            if (hasBehField)
               outFilters.push({ $unwind: "$events" });
            outFilters.push({ $group: group });
            outFilters.push({ $sort: sort});
            outFilters.push({ $limit: this.groupby.limit });
         }

         if (project) {
            if (and && and.length)
               project.events = {
                  $filter: {
                     input: '$events',
                     as: 'event',
                     cond: { $and: and }
                  }
               };
            else if (hasBehField)
               project.events = 1;

            outFilters.splice(1, 0, { $project: project });
         }

         // this.log('outFilters=' + JSON.stringify(outFilters));
         return outFilters;
      },

      processFiltersForMongodb(filters) {
         const match = {};
         filters.forEach(filter => {
            // alert('filter='+JSON.stringify(filter))
            let val;
            const type = filter.type.toLowerCase();
            if (type === 'boolean') {
               // val = Boolean(filter.value);  //B1.14

               //V230817
               if (filter.systemField && filter.value === 'false')
                  val = { "$exists": false };
               else
                  val = filter.value === 'true' ? true : false;

               // alert(filter.value + '\n' + val);
            } else {
               let subVal, arrVal;
               if (type === 'string')
                  subVal = filter.value;
               else if (type === 'date')
                  subVal = new Date(filter.value);
               else
                  subVal = Number(filter.value);

               // const subVal = quote + filter.value + quote;
               // const arrVal = [];
               switch (filter.operator) {
                  case "=":
                     val = subVal;
                     break;
                  case "!=":
                     val = { "$ne": subVal };
                     break;
                  case ">":
                     val = { "$gt": subVal };
                     break;
                  case ">=":
                     val = { "$gte": subVal };
                     break;
                  case "<":
                     val = { "$lt": subVal };
                     break;
                  case "<=":
                     val = { "$lte": subVal };
                     break;
                  case "in":
                  case "nin":
                     // alert(JSON.stringify(filter));
                     //B2.16
                     if (Array.isArray(filter.value))
                        arrVal = filter.value;
                     else {
                        arrVal = [];
                        filter.value.split(',').forEach(v => {
                           if ((type === 'number'))
                              arrVal.push(Number(v));
                           else arrVal.push(v);
                        });
                     }
                     val = {};
                     val["$" + filter.operator] = arrVal;
                     break;
                  case "regex":
                  case "contains":
                     val = { "$regex": subVal, "$options": "i" };
                     break;
                  case "starts":
                     val = { "$regex": "^" + subVal, "$options": "i" };
                     break;
                  case "ends":
                     val = { "$regex": subVal + "$", "$options": "i" };
                     break;
                  case "between":
                     val = {
                        "$gte": subVal,
                        "$lt": type === 'date' ? new Date(filter.value2) : Number(filter.value2)
                     }
                     break;
                  default:
                     val = {};
                     val[filter.operator] = subVal;
                     break;
               }
            }
            // alert('in getFiltersForMongodb():\nfilter=' + JSON.stringify(filter) + '\nval=' + JSON.stringify(val));
            // alert('filter.field='+filter.field+'\nfilter.eventData='+JSON.stringify(filter.eventData))
            if (filter.field === '__custom__')
               match[filter.custom.toLowerCase()] = val;
            else if (filter.field === 'event_data') {
               match[`${filter.field}.${filter.eventData.value || filter.eventData}`] = val;
            } else
               match[filter.field] = val;
         });
         // alert('in getFiltersForMongodb():\nmatch=' + JSON.stringify(match));
         return match;
      },

      //NOTE: group is changed in the method
      buildGroupbyId(id, dateType, eventData, group) {
         // alert(`in buildGroupbyId(): id=${id}, dateType=${dateType}, eventData=${JSON.stringify(eventData)}, group=${JSON.stringify(group)}`);
         let isBehField, val;
         let fld = this.fields.find(f => f.value === id);
         if (fld) {
            isBehField = false;
            val = `$${id}`
         } else {
            isBehField = true;
            if (eventData) {
               if (typeof eventData === 'string')
                  fld = { text: eventData, value: eventData };
               else
                  fld = eventData;
               val = `$events.event_data.${fld.value}`;
            } else {
               fld = this.behFields.find(f => f.value === id);
               val = `$events.${id}`
            }
         }

         if (dateType) {
            // const inner = {};
            // inner[`$${dateType}`] = val;
            // group[dateType] = inner;
            dateType.split('-').forEach(dt => {
               const inner = {};
               inner[`$${dt}`] = val;
               group[dt] = inner;
            });
         } else {
            group[fld.text] = val;
         }

         // alert('isBehField=' + isBehField + '\n' + JSON.stringify(group));
         return isBehField;
      },

      fieldsChanged() {
         this.filtersMax = Math.min(this.fields.length, this.max);
         this.fields.forEach(field => {
            if (field.type)
               field.operators = field.value === 'importId' ? this.operators.filter(o => o.value === 'in') : this.getOperators(field.type);  //B2.17
         });
      },

      testCaseChanged(val) {
         // this.alert('in testCaseChanged(): val=' + val);
         // this.groupbyIdItems = [...this.originalGroupbyIdItems];
         this.groupby = {
            sort: val === 'distinct' ? true : false,
            limit: 300
         };
         switch (val) {
            case 'distinct':
               this.groupby.id = 'product';
               this.groupbyIdChanged(this.groupby.id);
               this.groupby.operator = val;
               this.groupbyOperatorChanged(this.groupby.operator);
               break;
            case 'sum':
               this.groupby.id = 'state';
               this.groupbyIdChanged(this.groupby.id);
               this.groupby.operator = val;
               this.groupbyOperatorChanged(this.groupby.operator);
               this.groupby.field = 'price';
               break;
            case 'avg':
               this.groupby.id = 'payment_type';
               this.groupbyIdChanged(this.groupby.id);
               this.groupby.operator = val;
               this.groupbyOperatorChanged(this.groupby.operator);
               this.groupby.field = 'price';
               break;
            case 'max':
               this.groupby.id = 'country';
               this.groupbyIdChanged(this.groupby.id);
               this.groupby.operator = val;
               this.groupbyOperatorChanged(this.groupby.operator);
               this.groupby.field = 'price';
               break;
            case 'two-level_non-date':
               this.groupby.id = 'product';
               this.groupbyIdChanged(this.groupby.id);
               this.groupby.id2 = 'payment_type';
               this.groupbyId2Changed(this.groupby.id2);
               this.groupby.operator = 'min';
               this.groupbyOperatorChanged(this.groupby.operator);
               this.groupby.field = 'price';
               break;
            case 'two-level_date':
               this.groupby.id = 'transaction_date';
               this.groupbyIdChanged(this.groupby.id);
               this.groupby.dateType = 'dayOfWeek';
               this.groupby.id2 = 'product';
               this.groupbyId2Changed(this.groupby.id2);
               this.groupby.operator = 'count';
               this.groupbyOperatorChanged(this.groupby.operator);
               break;
            case 'two-level_event_date':
               this.groupby.id = 'event_date';
               this.groupbyIdChanged(this.groupby.id);
               this.groupby.dateType = 'dayOfWeek';
               this.groupby.id2 = 'event_code';
               this.groupbyId2Changed(this.groupby.id2);
               this.groupby.operator = 'count';
               this.groupbyOperatorChanged(this.groupby.operator);
               break;
            case 'two-level_event_code':
               this.groupby.id = 'payment_type';
               this.groupbyIdChanged(this.groupby.id);
               this.groupby.id2 = 'event_code';
               this.groupbyId2Changed(this.groupby.id2);
               this.groupby.operator = 'count';
               this.groupbyOperatorChanged(this.groupby.operator);
               break;
            default:
               break;
         }
         this.formChanged();
      },

      async getPredefinedFilters() {
         const result = await this.btHelpers.getPredefinedFilters();
         this.predefinedItems = result.map(({ name, _id }) => ({ text: name, value: _id }));
         this.log('predefinedItems=' + JSON.stringify(this.predefinedItems));
      },

      async predefinedFilterChanged(val) {
         // alert('in predefinedFilterChanged(): val=' + JSON.stringify(val));
         if (val) {
            const result = await this.btHelpers.getPredefinedFilter(this.selectedPredefined.value);
            this.predefined = new Predefined(result);
            this.currPredefined = JSON.stringify(this.predefined);
            // alert(JSON.stringify(this.predefined));
            this.showFilter = false;
         } else {
            this.predefined = new Predefined({});
            this.currPredefined = '';
            this.showFilterResult = false;
         }

         this.formChanged();
      },

      btnAddPredefinedClicked() {
         this.action = 'add';
         this.predefined = new Predefined({});
         this.$nextTick(() => {
            this.$refs.predefinedName.focus();
         });
      },

      async savePredefined() {
         this.predefinedLoading = true;
         const postData = {
            aggregateQuery: this.predefined.query,
            name: this.predefined.name,
            showInSubaccounts: this.predefined.showInSubaccounts,
            isRangeQuery: this.predefined.isRangeQuery
         };

         if (this.predefined.isRangeQuery)
            postData.rowDimension = this.predefined.rowDimension;

         const result = await this.btHelpers.createPredefinedFilter(postData);
         if (result) {
            this.predefined = new Predefined(result);
            this.currPredefined = JSON.stringify(this.predefined);
            const newItem = {
               text: result.name,
               value: result._id
            };
            this.predefinedItems.push(newItem);
            this.selectedPredefined = newItem;
            this.action = '';
            this.formChanged();
            // this.cancelPredefined();
         }
         this.predefinedLoading = false;
      },

      async btnEditPredefinedClicked() {
         this.action = 'edit';
         // this.predefined = new Predefined(JSON.parse(this.currPredefined));
         this.$nextTick(() => {
            this.$refs.predefinedName.focus();
         })
      },

      async updatePredefined() {
         this.predefinedLoading = true;
         const putData = {
            aggregateQuery: this.predefined.query,
            name: this.predefined.name,
            showInSubaccounts: this.predefined.showInSubaccounts,
            isRangeQuery: this.predefined.isRangeQuery,
            rowDimension: this.predefined.rowDimension
         };

         const result = await this.btHelpers.updatePredefinedFilter(this.selectedPredefined.value, putData);
         if (result) {
            // this.predefined = new Predefined(result.data);
            this.currPredefined = JSON.stringify(this.predefined);
            this.selectedPredefined.text = result.name;
            this.action = '';
            this.formChanged();
            // this.cancelPredefined();
         }
         this.predefinedLoading = false;
      },

      async btnDeletePredefinedClicked() {
         this.action = 'delete';
         this.predefined.name = '';
         // this.predefined = new Predefined(JSON.parse(this.currPredefined));
         this.$nextTick(() => {
            this.$refs.predefinedName.focus();
         })
      },

      async deletePredefined() {
         if (this.predefined.name === this.selectedPredefined.text) {
            this.predefinedLoading = true;
            const result = await this.btHelpers.deletePredefinedFilter(this.selectedPredefined.value);
            if (result) {
               const i = this.predefinedItems.indexOf(this.selectedPredefined);
               this.predefinedItems.splice(i, 1);
               this.selectedPredefined = '';
               this.predefined = null;
               this.currPredefined = '';
               this.action = '';
               this.formChanged();
               // this.cancelPredefined();
            }
            this.predefinedLoading = false;
         } else {
            alert ("Names didn't match! Please try again.");
            this.$refs.predefinedName.focus();
         }
      },

      cancelPredefined() {
         this.action = '';
         this.predefined = JSON.parse(this.currPredefined);
      },

      rangeQueryChanged(val) {
         if (val)
            this.rowDimensionRules = [this.rules.required];
         else {
            this.rowDimensionRules = [];
            this.predefined.rowDimension = '';
         }
      },

      //V230511.1
      getListItems(strVal) {
         const items = [];
         if (strVal.trim())
            strVal.trim().split(',').forEach(v => {
               items.push(v.trim().toLowerCase());
            });
         return items;
      },

      //V230511.1
      getSortItems() {
         let listItems = [];
         let extraFields = [];
         // if (this.showExtraFields) {
         if (this.tabSettings[4]) {
            listItems = this.getListItems(this.extraFields);
            listItems.forEach(item => {
               extraFields.push({ text: item, value: item });
            });
         }

         this.sortItems = [...this.fields.filter(field => isIncluded(this.selectedFields, field.value)), ...extraFields];
         this.log('sortItems=' + JSON.stringify(this.sortItems))

         // if (!isIncluded([...this.selectedFields, ...listItems], this.sortField))
         //    this.sortField = '';

         // if (!isIncluded(this.sortItems, this.sortField))
         // if (this.sortField && !this.sortItems.find(item => item.value === this.sortField))
         //    this.sortField = '';

         // this.alert('in getSortItems(): extraFields=' + JSON.stringify(extraFields));
      },

      //V230511.1
      extraFieldsChanged() {
         this.getSortItems();
         this.formChanged();
      },

      groupbyFieldChanged() {
         this.groupbyColSize3 = this.groupbyShowEventData3 ? 2 : 4;
         this.formChanged();
      }
   },

   created() {
      this.log(`in ${NAME}.created(): fields=${JSON.stringify(this.fields)}`);
      // console.error(`in ${NAME}.created(): fields=${JSON.stringify(this.fields.filter(f => f.value === 'purlNumber' || f.value === '_mfi_studio_added_record'))}`);

      // this.alert(`in ${NAME}.created(): stdFieldValues=` + JSON.stringify(this.stdFieldValues));
      // if (this.btHelpers)
      //    this.getPredefinedFilters();
   },

   mounted() {
      // alert(`in ${NAME}.mounted(): filterResult=${JSON.stringify(this.filterResult)}`);
      //alert(`in ${NAME}.mounted(): groupbyFieldHasEventData=${this.groupbyFieldHasEventData}`);

      this.isApplied = true;
      this.isFilterFormValid = true;
      //V230515.1 this.setTabSettings();
      //V230630.1: Reverted back the above change as it kept moving to the Standard tab.
      console.warn('calling setTabSettings from mounted');
      this.setTabSettings();
      this.getSortItems();
   }
}
</script>

<style scoped>
/* .v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix, .v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix, .v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 0px;
} */
</style>