<template>
   <!-- 
      WHAT: A component to allow selecting an option and entering a value, if needed.
      WHO: Behzad Talebpour (btalebpour@mindfireinc.com)
      HISTORY:
      01/20/22(B0.1): 1st release.
   -->
   <v-menu close-on-content-click offset-y bottom
      class="pt-0"
      min-width="auto"
      transition="scale-transition"
      v-model="menu"
   >
      <template v-slot:activator="{ on, attrs }">
         <v-text-field v-if="combolist.type === 'number'"
            persistent-hint 
            ref="btCombolist_textField"
            autocomplete="off"
            append-icon="arrow_drop_down"
            :class="myClass"
            :clearable="!required"
            :hint="combolist.hint"
            :placeholder="combolist.placeholder"
            :readonly="isTextFieldReadonly"
            :required="required"
            :rules="textFieldRules"
            v-bind="attrs"
            v-on="on"
            v-model="combolist.text"
            @click:clear="listChanged({})"
         ></v-text-field>
         <v-text-field v-else
            persistent-hint
            ref="btCombolist_textField"
            class="pt-0"
            autocomplete="off"
            append-icon="arrow_drop_down"
            :clearable="!required"
            :hint="combolist.hint"
            :placeholder="combolist.placeholder"
            :readonly="isTextFieldReadonly"
            :required="required"
            :rules="textFieldRules"
            v-bind="attrs"
            v-on="on"
            v-model="combolist.text"
            @click:clear="listChanged({})"
         ></v-text-field>
      </template>
      <v-list dense class="py-0">
         <v-list-item v-for="(listItem, i) in listItems" :key="i"
            @click="listChanged(listItem)"
         >
            <v-list-item-content>
               <v-list-item-title v-text="listItem.text"></v-list-item-title>
            </v-list-item-content>
         </v-list-item>
      </v-list>
   </v-menu>
</template>

<script>
const NAME = 'BtCombolist';

export default {
   name: NAME,

   props: {
      //{list: '', text: ''}
      value: {
         type: Object,
         default: () => {}
      },
      debug: {
         type: Boolean,
         default: false
      },
      hint: {
         type: String,
         default: ''
      },
      label: {
         type: String,
         default: ''
      },
      listItems: {
         //{text: '', list: '', needsInput: true/false, hint: '', placeholder: ''}
         type: Array,
         required: true,
         default: () => []
      },
      myClass: {
         type: String,
         default: "py-0"
      },
      placeholder: {
         type: String,
         default: ''
      },
      required: {
         type: Boolean,
         default: false
      },
      rules: {
         type: Array,
         default: () => []
      },
      shouldInit: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         myRules: {
            required: value => !!value || "Value is required!"
         },
         menu: false,
         combolist: {
            // text: '',
            // list: '',
            // hint: '',
            // placeholder: ''
         },
         isTextFieldReadonly: true,
      }
   },

   computed: {
      textFieldRules() {
         let rules;
         if (this.rules && Array.isArray(this.rules))
            rules = [...this.rules];
         else if (this.required)
            rules = [this.myRules.required];
         else
            rules = [];

         // alert('textFieldRules1=' + JSON.stringify(rules));
         return rules;
      }
   },

   watch: {
      // rule: {
      //    handler(val) {
      //       alert('rule=' + val || JSON.stringify(val));
      //    }
      // },
      shouldInit: {
         immediate: true,
         handler(val) {
            // alert('in watch.shouldInit: val=' + val + '\nthis.value=' + JSON.stringify(this.value));
            // alert('BtCombo:this.value='+JSON.stringify(this.value));
            if (val) {  //shouldInit === true
               if (this.value.text) {
                  const listItem = this.listItems.find(item => item.list === this.value.list);
                  this.combolist = {
                     text: this.value.text,
                     list: this.value.list,
                     hint: listItem.hint,
                     placeholder: listItem.needsInput ? listItem.placeholder : '',
                     type: listItem.type || 'text'
                  };

                  if (listItem.min || listItem.min === 0)
                     this.combolist.min = listItem.min;
               } else {
                  this.combolist = {
                     text: '',
                     list: '',
                     hint: this.hint,
                     placeholder: this.placeholder,
                     type: 'text'
                  }
               }
            // alert('in watch.shouldInit: combolist=' + JSON.stringify(this.combolist));
            }
         }
      },

      combolist: {
         immediate: false,
         deep: true,
         handler(val) {
            // alert('in watch.combolist: val=' + JSON.stringify(val));
            this.$emit('input', { 
               text: val.text,
               list: val.list
            });
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} B0.1 says => ${msg}`);
      },

      listChanged(listItem) {
         // alert('in listChanged(): listItem=' + JSON.stringify(listItem));
         if (listItem.list) {
            this.combolist = {
               text: listItem.needsInput ? '' : listItem.text,
               list: listItem.list,
               hint: listItem.hint,
               placeholder: listItem.needsInput ? listItem.placeholder : '',
               type: listItem.type || 'text'
            };

            if (listItem.min || listItem.min === 0)
               this.combolist.min = listItem.min;
               
            if (listItem.needsInput)
               this.$refs['btCombolist_textField'].focus();
         } else {
            this.combolist = {
               text: '',
               list: '',
               hint: this.hint,
               placeholder: this.placeholder,
               type: 'text'
            }
         }

         this.isTextFieldReadonly = !listItem.needsInput;
      }
   },

   created() {
      // alert(`in ${NAME}.created()`);
      // this.combolist.text = this.value.text;
      // this.combolist.list = this.value.list;
      // // this.selectedListItem = this.listItems.find(item => item.list === this.value.list) || {};
      // this.setListHintAndPlaceholder();
      // // this.listChanged(this.value.text && this.listItems.find(item => item.list === this.value.list) || {}, false);
   }
}
</script>