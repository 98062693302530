<template>
    <div class="kc-color-picker input-group color-picker" ref="colorpicker">
        <span class="input-group-addon color-picker-container">
			<template v-if="hideInput == false">
				<span class="current-color" :style="'background-color: ' + colorValue" @click="togglePicker()"></span>
				<!-- <label>{{colorValue}}</label> -->
				<input type="text" class="form-control" :value="colorValue" @focus="showPicker()" @input="updateFromInput" />
			</template>
			<span @click="togglePicker()"><slot></slot></span>
            <v-color-picker hide-mode-switch :width=width elevation="12" :style="popUpStyle" style="position:absolute;z-index:1;" v-if="displayPicker" canvas-height="80" v-model="pickerColorValue"></v-color-picker>
        </span>
    </div>
</template>
<style>
.kc-color-picker .current-color {
	display: inline-block;
	width: 16px;
	height: 26px;
	background-color: #000;
	cursor: pointer;
}
.kc-color-picker .text-color {
    width:80px;
}
.kc-color-picker .color-picker-container {
	display: flex;
}
.kc-color-picker.color-picker {
    position:relative;
    display: inline-block;
}
.kc-color-picker .v-color-picker__controls {
    padding:5px 10px;
}

.kc-color-picker .v-color-picker__edit {
    margin-top: 10px;
}

.kc-color-picker .v-color-picker__input input {
    height: 19px;
    font-size: 12px;
    margin-bottom: 2px;
}
.kc-color-picker .v-color-picker__input {
    margin-right:0px;
}
.kc-color-picker input.form-control {
    width:70px;
    border: solid 1px lightgray;
    margin-left: 3px;
}

</style>
<script>
import { consoleError } from 'vuetify/lib/util/console';
function isValidHexColor(color){
    const regex = /^#?[0-9A-Fa-f]{6}$/gm;
    var match = color.match(regex);
    var ret =  match && color === match[0];
    return ret;
}
function rgbToHex(r, g, b) {
  return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
}
function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}
function componentFromStr(numStr, percent) {
    var num = Math.max(0, parseInt(numStr, 10));
    return percent ?
        Math.floor(255 * Math.min(100, num) / 100) : Math.min(255, num);
}

function rgbStringToHex(rgb,prefix="#") {
    var rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/;
    var result, r, g, b, hex = "";
    if ( (result = rgbRegex.exec(rgb)) ) {
        r = componentFromStr(result[1], result[2]);
        g = componentFromStr(result[3], result[4]);
        b = componentFromStr(result[5], result[6]);

        hex = prefix + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }
    return hex;
}
export default {
    components: {
		
	},
    props: {
		value: {
			default:"#000000"
		},
		hideAlpha: {
			default:true,
		},
		hideInput: {
			default:false,
		},
		ouputFormat: {
			default:"hex",
		},
		top: {
			default: "-190px"
		},
		width: {
			default: 240,
		},

	},
	data() {
		return {
			// colors: "white",
			colorValue: 'red',
            pickerColorValue: '#7e15ff',
			displayPicker: false,
		}
	},
	computed: {
		popUpStyle(){
			return {
				"top": this.top,
			}
		},
		slotStyle(){
			return {
				"background-color": this.colorValue,
			}
		}
	},
    mounted() {
        console.log("mount",this.ouputFormat);
		this.setColor(this.value);
	},
	methods: {
		setColor(color) {
            console.log("setColor",color)
			if(color.startsWith("#")){
				// remove alpha
				if(this.hideAlpha == true){
					color = color.slice(0,7);
				}
			}
			if(color.startsWith("rgb")){
				color = rgbStringToHex(color);
			}
			//this.updateColors(color);
			this.colorValue = color;
            this.pickerColorValue = color;
		},
		// updateColors(color) {
        //     console.log("updateColors",color)
        //     this.setColor(color);
		// 	// if(color.slice(0, 1) == '#') {
		// 	// 	this.colors = {
		// 	// 		hex: color
		// 	// 	};
		// 	// }
		// 	// else if(color.slice(0, 4) == 'rgba') {
		// 	// 	var rgba = color.replace(/^rgba?\(|\s+|\)$/g,'').split(','),
		// 	// 		hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
		// 	// 	this.colors = {
		// 	// 		hex: hex,
		// 	// 		a: rgba[3],
		// 	// 	}
		// 	// }
		// },
		showPicker() {
			document.addEventListener('click', this.documentClick);
			this.displayPicker = true;
		},
		hidePicker() {
			document.removeEventListener('click', this.documentClick);
			this.displayPicker = false;
		},
		togglePicker() {
			this.displayPicker ? this.hidePicker() : this.showPicker();
		},
		updateFromInput(val) {
             console.log("updateFromInput",this.colorValue,val.target.value)
             var value = val.target.value.trim();
             if(isValidHexColor(value)){
                if(this.colorValue.startsWith("#")==false){
                    this.colorValue = "#" + value;
                }else{
                    this.colorValue = value;
                }
             }
        },
		updateFromPicker(color) {
            console.log("updateFromPicker",color)
			this.colors = color;
			// if(color.rgba.a == 1) {
			// 	this.colorValue = color.hex;
			// }
			// else {
			// 	this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
			// }
		},
		documentClick(e) {
			var el = this.$refs.colorpicker;
            if(el != undefined){
                var target = e.target;
                if(el !== target && !el.contains(target)) {
                    this.hidePicker()
                }
            }
		}
	},
	watch: {
		value(newValue, oldValue) {
			this.setColor(newValue);
        },		
        pickerColorValue(val){
            this.colorValue = val;
        },
		colorValue(val) {
			if(val) {
				//this.updateColors(val);
				if(this.hideAlpha == true){
					if(val.startsWith("#")){
						val += "FF";
					}
				}
				if(this.ouputFormat == "rgb"){
					//console.log("RGB",val);
					val = val.substring(0,7);
					var rgb = hexToRgb(val)
					this.$emit('input', `rgb(${rgb.r},${rgb.g},${rgb.b})`);
				}else{
					this.$emit('input', val);
				}
				//document.body.style.background = val;
			}
		}
	},    
}
</script>