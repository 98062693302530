<template>
    <v-container style="height: 100%;width: 100%;background: white;">
        <template v-if="AppMode">
            <KCCardBuilderView :useMongoDB=true style="transform-origin: top;transform: scale(0.75);display: table-row;" ref="cardBuilder" :loadPassCommand=loadPassCommand :templateName=taskName :editMode=editMode :readOnlyMode=readOnlyMode @canSave="(n)=> shouldShowWalletPassSaveButton = n" :headers="headers" :sampleData="sampleData" :hideCardType=true :debugMode=false :autoLoadDefault=true :viewMode=true :lightMode=true :pushMessageMode=false :loadListServiceHeader=false></KCCardBuilderView>
        </template>            
        <template v-else>
            <v-tabs v-model="msgtab" @change="tabChanged">
                <v-tab>Update Pass</v-tab>
                <v-tab>Message History</v-tab>
                <v-tab>Geo-Fenced Alerts</v-tab>
                <v-tab>Bluetooth Beacons</v-tab>
            </v-tabs>
            <v-tabs-items v-model="msgtab" style="height:100%;">
                <!-- <v-tab-item>
                    <v-row>
                        <v-col cols=12>
                            <v-data-table :loading="messageLoading" dense :hide-default-footer="false" :headers="messageDataTable.headers" :items="messageDataTable.items" :items-per-page="5" sort-by="sendDateTime" sort-desc class="elevation-1">
                                <template v-slot:[`item.target`]="props">
                                    <span v-if="props.item.target == 'all'">Everyone who has the pass installed</span>
                                    <span v-else-if="props.item.target == 'visitor'">Specific visitors to your microsite who has the pass installed</span>
                                    <span v-else>People within a geofenced area</span>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-tab-item> -->
                <v-tab-item>
                    <v-row class="mt-1">
                        <v-col cols=8 class="jmd" style="margin-left: -20px;">
                            <KCCardBuilderView :useMongoDB=true style="transform-origin: top;transform: scale(0.85);display: table-row;" ref="cardBuilder" :loadPassCommand=loadPassCommand :templateName=taskName :editMode=editMode :readOnlyMode=readOnlyMode @canSave="(n)=> shouldShowWalletPassSaveButton = n" :headers="headers" :sampleData="sampleData" :hideCardType=true :debugMode=false :autoLoadDefault=true :viewMode=true :lightMode=true :pushMessageMode=true :loadListServiceHeader=false></KCCardBuilderView>
                        </v-col>
                        <v-col cols=4 class="jmd" id="settings">
                            <v-row>
                                <span class="title font-weight-bold" style="color:#757575 !important; line-height: 2.5rem;">Settings</span>
                            </v-row>
                            <v-row>
                                <div><span style="color:#757575;">Which Day and Time Do You Want to Send?</span></div>
                            </v-row>
                            <v-row>
                                <v-col cols="6" class="pt-1">
                                    <v-menu v-model="datepicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="pushMessage.sendDate" label="" prepend-icon="event" readonly v-bind="attrs" v-on="on" dense style="height: 32px;"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="pushMessage.sendDate" @input="datepicker = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6" class="pt-1" style="display: inline-flex;">
                                    <!-- <div style="color:#757575;line-height: 2.3rem;">at</div>&nbsp; -->
                                    <v-menu ref="timepickerref" v-model="timepicker" :close-on-content-click="false" :nudge-right="40" :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="pushMessage.sendTime" label="" prepend-icon="schedule" readonly v-bind="attrs" v-on="on" dense @click="initTime()" @change="formatTime()" style="height: 32px;"></v-text-field>
                                        </template>
                                        <v-time-picker v-if="timepicker" v-model="pushMessage.sendTime" ampm-in-title format="ampm" @change="formatTime()" @click:hour="formatTime()" @click:minute="$refs.timepickerref.save(time)" @update:period="formatTime()"></v-time-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="mt-1">
                                <v-col cols="12" class="pt-1">
                                    <v-select hide-details v-model="pushMessage.timezone" :items="timezones" placeholder="Timezone" dense style="height: 32px;"></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <div><span style="color:#757575;padding-right:5px;">Alert Preference Classification</span><v-icon dense @click="preferenceClick()">settings</v-icon></div>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pt-1">
                                    <v-select hide-details v-model="pushMessage.preference" :items="preferences" placeholder="Target" dense></v-select>
                                </v-col>
                            </v-row>
                            <template v-if="!showCreateAction">
                            <v-row>
                                <div><span style="color:#757575;">Who do you want to target?</span></div>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pt-1">
                                    <v-select hide-details v-model="pushMessage.target" :items="effectiveTargets" placeholder="Target" dense @change="targetChanged()"></v-select>
                                </v-col>
                            </v-row>
                            <v-row class="mt-1" v-if="pushMessage.target=='visitor'"> 
                                <v-col cols="12">
                                    <v-select hide-details multiple v-model="pushMessage.audience" :items="audiences" placeholder="Choose Segments" dense></v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="pushMessage.target == 'visitor' && pushMessage.audience.length == 0" style="padding: 0; font-size: small;font-style: italic;color: firebrick; margin-top: 0;">
                                <v-col cols="12" class="py-1">
                                    Note: Use visitor require segment values.
                                </v-col>
                            </v-row>
                            </template>
                            <template v-if="pushMessage.target=='geofencing'">
                                <v-row>
                                    <v-col cols="12" class="py-1">
                                        <v-radio-group v-model="pushMessage.addressing" row hide-details="auto" class="mt-0">
                                            <v-radio label="Address" value="address"></v-radio>
                                            <v-radio label="Latitude&Longitude" value="latitude"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row v-if="pushMessage.addressing=='address'">
                                    <v-col cols="12" class="py-1">
                                        <KCGoogleAutoComplete ref="addressY" id="map" classname="form-control" placeholder="Type US Address" v-on:placechanged="getAddressData" label="" :country="['us','ca']"></KCGoogleAutoComplete>
                                    </v-col>
                                </v-row>
                                <v-row v-if="pushMessage.addressing=='latitude'">
                                    <v-col cols="6" class="py-1">
                                        <v-text-field v-model="pushMessage.latitude" placeholder="Latitude" dense style="height: 32px;"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="py-1">
                                        <v-text-field v-model="pushMessage.longitude" placeholder="Longitude" dense style="height: 32px;"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" class="py-1">
                                        <v-select hide-details v-model="pushMessage.operator" :items="operators" placeholder="Operator" dense></v-select>
                                    </v-col>
                                    <v-col cols="6" class="py-1">
                                        <v-text-field v-model="pushMessage.range" placeholder="Distance in miles" dense @blur="validateDistance()"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row v-if="(pushMessage.range<0.5 || pushMessage.range>5 || !/^\d{0,1}(?:\.\d{0,1})?$/.test(pushMessage.range))" style="padding: 0; font-size: small;font-style: italic;color: firebrick; margin-top: 0;">
                                    <v-col cols="12" class="py-1">
                                        Distance should be between 0.5 miles to 5.0 miles. Please provide the distance with increments of 0.5 mile. 
                                    </v-col>
                                </v-row>
                                <v-row v-if="pushMessage.operator =='' || pushMessage.range =='' || (pushMessage.address =='' && pushMessage.addressing == 'address') || ((pushMessage.latitude =='' || pushMessage.longitude =='') && pushMessage.addressing == 'latitude')" style="padding: 0; font-size: small;font-style: italic;color: firebrick; margin-top: 0;">
                                    <v-col cols="12" class="py-1">
                                        In order to use geo-fencing, provide either a US address or a latitude-longitude pair.
                                    </v-col>
                                </v-row>
                            </template>
                            <v-row style="height: 50px;">
                                <v-btn v-if="!showCreateAction && canSavePushMessage()" @click="beforeSavePushMessage('pushMessage')">SCHEDULE MESSAGE</v-btn>
                                <!-- <v-btn v-if="!showCreateAction && shouldShowWalletPassSaveButton && (pushMessage.target == 'all' || (pushMessage.target == 'visitor' && pushMessage.audience.length > 0) || !(pushMessage.operator =='' || pushMessage.range =='' || (pushMessage.address =='' && pushMessage.addressing == 'address') || ((pushMessage.latitude =='' || pushMessage.longitude =='') && pushMessage.addressing == 'latitude')))" @click="beforeSavePushMessage('pushMessage')">SCHEDULE MESSAGE</v-btn> -->
                                <v-btn v-if="showCreateAction" @click="beforeSavePushMessage('createAction')">Create Action</v-btn>
                            </v-row>

                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item>
                    <KCPushMessageHistory ref="history" @pushMessage="pushRecentMessage"></KCPushMessageHistory>
                </v-tab-item>
                <v-tab-item>
                    <JWGeofencing ref="geofences" locationMode="geofences"></JWGeofencing>
                </v-tab-item>
                <v-tab-item>
                    <JWGeofencing ref="beacons" locationMode="beacons"></JWGeofencing>
                </v-tab-item>
            </v-tabs-items>
              
            <v-dialog persistent v-model="showPreference" width="600">
                <v-sheet style="position:relative;" class="rounded-lg mx-auto pa-5" elevation="12" height="550" width="100%">
                    <JWAlertPreference ref="preference"></JWAlertPreference>
                    <div style="position: absolute;bottom: 15px;width: 95%;">
                        <v-btn style="float:right;" @click="showPreference=false">Close</v-btn>
                        <!-- <v-btn style="float:right;margin-right:10px;" @click="saveHtmlClick()">Save</v-btn> -->
                    </div>
                </v-sheet>
            </v-dialog>
        </template>
    </v-container>
</template>
<style scoped>
    @media (max-width: 1264px) {
        .jmd.col-8, .jmd.col-4 {
            max-width: 90%;
            flex: 0 0 90%;
        }
        .jmd.col-4 {
            margin-left: 20px;
        }
    }
    #settings .v-input, #settings .v-label, #settings div {
        font-size: 0.87rem;
    }
</style>
<style>
.container .container {
    padding: 0px;
}
</style>


<script>
 import axios from "axios";
 import KCCardBuilderView from './KCCardBuilderView.vue';
 import addThisPass from "../services/KCAddThisPass.js";
 import moment from 'moment-timezone';
 import KCGoogleAutoComplete from "../components/KCGoogleAutoComplete.vue";
 import JWGeofencing from './JWGeofencing.vue'; 
 import kcMixins from "../services/KCMixins.js";
 import JWAlertPreference from "../components/JWAlertPreference.vue";
 import KCPushMessageHistory from '../components/KCPushMessageHistory.vue';
 import utils from '../services/KCUtils.js';
 const NAME = "JWPushMessage";
export default {
    mixins: [kcMixins],
    name: NAME,
    components: {
        KCCardBuilderView,
        KCGoogleAutoComplete,
        JWGeofencing,
        JWAlertPreference,
        KCPushMessageHistory
    },
    props: {
        showCreateAction: {
            default: false,
        },
        AppMode: {
            default: false,
        },
        masterHeader: {
            type: Object,
            default() {
                return {}
            }
        },
        passUIEndpoint: {
          type: String,
          default: utils.getPassUIEndpoint()
        }
    },
    data() {
        return {
            /* mixins start */
            appName: "passUI",
            /* mixins end */
            davinciUrl: null,
            server: "stage",
            msgtab: 0,
            passDataLoading: false,
            showLoadDialog: false,
            shouldShowWalletPassSaveButton: true,
            readOnlyMode: false,
            editMode: false,
            headers: [],
            sampleData: [],
            taskName: "",
            loadPassCommand: null,
            // this will be override by getConfig();
            walletPassConfig: {
                "version": 0,
                "addThisPassScanUrl":"https://addthiscard.com/?pass=2X9ZOg==@{_id}{exportId}{actionId}{purl}&a=scan{purl}&a=scan"   
            },
            datepicker: false,
            timepicker: false,
            time: '',
            timezones: [{"text":"Hawaii Time","value":"Pacific/Honolulu","atpnum":"-6"},{"text":"Alaska Time","value":"America/Anchorage","atpnum":"-4"},{"text":"Pacific Time","value":"America/Los_Angeles","atpnum":"-3"},{"text":"Mountain Time","value":"America/Denver","atpnum":"-2"},{"text":"Central Time","value":"America/Chicago","atpnum":"-1"},{"text":"Eastern Time","value":"America/New_York","atpnum":"0"},{"text":"SE Asia Time","value":"Asia/Bangkok","atpnum":"11"}],
            sendTimezone: null,
            targets: [{"value": "all", "text": "Everyone who have the pass installed"}, {"value": "visitor", "text": "Specific visitors to your microsite who have the pass installed"}, {"value": "geofencing", "text": "People within a geofenced area"}, {"value": "registered", "text": "All registered passholders"}, {"value": "nonregistered", "text": "All unregistered passholders"}],            
            defPreferences: [{"value": "", "text": "Everyone irrespective of their preferences"}],
            preferences: [],
            audiences: [],
            operators: [{"value": "<", "text": "Less than"}, {"value": "<=", "text": "Less than or equal to"}, {"value": "=", "text": "Exactly"}, {"value": ">", "text": "More than"}, {"value": ">=", "text": "More than or equal to"}],
            defPushMessage: {
                accountId: this.$store.getters.user.account,
                acctKey: '',
                passKey: '',
                id: 232,
                name: "",
                logoText: "",
                line1Text: "",
                line2Text: "",
                line3Text: "",
                thumbnail: "",
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                preference: "",
                target: "all",
                addressing: "address",
                sendDate: moment().format('YYYY-MM-DD'),
                sendTime: moment().add(10, 'minutes').format('hh:mmA'),
                status: "",
                audience: [],
                address: "",
                operator: "",
                range: "",
                latitude: "",
                longitude: "",
                fromDevice: "desktop"
            },
            pushMessage: {},
            passData: {},
            
            messageDataTable: {
                headers: [
                    { text: "Date and Time", value: "sendDateTime", sortable: true },
                    { text: "Message", value: "message", sortable: true },
                    { text: "Target", value: "target", sortable: true },
                    { text: "Status", value: "status", sortable: true }
                ],
                items: [
                    {sendDateTime: "2023-12-14 02:48PM", message: "Any premium dog 20% off, 1234567890, 1234567890", target: "all", status: "sent"},
                    {sendDateTime: "2023-12-14 09:48AM", message: "Any premium dog 20% off, 1234567890, 1234567890", target: "geofencing", status: "sent"},
                    {sendDateTime: "2023-12-14 11:48AM", message: "Any premium dog 20% off, 1234567890, 1234567890", target: "visitor", status: "sent"},
                    {sendDateTime: "2023-12-14 04:48PM", message: "Any premium dog 20% off, 1234567890, 1234567890", target: "visitor", status: "sent"},
                    {sendDateTime: "2023-12-14 08:48PM", message: "Any premium dog 20% off, 1234567890, 1234567890", target: "algeofencingl", status: "sent"},
                    {sendDateTime: "2023-12-14 10:48AM", message: "Any premium dog 20% off, 1234567890, 1234567890", target: "all", status: "sent"},
                ]
            },
            messageLoading: false,
            geofencingLoading: false,
            APIKey: "AIzaSyA-DomT9kwu5eyp2J0of7DWAABiW6y4V5g",
            showPreference: false
        };
    },
    computed: {
        effectiveTargets() {
            if(this.showCreateAction){
                return this.targets;
            }else{
                let ret = JSON.parse(JSON.stringify(this.targets));
                // delete ret[1];
                return ret;
            }
        }
    },
    methods: {
        async showPass(acctKey,passKey,passID,passName,data,prevPushMessage=null){
            this.$nextTick(async () => {
                this.pushMessage = JSON.parse(JSON.stringify(this.defPushMessage));
                this.passData = JSON.parse(JSON.stringify(data));
                if (prevPushMessage == null) prevPushMessage = await this.getPrevPushMessage(passKey, this.AppMode);
                console.log('prevPushMessage: ',prevPushMessage);
                if (prevPushMessage != null) {
                    if (prevPushMessage.target == 'visitor') {
                        await this.targetChanged();
                    }
                    this.pushMessage = JSON.parse(JSON.stringify(prevPushMessage));
                    this.passData.passData.eventTicket.primaryFields[0].value = prevPushMessage.line1Text;
                    this.passData.passData.eventTicket.secondaryFields[0].value = prevPushMessage.line2Text;
                    this.passData.passData.eventTicket.secondaryFields[1].value = prevPushMessage.line3Text;
                    if (this.pushMessage.target == 'geofencing' && this.pushMessage.addressing == 'address') {
                        setTimeout(() => {
                            this.$refs.addressY.setInitAddress(prevPushMessage.address);
                        }, 1000);
                    }
                }
                this.pushMessage.acctKey = acctKey;
                this.pushMessage.passKey = passKey;
                this.pushMessage.id = passID;
                this.pushMessage.name = passName;
                this.pushMessage.logoText = this.passData.passData.logoText;
                this.pushMessage.sendDate = moment().format('YYYY-MM-DD');
                this.pushMessage.sendTime = moment().add(10, 'minutes').format('hh:mmA');
                if(this.showCreateAction){
                    this.pushMessage.target = 'visitor';
                }
                //this.$refs.cardBuilder.loadByID(passID);
                console.log('showpass: ',acctKey,passKey,this.msgtab,this.passData);
                this.$refs.cardBuilder.loadByCardData(passID,this.passData,()=>{
                    this.$refs.cardBuilder.setUseLogoAsThumbnail(this.pushMessage.thumbnail);
                });
                await this.loadPreferences(this.pushMessage.passKey);
                if (this.msgtab == 1) this.$refs.history.loadPushMessage(this.pushMessage.passKey);
                if (this.msgtab == 2) this.$refs.geofences.initialize(acctKey, passKey, this.passData);
                if (this.msgtab == 3) this.$refs.beacons.initialize(acctKey, passKey, this.passData);
            });
        },
        getCardBuilder(){
            return this.$refs.cardBuilder;
        },
        async getPrevPushMessage(passKey, AppMode){
            let ret = await this.appUIGet(this.app.passUI.pushMessageHistory,{name: passKey},{},this.masterHeader,this.getListServiceAxios());
            let device = 'desktop';
            if (AppMode) device = 'mobile';
            let data = null;
            let sortedRet = ret.sort((a, b) => new Date(b.definition.createdDate) - new Date(a.definition.createdDate));
            console.log('getPrevPushMessage: ', sortedRet);
            for(let x in sortedRet){
                let pushMessageData = ret[x].definition;
                console.log('getPrevPushMessage: ', device, pushMessageData.fromDevice, pushMessageData);
                if (device == pushMessageData.fromDevice) {
                    data = pushMessageData;
                    break;
                }
            }
            return data;
        },
        async beforeSavePushMessage(mode){
            // var templateData = this.$refs.cardBuilder.getCardData();
            let templateData = await this.$refs.cardBuilder.fixImageDataURLs(this.$refs.cardBuilder.getCardData());
            console.log(templateData);
            this.pushMessage.line1Text = templateData.passData.eventTicket.primaryFields[0].value;
            this.pushMessage.line2Text = templateData.passData.eventTicket.secondaryFields[0].value;
            this.pushMessage.line3Text = templateData.passData.eventTicket.secondaryFields[1].value;
            this.pushMessage.thumbnail = '';
            if (templateData.resource.chkThumbnail) {
                if (templateData.resource.useLogoAsThumbnail) {
                    this.pushMessage.thumbnail = templateData.resource.logo;
                } else {
                    this.pushMessage.thumbnail = templateData.resource.thumbnail;
                }
            }
            if (mode=='pushMessage_mobile') {
                this.pushMessage.fromDevice = 'mobile';
            } else {
                this.pushMessage.fromDevice = 'desktop';
            }
            console.log(this.pushMessage);

            if (this.pushMessage.line1Text == "" || this.pushMessage.line1Text == null) {
                await this.$swal.fire("Push message require line 1 value");
                return true;
            }

            if (!this.checkDate(this.pushMessage.sendDate+' '+this.pushMessage.sendTime, this.pushMessage.timezone)) {
                if (mode == 'pushMessage_mobile') {
                    let newDate = moment().tz(this.pushMessage.timezone);
                    this.pushMessage.sendDate = newDate.format('YYYY-MM-DD');
                    this.pushMessage.sendTime = newDate.add(5, 'minutes').format('hh:mmA');
                } else {
                    await this.$swal.fire("Please schedule messages at least 5 minutes in future");
                    return true;
                }
            }
            let result = await this.$swal.fire({
                title: "Do you need to publish this message?",
                html: "Keep in mind that you won't be able to edit this message later.",
                showCancelButton: true,
            });
            if (result.isConfirmed) {
                this.savePushMessageHistory();
                if (mode == 'createAction') {
                    this.createPushMessageAction();
                } else if (this.pushMessage.target == 'visitor') {
                    this.pushMessageByNodeJS();
                } else {
                    this.savePushMessage();
                }
            }
            return true;
        },
        async savePushMessage(){
            // console.log(JSON.stringify(this.pushMessage));
            this.$swal.fire({
                title: 'Push message',
                html: `<span></span>`,
            });
            this.$swal.showLoading();
            var ret = await addThisPass.pushMessage(this.pushMessage, []);
            console.log(ret);
            this.$swal.close();
            this.$emit("autoCloseDlg");
        },
        async savePushMessageHistory(){
            // console.log(JSON.stringify(this.pushMessage));
            this.pushMessage.createdDate = utils.UTCDateTime();
            let saveRet = await this.appUIPost(this.app.passUI.pushMessageHistory, this.pushMessage.passKey, this.pushMessage);
            console.log('savePushMessageHistory: ', saveRet);
        },
        createPushMessageAction(){
            console.log(JSON.stringify(this.pushMessage));
            this.$emit("createPushMessage", this.pushMessage);
        },
        pushMessageByNodeJS(){
            console.log("pushMessageBySAM", this.pushMessage);
            const now = moment();
            let task =  {
                _id: '65a9e4b6e90e0c123aae35b3',
                responseStatusCode: 200,
                processStatus: 'processing',
                processCount: 0,
                processRecoveryCount: 0,
                debug: true,
                creationDate: now.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                accountId: this.$store.getters.user.account,
                exportId: '65a9e4ab6083598a28d0af9d',
                exportSignedUrl: 'https://mfdavinci.s3.us-west-1.amazonaws.com/file/qrcode/35917-490-1b7edb7b-2571-46a8-95a2-62423e74d7b9.zip',
                actionId: '65a9e45206fdb7abdad2e821',
                actionName: 'Push Message',
                actionAuthor: this.$store.getters.user.email,
                appCode: '700',
                appConsumerCode: '7b0ce1aec8f6c28ee79ffbc8c28ec56a',
                appInstruction: {
                    "fieldDefinitions": [
                        {
                        "label": "passQRCode",
                        "format": [
                            "https://addthiscard.com/?pass=3EL0wQ==@{_id}{exportId}{actionId}{purl}&a=scan&O-other=Deepal 240123&O-registered=true",
                            "_id",
                            "purl"
                        ],
                        "default": ""
                        }
                    ],
                    "pushMessageData":this.pushMessage,
                    "server": this.server
                },
                __v: 0,
                processLastActivity: now.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
            }
            console.log(JSON.stringify(task));
            axios.post(this.passUIEndpoint+`?cmd=pushMessage&stage=${this.server}`,task);
            this.$emit("autoCloseDlg");
        },
        initTime(){
            if (!this.pushMessage.sendTime) {
                const now = moment();
                this.pushMessage.sendTime = now.format('hh:mmA');
            }
        },
        formatTime(){
            console.log('format time');
            if (this.pushMessage.sendTime) {
                let amPmRegex = /(AM|PM)/;
                let currentFormat = 'YYYY/MM/DD HH:mm';
                // Use test() method to check if the string contains AM or PM
                if (amPmRegex.test(this.pushMessage.sendTime)) {
                    currentFormat = 'YYYY/MM/DD hh:mmA';
                }
                const now = moment();
                console.log(now.format('YYYY/MM/DD')+' '+this.pushMessage.sendTime, currentFormat);
                const dt = moment(now.format('YYYY/MM/DD')+' '+this.pushMessage.sendTime, currentFormat);
                this.pushMessage.sendTime = dt.format('hh:mmA');
                console.log(this.pushMessage.sendTime);
            }
        },
        getAddressData: function (addressData, placeResultData, id) {
            console.log(addressData,placeResultData,id);
            this.pushMessage.address = placeResultData.formatted_address;
        },
        checkDate: function (datetime1, timezone1) {
            let datetime = moment.tz(datetime1,'YYYY-MM-DD hh:mmA',timezone1).format('YYYY-MM-DD HH:mm:ssZ');
            let inputDate = moment(datetime).toDate();
            // Get today's date
            // let todaysDate= new Date();
            let newDate = moment().tz(this.pushMessage.timezone);
            let todaysDate = newDate.add(5, 'minutes').toDate();
            if (inputDate < todaysDate) {
                return false;
            } else {
                return true;
            }
        },
        tabChanged: function () {
            // this.$nextTick(() => {
            //     if (this.msgtab == 1) this.$refs.geofences.initialize(this.pushMessage.acctKey, this.pushMessage.passKey);
            //     if (this.msgtab == 2) this.$refs.beacons.initialize(this.pushMessage.acctKey, this.pushMessage.passKey);
            // });
            setTimeout(() => {
                if (this.msgtab == 1) this.$refs.history.loadPushMessage(this.pushMessage.passKey);
                if (this.msgtab == 2) this.$refs.geofences.initialize(this.pushMessage.acctKey, this.pushMessage.passKey, this.passData);
                if (this.msgtab == 3) this.$refs.beacons.initialize(this.pushMessage.acctKey, this.pushMessage.passKey, this.passData);
            }, 500);
        },
        targetChanged: async function () {
            let ret = await this.exportsGet({}, {limit: 50});
            console.log("exportsGet",ret);
            let audience = [];
            for(let x in ret){
                let data = ret[x];
                console.log(data);
                let jsonValue = {
                    _id: data._id,
                    delimiter: data.delimiter,
                    shouldZip: data.shouldZip
                };
                audience.push({value: jsonValue, text: data.name});
            }
            console.log('audience',audience);
            this.audiences = audience;
        },
        getContacts: async function(audience){
            let allContact = [];
            for (let i=0; i<audience.length; i++){
                let fname = audience[i]._id+'.'+audience[i].delimiter+(audience[i].shouldZip ? '.gz' : '');
                console.log('fname: '+fname);
                let ret = await this.downloadsGet(fname);
                console.log('ret: ',ret);
                let csvContent = '';
                try {
                    let axios = this.getCleanAxios();
                    const response = await axios.get(ret.url, {responseType: 'arraybuffer'});
                    csvContent = response.data;
                    // if (audience[i].shouldZip) {
                    //     const inflated = pako.inflate(response.data, { to: 'string' });
                    //     csvContent = inflated;
                    // } else {
                    //     csvContent = response.data;
                    // }
                } catch (error) {
                    console.log('Error fetching CSV:', error);
                }
                console.log('CSV: ', csvContent);
                if (csvContent != '') {
                    let contacts = this.convertCSVToJSON(csvContent);
                    console.log('contacts: ', contacts);
                    allContact = this.concatArrays(allContact, contacts); 
                }
            }
            console.log('allContact: ', allContact);
            return allContact;
        },
        convertCSVToJSON: function(csv) {
            var lines = csv.split("\n");
            var result = [];
            var headers = lines[0].slice(1, -1).split('","');
            for (var i = 1; i < lines.length; i++) {
                var obj = {};
                var currentline = lines[i].slice(1, -1).split('","');
                console.log('currentline: ', currentline);
                for (var j = 0; j < headers.length; j++) {
                    obj[headers[j].toLowerCase()] = currentline[j];
                }
                result.push(obj);
            }
            return result;
        },
        concatArrays: function(array1, array2) {
            // Concatenate the arrays and convert to a Set to remove duplicates
            const concatenatedArray = new Set([...array1, ...array2]);

            // Convert the Set back to an array
            return [...concatenatedArray];
        },
        validateDistance: function() {
            if (this.pushMessage.range.startsWith('.')) this.pushMessage.range = '0'+this.pushMessage.range;
            if (this.pushMessage.range.endsWith('.')) this.pushMessage.range = this.pushMessage.range.substr(0, this.pushMessage.range.length-1);
        },
        canSavePushMessage: function() {
            if (this.shouldShowWalletPassSaveButton) {
                if (this.pushMessage.target == 'all' || this.pushMessage.target == 'registered' || this.pushMessage.target == 'nonregistered' || (this.pushMessage.target == 'visitor' && this.pushMessage.audience.length > 0)) {
                    return true;
                }
                if (this.pushMessage.target == 'geofencing') {
                    if (this.pushMessage.operator !='') {
                        if (this.pushMessage.range !='' && this.pushMessage.range>=0.5 && this.pushMessage.range<=5 && /^\d{0,1}(?:\.\d{0,1})?$/.test(this.pushMessage.range)) {
                            if (this.pushMessage.addressing == 'address' && this.pushMessage.address !='') {
                                return true;
                            } else if (this.pushMessage.addressing == 'latitude' && !(this.pushMessage.latitude =='' || this.pushMessage.longitude =='')) {
                                return true;
                            }
                        }
                    }
                }
            }
            return false;
        },
        pushRecentMessage(recentMessage) {
            console.log('pushRecentMessage: ', recentMessage);
            this.showPass(recentMessage.acctKey,recentMessage.passKey,recentMessage.passID,recentMessage.passName,this.passData,recentMessage);
            this.msgtab = 0;
        },

        async preferenceClick(){
            this.showPreference = true;
            this.$nextTick(async () => {
                this.$refs.preference.initialize(this.pushMessage.acctKey, this.pushMessage.passKey);
            });
        },
        async loadPreferences(passKey){
            var ret = await this.appUIGet(this.app.passUI.preferences,{name: passKey},{},this.masterHeader,this.getListServiceAxios());
            console.log("loadPreferences",ret);
            this.preferences = JSON.parse(JSON.stringify(this.defPreferences));
            for(var x in ret){
                let item = ret[x];
                this.preferences.push({
                    value: item.definition.id,
                    text: item.definition.preference
                });
            }
        },
        closePreference() {
            this.loadPreferences(this.pushMessage.passKey);
        },
        getListServiceAxios(){
            if(Object.keys(this.masterHeader).length ==0){
                return null;
            }else{
                return this.getCleanAxios();
            }
        },
    },
    created() {
        this.davinciUrl = this.$store.getters.deployment.dvURL;
        // alert(`in created(): davinciUrl=${this.davinciUrl}`);
        if (this.davinciUrl.indexOf("davincistage") >= 0) {
            this.server = "stage";
        } else {
            this.server = "prod";
        }    
    },
    destroyed() {
    },
    mounted() {
    },
    beforeUnmount() { 
    },
    watch: {
        showPreference (val) {
            val || this.closePreference()
        },
    },
};
</script>