<template>
<!-- TODO:
   - Make validations tab visible to au only
   - Make creationDate sortable
   - Main Info tab should be red
   - Move the v-form inside dialog v-card-text
-->
<!-- HISTORY:
   V241204.1: In editImportClicked(), removed unnecessary tab setting as it caused issue if New Import was clicked 1st +
      Displayed a message if sftpImportsCount >= maxSftpCount.
   V241002.2: Converted port to number + Distinguished SFTP imports by checking their host property rather their status +
      Added includePgp switch to show the PGP Settings and allow removing them via edit and used it to decide if PGP has been set or not.
   V241001.1: Added edit for SFTP imports to edit their SFTP info + Only allowed 2 SFTP import to be created +
      Cleared some extra codes regarding date format testing from init().
   V240904.1: Changed errMsg = '' to errMsg = null to fix the bug that didn't enable Submit btn.
   V240829.1: Implemented and consumed validateHeadersAgainstDisallowedHeaders() + Removed disabled from Notification Emails tab.
   V240301.1: Consumed getImportsStats() - instead of getImportsCount() - and displayed them under the table.
   V240216.1: Changed the wording of dialogDelete to be the same as CsDocuments' Feed Search.
   V240215.1: [on Aref side] Modified deleteOffersClicked() to change the offersCount logic query (to show deletedRecords correctly).
   V240202.1: Modified saveImport() to add dateFormat to fieldDefinitions where is applicable (same logic as CsExports_V240201).
   V240103.1: Changed the reject icon from delete to mdi-cloud-remove + Added purlStatus condition in canDeleteOffers().
   V231220.1: Added reject action to cancel/reject certain imports + Remove updateImport() method, which was not in use.
   V231128.1: Consumed is-power-admin prop of bt-import-validations.
   V231117.1: Made validations tab red in the case of error, and managed to be enabled for sftp imports.
   V231115.1: Added Validations tab to define validations for the uploaded file on the server.
   V231031.2: Updated import before deleting offers + Disabled submit and cancel buttons while delete in process.
   V231031.1: Added delete action to delete all unconverted/unsynced records of non-sftp imports and set recordsDeleted field +
      Removed unused and commented codes.
   V230411.1: Disabled SFTP tab if a file is selected + Always showed the auto-export definition because sftp imports also need it +
      Validated exportDefinition against exportFields only if a file is selected + Removed getEventFields from mixin's import +
      Passed isCancelled prop to the ImportFileUpload component + Modified getExportFields() and hostCleared() to fix a bug that didn't show upload btn back.
   V230324.2: In svaeImport(), ignored validation for exportDefinition custom fields.
   V230324.1: In setImportName(), reset errMsg and changed condition that read headers if name is already specified.
   V230323.1: in setImportName(), processed import headers from arg and previewed the provided records, if available +
      in saveImport(), validated latitude/longtitude, purls/dedups and exportDefinition.fieldDefinitions against header +
      Made sure to send latitude/lonitude values according their case from header, and purls/dedups as lowercase +
      Removed hasImport and used "importsCount > 0" instead.
   V230320.1: Hid exportDefinition for the 1st import as there is no import headers yet + Tried to get headers after 1st import and during refresh + 
      Added logic to show some of the selected file's records.
   V230315.2: Initiated exportDefinition with '{}' instead of empty.
   V230315.1: Added a new dialog to capture export definition when auto-export is on.
   V230306.1: Moved the logic in V230302.1 to newImportClicked() + Set import name with the fileName without extension + Enabled SFTP tab.
   V230302.1: Add some logic in setImportName() to force the other tabs validations.
   V230301.1: Moved all fields except importName and fileDelimiter under the new settings tab + Defined TABS constant + Commented SAVE btn.
   V230227.1: Modified the logic for V230224.1 changes to make it work by adding file-select event on the Aref's side and consuming it here.
   V230224.1: In saveImport(), set import name automatically with the uploaded file path.
   V2212xx.1: Added a feature to delete rejected records.
   V230105.1: In the table, displayed processedRecords and purlProcessedRecords as zero instead of empty.
   V221222.1: Added rejectOnError switch (cannot be changed once set to true) + Shortened dedupHashMatching label & moved exportOnImportCompletion to the end + 
      Applied formatting to the processedRecords and purlProcessedRecords + Removed statuses texts and added tooltips instead.
   V221207.1: Made SFTP & PGP tab disabled (on Aref side).
   V221118.1: Added delete action for the imports with sftp.
   V221110.1: Applied/Modified styles to display contents properly after Aref's upgrade + Replaced hasOwnProperty() with hasOwn from mixins +
      Changed debug switch permission from pa to au + Changed the approach of parsing JWT token.
   V221021.2: Changed item-key from 'name' to '_id'.
   V221021.1: Added creator to the table's headers + Fixed SAVE btn disability by including SFTP & PGP forms to isImportFormValid.
   V221020.1: Made SFTP and PGP tab enabled and added SFTP fields + Added new fields to the expanded item + Added 2 new status values.
   08/16/22(B1.6): Added debug switch for power admin.
   06/06/22(B1.5): Made typeCasting switch disabled if it had been set to true + Added persistEvent switch.
   05/27/22(B1.4): Added typeCasting switch.
   05/02/22(B1.3): Implemented logout feature + Disabled Notifications and GPG tabs.
   04/14/22(B1.2): Commented Actions header (from Aref side).
   03/17/22(B1.1): Showed New Import btn only if the user has contact-imports-create policy + Changed the icon.
   02/25/22(B1.0): Made Refresh btn hidden until an import is available + Added hide-default-footer prop to v-data-table +
      Fixed the bug that didn't show the 1st item (new import) + Fixed the bug that didn't show imports when account was changed.
   02/17/22(B0.12): Changed 30 sec delay for purlProcessedRecords to 2 and added 20 sec for the processedRecords as well +
      Changed the layout of expanded-item to be 2 columns with Headers to be in one + Added PURLs fields to searchFields and set their isIndexed properties.
   02/15/22(B0.11): Added Tooltip to the processedRecords and purlProcessedRecords columns to show the processing time and speed.
   01/14/22(B0.10): Added purlLastActivityDate to the table.
   01/13/22(B0.9): shouldGeneratePurl & dedupHashMatching were allowed to be set during 1st import creation only.
   01/11/22(B0.8): Added dedupHashMatching to the Main Info tab.
   01/06/22(B0.7): Added PURL Status and PURL Processed Records to headers.
   07/26/21(B0.6): Added Creation Date, Processed Records, Start Date and Last Activity Date to searchFields.
   07/26/21(B0.5): Consumed BtFilterDefiner to provide search option + Added pagination logic +
      Removed aid from all methods params.
   03/24/21(B0.4): Merged Aref's code + Added _id and header + Set icon for Status.
   03/23/21(B0.3): In SaveImport(), only passed existed values instead of all.
   03/19/21(B0.2): Removed Config concept and implemented new rules.
   xx/xx/20(B0.1): 1st version/release.
-->
<!-- BUSINESS LOGICS:
   - Latitude/Longitude Columns:
      - Either both should be specified or none.
      - Column names cannot be same.
      - It should have the exact case as the CSV header.
      - If CSV headers are available in the code, letter cases will be automatically adjusted.
   - Export Definition:
   - Purls/Dedups Columns:
      - They will always come in lowercase even ff cannot be validated against headers.
   - {
      "sftp": {
         "sftpCredentials": {
            "host": "",
            "port": 22,
            "username": "",
            "password": ""
         },
         "sftpPath": "listServices", //folder
         "pgpCredentials": {
            "privateKey": "",
            "passPhrase": ""
         }
      },
      "status": "sftp",
      ...
     }
   - scope cannot be modified anymore if Import Level is selected.
   - purl and dedup columns cannot be modified if scope is 'account'.
   - purl and dedup columns are not needed if generatePURL is false.
-->
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title class="pl-2">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <!-- <v-icon class="pr-1">import_contacts</v-icon> -->
            <v-icon class="pr-1">file_download</v-icon>
            <span>Imports</span>
         </h1>
         <div class="flex-grow-1"></div>
         <div>
            <v-btn v-if="imports.length"
               x-small
               class="mr-2 mt-1"
               color="gray darken-1"
               :disabled="btnRefreshImportsDisabled || !imports.length"
               @click="refreshClicked()"
            >REFRESH{{importsDisabledDuration ? ' in ' + importsDisabledDuration + ' sec' : ''}}
               <v-icon right dark>refresh</v-icon>
            </v-btn>
            <v-btn v-if="$store.getters.user.policies.includes('contact-imports-create')"
               x-small
               class="mr-2 mt-1"
               color="gray darken-1"
               :disabled="loadingImports"
               @click="newImportClicked()"
            >NEW IMPORT
               <v-icon right dark>add</v-icon>
            </v-btn>
         </div>
      </v-card-title>

      <v-card-text class="py-0">
         <bt-filter-wrapper-with-panel
            :closed-on-load="true"
            :fields="searchFields"
            :included-tabs="['standard']"
            :is-admin="jwt.pa"
            :max="searchFields.length"
            :preselected-fields="[]"
            :should-init="shouldInitFilterDefiner2"
            :std-field-values="searchFieldsValues"
            v-model="filter2"
            @filter-change="filterChanged2"
         ></bt-filter-wrapper-with-panel>
      </v-card-text>

      <v-card-text>
         <v-data-table dense fixed-header show-expand
            class="elevation-1"
            item-key="_id"
            :footer-props="{
               itemsPerPageOptions: [5, 10, 20],
               showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="importsStats.count <= 5"
            :items="imports"
            :items-per-page="5"
            :loading="loadingImports"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'imports' })"
            :no-results-text="$t('no-results-text', { value: 'imports' })"
            :options.sync="options"
            :search="search"
            :server-items-length="importsStats.count"
            :single-expand="false"
         >
            <template v-slot:[`item.creationDate`]="{ item }">
               {{ formatDate(item.creationDate, true) }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
               <!-- <v-icon :color="getIcon(item.status).color">{{getIcon(item.status).icon}}</v-icon>
               <span :text--color="getIcon(item.status).color"> {{ item.status }}</span> -->
               <!-- <v-tooltip top :color="getIcon(item.status).color"> -->
               <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                     <span v-bind="attrs" v-on="on">
                        <v-icon :color="getIcon(item.status).color"
                        >{{getIcon(item.status).icon}}</v-icon>
                     </span>
                  </template>
                  <span>{{item.status}}</span>
               </v-tooltip>
            </template>
            <template v-slot:[`item.processedRecords`]="{ item }">
               <v-tooltip v-if="item.processedRecords"
                  top color="black"
               >
                  <template v-slot:activator="{ on, attrs }">
                     <span v-bind="attrs" v-on="on">
                        {{formatNumber(item.processedRecords)}}
                     </span>
                  </template>
                  <span>Processing Time: {{calculateProcessingTime(item.lastActivityDate, item.startDate, 0)}}</span>
                  <br>
                  <span>Processing Speed: {{calculateProcessingSpeed(item.processedRecords, item.lastActivityDate, item.startDate, 0)}} Rec/Hr</span>
               </v-tooltip>
               <span v-else>0</span>
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
               {{ formatDate(item.startDate, true) }}
            </template>
            <template v-slot:[`item.lastActivityDate`]="{ item }">
               {{ formatDate(item.lastActivityDate, true) }}
            </template>
            <template v-slot:[`item.purlStatus`]="{ item }">
               <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                     <span v-bind="attrs" v-on="on">
                        <v-icon :color="getIcon(item.purlStatus).color"
                        >{{getIcon(item.purlStatus).icon}}</v-icon>
                     </span>
                  </template>
                  <span>{{item.purlStatus}}</span>
               </v-tooltip>
            </template>
            <template v-slot:[`item.purlProcessedRecords`]="{ item }">
               <v-tooltip v-if="item.purlProcessedRecords"
                  top color="black"
               >
                  <template v-slot:activator="{ on, attrs }">
                     <span v-bind="attrs" v-on="on">{{formatNumber(item.purlProcessedRecords)}}</span>
                  </template>
                  <span>Processing Time: {{calculateProcessingTime(item.purlLastActivityDate, item.purlStartDate, 0)}}</span>
                  <br>
                  <span>Processing Speed: {{calculateProcessingSpeed(item.purlProcessedRecords, item.purlLastActivityDate, item.purlStartDate, 0)}} Rec/Hr</span>
               </v-tooltip>
               <span v-else>0</span>
            </template>
            <template v-slot:[`item.purlLastActivityDate`]="{ item }">
               {{ formatDate(item.purlLastActivityDate, true) }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
               <span v-if="item.sftp?.sftpCredentials?.host">
                  <v-tooltip top color="black">
                     <template v-slot:activator="{ on, attrs }">
                        <v-icon small
                           class="pr-2"
                           v-on="on"
                           v-bind="attrs"
                           :disabled="!canUpdateImport"
                           color="error"
                           @click="editImportClicked(item)"
                        >edit</v-icon>
                     </template>
                     <span>Click to update SFTP settings...</span>
                  </v-tooltip>
                  <v-tooltip top color="black">
                     <template v-slot:activator="{ on, attrs }">
                        <!-- <v-icon v-if="item.status.indexOf('sftp') === 0" -->
                        <!-- mdi-cloud-remove -->
                        <v-icon small
                           v-on="on"
                           v-bind="attrs"
                           :disabled="!canDeleteImport"
                           color="error"
                           @click="deleteImport(item)"
                        >cloud_off</v-icon>
                     </template>
                     <span>Click to delete the import...</span>
                  </v-tooltip>
               </span>
               <v-tooltip v-else-if="canBeRejected(item.status)"
                  top
                  color="black"
               >
                  <template v-slot:activator="{ on, attrs }">
                     <v-icon small
                        v-on="on"
                        v-bind="attrs"
                        :disabled="!canUpdateImport"
                        color="error"
                        @click="rejectImportClicked(item)"
                     >clear</v-icon>
                  </template>
                  <span>Click to cancel/reject the import...</span>
               </v-tooltip>
               <!-- <v-icon small
                  :disabled="item.status !='waiting' && item.status !='recovery'"
                  @click="updateImport(item)"
               >cancel</v-icon> -->
               <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                     <v-icon v-if="canDeleteOffers(item)"
                        small
                        v-on="on"
                        v-bind="attrs"
                        color="error"
                        @click="deleteOffersClicked(item)"
                     >delete</v-icon>
                  </template>
                  <span>Click to delete the unconverted records...</span>
               </v-tooltip>
            </template>

            <template v-slot:expanded-item="{ item }">
               <td colspan="5" class="py-2" valign="top" dense>
                  <ul>
                     <li>
                        <span class="expanded-header">PURLs: </span>
                        <span class="expanded-content">{{item.generatePURL ? item.purlColumns.join(', ') : ''}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Dedups: </span>
                        <span class="expanded-content">{{item.generatePURL ? item.dedupColumns.join(', ') : ''}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Notification Emails: </span>
                        <span class="expanded-content">{{item.notificationEmails ? item.notificationEmails.join(', ') : ''}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Latitude Column: </span>
                        <span class="expanded-content">{{item.latitudeColumn}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Longitude Column: </span>
                        <span class="expanded-content">{{item.longitudeColumn}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Generate PURL: </span>
                        <span class="expanded-content text-capitalize">{{item.generatePURL}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">PURL Generation Scope: </span>
                        <span class="expanded-content text-capitalize">{{item.purlGenerationScope}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Same PURL: </span>
                        <span class="expanded-content text-capitalize">{{item.dedupHashMatching}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Cast Types: </span>
                        <span class="expanded-content text-capitalize">{{item.typeCasting || false}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Persist Event: </span>
                        <span class="expanded-content text-capitalize">{{item.persistEvent || false}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Reject on Error: </span>
                        <span class="expanded-content text-capitalize">{{item.rejectOnError}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Auto Export: </span>
                        <span class="expanded-content text-capitalize">{{item.exportOnImportCompletion}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Batch Size: </span>
                        <span class="expanded-content">{{item.processBatchSize}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">File Delimiter: </span>
                        <span class="expanded-content">{{getDelimiterDesc(item.fileDelimiter)}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Debug: </span>
                        <span class="expanded-content text-capitalize">{{item.debug}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">File Path: </span>
                        <span class="expanded-content text-capitalize">{{item.filePath}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">DB Name: </span>
                        <span class="expanded-content text-capitalize">{{item.databaseName}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Recovery Count: </span>
                        <span class="expanded-content">{{item.recoveryCount}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Recovery Error: </span>
                        <span class="expanded-content">{{item.recoveryError}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">ID: </span>
                        <span class="expanded-content text-capitalize">{{item._id}}</span>
                     </li>
                  </ul>
               </td>
               <td colspan="8" class="py-2" valign="top" dense>
                  <ul>
                     <li>
                        <span class="expanded-header">Header({{item.header ? item.header.length : 0}}): </span>
                        <span class="expanded-content">{{item.header ? item.header.join(', ') : ''}}</span>
                     </li>
                     <li v-if="item.exportDefinition">
                        <span class="expanded-header">Export Definition: </span>
                        <span class="expanded-content">{{item.exportDefinition}}</span>
                     </li>
                     <li v-if="item.sftp">
                        <span class="expanded-header">SFTP Host: </span>
                        <span class="expanded-content">{{item.sftp.sftpCredentials.host}}</span>
                     </li>
                     <li v-if="item.sftp && item.sftp.sftpPath">
                        <span class="expanded-header">SFTP Path: </span>
                        <span class="expanded-content">{{item.sftp.sftpPath}}</span>
                     </li>
                     <li v-if="item.sftp && item.sftp.sftpCredentials.port">
                        <span class="expanded-header">SFTP Port: </span>
                        <span class="expanded-content">{{item.sftp.sftpCredentials.port}}</span>
                     </li>
                     <li v-if="item.sftp">
                        <span class="expanded-header">SFTP Username: </span>
                        <span class="expanded-content">{{item.sftp.sftpCredentials.username}}</span>
                     </li>
                     <li v-if="item.sftp">
                        <span class="expanded-header">Has PGP Credentials: </span>
                        <span class="expanded-content">{{item.sftp.pgpCredentials ? true : false}}</span>
                     </li>
                     <li v-if="item.validations">
                        <span class="expanded-header">Validations: </span>
                        <span class="expanded-content">{{item.validations}}</span>
                     </li>
                  </ul>
               </td>
            </template>

            <template v-slot:[`body.append`] v-if="importsStats.count">
               <tr class="font-weight-bold">
                  <td colspan="5">Totals:</td>
                  <td colspan="4" align="left">{{formatNumber(importsStats.processedTotal)}}</td>
                  <td colspan="2" align="left">{{formatNumber(importsStats.purlTotal)}}</td>
                  <td colspan="2" align="left">{{formatNumber(importsStats.deletedTotal)}}</td>
                  <td></td>
               </tr>
            </template>
         </v-data-table>
      </v-card-text>
   </v-card>

   <v-form lazy-validation
      ref="mainForm"
      v-model="isMainFormValid"
   >
      <v-dialog no-click-animation persistent
         max-width="1200px"
         v-model="dialogImport"
      >
         <v-card flat>
            <v-card-title class="title grey--text darken-4 font-weight-bold pb-2">
               <div v-if="isEdit">Editing {{currImport.name}}:</div>
               <div v-else>Creating New Import:</div>
               <div class="flex-grow-1"></div>
               <div class="caption" v-if="jwt.au && !isEdit">{{formData.exportFieldsSrc}}:{{exportFieldsSrcDesc}}</div>
            </v-card-title>

            <v-card-text
               class="pb-0"
               :loading="loadingNewImport"
            >
               <div v-if="errMsg"
                  class="pb-4 red--text font-weight-bold"
               >{{errMsg}}
               </div>

               <!-- V241204 -->
               <div v-if="sftpImportsCount >= maxSftpCount" class="pb-3">
                  <v-icon color="warning">warning_amber</v-icon>
                  <span class="pl-2 font-italic font-weight-bold">You have exceeded the allowed limit of {{maxSftpCount}} SFTP imports.</span>
               </div>

               <v-tabs
                  class="elevation-2"
                  background-color="grey lighten-2 accent-4"
                  slider-color="black"
                  v-model="tab"
               >
                  <v-tab v-if="!isEdit"
                     :class="isMainFormValid ? '' : 'red--text'"
                  >General</v-tab>
                  <v-tab v-if="!isEdit">Settings</v-tab>
                  <v-tab v-if="formData.shouldGeneratePurl && !isEdit"
                     :class="isPurlsFormValid ? '' : 'red--text'"
                  >PURL Columns</v-tab>
                  <v-tab v-if="formData.shouldGeneratePurl && !isEdit"
                     :class="isDedupsFormValid ? '' : 'red--text'"
                  >Dedup Columns</v-tab>
                  <v-tab
                     :class="isSftpFormValid && isPgpFormValid ? '' : 'red--text'"
                     :disabled="formData.isFileSelected || sftpImportsCount >= maxSftpCount"
                  >SFTP & PGP</v-tab>
                     <!--TODO: :disabled="!isAdminUser || !formData.isFileSelected" -->
                  <v-tab v-if="!isEdit"
                     :class="isValidationsFormValid ? '' : 'red--text'"
                     :disabled="isValidationsTabDisabled"
                  >Validations</v-tab>
                  <v-tab v-if="!isEdit"
                     :class="isEmailsFormValid ? '' : 'red--text'"
                  >Notification Emails</v-tab>

                  <v-tabs-items v-model="tab">
                     <v-tab-item v-if="!isEdit"><!-- General -->
                        <v-card flat tile>
                           <v-card-text>
                              <v-row class="pb-2">
                                 <v-col xs="12" sm="12" md="9" class="py-2">
                                    <v-text-field autofocus dense persistent-hint required
                                       v-model="formData.importName"
                                       ref="importName"
                                       hint="* Import Name"
                                       placeholder="enter a name for the import or upload a file"
                                       :counter="100"
                                       :maxlength="100"
                                       :rules="[rules.required, rules.minLength, rules.duplicate]"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="3" class="py-2">
                                    <v-select dense persistent-hint hide-selected required
                                       ref="delimiter"
                                       v-model="formData.delimiter"
                                       hint="* File Delimiter"
                                       :items="delimiterItems"
                                    ></v-select>
                                 </v-col>
                              </v-row>
                              <v-row v-if="formData.previewHeaders.length" class="pt-0">
                                 <v-col cols="12" class="pt-0">
                                    <v-expansion-panels focusable multiple
                                       v-model="panel"
                                    >
                                       <v-expansion-panel>
                                          <v-expansion-panel-header><strong>File Preview</strong></v-expansion-panel-header>
                                          <v-expansion-panel-content class="pt-2 px-0">
                                             <v-data-table dense fixed-header
                                                class="elevation-1"
                                                item-key="_mfi_seq"
                                                :footer-props="{
                                                   itemsPerPageOptions: [5, 10],
                                                   showFirstLastPage: true,
                                                   disableItemsPerPage: true
                                                }"
                                                :headers="formData.previewHeaders"
                                                :hide-default-footer="formData.previewItems.length <= 5"
                                                :items="formData.previewItems"
                                                :items-per-page="5"
                                                :no-data-text="$t('no-data-text', { value: 'records' })"
                                             ></v-data-table>
                                          </v-expansion-panel-content>
                                       </v-expansion-panel>
                                    </v-expansion-panels>
                                 </v-col>
                              </v-row>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item v-if="!isEdit"><!-- Settings -->
                        <v-card flat tile>
                           <v-card-text>
                              <v-row>
                                 <v-col class="pt-1 pb-0 font-weight-bold">Please note that "Generate PURL" and "Same PURL" options cannot be changed after the 1st import is submitted. Also, "Cast Types", "Persist Event", and "Reject on Error" options cannot be changed once they are set to true.</v-col>
                              </v-row>
                              <v-row class="pt-2">
                                 <v-col xs="12" sm="12" md="2" class="py-0 pr-0">
                                    <v-switch
                                       class="mx-0 mt-2 mb-0 pb-0"
                                       label="Generate PURL"
                                       :disabled="importsStats.count > 0"
                                       v-model="formData.shouldGeneratePurl"
                                       @change="generatePurlChanged"
                                    ></v-switch>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="2" class="py-0 pl-5 pr-0">
                                    <v-switch
                                       class="mx-0 mt-2 mb-0 pb-0"
                                       label="Same PURL"
                                       :disabled="!formData.shouldGeneratePurl || importsStats.count > 0"
                                       v-model="formData.dedupHashMatching"
                                    ></v-switch>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="2" class="py-0">
                                    <v-switch
                                       class="mx-0 mt-2 mb-0 pb-0"
                                       label="Cast Types"
                                       :disabled="imports && imports.length > 0 && imports[0].typeCasting"
                                       v-model="formData.shouldCastTypes"
                                    ></v-switch>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="2" class="py-0" v-show="false">
                                    <v-switch
                                       class="mx-0 mt-2 mb-0 pb-0"
                                       label="Persist Event"
                                       :disabled="imports && imports.length > 0 && imports[0].persistEvent"
                                       v-model="formData.shouldPersistEvent"
                                    ></v-switch>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="2" class="py-0 pr-0">
                                    <v-switch
                                       class="mx-0 mt-2 mb-0 pb-0"
                                       label="Reject on Error"
                                       :disabled="imports && imports.length > 0 && imports[0].rejectOnError"
                                       v-model="formData.shouldRejectOnError"
                                    ></v-switch>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="2" class="py-0">
                                    <v-switch
                                       class="mx-0 mt-2 mb-0 pb-0"
                                       label="Auto Export"
                                       v-model="formData.shouldExport"
                                    ></v-switch>
                                 </v-col>
                              </v-row>
                              <v-row v-if="showExportDefinition">
                                 <v-col cols="12" class="py-0 pr-0">
                                    <v-card flat class="py-0">
                                       <v-card-title class="mx-0 my-0 px-0 py-0">
                                          <div class="flex-grow-1"></div>
                                          <bt-export-child
                                             :debug="debug"
                                             :export-fields="formData.exportFields"
                                             v-model="formData.exportDefinition"
                                             @change="exportDefinitionChanged"
                                          ></bt-export-child>
                                       </v-card-title>
                                    </v-card>
                                 </v-col>
                              </v-row>
                              <v-row v-if="showExportDefinition">
                                 <v-col cols="12" class="py-0">
                                    <v-textarea outlined readonly
                                       class="py-0 my-0 caption font-weight-thin"
                                       ref="exportDefinition"
                                       label="Export Definition:"
                                       rows="3"
                                       v-model="formData.strExportDefinition"
                                    ></v-textarea>
                                 </v-col>
                              </v-row>
                              <v-row class="pt-1 pb-3">
                                 <v-col xs="12" sm="12" md="3" class="py-0">
                                    <v-text-field clearable dense persistent-hint
                                       hint="Latitude Column"
                                       :rules="[rules.location(formData.latitudeColumn, formData.longitudeColumn)]"
                                       v-model="formData.latitudeColumn"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="3" class="py-0">
                                    <v-text-field clearable dense persistent-hint
                                       hint="Longitude Column"
                                       :rules="[rules.location(formData.longitudeColumn, formData.latitudeColumn)]"
                                       v-model="formData.longitudeColumn"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="2" class="py-0">
                                    <v-select dense persistent-hint hide-selected required
                                       ref="batchSize"
                                       v-model="formData.batchSize"
                                       hint="* Import Batch Size"
                                       :items="batchSizeItems"
                                    ></v-select>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="4" class="py-0">
                                    <v-select dense persistent-hint hide-selected required
                                       ref="scope"
                                       v-model="formData.scope"
                                       :hint="`* PURL Generation Scope${formData.scope==='account' ? '' : ': Cannot be changed anymore'}`"
                                       :disabled="!canModifyScope"
                                       :items="scopeItems"
                                    ></v-select>
                                 </v-col>
                              </v-row>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item v-if="formData.shouldGeneratePurl && !isEdit"><!-- PURL Columns -->
                        <v-card flat tile>
                           <v-card-text class="py-2 px-0">
                              <bt-dynamic-text-fields
                                 field-label=""
                                 field-placeholder="purl column"
                                 field-type="string"
                                 :debug="debug"
                                 :field-dense="true"
                                 :field-disabled="imports.length > 0 && imports[0].purlGenerationScope === 'account' && imports[0].purlColumns && imports[0].purlColumns.length > 0 && formData.scope === 'account'"
                                 :field-max="3"
                                 :field-outlined="false"
                                 :field-required="true"
                                 :should-init="formData.shouldInitPurls"
                                 v-model="formData.purls"
                                 @form-validation="purlsFormValidated"
                              ></bt-dynamic-text-fields>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item v-if="formData.shouldGeneratePurl && !isEdit"><!-- Dedup Columns -->
                        <v-card flat tile>
                           <v-card-text class="py-2 px-0">
                              <bt-dynamic-text-fields
                                 field-placeholder="dedup column"
                                 field-type="string"
                                 field-validation=""
                                 :debug="debug"
                                 :field-dense="true"
                                 :field-disabled="imports.length > 0 && imports[0].purlGenerationScope === 'account' && imports[0].dedupColumns && imports[0].dedupColumns.length > 0 && formData.scope === 'account'"
                                 :field-max="5"
                                 :field-required="true"
                                 :should-init="formData.shouldInitDedups"
                                 v-model="formData.dedups"
                                 @form-validation="dedupsValidated"
                              ></bt-dynamic-text-fields>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item><!-- SFTP and PGP -->
                        <div v-if="!isEdit" class="pl-5 pt-3">
                           <v-icon color="warning">warning_amber</v-icon>
                           <span class="pl-2 font-italic font-weight-bold">Please note that you can only create {{maxSftpCount}} SFTP imports. Currently you have {{sftpImportsCount}}.</span>
                        </div>
                        <v-expansion-panels multiple focusable
                           class="px-4 py-4"
                           v-model="panels"
                        >
                           <v-expansion-panel readonly
                              class="px-0"
                           >
                              <v-expansion-panel-header hide-actions
                                 class="py-0 font-weight-bold"
                              >SFTP Settings</v-expansion-panel-header>
                              <v-expansion-panel-content class="px-0">
                                 <v-card flat tile>
                                    <v-card-text class="px-0 pt-5 pb-0">
                                       <v-form lazy-validation
                                          ref="sftpForm"
                                          v-model="isSftpFormValid"
                                       >
                                          <v-row class="py-0">
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="pt-1 pb-0">
                                                <v-text-field clearable dense persistent-hint
                                                   ref="host"
                                                   autocomplete="off"
                                                   label="Host"
                                                   v-model="formData.host"
                                                   @keyup="hostChanged(formData.host)"
                                                   @click:clear="hostCleared"
                                                ></v-text-field>
                                             </v-col>
                                             <v-col v-if="formData.host" class="pt-1"
                                                cols="12" xs="12" sm="12" md="4" lg="4"
                                             >
                                                <v-text-field dense persistent-hint
                                                   ref="folder"
                                                   autocomplete="off"
                                                   label="Folder"
                                                   v-model="formData.sftpPath"
                                                   @keyup="formDataChanged"
                                                ></v-text-field>
                                             </v-col>
                                             <v-col v-if="formData.host" class="pt-1"
                                                cols="12" xs="12" sm="12" md="2" lg="2"
                                             >
                                                <v-text-field dense persistent-hint
                                                   ref="port"
                                                   autocomplete="off"
                                                   label="Port"
                                                   type="number"
                                                   min="1"
                                                   v-model="formData.port"
                                                   @keyup="formDataChanged"
                                                ></v-text-field>
                                             </v-col>
                                          </v-row>
                                          <v-row v-if="formData.host" class="py-0">
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0">
                                                <v-text-field dense persistent-hint
                                                   ref="sftpUsername"
                                                   autocomplete="off"
                                                   label="* User Name"
                                                   :rules="[rules.required]"
                                                   v-model="formData.sftpUsername"
                                                   @keyup="formDataChanged"
                                                ></v-text-field>
                                             </v-col>
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0">
                                                <v-text-field counter dense persistent-hint
                                                   ref="sftpPass"
                                                   autocomplete="off"
                                                   label="* Password"
                                                   :type="showPassword ? 'text' : 'password'"
                                                   :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                                   :rules="[rules.required]"
                                                   @click:append="showPassword = !showPassword"
                                                   v-model="formData.sftpPass"
                                                   @keyup="formDataChanged"
                                                ></v-text-field>
                                             </v-col>
                                          </v-row>
                                          <!-- V241002 -->
                                          <v-row v-if="formData.host" class="pb-0">
                                             <v-col cols="12" class="pb-0">
                                                <v-switch dense
                                                   class="mx-0 mt-2 mb-0 pb-0"
                                                   ref="includePgp"
                                                   label="Include PGP"
                                                   :disabled="!isSftpFormValid"
                                                   v-model="formData.includePgp"
                                                   @change="includePgpChanged"
                                                ></v-switch>
                                             </v-col>
                                          </v-row>
                                       </v-form>
                                    </v-card-text>
                                 </v-card>
                              </v-expansion-panel-content>
                           </v-expansion-panel>                                       

                           <v-expansion-panel v-show="formData.includePgp"
                              readonly
                              class="px-0 pb-0"
                           >
                              <v-expansion-panel-header hide-actions
                                 class="py-0 font-weight-bold"
                              >PGP Settings</v-expansion-panel-header>
                              <v-expansion-panel-content class="px-0">
                                 <v-card flat tile>
                                    <v-card-text class="px-0 pt-5 pb-2">
                                       <v-form lazy-validation
                                          ref="pgpForm"
                                          v-model="isPgpFormValid"
                                       >
                                          <v-row class="py-0">
                                             <v-col xs="12" sm="12" md="6">
                                                   <!--V241002 @keyup="passphraseChanged"
                                                   @click:clear="passphraseCleared" -->
                                                <v-text-field clearable dense persistent-hint
                                                   label="Passphrase"
                                                   :disabled="!formData.host || !isSftpFormValid"
                                                   v-model="formData.passphrase"
                                                ></v-text-field>
                                             </v-col>
                                             <v-col v-if="formData.passphrase"
                                                xs="12" sm="12" md="6" class="pt-5 pb-0">
                                                <v-btn block
                                                   color="primary"
                                                   :disabled="!isSftpFormValid"
                                                   @click="generatePgpKeys"
                                                >Generate Key</v-btn>
                                             </v-col>
                                          </v-row>

                                          <v-row v-if="formData.passphrase" v-show="formData.publicKey"
                                             class="py-0"
                                          >
                                             <v-col xs="12" sm="12" md="6" class="py-0">
                                                   <!--V241002 :rules="[rules.required]" -->
                                                <v-textarea dense hide-details
                                                   class="py-0 my-0 caption"
                                                   rows="4"
                                                   v-model="formData.publicKey"
                                                ></v-textarea>
                                             </v-col>
                                             <v-col xs="12" sm="12" md="6" class="pt-0 pb-0">
                                                <v-btn v-if="isClipboard"
                                                   block
                                                   class="caption"
                                                   color="primary"
                                                   :hint="copyToClipboardHint"
                                                   @click="copyToClipboard"
                                                >
                                                   <v-icon dark left class="pl-1">content_copy</v-icon>
                                                   <span>{{copyToClipboardHint}}</span>
                                                </v-btn>
                                             </v-col>
                                          </v-row>
                                       </v-form>
                                    </v-card-text>
                                 </v-card>
                              </v-expansion-panel-content>
                           </v-expansion-panel>
                        </v-expansion-panels>
                     </v-tab-item>

                     <v-tab-item v-if="!isEdit"><!-- File Validation Definitions -->
                           <!-- @change="validationsChanged" -->
                        <bt-import-validations
                           :debug="debug"
                           :file-headers="fileHeaders"
                           :is-power-admin="jwt.pa"
                           v-model="formData.validations"
                           @has-error="validationsHaveError"
                        ></bt-import-validations>
                     </v-tab-item>

                     <v-tab-item v-if="!isEdit"><!-- Notification Emails -->
                        <v-card flat tile>
                           <v-card-text class="py-2 px-0">
                              <bt-dynamic-text-fields
                                 field-placeholder="email"
                                 field-type="email"
                                 :debug="debug"
                                 :field-dense="true"
                                 :field-required="false"
                                 :should-init="formData.shouldInitEmails"
                                 v-model="formData.emails"
                                 @form-validation="emailsValidated"
                              ></bt-dynamic-text-fields>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>
                  </v-tabs-items>
               </v-tabs>
            </v-card-text>

            <v-card-actions :class="actionsClass">
               <v-switch v-if="jwt.au && !isEdit"
                  class="mx-0 my-0 pl-5 pt-3 pb-0"
                  label="Debug"
                  v-model="formData.debug"
               ></v-switch>
               <div class="flex-grow-1"></div>
               <v-btn text
                  :class="`pl-0 ${isEdit ? 'pr-0' : 'pr-3'}`"
                  color="blue darken-1"
                  @click="cancelClicked"
               >Cancel</v-btn>
               <v-btn v-if="isEdit"
                  text
                  class="pl-0"
                  color="blue darken-1"
                  :disabled="!isFormDataChanged || !formData.host || !isSftpFormValid || !isPgpFormValid"
                  @click="updateImport"
               >Update</v-btn>
               <span v-else>
                  <import-file-upload
                     :notActive="!isImportFormValid || errMsg != null"
                     :noUpload="formData.host != '' && formData.host != null"
                     :isCancelled="isCancelled"
                     @file-selected="setImportName"
                     @uploaded="saveImport"
                  />
               </span>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-form>

   <!-- <v-dialog no-click-animation persistent scrollable
      max-width="640px"
      v-model="dialogDelete"
   >
      <v-card>
         <v-card-title class="title grey--text darken-4 font-weight-bold pt-3 pb-0"
         >            
            <v-icon color="error" class="pr-1">warning_amber</v-icon>Deleting {{formatNumber(currImport.processedRecords)}} records of <span class="font-italic px-1">{{currImport.name}}</span>:
         </v-card-title>

         <v-card-text v-if="isAnotherDelete"
            class="error--text"
         >
            A delete request is already in progress. Please try again after a while.
         </v-card-text>
         <v-card-text v-else
            class="pb-0"
         >
            <v-text-field outlined required persistent-hint
               class="pt-3 pb-0"
               ref="currProcessedRecords"
               hint="Please be mindful! This processes cannot be stopped or cancelled once submitted."
               label="* Number of Processed Records to be deleted"
               placeholder="enter the number of processed records of the import"
               type="number"
               :min="currImport.processedRecords"
               :max="currImport.processedRecords"
               v-model="currProcessedRecords"
            ></v-text-field>
            <div v-if="isDeleteInProcess"
               class="error--text font-weight-bold"
            >
               <v-progress-circular indeterminate
                  color="red"
                  :size="20"
                  :width="2"
               ></v-progress-circular>
               Please wait while your delete request is being processed: {{deleteElapsedTime}}
            </div>
         </v-card-text>

         <v-card-actions class="pt-0 pr-4">
            <div class="flex-grow-1"></div>
            <v-btn text
               color="blue darken-1"
               @click="closeDialogDelete"
            >Cancel</v-btn>
            <v-btn v-if="!isAnotherDelete"
               text
               color="blue darken-1"
               :disabled="currProcessedRecords != currImport.processedRecords"
               @click="deleteClicked"
            >Delete</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog> -->

   <v-dialog no-click-animation persistent scrollable
      max-width="480px"
      v-model="dialogDeleteOffers"
   >
      <v-card flat tile :loading="loadingDialogDeleteOffers">
         <v-card-title class="title grey--text darken-4 font-weight-bold pb-4">Delete Unconverted Records</v-card-title>
         <v-card-text v-if="isAnotherDelete"
            class="error--text"
         >
            A delete request is already in progress. Please try again after a while.
         </v-card-text>
         <v-card-text v-else-if="offersCount === 0">
            No unconverted records were found for deletion.
         </v-card-text>
         <v-card-text v-else-if="offersCount > 0"
            class="px-5 pt-2 pb-1">
            <v-icon>warning_amber</v-icon> Please note that only unconverted records can be deleted.
            <!-- <div class="pl-5 ml-2">To delete {{new Intl.NumberFormat().format(offersCount)}} unconverted records of {{new Intl.NumberFormat().format(currImport.processedRecords)}} processed records, type <span class="font-weight-bold">{{offersCount}}</span> in the following box:</div> -->
            <div class="pl-5 ml-2">To delete all unconverted records of the selected import, type '<span class="font-weight-bold">delete</span>' in the following box.
               <br><b>NOTE:</b> You can find out the actual number of deleted records on the <i>Deletes</i> page once the process is completed.
            </div>
            <v-text-field dense outlined hide-details
               ref="numberOfOffers"
               class="py-2"
               v-model="numberOfOffers"
            ></v-text-field>
         </v-card-text>
         <v-card-text v-if="isDeleteInProcess" class="pb-0 red--text">
            Records are being deleted. Please wait...<br>The window will be closed after the process is completed.
         </v-card-text>
         <v-card-actions class="pt-0 pb-2 pr-3">
            <div class="flex-grow-1"></div>
            <v-btn text
               class="px-0"
               color="blue darken-1"
               :disabled="isDeleteInProcess"
               @click="cancelDialogDeleteOffers"
            >Cancel</v-btn>
               <!-- :disabled="numberOfOffers != offersCount || isDeleteInProcess" -->
            <v-btn v-if="offersCount > 0"
               text
               class="px-0"
               color="blue darken-1"
               :disabled="isDeleteInProcess || numberOfOffers != 'delete'"
               @click="submitDialogDeleteOffers"
            >Submit</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>

   <v-dialog no-click-animation persistent scrollable
      max-width="521px"
      v-model="dialogRejectImport"
   >
      <v-card flat tile :loading="loadingDialogRejectImport">
         <v-card-title class="title grey--text darken-4 font-weight-bold pb-4">Rejecting '{{currImport.name}}'</v-card-title>
         <v-card-text v-if="statusChanged"
            class="error--text"
         >
            Sorry, The status of the import has changed to '{{currImport.status}}' and cannot be rejected anymore!
         </v-card-text>
         <v-card-text v-else
            class="px-5 pt-2 pb-1">
            <div class="pl-1">To reject the import, type '<span class="font-weight-bold">{{currImport.name}}</span>' in the following box:</div>
            <v-text-field dense outlined hide-details
               ref="rejectingImportName"
               class="py-2"
               v-model="rejectingImportName"
            ></v-text-field>
         </v-card-text>
         <v-card-actions class="pt-0 pb-2 pr-3">
            <div class="flex-grow-1"></div>
            <v-btn text
               class="px-0"
               color="blue darken-1"
               :disabled="isRejectInProcess"
               @click="cancelDialogRejectImport"
            >Cancel</v-btn>
            <v-btn text
               class="px-0"
               color="blue darken-1"
               :disabled="rejectingImportName != currImport.name || isRejectInProcess"
               @click="rejectImport"
            >Submit</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>

</v-container>
</template>

<script>
import BtExportChild from './BtExportChild.vue';
import BtFilterWrapperWithPanel from './BtFilterWrapperWithPanel.vue';
import BtDynamicTextFields from './BtDynamicTextFields.vue';
import ImportFileUpload from './ImportFileUpload.vue';
import BtImportValidations from './BtImportValidations.vue';
import { addSeconds, differenceInHours, differenceInMinutes, differenceInSeconds, format, parseISO } from "date-fns";
import { APIService } from '../services/cs-api-service.js';
import { BtHelpers } from '../services/bt-helpers.js';
// import { generateKey } from 'openpgp/lightweight';
import { generateKey } from 'openpgp';
import { hasOwn } from '../mixins/bt-mixin.js';

const TABS = { general: 0, settings: 1, purls: 2, dedups: 3, sftp: 4, validations: 5, emails: 6 };
const DISALLOWED_HEADERS = ['__v','_id','_mfi_studio_added_record','_mfi_studio_sync','basepurl','deduphash','error','events','importid','location','purlnumber','rownumber'];

class FormData {
   constructor(initData, shouldInit, isEdit) {
      this.scope = initData.purlGenerationScope || 'account';
      this.purls = initData.purlColumns || [];
      this.shouldInitPurls = shouldInit;

      this.dedups = initData.dedupColumns || [];
      this.shouldInitDedups = shouldInit;

      this.emails = initData.notificationEmails || [];
      this.shouldInitEmails = shouldInit;

      //V2311xx
      // this.validations = initData.columnValidation || [];
      // this.shouldInitValidations = shouldInit;
      // this.indexes = initData.indexObject || {};
      // this.shouldInitIndexes = shouldInit;

      this.mappings = initData.columnMapping || {};
      this.shouldInitMappings = shouldInit;

      this.latitudeColumn = initData.latitudeColumn || '';
      this.longitudeColumn = initData.longitudeColumn || '';
      this.delimiter = initData.fileDelimiter || ',';
      this.batchSize = initData.processBatchSize || 100;
      this.shouldGeneratePurl = hasOwn(initData, 'generatePURL') ? initData.generatePURL : true;
      this.shouldExport = hasOwn(initData, 'exportOnImportCompletion') ? initData.exportOnImportCompletion : false;
      this.exportDefinition = hasOwn(initData, 'exportDefinition') ? initData.exportDefinition : {};
      this.strExportDefinition = Object.keys(this.exportDefinition).length ? JSON.stringify(this.exportDefinition) : '{}';
      this.shouldCastTypes = hasOwn(initData, 'typeCasting') ? initData.typeCasting : false;
      this.shouldPersistEvent = hasOwn(initData, 'persistEvent') ? initData.persistEvent : false;
      this.importName = '';
      this.dedupHashMatching = hasOwn(initData, 'dedupHashMatching') ? initData.dedupHashMatching : false;
      this.shouldRejectOnError = hasOwn(initData, 'rejectOnError') ? initData.rejectOnError : false;

      if (isEdit) {
         this.host = initData.sftp && initData.sftp.sftpCredentials ? initData.sftp.sftpCredentials.host : '';
         this.port = initData.sftp && initData.sftp.sftpCredentials ? initData.sftp.sftpCredentials.port : '';
         this.sftpUsername = initData.sftp && initData.sftp.sftpCredentials ? initData.sftp.sftpCredentials.username : '';
         this.sftpPass = initData.sftp && initData.sftp.sftpCredentials ? initData.sftp.sftpCredentials.password : '';
         this.sftpPath = initData.sftp ? initData.sftp.sftpPath : '';
         this.passphrase = initData.sftp && initData.sftp.pgpCredentials ? initData.sftp.pgpCredentials.passPhrase : '';
         this.privateKey = initData.sftp && initData.sftp.pgpCredentials ? initData.sftp.pgpCredentials.privateKey : '';
         this.includePgp = this.privateKey ? true : false;
      } else {
         this.host = '';
         this.port = 22;
         this.sftpUsername = '';
         this.sftpPass = '';
         this.sftpPath = '';
         this.passphrase = '';
         this.privateKey = '';
         this.includePgp = false;
      }
      this.publicKey = '';

      this.debug = false;
      this.previewHeaders = [];
      this.previewItems = [];
      this.exportFields = [];
      this.exportFieldsSrc = '';
      this.isFileSelected = false;

      this.validations = initData.validations || {};
   }
}

export default {
   name: "CsImports",

   components: {
      BtExportChild,
      BtFilterWrapperWithPanel,
      BtDynamicTextFields,
      ImportFileUpload,
      BtImportValidations
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         maxSftpCount: 2,
         jwt: {},
         apiService: null,
         tab: null,
         tabSftp: null,
         formData: new FormData({}, false),
         imports: [],
         rules: {
            required: value => !!value || "Value is required!",
            minLength: value => value.length >= 5 || "Name should have more than 5 charecters",
            duplicate: value => this.imports.filter(imprt => imprt.name === value).length === 0 || 'Value is duplicate!',
            longitude: value => this.formData.latitudeColumn?.trim() ? Boolean(value?.trim()) || "Value is required!" : true,
            location: (source, destination) => {
               const src = source?.trim().toLowerCase();
               const dest = destination?.trim().toLowerCase();
               if (src && dest)
                  return src != dest || 'Latitude/Longitude columns cannot be same!';
               else if (dest)
                  return 'Value is required!';
               else
                  return true;
            }
         },
         headers: [
            { text: 'Name', value: 'name', align: 'left', sortable: true },
            { text: 'Creation', value: 'creationDate', align: 'left', sortable: false },
            { text: 'Creator', value: 'creator', align: 'left', sortable: true },
            { text: 'Status', value: 'status', align: 'left', sortable: true },
            { text: 'Processed Records', value: 'processedRecords', align: 'left', sortable: true },
            { text: 'Start', value: 'startDate', align: 'left', sortable: true },
            { text: 'Last Activity', value: 'lastActivityDate', align: 'left', sortable: true },
            { text: 'PURL Status', value: 'purlStatus', align: 'left', sortable: true },
            { text: 'PURL Processed Records', value: 'purlProcessedRecords', align: 'left', sortable: true },
            { text: 'PURL Last Activity', value: 'purlLastActivityDate', align: 'left', sortable: true },
            { text: 'Deleted Records', value: 'recordsDeleted', align: 'left', sortable: true },
            { text: 'Actions', value: 'action', align: 'right', sortable: false }
         ],
         batchSizeItems: [
            { text: '25', value: 25 },
            { text: '50', value: 50 },
            { text: '75', value: 75 },
            { text: '100', value: 100 }
         ],
         delimiterItems: [
            { text: 'Comma', value: ',' },
            { text: 'Tab', value: '\t' },
            { text: 'Pipe', value: '|' }
         ],
         scopeItems: [
            { text: 'Account Level', value: 'account' },
            { text: 'Import Level', value: 'import' }
         ],
         search: '',
         tdHeader: 'font-weight-bold font-italic',
         tdContent: 'font-italic',
         importsDisabledDuration: 0,
         btnRefreshImportsDisabled: true,
         loadingImports: false,
         loadingNewImport: false,
         dialogImport: false,
         dialogUpload: false,
         isMainFormValid: false,
         isPurlsFormValid: false,
         isDedupsFormValid: false,
         isEmailsFormValid: true,
         isSftpFormValid: true,
         isPgpFormValid: true,
         canModifyScope: false,
         // importsCount: 0,
         options: {},
         shouldInitFilterDefiner2: false,


         filter2: {
            standard: [{ $match: {} }]
         },
         searchFields: [
            { text: 'Name', value: 'name', type: 'string', isIndexed: true },
            { text: 'Creation Date', value: 'creationDate', type: 'date', isIndexed: true },
            { text: 'Status', value: 'status', type: 'string', isIndexed: true },
            { text: 'Processed Records', value: 'processedRecords', type: 'number', isIndexed: true },
            { text: 'Start Date', value: 'startDate', type: 'date', isIndexed: true },
            { text: 'Last Activity Date', value: 'lastActivityDate', type: 'date', isIndexed: true },
            { text: 'PURL Status', value: 'purlStatus', type: 'string', isIndexed: true },
            { text: 'PURL Processed Records', value: 'purlProcessedRecords', type: 'number', isIndexed: true },
            { text: 'PURL Last Activity Date', value: 'purlLastActivityDate', type: 'date', isIndexed: true },
            { text: 'Deleted Records', value: 'recordsDeleted', type: 'number', isIndexed: true }
         ],
         searchFieldsValues: {
            status: ['waiting','processing','sftp', 'sftp-processing', 'completed','recovery','rejected'],
            purlStatus: ['waiting','processing','completed','recovery','rejected']
         },
         panels: [0, 1],
         showPassword: false,
         isClipboard: false,
         copyToClipboardHint: '',
         actionsClass: 'mr-1 pr-5',
         btHelpers: null,
         isAnotherDelete: null,
         dialogDelete: false,
         currImport: {},
         currProcessedRecords: null,
         isDeleteInProcess: null,
         deleteElapsedTime: null,
         firstLoad: true,
         panel: [],
         errMsg: null,
         isCancelled: null,
         dialogDeleteOffers: false,
         loadingDialogDeleteOffers: false,
         offersFilter: null,
         offersCount: -1,
         numberOfOffers: '',
         isValidationsFormValid: true,
         fileHeaders: [],
         dialogRejectImport: false,
         loadingDialogRejectImport: false,
         rejectingImportName: '',
         isRejectInProcess: false,
         statusChanged: false,
         importsStats: {},
         isEdit: false,
         isFormDataChanged: false,
         sftpImportsCount: 0
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      canDeleteImport() {
         return this.$store.getters.user.policies.includes('contact-import-delete');
      },

      canUpdateImport() {
         return this.$store.getters.user.policies.includes('contact-import-update');
      },

      showExportDefinition() {
         return this.formData && this.formData.shouldExport && this.formData.exportFields.length;
      },

      isImportFormValid() {
         return this.formData.importName && this.isMainFormValid &&
            this.isEmailsFormValid && this.isSftpFormValid && this.isPgpFormValid &&
            (this.formData.shouldGeneratePurl ? this.isPurlsFormValid && this.isDedupsFormValid : true) &&
            this.isValidationsFormValid;
      },

      exportFieldsSrcDesc() {
         const src = this.formData ? this.formData.exportFieldsSrc : '';
         if (src === 'file')
            return 'Headers were obtained from the file';
         else if (src === 'import')
            return 'Headers were obtained from the last import';
         else
            return 'No headers were obtained yet';
      },

      isAdminUser() {
         // alert(this.$store.getters.isAdminUser)
         return this.$store.getters.isAdminUser;
      },

      isValidationsTabDisabled() {
         if (this.formData.isFileSelected)
            return false;
         else if (this.formData.host)
            return !this.formData.validations.header || !this.formData.validations.header.requiredHeaderColumns.length;
         else
            return true;
      }
   },

   watch: {
      token() {
         this.init();
         this.nextAction();
      },

      options: {
         handler (val) {
            // alert('in watch: options=' + JSON.stringify(val));
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
               this.filter2.sort = sort;
            }
            this.getImports();
         }
      },

      tab: {
         handler (val) {
            // alert('in watch: tab=' + val);
            if (val === TABS.general) {   //0
               setTimeout(() => {
                  //V241001: Added '?'
                  this.$refs.importName?.focus();
               }, 10);
            } else if (val === TABS.sftp) { //4
               setTimeout(() => {
                  this.$refs.host.focus();
               }, 10);
            }
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----CsImport V241204.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      // hasError(ref) {
      //    let res = false;
      //    if (!this.isMainFormValid && this.$refs[ref]) {
      //       // if (ref === 'importName') alert('isDirty=' + this.$refs[ref].isDirty);
      //       res = this.$refs[ref].hasError;
      //    }

      //    return res;
      // },

      formatNumber(number) {
         return new Intl.NumberFormat().format(number);
      },

      isFormatValid(value) {
         let result;
         if (value === 'yyyyMMddTHHmmzzz')
            result = true;
         else if (value.length >= 6) {
            const formatParts = value.split(' ');
            if (formatParts.length <= 3) {
               result = this.isDateFormatValid(formatParts[0]);
               if (result && formatParts.length > 1) {
                  const tz = formatParts.length === 3 ? formatParts[2] : null;
                  result = this.isTimeFormatValid(formatParts[1], tz);
               }
            }
         }

         return result;
      },

      isDateFormatValid(date) {
         const dateParts = date.includes('/') ? date.split('/') : (date.includes('-') ? date.split('-') : date.split('.'));
         if (dateParts.length === 3) {
            const dmy = {};
            dateParts.forEach(part => {
               if (part === 'd' || part === 'dd')
                  dmy.d = true;
               else if (part === 'M' || part === 'MM' || part === 'MMM' || part === 'MMMM')
                  dmy.m = true;
               else if (part === 'yy' || part === 'yyyy')
                  dmy.y = true;
            });

            return dmy.d && dmy.m && dmy.y;
         } else
            return false;
      },

      isTimeFormatValid(time, tz) {
         const timeParts = time.split(':');
         if (timeParts.length > 1) {
            const h12 = timeParts[0] === 'h' || timeParts[0] === 'hh';
            const h24 = timeParts[0] === 'H' || timeParts[0] === 'HH';
            if (h12 || h24) {
               if (h12) {
                  if (tz != 't' && tz != 'tt')
                     return false;
               } else if (tz != null)
                  return false;
            } else
               return false;

            if (timeParts[1] != 'm' && timeParts[1] != 'mm')
               return false;
         }

         if (timeParts.length === 3)
            return timeParts[2] === 's' || timeParts[2] === 'ss';
         else
            return false;
      },


      async init() {
         try {
            this.imports = [];

            if (this.token) {
               this.jwt = JSON.parse(Buffer.from(this.token.split('.')[1], 'base64'));
               this.log('in init(): jwt=' + JSON.stringify(this.jwt));
               this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
               this.btHelpers = new BtHelpers(this.token, this.isActualEndpoint, this.debug);
               // this.importsCount = await this.getImportsCount(this.filter2);
               await this.getImportsStats();
               this.shouldInitFilterDefiner2 = true;
            } else
               this.jwt = {};
         } catch (error) {
            alert('Exception while parsing token: ' + error.message);
         }
      },

      //V241001: Uncommentted
      async getImportsCount(filter) {
         this.loadingImports = true;
         let result = await this.apiService.getImportsCount(filter);
         this.loadingImports = false;
         if (result.logout)
            this.logout();

         return result.message ? 0 : result.data;
      },

      //V240301
      async getImportsStats() {
         this.loadingImports = true;
         let result = await this.apiService.getImportsStats(this.filter2);
         this.loadingImports = false;
         if (result.logout)
            this.logout();

         this.importsStats = result.message ? { count: 0 } : result.data;
      },

      refreshClicked() {
         this.getImports();
      },

      async getImports() {
         // alert(`in getImports(): filter2=${JSON.stringify(this.filter2)}`);
         this.loadingImports = true;
         this.btnRefreshImportsDisabled = true;
         this.imports = [];
         let result = await this.apiService.getImports(this.filter2, this.options.itemsPerPage, this.options.page);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.imports = result.data;
         }

         if (this.imports.length === 0 || this.imports[0].purlGenerationScope === 'account')
            this.canModifyScope = true;
         else
            this.canModifyScope = false;

         if (this.imports.length || result.message) {
            this.importsDisabledDuration = 10;
            setTimeout(() => {
               this.btnRefreshImportsDisabled = false;
            }, 10000);

            const setIntervalImports = setInterval(() => {
               if (this.importsDisabledDuration > 0)
                  this.importsDisabledDuration--;
               else
                  clearInterval(setIntervalImports);
            }, 1000);
         }

         this.loadingImports = false;
      },

      getIcon(status) {
         switch (status) {
            case 'waiting':
               return { color: 'grey', icon: 'schedule' };
            case 'processing':
               return { color: 'blue', icon: 'hourglass_empty' };
            case 'sftp':
               return { color: 'grey lighten-2', icon: 'cloud' };  //mdi-cloud-outline
            case 'sftp-processing':
               return { color: 'blue', icon: 'cloud_off' }; //mdi-cloud-off-outline
            case 'completed':
               return { color: 'green', icon: 'done' };
            case 'recovery':
               return { color: 'orange', icon: 'restore' };
            case 'rejected':
               return { color: 'red', icon: 'clear' };
            default:
               return { color: '', icon: '' };
         }
      },

      canBeRejected(status) {
         return ['waiting', 'processing', 'recovery'].includes(status);
      },

      async rejectImportClicked(item) {
         // console.error(`in rejectImportClicked(): item=${JSON.stringify(item)}`);
         this.currImport = JSON.parse(JSON.stringify(item));
         this.currItem = item;
         this.statusChanged = false;
         this.isRejectInProcess = false;
         this.dialogRejectImport = true;

         setTimeout(() => {
            if (this.$refs.rejectingImportName)
               this.rejectingImportName = '';
               this.$refs.rejectingImportName.focus();
         }, 10);
      },

      async rejectImport() {
         // console.error(`in rejectImport(): item=${JSON.stringify(this.currImport)}`);
         this.isRejectInProcess = true;
         this.loadingDialogRejectImport = true;
         let result = await this.apiService.getImport(this.currImport._id);
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.currImport = result.data;
            this.statusChanged = !this.canBeRejected(result.data.status);
            if (this.statusChanged) {
               this.updateCurrImport(result.data);
            } else {
               const result = await this.apiService.rejectImport(this.currImport);
               if (result.logout)
                  this.logout();
               else if (!result.message) {
                  // this.updateCurrImport(result.data);
                  //had to do this because it didn't bring the delete icon when I updated the item
                  this.getImports();
                  this.cancelDialogRejectImport();
                  this.$emit('snackbar-event', `'${this.currImport.name}' import was cancelled.`);

               }
            }
         }
         this.loadingDialogRejectImport = false;
         // this.isRejectInProcess = false;
         // this.rejectingImportName = '';
      },

      updateCurrImport(newItem) {
         Object.assign(
            this.imports.find(imprt => imprt._id === this.currImport._id),
            newItem
         );
      },

      cancelDialogRejectImport() {
         this.dialogRejectImport = false;
         // this.currImport = {};
      },

      async deleteImport(importData) {
         if (confirm(`Are you sure you want to DELETE '${importData.name}'?`)) {
            this.loadingImports = true;
            this.log('in deleteImport(): importData=' + JSON.stringify(importData));
            const result = await this.apiService.deleteImport(importData._id);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               const ind = this.imports.indexOf(importData);
               this.imports.splice(ind, 1);
               this.$emit('snackbar-event', `'${importData.name}' import was deleted.`);
            }
            this.loadingImports = false;
         }
      },

      async editImportClicked(item) {
         // console.error(`in editImportClicked(): item=${JSON.stringify(item)}`);
         this.isEdit = true;
         this.isFormDataChanged = false;
         this.currImport = JSON.parse(JSON.stringify(item));
         this.errMsg = null;
         this.panel = [];
         this.formData = new FormData(item, false, true);
         this.isCancelled = false;
         this.dialogImport = true;

         // V241204
         // setTimeout(() => {
         //    this.tab = TABS.sftp;
         // }, 10);
      },

      async updateImport() {
         this.isSftpFormValid = this.$refs.sftpForm.validate();
         this.isPgpFormValid = this.$refs.pgpForm.validate();

         // alert('isPurlsFormValid=' + this.isPurlsFormValid +
         //       '\nisDedupsFormValid=' + this.isDedupsFormValid  +
         //       '\nisEmailsFormValid=' + this.isEmailsFormValid  +
         //       '\nisMainFormValid=' + this.isMainFormValid);

         if (this.isSftpFormValid && this.isPgpFormValid) {
            // alert('in saveImport(): formData.mappings=' + JSON.stringify(this.formData));
            this.loadingNewImport = true;
            this.errMsg = null;

            const sftpCredentials = {
               host: this.formData.host,
               username: this.formData.sftpUsername,
               password: this.formData.sftpPass
            };

            if (this.formData.port)
               sftpCredentials.port = +this.formData.port;

            const sftpData = {
               sftpCredentials: sftpCredentials
            };

            if (this.formData.sftpPath)
               sftpData.sftpPath = this.formData.sftpPath;

            if (this.formData.includePgp) {
               sftpData.pgpCredentials = {
                  passPhrase: this.formData.passphrase,
                  privateKey: this.formData.privateKey
               };
            }

            this.log('in updateImport(): sftpData=' + JSON.stringify(sftpData));

            this.currImport.sftp = sftpData;
            let result = await this.apiService.updateImport(this.currImport);
            this.loadingNewImport = false;
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$emit('snackbar-event', `'${this.currImport.name}' was updated.`);
               const updatedImport = this.imports.find(imprt => imprt._id === this.currImport._id);               
               updatedImport.status = result.data.status;
               updatedImport.sftp = result.data.sftp;
               updatedImport.lastActivityDate = result.data.lastActivityDate;
            }

            this.cancelImport();
         }
      },

      getDelimiterDesc(delimiter) {
         return this.delimiterItems.filter(d => d.value === delimiter)[0].text;
      },

      formatDate(date, withTime) {
         if (date) {
            // alert('in formatDate(): date='+date+'\nwithTime=' + withTime + '\nparseISO='+parseISO(date));
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            // const dateParts = formatteddate.split(' ');
            // if (withTime) return dateParts[0] + '\n' + dateParts[1] + ' ' + dateParts[2];
            if (withTime) return formatteddate;
            else return formatteddate.split(' ')[0];
         }
      },

      calculateProcessingTime(dateEnded, dateStarted, extraSeconds) {
         const isoEnded = parseISO(dateEnded);
         const isoStarted = addSeconds(parseISO(dateStarted), extraSeconds || 0);
         const h = differenceInHours(isoEnded, isoStarted);
         const min = differenceInMinutes(isoEnded, isoStarted);
         const m = min - h * 60;
         const s = differenceInSeconds(isoEnded, isoStarted) - h * 3600 - m * 60;
         let diff = '';
         if (h) diff = h + 'h ';
         if (min) diff += m + 'm ';
         diff += s + 's';
         return diff;
      },

      calculateProcessingSpeed(processedRecs, dateEnded, dateStarted, extraSeconds) {
         const cntPerHour = this.formatNumber(Math.round(processedRecs * 3600 / differenceInSeconds(parseISO(dateEnded), addSeconds(parseISO(dateStarted), extraSeconds || 0))));
         // alert(`processedRecs=${processedRecs}\ndateEnded=${dateEnded}\ndateStarted=${dateStarted}\ncntPerHour=${cntPerHour}`);
         return cntPerHour;
      },

      getMappings(columnMapping) {
         const mappings = [];
         if (columnMapping)
            for (let prop in columnMapping) {
               mappings.push(prop + '=' + columnMapping[prop]);
            }
         return mappings;
      },

      async newImportClicked() {
         this.dialogImport = true;
         this.errMsg = null;
         this.panel = [];
         this.formData = new FormData(this.imports[0] || {}, true);
         this.isCancelled = false;
         
         if (this.firstLoad) {
            this.tab = TABS.dedups;
            this.firstLoad = false;

            setTimeout(() => {
               this.tab = TABS.purls;
            }, 1);

            setTimeout(() => {
               this.tab = TABS.general;
            }, 5);

            const filter = {
               standard: [{ 
                  $match: {
                     "sftp.sftpCredentials.host": { $exists: true, $ne: "" }
                  }
               }]
            };
            this.sftpImportsCount = await this.getImportsCount(filter);
         }

         // setTimeout(() => {
         //    this.$refs.importName.focus();
         // }, 10);
      },

      purlsFormValidated(val) {
         this.log('in purlsFormValidated(): val=' + val);
         this.isPurlsFormValid = val;
      },
      dedupsValidated(val) {
         this.log('in dedupsValidated(): val=' + val);
         this.isDedupsFormValid = val;
      },
      emailsValidated(val) {
         this.log('in emailsValidated(): val=' + val);
         this.isEmailsFormValid = val;
      },

      async generatePurlChanged(val) {
         if (!val) {
            this.isPurlsFormValid = true;
            this.formData.dedupHashMatching = false;
         }

         if (this.formData.isFileSelected)
            await this.getExportFields();
      },

      exportDefinitionChanged(val) {
         this.formData.strExportDefinition = JSON.stringify(val);
      },

      async hostChanged(val) {
         // alert('in hostChanged(): val=' + JSON.stringify(val) + ', formData.host=' + this.formData.host);
         this.formDataChanged();
         this.actionsClass = 'mr-1';
         if (val) {
            this.isSftpFormValid = this.$refs.sftpForm.validate();
            await this.getExportFields();
         } else {
            this.formData.passphrase = '';
            this.actionsClass += ' pr-5';
         }
      },

      hostCleared() {
         // alert('in hostCleared(): val=' + JSON.stringify(val) + ', formData.host=' + this.formData.host);
         this.formData.host = '';
         this.hostChanged(this.formData.host);
      },

      // V241002
      includePgpChanged(val) {
         if (!val)
            this.formDataChanged();
      },

      /* V241002
      passphraseChanged(val) {
         // alert('in passphraseChanged(): val=' + JSON.stringify(val) + ', formData.host=' + this.formData.host);
         this.formDataChanged();

         if (val)
            this.isPgpFormValid = this.$refs.pgpForm.validate();
         // else
         //    this.formData.passphrase = '';
      },

      passphraseCleared() {
         // alert('in passphraseCleared(): val=' + JSON.stringify(val) + ', formData.host=' + this.formData.host);
         this.passphraseChanged('');
      },
      */

      async generatePgpKeys() {
         // revocationCertificate also can be added to the end of const.
         const { privateKey, publicKey } = await generateKey({
            // ECC keys (smaller and faster to generate):
            type: 'ecc',
            curve: 'p256', // ECC curve name, defaults to curve25519
            // RSA keys (increased compatibility):
            // type: 'rsa',
            // rsaBits: 4096, // RSA key size (defaults to 4096 bits)
            userIDs: [{ name: this.jwt.email.split('@')[0], email: this.jwt.email }], // you can pass multiple user IDs
            passphrase: this.formData.passphrase, // protects the private key
            format: 'armored' // output key format, defaults to 'armored' (other options: 'binary' or 'object')
         });

         this.formData.privateKey = privateKey;
         this.formData.publicKey = publicKey;
         this.copyToClipboardHint = 'Copy Public Key to the Clipboard';
         this.formDataChanged();
      },

      formDataChanged() {
         this.isFormDataChanged = true;
      },

      async copyToClipboard() {
         await navigator.clipboard.writeText(this.formData.publicKey);
         this.copyToClipboardHint = 'Public Key was copied to the clipboard at ' + new Date().toString().split(' ')[4];
      },

      cancelClicked() {
         this.isCancelled = true;
         this.cancelImport();
      },

      cancelImport() {
         this.dialogImport = false;
         this.formData = new FormData({}, false);
         if (this.isEdit) {
            this.isEdit = false;
            this.currImport = {};
         }
         this.tab = TABS.general;
      },

      async setImportName(arg) {
         this.formData.isFileSelected = true;
         this.formData.previewHeaders = [];
         this.errMsg = null;

         if (hasOwn(arg, 'fileName')) {
            // const fn = arg.fileName;
            // const ind = fn.lastIndexOf('.');
            // this.formData.importName = ind > 0 ? fn.substring(0, ind) : fn;
            if (!this.formData.importName.trim())
               this.formData.importName = arg.fileName.replace(/.csv/i, '').replace(/.txt/i, '').replace(/.zip/i, '');

            if (hasOwn(arg, 'fileData') && arg.fileData.data && arg.fileData.data.length) {
               this.fileHeaders = [...arg.fileData.data[0]];
               arg.fileData.data[0].forEach(header => {
                  this.formData.previewHeaders.push({
                     text: header,
                     value: header.toLowerCase(),
                     align: 'left',
                     sortable: false
                  });
               });
               // alert('previewHeaders=' + JSON.stringify(this.formData.previewHeaders));
               if (arg.fileData.data.length > 1) {
                  // let len = Math.min(5, arg.fileData.data.length);
                  let len = arg.fileData.data.length;
                  if (arg.fileData.data[len - 1].length < this.formData.previewHeaders.length)
                     len--;

                  for (let i = 1; i < len; i++) {
                     const record = arg.fileData.data[i];
                     const item = {};
                     for (let j = 0; j < this.formData.previewHeaders.length; j++) {
                        item[this.formData.previewHeaders[j].value] = record[j];
                     }
                     item._mfi_seq = i;
                     this.formData.previewItems.push(item);
                  }
                  // alert('previewItems=' + JSON.stringify(this.formData.previewItems));
               }

               if (hasOwn(arg.fileData, 'meta') && hasOwn(arg.fileData.meta, 'delimiter') && arg.fileData.meta.delimiter)
                  this.formData.delimiter = arg.fileData.meta.delimiter;

               //V240829
               this.validateHeadersAgainstDisallowedHeaders();
               if (this.errMsg) {
                  this.tab = TABS.general;
                  return;
               }
            } else {
               this.fileHeaders = [];
            }
         }

         await this.getExportFields();

         if (this.tab === TABS.sftp)
            this.tab = TABS.general;
      },

      async getExportFields() {
         if (this.formData.previewHeaders.length) {
            this.formData.exportFields = [
               ...this.formData.previewHeaders,
               ...this.btHelpers.getSystemFields(this.formData.shouldGeneratePurl)
            ];
            this.formData.exportFieldsSrc = 'file';
         } else {
            this.formData.exportFields = await this.btHelpers.getImportHeaders(this.btHelpers.getInitialFilter());
            this.formData.exportFieldsSrc = 'import';
         }

         if (this.formData.exportFields.length) {
            if (!this.formData.exportFields.find(f => f.value === '_id'))
               this.formData.exportFields.push({ text: '_id', value: '_id', type: 'string', isIndexed: true, internalField: true });
               
            const importField = this.formData.exportFields.find(f => f.value === 'importId');
            if (importField)
               importField.text = importField.value;
         }

         this.log(`in getExportFields(): exportFieldsSrc=${this.formData.exportFieldsSrc}, exportFields=${JSON.stringify(this.formData.exportFields)}`);
      },

      async saveImport(arg) {
         this.isMainFormValid = this.$refs.mainForm.validate();

         // alert('isPurlsFormValid=' + this.isPurlsFormValid +
         //       '\nisDedupsFormValid=' + this.isDedupsFormValid  +
         //       '\nisEmailsFormValid=' + this.isEmailsFormValid  +
         //       '\nisMainFormValid=' + this.isMainFormValid);

         if (!this.isImportFormValid)
            return;

         // alert('in saveImport(): formData.mappings=' + JSON.stringify(this.formData));
         this.loadingNewImport = true;
         this.errMsg = null;

         const importData = {
            exportOnImportCompletion: this.formData.shouldExport,
            fileDelimiter: this.formData.delimiter,
            generatePURL: this.formData.shouldGeneratePurl,
            name: this.formData.importName,
            processBatchSize: this.formData.batchSize,
            purlGenerationScope: this.formData.scope,
            dedupHashMatching: this.formData.dedupHashMatching,
            typeCasting: this.formData.shouldCastTypes,
            persistEvent: this.formData.shouldPersistEvent,
            rejectOnError: this.formData.shouldRejectOnError,
            debug: this.formData.debug
         };

         const latCol = this.formData.latitudeColumn?.trim();
         if (latCol) {
            if (this.formData.previewHeaders.length) {
               const latHeader = this.formData.previewHeaders.find(f => f.value === latCol.toLowerCase());
               if (latHeader)
                  importData.latitudeColumn = latHeader.text;
               else
                  this.errMsg = `Latitude Column doesn't exist in the headers!`;

               const longCol = this.formData.longitudeColumn?.trim();
               const longHeader = this.formData.previewHeaders.find(f => f.value === longCol.toLowerCase());
               if (longHeader)
                  importData.longitudeColumn = longHeader.text;
               else
                  this.errMsg += `Longitude Column doesn't exist in the headers!`;

               if (this.errMsg) {
                  this.tab = TABS.settings;
                  return;
               }
            } else {
               importData.latitudeColumn = this.formData.latitudeColumn;
               importData.longitudeColumn = this.formData.longitudeColumn;
            }
         }

         if (this.formData.shouldExport && this.formData.strExportDefinition != '{}') {
            if (this.formData.previewHeaders.length) {
               const invalidFields = [];
               this.formData.exportDefinition.fieldDefinitions.forEach(field => {
                  if (field.value) {
                     const fld = this.formData.exportFields.find(f => f.value === field.value);
                     if (!fld)
                        invalidFields.push(field.label);
                  }
               });

               if (invalidFields.length) {
                  this.errMsg = `'${invalidFields.join(', ')}' fields in the Export Definition don't exist in the headers!`;
                  this.tab = TABS.settings;
                  return;
               }
            }

            importData.exportDefinition = this.formData.exportDefinition;
            importData.exportDefinition.debug = this.formData.debug;
         }

         if (this.formData.shouldGeneratePurl) {
            if (this.formData.previewHeaders.length) {
               let result = this.validateFieldsAgainstHeaders(this.formData.purls);
               if (this.errMsg) {
                  this.tab = TABS.purls;
                  return;
               }
               
               importData.purlColumns = [...result.validFields]

               result = this.validateFieldsAgainstHeaders(this.formData.dedups);
               if (this.errMsg) {
                  this.tab = TABS.dedups;
                  return;
               }

               importData.dedupColumns = [...result.validFields]
            } else {
               const purls = [];
               this.formData.purls.forEach(purl => {
                  if (purl.trim())
                     purls.push(purl.trim().toLowerCase());
               });
               if (purls.length)
                  importData.purlColumns = purls;
               else {
                  this.errMsg = `No columns have been specified!`;
                  this.tab = TABS.purls;
                  return;
               }

               const dedups = [];
               this.formData.dedups.forEach(dedup => {
                  if (dedup.trim())
                     dedups.push(dedup.trim().toLowerCase());
               });
               if (dedups.length)
                  importData.dedupColumns = dedups;
               else {
                  this.errMsg = `No columns have been specified!`;
                  this.tab = TABS.dedups;
                  return;
               }
            }
         }

         if (this.formData.emails.length > 0)
            importData.notificationEmails = this.formData.emails;

         if (hasOwn(arg, 'fileName'))
            importData.filePath = arg.fileName;

         if (this.formData.host) {
            importData.sftp = {
               sftpCredentials: {
                  host: this.formData.host,
                  username: this.formData.sftpUsername,
                  password: this.formData.sftpPass
               }
            };
            if (this.formData.port)
               importData.sftp.sftpCredentials.port = +this.formData.port;
            if (this.formData.sftpPath)
               importData.sftp.sftpPath = this.formData.sftpPath;
            // if (this.formData.passphrase) {
            if (this.formData.includePgp) {
               importData.sftp.pgpCredentials = {
                  passPhrase: this.formData.passphrase,
                  privateKey: this.formData.privateKey
               };
            }
         }

         // alert('validations=' + JSON.stringify(this.formData.validations));
         if (this.formData.validations.header && this.formData.validations.header.requiredHeaderColumns.length) {
            importData.validations = this.formData.validations;

            //V240202
            if (importData.exportDefinition?.fieldDefinitions && importData.validations?.data) {
               importData.validations.data.filter(v => v.type === 'date').forEach(v => {
                  const fld = importData.exportDefinition.fieldDefinitions.find(f => f.label === v.column);
                  if (fld) {
                     fld.dateFormat = v.format;
                  }
               });
            }
         }

         this.log('in saveImport(): importData=' + JSON.stringify(importData));

         const result = await this.apiService.createImport(importData);
         this.loadingNewImport = false;

         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.$emit('snackbar-event', `'${importData.name}' was saved.`);
            // this.importsCount++;
            this.importsStats.count++;
            if (importData.sftp)
               this.sftpImportsCount++;
            this.getImports();
            this.cancelImport(); //V241001: false
            // if (!this.exportFields.length)
            //    this.getExportFields();
         }
      },

      validateFieldsAgainstHeaders(fields) {
         const result = {
            validFields: [],
            invalidFields: []
         };

         fields.forEach(element => {
            const field = element.trim();
            if (field) {
               const fld = this.formData.previewHeaders.find(f => f.value === field.toLowerCase());
               if (fld)
                  result.validFields.push(fld.value);
               else
                  result.invalidFields.push(field);
            }
         });

         if (result.invalidFields.length)
            this.errMsg = `'${result.invalidFields.join(', ')}' don't exist in the headers!`;
         else if (!result.validFields.length)
            this.errMsg = `No columns have been specified!`;

         return result;
      },

      async filterChanged2(filter) {
         // alert('in filterChanged2(): filter=' + JSON.stringify(filter) + '\noptions=' + JSON.stringify(this.options));
         this.filter2 = filter;
         // this.importsCount = await this.getImportsCount();
         await this.getImportsStats();
         this.nextAction();
      },

      async nextAction() {
         const currOptions = JSON.stringify(this.options);
         const newOptions = JSON.parse(currOptions);
         newOptions.page = 1;
         if (JSON.stringify(newOptions) === currOptions)
            await this.getImports();
         else
            this.options = newOptions;
      },

      canDeleteOffers(item) {
         return this.$store.getters.user.policies.includes(`contact-offers-delete`)
            && !item.recordsDeleted
            && ['completed', 'rejected'].includes(item.status)
            && ['completed', 'rejected'].includes(item.purlStatus)
            && item.processedRecords;
      },

      async deleteOffersClicked(item) {
         // alert(`in deleteOffers(): item=${JSON.stringify(item)}`);
         this.loadingDialogDeleteOffers = true;
         this.isAnotherDelete = false;
         this.dialogDeleteOffers = true;
         // this.currImport = JSON.parse(JSON.stringify(item));
         this.currImport = item;
         const filter = this.btHelpers.getInitialFilter();
         filter.standard[0].$match.status = 'processing';
         this.log('going to get the in-process deletes...');
         let result = await this.apiService.getDeletesCount(filter);
         if (result.logout)
            this.logout();
         else if (!result.message) {
            if (result.data)
               this.isAnotherDelete = true;
            else {
               this.offersFilter = this.btHelpers.getInitialFilter();
               this.offersFilter.standard[0].$match.importId = {
                  $in: [this.currImport._id]
               };
               //V240215
               // this.offersFilter.standard[0].$match._mfi_studio_sync = {
               //    $exists: false
               // };
               this.offersFilter.standard[0].$match._mfi_studio_sync = true;

               this.log('going to get the number of unconverted offers...');
               //V240215: this.offersCount = await this.getOffersCount();
               this.offersCount = this.currImport.processedRecords - await this.getOffersCount();
               // alert('offersCount='+this.offersCount);
               if (this.offersCount) {
                  this.numberOfOffers = '';
                  setTimeout(() => {
                     this.$refs.numberOfOffers.focus();
                  }, 100);
               }
            }
         }
         this.loadingDialogDeleteOffers = false;
      },

      async getOffersCount() {
         // alert(convertToISODate(new Date()));
         // const date = convertToISODate(new Date(), true);   It gaves error saying not an object
         // alert(`in getOffersCount(): offersFilter=${JSON.stringify(this.offersFilter)}\n\ndate=${date}`);
         let result = await this.apiService.getOffersCount(this.offersFilter, null);
         if (result.logout)
            this.logout();
         return result.message ? 0 : result.data;
      },

      async submitDialogDeleteOffers() {
         // const dateStarted = new Date();
         // const setIntervalDeletes = setInterval(() => {
         //    if (this.isDeleteInProcess) {
         //       const DateEnded = new Date();
         //       const h = differenceInHours(DateEnded, dateStarted);
         //       const min = differenceInMinutes(DateEnded, dateStarted);
         //       const m = min - h * 60;
         //       const s = differenceInSeconds(DateEnded, dateStarted) - h * 3600 - m * 60;
         //       this.deleteElapsedTime = '';
         //       if (h) this.deleteElapsedTime = h + 'h ';
         //       if (min) this.deleteElapsedTime += m + 'm ';
         //       this.deleteElapsedTime += s + 's';
         //    }
         //    else {
         //       clearInterval(setIntervalDeletes);
         //    }
         // }, 1000);

         this.isDeleteInProcess = true;
         this.loadingDialogDeleteOffers = true;
         this.currImport.recordsDeleted = this.offersCount;
         let result = await this.apiService.updateImport(this.currImport);
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.$emit('snackbar-event', `'${this.currImport.name}' was updated.`);
            result = await this.apiService.deleteOffers(this.offersFilter, 0);   //V24-02-16: this.numberOfOffers
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$emit('snackbar-event', `'${result.data}' unconverted records were deleted.`);
               this.cancelDialogDeleteOffers();
            }
         }
         this.loadingDialogDeleteOffers = false;
         this.isDeleteInProcess = false;
      },

      cancelDialogDeleteOffers() {
         this.dialogDeleteOffers = false;
         this.offersCount = -1;
      },

      validationsHaveError(val) {
         this.isValidationsFormValid = !val;
         // alert(`in validationsHaveError(): isValidationsFormValid=${this.isValidationsFormValid}`);
      },

      //V240829
      validateHeadersAgainstDisallowedHeaders() {
         const invalidHeaders = [];

         this.formData.previewHeaders.forEach(h => {
            if (DISALLOWED_HEADERS.includes(h.value))
               invalidHeaders.push(h.text);
         });

         if (invalidHeaders.length)
            this.errMsg = `'${invalidHeaders.join(', ')}' shouldn't exist in the headers!`;
         else
            this.errMsg = null;
      }

      // validationsChanged(val) {
      //    alert(`in validationsChanged(): val=${JSON.stringify(val)}`)
      // }
   },

   created() {
      this.isClipboard = navigator.clipboard;
      this.init();
   },
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
.v-expansion-panel-header, .v-expansion-panel-header--active {
   padding: 0 16px;
   min-height: 40px !important;
}
div >>> .v-expansion-panel-content__wrap {
   padding: 8px 16px 16px !important;
}
.v-text-field >>> .v-label {
    font-size: 20px;
    color: black;
    font-weight: 400 !important;
}
</style>
