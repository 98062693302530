<template>
  <div class="team">
    <h1 class="subheading grey--text">Team</h1>

    <v-container class="my-5">

      <v-layout row wrap>
        <v-flex xs12 sm6 md4 lg3 v-for="person in team" :key="person.name">
          <v-card flat class="text-xs-center ma-3">
            <v-responsive class="pt-4">
              <v-avatar size="100" class="grey lighten-2">
                <img :src="person.avatar">
              </v-avatar>
            </v-responsive>
            <v-card-text>
              <div class="subheading">{{ person.name }}</div>
              <div class="grey--text">{{ person.role }}</div>
            </v-card-text>
            <v-card-actions>
              <v-btn flat color="grey">
                <v-icon small left>message</v-icon>
                <span class="">Message</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>

    </v-container>
    
    <file-upload @uploaded="onFileUpload" :isPublic="isPublic" :serviceType="serviceType"  :folder="email"   />

  </div>
</template>

<script>
import FileUpload from '../components/FileUpload';

export default {
  components: {
    FileUpload
  },
  data() {
    return {
      team: [
        { name: 'Reza Memarian', role: 'Chief Architect', avatar: 'https://media.licdn.com/dms/image/C5603AQGlwyKeq3rDXQ/profile-displayphoto-shrink_200_200/0?e=1573689600&v=beta&t=wo1cpfm6PWflF9U6Z-as-ryM-glE68hl23MKeaoCJ6Q' },
        { name: 'Ali Malekshahi', role: 'Data Architect', avatar: 'https://media.licdn.com/dms/image/C4E03AQHna6fhjEvuMA/profile-displayphoto-shrink_800_800/0?e=1568246400&v=beta&t=RxrbKl0RiMLJH0cbRsn6WKtGfOOm4kFth_Un-2ToCD8' },
        { name: 'Amir Asghari', role: 'Dev Lead', avatar: 'https://media.licdn.com/dms/image/C4E03AQFDC_Rtw1znQg/profile-displayphoto-shrink_800_800/0?e=1568246400&v=beta&t=jtF8kiKyyU8-V-PSQYvoePYRvrnsMgv3qwa9A71Sfaw' },
        { name: 'Behzad Talebpour', role: 'Developer', avatar: 'https://media.licdn.com/dms/image/C4E03AQGBVkWpsoNHBw/profile-displayphoto-shrink_800_800/0?e=1568246400&v=beta&t=BQ-YhmTThL9AAlsgfWs7mS30-KMwf2kxcJrwF4V5rb4' },
        { name: 'Jason Voigt', role: 'Support', avatar: 'https://media.licdn.com/dms/image/C5603AQG6ObHD5iSJnw/profile-displayphoto-shrink_800_800/0?e=1568246400&v=beta&t=LnEk5ItNPU1XNu3w-BoWM3fBHhyEYgzihlDI-bi_B08'}

      ],
      isPublic: true,
      serviceType: 205
    }
  },
  methods: {
    onFileUpload: function(arg){
      console.log(arg);
    }
  },
  computed:{
    email: function(){
        return this.$store.getters.user.email;
    }
  }
}
</script>