<!--TODO:
   - Remove datefnsFormat rule
-->
<!--
   WHAT: A child component for CsImports to define import data and Headerss validations.
   WHO: Behzad Talebpour (btalebpour@mindfireinc.com)
   HISTORY:
   V240202.1: Added the list of valid date/time formats.
   V240103.1: Converted formattedMinDate and formattedMaxDate computed to the function as they didn't get fired on update.
   V231128.1: Added a feature to show the validations object for the power admin.
   V231127.1: Made the format required again and enhanced its validations but didn't force it for now.
   V231117.1: Made sure that requiredHeaderColumns and validation columns exist in the uploaded file.
   V231116.1: Applied some non-functional changes.
   V231115.1: 1st release.
-->
<template>
   <div>
      <v-card class="pb-2">
         <v-card-text class="pb-2">
            <v-tabs dark
               class="elevation-2"
               background-color="grey lighten-2 accent-4"
               slider-color="black"
               v-model="tab"
            >
               <v-tab
                  :class="requiredHeaderColumnsErrMsg ? 'red--text' : 'black--text'"
               >Header Columns</v-tab>
                  <!-- :disabled="!allHeaders.length" -->
               <v-tab
                  :class="dataErrMsg ? 'red--text' : 'black--text'"
                  :disabled="requiredHeaderColumnsErrMsg != ''"
               >Data</v-tab>

               <v-tabs-items v-model="tab">
                  <v-tab-item><!-- Header tab -->
                     <v-form lazy-validation
                        ref="formHeader"
                        v-model="isFormHeaderValid"
                     >
                        <v-card flat tile>
                           <v-card-title v-if="requiredHeaderColumnsErrMsg"
                              class="pb-0"
                           >
                              <div class="text-body-2 error--text">{{requiredHeaderColumnsErrMsg}}</div>
                           </v-card-title>
                           <v-card-text class="pt-2 pb-2">
                              <v-row>
                                 <v-col cols="12">
                                    <bt-autocomplete-with-all chips deletable-chips persistent-hint required return-object small-chips
                                       ref="headers"
                                       hint="* Mandatory columns for all files"
                                       placeholder="select one or more columns"
                                       :counter="allHeaders.length"
                                       :error-message="requiredHeaderColumnsErrMsg"
                                       :items="allHeaders"
                                       v-model="validations.header.requiredHeaderColumns"
                                       @change="emitChanges"
                                    ></bt-autocomplete-with-all>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col cols="12" class="py-0">
                                       <!-- :disabled="!validations.header.requiredHeaderColumns.length" -->
                                    <v-switch
                                       class="mt-3 pr-0"
                                       label="Can have extra columns?"
                                       v-model="validations.header.extraHeaderColumns"
                                       @change="emitChanges"
                                    ></v-switch>
                                 </v-col>
                              </v-row>
                           </v-card-text>
                        </v-card>
                     </v-form>
                  </v-tab-item>

                  <v-tab-item><!-- Data tab -->
                     <v-card flat tile>
                        <v-card-title>
                           <div class="text-body-2 error--text">{{dataErrMsg}}</div>
                           <div class="flex-grow-1"></div>
                           <v-btn small
                              color="error"
                              :disabled="validations.header.requiredHeaderColumns.length === validations.data.length"
                              @click="addDataClicked"
                           >
                              <v-icon left>add</v-icon>
                              Add Validation
                           </v-btn>
                        </v-card-title>
                        <v-card-text>
                           <v-data-table dense show-expand single-expand
                              class="elevation-1 mb-2"
                              item-key="column"
                              :expanded.sync="expandedItem"
                              :headers="dataHeaders"
                              :hide-default-footer="!validations.data || validations.data.length <= 5"
                              :items="validations.data"
                              :items-per-page="5"
                              :loading-text="$t('loading-text')"
                              :no-data-text="$t('no-data-text', { value: 'event data fields' })"
                              :no-results-text="$t('no-results-text', { value: 'event data field' })"
                           >
                              <template v-slot:[`item.required`]="{ item }">
                                 {{ item.required ? 'Yes' : 'No' }}
                              </template>
                              <template v-slot:[`item.min`]="{ item }">
                                 {{ getMinMax(item.min, item.format) }}
                              </template>
                              <template v-slot:[`item.max`]="{ item }">
                                 {{ getMinMax(item.max, item.format) }}
                              </template>
                              <template v-slot:[`item.actions`]="{ item }">
                                 <v-icon small
                                    class="mr-2"
                                    @click="editDataClicked(item)"
                                 >edit</v-icon>
                                 <v-icon small
                                    @click="deleteDataClicked(item)"
                                 >delete</v-icon>
                              </template>
                              <template v-slot:[`expanded-item`]="{ item }">
                                 <td>&nbsp;</td>
                                 <td dense
                                    class="py-2 pl-2" valign="top"
                                    colspan="6"
                                 >
                                    <ul v-if="item.type === 'date'">
                                       <li>
                                          <span class="expanded-header">Date Format: </span>
                                          <span class="expanded-content">{{item.format ? item.format : 'None'}}</span>
                                       </li>
                                    </ul>
                                    <ul v-else>
                                       <li>
                                          <span class="expanded-header">Enums: </span>
                                          <span class="expanded-content">{{item.enums ? item.enums.join(', ') : 'None'}}</span>
                                       </li>
                                       <li>
                                          <span class="expanded-header">Regex: </span>
                                          <span class="expanded-content">{{item.regex ? item.regex : 'None'}}</span>
                                       </li>
                                    </ul>
                                 </td>
                              </template>
                           </v-data-table>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
               </v-tabs-items>
            </v-tabs>
         </v-card-text>

         <v-card-action v-if="isPowerAdmin">
            <div class="pt-2">
            <v-btn text small
               class="pl-4"
               color="blue darken-1"
               @click="showFilter = !showFilter"
            >{{showFilter ? 'Hide' : 'Show'}} Validations</v-btn>
            <v-btn v-if="isNavigator && showFilter"
               x-small
               color="primary"
               @click="copyToClipboard"
            >Copy to Clipboard as Text
               <v-icon dark x-small class="pl-1">content_copy</v-icon>
            </v-btn>
            <span class="caption pl-3" v-if="showFilter">{{copyToClipboardHint}}</span>
            </div>
            <pre v-if="showFilter" class="pl-5">{{validations}}</pre>

         </v-card-action>
      </v-card>

      <v-dialog no-click-animation persistent scrollable
         max-width="1024px"
         v-model="dialogData"
      >
         <v-card flat>
            <v-card-title class="title grey--text darken-4 font-weight-bold">{{currDataIndex > -1 ? 'Edit' : 'Add'}} Validation</v-card-title>
            <v-card-text class="pt-2">
               <v-form lazy-validation
                  ref="formData"
                  v-model="isFormDataValid"
               >
                  <v-row>
                     <v-col
                        xs="12" sm="12" md="6"
                        class="pt-0 pb-3"
                     >
                        <v-autocomplete autofocus dense persistent-hint hide-selected required
                           ref="column"
                           autocomplete="off"
                           :hint="`${currData.column ? '* Column Name' : ''}`"
                           :placeholder="`* select one of ${columnNames.length} columns`"
                           :items="columnNames"
                           :rules="[rules.required]"
                           v-model="currData.column"
                           @change="columnNameChanged"
                        ></v-autocomplete>
                     </v-col>
                     <v-col v-if="currData.column"
                        xs="12" sm="12" md="3"
                        class="py-0"
                     >
                        <v-select dense persistent-hint hide-selected required
                           ref="type"
                           placeholder="* select a type"
                           :hint="`${currData.type ? '* Type' : ''}`"
                           :items="typeItems"
                           :rules="[rules.required]"
                           v-model="currData.type"
                           @change="typeChanged"
                        ></v-select>
                     </v-col>
                     <v-col v-if="currData.column"
                        xs="12" sm="12" md="3"
                        class="py-0 pr-0"
                     >
                        <v-switch
                           class="mt-3 pr-0"
                           label="Is Required?"
                           v-model="currData.required"
                        ></v-switch>
                     </v-col>
                  </v-row>
                  <div v-if="currData.type === 'date'">
                     <v-row class="pt-5">
                        <v-col xs="12" sm="12" md="6" class="pt-0 pb-0">
                              <!-- :clearable="!currData.min && !currData.max" -->
                              <!-- hint="* Format: any combination of d (1 or 2), M (1 to 4), y (2 or 4) separated by -, / or ." -->
                              <!-- :rules="[rules.required, rules.format, , rules.datefnsFormat]" -->
                           <v-text-field dense persistent-hint
                              ref="format"
                              autocomplete="off"
                              placeholder="specify a date format (ex: MM/DD/YYYY)"
                              hint="* Format: any valid date and time format from the below tables"
                              :rules="[rules.required]"
                              v-model="currData.format"
                           ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0">
                           <v-menu offset-y
                              transition="scale-transition"
                              max-width="290px"
                              min-width="290px"
                              :close-on-content-click="false"
                              :disabled="!currData.format"
                              v-model="minMenu"
                           >
                              <template v-slot:activator="{ on }">
                                 <v-text-field dense persistent-hint readonly
                                    class="pt-0 mt-1"
                                    hint="Minimum Date"
                                    append-icon="event"
                                    :disabled="!currData.format"
                                    v-on="on"
                                    v-model="formattedMinDate"
                                 ></v-text-field>
                              </template>
                              <v-date-picker no-title
                                 :disabled="!currData.format"
                                 v-model="currData.minDate"
                                 @input="minMenu = false"
                                 @change="minDateChanged"
                              ></v-date-picker>
                           </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0">
                           <v-menu offset-y
                              transition="scale-transition"
                              max-width="290px"
                              min-width="290px"
                              :close-on-content-click="false"
                              :disabled="!currData.format"
                              v-model="maxMenu"
                           >
                              <template v-slot:activator="{ on }">
                                 <v-text-field dense persistent-hint readonly
                                    class="pt-0 mt-1"
                                    hint="Maximum Date"
                                    append-icon="event"
                                    :disabled="!currData.format"
                                    v-on="on"
                                    v-model="formattedMaxDate"
                                 ></v-text-field>
                              </template>
                              <v-date-picker no-title
                                 :disabled="!currData.format"
                                 :min="currData.minDate"
                                 v-model="currData.maxDate"
                                 @input="maxMenu = false"
                                 @change="maxDateChanged"
                              ></v-date-picker>
                           </v-menu>
                        </v-col>
                     </v-row>
                     <v-row class="pt-5 pb-0 font-italic">
                        <v-col class="pt-5 pb-0 font-italic">
                           <!-- <div>In order to see all the date and time formats and their usage with examples, please refer to the following document:</div>
                           <div><a href="https://help.scribesoft.com/scribe/en/sol/general/datetime.htm#:~:text=dd%2FMM%2Fyyyy%20" target="_blank">DateTime Format</a></div> -->
                           <div>Please refer to the following tables to see all the valid date and time formats and their usage with examples:</div>
                        </v-col>
                     </v-row>
                     <v-row><!-- V240202.1 -->
                        <v-col cols="6">
                           <table>
                              <thead>
                                 <tr><th align ="left">Date Input</th><th align ="left">Example</th><th align ="left">Description</th></tr>
                              </thead>
                              <tbody>
                                 <tr><td style="width:20%">YY</td><td style="width:35%">01</td><td style="width:45%">Two-digit year</td></tr>
                                 <tr><td>YYYY</td><td>2001</td><td>Four-digit year</td></tr>
                                 <tr><td>M</td><td>1-12</td><td>Month, beginning at 1</td></tr>
                                 <tr><td>MM</td><td>01-12</td><td>Month, 2-digits</td></tr>
                                 <tr><td>MMM</td><td>Jan-Dec</td><td>The abbreviated month name</td></tr>
                                 <tr><td>MMMM</td><td>January-December</td><td>The full month name</td></tr>
                                 <tr><td>D</td><td>1-31</td><td>Day of month</td></tr>
                                 <tr><td>DD</td><td>01-31</td><td>Day of month, 2-digits</td></tr>
                              </tbody>
                           </table>
                        </v-col>
                        <v-col cols="6">
                           <table>
                              <thead>
                                 <tr><th align ="left">Time Input</th><th align ="left">Example</th><th align ="left">Description</th></tr>
                              </thead>
                              <tbody>
                                 <tr><td style="width:25%">H</td><td style="width:25%">0-23</td><td style="width:50%">Hours</td></tr>
                                 <tr><td>HH</td><td>00-23</td><td>Hours, 2-digits</td></tr>
                                 <tr><td>h</td><td>1-12</td><td>Hours, 12-hour clock</td></tr>
                                 <tr><td>hh</td><td>01-12</td><td>Hours, 12-hour clock, 2-digits</td></tr>
                                 <tr><td>m</td><td>0-59</td><td>Minutes</td></tr>
                                 <tr><td>mm</td><td>00-59</td><td>Minutes, 2-digits</td></tr>
                                 <tr><td>s</td><td>0-59</td><td>Seconds</td></tr>
                                 <tr><td>ss</td><td>00-59</td><td>Seconds, 2-digits</td></tr>
                                 <tr><td>A</td><td>AM PM</td><td>Post or ante meridiem, upper-case</td></tr>
                                 <tr><td>a</td><td>am pm</td><td>Post or ante meridiem, lower-case</td></tr>
                              </tbody>
                           </table>
                        </v-col>
                     </v-row>
                  </div>
                  <div v-else-if="currData.type">
                     <v-row>
                        <v-col xs="12" sm="12" :md="minMaxColSize" class="py-0">
                              <!-- :type="currData.type==='date'?'text':'number'" -->
                           <v-text-field dense persistent-hint
                              ref="min"
                              autocomplete="off"
                              :disabled="currData.enums != ''"
                              :hint="minHint"
                              :rules="minRules"
                              v-model="currData.min"
                           ></v-text-field>
                        </v-col>
                        <v-col xs="12" sm="12" :md="minMaxColSize" class="py-0">
                              <!-- :type="currData.type==='date'?'text':'number'" -->
                           <v-text-field dense persistent-hint
                              ref="max"
                              autocomplete="off"
                              :disabled="currData.enums != ''"
                              :hint="maxHint"
                              :rules="maxRules"
                              v-model="currData.max"
                           ></v-text-field>
                        </v-col>
                        <v-col v-if="currData.type === 'string'"
                           xs="12" sm="12" md="6" class="py-0"
                        >
                           <v-text-field clearable dense persistent-hint
                              ref="regex"
                              autocomplete="off"
                              hint="Regex Validation"
                              :disabled="currData.enums != ''"
                              v-model="currData.regex"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="12" class="pt-3 pb-0">
                           <v-text-field clearable dense persistent-hint
                              ref="enum"
                              autocomplete="off"
                              :disabled="currData.min != '' || currData.max != ''"
                              hint="Enum: a comma-separated list of values"
                              :rules="[rules.enums]"
                              v-model="currData.enums"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                  </div>
               </v-form>
            </v-card-text>

            <v-card-actions>
               <div class="flex-grow-1"></div>
               <v-btn text small
                  class="pr-0"
                  color="blue darken-1"
                  @click="cancelData"
               >Cancel</v-btn>
               <v-btn text small
                  color="blue darken-1"
                  :disabled="!isFormDataValid"
                  @click="addUpdateData"
               >{{currDataIndex > -1 ? 'Update' : 'Add'}}
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

      <!-- <div class="pt-4 pr-4 pl-4" v-if="showFilter">
      <v-card v-if="showFilter">
         <v-card-text class="pt-2">
            <pre>{{validations}}</pre>
         </v-card-text>
      </v-card>
      </div> -->
   </div>
</template>

<script>
import BtAutocompleteWithAll from './BtAutocompleteWithAll.vue';
import { convertToISODate, hasOwn, isNumber } from '../mixins/bt-mixin.js';
// import { parse } from "date-fns";

class FormData {
   constructor(initData) {
      // {
      //   "column": "column name",
      //   "type": "string | number | date",
      //   "required": true | false,
      //   "min": 0,
      //   "max": 0,
      //   "enums": [],
      //   "regex": "",
      //   "format": "any combinations of dd, mm, yy or yyyy"
      // }
      this.column = initData.column || '';
      this.type = initData.type || '';
      this.required = initData.required || false;
      this.regex = initData.regex || '';
      this.min = hasOwn(initData, 'min') ? initData.min : '';
      this.max = hasOwn(initData, 'max') ? initData.max : '';
      this.enums = initData.enums ?  initData.enums.join(', ') : '';
      this.format = initData.format || '';
      this.menu = false;
   }
}

const NAME = "BtImportValidations";
const ISO_FORMAT = "yyyy-MM-dd";

function formatDate(isoDate, format) {
   //TODO:
   console.warn(`in formatDate func: isoDate=${isoDate}\nformat=${format}`);
   if (!isoDate || !format) return null;

   const [year4, month, day] = isoDate.split('-');
   // // const dateFormat = format.split(' ')[0];
   const dateFormat = ISO_FORMAT;
   return dateFormat.replace('yyyy', year4)
      // // .replace('yy', year4.substring(2))
      .replace('MM', month)
      // // .replace('M', Number(month))
      .replace('dd', day);
      // // .replace('d', Number(day));
      // .replace('MMMM', month)
      // .replace('MMM', month)
}

export default {
   name: NAME,

   components: {
      BtAutocompleteWithAll
   },

   props: {
      value: {
         type: Object,
         default: () => {}
      },

      debug: {
         type: Boolean,
         default: false
      },

      fileHeaders: {
         type: Array,
         default: () => []
      },

      isPowerAdmin: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         tab: null,
         formData: new FormData({}),
         settings: {},
         rules: {
            required: value => !!value || "Value is required!",
            regex: value => {
               try {
                  new RegExp(value, 'i');
                  return true;
               } catch (error) {
                  return 'Value is invalid!';
               }
            },
            greaterThan: value => {
               return +value == 0 || value > this.currData.min || `Maximum should be greater than Minimum!`;
            },
            enums: value => {
               let isInvalid = false;
               let duplicate = '';
               if (value.trim()) {
                  const enums = [];
                  value.split(',').forEach(val => {
                     const v = val.trim();
                     if (!v) {
                        isInvalid = true;
                        return;
                     } else if (enums.includes(v)) {
                        duplicate = v;
                        return;
                     } else enums.push(v);
                  });
               }
               if (isInvalid) return 'Value is invalid!';
               else if (duplicate) return `'${duplicate}' is duplicate!`;
               else return true;
            },
            number: value => isNumber(value) || 'Value is invalid!',
            format: value => this.isFormatValid(value) || 'Invalid value!',
            // format2: value => {
            //    let result;
            //    if (value.length < 6)// && value.length > xx
            //       result = false;
            //    else if (value === 'yyyyMMddTHHmmzzz')
            //       result = true;
            //    else {
            //       const formatParts = value.split(' ');
            //       if (formatParts <= 3) {
            //          const date = formatParts[0];
            //          const dateParts = date.includes('/') ? date.split('/') : (date.includes('-') ? date.split('-') : date.split('.'));// /\/|-/
            //          if (dateParts.length === 3) {
            //             const dmy = formatParts.join('');
            //             result = dmy.includes('dd') && dmy.includes('MM') && (dmy.includes('yyyy') || dmy.includes('yy'));
            //          } 
            //          // else
            //          //    result = false;

            //          if (result && formatParts.length > 1) {
            //             const timeParts = formatParts[1].split(':');
            //             if (timeParts.length === 2 || timeParts.length === 3) {
            //                let timezone;
            //                const h = timeParts[0];//.toLowerCase();
            //                result = h === 'h' || h === 'hh';
            //                if (result) {
            //                   timezone = false;
            //                }
            //                const m = timeParts[1];
            //                result =  (h === 'h' || h === 'hh') && (m === 'm' || m === 'mm');
            //                if (result && timeParts.length === 3) {
            //                   const s = timeParts[1];
            //                   result = s === 's' || s === 'ss';
            //                }
            //             }
            //          }
            //       }
            //    }
            //    return result || 'Invalid value!';
            // },
            // format_old: value => {
            //    let result;
            //    if (!value)
            //       result = true;
            //    else if (value.length != 8 && value.length != 10)
            //       result = false;
            //    else {
            //       const formatParts = value.includes('/') ? value.split('/') : value.split('-');// /\/|-/
            //       if (formatParts.length === 3) {
            //          const dmy = formatParts.join('');
            //          result = dmy.includes('dd') && dmy.includes('MM') && (dmy.includes('yyyy') || dmy.includes('yy'));
            //       } else
            //          result = false;
            //    }
            //    return result || 'Invalid value!';
            // },
            // datefnsFormat: value => {
            //    try {
            //       // return format(new Date(), value);
            //       return parse(new Date().toString(), value, new Date(), { useAdditionalDayOfYearTokens: true });
            //       // return true; 
            //    } catch {
            //       return 'Invalid format!';
            //    }
            // }
         },
         loading: false,
         options: {},
         search: '',
         expandedItem: [],
         dataHeaders: [
            { text: 'Column', value: 'column', align: 'left', sortable: true },
            { text: 'Type', value: 'type', align: 'left', sortable: true },
            { text: 'Required?', value: 'required', align: 'left', sortable: true },
            { text: 'Min', value: 'min', align: 'left', sortable: true },
            { text: 'Max', value: 'max', align: 'left', sortable: true },
            { text: 'Actions', value: 'actions', align: 'right', sortable: false }
         ],
         dataDetails: {},
         dialogData: false,
         isFormDataValid: true,
         currDataIndex: -1,
         currData: {},
         typeItems: [
            { text: 'String', value: 'string' },
            { text: 'Number', value: 'number' },
            { text: 'Date', value: 'date' }
         ],
         isFormHeaderValid: true,
         columnNames: [],
         allHeaders: [],
         isDataTabDisabled: true,
         minRules: [],
         maxRules: [],
         minMenu: false,
         maxMenu: false,
         minMaxColSize: 3,
         showFilter: false,
         copyToClipboardHint: '',
         isNavigator: false,
         formattedMinDate: '',
         formattedMaxDate: '',
         dateFormatHeaders: [
            { text: 'Input', value: 'input', align: 'left', sortable: true },
            { text: 'Example', value: 'example', align: 'left', sortable: true },
            { text: 'Description', value: 'desc', align: 'left', sortable: true }
         ],
         dateFormatItems: [
            { groupBy: 'Year', input: 'YY', example: '01', desc: 'Two-digit year'},
            { groupBy: 'Month', input: 'M', example: '1-12', desc: 'Month, beginning at 1'}
         ]
      }
   },

   computed: {
      requiredHeaderColumnsErrMsg() {
         let errMsg = '';
         if (this.validations.header.requiredHeaderColumns.length && this.fileHeaders.length) {
            const missingHeaderColumns = this.validations.header.requiredHeaderColumns.filter(h => !this.fileHeaders.includes(h));
            if (missingHeaderColumns.length)
               errMsg = `The folowing columns do not exist in the uploaded file: ${missingHeaderColumns.join(', ')}`;
         }
         // this.emitErrors(errMsg);
         return errMsg;
      },

      dataErrMsg() {
         let errMsg = '';
         if (this.validations.header.requiredHeaderColumns.length && this.validations.data.length) {
            const extraColumns = this.validations.data.filter(d => !this.validations.header.requiredHeaderColumns.includes(d.column));
            if (extraColumns.length)
               errMsg = `The folowing columns do not exist in the mandatory columns: ${extraColumns.map(c => c.column).join(', ')}`;
         }
         return errMsg;
      },

      minHint() {
         let hint = 'Minimum ';
         if (this.currData.type === 'string')
            hint += 'Length';
         else if (this.currData.type === 'number')
            hint += 'Value';
         return hint;
      },

      maxHint() {
         return this.minHint.replace('Min', 'Max');
      },

      /* The following variabled did not fire on updating
      // formattedMinDate () {
      //    alert('formattedMinDate='+formatDate(this.currData.minDate, this.currData.format));
      //    return formatDate(this.currData.minDate, this.currData.format);
      // },

      // formattedMaxDate () {
      //    return formatDate(this.currData.maxDate, this.currData.format);
      // }
      */
   },

   watch: {
      fileHeaders: {
         immediate: true,
         handler() {
            // alert('in watch.fileHeaders(): val=' + val);
            this.init();
         }
      },

      requiredHeaderColumnsErrMsg: {
         immediate: true,
         handler() {
            this.emitErrors();
         }
      },
      dataErrMsg: {
         immediate: true,
         handler() {
            this.emitErrors();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V240202.1 says => ${msg}`);
      },

      async init() {
         this.validations = this.value || {};
         // alert(`in init(): this.value=${JSON.stringify(this.value)}`);
         if (!hasOwn(this.validations, 'header')) {
            this.validations.header = {
               requiredHeaderColumns: [],
               extraHeaderColumns: false
            };
         }

         if (!hasOwn(this.validations, 'data')) {
            this.validations.data = [];
         }

         // alert(`init(): fileheader=${JSON.stringify(this.fileHeaders)}`);

         const mergedHeaders = this.validations.header.requiredHeaderColumns.concat(this.fileHeaders);
         const uniqueSet = new Set(mergedHeaders);
         this.allHeaders = Array.from(uniqueSet);

         // alert(`init() finished: validations=${JSON.stringify(this.validations)})`);
         this.tab = 0;

         // if (this.debug)
            // this.testFormatValidation();
      },

      getMinMax(val, format) {
         // console.warn(`in getMinMax(): item=${JSON.stringify(item)}\nminOrMax=${minOrMax}`);
         if (val) {
            if (format) {
               const localDateString = val.toLocaleString('en-US');
               const localDate = new Date(localDateString);
               const iso = localDate.getFullYear() + '-' +
                  (localDate.getMonth() + 1).toString().padStart(2, "0") + '-' +
                  localDate.getUTCDate().toString().padStart(2, "0");
               return formatDate(iso, format);
            } else
               return val;
         } else
            return '';
      },

      addDataClicked() {
         this.currDataIndex = -1;
         this.currData = new FormData({});
         this.columnNames = [];
         this.validations.header.requiredHeaderColumns.forEach(h => {
            if (!this.validations.data.find(d => d.column === h))
               this.columnNames.push(h);
         });
         this.dialogData = true;
         this.$nextTick(() => {
            this.$refs.formData?.validate();
         });
      },

      editDataClicked(item) {
         // alert('in editDataClicked(): item=' + JSON.stringify(item));
         this.currDataIndex = this.validations.data.findIndex(d => d.column === item.column);
         this.currData = new FormData(item);

         if (this.currData.type === 'date') {
            this.currData.minDate = this.getMinMax(this.currData.min, ISO_FORMAT);
            this.formattedMinDate = formatDate(this.currData.minDate, this.currData.format);
            this.currData.maxDate = this.getMinMax(this.currData.max, ISO_FORMAT)
            this.formattedMaxDate = formatDate(this.currData.maxDate, this.currData.format);
         }

         if (this.expandedItem.length && this.expandedItem[0].column === item.column)
            this.expandedItem = [];

         this.columnNames = [item.column];
         this.dialogData = true;
      },

      deleteDataClicked(item) {
         // alert('in deleteDataClicked(): item=' + JSON.stringify(item));
         if (confirm(`Are you sure to delete '${item.column}' validation?`)) {
            const ind = this.validations.data.findIndex(d => d.column === item.column);
            this.validations.data.splice(ind, 1);
            this.emitChanges();
         }
      },

      columnNameChanged() {
         this.$nextTick(() => {
            this.$refs.formData?.validate();
         });
      },

      typeChanged(val) {
         this.currData.format = '';
         this.currData.min = '';
         this.formattedMinDate = '';
         this.currData.max = '';
         this.formattedMaxDate = '';

         let focus;
         if (val === 'date') {
            this.minRules = [];
            this.maxRules = [];
            focus = 'format';
         } else {
            this.minMaxColSize = val === 'string' ? 3 : 6;
            this.minRules = [this.rules.number];
            this.maxRules = [this.rules.number, this.rules.greaterThan];
            focus = 'min';
         }

         this.$nextTick(() => {
            // this.$refs.formData?.validate();
            if (this.$refs.formData) {
               this.$refs.formData.validate();
               this.$refs[focus].focus();
            }
         });
      },

      isFormatValid(value) {
         let result;
         if (value === 'yyyyMMddTHHmmzzz')
            result = true;
         else if (value.length >= 6) {
            const formatParts = value.split(' ');
            if (formatParts.length <= 3) {
               result = this.isDateFormatValid(formatParts[0]);
               if (result && formatParts.length > 1) {
                  const tz = formatParts.length === 3 ? formatParts[2] : null;
                  result = this.isTimeFormatValid(formatParts[1], tz);
               }
            }
         }

         return result;
      },

      isDateFormatValid(date) {
         const dateParts = date.includes('/') ? date.split('/') : (date.includes('-') ? date.split('-') : date.split('.'));
         if (dateParts.length === 3) {
            const dmy = {};
            dateParts.forEach(part => {
               if (part === 'd' || part === 'dd')
                  dmy.d = true;
               else if (part === 'M' || part === 'MM' || part === 'MMM' || part === 'MMMM')
                  dmy.m = true;
               else if (part === 'yy' || part === 'yyyy')
                  dmy.y = true;
            });

            return dmy.d && dmy.m && dmy.y;
         } else
            return false;
      },

      isTimeFormatValid(time, tz) {
         const timeParts = time.split(':');
         if (timeParts.length > 1) {
            const h12 = timeParts[0] === 'h' || timeParts[0] === 'hh';
            const h24 = timeParts[0] === 'H' || timeParts[0] === 'HH';
            if (h12 || h24) {
               if (h12) {
                  if (tz != 't' && tz != 'tt')
                     return false;
               } else if (tz != null)
                  return false;
            } else
               return false;

            if (timeParts[1] != 'm' && timeParts[1] != 'mm')
               return false;
         }

         if (timeParts.length === 3)
            return timeParts[2] === 's' || timeParts[2] === 'ss';
         else
            return false;
      },

      dateChanged(val) {
         // alert(`in dateChanged(): val=${val}`);
         const dateParts = val.split('-');
         const localDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));
         return convertToISODate(localDate, true);
      },

      minDateChanged(val) {
         this.currData.min = this.dateChanged(val);
         this.formattedMinDate = formatDate(this.currData.minDate, this.currData.format);
      },

      maxDateChanged(val) {
         this.currData.max = this.dateChanged(val);
         this.formattedMaxDate = formatDate(this.currData.maxDate, this.currData.format);
      },

      addUpdateData() {
         if (this.$refs.formData.validate()) {
            // alert('in addUpdateData(): currData=' + JSON.stringify(this.currData));
            const data = {
               column: this.currData.column,
               type: this.currData.type
            };

            if (this.currData.required)
               data.required = true;
            if (this.currData.regex)
               data.regex = this.currData.regex;
            if (this.currData.enums) {
               if (data.type === 'string')
                  data.enums = this.currData.enums.split(',').map(e => e.trim());
               else
                  data.enums = this.currData.enums.split(',').map(e => Number(e.trim()));
            }
            if (this.currData.format)
               data.format = this.currData.format;
            if (this.currData.min != '')
               data.min = this.currData.min;
            if (this.currData.max != '')
               data.max = this.currData.max;

            // alert('data=' + JSON.stringify(data) + '\n' + 'currDataIndex=' + this.currDataIndex);

            if (this.currDataIndex === -1)
                  this.validations.data = [...this.validations.data, data];
            else
               this.validations.data.splice(this.currDataIndex, 1, JSON.parse(JSON.stringify(data)));

            // alert('validations.data=' + JSON.stringify(this.validations.data));

            this.$emit('snackbar-event', `'${data.column}' Field was ${this.currEventDataIndex > -1 ? 'updated' : 'added'}.`);
            this.emitChanges();
            this.cancelData();
         }
      },

      cancelData() {
         this.dialogData = false;
      },

      emitErrors() {
         const hasErr = this.requiredHeaderColumnsErrMsg || this.dataErrMsg ? true : false;
         this.$emit('has-error', hasErr);
      },

      emitChanges() {
         if (this.isFormHeaderValid && this.isFormDataValid && this.validations.header.requiredHeaderColumns.length) {// && !this.hasError
            this.$emit('input', this.validations);
            this.$emit('change', this.validations);
            this.copyToClipboardHint = '';
         }
      },

      async copyToClipboard() {
         if (this.isNavigator) {
            await navigator.clipboard.writeText(JSON.stringify(this.validations));
            this.copyToClipboardHint = 'JSON content was copied to clipboard at ' + new Date().toString().split(' ')[4];
         }
      },
      
      testFormatValidation() {
         //valid formats
         let format = [
            'yyyy-M-d',
            'yyyy/MM/d',
            'yyyy-MMM-dd',
            'yyyy.MMMM.dd',
            'M/d/yyyy',
            'MMM/d/yyyy',
            'd/M/yyyy',
            'dd.MM.yy',
            'dd/MM/yyyy',
            'M/d/yyyy h:mm:ss t',
            'M/d/yyyy hh:mm:ss tt',
            'dd.MM.yyyy H:mm:ss',
            'dd/MM/yyyy HH:mm:ss',
            'yyyyMMddTHHmmzzz'
         ];
         format.forEach(f => {
            const result = `VALID: ${f} => ${this.isFormatValid(f)}`;
            console.log(result);
            // alert(result);
         });

         //invalid formats
         let format2 = [
            'yyyy-Md',
            'yyyy/MM//d',
            'yyy-MMM-dd',
            'yyyy_MMMM.dd',
            'M/d/yyyyy',
            'MMMMM/d/yyyy',
            'ddd/M/yyyy',
            'ddMMyy',
            'MM/yyyy',
            'M/d/yyyy h:mm:ss',  //should have t or tt
            'M/d/yyyy hh:mm:ss z',  //should have t or tt
            'M/d/yyyy H:mm:ss t',   //shouldn't have t or tt
            'dd.MM.yyyy H-mm-ss',   //shouldn't have -
            'dd/MM/yyyyHH:mm:ss',   //should have space
            'yyyyMMddTHHmmzz',   //should have 3 z
            'yyyyMMddTHHMMzz' //shouldn't have MM in time
         ];
         format2.forEach(f => {
            const result = `INVALID: ${f} => ${this.isFormatValid(f)}`;
            console.log(result);
            // alert(result);
         });
      }
   },

   created() {
      this.isNavigator = navigator.clipboard;
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>