<template>
<!-- TODO:
   - Showing items on the 1st load has delay and it shows 'no data' then records are shown.
-->
<!-- HISTORY:
   05/02/22(B0.3): Implemented logout feature.
   03/08/22(B0.2): Added status to the table and filter.
   03/07/22(B0.1): 1st release.
-->
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">delete</v-icon>
            <span>Deletes</span>
         </h1>
      </v-card-title>

      <v-card-text class="py-0">
         <bt-filter-wrapper-with-panel
            :closed-on-load="true"
            :fields="searchFields"
            :included-tabs="['standard']"
            :is-admin="jwt.pa"
            :max="searchFields.length"
            :preselected-fields="[]"
            :should-init="shouldInitFilterDefiner"
            :std-field-values="{
               status: ['processing', 'completed']
            }"
            v-model="filter"
            @filter-change="filterChanged"
         ></bt-filter-wrapper-with-panel>
      </v-card-text>
      <v-card-text>
         <v-data-table dense fixed-header
            class="elevation-1 mb-2"
            :footer-props="{
               itemsPerPageOptions: [5, 10, 20],
               showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="itemsCount <= 5"
            item-key="_id"
            :items="tableItems"
            :items-per-page="5"
            :loading="loadingItems"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'items' })"
            :no-results-text="$t('no-results-text', { value: 'items' })"
            :options.sync="options"
            :search="search"
            :server-items-length="itemsCount"
         >
            <template v-slot:[`item.creationDate`]="{ item }">
               {{ formatDate(item.creationDate, true) }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
               <v-icon :color="getIcon(item.status).color">{{getIcon(item.status).icon}}</v-icon><span :text--color="getIcon(item.status).color"> {{ item.status }}</span>
            </template>
         </v-data-table>
      </v-card-text>
   </v-card>

</v-container>
</template>

<script>
import { APIService } from '../services/cs-api-service.js';
import { format, parseISO } from "date-fns";
import BtFilterWrapperWithPanel from './BtFilterWrapperWithPanel.vue';

const NAME = "CsDeletes";

export default {
   name: NAME,

   components: { BtFilterWrapperWithPanel },

   props: {
      debug: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         apiService: null,
         filter: {
            standard: [{ $match: {} }]
         },
         headers: [
            { text: 'ID', value: '_id', align: 'center', sortable: false },
            { text: 'Filter', value: 'filter', align: 'center', sortable: false },
            { text: 'Count', value: 'deletedRecords', align: 'center', sortable: true },
            { text: 'Creator', value: 'creator', align: 'center', sortable: true },
            { text: 'Date', value: 'creationDate', align: 'center', sortable: true },
            { text: 'Status', value: 'status', align: 'center', sortable: true }
         ],
         tableItems: [],
         itemsCount: 0,
         jwt: {},
         loadingItems: false,
         options: {},
         search: '',
         searchFields: [
            { text: 'Filter', value: 'filter', type: 'string', isIndexed: true },
            { text: 'Count', value: 'deletedRecords', type: 'number', isIndexed: true },
            { text: 'Creator', value: 'creator', type: 'string', isIndexed: true },
            { text: 'Date', value: 'creationDate', type: 'date', isIndexed: true },
            { text: 'Status', value: 'status', type: 'string', isIndexed: true }
         ],
         shouldInitFilterDefiner: false
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      }
   },

   watch: {
      token: {
         immediate: true,
         handler() {
            this.init();
            this.nextAction();
         }
      },

      options: {
         handler (val) {
            // alert('in watch: options=' + JSON.stringify(val));
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
               this.filter.sort = sort;
            }
            this.getItems();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} B0.3 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         // alert('in init(): token=' + this.token);
         this.tableItems = [];

         if (this.token) {
            const JWT = JSON.parse(atob(this.token.split('.')[1]));
            this.jwt = {
               aid: JWT.aid,
               paid: JWT.paid,
               pa: JWT.pa,
               pu: JWT.pu
            };

            this.log('in init(): jwt=' + JSON.stringify(this.jwt));
            this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
            await this.getItemsCount(this.filter);
            this.shouldInitFilterDefiner = true;
         } else
            this.jwt = {};
      },

      async getItemsCount() {
         this.loadingItems = true;
         let result = await this.apiService.getDeletesCount(this.filter);
         if (result.logout)
            this.logout();
         this.itemsCount = result.message ? 0 : result.data;
         this.loadingItems = false;
      },

      /*sample response: 
      {
         _id: "62211c02a664d50dedba432c", 
         filter: "", 
         deletedRecords: 0, 
         creator: "rmemarian@mindfiremail.info", 
         creationDate: "2022-03-03T19:50:26.997+00:00",
         databaseName: "19375"
      }
      */
      async getItems() {
         this.loadingItems = true;
         const result = await this.apiService.getDeletes(this.filter, this.options.page, this.options.itemsPerPage);
         this.loadingItems = false;
         if (result.logout)
            this.logout();
         else if (!result.message) {
            // this.items = [...result.data];
            this.tableItems = result.data;
         }
         this.log(`in getItems(): tableItems=${JSON.stringify(this.tableItems)}`);
      },

      formatDate(date, withTime) {
         if (date) {
            // alert('in formatDate(): date=' + date + '\nwithTime=' + withTime + '\nparseISO=' + parseISO(date));
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            return withTime ? formatteddate : formatteddate.split(' ')[0];
         }
      },

      getIcon(status) {
         switch (status) {
            case 'processing':
               return { color: 'blue', icon: 'hourglass_empty' };
            case 'completed':
               return { color: 'green', icon: 'done' };
            default:
               return { color: '', icon: '' };
         }
      },

      async filterChanged(filter) {
         // alert('in filterChanged(): filter=' + JSON.stringify(filter) + '\noptions=' + JSON.stringify(this.options));
         this.filter = filter;
         await this.getItemsCount();
         this.nextAction();
      },

      async nextAction() {
         const currOptions = JSON.stringify(this.options);
         const newOptions = JSON.parse(currOptions);
         newOptions.page = 1;
         if (JSON.stringify(newOptions) === currOptions)
            await this.getItems();
         else
            this.options = newOptions;
      }
   },

   created() { }
}
</script>
