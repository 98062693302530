<!-- HISTORY:
   V230906.1: Made list table unsortable + Fixed the empty names issue and typo in "Run Sequence".
   V230905.1: Added Totals tab.
   V230901.3: In getListCount(), reset pagination.
   V230901.2: Removed accounts dropdown + Passed path to APIService.
   V230901.1: 1st release (copied file from AsImports.vue V230831.1).
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">file_upload</v-icon>
            <span>Schedules</span>
         </h1>
      </v-card-title>

      <v-card-text class="ml-2 px-5 pb-0">
         <v-form lazy-validation
            ref="mainForm"
            v-model="isMainFormValid"
         >
            <v-row>
               <v-col xs="12" sm="12" md="3" class="py-2">
                  <v-select dense hide-selected persistent-hint
                     hint="* Schedule Type"
                     prepend-inner-icon="schedule"
                     :items="typeItems"
                     v-model="type"
                     @change="typeChanged"
                  ></v-select>
               </v-col>
               <v-col xs="12" sm="12" md="2" class="py-2">
                  <v-select dense hide-selected persistent-hint
                     hint="* Status"
                     :items="statusItems"
                     :prepend-inner-icon="statusIcon"
                     v-model="status"
                     @change="statusChanged"
                  >
                  </v-select>
               </v-col>
               <v-col xs="12" sm="12" md="2" class="py-2">
                  <bt-date-picker
                     :debug="debug"
                     :dd-custom-label="true"
                     dd-label="select a date range..."
                     :dd-monthly-items="datePickerMonthlyItems"
                     dd-value="TM"
                     :dp-no-title="false"
                     :dp-max-range="31"
                     v-model="dateRange"
                     @date-range-change="dateRangeChanged"
                  ></bt-date-picker>
               </v-col>
               <v-col xs="12" sm="12" md="2" class="py-5 mt-1">
                  <v-btn small
                     color="primary"
                     :disabled="!isCriteriaChanged"
                     @click="getReports()"
                  >Get Report
                     <v-icon right dark>summarize</v-icon>
                  </v-btn>
               </v-col>
            </v-row>
         </v-form>
      </v-card-text>

      <v-card-text>
         <v-tabs
            class="elevation-2"
            background-color="grey lighten-2 accent-4"
            slider-color="black"
            v-model="tab"
         >
            <v-tab>Totals</v-tab>
            <v-tab>List</v-tab>

            <v-tabs-items v-model="tab">
               <v-tab-item class="pt-5"><!-- Totals -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-text-field clearable
                           class="py-0"
                           append-icon="search"
                           label="Search"
                           :disabled="!totalItems.length"
                           v-model="searchString"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header
                     class="elevation-1"
                     :footer-props="footerPropsTotals"
                     :headers="totalHeaders"
                     :hide-default-footer="totalItems.length <= 5"
                     :items="totalItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsTotals"
                     :search="searchString"
                     @current-items="tableCurrItems"
                  >
                     <template v-slot:[`item.totalItems`]="{item}">
                        {{ formatNumber(item.totalItems) }}
                     </template>
                     <template v-slot:[`body.append`] v-if="totalItemsTotal">
                        <tr class="font-weight-bold">
                           <td>Totals:</td>
                           <td></td>
                           <td></td>
                           <td></td>
                           <td align="right">{{formatNumber(totalItemsTotal)}}</td>
                        </tr>
                     </template>
                  </v-data-table>
               </v-tab-item>

               <v-tab-item class="pt-5"><!-- List -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-autocomplete clearable dense hide-selected
                           append-icon="search"
                           placeholder="search for a specific account"
                           :items="accounts"
                           v-model="selectedAccount"
                           @change="getListReport"
                        ></v-autocomplete>
                     </v-col>
                  </v-row>
                     <!-- :hide-default-footer="listCount <= 5" -->
                  <v-data-table dense fixed-header show-expand single-expand
                     class="elevation-1"
                     item-key="_id"
                     :footer-props="footerPropsList"
                     :headers="listHeaders"
                     :items="listItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsList"
                     :server-items-length="listCount"
                  >
                     <!-- <template v-slot:[`item.processedRecords`]="{item}">
                        {{ formatNumber(item.processedRecords) }}
                     </template> -->
                     <template v-slot:[`item.scheduleDate`]="{ item }">
                        <span style="white-space:pre">{{ formatDate(item.scheduleDate) }}</span>
                     </template>
                     <template v-slot:expanded-item="{ item }">
                        <td>&nbsp;</td>
                        <td colspan="4" class="py-2" valign="top" dense>
                           <ul class="pl-3">
                              <li>
                                 <span class="expanded-header">ID: </span>
                                 <span class="expanded-content">{{item._id}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Comment: </span>
                                 <span class="expanded-content">{{item.comment}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">File URL: </span>
                                 <span class="expanded-content">{{item.fileUrl}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Cron ID: </span>
                                 <span class="expanded-content">{{item.cron._id}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Cron Description: </span>
                                 <span class="expanded-content">{{item.cron.cronDesc}} ({{item.cron.cronTZ}})</span>
                              </li>
                           </ul>
                        </td>
                        <td colspan="3" class="py-2" valign="top" dense>
                           <ul class="pl-3">
                              <li>
                                 <span class="expanded-header">Start Date: </span>
                                 <span class="expanded-content">{{formatDate(item.startDate)}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">End Date: </span>
                                 <span class="expanded-content">{{formatDate(item.endDate)}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Recovery Count: </span>
                                 <span class="expanded-content">{{item.recoveryCount}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">File Transfer Recovery Count: </span>
                                 <span class="expanded-content">{{item.fileTransferRecoveryCount}}</span>
                              </li>
                           </ul>
                        </td>
                     </template>
                  </v-data-table>
               </v-tab-item>
            </v-tabs-items>
         </v-tabs>
      </v-card-text>
   </v-card>
</v-container>
</template>

<script>
import BtDatePicker from "./BtDatePicker.vue";
import { APIService } from '../services/as-api-service.js';
import { format, parseISO } from "date-fns";
// import { sleep } from '../mixins/bt-mixin.js';

const NAME = "AsSchedules";
const TABS = { totals: 0, list: 1 };
const BI_CONSUMER_CODE = 'eb775df1-8fdd-44a5-9d34-da9b8ee55d57';
const LS_CONSUMER_CODE = 'b4e6b535-7819-4fbe-bed5-0e215f437d89';

function _compareItems (e1, e2) {
   return e1.text > e2.text ? 1 : -1;
}

export default {
   name: NAME,

   components: {
      BtDatePicker
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         typeItems: [
            { text: 'Scheduled BI Reports', value: BI_CONSUMER_CODE },
            { text: 'Scheduled List Service Exports', value: LS_CONSUMER_CODE }
         ],
         statusItems: [ 'waiting', 'processing', 'recovery', 'completed', 'terminated' ],
         datePickerMonthlyItems: [
            { text: 'Current Month', value: 'TM' },
            { text: 'Last Month', value: 'LM' }
         ],
         totalHeaders: [
            { text: 'Account Name', value: 'accountname', align: 'left', sortable: true },
            { text: 'Account ID', value: 'accountid', align: 'left', sortable: true },
            { text: 'Parent Name', value: 'parentname', align: 'left', sortable: true },
            { text: 'Parent ID', value: 'parentid', align: 'left', sortable: true },
            { text: 'Total Schedules', value: 'totalSchedules', align: 'right', sortable: true }
         ],
         listHeaders: [],
         apiService: null,
         isMainFormValid: false,
         isCriteriaChanged: false,
         type: BI_CONSUMER_CODE,
         status: 'completed',
         statusIcon: '',
         dateRange: [],
         tab: null,
         loading: false,
         searchString: '',
         optionsTotals: {},
         lastItemsPerPage: 10,
         footerPropsTotals: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         totalItems: [],
         totalItemsTotal: 0,
         // loadingList: false,
         footerPropsList: {
            itemsPerPageOptions: [5, 10, 15, 20],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         optionsList: {},
         listCount: 0,
         listItems: [],
         accounts: [],
         selectedAccount: 0
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      }
   },

   watch: {
      token() {
         this.init();
      },

      async tab(val) {
         if (val === TABS.list && !this.listCount && this.totalItems.length) {
            await this.getListReport();
            const accounts = [];
            this.totalItems.forEach(item => {
               accounts.push({
                  text: `${item.accountname}: ${item.accountid}`,
                  value: item.accountid
               });
            });
            this.accounts = accounts.sort(_compareItems);
         }
      },

      optionsList: {
         handler(val) {
            // alert(`in optionsList watch: val=${JSON.stringify(val)}`)
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
            }
            this.getListItems();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V230906.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         // _alert('in init(): token=' + this.token);
         if (this.token) {
            this.apiService = new APIService(this.token, this.debug, this.isActualEndpoint, '/api/scheduler/v1/admins');
            this.typeChanged(this.type);
            this.statusChanged(this.status);
            setTimeout(() => {
               this.getTotalItems();
            }, 100);
         }
      },

      typeChanged() {
         this.isCriteriaChanged = true;
      },

      statusChanged(val) {
         // alert(`in statusChanged(): val=${val}`);
         this.statusIcon = this.getIcon(val).icon;
         this.listHeaders = [
            { text: 'Account Name', value: 'accountname', align: 'left', sortable: false },
            { text: 'Account ID', value: 'accountid', align: 'left', sortable: false },
            { text: 'Parent Name', value: 'parentname', align: 'left', sortable: false },
            { text: 'Parent ID', value: 'parentid', align: 'left', sortable: false },
            { text: 'Name', value: 'cron.name', align: 'left', sortable: false },
            { text: 'Schedule Date', value: 'scheduleDate', align: 'left', sortable: false }
         ];
         if (val != 'waiting')
            this.listHeaders.push({ text: 'Run Sequence', value: 'runSequence', align: 'right', sortable: false });
         this.isCriteriaChanged = true;
      },

      dateRangeChanged() {
         // alert(`in dateRangeChange(): val=${JSON.stringify(val)}`);
         this.isCriteriaChanged = true;
      },

      getIcon(status) {
         switch (status) {
            case 'waiting':
               return { color: 'grey', icon: 'schedule' };
            case 'processing':
               return { color: 'blue', icon: 'hourglass_empty' };
            case 'completed':
               return { color: 'green', icon: 'done' };
            case 'recovery':
               return { color: 'orange', icon: 'restore' };
            case 'terminated':
               return { color: 'red', icon: 'clear' };
            default:
               return { color: '', icon: '' };
         }
      },

      async getTotalItems() {
         // alert(`in getTotalItems(): status=${this.status}\ndateRange=${JSON.stringify(this.dateRange)}`);
         this.loading = true;
         this.totalItems = [];
         let result = await this.apiService.getTotalSchedules(this.type, this.status, this.dateRange);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.totalItems = result.data;
         }

         this.isCriteriaChanged = false;
         this.loading = false;
      },

      // async getReports() {
      //    // alert(`in getReports(): status=${this.status}\ndateRange=${JSON.stringify(this.dateRange)}`);
      //    this.loadingList = true;
      //    this.totalImports = [];
      //    let result = await this.apiService.getTotalImports(this.status, this.dateRange);
      //    if (result.logout)
      //       this.logout();
            
      //    if (!result.message) {
      //       this.totalImports = result.data;
      //       if (this.totalImports.length) {
      //          await this.getListReport();

      //          const accounts = [];
      //          this.totalImports.forEach(imprt => {
      //             accounts.push({
      //                text: `${imprt.accountname}: ${imprt.accountid}`,
      //                value: imprt.accountid
      //             });
      //          });
      //          this.accounts = accounts.sort(_compareItems);
      //       }
      //    }

      //    this.isCriteriaChanged = false;
      //    this.loadingList = false;
      // },

      // async getReports() {
      //    // alert(`in getReports(): status=${this.status}\ndateRange=${JSON.stringify(this.dateRange)}`);
      //    // this.loadingList = true;
      //    this.loading = true;
      //    await this.getListReport();
      //    this.isCriteriaChanged = false;
      //    // this.loadingList = false;
      //    this.loading = false;
      // },

      formatNumber(number, maxFractionDigits) {
         return new Intl.NumberFormat('en-US', 
            { 
               maximumFractionDigits: maxFractionDigits || 0
            }).format(number);
      },

      tableCurrItems(val) {
         this.totalItemsTotal = 0;
         if (this.searchString) {
            if (!this.lastItemsPerPage) {
               this.lastItemsPerPage = this.optionsTotals.itemsPerPage;
               this.optionsTotals.itemsPerPage = -1;
               this.footerPropsTotals.disableItemsPerPage = true;
            }

            if (val && val.length) {
               val.forEach(v => {
                  this.totalItemsTotal += v.totalItems;
               });
            }
         } else {
            if (this.lastItemsPerPage) {
               this.footerPropsTotals.disableItemsPerPage = false;
               this.optionsTotals.itemsPerPage = this.lastItemsPerPage;
               this.lastItemsPerPage = 0;
            }
            this.totalItems.forEach(ti => {
               this.totalItemsTotal += ti.totalItems;
            });
         }
      },

      async getListCount() {
         // this.loadingList = true;
         this.loading = true;
         let result = await this.apiService.getListSchedulesCount(this.type, this.status, this.dateRange, this.selectedAccount);
         if (result.logout)
            this.logout();

         this.listCount = result.message ? 0 : result.data;
         this.optionsList.page = 1;
         this.footerPropsList.disableItemsPerPage = this.listCount <= 5;
      },

      async getListItems() {
         // alert(`in getListItems(): status=${this.status}\ndateRange=${JSON.stringify(this.dateRange)}`);
         // this.loadingList = true;
         this.loading = true;
         this.listItems = [];
         if (this.listCount) {
            let result = await this.apiService.getListSchedules(this.type, this.status, this.dateRange, this.selectedAccount, this.optionsList.page, this.optionsList.itemsPerPage);
            if (result.logout)
               this.logout();
               
            if (!result.message) {
               this.listItems = result.data;
            }
         }

         this.isCriteriaChanged = false;
         // this.loadingList = false;
         this.loading = false;
      },

      async getReports() {
         await this.getTotalItems();
         this.listCount = 0;
         this.listItems = [];
         if (this.totalItems.length && this.tab === TABS.list) {
            await this.getListReport();
         }
      },

      // async getReports() {
      //    await this.getTotalExports();
      //    this.listExportsCount = 0;
      //    this.listExports = [];
      //    if (this.totalExports.length && this.tab === TABS.list) {
      //       await this.getListReport();
      //    }
      // },

      formatDate(date) {
         if (date) {
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            // return formatteddate.replace(' ', '\n');
            return formatteddate;
         }
      },

      async getListReport() {
         await this.getListCount();
         if (this.listCount)
            await this.getListItems();
         else
            this.listItems = [];
      }
   },

   created() {
      this.init();
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>