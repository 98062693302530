<template>
    <v-form :disabled=readOnlyMode ref="form" v-model="isFormValid">
    <v-sheet class="kc-custom-script">
        <v-row>
            <v-col>
                <editor v-model="scriptData" style="font-size:16px;" @init="editorInit" lang="javascript" theme="chrome" width="760" height="420"></editor>
            </v-col>
        </v-row>
        <!-- {{readOnlyMode}} -->
        <!-- <v-row>
            <v-col class="py-3" cols=6>
                <v-select :readonly=readOnlyMode hide-details v-model="calculatedColumn.chkCreateNewColumn" item-text="text" item-value="value" :items="[{'text':'Create new column', value:true},{'text':'Use existing column',value:false}]" label="Source column" dense></v-select>
            </v-col>
            <v-col v-if="calculatedColumn.chkCreateNewColumn == false" class="py-3" cols=6>
                <v-select :readonly=readOnlyMode hide-details="auto" 
                    v-model="calculatedColumn.columnName"
                    :items="headers"
                    :rules="columnNameRules"
                    label="Column selector"
                    disable-lookup
                    dense
                >
                </v-select>
                
            </v-col>
        </v-row> -->
        <!-- {{isFormValid}} -->
        <!-- <v-row class="mt-0" v-if="calculatedColumn.chkCreateNewColumn == true">
            <v-col class="pt-1" cols=6>
                <v-text-field  :readonly=readOnlyMode v-model="calculatedColumn.name" :rules="nameRules" class="py-1 shrink" label="Name">
                </v-text-field>
            </v-col>
            <v-col class="pt-1" cols=6>
                <v-text-field :readonly=readOnlyMode @click:append="showHelp = !showHelp" _append-icon="help"
                    v-model="calculatedColumn.value" :rules="valueRules" hide-details="auto" class="py-1 shrink" label="Value">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row style="margin-top: -27px;" class="px-4">
            <div style="transform: translateY(16px) translateX(-17px) scale(.75);position: relative;background-color: white;padding: 0 10px;">Preview Render Value</div>
            <v-col style="width: 100%;min-height: 25px;font-size: 0.9em;overflow-y: auto; white-space: nowrap;border-radius: 4px; border: solid 1px gray;" class="py-1 mx-0 my-1" cols=12>
                <div>{{testQRCodeData}}</div>
            </v-col>                            
        </v-row>        
        <v-row v-if="showHelp == true">
            <v-col style="font-size:0.75rem;" cols=6>
                <v-select :readonly=readOnlyMode hide-details class="py-1 shrink"
                    v-model="calculatedColumnSelectorValue"
                    :items="headers"
                    label="Column selector"
                    ref="selectColumn"
                    disable-lookup
                    dense
                >
                </v-select>
            </v-col>
            <v-col v-if="calculatedColumn.chkEvalMode==true" style="font-size:0.75rem;" cols=6>
                <v-sheet>
                    <span class="font-weight-bold">Syntax</span><br>
                    - $n -> column n data start from 1, Ex $2 as column2 <br>
                    - support standdard javascript expression include string interpolation<br>
                    <span class="font-weight-bold">Example</span><br>
                    - "$"+$1 or `${$1}` -> prepend $ with column1<br>
                    - $1.toUpperCase() -> uppercase column1 <br>
                    - $1 + " " + $2 -> concat column 1 and 2 <br>
                </v-sheet>
            </v-col>
            <v-col v-if="calculatedColumn.chkEvalMode==false" style="font-size:0.75rem;" cols=6>
                <v-sheet>
                    <span class="font-weight-bold">Syntax</span><br>
                    - {header} -> data from that header column<br>
                    <span class="font-weight-bold">Example</span><br>
                    - {purl}.jpg -> append data from purl column with .jpg <br>
                </v-sheet>
            </v-col>            
        </v-row> -->
        <!-- <v-row v-if="calculatedColumn.chkCreateNewColumn==true" class="mt-1">
            <v-col class="pb-1" cols=6>
                <v-checkbox :readonly=readOnlyMode color="rgba(0,0,0,.54)" hide-details class="shrink mr-0 mt-0"
                    v-model="calculatedColumn.chkGenImage" label="Generate QRCode Image"></v-checkbox>
            </v-col>
        </v-row>
        <v-sheet class="mt-2" v-if="calculatedColumn.chkGenImage == true" width="100%">
            <v-row>
                <v-col cols="6">
                    <v-sheet width="100%" style="min-height: 300px;">
                        <v-row>
                            <v-col class="py-1" col=6>
                                <v-select :readonly=readOnlyMode hide-details="auto" class="py-1 shrink"
                                    v-model="calculatedColumn.imageColumn"
                                    :rules="imageNameRules"
                                    :items="headers"
                                    label="Image file name"
                                >
                                </v-select>
                            </v-col>
                        </v-row>                     
                        <v-row>
                            <v-col class="pt-1" dense cols="8">
                                <v-select :readonly=readOnlyMode hide-details v-model="calculatedColumn.defaultOption.width"
                                    :items="imageSizes" item-text="name" item-value="value" label="Size" dense>
                                </v-select>
                            </v-col>
                            <v-col class="pt-1" dense cols="4">
                                <v-select :readonly=readOnlyMode hide-details v-model="calculatedColumn.imageFormat" :items="imageFormats"
                                    label="Format" dense></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="6">
                                <v-checkbox :readonly=readOnlyMode color="rgba(0,0,0,.54)" hide-details class=" mr-0 mt-0"
                                    v-model="calculatedColumn.chkRoundEdge" label="Round Edge"></v-checkbox>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="6">
                                <v-checkbox :readonly=readOnlyMode color="rgba(0,0,0,.54)" hide-details class=" mr-0 mt-0"
                                    v-model="calculatedColumn.chkGrayScale" label="Use Grayscale"></v-checkbox>
                            </v-col>

                        </v-row>
                        <v-sheet class="pb-6" v-if="calculatedColumn.chkGrayScale == false">
                            <v-row>
                                <v-col class="pt-5 pb-0 pl-4">Color</v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pl-10">
                                    <v-row class="pt-1">
                                        <v-col class="py-0" cols="4">
                                            <label>Main Area</label>
                                        </v-col>
                                        <v-col class="py-0" cols="8">
                                            <KCColorPicker v-model="calculatedColumn.defaultOption.color.dark"
                                                :hideAlpha=true></KCColorPicker>
                                        </v-col>
                                    </v-row>
                                    <v-row class="pt-1">
                                        <v-col class="py-0" cols="4">
                                            <label>Bottom Left</label>
                                        </v-col>
                                        <v-col class="py-0" cols="8">
                                            <KCColorPicker v-model="calculatedColumn.defaultOption.color.leftbottom"
                                                :hideAlpha=true></KCColorPicker>
                                        </v-col>
                                    </v-row>
                                    <v-row class="pt-1">
                                        <v-col class="py-0" cols="4">
                                            <label>Top Left</label>
                                        </v-col>
                                        <v-col class="py-0" cols="8">
                                            <KCColorPicker v-model="calculatedColumn.defaultOption.color.lefttop"
                                                :hideAlpha=true></KCColorPicker>
                                        </v-col>
                                    </v-row>
                                    <v-row class="pt-1"> 
                                        <v-col class="py-0" cols="4">
                                            <label>Top Right</label>
                                        </v-col>
                                        <v-col class="py-0" cols="8">
                                            <KCColorPicker v-model="calculatedColumn.defaultOption.color.righttop"
                                                :hideAlpha=true></KCColorPicker>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-sheet> -->
                        <!-- <v-row>
                            <v-col class="py-0" cols="6">
                                <v-checkbox color="rgba(0,0,0,.54)" hide-details class="shrink mr-0 mt-0"
                                    v-model="calculatedColumn.chkLogoImage" label="Center Logo">
                                </v-checkbox>
                            </v-col>
                        </v-row> -->
                        <!-- <v-row>
                            <v-col>
                            <v-file-input :readonly=readOnlyMode hide-details class="pt-2 shrink" v-model="localFileInput" label="Center Logo Image" prepend-icon="upload_file"
                            @change="centerLogoChange" ></v-file-input>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col cols="6">
                    <v-sheet style="position:relative;" width="100%">
                        <v-img v-if="testQRCodeData" style="position:absolute;top:0px;" contain width="350" height="250" :src="url"
                            :key="url">
                            <v-row class="fill-height ma-0" align="center" justify="center"> -->
                                <!-- <v-btn @click="showSetting = !showSetting" v-show="showSettingButton" style="margin-top:50px;" absolute color="orange" class="white--text" fab right top small>
                        <v-icon>settings</v-icon>
                    </v-btn> -->
                                <!-- <v-progress-circular v-show="url == null" size="100" width="8" indeterminate
                                    color="indigo darken-2"></v-progress-circular>
                            </v-row>
                        </v-img>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-sheet> -->
    </v-sheet>
    </v-form>
</template>

<style>
.kc-qrcode-config span.required {
    color:red;    
}
</style>

<script>
import axios from "axios";
import debounce from "lodash.debounce";
import csvUtils from "../services/KCCSVUtils.js";
import {
    id
} from "vuetify/lib/locale";
import utils from '../services/KCUtils.js';
import draggable from 'vuedraggable'
import {
    consoleError
} from "vuetify/lib/util/console";
import KCColorPicker from "./KCColorPicker.vue";

export default {
    components: {
        editor: require('vue2-ace-editor'),
        //KCColorPicker
    },
    props: {
        value: null,
        headers: {
             default() {
                 return []
             }
        },
        sampleData: {
             default() {
                 return []
             }
        },
        readOnlyMode: {
            default() {
                 return false;
             }
        },
    },
    data() {
        return {
            customScript: {},
            defaultScript: 
`//function customScriptExample(items){
    // write code below
    let header = items[0];
    header.push("KKK");
    for(let i=1;i<items.length;i++){
        items[i].push(items[0] + items[1]);
    }
    return items;
//}
`,
            scriptData: "",
            isFormValid: false,
        };
    },
    created() {
    },
    mounted() {
        this.scriptData = this.defaultScript;
    },
    beforeUnmount() {
    },
    methods: {
        async editorInit(editor){
            console.log("editorInit");
            require('brace/ext/language_tools') //language extension prerequsite...
            require('brace/mode/html')                
            require('brace/mode/javascript')    //language
            require('brace/mode/less')
            require('brace/theme/chrome')
            require('brace/snippets/javascript') //snippet
            console.log(editor);
            editor.setOption("showPrintMargin", false);
            editor.focus();
            this.aceEditor = editor;
        },
        canSave(){
            console.log("CustomScript cansave");
            try{
                const customFunction = new Function("items",this.scriptData);
                let ret = customFunction([["col1"],[1]]);
                console.log(ret);
                return true;    
            }catch(ex){
                console.log(ex);
            }
            return false;
        },

        setConfig(config) {
            //var oldCalculatedColumn = this.calculatedColumn;
            //this.calculatedColumn = {...{chkCreateNewColumn:true }  , ...JSON.parse(JSON.stringify(config))};
            this.customScript = JSON.parse(JSON.stringify(config));
            console.log("setConfig",config);
            //if(oldCalculatedColumn.chkEvalMode != this.calculatedColumn.chkEvalMode){
                //this.calculatedColumnValueChanged();
            //}
        },
        setDefaultConfig(){
            this.scriptData = this.defaultScript;
        },

        setConfigByValue(){
            //var oldLogo = this.calculatedColumn.defaultOption.logo;
            this.setConfig(this.value);
            // if(this.calculatedColumn.defaultOption.logo!=""){
            //     if(this.calculatedColumn.defaultOption.logo != oldLogo){
            //         console.log("Set bogus fileInput");
            //         this.localFileInput = new File([], this.calculatedColumn.logoLocalFileName, {
            //             type: "text/plain",
            //         })
            //     }
            // }    
        },
        // centerLogoChange(evt){
        //     console.log(evt);
        //     if(evt == null){
        //         // click clear 
        //         this.calculatedColumn.defaultOption.logo = "";
        //         this.calculatedColumn.logoLocalFileName = "";
        //     }else{
        //         var self = this;
        //         if (this.localFileInput) {
        //             var reader = new FileReader();
        //             reader.readAsDataURL(this.localFileInput);
        //             reader.onload = () => {
        //                 this.data = reader.result;
        //                 console.log(this.data.substring(0,1000));
        //                 self.calculatedColumn.defaultOption.logo = this.data;
        //                 self.calculatedColumn.logoLocalFileName = evt.name;
        //                 console.log("centerLogoChange",self.calculatedColumn.logoLocalFileName);

        //                 //this.loadCSVFromString(this.data);
        //                 //this.processAll();
        //             }
        //         }else{
        //             this.calculatedColumn.logoLocalFileName = evt.name;
        //         }
        //     }
        // },
        // updateVModel(...args){
        //     if (args[0] != null) {
        //         this.emitInput();
        //         //this.$emit('input', JSON.parse(JSON.stringify(this.calculatedColumn)));
        //     }
        // },
        // calculatedColumnValueChanged(){
        //     try{
        //         //console.log("calculatedColumnValueChanged","isFormValid",this.isFormValid);
        //         var value;
        //         if(this.calculatedColumn.chkCreateNewColumn){
        //             console.log("calculatedColumn.value",this.calculatedColumn.value);
        //             //this.calculatedColumn.err = "";
        //             value = this.calculatedColumn.value.trim();
        //             if(this.calculatedColumn.chkEvalMode){
        //                 var cookedValue = csvUtils.convertColNameToColNum(value,this.headers);
        //                 value = csvUtils.calculatedValue(this.sampleData, cookedValue);
        //             }else{
        //                 var subCommand = csvUtils.createSubsitiueCommand(value,this.headers);
        //                 value = csvUtils.subsituteValue(this.sampleData,subCommand);
        //             }
        //             if(value==undefined){
        //                 value = "";
        //             }
        //             this.testQRCodeData = "" + value;   // need to make data into string 
        //         }else{
        //             var index = this.headers.indexOf(this.calculatedColumn.columnName);
        //             if(index >=0){
        //                 this.testQRCodeData = this.sampleData[index];
        //             }else{
        //                 this.testQRCodeData = "";
        //             }
        //         }
        //     }catch(ex){
        //         console.log(ex);
        //         // var cookedErrorMessage = csvUtils.convertColNumToColName(ex.message,this.headers);
        //         //this.calculatedColumn.err = cookedErrorMessage;
        //         this.testQRCodeData = "";
        //     }
        //     this.$refs.form.validate();
        // },
        // calculatedColumnSelectorValueKeydown: function(e){
        //     console.log("calculatedColumnSelectorValueKeydown",e);
        //     // var prevValue = this.calculatedColumnSelectorValue;
        //     // if(e.key == "Enter"){
        //     //     setTimeout(()=>{
        //     //         var newValue = this.calculatedColumnSelectorValue;
        //     //         if(prevValue != newValue){
        //     //             console.log("calculatedColumnSelectorValueKeydown",newValue,this.$refs.selectColumn);
        //     //             if(this.calculatedColumn.chkEvalMode == true){
        //     //                 this.calculatedColumn.value += '$["' + newValue +'"]';
        //     //             }else{
        //     //                 this.calculatedColumn.value += '{' + newValue +'}';
        //     //             }               
        //     //         }
        //     //     },500);
        //     // }
        // }
    },
    computed: {
    },
    watch: {
        value: function (newValue, oldValue) {
            console.log("QRCodeCOnfig watch value", newValue);
            if (newValue != null) {
                this.setConfigByValue();
            }else{
                this.setDefaultConfig();
            }
        },
        scriptData: function(newValue,oldValue){
            this.$nextTick(() => {
                this.$emit("canSave",this.canSave());
            })
        }
        // testQRCodeData: function (...args) {
        //     if (args[0] != null) {
        //         if(args[0]!=""){
        //             this.url = null;
        //             this.debouncedWatch(...args);
        //         }
        //     }
        // },
        // calculatedColumnSelectorValue(newValue, oldValue) {
        //     //console.log("calculatedColumnSelectorValue",newValue);
        //     if(newValue != ""){
        //         setTimeout(()=>{
        //             if(this.$refs.selectColumn.isMenuActive==false){
        //                 if(this.calculatedColumn.chkEvalMode == true){
        //                     this.calculatedColumn.value += '$["' + newValue +'"]';
        //                 }else{
        //                     this.calculatedColumn.value += '{' + newValue +'}';
        //                 }
        //             }
        //         },500);
        //     }
        // },
        // isFormValid: function(...args){
        //     if (args[0] != null) {
        //         if(args[0] == false){
        //             this.calculatedColumn.err = "ERROR";
        //             this.emitInput();
        //         }else{
        //             this.calculatedColumn.err = "";
        //         }
        //     }
        // },
        // 'calculatedColumn.defaultOption.color.dark': {
        //     handler(val, oldval) {
        //         this.url = null;
        //         this.debouncedWatch(val, oldval);
        //     },
        // },
        // 'calculatedColumn.defaultOption.color.lefttop': {
        //     handler(val, oldval) {
        //         this.url = null;
        //         this.debouncedWatch(val, oldval);
        //     },
        // },
        // 'calculatedColumn.defaultOption.color.righttop': {
        //     handler(val, oldval) {
        //         this.url = null;
        //         this.debouncedWatch(val, oldval);
        //     },
        // },
        // 'calculatedColumn.defaultOption.color.leftbottom': {
        //     handler(val, oldval) {
        //         this.url = null;
        //         this.debouncedWatch(val, oldval);
        //     },
        // },

        // 'calculatedColumn.defaultOption.logo': {
        //     handler(val, oldval) {
        //         console.log("defaultOption.logo change",val);
        //         this.url = null;
        //         this.debouncedWatch(val, oldval);
        //     },
        // },        

        // "calculatedColumn.chkRoundEdge": function (...args) {
        //     if (args[0] != null) {
        //         this.url = null;
        //         this.debouncedWatch(...args);
        //     }
        // },
        // "calculatedColumn.chkGrayScale": function (...args) {
        //     if (args[0] != null) {
        //         this.url = null;
        //         this.debouncedWatch(...args);
        //     }
        // },
        // "calculatedColumn.chkGenImage": function (...args) {
        //     if (args[0] != null) {
        //         this.url = null;
        //         //this.$refs.form.validate();
        //         this.debouncedWatch(...args);
        //     }
        // },

        // // "calculatedColumn.chkLogoImage": function (...args) {
        // //     if (args[0] != null) {
        // //         this.url = null;
        // //         this.debouncedWatch(...args);
        // //     }
        // // },
        
        // "calculatedColumn.imageFormat": function (...args) {
        //     if (args[0] != null) {
        //         this.url = null;
        //         this.debouncedWatch(...args);
        //     }
        // },
        // "calculatedColumn.imageColumn": function (...args) {
        //     if (args[0] != null) {
        //         this.url = null;
        //         this.debouncedWatch(...args);
        //     }
        // },        
        // "calculatedColumn.defaultOption.width": function (...args) {
        //     if (args[0] != null) {
        //         this.url = null;
        //         this.debouncedWatch(...args);
        //     }
        // },
        // "calculatedColumn.name": function (...args) {
        //     if (args[0] != null) {
        //         this.emitInput();
        //         //this.$emit('input', JSON.parse(JSON.stringify(this.calculatedColumn)));
        //     }
        // },
        // "calculatedColumn.value": function (...args) {
        //     if (args[0] != null) {
        //         this.calculatedColumnValueChanged();
        //         this.emitInput();
        //         //this.$emit('input', JSON.parse(JSON.stringify(this.calculatedColumn)));                
        //     }
        // },
        // "calculatedColumn.columnName": function (...args) {
        //     if (args[0] != null) {
        //         this.emitInput();
        //     }
        // },
        // "calculatedColumn.logoLocalFileName": function (...args) {
        //     this.updateVModel(...args);
        // },
        // "calculatedColumn.chkCreateNewColumn": function (...args) {
        //     if (args[0] != null) {
        //         if(args[0] == false){
        //             this.calculatedColumn.chkGenImage = true;
        //         }
        //     }
        //     this.updateVModel(...args);
        // },       
        // "calculatedColumn.err": function (...args) {
        //     this.updateVModel(...args);
        // },       
        // "calculatedColumn.chkEvalMode": function (...args) {
        //     //this.calculatedColumn.err = "";
        //     //this.$refs.form.validate();
        //     this.updateVModel(...args);
        //     //this.calculatedColumnValueChanged();
        // },          
        // // calculatedColumn: {
        // //     handler(val){
        // //         if(val){
        // //             //console.log("commit",val);
        // //             this.$emit('commit', JSON.parse(JSON.stringify(val)));
        // //         }
        // //     },
        // //     deep:true,
        // // }            
    },
}
</script>
