<template>
    <v-container style="height: 100%; width: 100%;background: white;">
        <v-row v-if="showHeader">
            <span class="title font-weight-bold" style="color:#757575 !important; line-height: 2.5rem;">SFTP</span>
        </v-row>
        <v-row v-if="showEnable">
            <v-col cols="12" class="pt-1">
                <v-checkbox v-model="sftp.enable" hide-details class="shrink mr-0 mt-0" :label=enableLabel  ></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="8" class="pt-1 pb-0">
                <v-text-field hide-details :disabled=disableInput dense v-model="sftp.host" label="Host"></v-text-field>
                <div v-if="canSaveHost == false" style="padding: 0px; font-size: 12px;font-style: italic;color: firebrick;">
                    Please provide the correct domain.
                </div>
            </v-col>
            <v-col cols="4" class="pt-1 pb-0">
                <v-text-field hide-details :disabled=disableInput hide-spin-buttons type="number" dense v-model="sftp.port" label="Port"></v-text-field>
                <div v-if="canSavePort == false" style="padding: 0px; font-size: 12px;font-style: italic;color: firebrick;">
                    Please provide numberic only.
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" class="pt-4 pb-0">
                <v-text-field hide-details :disabled=disableInput dense v-model="sftp.username" label="User Name"></v-text-field>
                <div v-if="canSaveUsername == false" style="padding: 0px; font-size: 12px;font-style: italic;color: firebrick;">
                    Please provide User Name.
                </div>
            </v-col>
        </v-row>
        <div class="mt-6">
            <!-- <label>Password</label> -->
            <v-radio-group hide-details :disabled=disableInput row v-model="sftp.passwordtype">
                <template v-slot:label>
                      <div style="font-size:16px;margin-right:20px;">Password</div>
                </template>
                <v-radio label="Plain Text" value="plaintext"></v-radio>
                <v-radio label="Private Key" value="privatekey"></v-radio>
            </v-radio-group>            
        </div>
        <v-row class="py-0 my-0">
            <!-- <v-col class="py-0 my-0" cols="3">
                <v-checkbox v-model="sftp.chkPlainText" hide-details class="shrink mr-0 mt-0" label="Plain text"></v-checkbox>
            </v-col> -->
            
            <v-col class="py-2 my-0" cols="12">
                <template v-if="sftp.passwordtype == 'plaintext'">
                <v-text-field hide-details :disabled=disableInput dense v-model="sftp.password" label="Plain Text Password"></v-text-field>
                <div v-if="canSavePassword == false" style="padding: 0px; font-size: 12px;font-style: italic;color: firebrick;">
                    Please provide Password.
                </div>
                </template>
                <template v-else>
                    <v-text-field style="font-style:italic;" :value=textPrivateKey placeholder="click here to set" @click="rsaPassword()" hide-details  readonly  :disabled=disableInput dense label="Private Key"></v-text-field>                    
                </template>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="10" class="pt-1 pb-0">
                <v-text-field :disabled=disableInput dense v-model="sftp.folder" label="Folder"></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-1 pb-0">
                <v-btn v-show="sftp.enable && canSaveAll" :disabled=disableInput @click="testSFTP()">Test</v-btn>
            </v-col>
        </v-row>
        <v-progress-linear v-show="showProgress"
            indeterminate
            height="3"
        ></v-progress-linear>
        <v-dialog persistent v-model="showRSApassword" width="800" height="600">
            <v-sheet style="position:relative;" class="rounded-lg mx-auto pa-5" elevation="12" height="480" width="95%">
                <v-row>
                    <v-col class="pb-0" cols="12">
                        <span class="title font-weight-bold" style="color:#757575 !important; line-height: 2.5rem;">Credential</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea :rules="[privateKeyRule]"  style="font-size:14px;" v-model="tmpprivatekey" placeholder=
"-----BEGIN OPENSSH PRIVATE KEY-----
b3BlbnNzaC1rZXktdjEAAAAACmFlczI1Ni1jdHIAAAAGYmNyeXB0AAAAGAAAABD3bpG/cf
...
or 
-----BEGIN RSA PRIVATE KEY-----
CEM0dJJfPnuXN8TDADcuIMzy5Kd5aUUqPlBd8rLrJAa09gBj+hhH00XcSSqo6uY8RU78Q9
...
" label="OPENSSH privatekey or RSA privatekey" row="10" height="260" outlined no-resize></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0" cols="6">
                        <v-text-field  :disabled=disableInput dense v-model="tmppassphrase" label="Passphrase"></v-text-field>
                        <div v-if="canSavePassword == false" style="padding: 0px; font-size: 12px;font-style: italic;color: firebrick;">
                            Please provide Password.
                        </div>
                    </v-col>
                </v-row>       
                <div style="position: absolute;bottom: 15px;width: 95%;">
                    <v-btn style="float:right;" @click="cancelRSAPassword()">cancel</v-btn>
                    <v-btn :disabled=!isRSABSaveButtonEnabled style="float:right;margin-right:10px;" @click="saveRSAPassword()">OK</v-btn>
                </div>                           
            </v-sheet>
        </v-dialog>
    </v-container>
</template>
<script>
import axios from "axios";
import utils from '../services/KCUtils.js'
import kcMixins from "../services/KCMixins.js";
//  import addThisPass from "../services/KCAddThisPass.js";
// import KCQRCodeView from "./KCQRCodeView.vue";
//  import KCGoogleAutoComplete from "../components/KCGoogleAutoComplete.vue";
// var crypto = require('crypto');

const NAME = "JWSFTP";

const serverMode="pass";
// let config = utils.getServerConfig(serverMode);
import csvUtils from "../services/KCCSVUtils.js";

export default {
    name: NAME,
    mixins: [kcMixins],
    components: {
        // KCQRCodeView
    },
    props: {
        serverMode: {
            default: utils.getServerMode("mindfireOne"),
        },
        showHeader: {
            default: true,
        },
        showEnable: {
            default: false,
        },
        enableLabel: {
            default: "Enable",
        },
        enablePrivateKey: {
            default: false,
        },
    },
    data() {
        var config = utils.getServerConfig(this.serverMode);
        //console.log(config);
        var endPoint =  config.endPoints.mindfireOne + "/api/csvprocessor";        
        return {
            endPoint,
            /* mixins start */
            appName: "passUI",
            /* mixins end */
            defSFTP: {
                host: '',
                port: '',
                username: '',
                password: '',
                passphrase: "",
                privatekey: "",
                folder: '',
                passwordtype: "plaintext",
                //chkPrivateKey: false,
            },
            sftp: {},
            sftpInit: {},
            canSaveHost: true,
            canSavePort: true,
            canSaveUsername: true,
            canSavePassword: true,
            showProgress: false,
            canSaveAll: false,
            showRSApassword: false,

            tmppassphrase: "",
            tmpprivatekey: "",
            //privateKey: "",
        };
    },
    computed: {
        disableInput(){
            return !(this.sftp.enable == true);
        },
        isRSABSaveButtonEnabled() {
            // passphrase is optional
            // if(this.tmppassphrase.trim() ==""){
            //     return false;
            // }
            if(this.tmpprivatekey.trim() ==""){
                return false;
            }
            if(this.privateKeyRule(this.tmpprivatekey)!=true){
                return false;
            }
            return true;
        },
        textPrivateKey(){
            if(this.isRSABSaveButtonEnabled == false){
                return "<Click here to set>";
            }else{
                return `Private key with passphrase [${this.sftp.passphrase}]`;
            }
        },
    },
    methods: {
        privateKeyRule(value){
            // check OPENSSH
            let trimvalue = value.trim();
            if(trimvalue.startsWith('-----BEGIN OPENSSH PRIVATE KEY-----') && trimvalue.endsWith('-----END OPENSSH PRIVATE KEY-----')){
                return true;  
            }
            if(trimvalue.startsWith('-----BEGIN RSA PRIVATE KEY-----') && trimvalue.endsWith('-----END RSA PRIVATE KEY-----')){
                return true;  
            }
            if(trimvalue == ""){
                return true;
            }
            return "Only OPENSSH PRIVATE and RSA PRIVATE are supported"
        },
        setSFTP (sftp) {
            console.log('setSFTP: ', sftp);
            this.sftp = {... JSON.parse(JSON.stringify(this.defSFTP)), ... JSON.parse(JSON.stringify(sftp)) }
            this.tmppassphrase = this.sftp.passphrase;
            this.tmpprivatekey = this.sftp.privatekey;
            // if (typeof sftp.host != "undefined") {
            //     this.sftp = JSON.parse(JSON.stringify(sftp));
            //     this.sftpInit = JSON.parse(JSON.stringify(sftp));
            // } else {
            //     this.sftp = JSON.parse(JSON.stringify(this.defSFTP));
            //     this.sftpInit = JSON.parse(JSON.stringify(this.defSFTP));
            // }
            //this.$emit("canSave", false);
            this.canSave();
            console.log('setSFTP: ', this.sftp);
        },
        getSFTP() {
            return this.sftp;
        },
        async rsaPassword(){
            this.tmppassphrase = this.sftp.passphrase;
            this.tmpprivatekey = this.sftp.privatekey;
            this.showRSApassword = true;
            this.canSave();
        },
        async cancelRSAPassword(){
            this.tmppassphrase = this.sftp.passphrase;
            this.tmpprivatekey = this.sftp.privatekey;
            this.showRSApassword = false;
            this.canSave();
        },
        async saveRSAPassword(){
            this.sftp.passphrase = this.tmppassphrase;
            this.sftp.privatekey = this.tmpprivatekey;
            this.showRSApassword = false;
            this.canSave();
        },
        async testSFTP(){
            try{  
                var accountID = this.$store.getters.user.account;
                /*
                cmd:"checkSFTPServer",
                "host":"web2xmm.com","username":"atrium","password":"Atm3579#","folder":"testsftp/import","port":22,
                */
                this.showProgress = true;
                var resp = await axios.post(this.endPoint,{
                    cmd:"checkSFTPServer",
                    acctID: accountID,
                    ... this.sftp,
                });
                console.log(resp.data);
                if(resp.data.success == false){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'SFTP test fail',
                        text: resp.data.message,
                        ... csvUtils.getSwalYesNoOption(),
                        confirmButtonText: "Ok",
                    }).then((result)=>{
                    // close popup
                    
                    })
                }else{
                    this.$swal.fire({
                        icon: 'success',
                        title: 'SFTP test success',
                        text: 'This config can be used.',
                        ... csvUtils.getSwalYesNoOption(),
                        confirmButtonText: "Ok",
                    }).then((result)=>{
                    // close popup
                    })
                }
                return resp.data;
                
            }catch(ex){
                console.log(ex);
            }finally {
                this.showProgress = false;
            }
        },
        validateDomain(url) {
            var matches = url.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i);
            var domain = matches && matches[0];
            return domain;
        },
        canSave(hint){
            console.log("canSave: ", this.sftp, this.sftpInit);
            if(this.sftp.enable == false){
                console.log("canSave due to enable == false");
                this.$emit("canSave", true);
                this.canSaveAll = true;
                return;
            }
            if (hint=='host') this.canSaveHost = true;
            if (hint=='port') this.canSavePort = true;
            if (hint=='username') this.canSaveUsername = true;
            if (hint=='password') this.canSavePassword = true;
            // if(JSON.stringify(this.sftp) == JSON.stringify(this.sftpInit)){
            //     this.$emit("canSave", false);
            //     return;
            // }
            if ((typeof this.sftp.host == "undefined") || (this.sftp.host.trim() == '')) {
                console.log("canSave sftp return: false");
                if (hint=='host') this.canSaveHost = false;
                this.$emit("canSave", false);
                this.canSaveAll = false;
                return;
            }
            let host = this.validateDomain(this.sftp.host);
            console.log("canSave host: ", host);
            if (host == null) {
                console.log("canSave host return: false");
                if (hint=='host') this.canSaveHost = false;
                this.$emit("canSave", false);
                this.canSaveAll = false;
                return;
            } else {
                this.sftp.host = host;
            }
            if (!this.sftp.port) {
                console.log("canSave port return: false");
                if (hint=='port') this.canSavePort = false;
                this.$emit("canSave", false);
                this.canSaveAll = false;
                return;
            }
            if ((typeof this.sftp.username == "undefined") || (this.sftp.username.trim() == '')) {
                console.log("canSave username return: false");
                if (hint=='username') this.canSaveUsername = false;
                this.$emit("canSave", false);
                this.canSaveAll = false;
                return;
            }
            if(this.sftp.passwordtype=="plaintext"){
                if ((typeof this.sftp.password == "undefined") || (this.sftp.password.trim() == '')) {
                    console.log("canSave plaintext return: false");
                    if (hint=='password') this.canSavePassword = false;
                    this.$emit("canSave", false);
                    this.canSaveAll = false;
                    return;
                }
            }
            if(this.sftp.passwordtype=="privatekey"){
                if(this.isRSABSaveButtonEnabled == false){
                    console.log("canSave privatekey return: false");
                    this.$emit("canSave", false);
                    this.canSaveAll = false;
                    return;
                }
            }
            console.log("canSave return: true");
            this.$emit("canSave", true);
            this.canSaveAll = true;
        }
    },
    created() {
    },
    destroyed() {
    },
    async mounted() {
    },
    beforeUnmount() { 
    },
    watch: {
        'sftp.host': {
            handler(newValue, oldValue) {
                this.canSave("host");
            },
        },
        'sftp.port': {
            handler(newValue, oldValue) {
                this.canSave("port");
            },
        },
        'sftp.username': {
            handler(newValue, oldValue) {
                this.canSave("username");
            },
        },
        'sftp.password': {
            handler(newValue, oldValue) {
                this.canSave("password");
            },
        },
        'sftp.enable': {
            handler(newValue, oldValue) {
                this.canSave();
                this.$emit("enableChange", newValue);
            },
        },
        'sftp.chkPlainText': {
            handler(newValue, oldValue) {
                // if(newValue!=oldValue){
                //     this.sftp.chkPrivateKey = !newValue
                // }
                this.canSave();
                //this.$emit("chkPlainText", newValue);
            },
        },
        'sftp.passwordtype':{
            handler(newValue, oldValue) {
                this.canSave();
                //this.$emit("passwordtype", newValue);
            },
        }
    },
};
</script>