<!-- 
* WHAT: The user interface for the CalculatedTableForCs component.
* HISTORY:
- 05/xx/22(B0.1): 1st Release.
-->
<template>
<div>
   <v-textarea clearable outlined persistent-hint readonly
      class="pt-3 pr-0 mb-0"
      label="Calculated Columns in JSON Format"
      rows="3"
      append-icon="edit"
      :hide-details="false"
      v-model="serializedCalCols"
      @click:append="editSerializedCalCols"
      @click:clear="clearSerializedCalCols"
   ></v-textarea>

   <v-dialog persistent no-click-animation scrollable
      max-width="1200px"
      v-model="dialogCalCol"
   >
      <v-card class="px-4">
         <v-card-title class="title grey--text darken-4 font-weight-bold pl-3 pt-3 pb-0">Configure Calculated Columns:</v-card-title>
         <v-container>
            <v-form lazy-validation
               ref="formCalCol"
               v-model="isFormCalColValid"
            >
               <v-row v-for="(item, i) in calculatedColumns" :key="i">
                  <v-col class="pt-0 pb-2" xs="12" sm="12" md="2" lg="2">
                     <v-text-field persistent-hint
                        :ref="`name${i}`"
                        class="pt-0"
                        autocomplete="off"
                        hint="Name"
                        :rules="[rules.duplicate]"
                        v-model="item.name"
                        @change="addCalCol"
                     >
                        <template v-slot:prepend>
                           <v-icon
                              class="py-2"
                              color="grey darken-1"
                              :disabled="!item.name"
                              @click="removeCalCol(i)"
                           >delete_forever</v-icon>
                        </template>
                     </v-text-field>
                  </v-col>
                  <v-col class="pt-0 pb-2" xs="12" sm="12" md="5" lg="5" v-if="item.name">
                     <v-text-field persistent-hint required
                        class="pt-0"
                        hint="Calculation Expression"
                        :placeholder="`{{col1}}/{{col2}}*100 OR {{col1}}+{{col2}}`"
                        v-model="item.expression"
                        :rules="[rules.required,rules.expression]"
                     ></v-text-field>
                  </v-col>
                  <v-col class="pt-0 pb-2" xs="12" sm="12" md="2" lg="2" v-if="item.name">
                     <v-menu close-on-content-click offset-y bottom
                        class="pt-0"
                        min-width="auto"
                        transition="scale-transition"
                        v-model="item.fix.menu"
                     >
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field persistent-hint required
                              :ref="`fix_${item.name}`"
                              class="pt-0"
                              autocomplete="off"
                              append-icon="arrow_drop_down"
                              :hint="item.fix.hint"
                              :placeholder="item.fix.placeholder"
                              :readonly="item.fix.list === 'none'"
                              :rules="[rules.required]"
                              v-model="item.fix.text"
                              v-bind="attrs"
                              v-on="on"
                           ></v-text-field>
                        </template>
                        <v-list dense class="py-0">
                           <v-list-item v-for="(listItem, index) in calColFixItems" :key="index"
                              @click="calColFixMenuChanged(listItem, item)"
                           >
                              <v-list-item-content>
                                 <v-list-item-title v-text="listItem.text"></v-list-item-title>
                              </v-list-item-content>
                           </v-list-item>
                        </v-list>
                     </v-menu>
                  </v-col>
                  <v-col class="pt-0 pb-0" xs="12" sm="12" md="3" lg="3" v-if="item.name">
                     <v-menu close-on-content-click offset-y bottom
                        class="pt-0"
                        min-width="auto"
                        transition="scale-transition"
                        v-model="item.position.menu"
                     >
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field persistent-hint required
                              :ref="item.name"
                              class="pt-0"
                              autocomplete="off"
                              append-icon="arrow_drop_down"
                              :placeholder="item.position.placeholder"
                              :hint="item.position.hint"
                              :readonly="['s','e'].includes(item.position.list)"
                              :rules="[rules.required]"
                              v-model="item.position.text"
                              v-bind="attrs"
                              v-on="on"
                           ></v-text-field>
                        </template>
                        <v-list dense class="py-0">
                           <v-list-item v-for="(listItem, index) in calColPositionItems" :key="index"
                              @click="calColPositionMenuChanged(listItem, item)"
                           >
                              <v-list-item-content>
                                 <v-list-item-title v-text="listItem.text"></v-list-item-title>
                              </v-list-item-content>
                           </v-list-item>
                        </v-list>
                     </v-menu>
                  </v-col>
               </v-row>
            </v-form>
         </v-container>
         <v-card-actions class="py-0 pr-0">
            <div class="flex-grow-1"></div>
            <v-btn text
               class="px-0"
               color="blue darken-1"
               @click="cancelCalCol"
            >Cancel</v-btn>
            <v-btn text
               class="px-0 ml-0"
               color="blue darken-1"
               :disabled="!isFormCalColValid"
               @click="saveCalCol"
            >Save</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</div>
</template>

<script>
const NAME = "BtCalculatedColumns";
const MSG = `-----${NAME} B0.1 says => `;

export default {
   name: NAME,

   props: {
      value: {
         type: String,
         required: true,
         default: ''
      },

      debug: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         serializedCalCols: '',
         dialogCalCol: false,
         isFormCalColValid: false,
         calculatedColumns: [],
         calColPositionItems: [
            { text: "At the Beginning", value: 's' },
            { text: "At the End", value: 'e' },
            { text: "Before Column...", value: "b" },
            { text: "After Column...", value: "a" },
         ],
         calColFixItems: [
            { text: "None", value: 'none' },
            { text: "Prefix (before values)", value: 'pre' },
            { text: "Postfix (after values)", value: "post" }
         ],
         rules: {
            required: value => !!value || 'Value is required!',
            // validJson: value => {
            //    try {
            //       if (value) {
            //          if (value.trim().indexOf('"') === 0)
            //             return 'SyntaxError: Unexpected token a in JSON at position 0';
            //          JSON.parse(value);
            //       }
            //       return true;
            //    } catch (error) {
            //       return error.toString();
            //    }
            // },
            // validJsonArray: value => {
            //    try {
            //       let json;
            //       if (value) {
            //          json = JSON.parse(value);
            //          return Array.isArray(json) || 'Should be an array';
            //       } else return true;
            //    } catch (error) {
            //       return error.toString();
            //    }
            // },
            duplicate: value => this.calculatedColumns.filter(cc => cc.name.toLowerCase() === value.toLowerCase()).length <= 1 || 
               'Value is duplicate!',
            expression: value => this.findVariables(value).length > 0 || 'Value is invalid!',
         }
      }
   },

   computed: { },

   watch: {
      value: {
         immediate: true,
         handler(val) {
            // alert('in watch.value(): val=' + val);
            this.serializedCalCols = val ? val : '';
            this.$forceUpdate();
         }
      }
   },

   methods: {
      log(msg, isError) {
         if (isError)
            console.error(`${MSG}${msg}`);
         else if (this.debug) {
            console.log(`${MSG}${msg}`);
            // alert(`${MSG}${msg}`);
         }
      },

      findVariables(expression) {
         const startMark = "{{";
         const endMark = "}}";
         const regEx = /{{[^{]+}}/g;
         const matches = expression.match(regEx) || [];
         // console.log('matches=' + JSON.stringify(matches));
         const vars = [];

         matches.forEach(match => {
            const key = match.replace(startMark, '').replace(endMark, '');
            // console.log('match=' + match + ', key=' + key);
            if (key)
               vars.push(key);
         });

         // console.log('vars=' + JSON.stringify(vars));
         return vars;
      },

      editSerializedCalCols() {
         this.calculatedColumns = this.serializedCalCols ? JSON.parse(this.serializedCalCols) : [];

         this.calculatedColumns.forEach(calCol => {
            const fixParts = calCol.fix.split('::');
            calCol.fix = {
               menu: false,
               list: fixParts[0],
               text: fixParts[1] || this.calColFixItems.find(f => f.value === fixParts[0]).text,
            };

            const positionParts = calCol.position.split('::');
            calCol.position = {
               menu: false,
               list: positionParts[0],
               text: positionParts[1] || this.calColPositionItems.find(p => p.value === positionParts[0]).text,
            };

            this.setCalColFixHintAndPlaceholder(calCol.fix);
            this.setCalColPositionHintAndPlaceholder(calCol.position);
         });

         this.addCalCol();
         this.$nextTick(() => {
            this.dialogCalCol = true;
            // this.$refs[`name${this.calculatedColumns.length - 1}`].focus();
         });
      },

      clearSerializedCalCols() {
         this.$emit('input', '');
      },

      addCalCol() {
         const len = this.calculatedColumns.length;
         if (len === 0 || (this.calculatedColumns[len - 1].name && len < 5)) {
            const calCol = {
               name: '',
               expression: '',
               fix: {
                  menu: false,
                  list: '',
                  text: ''
               },
               position: {
                  menu: false,
                  list: '',
                  text: ''
               }
            };

            this.setCalColFixHintAndPlaceholder(calCol.fix);
            this.setCalColPositionHintAndPlaceholder(calCol.position);
            this.calculatedColumns.push(calCol);
         }
      },

      removeCalCol(ind) {
         if (confirm('Are you sure?')) {
            this.calculatedColumns.splice(ind, 1);
            this.addCalCol()
         }
      },

      calColFixMenuChanged(listItem, item) {
         // alert('in calColFixMenuChanged(): listItem='+JSON.stringify(listItem)+'\nitem='+JSON.stringify(item));
         item.fix.menu = false;
         item.fix.list = listItem.value;
         if (listItem.value === 'none')
            item.fix.text = listItem.text;
         else if (item.fix.text === this.calColFixItems.find(cc => cc.value === 'none').text)
            item.fix.text = '';

         this.setCalColFixHintAndPlaceholder(item.fix);
         this.$refs[`fix_${item.name}`][0].focus();
      },

      setCalColFixHintAndPlaceholder(fix) {
         // alert('in setCalColFixHintAndPlaceholder(): fix=' + JSON.stringify(fix));
         if (fix.list === 'none')
            fix.hint = 'No pre/postfix';
         else if (fix.list === 'pre')
            fix.hint = 'Prefix';
         else if (fix.list === 'post')
            fix.hint = 'Postfix';
         else
            fix.hint = 'Pre/Postfix';

         if (fix.list === '')
            fix.placeholder = 'select an option';
         else if (fix.list === 'pre')
            fix.placeholder = 'specify the prefix';
         else if (fix.list === 'post')
            fix.placeholder = 'specify the postfix';
         else
            fix.placeholder = '';
      },

      calColPositionMenuChanged(listItem, item) {
         item.position = {
            menu: false,
            list: listItem.value,
            text: ['b','a'].includes(listItem.value) ? '' : listItem.text
         };

         this.setCalColPositionHintAndPlaceholder(item.position);
         this.$refs[item.name][0].focus();
         // alert(JSON.stringify(item));
      },

      setCalColPositionHintAndPlaceholder(position) {
         position.hint = 'Position';
         if (position.list === 'b')
            position.hint += ': Before the above column';
         else if (position.list === 'a')
            position.hint += ': After the above column';

         if (position.list === '')
            position.placeholder = 'select an option';
         else if (position.list === 'b')
            position.placeholder = 'specify the before column name';
         else if (position.list === 'a')
            position.placeholder = 'specify the after column name';
         else
            position.placeholder = '';

         // alert(JSON.stringify(position));
      },

      cancelCalCol() {
         this.$refs.formCalCol.resetValidation();
         this.dialogCalCol = false;
      },

      saveCalCol() {
         if (this.$refs.formCalCol.validate()) {
            if (!this.calculatedColumns[this.calculatedColumns.length - 1].name)
               this.calculatedColumns.pop();

            this.cancelCalCol();
            this.calculatedColumns.forEach(calCol => {
               calCol.fix = calCol.fix.list + '::' + (['pre','post'].includes(calCol.fix.list) ? calCol.fix.text : '');
               calCol.position = calCol.position.list + '::' + (['b','a'].includes(calCol.position.list) ? calCol.position.text : '');
            });

            this.$emit('input', JSON.stringify(this.calculatedColumns));
         }
      }
   },

   created() {
      // alert(`in ${NAME}.create()`);
   }
}
</script>
