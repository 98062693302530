/*jshint esversion: 6 */
// V221011.1: Removed 'hasApiAccess' from params and the constructor.
class Identity {
   // constructor(email, userName, days, daysEffect, hours, hoursEffect, cidr, hasApiAccess) {
   constructor(email, userName, days, daysEffect, hours, hoursEffect, cidr) {
         this._id = '';
      this.aid = '';
      this.accessToAllSubAccounts = false;
      this.groups = [];
      this.apiCredentials = [];

      this.email = email || '';
      this.userName = userName || '';
      this.days = days || [];
      this.daysEffect = daysEffect || '';  //in | nin
      this.hours = hours || [];
      this.hoursEffect = hoursEffect || '';  //in | nin
      this.cidr = cidr || '';
      // this.hasApiAccess = hasApiAccess || false;
   }
}

//export { Identity, Credential };
export { Identity };