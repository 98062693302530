<template>
  <nav>

    <v-app-bar app>
      <!-- <v-toolbar-side-icon @click="drawer = !drawer" class="grey--text"></v-toolbar-side-icon> -->
      <v-app-bar-nav-icon @click="drawer = !drawer" class="grey--text"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="font-weight-light">MindFire</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- dropdown menu -->
      <v-menu offset-y><!-- offset-y opens menu underneath of the btn -->
        <v-btn  color="grey"> <!-- v-slot="activator" -->
          <v-icon left>expand_more</v-icon>
          <span>Menu</span>
        </v-btn>
        <v-list>
          <v-list-tile v-for="link in links" :key="link.text" router :to="link.route">
            <v-list-tile-title>{{link.text}}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>

      <v-btn color="grey">
        <span>Sign Out</span>
        <v-icon right>exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer app permanent expand-on-hover
      class="grey pt-5"
      v-model="drawer"
   >
        <v-layout column align-center>
           <v-flex class="mt-5">
            <v-avatar size="100">
                <img src="../assets/mindfire_logo.png" alt="">
            </v-avatar>
            <p class="white--text subheading mt-1">MindFireInc</p>
            </v-flex>
        </v-layout>
`

      <v-list>
        <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
          <v-list-item-action>
            <v-icon class="white--text">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      
    </v-navigation-drawer>


                <!-- <v-list-item v-for="item in items" :key="item.title" link :to="getLink(item.route)">
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->



  </nav>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      links: [
        { icon: 'dashboard', text: 'Dashboard', route: '/' },
        { icon: 'settings', text: 'Identity & Access Management', route: '/iam' }
      ]
    }
  }
}
</script>

<style>

</style>
