<template>
  <div class="projects">
    <h1 class="subheading grey--text">Projects</h1>

    <v-container class="my-5">
      <v-expansion-panel>
        <v-expansion-panel-content v-for="project in myProjects" :key="project.title">
          <div slot="header" class="py-1">{{ project.title }}</div>
          <v-card>
            <v-card-text class="px-4 grey--text">
              <div class="font-weight-bold">Due by {{ project.due }}</div>
              <div>{{ project.content }}</div>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-container>
    
  </div>
</template>

<script>

export default {
  data() {
    return {
      projects: [
        {person:'Amir Asghari',title:'Studio Web', due:'12/31/2019', content:'This is Studio Web project.', status:'complete'},
        {person:'Reza Memarian',title:'Identity Service', due:'12/31/2019', content:'This is Identity Serviceproject.', status:'ongoing'},
        {person:'Reza Memarian',title:'Edge Service', due:'12/31/2019', content:'This is Edge Service project.', status:'overdue'},
      ]
    }
  },
  computed: {
    myProjects() {
      return this.projects.filter(project => {
        return project.person === 'Reza Memarian' && project.status != 'complete'
      })
    }
  }//,
  // created() {
  //   db.collection('projects').onSnapshot(res => {
  //     const changes = res.docChanges();

  //     changes.forEach(change => {
  //       if (change.type === 'added') {
  //         this.projects.push({
  //           ...change.doc.data(),
  //           id: change.doc.id
  //         })
  //       }  
  //     })
  //   })
  // }
}
</script>
