<template>
  <v-container  :style="{ height: height + 'px' }" max-height="height + 100" max-width="100%">
    <v-row justify="space-around" style="height:100%;">
      <v-card :loading=loading style="width:100%;height:100%;">
        <template slot="progress">
            <v-progress-linear
                indeterminate
            ></v-progress-linear>
        </template>   
        <v-img style="height:30%;"
          height="30%"
          xsrc="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg"
          :src=src
          :position=position
        >
          <v-card-title class="white--text mt-8" style="height:100%;">
            <!--<v-avatar size="56">
              <img
                alt="user"
                src="https://cdn.pixabay.com/photo/2020/06/24/19/12/cabbage-5337431_1280.jpg"
              >
            </v-avatar>-->
            <p class="ml-3">
               {{title}}
            </p>
          </v-card-title>
        </v-img>

        <v-card-text style="overflow-y:auto;height:60%;margin-top:10px;">
          <div class="font-weight-bold ml-8 mb-2">
            {{tinelineTitle}}
          </div>

          <v-timeline v-if="listData.length > 0" 
            align-top
            dense
          >
            <v-timeline-item
              v-for="item in listData" :key="item.title"
              :color="item.color"
              small
            >
              <div>
                <div><a style="text-decoration:none" :href="item.url" target="blank">{{item.title}}</a></div>
                <div class="font-weight-normal">
                  <span v-if="item.subtitle">{{item.subtitle}}</span><span v-if="item.lastUpdate">@{{fromNow(item.lastUpdate)}}</span>
                </div>
                
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import utils from '../services/KCUtils.js'
export default {
    name: "Kwang-VCardList",
    props: {
        height: {
            type: Number,
            default: 300,
        },
        title: {
            type: String,
            default: 'TITLE',
        },
        tinelineTitle: {
            type: String,
            default: 'Today',
        },
        listData: {
            type: Array,
        },
        src: {
            type: String,
        },
        position: {
            type: String,
            default: "center center",
        },
        loading: {
            default: false,
        },
        /*consumerParam: {
            type: Object,
        },
        showExpandButton: {
            default: false,
        },*/
    },
    computed: {
    },
    methods: {
        fromNow: utils.fromNow,
        /*expand(evt){
            this.expanded=!this.expanded;
            this.$emit("expand",this.consumerParam,evt);
        }*/
    },
    created() {
    },
    mounted() {
    },
    beforeUnmount() {
    },  
    watch: {
    },
    data() {
        return {
            expanded: false,
        }
    },
}
</script>

