<template>
    <v-dialog v-model="dialog" :persistent=persistent width="400">
        <v-container style="height: 100%; width: 100%;background: white;">
            <v-card>
                <v-img height="150" :src=image></v-img>
                <v-card-title  class="py-3" style="color: gray;">
                    {{title}}
                </v-card-title>
                <v-card-text >
                    <template v-if="accounts.length == 0">
                        <v-row>
                            <v-text-field v-model="email" prepend-icon="person" label="Email"></v-text-field>
                        </v-row>
                        <v-row v-if="flgTwoFactor==false">
                            <v-text-field :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" v-model="inputPassword" prepend-icon="lock" label="Password"></v-text-field>
                        </v-row>
                        <v-row v-if="flgTwoFactor">
                            <v-text-field :type="showTwoFactorCode ? 'text' : 'password'" @click:append="showTwoFactorCode = !showTwoFactorCode" :append-icon="showTwoFactorCode ? 'mdi-eye' : 'mdi-eye-off'" v-model="twoFactorCode" prepend-icon="lock" label="Code from email"></v-text-field>
                        </v-row>
                        <v-row v-if="loginFail">
                            <v-col style="color:red;" class="pl-8 py-0" cols="12"><span >Login fail</span></v-col>
                        </v-row>
                    </template>
                    <template v-else>
                        <v-row>
                            <v-autocomplete v-model="account" :items="accounts" item-text="Value" item-value="Key" prepend-icon="home" label="Account"></v-autocomplete>
                        </v-row>
                    </template>
                </v-card-text>
                <v-card-actions style="padding-top: 0;padding-bottom: 0;margin-top: -11px;">
                    <!-- {{sameAcct}} -->
                    <v-switch v-if="accounts.length == 0" v-model="sameAcct" :label=sameAccountText></v-switch>
                    <v-spacer></v-spacer>
                    <v-btn v-if="accounts.length == 0" :disabled="email=='' || inputPassword==''" color="gray" @click="login(email,inputPassword)">Log in</v-btn>
                    <v-btn v-else color="gray" @click="select(account)">Select</v-btn>
                </v-card-actions>
                <template v-if="credential && accounts.length == 0">
                    <v-card-actions>
                        <v-spacer></v-spacer>or<v-spacer></v-spacer>
                    </v-card-actions>
                    <v-divider style="margin-top:-18px;" class="mx-4"></v-divider>
                    <v-card-actions class="py-5">
                        <v-spacer></v-spacer><v-btn color="gray" @click="credentialLogin()"><v-icon>fingerprint</v-icon></v-btn><v-spacer></v-spacer>
                    </v-card-actions>
                </template>
            </v-card>
        </v-container>
    </v-dialog>
</template>
<style>
</style>
<style scoped>
</style>
<script>
 import axios from "axios";
 import utils from '../services/KCUtils.js'
//  import KCGoogleAutoComplete from "../components/KCGoogleAutoComplete.vue";
var crypto = require('crypto');

const NAME = "KCPushMessageHistory";

const serverMode="pass";
let config = utils.getServerConfig(serverMode);
import kcMixins from "../services/KCMixins.js";
import csvUtils from "../services/KCCSVUtils.js";
import { consoleError } from "vuetify/lib/util/console";

export default {
    name: NAME,
    mixins: [kcMixins],   
    components: {
    },
    props: {
        value: {
            default: false,
        },
        persistent: {
            default: false,
        },
        title: {
            default: "MindFire Sign in",
        },
        sameAccountText: {
            default: "Same Account",
        },
        removePassword: {
            default: false,
        },
        image: {
            default: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQnDJq_Z-46Sd0eTQnrYT0GeO2POKzIIKz3hr0x8UzAkz7pMKes",
        }
    },
    data() {
        return {
            /* mixins start */
            appName: "generic",
            /* mixins end */            
            davinciUrl: null,
            dialog: false,
            email:"",
            password:"",
            showPassword: false,
            showTwoFactorCode: false,
            loginFail: false,
            sameAcct: false,
            account: null,
            accounts:[],
            accountsx: [
                {
                    "Key": "31546",
                    "Value": "Autoloyalty - Stage"
                },
                {
                    "Key": "32464",
                    "Value": "MFI Test 112321 ALL Sub1"
                },
                {
                    "Key": "32477",
                    "Value": "MFI Test Sub1 112921 ALL"
                },
                {
                    "Key": "32479",
                    "Value": "MFINC Test 112921 Sub2"
                },
                {
                    "Key": "32503",
                    "Value": "MFI Test 120221 Sub01"
                },
            ],
            credential:null,  // will be override when mounted
            flgTwoFactor: false,
            twoFactorCode: "",
            inputPassword: "",
        };
    },
    computed: {
        // account: function () {
        //     //console.log('user object: ', this.$store.getters.user);
        //     return this.$store.getters.user.account;
        // },
    },
    methods: {
        async clear(){
            this.$store.commit('setUser',"");
            this.accounts = [];
        },
        async login(email,password){
            try{
                let ret = null;
                this.loginFail = false;
                this.accounts = [];
                if(this.flgTwoFactor){
                    console.log("login 2 factor",email,this.twoFactorCode);
                    ret = await this.ListServiceLoginWithCode(email,this.twoFactorCode);
                }else{
                    this.twoFactorCode = "";
                    console.log("login",email,password);
                    ret = await this.ListServiceLogin(email,password);
                }
                // console.log("login",ret);
                if(ret){
                    if(ret.isTwoFactor){
                        this.flgTwoFactor = true;
                    }
                    if(ret && ret.token){
                        this.accounts = ret.accounts;
                        this.password = password;
                        if(localStorage){
                            localStorage.setItem("ListServiceLogin",JSON.stringify({
                                email: email,
                                password: password,
                                account: this.account,
                                sameAcct: this.sameAcct,
                            }));
                        }
                        // set basic JWT
                        this.ListServiceSetAxiosToken(ret.token);
                        this.$store.commit('setAccounts',ret.accounts);
                        if(this.sameAcct && this.account !=null){
                            console.log("auto select account",this.account);
                            //make sure we have selected account in list
                            let found = ret.accounts.filter(a=> a.Key == this.account);
                            if(found.length > 0){
                                await this.select(this.account);
                            }
                        }
                        
                    }
                }
            }catch(ex){
                this.loginFail = true;
            }
        },
        async select(account){
            this.account = account;
            // this.dialog = false;
            let ret = await this.ListServiceSelectAccount(account);
            // console.log("select",ret);
            if(ret && ret.token){
                if(localStorage){
                    localStorage.setItem("ListServiceLogin",JSON.stringify({
                        email: this.email,
                        password: this.password,
                        account: this.account,
                        sameAcct: this.sameAcct,
                    }));
                }
                // set final JWT
                console.log("select set final JWT for account",account);
                this.ListServiceSetAxiosToken(ret.token);
                this.$store.commit('setUser',ret.user);
                this.dialog = false;
                console.log("select close dlg");
                this.$emit('loginSuccess',ret);
            }
        },
        isLogin(){
            return this.account != null;
        },
        async createWebAuthCredential(force = false){
            // make it already login
            if(!this.isLogin()){
                throw new Error("Not login");
            }
            // check if we have credential for this ID
            if(this.credential!=null && force == false){
                throw new Error("credential exist");
            }
            var randomID = new Uint8Array(32);
            window.crypto.getRandomValues(randomID);
            const base64RandomID = btoa(String.fromCharCode.apply(null, randomID));
            await this.createWebAuthCredentialEx(base64RandomID,"MindFire, Inc",this.account,this.email)
            return this.credential;
        },
        async createWebAuthCredentialEx(base64id,rpName,accountName,email){
            var randomChallengeBuffer = new Uint8Array(32);
            window.crypto.getRandomValues(randomChallengeBuffer);
            var idBuffer = Uint8Array.from(window.atob(base64id), c=>c.charCodeAt(0))
            var publicKey = {
                challenge: randomChallengeBuffer,
                rp: { name: rpName },
                user: {
                    id: idBuffer,
                    name: email,
                    displayName: email,
                },
                attestation: 'direct',
                authenticatorSelection: {
                    requireResidentKey: true,
                },                
                pubKeyCredParams: [
                    { type: 'public-key',  alg: -7  }, // ES256
                    { type: 'public-key', alg: -257 }  // RS256
                ]
            }
            let newCredentialInfo = await navigator.credentials.create({ publicKey });
            this.newCredentialInfo = newCredentialInfo;
            console.log(newCredentialInfo);
            //correct credential
            const uint8Array = new Uint8Array(newCredentialInfo.rawId);
            const base64String = window.btoa(String.fromCharCode.apply(null, uint8Array));
            let credential = {
                id: base64String,
            } 
            console.log(base64String);
            if(localStorage){
                console.log("createWebAuthCredential",credential);
                localStorage.setItem("credential",JSON.stringify(credential));
                this.credential = credential;
            }         
        },
        async authenWebAuthCredential(){
            // make it already login
            if(!this.isLogin()){
                throw new Error("Not login");
            }
            // check if we have credential for this ID
            if(this.credential==null){
                throw new Error("credential not exist");
            }
            console.log("authenWebAuthCredential",this.credential);
            var randomChallengeBuffer = new Uint8Array(32);
            window.crypto.getRandomValues(randomChallengeBuffer);
            var base64id = this.credential.id;
            var idBuffer = Uint8Array.from(window.atob(base64id), c=>c.charCodeAt(0))            
            const publicKeyCredentialRequestOptions = {
                challenge: randomChallengeBuffer,
                allowCredentials: [{
                    id: idBuffer,
                    type: 'public-key',
                    transports: ['internal'],
                }],
                timeout: 60000,
            }
            try{
                const assertion = await navigator.credentials.get({
                    publicKey: publicKeyCredentialRequestOptions
                });
                console.log(assertion);
                return assertion;
            }catch(ex){
                console.log(ex);
                throw new Error("Authen fail");
            }  
        },
        async credentialLogin(){
            var ret = await this.authenWebAuthCredential();
            await this.login(this.credentailLogin.email,this.credentailLogin.password);
        }
    },
    created() {
        this.davinciUrl = this.$store.getters.deployment.dvURL;
        // alert(`in created(): davinciUrl=${this.davinciUrl}`);
        if (this.davinciUrl.indexOf("davincistage") >= 0)
            this.server = "stage";
        else
            this.server = "prod";
        // this.endPoint = config.endPoints.mindfireOne + `/api/card/${this.server}`;
    },
    destroyed() {
    },
    async mounted() {
        this.dialog = this.value;
        if(localStorage){
            let lslogin = localStorage.getItem("ListServiceLogin");
            if(lslogin){
                try {
                   let data = JSON.parse(lslogin);
                   console.log(data);
                   Object.assign(this,data);
                   this.credentailLogin = data;
                }catch(e) {
                    console.log(e)
                }
                let credential = localStorage.getItem("credential");
                if(credential){
                    this.credential = JSON.parse(credential);
                }
                // if(this.removePassword){
                //     // remove password
                //     this.password = "";
                // }
            }
        }        
        // this.email = this.$store.getters.user.email;
    },
    beforeUnmount() { 
    },
    watch: {
        value (newValue,oldValue){
            this.dialog = newValue;
        },
        account: {
            async handler(val) {
            },
            deep: true
        },
        dialog(newValue,oldValue){
            this.$emit('input', newValue)
        }
    },
};
</script>