<!-- HISTORY:
   V230915.1: 1st release (copied file from AsExports.vue V230906.2).
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">cloud_download</v-icon>
            <span>Downloads</span>
         </h1>
      </v-card-title>

      <v-card-text class="ml-2 px-5 pb-0">
         <v-form lazy-validation
            ref="mainForm"
            v-model="isMainFormValid"
         >
            <v-row>
               <v-col xs="12" sm="12" md="3" class="py-2">
                  <bt-date-picker
                     :debug="debug"
                     :dd-custom-label="true"
                     dd-label="select a date range..."
                     :dd-monthly-items="datePickerMonthlyItems"
                     dd-value="TM"
                     :dp-no-title="false"
                     :dp-max-range="31"
                     v-model="dateRange"
                     @date-range-change="dateRangeChanged"
                  ></bt-date-picker>
               </v-col>
               <v-col xs="12" sm="12" md="2" class="py-5 mt-1">
                  <v-btn small
                     color="primary"
                     :disabled="!isCriteriaChanged"
                     @click="getReports()"
                  >Get Report
                     <v-icon right dark>summarize</v-icon>
                  </v-btn>
               </v-col>
            </v-row>
         </v-form>
      </v-card-text>

      <v-card-text>
         <v-tabs
            class="elevation-2"
            background-color="grey lighten-2 accent-4"
            slider-color="black"
            v-model="tab"
         >
            <v-tab>Totals</v-tab>
            <v-tab>List</v-tab>

            <v-tabs-items v-model="tab">
               <v-tab-item class="pt-5"><!-- Totals -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-text-field clearable
                           class="py-0"
                           append-icon="search"
                           label="Search"
                           :disabled="!totalItems.length"
                           v-model="searchString"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header
                     class="elevation-1"
                     :footer-props="footerPropsTotals"
                     :headers="totalHeaders"
                     :hide-default-footer="totalItems.length <= 5"
                     :items="totalItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsTotals"
                     :search="searchString"
                     @current-items="tableCurrItems"
                  >
                     <template v-slot:[`item.totalDownloadSize`]="{ item }">
                        <v-tooltip left color="black">
                           <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                 {{formatSize(item.totalDownloadSize)}}
                              </span>
                           </template>
                           <span>{{formatNumber(item.totalDownloadSize)}} bytes</span>
                        </v-tooltip>
                     </template>
                     <template v-slot:[`body.append`] v-if="totalItemsTotal">
                        <tr class="font-weight-bold">
                           <td>Totals:</td>
                           <td></td>
                           <td></td>
                           <td></td>
                           <!-- <td align="right">{{formatSize(totalItemsTotal)}}</td> -->
                           <td align="right">
                              <v-tooltip left color="black">
                                 <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                       {{formatSize(totalItemsTotal)}}
                                    </span>
                                 </template>
                                 <span>{{formatNumber(totalItemsTotal)}} bytes</span>
                              </v-tooltip>
                           </td>
                        </tr>
                     </template>
                  </v-data-table>
               </v-tab-item>

               <v-tab-item class="pt-5"><!-- List -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-autocomplete clearable dense hide-selected
                           append-icon="search"
                           placeholder="search for a specific account"
                           :items="accounts"
                           v-model="selectedAccount"
                           @change="getListReport"
                        ></v-autocomplete>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header show-expand single-expand
                     class="elevation-1"
                     item-key="_id"
                     :expanded.sync="expandedItems"
                     :footer-props="footerPropsList"
                     :headers="listHeaders"
                     :hide-default-footer="listItemsCount.length <= 5"
                     :items="listItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsList"
                     :server-items-length="listItemsCount"
                  >
                     <template v-slot:[`item.size`]="{item}">
                        <v-tooltip top color="black">
                           <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                 {{formatSize(item.size)}}
                              </span>
                           </template>
                           <span>{{formatNumber(item.size)}} bytes</span>
                        </v-tooltip>
                     </template>
                     <template v-slot:[`item.createdAt`]="{ item }">
                        <span style="white-space:pre">{{ formatDate(item.createdAt) }}</span>
                     </template>
                     <template v-slot:[`item.deletedAt`]="{ item }">
                        <span style="white-space:pre">{{ formatDate(item.deletedAt) }}</span>
                     </template>
                     <template v-slot:expanded-item="{ item }">
                        <td>&nbsp;</td>
                        <td colspan="5" class="py-2" valign="top" dense>
                           <ul class="pl-3">
                              <li>
                                 <span class="expanded-header">ID: </span>
                                 <span class="expanded-content">{{item._id}}</span>
                              </li>
                           </ul>
                        </td>
                        <td colspan="3" class="py-2" valign="top" dense>
                           <ul class="pl-3">
                              <li>
                                 <span class="expanded-header">File Size: </span>
                                 <span class="expanded-content">{{formatNumber(item.size)}} bytes</span>
                              </li>
                           </ul>
                        </td>
                     </template>
                  </v-data-table>
               </v-tab-item>
            </v-tabs-items>
         </v-tabs>
      </v-card-text>
   </v-card>
</v-container>
</template>

<script>
import BtDatePicker from "./BtDatePicker.vue";
import { APIService } from '../services/as-api-service.js';
import { format, parseISO } from "date-fns";
// import { sleep } from '../mixins/bt-mixin.js';

const NAME = "AsDownloads";
const TABS = { totals: 0, list: 1 };
function _compareItems (e1, e2) {
   return e1.text > e2.text ? 1 : -1;
}

export default {
   name: NAME,

   components: {
      BtDatePicker
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         datePickerMonthlyItems: [
            { text: "Current Month", value: "TM" },
            { text: "Last Month", value: "LM" }
         ],
         totalHeaders: [
            { text: 'Account Name', value: 'accountname', align: 'left', sortable: true },
            { text: 'Account ID', value: 'accountid', align: 'left', sortable: true },
            { text: 'Parent Name', value: 'parentname', align: 'left', sortable: true },
            { text: 'Parent ID', value: 'parentid', align: 'left', sortable: true },
            { text: 'Total Download Size', value: 'totalDownloadSize', align: 'right', sortable: true }
         ],
         listHeaders: [
            { text: 'Account Name', value: 'accountname', align: 'left', sortable: false },
            { text: 'Account ID', value: 'accountId', align: 'left', sortable: false },
            { text: 'Parent Name', value: 'parentname', align: 'left', sortable: false },
            { text: 'Parent ID', value: 'parentid', align: 'left', sortable: false },
            { text: 'Name', value: 'name', align: 'left', sortable: false },
            { text: 'Size', value: 'size', align: 'right', sortable: false },
            { text: 'Creation Date', value: 'createdAt', align: 'left', sortable: false },
            { text: 'Deletion Date', value: 'deletedAt', align: 'left', sortable: false }
         ],
         apiService: null,
         isMainFormValid: false,
         isCriteriaChanged: false,
         dateRange: [],
         tab: null,
         loading: false,
         searchString: '',
         optionsTotals: {},
         lastItemsPerPage: 10,
         footerPropsTotals: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         totalItems: [],
         totalItemsTotal: 0,
         footerPropsList: {
            itemsPerPageOptions: [5, 10, 15, 20],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         optionsList: {},
         listItemsCount: 0,
         listItems: [],
         expandedItems: [],
         accounts: [],
         selectedAccount: 0
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      }
   },

   watch: {
      token() {
         this.init();
      },

      async tab(val) {
         if (val === TABS.list && !this.listItemsCount && this.totalItems.length) {
            await this.getListReport();
            const accounts = [];
            this.totalItems.forEach(item => {
               accounts.push({
                  text: `${item.accountname}: ${item.accountid}`,
                  value: item.accountid
               });
            });
            this.accounts = accounts.sort(_compareItems);
         }
      },

      optionsList: {
         handler(val) {
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
            }
            this.getListItems();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V230915.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         // _alert('in init(): token=' + this.token);
         if (this.token) {
            this.apiService = new APIService(this.token, this.debug, this.isActualEndpoint, '/api/bi/v1/admins/downloads');
            setTimeout(() => {
               this.getTotalItems();
            }, 100);
         }
      },

      dateRangeChanged(val) {
         // alert(`in dateRangeChange(): val=${JSON.stringify(val)}`);
         this.isCriteriaChanged = true;
      },

      async getTotalItems() {
         // alert(`in getTotalItems(): dateRange=${JSON.stringify(this.dateRange)}`);
         this.loading = true;
         this.totalItems = [];
         let result = await this.apiService.getTotalDownloads(this.dateRange);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.totalItems = result.data;
         }

         this.isCriteriaChanged = false;
         this.loading = false;
      },

      formatSize(size) {
         if (size < 1024)
            return `${size} bytes`;
         else if (size < 1048576)
            return this.formatNumber(size / 1024) + ' KB';
         else if (size < 1073741824)
            return this.formatNumber(size / 1048576) + ' MB';
         else
            return this.formatNumber(size / 1073741824) + ' GB';
      },

      formatNumber(number, maxFractionDigits) {
         return new Intl.NumberFormat('en-US', 
            { 
               maximumFractionDigits: maxFractionDigits || 0
            }).format(number);
      },

      tableCurrItems(val) {
         this.totalItemsTotal = 0;
         if (this.searchString) {
            if (!this.lastItemsPerPage) {
               this.lastItemsPerPage = this.optionsTotals.itemsPerPage;
               this.optionsTotals.itemsPerPage = -1;
               this.footerPropsTotals.disableItemsPerPage = true;
            }
            if (val && val.length) {
               val.forEach(v => {
                  this.totalItemsTotal += v.totalDownloadSize;
               });
            }
         } else {
            if (this.lastItemsPerPage) {
               this.footerPropsTotals.disableItemsPerPage = false;
               this.optionsTotals.itemsPerPage = this.lastItemsPerPage;
               this.lastItemsPerPage = 0;
            }
            this.totalItems.forEach(ti => {
               this.totalItemsTotal += ti.totalDownloadSize;
            });
         }
      },

      async getListItemsCount() {
         this.loading = true;
         let result = await this.apiService.getListDownloadsCount(this.dateRange, this.selectedAccount);
         if (result.logout)
            this.logout();

         this.listItemsCount = result.message ? 0 : result.data;
         this.optionsList.page = 1;
      },

      async getListItems() {
         // alert(`in getListItems(): dateRange=${JSON.stringify(this.dateRange)}`);         this.loading = true;
         this.listItems = [];
         this.expandedItems = [];
         if (this.listItemsCount) {
            let result = await this.apiService.getListDownloads(this.dateRange, this.selectedAccount, this.optionsList.page, this.optionsList.itemsPerPage);
            if (result.logout)
               this.logout();
               
            if (!result.message) {
               this.listItems = [];
               result.data.forEach(d => {
                  //NOTE: keep == as accountid is number but accountId is string.
                  const totItem = this.totalItems.find(item => item.accountid == d.accountId);
                  d.accountname = totItem.accountname;
                  d.parentname = totItem.parentname;
                  d.parentid = totItem.parentid;
                  this.listItems.push(d);
               });
            }
         }

         this.isCriteriaChanged = false;
         this.loading = false;
      },

      async getReports() {
         await this.getTotalItems();
         this.listItemsCount = 0;
         this.listItems = [];
         if (this.totalItems.length && this.tab === TABS.list) {
            await this.getListReport();
         }
      },

      formatDate(date) {
         if (date) {
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            // return formatteddate.replace(' ', '\n');
            return formatteddate;
         }
      },

      async getListReport() {
         await this.getListItemsCount();
         await this.getListItems();
      }
   },

   created() {
      this.init();
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>