// import Vue from 'vue'
// import Vuetify from 'vuetify/lib'
// import 'vuetify/src/stylus/app.styl'
// import {Ripple} from 'vuetify/lib/directives'

// Vue.use(Vuetify, {
//   directives: {
//     Ripple
//   },
//   iconfont: 'md',
//   theme: {
//     primary: '#808080',
//     success: '#3cd1c2',
//     info: '#ffaa2c',
//     error: '#f83e70'
//   }
// })

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

//Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
    theme: {
      primary: '#808080',
      success: '#3cd1c2',
      info: '#ffaa2c',
      error: '#f83e70'
    }
  }
});
