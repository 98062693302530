<template>
    <v-container ref="container" class="pass-view-ui pb-0" style="height: 100%; width: 100%;background: white;">
        <!-- {{ useMongoDB }} -->
        <!-- {{ selected }} -->
        <!-- {{ passDataTable.items }} -->
        <!-- <v-row v-if="adminMode">
            <v-col cols="3">
                <v-text-field clearable hide-details v-model="config.definition.addThisPassKey" label="AddThisPass Key">
                </v-text-field>
            </v-col>
            <v-col cols="1">
                <v-btn v-if="config.definition.addThisPassKey==''" @click="saveConfig()">Add</v-btn>
                <v-btn v-else @click="saveConfig()">Update</v-btn>
            </v-col>
        </v-row>       -->
        <v-row v-if="showHeader" class="mt-0">
            <v-col class="pt-2" cols=9>
                <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
                    <span><v-icon @click="qrcodeClick()">qr_code</v-icon></span>
                    <span style="vertical-align: middle;" class="pl-1">My Passes 
                        <!-- <span v-if="useMongoDB">*</span> -->
                        <!-- <KCSearchableSelect @select="searchableSelect" attachID="btn" v-model="menu"></KCSearchableSelect> -->
                        <!-- {{allowAPIAccess}} -->
                    </span>
                </h1>
            </v-col>
            <v-col cols=3 :style=colStyle class="text-right">
                <!-- <span v-if="config._id == undefined" style="margin-top: 15px;color: red;" class="text-right">
                    No pass key
                </span>                 -->
                <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on}">
                        <v-btn v-on="on" small class="btn-icon mt-1 mr-2" @click="refreshTaskClick()">                        
                            <v-icon>
                                refresh
                            </v-icon>
                        </v-btn>
                    </template>
                    Reload my pass
                </v-tooltip>
                <v-tooltip v-if="config.definition.addThisPassKey != '' || useMongoDB==false" top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on}">
                        <v-btn v-on="on" small class="btn-icon mt-1 mr-2" @click="addNewPassClick(false)">                        
                            <v-icon>
                                add
                            </v-icon>
                        </v-btn>
                    </template>
                    Create new pass
                </v-tooltip>
            </v-col>          

        </v-row>
        <v-row class="py-0" style="min-height:323px;" >
            <v-col :cols=colSize>
                <v-data-table v-model="selected" :loading="passDataLoading" dense :hide-default-footer="false" :headers="passDataTable.headers" :items="passDataTable.items" 
                    :items-per-page=effectiveItemPerPage
                    :footer-props="{
                        'items-per-page-options': effectiveCurrentPerPageOptions
                    }"
                    @click:row="passDataTableRowClick" :single-select="true" show-select
                    sort-by="updatedAt"
                    sort-desc
                    class="elevation-1">
                        <template v-slot:[`item.updatedAt`]="props">
                            <div>{{ fromNow(props.item.updatedAt)}}</div>
                            <!-- {{props.item.lastModified}} -->
                        </template>
                        <template v-slot:[`item.enable`]="props">
                            <v-switch @click.native.stop class="my-1" v-model="props.item.enable" xcolor="success" hide-details>
                            </v-switch>
                        </template>
                        <template v-slot:[`item.action`]="props">
                            <td class="text-center" style="min-width: 105px;">
                           <!-- <v-tooltip v-if="readOnlyMode==false  && replayMode==false" top>
                              <template v-slot:activator="{ on , attrs}">
                           <v-icon v-bind="attrs" v-on="on" :disabled="readOnlyMode == true" v-if="props.index != 0"  class="mr-2" @click="doAction('up', props)">
                              arrow_drop_up
                           </v-icon>
                              </template>
                              <span>Move up</span>
                           </v-tooltip>
                           <v-tooltip v-if="readOnlyMode==false  && replayMode==false" top>
                              <template v-slot:activator="{ on , attrs}">
                                 <v-icon v-bind="attrs" v-on="on" :disabled="readOnlyMode == true" v-if="props.index != configItems.length - 1"  class="mr-2"
                                       @click="doAction('down', props)">
                                       arrow_drop_down
                                 </v-icon>
                              </template>
                              <span>Move down</span>                                
                           </v-tooltip>
                           <v-tooltip  v-if="props.item.error && replayMode==false" top>
                              <template v-slot:activator="{ on, attrs }">
                                 <v-icon v-bind="attrs" v-on="on" color="red" small class="mr-2"
                                       @click="doAction('edit', props)">
                                       mode
                                 </v-icon>
                              </template>
                              <span>{{ props.item.error }}</span>
                           </v-tooltip>-->
                           <v-tooltip  top hide-on-click hide-on-hover open-on-hover>
                              <template v-slot:activator="{ on, attrs }">
                                 <v-icon   :disabled="props.item.enable==false"  v-bind="attrs" v-on="on" small class="mr-2" @click="editPassClick(props)">
                                       mode
                                 </v-icon>
                              </template>
                              <span>Edit</span>
                           </v-tooltip>
                           <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                              <template v-slot:activator="{ on , attrs}">
                                 <v-icon  :disabled="props.item.enable==false"  v-bind="attrs" v-on="on" small class="mr-2" @click="pushMessageClick(props)">
                                       send
                                 </v-icon> 
                              </template>
                              <span>Send message</span>
                           </v-tooltip>
                           <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                              <template v-slot:activator="{ on , attrs}">
                                 <v-icon @click.native.stop v-bind="attrs" v-on="on" small class="mr-2" @click="deletePassClick(props)">
                                       delete
                                 </v-icon> 
                              </template>
                              <span>Remove</span>
                           </v-tooltip>
                           <!-- <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                              <template v-slot:activator="{ on , attrs}">
                                 <v-icon  v-bind="attrs" v-on="on" small class="mr-2" @click="testRegisterPassClick(props)">
                                    arrow_drop_up
                                 </v-icon> 
                              </template>
                              <span>Register</span>
                           </v-tooltip>                            -->
                            </td>
                        </template>                        
                    </v-data-table>
            </v-col>
            <Transition name="preview" v-on:leave="previewLeave(true)"  v-on:after-leave="previewLeave(false)">
            <v-col v-show="selected.length > 0" cols=3 style="max-height:323px;">
                <template >
                    <KCCardBuilderView :useMongoDB=useMongoDB style="transform-origin: top left;transform: scale(0.58);" ref="cardBuilderPreview" :loadPassCommand=loadPassCommand :templateName=taskName :editMode=editMode :readOnlyMode=readOnlyMode @canSave="(n)=> shouldShowWalletPassSaveButton = n" :headers="headers" :sampleData="sampleData" :hideCardType=true :debugMode=false :autoLoadDefault=true :viewMode=true :lightMode=true></KCCardBuilderView>
                </template>
            </v-col>
            </Transition>
        </v-row>  
        <v-dialog content-class="cardDlg" persistent height="656px" :width=1080 v-model="cardBuilderDlg">
            <v-sheet style="padding-left: 15px;padding-top: 5px;">
                    <KCCardBuilderView showQRCode="true" :qrcodeUrl=qrcodeUrl :allowAPIAccess=allowAPIAccess :apiCredentials=apiCredentials ref="cardBuilder" :loadItems=passDataTable.items :useMongoDB=useMongoDB :loadPassCommand=loadPassCommand :templateName=taskName :editMode=editMode :readOnlyMode=readOnlyMode @canSave="(n)=> shouldShowWalletPassSaveButton = n" :headers="headers" :sampleData="sampleData" :hideCardType=true :debugMode=false :autoLoadDefault=true :guests=config.definition.guests></KCCardBuilderView>
                    <v-row style="width:98%;margin-top:-30px;padding-bottom:10px;" class="justify-end">
                    <v-btn color="blue darken-1" text @click="cardBuilderDlg = false">
                        Close
                    </v-btn>
                    <v-btn v-if="shouldShowWalletPassSaveButton && (readOnlyMode==false)" color="blue darken-1" text  @click="savePassClick">
                        Save
                    </v-btn>
                    <v-btn v-if="shouldShowWalletPassSaveButton && (readOnlyMode==true && editMode==true)" color="blue darken-1" text  @click="updateWallet()">
                        Update
                    </v-btn>
                    </v-row>                
            </v-sheet>        
        </v-dialog>
        <v-dialog content-class="pushMessageDlg" persistent :width=1080 height="656px" v-model="pushMessageDlg">
            <v-sheet style="padding-left: 15px;padding-top: 5px;">
                    <JWPushMessage ref="pushMessage" @createPushMessage="createPushMessage" @autoCloseDlg="autoCloseDlg" :showCreateAction=showCreateAction></JWPushMessage>
                    <v-row style="width:98%;margin-top:-40px;" class="justify-end">
                    <v-btn color="blue darken-1" text @click="pushMessageDlg = false">
                        Close
                    </v-btn>
                    <!-- <v-btn v-if="shouldShowWalletPassSaveButton && (readOnlyMode==false)" color="blue darken-1" text  @click="savePassClick">
                        Save
                    </v-btn>
                    <v-btn v-if="shouldShowWalletPassSaveButton && (readOnlyMode==true && editMode==true)" color="blue darken-1" text  @click="updateWallet()">
                        Update
                    </v-btn> -->
                    </v-row>                
            </v-sheet>        
        </v-dialog>
        <!-- <KCGoogleAutoComplete  ref="address"
      id="map"
      classname="form-control"
      placeholder="Please type your address"
      v-on:placechanged="getAddressData"
      label="Address"
      country="sg"></KCGoogleAutoComplete> -->
      <!-- <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY_HERE&libraries=places&callback=initMap"></script> -->
    </v-container>
</template>
<style>
.theme--light.v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.2) !important;
}
</style>
<style>
  /* make dialog overflow-y visible */
  .cardDlg {
    overflow-y: auto;
    height: 670px;
    overflow-x: hidden;
  }
  .pushMessageDlg  {
    overflow-y: hidden;
    height: 661px;
    overflow-x: hidden;
  }
  .pushMessageDlg .v-sheet {
    height: 100%;
  }
  .action-class {
    padding-left:0px !important;
    padding-right:0px !important;
  }
    .preview-enter {
        opacity: 0.2;
    }
    .preview-enter-to {
        opacity: 0.8;
    }

    .preview-leave-to {
        opacity: 0.4;
    }
    .preview-leave-active {
        transition: opacity 0.3s ease-in;
    }    

    .preview-enter-active {
        transition: opacity 0.3s ease-in;
    }  
</style>
<script>
import axios from "axios";
import utils from '../services/KCUtils.js';
import addThisPass from "../services/KCAddThisPass.js";
import KCCardBuilderView from '../views/KCCardBuilderView.vue';
import JWPushMessage from "./JWPushMessage.vue"

//  import KCGoogleAutoComplete from "../components/KCGoogleAutoComplete.vue";
var crypto = require('crypto');

const NAME = "KCPassUIView";

const serverMode="pass";
let config = utils.getServerConfig(serverMode);
import csvUtils from "../services/KCCSVUtils.js";

import kcMixins from "../services/KCMixins.js";
// import { debug } from "console";
var defaultConfig = {
    definition: {
        addThisPassKey: "",
        guests: [],
        guestTags: []            
    }
}
export default {
    name: NAME,
    mixins: [kcMixins],    
    components: {
        KCCardBuilderView,
        JWPushMessage,
        // KCGoogleAutoComplete,
    },
    props: {
        useMongoDB: {
            default: true,
        },
        passUIEndpoint: {
          type: String,
          default: utils.getPassUIEndpoint()
        },
        showCreateAction: {
            default: false,
        },        
        mode: {
            type: String,
            default: "",    // admin
        },
        itemsPerPage: {
            default: 5,
        },
        showHeader: {
            default: true,
        },
        perPageOptions: {
            default: function(){
                return [5, 10, 15, 20];
            }
        }
    },
    data() {
        return {
            /* mixins start */
            appName: "passUI",
            /* mixins end */
            config: defaultConfig,
            adminMode: this.mode=="admin",
            adminModeCount: 0,
            selected: [],
            endPoint: null,
            davinciUrl: null,
            server: null,
            passDataTable: {
                headers: [
                    // { text: "ID", value: "id", sortable: true },
                    { text: "Name", value: "name", sortable: true,},
                    { text: "LastModified", value: "updatedAt", sortable: true,width: '150px' },
                    // { text: "Enable", value: "enable", sortable: false },                    
                    { text: "Action", value: "action", sortable: false,cellClass:'action-class', align: 'center', },

                ],
                items: []
            },
            passDataLoading: false,
            //showLoadDialog: false,
            shouldShowWalletPassSaveButton: true,
            cardBuilderDlg: false,
            pushMessageDlg: false,
            readOnlyMode: false,
            editMode: false,
            headers: [],
            sampleData: [],
            taskName: "",
            loadPassCommand: null,
            // this will be override by getConfig();
            // walletPassConfig: {
            //     "version": 0,
            //     "addThisPassScanUrl":"https://addthiscard.com/?pass=2X9ZOg==@{_id}{exportId}{actionId}{purl}&a=scan{purl}&a=scan"   
            // },
            previewLeaving: false,
            currentItemPerPage: this.itemsPerPage,
            currentPerPageOptions: [... this.perPageOptions],
            qrcodeUrl: null,
            allowAPIAccess: false,
            apiCredentials: {},
        };
    },
    computed: {
        effectiveCurrentPerPageOptions: function(){
            return this.currentPerPageOptions;
        },
        effectiveItemPerPage: function(){
            // if(this.$refs.container){
            //     let height = this.$refs.container.clientHeight;
            //     console.log("height",height);
            // }
            return this.currentItemPerPage;
        },
        account: function () {
            //console.log('user object: ', this.$store.getters.user);
            return this.$store.getters.user.account;
        },
        colSize: function(){
            return this.selected.length >0 || this.previewLeaving ? 9:12;
        },
        colStyle: function() {
            if(this.selected.length >0){
                return {
                    "max-width": "215px",
                }
            }else{
                return {
                    "max-width": "unset",
                }
            }
        },
    },
    methods: {
        redraw(){
            let back = this.passDataTable.items;
            this.passDataTable.items = [];
            this.$nextTick(() => {
                this.passDataTable.items = back;
            });
        },
        onResize() {
            console.log("onResize",window.innerWidth,window.innerHeight);
            this.windowHeight = window.innerHeight;
            this.windowWidth = window.innerWidth;
            // this.$nextTick(() => {
                if(window.innerHeight < 600){
                    if(this.currentItemPerPage > 5){
                        this.currentItemPerPage = 5;
                        this.redraw();
                    }
                    this.currentPerPageOptions = [3,5];
                }else if(window.innerHeight < 760){
                    if(this.currentItemPerPage > 10){
                        this.currentItemPerPage = 10;
                        this.redraw();
                    }
                    this.currentPerPageOptions = [5,10];
                }else{
                    this.currentPerPageOptions = [... this.perPageOptions];
                }
            // });
            // let height = this.$refs.container.clientHeight;
            // console.log("height",height);
        },
        // getAddressData: function (addressData, placeResultData, id) {
        //     console.log(addressData,placeResultData,id);
        // },    
        async previewLeave(value){
            console.log("previewAfterLeave",value);
            this.previewLeaving = value;
        },
        async qrcodeClick(){
            this.adminModeCount = (this.adminModeCount + 1)%5
            this.adminMode = this.adminModeCount == 4;
        },    
        async refreshTaskClick(){
            if(this.selected.length>0){
                this.populatePass(this.selected[0]._id);
            }else{
                this.populatePass();
            }
        },
        async addNewPassClick(){
            this.taskName = ""; //"New Pass " + utils.UTCDateTime();
            this.cardBuilderDlg = true;
            this.$nextTick(() => {
               if(this.$refs.cardBuilder){
                  this.qrcodeUrl = null;
                  this.$refs.cardBuilder.loadByDefault();
               }
            });
            this.editingID = null;
        },
        async editPassClick(props){
            //console.log(props);
            this.cardBuilderDlg = true;
            this.qrcodeUrl = `https://addthiscard.com/?id=${props.item.passKey}&a=scan`;
            // console.log('editPassClick', this.config.definition.guestTags);
            if (this.config.definition.guestTags.length > 0) this.qrcodeUrl = `https://addthiscard.com/?pass=${props.item.passKey}@${this.config.definition.guestTags[0]}&a=scan`;
            if (this.allowAPIAccess) this.apiCredentials.passKey = props.item.passKey;
            this.$nextTick(() => {
                if(this.useMongoDB){
                    //this.$refs.cardBuilder.loadByID(props.item.id);
                    this.$refs.cardBuilder.loadByCardData(props.item.id,props.item.data);
                }else{
                    this.$refs.cardBuilder.loadByID(props.item.id);
                }
            });
            this.editingID = props.item.id;
        },
        async pushMessageClick(props){
            if (props.item.passKey == '') {
                this.$swal.fire('Pass Key not found.');
                return;
            }
            this.pushMessageDlg = true;
            this.$nextTick(() => {
               this.$refs.pushMessage.showPass(this.config.definition.addThisPassKey,props.item.passKey,props.item.id,props.item.name,props.item.data);
            });
        },
        async deletePassClick(props){
            var result = await this.$swal.fire({
                title: 'Are you sure you want to delete the pass?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                // confirmButtonColor: '#3085d6',
                // cancelButtonColor: '#d33',
                // confirmButtonText: 'Yes',
                // cancelButtonText: 'No',
                ... csvUtils.getSwalYesNoOption()
            });
            console.log(result);
            if (result.isConfirmed) {
                console.log("deletePassClick",props.item);
                let atpRet = await addThisPass.cardSuspension(props.item.addThisPassKey, props.item.passKey, {suspend: 'yes'}); //
                console.log("atpRet", atpRet);
                if (atpRet.data.ERROR) {
                    result = await this.$swal.fire({
                        title: 'We cannot delete your pass.',
                        text: atpRet.data.ERROR,
                        icon: 'error'
                    });
                    return;
                }
                if(this.useMongoDB){
                    console.log("deletePassClick",props.item.id);     
                    await this.appUIDelete(props.item.id);
                    this.populatePass();
                }
                // await this.deleteTaskStatus(Id);
                // await this.loadTaskStatus();
                this.dialog = false;
                // this.$emit("close");
            }        
        },
        async savePassClick(){
            // console.log(this.$refs.cardBuilder.getCardData());
            let cardData = this.$refs.cardBuilder.getCardData();
            let personalizeMessage = cardData.resource.templateData.backsideMessage.value;
            if (!this.checkAnchorTagHtml(personalizeMessage)) {
                let result = await this.$swal.fire({
                    text: 'Please provide <a> tag with single quote (\') in Personalized Message on the Backside field.',
                    icon: 'warning',
                });
                return;
            }
            let more = cardData.passData.eventTicket.backFields[3].value;
            if (!this.checkAnchorTagHtml(more)) {
                let result = await this.$swal.fire({
                    text: 'Please provide <a> tag with single quote (\') in More field.',
                    icon: 'warning',
                });
                return;
            }
            console.log("Saving Mode",this.editingID);
            if(this.editingID){
                this.showProgressSwal("Updating pass","<span></span>",()=>{},false);
            }else{
                this.showProgressSwal("Creating new pass","<span></span>",()=>{},false);
            }
            //this.$swal.showLoading();
            let saveRet = await this.$refs.cardBuilder.saveClick();
            console.log("savePassClick","saveRet",saveRet);
            let passJSON = saveRet.definition;
            //let id = saveRet._id;
            if(saveRet){
                this.updateProgressSwal("Registering pass");
                //console.log("savePassClick","registering pass");
                var registerRet = await this.registerPass(passJSON);
                console.log("registerRet: ", registerRet);
                if (registerRet && !registerRet.success) {
                    this.closeProgressSwal();
                    let result = await this.$swal.fire({
                        title: 'We are not able to register your pass.',
                        text: "Please click here to export your pass, and import it later.",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Export',
                    });
                    if (result.isConfirmed) {
                        this.$refs.cardBuilderPreview.exportClick();
                    }
                    return;
                }
                if(registerRet){
                    if(registerRet.passKey != passJSON.resource.dbFields.passKey || passJSON.resource.dbFields.addThisPassKey == undefined){
                        passJSON.resource.dbFields.passKey = registerRet.passKey;
                        passJSON.resource.dbFields.addThisPassKey = this.config.definition.addThisPassKey;
                        this.updateProgressSwal("Storing pass");
                        // console.log("savePassClick","updating storage");
                        await this.appUIUpdate(saveRet._id,passJSON);
                    }
                }
                await this.populatePass(saveRet._id);
                // close dialog
            }
            //this.$swal.close();
            this.closeProgressSwal();
            this.cardBuilderDlg = false;            
            return;
        },
        checkAnchorTagHtml(str) {
            // Regular expression to match anchor tags with URLs in single quotes
            const anchorTagRegex = /<a\s+href='(http:\/\/|https:\/\/)[^']*'[^>]*>.*?<\/a>/gi;
            
            // Check and remove valid anchor tags
            let cleanedStr = str;
            let match;
            
            // Loop through all anchor tags to remove them
            while ((match = anchorTagRegex.exec(cleanedStr)) !== null) {
                cleanedStr = cleanedStr.replace(match[0], '');
            }
            
            // Regular expression to match any remaining HTML tags
            const otherTagsRegex = /<[^>]*>/;
            
            // Check if there are any remaining HTML tags
            return !otherTagsRegex.test(cleanedStr);
        },
        async testRegisterPassClick(props){
            console.log("testRegisterPassClick",props);
            let passJSON = props.item.definition;
            return await this.registerPass(passJSON);
        },
        async updateWallet(){

        },
        fromNow(date){
            return utils.fromNow(date);
        },
        async registerPass(passJSON){
            //console.log("registerPass",addThisPass.addThisPassAccountKey[this.account]);
            let passKey = passJSON.resource.dbFields.passKey?passJSON.resource.dbFields.passKey:"";
            if(passKey.indexOf("FAKEKEY")==0){
                passKey="";
            }
            var ret = await addThisPass.createPass(this.config.definition.addThisPassKey,passKey,passJSON,this.config.definition.guestTags);
            return ret;
        },
        async passDataTableRowClick(param){
            console.log("passDataTableRowClick",param);
            this.selected = [param];
            //this.showPass();
            // this.$nextTick(() => {
            //    this.$refs.cardBuilder.loadByID(param.id);
            // });
            
            // this.showLoadDialog = false;
        },
        async showPass(){
            if(this.selected.length>0){
                this.$nextTick(() => {
                    if(this.useMongoDB){
                        this.$refs.cardBuilderPreview.loadByCardData(this.selected[0].id,this.selected[0].data)
                    }else{
                        this.$refs.cardBuilderPreview.loadByID(this.selected[0].id);
                    }
                });
            }
        },
        clear(){
            this.passDataTable.items = [];
        },
        async populatePassJWT(id){
            console.log("populatePassJWT",id);
            var hasConfig = await this.loadConfig();
            if(hasConfig == null){
                this.passDataTable.items = [];
                this.selected = [];
                return;
            }
            //this.showLoadDialog = true;
            this.passDataLoading = true;
            var ret = await this.appUIGet(this.app.passUI.passData/*,{"definition.resource.dbFields.passKey":"FAKEKEY2"}*/);
            
            // var ret = await axios.post(this.endPoint, {
            //     cmd: "dbGetByAccount",
            //     acctID: this.dbgAccountID?35917:this.$store.getters.user.account,
            // });            
            this.passDataLoading = false;
            console.log(ret);
            // if(ret){
            //     return;
            // }
            // // selected
            // let selectedID = null;
            // if("instruction" in this.value){
            // if("selected" in this.value.instruction){
            //     selectedID = this.value.instruction.selected.id;
            // }
            // }
            // filter that table if in add this pass mode
            this.passDataTable.items = [];
            this.selected = [];
            for(var x in ret){
                console.log(ret[x]);
                let tmp = {... ret[x], id: ret[x]._id, data:  ret[x].definition, name: ret[x].name};
                // ret[x].id = ret[x]._id;
                // ret[x].data = ret[x].definition;
                // ret[x].name = ret[x].definition.resource.templateData.templateName;
                if("dbFields" in ret[x].definition.resource){
                    tmp = {...tmp, ...ret[x].definition.resource.dbFields};
                }
                console.log(tmp);
                if(tmp.passKey != undefined){
                    this.passDataTable.items.push(tmp);   
                    if(tmp._id == id){
                        this.selected = [tmp];
                    }
                }
                // if(ret.data.result[x].status == null){
                //     //make sure we have passkey
                //     if(ret.data.result[x].passKey != null){
                //         ret.data.result[x].enable = true;
                //         this.passDataTable.items.push(ret.data.result[x]);   
                //         // if(selectedID == ret.data.result[x].id){
                //         //     this.selected = [ret.data.result[x]];
                //         // }
                //     }
                // }else{
                //     ret.data.result[x].enable = false;
                // }
            }
            // update selected if have
            if(this.selected.length > 0){
                this.showPass();
            }
            //this.clearSelected();
        },
        async populatePass(id){
            if(this.useMongoDB){
                return this.populatePassJWT(id);
            }
            //this.showLoadDialog = true;
            this.passDataLoading = true;
            var ret = await axios.post(this.endPoint, {
                cmd: "dbGetByAccount",
                acctID: this.account,
            });            
            this.passDataLoading = false;
            console.log(ret);
            // // selected
            // let selectedID = null;
            // if("instruction" in this.value){
            // if("selected" in this.value.instruction){
            //     selectedID = this.value.instruction.selected.id;
            // }
            // }
            // filter that table if in add this pass mode
            this.passDataTable.items = [];
            for(var x in ret.data.result){
                //console.log(ret.data.result[x]);
                if(ret.data.result[x].status == null){
                    
                    //make sure we have passkey
                    if(ret.data.result[x].passKey != null){
                        ret.data.result[x].enable = true;
                        this.passDataTable.items.push(ret.data.result[x]);   
                        // if(selectedID == ret.data.result[x].id){
                        //     this.selected = [ret.data.result[x]];
                        // }
                    }
                }else{
                    ret.data.result[x].enable = false;
                }
            }
        },   
        async loadConfig(){
            if(this.useMongoDB){
                var config = await axios.get(this.passUIEndpoint+`?cmd=getPassKey&stage=${this.server}`);
                console.log("loadConfig", config.data.result);
                //let configs = await this.appUIGet(this.app.passUI.config);
                //console.log("loadConfig",config);
                this.config = defaultConfig;
                if(config == null || config.data.result == undefined){
                    return null;
                }
                if("passKey" in config.data.result){
                    this.config = JSON.parse(JSON.stringify(defaultConfig));
                    this.config.definition.addThisPassKey = config.data.result.passKey;
                    this.config.definition.guests = config.data.result.guest;
                    let guests = config.data.result.guest;
                    for (let x in guests) {
                        this.config.definition.guestTags.push(guests[x]._id+guests[x].gurl);
                    }
                    let allowAPIAccessEmail = config.data.result.allowAPIAccessEmail;
                    let loginEmail = this.$store.getters.user.email;
                    console.log("allowAPIAccessEmail", allowAPIAccessEmail, loginEmail);
                    if (allowAPIAccessEmail.includes(loginEmail)) {
                        this.allowAPIAccess = true;
                        this.apiCredentials = await this.getAPICredentials(config.data.result.acctID);
                        console.log("apiCredentials", this.apiCredentials);
                    } else {
                        this.allowAPIAccess = false;
                        this.apiCredentials = {};
                    }
                    return true;
                }
                return false;
                // if(configs.length > 0){
                //     this.config = configs[0];
                //     return true;
                // }else{
                //     this.config = defaultConfig;
                //     return false;
                // }
            }
            return true;
        },
        // async saveConfig(){
        //     if(this.useMongoDB){
        //         if(this.config._id == undefined){
        //             await this.appUIPost(this.app.passUI.config,"config",this.config.definition);
        //         }else{
        //             await this.appUIUpdate(this.config._id,this.config.definition);
        //         }
        //         await this.loadConfig();
        //     }
        // },
        clearSelected(){
            this.selected = [];
        },
        createPushMessage(data){
            console.log("createPushMessage",data);
            this.$emit("createPushMessage",data);
        },
        autoCloseDlg(){
            console.log("autoCloseDlg");
            this.pushMessageDlg = false;
        },
        async getAPICredentials(acctID) {
            console.log("getAPICredentials stage: "+this.server+" , acctID: "+acctID);
            let ret = await axios.get(this.passUIEndpoint+`?cmd=getMaster&stage=${this.server}`);
            let masterData = ret.data.result.definition.data;
            // console.log("getAPICredentials masterData: ", masterData);
            if(masterData.length > 0){
                for(let x in masterData){
                    if(masterData[x].acctID == acctID){
                        return {
                            "mfi-aid": masterData[x].acctID,
                            "mfi-paid": masterData[x].parentAcctID,
                            "mfi-accesskey": masterData[x].accessKey,
                            "mfi-secretkey": masterData[x].secretKey
                        };
                    }
                }
            }
            return {};
        }
    },
    created() {
        this.davinciUrl = this.$store.getters.deployment.dvURL;
        // alert(`in created(): davinciUrl=${this.davinciUrl}`);
        if (this.davinciUrl.indexOf("davincistage") >= 0)
            this.server = "stage";
        else
            this.server = "prod";
        this.endPoint = config.endPoints.mindfireOne + `/api/card/${this.server}`;
    },
    destroyed() {
    },
    async mounted() {
        // this.$nextTick(() => {
        //     window.addEventListener('resize', this.onResize);
        // }) 
        this.populatePass();
        // inject script 
        // if(window.google == undefined){
        //     let API_KEY="AIzaSyA-DomT9kwu5eyp2J0of7DWAABiW6y4V5g";
        //     const recaptchaScript = document.createElement("script");
        //     recaptchaScript.setAttribute(
        //         "src",
        //         `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=initMap`
        //     );
        //     document.head.appendChild(recaptchaScript);
        //     window.initMap = (x) => {
        //         console.log("initMap",x);
        //         this.$nextTick(() => {
        //             this.$refs.address.init();
        //         });
        //     }
        // }else{
        //     this.$nextTick(() => {
        //         this.$refs.address.init();
        //     });
        // }
    },
    beforeUnmount() { 
    },
    watch: {
        account: {
            async handler(val) {
                //this.getAllData();
                //this.getHomePageData();
                this.selected = [];
                await this.populatePass();
                
            },
            deep: true
        },
        useMongoDB: {
            async handler(val) {
                //this.getAllData();
                //this.getHomePageData();
                this.selected = [];
                await this.populatePass();
            },
        },
        selected(newValue,old){
            // console.log("selected",newValue,old);
            if(old.length > 0 && newValue.length > 0){
                console.log("selected",old[0].id,newValue[0].id);
                // if(old[0].id == newValue[0].id){
                //     return;
                // }
            }
            this.showPass();
            this.$emit("selected",newValue);
        }
    },
};
</script>