<template>
   <!-- TODO:
   -->
   <!-- WHAT: The UI for building the exports field definitions -->
   <!-- HISTORY:
      V240229.1: Set allCsvFields in the watch instead of created() to fix the issue that didn't update headers when the account was changed +
         Added index to the hints to show their sequence.
      V230711.1: Made definition of custom fields mandatory + Set rules inside Field class.
      V230627.2: Modified predefinedOptionChanged() to exclude event_data from prepopulated fields.
      V230627.1: Included event_data to the field names and added required logics to support it.
      V230323.1: Added hasEvents prop to add prepopulate options with event fields condionally.
      V230315.1: Removed rules from field definition and passed empty array if has no value.
      V230202.1: Excluded system fields from prepopulated fields + Replaced hasOwnProperty() with hasOwn() from mixins.
      V221007.1: Made Label / Title disabled for system (internal & event) fields + Set focus() to the next field +
         Made columns equal (from 3x2+6 to 3x4).
      08/19/22(B0.1): 1st release.
   -->
<div>
   <v-tooltip left>
      <template v-slot:activator="{ on }">
         <v-btn text icon
            v-on="on"
            @click="btnClicked"
         >
            <v-icon>{{btnIconName}}</v-icon>
         </v-btn>
      </template>
      <span>{{btnTooltip}}</span>
   </v-tooltip>

   <v-dialog no-click-animation persistent scrollable
      max-width="1280px"
      v-model="dialog"
   >
      <v-card flat>
         <v-card-title class="title grey--text darken-4 font-weight-bold">Field Definitions
            <div class="flex-grow-1"></div>
            <v-select dense persistent-hint
               autocomplete="off"
               hint="Prepopulated Option"
               placeholder="select an option to populate related fields automatically"      
               :items="predefinedOptions"
               v-model="selectedPredefinedOption"
               @change="predefinedOptionChanged"
            ></v-select>
         </v-card-title>
         <v-card-text class="px-4 pb-2">
            <v-form lazy-validation
               ref="form"
               v-model="isFormValid"
            >
               <v-row v-for="(item, i) in fields" :key="i">
                  <v-col xs="12" sm="12" md="3" class="py-0">
                        <!-- :placeholder="`field name ${i + 1}`" -->
                     <v-autocomplete dense persistent-hint
                        :ref="`value_${i}`"
                        autocomplete="off"
                        :hint="`Field Name ${i+1}`"
                        :items="getFields(i)"
                        v-model="item.value"
                        @change="fieldNameChanged(item, i)"
                     >
                        <template v-slot:prepend>
                           <v-icon
                              class="py-2"
                              :color="removeIconColor"
                              :disabled="!item.value"
                              @click="removeField(i)"
                           >{{removeIconName}}</v-icon>
                        </template>
                     </v-autocomplete>
                  </v-col>
                  <v-col xs="12" sm="12" md="3" class="py-0" v-if="item.value">
                     <v-combobox v-if="item.value === 'event_data'"
                        dense persistent-hint required
                        :ref="`label_${i}`"
                        autocomplete="off"
                        :hint="`Label / Title ${i+1}`"
                        :items="eventDataFields"
                        :rules="[rules.required]"
                        v-model="item.label"
                        @change="fieldLabelChanged(item)"
                     ></v-combobox>
                     <v-text-field v-else
                        dense persistent-hint required
                        :ref="`label_${i}`"
                        autocomplete="off"
                        :hint="`Label / Title ${i+1}`"
                        :disabled="isSystemField(item.value)"
                        :rules="[rules.required]"
                        v-model="item.label"
                     ></v-text-field>
                  </v-col>
                  <v-col v-if="item.value"
                     xs="12" sm="12" md="3" class="py-0"
                  >
                     <v-text-field dense persistent-hint required
                        :ref="`definition_${i}`"
                        autocomplete="off"
                        :hint="`Definition ${i+1}`"
                        :disabled="isDefinitionDisabled(item)"
                        :rules="item.rules"
                        v-model="item.format"
                     ></v-text-field>
                  </v-col>
                  <v-col v-if="item.value"
                     xs="12" sm="12" md="3" class="py-0"
                   >
                     <v-text-field dense persistent-hint
                        :ref="`default_${i}`"
                        autocomplete="off"
                        :hint="`Default Value ${i+1}`"
                        v-model="item.default"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>
         <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn text small
               class="mx-0 pl-0 pr-2"
               color="blue darken-1"
               @click="resetClicked"
            >Reset</v-btn>
            <v-btn text small
               class="mx-0 px-0"
               color="blue darken-1"
               @click="cancelClicked"
            >Cancel</v-btn>
               <!-- :disabled="isFormValid" -->
            <v-btn text small
               class="mx-0 pr-4"
               color="blue darken-1"
               @click="submitClicked"
            >Submit</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>


   <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
   </v-overlay>
</div>
</template>

<script>
import { hasOwn, getEventDataFields } from '../mixins/bt-mixin.js';

const NAME = "BtExportFieldDefiner";

class Field {
   // constructor(initVal, rules) {
   constructor(initVal, fields, eventDataFields, rules) {
      // alert('in Field(): initVal=' + JSON.stringify(initVal));
      this.rules = [];
      if (initVal) {
         this.label = initVal.label;
         this.default = initVal.default;
         if (initVal.value) {
            this.format = initVal.value;
            if (fields.find(f => f.value === initVal.value))
               this.value = initVal.value;
            else {
               this.value = 'event_data';
               const edField = eventDataFields.find(f => f.value === initVal.value);
               if (edField)
                  this.label = edField;
               else
                  this.rules.push(rules.required);
            }
         } else {
            this.value = '__custom__';
            this.format = initVal.format[0];
            this.rules.push(rules.required);  //V230711.1
         }
      } else {
         this.label = '';
         this.value = '';
         this.format = '';
         this.default = '';
      }
   }
}

export default {
   name: NAME,

   props: {
      /*
      [
         { label: 'Car', value: 'car', default: ''},
         { label: 'Color', value: 'color', default: ''},
         { label: 'Price', value: 'price', default: ''},
         { label: 'Comment', format: ['The {color} {car} worth ${price}', 'color', 'car', 'price'], default: ''}
      ]
      */
      value: {
         type: Array,
         default: () => []
      },
      btnIconName: {
         type: String,
         default: "more_vert"
      },
      btnTooltip: {
         type: String,
         default: "click to define fields"
      },
      debug: {
         type: Boolean,
         default: false
      },
      hasEvents: {
         type: Boolean,
         default: true
      },
      items: {
         type: Array,
         required: true,
         default: () => []
      },
      max: {
         type: Number,
         default: Number.MAX_VALUE
      },
      removeIconName: {
         type: String,
         default: "delete_forever"
      },
      removeIconColor: {
         type: String,
         default: "grey darken-1"
      },
      //TODO: is required?
      required: {
         type: Boolean,
         default: false
      },
      // shouldInit: {
      //    type: Boolean,
      //    default: false
      // }
   },

   data() {
      return {
         rules: {
            required: value => !!value || 'Value is required!',
            // expression: value => this.findVariables(value).length > 0 || 'Value is invalid!',
            // expression: value => {
            //    const vars = this.findVariables(value);
            //    if (vars.length === 0) return 'At least one field is required!';
            //    const invalidVars = [];
            //    vars.forEach(v => {
            //       if (!this.items.find(item => item.value === v))
            //          invalidVars.push(v);
            //    });
            //    const len = invalidVars.length;
            //    if (len === 1)
            //       return  `'${invalidVars[0]}' is not a valid field!`;
            //    else if (len > 1)
            //       return  `'${invalidVars.join(', ')}' are not valid fields!`;
            //    else
            //       return true;
            // },
            duplicate: value => this.fields.filter(f => f.label.toLowerCase() === value.toLowerCase()).length <= 1 || 
               'Value is duplicate!',
            same: value => value === this.selectedPredefined.text || "Name doesn't match!",
         },
         dialog: false,
         isFormValid: true,
         fields: [],
         // filterOut: '',
         overlay: false,
         allCsvFields: [], //V240229: null,
         predefinedOptions: [
            { text: 'All CSV Fields + PURL', value: 'purl' },
            { text: 'All CSV Fields + PURL & ID', value: 'purl+_id' },
            // { text: 'All CSV Fields + PURL & Event Fields', value: 'purl+event' },
            // { text: 'All CSV Fields + PURL, ID & Event Fields', value: 'purl+_id+event' }
         ],
         selectedPredefinedOption: null,
         eventDataFields: getEventDataFields(),
      }
   },

   computed: {
   },

   watch: {
      items: {
         immediate: true,
         deep: true,
         handler (val) {
            //alert('in watch: items=' + JSON.stringify(val));
            this.allCsvFields = val.filter(item => !hasOwn(item, 'internalField') && !hasOwn(item, 'systemField') && !hasOwn(item, 'isEventField'));
         }
      },
   },

   methods: {
      log(msg, always =false) {
         if (this.debug || always) {
            console.log(`-----${NAME} V240229.1 says => ${msg}`);
            // alert(`${NAME} V240229.1 says:\n${msg}`);
         }
      },

      btnClicked() {
         // alert('in btnClicked(): this.value=' + JSON.stringify(this.value));
         // this.myMax = Math.min(this.items.length, this.max);

         this.fields = [];
         this.selectedPredefinedOption = '';
   
         this.value.forEach(v => {
            this.fields.push(new Field(v, this.items, this.eventDataFields, this.rules));
         });

         this.addField();
         // alert('in btnClicked(): this.fields=' + JSON.stringify(this.fields));

         this.dialog = true;
         this.isFormValid = true;
      },

      predefinedOptionChanged(option) {
         this.fields = [];
         const extraFields = [];
         const optionParts = option.split('+');
         //alert(JSON.stringify(optionParts))
         //alert(JSON.stringify(this.items))
         //alert(JSON.stringify(this.allCsvFields))
         optionParts.forEach(op => {
            switch (op) {
               case 'purl':
               case '_id':
                  extraFields.push(this.items.find(item => item.value === op));
                  break;
               case 'event':
                  extraFields.push(...this.items.filter(item => item.isEventField && item.isIndexed));
                  break;
               default:
                  break;
            }
         });
        
         [...this.allCsvFields, ...extraFields].forEach(element => {
            const newField = new Field();
            newField.value = element.value;
            this.fieldNameChanged(newField);
            this.fields.push(newField);
         });

         this.addField();
      },

      fieldNameChanged(item, index) {
         let ref;
         if (item.value === '__custom__' || item.value === 'event_data') {
            // this.$refs[`label_${index}`].focus();
            item.label = '';
            item.format = '';
            // item.rules = [this.rules.required, this.rules.expression];
            item.rules = [this.rules.required]; //V230711.1
            ref = 'label';
         } else {
            // this.$refs[`default_${index}`].focus();
            const selectedItem = this.items.find(i => i.value === item.value);
            item.label = selectedItem.text;
            item.format = selectedItem.value;
            item.rules = [];  //V230711.1
            ref = 'default';
         }

         //V230711.1: item.rules = item.value === 'event_data' ? [this.rules.required] : [];

         if (index >= 0) {
            this.addField();
            const self = this;
            this.$nextTick(() => {
               self.$refs[`${ref}_${index}`][0].focus();
            });
         }
      },

      fieldLabelChanged(item) {
         // alert('in fieldLabelChanged(): item=' + JSON.stringify(item) + '\n\n' + typeof item.label);
         if (item.label) {
            if (typeof item.label === 'string') {
               if (!item.format)
                  item.format =  item.label;
            } else
               item.format =  item.label.value;
         }
      },

      isDefinitionDisabled(item) {
         if (item.value === '__custom__')
            return false;
         else if (item.value === 'event_data') {
            return typeof item.label === 'object'
         } else
            return true;
      },

      findVariables(expression) {
         const startMark = "{";
         const endMark = "}";
         const regEx = /{[^{]+}/g;
         const matches = expression.match(regEx) || [];
         // console.log('matches=' + JSON.stringify(matches));
         const vars = [];

         matches.forEach(match => {
            const key = match.replace(startMark, '').replace(endMark, '');
            // console.log('match=' + match + ', key=' + key);
            if (key)
               vars.push(key);
         });

         // console.log('vars=' + JSON.stringify(vars));
         return vars;
      },

      getFields(ind) {
         if (!this.dialog) return [];


         const remainingFields = [];
         if (this.fields)
            this.items.forEach(item => {
               if (
                     item.value === this.fields[ind].value ||
                     this.fields.filter(f => f.value === item.value).length === 0 ||
                     item.value === 'event_data'
                  )
                  remainingFields.push(item);
            });

         return [...remainingFields, {text: 'Custom...', value: '__custom__'}];
      },

      addField() {
         // alert('in addField(): fields=' + JSON.stringify(this.fields) + '\nmax=' + this.max);
         const len = this.fields.length;
         if (len === 0 || (this.fields[len - 1].value && len < this.max)) {
            this.fields.push(new Field());
         }

         // alert(JSON.stringify(this.filters));
      },

      removeField(ind) {
         if (confirm('Are you sure?')) {
            this.fields.splice(ind, 1);
            this.addField();
            this.selectedPredefinedOption = '';
         }
      },

      isSystemField(fieldValue) {
         const currItem = this.items.find(item => item.value === fieldValue);
         return currItem && (currItem.internalField || currItem.isEventField);
      },

      resetClicked() {
         if (confirm('This action will remove all the field definitions. Are you sure?')) {
            this.fields = [];
            this.selectedPredefinedOption = '';
            this.addField();
         }
      },
    
      cancelClicked() {
         // this.$emit('fields-cancel');
         this.dialog = false;
      },

      submitClicked() {
         this.isFormValid = this.$refs.form.validate();
         // alert('in submitClicked(): fields=' + JSON.stringify(this.fields));

         if (this.isFormValid) {
            const fieldsOut = [];
            for (let index = 0; index < this.fields.length - 1; index++) {
               const element = this.fields[index];
               const fld = {};
               if (element.value === 'event_data') {
                  fld.label = typeof element.label === 'string' ? element.label : element.label.text;
                  fld.value = element.format;
               } else {
                  fld.label = element.label;
                  if (element.value === '__custom__') {
                     if (element.format) {
                        const variables = this.findVariables(element.format);
                        fld.format = [element.format, ...variables];
                     } else
                        fld.format = [];
                  } else
                     fld.value = element.value;
               }

               fld.default = element.default;
               fieldsOut.push(fld);
            }
            
            this.log('in submitClicked(): fieldsOut=' + JSON.stringify(fieldsOut));
            this.$emit('change', fieldsOut);
            this.$emit('input', fieldsOut);
            this.cancelClicked();
         }
      }
   },

   created() {
      // console.error(`in ${NAME}.created(): items=${JSON.stringify(this.items)}`);
      if (this.hasEvents) {
         this.predefinedOptions.push({ text: 'All CSV Fields + PURL & Event Fields', value: 'purl+event' });
         this.predefinedOptions.push({ text: 'All CSV Fields + PURL, ID & Event Fields', value: 'purl+_id+event' });
      }
      // this.allCsvFields = this.items.filter(item => !hasOwn(item, 'internalField') && !hasOwn(item, 'systemField') && !hasOwn(item, 'isEventField'));
   },

   mounted() {
      // alert(`in ${NAME}.mounted()`);
   }
}
</script>