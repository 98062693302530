<template>
<!-- HISTORY:
   09/14/21(B0.5): Changed this.preferences to this.user to resolve saving issue in store +
      Made changes to be able to save preferences from my local without changing the code.
   09/14/21(B0.4): Changed rd-preferences v-model to pass the entire object.
   09/10/21(B0.3): Fixed the bug that passed wrong v-model.
   09/10/21(B0.2): Prevented acces to unauthorized users.
   09/10/21(B0.1): 1st release.
-->
   <rd-preferences v-if="jwt.pu || jwt.pa"
      :text-area-rows="2"
      v-model="user.preferences"
      @change="savePreferences"
   ></rd-preferences>
   <v-container v-else>
      <div class="red--text">
         Sorry, you should be a power user in order to access this page!
      </div>
   </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import axios from 'axios';
import RdPreferences from "./RdPreferences.vue";

export default Vue.extend({
   components: { RdPreferences },

   props: {
      debug: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         jwt: {},
         user: {},
         $http: null
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      }
   },

   watch: {
      token() {
         this.init();
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----IamPreferences B0.4 says => ${msg}`);
      },

      init() {
         // alert('in init(): token=' + this.token);
         if (this.token) {
            this.jwt = JSON.parse(atob(this.token.split('.')[1]));
            this.user = this.$store.getters.user;
            // this.preferences = user.preferences;
            // this.user = {
            //    preferences: {
            //       menus: {
            //          dashboards: [],
            //          campaigns: [],
            //          assets: [],
            //          crm: [],
            //          marketintel: [],
            //          settings: []
            //       },
            //       timeout: 60
            //    }
            // };
            this.log('in init(): preferences=' + JSON.stringify(this.user.preferences));
         }
      },

      async savePreferences(newPreferences) {
         // alert('in savePreferences(): newPreferences=' + JSON.stringify(newPreferences));
         const postData = { 
            AccountID: this.isActualEndpoint ? this.jwt.aid : 15972,
            IdentityToken: this.isActualEndpoint 
               ? this.token
               : 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpcCI6IjcwLjE4Ny4xNzAuNiIsImVtYWlsIjoiYnRhbGVicG91ckBtaW5kZmlyZWluYy5jb20iLCJwdSI6ZmFsc2UsImF1Ijp0cnVlLCJwYSI6ZmFsc2UsImFpZCI6MTU5NzIsInBhaWQiOjEsImlzcyI6ImlkZW50aXR5Lm1kbC5pbyIsImlhdCI6MTYzMTY0Mzk2NSwiZXhwIjoxNjMxNjQ3NTY1fQ.GKLKp9SRcVJxdtbSF0Esjcu6GPwXgStHIUCDZVW2ZS-Mc1VO-BFxxTTl37sU3O1dM5Lj_RR34FbRjmN2827BEys8Wo9_s-VDk2h6YjKLRvDirK7O1PqxuomyCNONLvYsKIxtwpeCLdy0arbUkqeX4jB2IVKOPuadzVYZiwIeI_a_D7xHTZ3D-y_uq0JlPHcfHcaNNHskR8yQjwzF7NDH1H3CxC7x-8OfTyXjZP-XWVsuWvCfQUjRAFfnMtIN8jhuRMcPF_9dI-C3hcIBkv4s2pSLQspLRdtoPBLbg0YxbHgNGhil5z-nBpaTXYEobDCwehFeHWUwxd1EIwGSzSRUfw',
            Preference: JSON.stringify(newPreferences)
         }
         // alert('postData=' + JSON.stringify(postData));

         const response = await this.$http.post('/accountservice/SetAccountPreference', postData);
         this.log(`response=${JSON.stringify(response)}`);
         const result = response.data.Result;
         if (result.ErrorCode)
            alert(result.ErrorMessage + '\n\n' + result.ExceptionMessage);
         else
            this.$emit('snackbar-event', this.$t('updated', { value: 'Preferences' }));
         this.user.preferences = newPreferences;
      }
   },

   created() {
      // alert('in created(): endpoint=' + process.env.VUE_APP_STUDIO_REST_ENDPOINT);
      this.$http = axios.create({
         baseURL: process.env.VUE_APP_STUDIO_REST_ENDPOINT,
         headers: {
            'Content-Type': 'application/json'
         }
      });
      delete this.$http.defaults.headers.common["Authorization"];      
      this.init();
   }
})
</script>

<style scoped>
   .v-input__slot {
      margin-bottom: 0px;
   }
   >>>.v-expansion-panel-content__wrap {
      padding: 0 24px 0;
   }
   >>>.v-toolbar__content {
      padding: 0 16px;
   }
</style>