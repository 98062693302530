<template>
    <div>
      <!-- Support type [export|trigger] -->
      <!-- {{ selected[0] }} -->
    <v-tooltip left>
       <template v-slot:activator="{ on }">
          <v-btn text icon
             v-on="on"
             @click="btnClicked"
          >
             <v-icon>{{btnIconName}}</v-icon>
          </v-btn>
       </template>
       <span>{{btnTooltip}}</span>
    </v-tooltip>
    <v-dialog no-click-animation persistent scrollable
       max-width="900px"
       v-model="triggerDialog"
    >
     <v-card style="overflow-x:hidden;" flat>
          <v-card-title style="position:relative;" class="title grey--text darken-4 font-weight-bold">Push message
            <!-- <v-btn @click="addPassClick" small style="display: block;position: absolute;right: 40px;top: 32px;">+</v-btn> -->
          </v-card-title>
          <v-card-text>
          </v-card-text>
      <v-card-actions class="pt-0">
         <div class="flex-grow-1"></div>
         <v-btn text small
            class="px-0"
            color="blue darken-1"
            @click="triggerDialog = false;"
         >Cancel</v-btn>
         <!-- <v-btn v-if="selected.length != 0" text small
            class="px-0 mx-0"
            color="blue darken-1"
            @click=""
         >Save</v-btn> -->
      </v-card-actions>      
      </v-card>
    </v-dialog>
 
    <v-dialog no-click-animation persistent scrollable
       max-width="900px"
       v-model="dialog"
    >
      <v-card v-if="JWTMode" flat>
         <KCPassUIView ref="passUI"  :perPageOptions=[5,10] @createPushMessage="createPushMessage" @selected="selectedChange" :showCreateAction=walletPassConfig.showCreateAction></KCPassUIView>
         <v-card-actions class="pt-0">
             <div style="opacity: 0.5;font-size:x-small;">Version 1.02</div>
             <div class="flex-grow-1"></div>
             <v-btn text small
                class="px-0"
                color="blue darken-1"
                @click="cancelClicked"
             >Cancel</v-btn>
             <v-btn v-if="selected.length != 0" text small
                class="px-0 mx-0"
                color="blue darken-1"
                @click="saveJWTClick"
             >Save</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
 </div>
</template>

<script>
 import axios from "axios";
 import utils from '../services/KCUtils.js'
//  import KCCardBuilderView from '../views/KCCardBuilderView.vue';
 import KCPassUIView from "../views/KCPassUIView.vue"
 var crypto = require('crypto');

 const NAME = "KCWalletPassSelector";

const serverMode="pass";
//let config = utils.getServerConfig(serverMode);
import csvUtils from "../services/KCCSVUtils.js";
// let davinciUrl = this.$store.getters.deployment.dvURL;
// let davinciUrl = "https://davincistage.mindfireinc.com/admin/portalLogin.php";
// let server = "prod";
// if(davinciUrl.indexOf("davincistage")>=0){
//    server = "stage";
// }       

 export default {
    name: NAME,
    components: {
      //   KCCardBuilderView,
        KCPassUIView
    },
    props: {
       value: {
          type: Object,
          default: () => {return {}}
       },
       appName: {
          type: String,
          default: "WalletPass"
       },
       btnIconName: {
          type: String,
          default: "more_vert"
       },
       btnTooltip: {
          type: String,
          default: "click to configure"
       },
       debug: {
          type: Boolean,
          default: true
       },
       isActualEndpoint: {
          type: Boolean,
          default: true
       },
       dbgAccountID: {
          type: Boolean,
          default: false             
       },
       type: {       // export or trigger
          type: String,
          default: "export"             
       },
       pullMode: {
          type: Boolean,
          default: true             
       },
       JWTMode: {
          type: Boolean,
          default: true             
       },
       passUIEndpoint: {
          type: String,
          //default: "https://8mdmei6e83.execute-api.us-west-1.amazonaws.com/test/api/csvprocessor?cmd=getDocument&name=walletPassConfig",
          default: process.env.VUE_APP_PASSUI_ENDPOINT?process.env.VUE_APP_PASSUI_ENDPOINT:"https://kiws7ng8z2.execute-api.us-west-1.amazonaws.com/stage/passui/process"
       },
       exportExpire: {
          type: Number,
          default: 365
       }
    //    serverMode: {
    //         default: "pass",
    //    },
    },
 
    data() {
       return {
          davinciUrl: null,
          server: null,
          //endPoint: null,
          selected: [],
          formData: {},
          dialog: false,
          triggerDialog: false,
          //cardBuilderDlg: false,
          passDataTable: {
                headers: [
                    { text: "ID", value: "id", sortable: true },
                    { text: "Name", value: "name", sortable: true },
                    { text: "LastModified", value: "lastModified", sortable: true },
                    { text: "Action", value: "action", sortable: false },
                ],
                items: []
            },
          showLoadDialog: false,
          passDataLoading: false,
          shouldShowWalletPassSaveButton: true,
          readOnlyMode: false,
          editMode: false,
          headers: [],
          sampleData: [],
          taskName: "",
          loadPassCommand: null,
          // this will be override by getConfig();
          walletPassConfig: 
         //  {
         //    "version": 0,
         //    "addThisPassScanUrl":"https://addthiscard.com/?pass=2X9ZOg==@{_id}{exportId}{actionId}{purl}&a=scan{purl}&a=scan"   
         //  }
         {
            "version": 1,
            "addThisPassScanUrl":"https://addthiscard.com/?pass=${passKey}@{_id}{exportId}{actionId}{purl}&a=scan",
            showCreateAction: false,
         },
          exportFileExpiry: this.exportExpire,
          //consumerCode: "7b0ce1aec8f6c28ee79ffbc8c28ec56a",//crypto.createHash('md5').update(passID).digest("hex");
          
       }
    },
 
    computed: {},
 
    watch: {},
 
    methods: {
       log() {
          if (this.debug) {
             console.log(`- ${NAME} -`,arguments);
          }
       },
 
       async btnClicked() {
         if(this.type == "export"){
            this.dialog = true;
            if(this.$refs.passUI){
               this.$refs.passUI.clearSelected();
            }
            //this.populatePass();
         }else{
            this.triggerDialog = true;
         }
       },

        async getConfig(){
            //let csvProcessorEndpoint = utils.getServerConfig(utils.getServerMode("mindfireOne")).endPoints.mindfireOne + "/api/csvprocessor";
            var ret = await axios.get(this.passUIEndpoint+`?cmd=config&stage=${this.server}`);
            console.log("getConfig", ret.data);
            return ret.data.result;
        },

        getColumnsFromTemplateValue(value){
            let ret = [];    
            try{
               value.match(/\{([^}]+)\}/g).map(match => {
                  // Remove the curly braces
                     console.log(match);
                     const withoutBraces = match.substring(1, match.length - 1);
                     //console.log(withoutBraces);
                     if(ret.indexOf(withoutBraces)<0){
                        ret.push(withoutBraces);
                     }
                     //return withoutBraces;
               });
            }catch(ex){
               console.log(ex);
            }
            return ret;
         },        
         getBarcodeOtherFromCardData(cardData){
            let other=null;
            let columns=[];
            //let cardData = JSON.parse(ret.data.result[0].data);
            if("barcodeOther" in cardData.resource.templateData && cardData.resource.templateData.barcodeOther.enabled == true){
               // using barcode
               let bc = cardData.resource.templateData.barcodeOther;
               if(bc.chkCreateNewColumn == false && bc.columnName!=""){
                  other = `{${bc.columnName}}`;
                  columns.push(bc.columnName);
                   return {other,columns};
               }
               if(bc.chkCreateNewColumn == true && bc.value !=""){
                  other = bc.value;
                  columns = this.getColumnsFromTemplateValue(bc.value);
                  return {other,columns};
               }
            }
            return null;

        },
        async saveJWTClick(){
            this.$swal.fire({
               title: 'Checking pass',
               html: `<span></span>`,
            });
            this.$swal.showLoading();
            let selectedData = JSON.parse(JSON.stringify(this.selected[0]));
            let passID = selectedData.passKey;
            let id = selectedData.id;
            if(passID == undefined){
               passID = "FAKEPASSID";
            }
            let passData = selectedData.definition;
            let otherData = this.getBarcodeOtherFromCardData(passData);
            console.log(this.selected[0]);
            console.log(otherData);
            console.log("saveJWTClick",passID,id);
            let passUrl = this.walletPassConfig.addThisPassScanUrl.replace("${passKey}",passID);
            let columns = ['_id', 'purl'];
            if(otherData!=null){
               passUrl = passUrl + `&O-other=${otherData.other.toLowerCase()}&O-registered=true`;
               otherData.columns.map(x =>{
                  if(columns.indexOf(x)<0){
                     columns.push(x.toLowerCase());
                  }
               })
            }
            // clean
            delete selectedData.definition.resource.templateData.headers;
            delete selectedData.definition.resource.templateData.sampleData;
            delete selectedData.definition.resource.backFields;
            delete selectedData.definition.passData;
            let returnObj = {
               instruction: {
                  fieldDefinitions: [
                        //{ label: 'passQRCode', format: [`https://addthiscard.com/?pass=${passID}@{_id}{purl}&a=scan`,'_id', 'purl'], default: ''}
                        { label: 'passQRCode', format: [passUrl,...columns], default: ''}
                  ],
                  selected: selectedData.definition,
                  server: this.server,
                  exportFileExpiry: this.exportFileExpiry
               },
               appEndpoint: this.pullMode?" ":utils.getServerConfig(utils.getServerMode("mindfireOne")).endPoints.mindfireOne + "/api/csvprocessor2",
               consumerCode: this.walletPassConfig.app.passUI.consumerCode,
               
            }
            console.log(returnObj);
            this.$emit('change', returnObj);
            this.$emit('input', returnObj);
            this.selected = [];
            this.$swal.close();
            this.dialog = false;

        },
 
       cancelClicked() {
          this.dialog = false;
       },

       selectedChange(selected){
         console.log("selectedChange",selected);
         this.selected = selected;
       },
       createPushMessage(data){
            let returnObj = {
               instruction: {
                  fieldDefinitions: [
                        //{ label: 'passQRCode', format: [`https://addthiscard.com/?pass=${passID}@{_id}{purl}&a=scan`,'_id', 'purl'], default: ''}
                        { label: 'passTag', format: ["{_id}{purl}",['_id', 'purl']], default: ''}
                  ],
                  //selected: selectedData.definition,
                  pushMessageData: data,
                  server: this.server,
                  exportFileExpiry: this.exportFileExpiry
               },
               appEndpoint: this.pullMode?" ":utils.getServerConfig(utils.getServerMode("mindfireOne")).endPoints.mindfireOne + "/api/csvprocessor2",
               consumerCode: this.walletPassConfig.app.passUI.consumerCode,
            }
            console.log(returnObj);
            this.$emit('change', returnObj);
            this.$emit('input', returnObj);
            this.selected = [];
            //this.$swal.close();
            this.dialog = false;
       }
    },
 
    created() {
      this.davinciUrl = this.$store.getters.deployment.dvURL;
      // alert(`in created(): davinciUrl=${this.davinciUrl}`);
      if (this.davinciUrl.indexOf("davincistage") >= 0)
         this.server = "stage";
      else
         this.server = "prod";
      //this.endPoint = config.endPoints.mindfireOne + `/api/card/${this.server}`;
    },
 
    async mounted() { 
      let newConfig = await this.getConfig();
      if(newConfig){
         this.walletPassConfig = newConfig;
         this.walletPassConfig.showCreateAction = false; // set to false to hide the create action button because we don't push message by action.
      }
      console.log("walletPassConfig",this.walletPassConfig);
    },

   //  watch: {
   //    "type": {
   //          handler: function (newValue, oldValue) {
   //              console.log("messageData change")
   //              this.$nextTick(() => {
   //              this.$emit("canSave",this.canSave());
   //              })
   //          }
   //    },
   //  }
 }
</script>