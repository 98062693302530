<!-- HISTORY:
   V230927.1: Added sftpStatus dropdown and header, if needed.
   V230906.2: In tableCurrItems(), fixed variables usage that was messed up with List.
   V230906.1: Made list table unsortable.
   V230901.1: In getListExportsCount(), reset pagination.
   V230831.1: Added List tab and moved search inside the totals tab (by copying from AsImports).
   V230825.1: Added search and total row + Removed sftp statuses.
   V230824.1: 1st release (copied file from AsImports.vue V230824.2).
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">file_upload</v-icon>
            <span>Exports</span>
         </h1>
      </v-card-title>

      <v-card-text class="ml-2 px-5 pb-0">
         <v-form lazy-validation
            ref="mainForm"
            v-model="isMainFormValid"
         >
            <v-row>
               <v-col xs="12" sm="12" md="3" class="py-2">
                  <v-select dense persistent-hint hide-selected required
                     ref="status"
                     placeholder="select a status..."
                     hint="* Status"
                     :items="statusItems"
                     :prepend-inner-icon="statusIcon"
                     v-model="status"
                     @change="statusChanged"
                  ></v-select>
               </v-col>
               <v-col v-if="status === 'completed'"
                  xs="12" sm="12" md="3" class="py-2"
               >
                  <v-select clearable dense persistent-hint hide-selected required
                     ref="sftpStatus"
                     placeholder="select an SFTP status..."
                     hint="SFTP Status"
                     :items="statusItems"
                     :prepend-inner-icon="sftpStatusIcon"
                     v-model="sftpStatus"
                     @change="sftpStatusChanged"
                  ></v-select>
               </v-col>
               <v-col xs="12" sm="12" md="2" class="py-2">
                  <bt-date-picker
                     :debug="debug"
                     :dd-custom-label="true"
                     dd-label="select a date range..."
                     :dd-monthly-items="datePickerMonthlyItems"
                     dd-value="TM"
                     :dp-no-title="false"
                     :dp-max-range="31"
                     v-model="dateRange"
                     @date-range-change="dateRangeChanged"
                  ></bt-date-picker>
               </v-col>
               <v-col xs="12" sm="12" md="2" class="py-5 mt-1">
                  <v-btn small
                     color="primary"
                     :disabled="!isCriteriaChanged"
                     @click="getReports()"
                  >Get Report
                     <v-icon right dark>summarize</v-icon>
                  </v-btn>
               </v-col>
            </v-row>
         </v-form>
      </v-card-text>

      <v-card-text>
         <v-tabs
            class="elevation-2"
            background-color="grey lighten-2 accent-4"
            slider-color="black"
            v-model="tab"
         >
            <v-tab>Totals</v-tab>
            <v-tab>List</v-tab>

            <v-tabs-items v-model="tab">
               <v-tab-item class="pt-5"><!-- Totals -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-text-field clearable
                           class="py-0"
                           append-icon="search"
                           label="Search"
                           :disabled="!totalExports.length"
                           v-model="searchString"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header
                     class="elevation-1"
                     :footer-props="footerPropsTotalExports"
                     :headers="totalHeaders"
                     :hide-default-footer="totalExports.length <= 5"
                     :items="totalExports"
                     :items-per-page="10"
                     :loading="loadingTotalExports"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsTotalExports"
                     :search="searchString"
                     @current-items="tableCurrItems"
                  >
                     <template v-slot:[`item.totalExports`]="{item}">
                        {{ formatNumber(item.totalExports) }}
                     </template>
                     <template v-slot:[`body.append`] v-if="totalExportsTotal">
                        <tr class="font-weight-bold">
                           <td>Totals:</td>
                           <td></td>
                           <td></td>
                           <td></td>
                           <td align="right">{{formatNumber(totalExportsTotal)}}</td>
                        </tr>
                     </template>
                  </v-data-table>
               </v-tab-item>

               <v-tab-item class="pt-5"><!-- List -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-autocomplete clearable dense hide-selected
                           append-icon="search"
                           placeholder="search for a specific account"
                           :items="accounts"
                           v-model="selectedAccount"
                           @change="getListReport"
                        ></v-autocomplete>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header show-expand single-expand
                     class="elevation-1"
                     item-key="_id"
                     :footer-props="footerPropsListExports"
                     :headers="listHeaders"
                     :hide-default-footer="listExportsCount.length <= 5"
                     :items="listExports"
                     :items-per-page="10"
                     :loading="loadingListExports"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsListExports"
                     :server-items-length="listExportsCount"
                  >
                     <template v-slot:[`item.processedRecords`]="{item}">
                        {{ formatNumber(item.processedRecords) }}
                     </template>
                     <template v-slot:[`item.creationDate`]="{ item }">
                        <span style="white-space:pre">{{ formatDate(item.creationDate) }}</span>
                     </template>
                     <template v-slot:expanded-item="{ item }">
                        <td>&nbsp;</td>
                        <td colspan="4" class="py-2" valign="top" dense>
                           <ul class="pl-3">
                              <li>
                                 <span class="expanded-header">ID: </span>
                                 <span class="expanded-content">{{item._id}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Creator: </span>
                                 <span class="expanded-content">{{item.creator}}</span>
                              </li>
                           </ul>
                        </td>
                        <td colspan="3" class="py-2" valign="top" dense>
                           <ul class="pl-3">
                              <li>
                                 <span class="expanded-header">Start Date: </span>
                                 <span class="expanded-content">{{formatDate(item.startDate)}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Last Activity: </span>
                                 <span class="expanded-content">{{formatDate(item.lastActivityDate)}}</span>
                              </li>
                           </ul>
                        </td>
                     </template>
                  </v-data-table>
               </v-tab-item>
            </v-tabs-items>
         </v-tabs>
      </v-card-text>
   </v-card>
</v-container>
</template>

<script>
import BtDatePicker from "./BtDatePicker.vue";
import { APIService } from '../services/as-api-service.js';
import { format, parseISO } from "date-fns";
// import { sleep } from '../mixins/bt-mixin.js';

const NAME = "AsExports";
const TABS = { totals: 0, list: 1 };
function _compareItems (e1, e2) {
   return e1.text > e2.text ? 1 : -1;
}

export default {
   name: NAME,

   components: {
      BtDatePicker
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         statusItems: [ 'waiting', 'processing', 'completed', 'recovery', 'rejected' ],
         datePickerMonthlyItems: [
            { text: "Current Month", value: "TM" },
            { text: "Last Month", value: "LM" }
         ],
         totalHeaders: [
            { text: 'Account Name', value: 'accountname', align: 'left', sortable: true },
            { text: 'Account ID', value: 'accountid', align: 'left', sortable: true },
            { text: 'Parent Name', value: 'parentname', align: 'left', sortable: true },
            { text: 'Parent ID', value: 'parentid', align: 'left', sortable: true },
            { text: 'Total Exports', value: 'totalExports', align: 'right', sortable: true }
         ],
         listHeaders: [
            { text: 'Account Name', value: 'accountname', align: 'left', sortable: false },
            { text: 'Account ID', value: 'databaseName', align: 'left', sortable: false },
            { text: 'Parent Name', value: 'parentname', align: 'left', sortable: false },
            { text: 'Parent ID', value: 'parentid', align: 'left', sortable: false },
            { text: 'Name', value: 'name', align: 'left', sortable: false },
            { text: 'Processed Records', value: 'processedRecords', align: 'right', sortable: false },
            { text: 'Creation Date', value: 'creationDate', align: 'left', sortable: false }
         ],
         apiService: null,
         isMainFormValid: false,
         isCriteriaChanged: false,
         status: 'completed',
         statusIcon: '',
         sftpStatus: '',
         sftpStatusIcon: '',
         dateRange: [],
         tab: null,
         loadingTotalExports: false,
         searchString: '',
         optionsTotalExports: {},
         lastItemsPerPage: 10,
         footerPropsTotalExports: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         totalExports: [],
         totalExportsTotal: 0,
         loadingListExports: false,
         footerPropsListExports: {
            itemsPerPageOptions: [5, 10, 15, 20],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         optionsListExports: {},
         listExportsCount: 0,
         listExports: [],
         accounts: [],
         selectedAccount: 0
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      }
   },

   watch: {
      token() {
         this.init();
      },

      async tab(val) {
         if (val === TABS.list && !this.listExportsCount && this.totalExports.length) {
            await this.getListReport();
            const accounts = [];
            this.totalExports.forEach(exprt => {
               accounts.push({
                  text: `${exprt.accountname}: ${exprt.accountid}`,
                  value: exprt.accountid
               });
            });
            this.accounts = accounts.sort(_compareItems);
         }
      },

      optionsListExports: {
         handler(val) {
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
            }
            this.getListExports();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V230927.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         // _alert('in init(): token=' + this.token);
         if (this.token) {
            this.loadingOffers = true;
            this.apiService = new APIService(this.token, this.debug, this.isActualEndpoint);
            this.statusChanged(this.status);
            setTimeout(() => {
               this.getTotalExports();
            }, 100);
         }
      },

      statusChanged(val) {
         // alert(`in statusChanged(): val=${val}`);
         this.statusIcon = this.getIcon(val).icon;
         // this.isCriteriaChanged = true;
         this.sftpStatus = '';
         this.sftpStatusChanged('');
      },

      async sftpStatusChanged(val) {
         // alert(`in sftpStatusChanged(): val=${val}`);
         this.sftpStatusIcon = this.getIcon(val).icon;
         this.isCriteriaChanged = true;
      },

      dateRangeChanged(val) {
         // alert(`in dateRangeChange(): val=${JSON.stringify(val)}`);
         this.isCriteriaChanged = true;
      },

      getIcon(status) {
         switch (status) {
            case 'waiting':
               return { color: 'grey', icon: 'schedule' };
            case 'processing':
               return { color: 'blue', icon: 'hourglass_empty' };
            case 'completed':
               return { color: 'green', icon: 'done' };
            case 'recovery':
               return { color: 'orange', icon: 'restore' };
            case 'rejected':
               return { color: 'red', icon: 'clear' };
            default:
               return { color: '', icon: '' };
         }
      },

      async getTotalExports() {
         // alert(`in getTotalExports(): status=${this.status}\ndateRange=${JSON.stringify(this.dateRange)}`);
         this.loadingTotalExports = true;
         this.totalExports = [];
         let result = await this.apiService.getTotalExports(this.status, this.sftpStatus, this.dateRange);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.totalExports = result.data;

            const sftpHeader = this.listHeaders.find(h => h.value === 'sftpStatus');
            if (this.status === 'completed' && !this.sftpStatus) {
               if (!sftpHeader)
                  this.listHeaders.push({ text: 'SFTP Status', value: 'sftpStatus', align: 'left', sortable: false });
            } else if (sftpHeader)
               this.listHeaders.pop();
         }

         this.isCriteriaChanged = false;
         this.loadingTotalExports = false;
      },

      formatNumber(number, maxFractionDigits) {
         return new Intl.NumberFormat('en-US', 
            { 
               maximumFractionDigits: maxFractionDigits || 0
            }).format(number);
      },

      tableCurrItems(val) {
         this.totalExportsTotal = 0;
         if (this.searchString) {
            if (!this.lastItemsPerPage) {
               this.lastItemsPerPage = this.optionsTotalExports.itemsPerPage;
               this.optionsTotalExports.itemsPerPage = -1;
               this.footerPropsTotalExports.disableItemsPerPage = true;
            }
            if (val && val.length) {
               val.forEach(v => {
                  this.totalExportsTotal += v.totalExports;
               });
            }
         } else {
            if (this.lastItemsPerPage) {
               this.footerPropsTotalExports.disableItemsPerPage = false;
               this.optionsTotalExports.itemsPerPage = this.lastItemsPerPage;
               this.lastItemsPerPage = 0;
            }
            this.totalExports.forEach(ti => {
               this.totalExportsTotal += ti.totalExports;
            });
         }
      },

      async getListExportsCount() {
         this.loadingListExports = true;
         let result = await this.apiService.getListExportsCount(this.status, this.sftpStatus, this.dateRange, this.selectedAccount);
         if (result.logout)
            this.logout();

         this.listExportsCount = result.message ? 0 : result.data;
         this.optionsListExports.page = 1;
         // this.footerPropsListExports.disableItemsPerPage = this.listExportsCount <= 5;
      },

      async getListExports() {
         // alert(`in getListExports(): status=${this.status}\ndateRange=${JSON.stringify(this.dateRange)}`);
         this.loadingListExports = true;
         this.listExports = [];
         if (this.listExportsCount) {
            let result = await this.apiService.getListExports(this.status, this.sftpStatus, this.dateRange, this.selectedAccount, this.optionsListExports.page, this.optionsListExports.itemsPerPage);
            if (result.logout)
               this.logout();
               
            if (!result.message) {
               this.listExports = [];
               result.data.forEach(lExport => {
                  //NOTE: keep == as accountid is number but databaseName is string.
                  const tExport = this.totalExports.find(exprt => exprt.accountid == lExport.databaseName);
                  lExport.accountname = tExport.accountname;
                  lExport.parentname = tExport.parentname;
                  lExport.parentid = tExport.parentid;
                  this.listExports.push(lExport);
               });
            }
         }

         this.isCriteriaChanged = false;
         this.loadingListExports = false;
      },

      async getReports() {
         await this.getTotalExports();
         this.listExportsCount = 0;
         this.listExports = [];
         if (this.totalExports.length && this.tab === TABS.list) {
            await this.getListReport();
         }
      },

      formatDate(date) {
         if (date) {
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            // return formatteddate.replace(' ', '\n');
            return formatteddate;
         }
      },

      async getListReport() {
         await this.getListExportsCount();
         await this.getListExports();
      }
   },

   created() {
      this.init();
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>