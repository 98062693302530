<template>
    <v-container style="height: 100%; width: 100%;background: white;">
        <v-row class="mt-0">
            <v-col cols=8 style="line-height: 32px;">
                <template v-if="mode=='editor'">ADD GUEST</template>
                <template v-else>VIEW GUEST</template>
            </v-col>
            <v-col cols=4 class="text-right">
                <v-tooltip top hide-on-click hide-on-hover open-on-hover :disabled="isTooltipDisabled">
                    <template v-slot:activator="{ on}">
                        <v-btn ref="refreshBtn" :disabled="guests.length==0" v-on="on" small class="btn-icon mt-1 mr-2" @click="refreshTaskClick()"><v-icon>refresh</v-icon></v-btn>
                    </template>
                    Reload
                </v-tooltip>
                <!-- <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small class="btn-icon mt-1 mr-2" v-bind="attrs" v-on="on" @click="importCSVClick()"><v-icon>upload_file</v-icon></v-btn>
                    </template>
                    Import CSV File
                </v-tooltip> -->
                <v-tooltip v-if="mode=='qrcode'" top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="guests.length==0" small class="btn-icon mt-1 mr-2" v-bind="attrs" v-on="on" @click="downloadAllClick()"><v-icon>download</v-icon></v-btn>
                    </template>
                    Download All
                </v-tooltip>
                <v-tooltip v-if="mode=='editor'" top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small class="btn-icon mt-1 mr-2" v-bind="attrs" v-on="on" @click="addNewGuestClick()"><v-icon>add</v-icon></v-btn>
                    </template>
                    Add New Guest
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row class="py-1">
            <v-col :cols=colSize>
                <v-data-table v-model="selected" :headers="headers" :items="guests" :loading="dataLoading" @click:row="rowClick" :items-per-page="5" item-key="_id" :single-select=true :show-select="mode=='qrcode'" class="elevation-1" dense style="width: 100%;" height="33vh">
                    <template v-slot:no-data>
                        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
                    </template>
                    <template v-if="mode=='editor'" v-slot:[`item.action`]="props">
                        <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                            <template v-slot:activator="{ on , attrs}">
                                <v-icon @click.native.stop v-bind="attrs" v-on="on" small class="mr-2" @click="editItem(props.item)">edit</v-icon> 
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                            <template v-slot:activator="{ on , attrs}">
                                <v-icon @click.native.stop v-bind="attrs" v-on="on" small class="mr-2" @click="deleteItem(props.item)">delete</v-icon> 
                            </template>
                            <span>Remove</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
            <!-- <Transition name="preview" v-on:leave="previewLeave(true)"  v-on:after-leave="previewLeave(false)"> -->
            <v-col v-show="mode=='qrcode' && selected.length > 0" cols=3 style="position:relative;max-height:123px;">
                <KCQRCodeView ref="qrcodeView2" id="qrcode2" @click="qrcodeClick('open')" :data=qrcodeUrl style="top:0px;position:absolute;right:0px;"></KCQRCodeView>                            
                <div style="position:absolute;bottom: 0px;right: 0px;cursor: pointer;opacity:0.7;font-size: small;">
                    <span @click="qrcodeClick('open')">Click here to download</span>
                </div>
                <v-menu
                    v-model="qrcodeMenu2"
                    attach="#qrcode2"
                    absolute
                    :close-on-content-click="false"
                    :nudge-width=400
                    :max-width=400
                    :position-x="-220"
                    :position-y=0>      
                    <v-card class="px-2 pb-3">
                        <v-card-title>Pass's Url</v-card-title>
                        <v-card-text>{{qrcodeUrl}}</v-card-text>
                        <v-card-actions>
                            <v-btn @click="qrcodeClick('copy')" small><v-icon small>content_copy</v-icon> Copy url</v-btn>
                            <v-btn @click="qrcodeClick('download')" small><v-icon small>download</v-icon> Download Image</v-btn>
                        </v-card-actions>
                        
                    </v-card>
                </v-menu>
            </v-col>
            <!-- </Transition> -->
        </v-row>
        <v-dialog v-model="dialog" max-width="400px">
            <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">New Address</v-btn>
            </template> -->
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field dense v-model="editedItem['_id']" label="Guest Id"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field dense v-model="editedItem['gurl']" label="Guest URL"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="canSave == false" color="blue darken-1" text @click="save('dialog')">OK</v-btn>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogImportCSV" max-width="500px">
            <v-card>
                <v-card-title class="text-h5"></v-card-title>
                <v-card-text>
                    <v-row dense class="py-1">
                        <v-col cols="12">
                            <v-file-input ref="localFileInput" accept=".csv" hide-details class="pt-1 shrink" v-model="localFileInput" label="CSV" prepend-icon="upload_file" ></v-file-input>
                        </v-col>
                        <v-col cols="12">
                            <a href="https://mfdavinci.s3.us-west-1.amazonaws.com/appData/file/geofences-sample.csv">download a file sample here</a>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="importCSVConfirm">OK</v-btn>
                    <v-btn color="blue darken-1" text @click="closeImportCSV">Cancel</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete '{{editedItem['preference']}}'?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<style>
</style>
<style scoped>
</style>
<script>
 import axios from "axios";
 import utils from '../services/KCUtils.js'
 import kcMixins from "../services/KCMixins.js";
 import addThisPass from "../services/KCAddThisPass.js";
import KCQRCodeView from "./KCQRCodeView.vue";
//  import KCGoogleAutoComplete from "../components/KCGoogleAutoComplete.vue";
// var crypto = require('crypto');

const NAME = "JWGuestDialog";

const serverMode="pass";
let config = utils.getServerConfig(serverMode);
import csvUtils from "../services/KCCSVUtils.js";

export default {
    name: NAME,
    mixins: [kcMixins],
    components: {
        KCQRCodeView
    },
    props: {
        masterHeader: {
            type: Object,
            default() {
                return {}
            }
        },
        mode: {
            default: 'editor'
        },
        passKey: {
            default: ''
        }
    },
    data() {
        return {
            /* mixins start */
            appName: "passUI",
            /* mixins end */
            davinciUrl: null,
            dialog: false,
            dialogDelete: false,
            dialogImportCSV: false,
            localFileInput: null,
            dataLoading: false,
            headers: [
                { text: "Guest Id", value: "_id", sortable: true },
                { text: "Guest URL", value: "gurl", sortable: true },
                { text: '', value: 'action', sortable: false, align: 'center' }
            ],
            guests: [],
            startGuests: [],
            default: {
                "_id": "",
                "gurl": ""
            },
            editedItem: {},
            editedIndex: -1,
            masterEditedItem: {},
            csvFields: [
                { displayName: "Guest Id", codeName: "_id", required: true },
                { displayName: "Guest URL", codeName: "gurl", required: true },
                { displayName: "Guest QR URL", codeName: "passQRCode", required: false }
            ],
            isTooltipDisabled: false,
            previewLeaving: false,
            qrcodeUrl: null,
            selected: [],
            qrcodeMenu2: false
        };
    },
    computed: {
        account: function () {
            //console.log('user object: ', this.$store.getters.user);
            return this.$store.getters.user.account;
        },
        canSave(){
            if(JSON.stringify(this.editedItem) == JSON.stringify(this.masterEditedItem)){
                return false
            }
            return true;
        },
        formTitle () {
            return this.editedIndex === -1 ? 'New Guest' : 'Edit Guest';
        },
        colSize: function(){
            return (this.selected.length >0 || this.previewLeaving) && this.mode == 'qrcode' ? 9:12;
        }
    },
    methods: {
        async initialize (guests) {
            console.log('initialize: ', guests);
            this.guests = JSON.parse(JSON.stringify(guests));
            this.startGuests = JSON.parse(JSON.stringify(guests));
        },
        rowClick(item) {
            if (this.mode == 'qrcode') {
                this.selected = [item];
            } else {
                this.editItem(item);
            }
        },
        qrcodeClick: async function(param){
            console.log("qrcodeClick",param);
            switch(param){
                case "open": this.qrcodeMenu2 = true; break;
                case "copy": 
                    await navigator.clipboard.writeText(this.qrcodeUrl);
                    console.log("done");
                    break;
                case "download":
                    this.$refs.qrcodeView2.saveImage();
                    break;
            }
        },
        editItem (item) {
            console.log('edit item',item);
            this.editedIndex = this.guests.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
            this.editMode = true;
        },

        async deleteItem (item) {
            console.log('delete item',item);
            this.editedIndex = this.guests.indexOf(item);
            this.editedItem = Object.assign({}, item);
            // this.dialogDelete = true
            this.isTooltipDisabled = true;
            var result = await this.$swal.fire({
                title: `Are you sure you want to delete '${this.editedItem.gurl}(${this.editedItem._id})'?`,
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                focusConfirm: true,
                ... csvUtils.getSwalYesNoOption()
            });
            console.log(result);
            this.isTooltipDisabled = false;
            if (result.isConfirmed) {
                this.deleteItemConfirm();
            }
        },

        async deleteItemConfirm () {
            this.preferences.splice(this.editedIndex, 1);
            this.closeAll();
        },

        close () {
            this.dialog = false;
            this.editMode = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false;
        },

        closeAll () {
            this.dialogDelete = false;
            this.close();
        },
        addNewGuestClick(){
            this.dialog = true;
            this.editedItem = Object.assign({}, this.default);
        },
        save(caller='dialog') {
            console.log('editedItem:', this.editedItem);
            if (this.editedIndex > -1) {
                Object.assign(this.guests[this.editedIndex], this.editedItem);
            } else {
                this.guests.push(this.editedItem);
            }
            this.$emit("canSave",this.canSaveAll());
            this.close();
        },
        getListServiceAxios(){
            if(Object.keys(this.masterHeader).length ==0){
                return null;
            }else{
                return this.getCleanAxios();
            }
        },
        refreshTaskClick() {
            this.initialize(this.guests);
        },
        getGuests() {
            return this.guests;
        },
        canSaveAll() {
            let startGuestsStr = JSON.stringify(this.startGuests);
            let guestsStr = JSON.stringify(this.guests);
            console.log('guestsStr1', startGuestsStr);
            console.log('guestsStr2', guestsStr);
            return (startGuestsStr==guestsStr) ? false : true;     
        },
        previewLeave(value){
            console.log("previewAfterLeave",value);
            this.previewLeaving = value;
        },

        downloadAllClick(){
            console.log("Downloading", this.guests);
            let downloadData = JSON.parse(JSON.stringify(this.guests));
            for (let x in downloadData) downloadData[x].passQRCode = `https://addthiscard.com/?pass=${this.passKey}@${downloadData[x]._id}${downloadData[x].gurl}&a=scan`;
            console.log("Downloading downloadData: ", downloadData);
            let csvContent = this.jsonToCSV(this.mapDisplayName(downloadData));
            console.log("csvContent",csvContent);
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

            // Create a temporary anchor element
            const link = document.createElement("a");
            if (link.download !== undefined) {
                // Set the URL to the blob
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "Guest-QRCode.csv");

                // Append the anchor to the body and trigger the download
                document.body.appendChild(link);
                link.click();

                // Clean up: remove the anchor and revoke the blob URL
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } else {
                console.error("Anchor element's 'download' attribute is not supported in this browser.");
            }
        },

        mapDisplayName(data, invert=false){
            let ret = [];
            console.log(data);
            for(let x in data){
                let loc = data[x];
                let newLoc = {};
                for(let y in this.csvFields){
                    let field = this.csvFields[y];
                    if (invert) {
                        newLoc[field.codeName] = loc[field.displayName];
                    } else {
                        newLoc[field.displayName] = loc[field.codeName];
                    }
                }
                ret.push(newLoc)
            }
            // console.log("ret", ret);
            return ret;
        },

        jsonToCSV(jsonData) {
            // Convert JSON to CSV
            const headers = Object.keys(jsonData[0]).join(",");
            const csvData = jsonData.map(obj => {
                return Object.values(obj).map(val => {
                    // If the value contains a comma, wrap it in double quotes
                    if (val.toString().includes(",")) {
                        return `"${val}"`;
                    }
                    return val;
                }).join(",");
            }).join("\n");
            return headers + "\n" + csvData;
        },

        importCSVClick(){
            this.dialogImportCSV = true;
        },

        closeImportCSV () {
            this.dialogImportCSV = false;
        },

        async importCSVConfirm () {
            console.log("importCSVConfirm", this.localFileInput); // e == this.localFileInput
            if(this.localFileInput == null){
                return;
            }
            if (this.localFileInput.name.indexOf(".csv")<0) {
                //alert("Invalid file type");
                //this.localFileInput = undefined;
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Invalid file type',
                    confirmButtonText: "Ok",
                }).then((result)=>{
                    console.log("Clear");
                    //this.$refs.localFileInput.value = null;
                    this.$refs.localFileInput.reset();
                    // this.fileInputKey++;    // clear this.localFileInput
                    this.localFileInput  = null;
                })
                return;
            }
            let content = await utils.readTextFileAsync(this.localFileInput);
            console.log('content: ', content);
            let contentLines = content.split(/\r?\n/);
            let headers = this.headerCSVStringToArray(contentLines[0]);
            if (!this.validateHeaders(headers)) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Required fields not found',
                    confirmButtonText: "Ok",
                }).then((result)=>{
                    console.log("Clear");
                    //this.$refs.localFileInput.value = null;
                    this.$refs.localFileInput.reset();
                    // this.fileInputKey++;    // clear this.localFileInput
                    this.localFileInput  = null;
                })
                return;
            }
            let contentJSON = this.csvToJSON(contentLines);
            console.log('contentJSON:', contentJSON);
            let preferences = this.mapDisplayName(contentJSON, true);
            console.log('preferences:', preferences);
            preferences = this.correctData(preferences);            
            console.log('corrected preferences:', preferences);
            this.closeImportCSV();
            this.$swal.fire({
                title: 'Importing preferences',
                html: `<span></span>`,
            });
            this.$swal.showLoading();
            for (let i=0; i<preferences.length; i++) {
                this.editedItem = preferences[i];
                await this.save('import');
            }
            this.$swal.close();
        },

        headerCSVStringToArray(inputString) {
            // Split the string by comma
            let parts = inputString.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

            // Map over the parts to remove text inside parentheses and trim whitespace
            let result = parts.map(part => part.split('(')[0].trim());

            return result;
        },

        validateHeaders(headers){
            console.log("validateHeaders", JSON.stringify(headers));
            let csvHeader = this.csvFields[this.locationMode];
            console.log("csvHeader", JSON.stringify(csvHeader));
            let ret = true;
            for(let i=0; i<csvHeader.length; i++){
                let hash = csvHeader[i];
                console.log("hash", JSON.stringify(hash));
                if (!hash.required) continue;
                console.log("hash2", JSON.stringify(hash));
                if (headers.indexOf(hash.displayName)<0){
                    ret = false;
                    break;
                }
            }
            return ret;
        },
    },
    created() {
        // this.davinciUrl = this.$store.getters.deployment.dvURL;
        // // alert(`in created(): davinciUrl=${this.davinciUrl}`);
        // if (this.davinciUrl.indexOf("davincistage") >= 0)
        //     this.server = "stage";
        // else
        //     this.server = "prod";
        // // this.endPoint = config.endPoints.mindfireOne + `/api/card/${this.server}`;
    },
    destroyed() {
    },
    async mounted() {
    },
    beforeUnmount() { 
    },
    watch: {
        account: {
            async handler(val) {
            },
            deep: true
        },
        dialog (val) {
            console.log("dialog",this.dialog,this.editedItem);
            if(val == true){
                // show
                this.masterEditedItem = JSON.parse(JSON.stringify(this.editedItem));
            }
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },
        dialogImportCSV (val) {
            val || this.closeImportCSV()
        },
        selected(val) {
            console.log('watch selected', val);
            if (val.length > 0 && this.mode == 'qrcode') {
                this.qrcodeUrl = `https://addthiscard.com/?pass=${this.passKey}@${val[0]._id}${val[0].gurl}&a=scan`;
                console.log('watch qrcodeUrl', this.qrcodeUrl);
            }
        }
    },
};
</script>