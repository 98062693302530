<template>
    <v-container style="height: 100%; width: 100%;background: white;">
        <v-row>
            <span class="title font-weight-bold" style="color:#757575 !important; line-height: 2.5rem;">Scheduling</span>
        </v-row>
        <!-- <v-row>
            <v-col cols="12" class="pt-1">
                <v-radio-group v-model="schedule.mode" row hide-details="auto" class="mt-0">
                    <v-radio label="Daily" value="daily"></v-radio>
                    <v-radio label="Weekly" value="weekly"></v-radio>
                    <v-radio label="Monthly" value="monthly"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row v-if="schedule.mode!='daily'">
            <v-col v-if="schedule.mode=='weekly'" cols="12" class="pt-1">
                <v-select hide-details v-model="weeklyDays" :items="daysOfWeek" placeholder="Day of week" dense multiple></v-select>
            </v-col>
            <v-col v-if="schedule.mode=='monthly'" cols="12" class="pt-1">
                <v-select hide-details v-model="monthlyDays" :items="daysOfMonth" placeholder="Day of month" dense multiple></v-select>
            </v-col>
        </v-row> -->
        <v-row>
            <!-- <v-col cols="6" class="pt-1" style="display: inline-flex;">
                <v-menu ref="timepickerref" v-model="timepicker" :close-on-content-click="false" :nudge-right="40" :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="schedule.time" label="" prepend-icon="schedule" readonly v-bind="attrs" v-on="on" dense @click="initTime()" @change="formatTime()"></v-text-field>
                    </template>
                    <v-time-picker v-if="timepicker" v-model="schedule.time" ampm-in-title format="ampm" @change="formatTime()" @click:hour="formatTime()" @click:minute="$refs.timepickerref.save(time)" @update:period="formatTime()" :allowed-minutes="allowedMinutes"></v-time-picker>
                </v-menu>
            </v-col> -->
            <v-col cols="6" class="pt-1" style="display: inline-flex;">
                <span style="min-width: 65px; line-height: 35px;">Daily at</span>
                <v-select hide-details v-model="hour" :items="hours" placeholder="HH" dense></v-select>
                <span style="line-height: 35px; padding: 0 5px;">:</span>
                <v-select hide-details v-model="minute" :items="minutes" placeholder="mm" dense></v-select>
            </v-col>
            <v-col cols="6" class="pt-1">
                <v-select hide-details v-model="schedule.timezone" :items="timezones" placeholder="Timezone" dense></v-select>
            </v-col>
        </v-row>            
    </v-container>
</template>
<script>
//  import axios from "axios";
//  import utils from '../services/KCUtils.js'
 import kcMixins from "../services/KCMixins.js";
//  import addThisPass from "../services/KCAddThisPass.js";
// import KCQRCodeView from "./KCQRCodeView.vue";
//  import KCGoogleAutoComplete from "../components/KCGoogleAutoComplete.vue";
// var crypto = require('crypto');
import moment from 'moment-timezone';

const NAME = "JWScheduling";

const serverMode="pass";
// let config = utils.getServerConfig(serverMode);
// import csvUtils from "../services/KCCSVUtils.js";

export default {
    name: NAME,
    mixins: [kcMixins],
    components: {
        // KCQRCodeView
    },
    props: {
    },
    data() {
        return {
            /* mixins start */
            appName: "passUI",
            /* mixins end */
            defSchedule: {
                mode: 'daily',
                days: [],
                time: '',
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            schedule: {},
            scheduleInit: {},
            timepicker: false,
            time: '',
            hour: '00',
            minute: '00',
            weeklyDays: ['1'],
            monthlyDays: ['1'],
            daysOfWeek: [{"value": "1", "text": "Mon", "index": 1}, {"value": "2", "text": "Tue", "index": 2}, {"value": "3", "text": "Wed", "index": 3}, {"value": "4", "text": "Thu", "index": 4}, {"value": "5", "text": "Fri", "index": 5}, {"value": "6", "text": "Sat", "index": 6}, {"value": "7", "text": "Sun", "index": 7}],
            daysOfMonth: [{"value": "1", "text": "1st", "index": 1}, {"value": "2", "text": "2nd", "index": 2}, {"value": "3", "text": "3rd", "index": 3}, {"value": "4", "text": "4th", "index": 4}, {"value": "5", "text": "5th", "index": 5}, {"value": "6", "text": "6th", "index": 6}, {"value": "7", "text": "7th", "index": 7}, {"value": "8", "text": "8th", "index": 8}, {"value": "9", "text": "9th", "index": 9}, {"value": "10", "text": "10th", "index": 10}, {"value": "11", "text": "11th", "index": 11}, {"value": "12", "text": "12th", "index": 12}, {"value": "13", "text": "13th", "index": 13}, {"value": "14", "text": "14th", "index": 14}, {"value": "15", "text": "15th", "index": 15}, {"value": "16", "text": "16th", "index": 16}, {"value": "17", "text": "17th", "index": 17}, {"value": "18", "text": "18th", "index": 18}, {"value": "19", "text": "19th", "index": 19}, {"value": "20", "text": "20th", "index": 20}, {"value": "21", "text": "21st", "index": 21}, {"value": "22", "text": "22nd", "index": 22}, {"value": "23", "text": "23rd", "index": 23}, {"value": "24", "text": "24th", "index": 24}, {"value": "25", "text": "25th", "index": 25}, {"value": "26", "text": "26th", "index": 26}, {"value": "27", "text": "27th", "index": 27}, {"value": "28", "text": "28th", "index": 28}, {"value": "29", "text": "29th", "index": 29}, {"value": "30", "text": "30th", "index": 30}, {"value": "31", "text": "31st", "index": 31}],
            hours: [{"value": "00", "text": "00", "index": 0}, {"value": "01", "text": "01", "index": 1}, {"value": "02", "text": "02", "index": 2}, {"value": "03", "text": "03", "index": 3}, {"value": "04", "text": "04", "index": 4}, {"value": "05", "text": "05", "index": 5}, {"value": "06", "text": "06", "index": 6}, {"value": "07", "text": "07", "index": 7}, {"value": "08", "text": "08", "index": 8}, {"value": "09", "text": "09", "index": 9}, {"value": "10", "text": "10", "index": 10}, {"value": "11", "text": "11", "index": 11}, {"value": "12", "text": "12", "index": 12}, {"value": "13", "text": "13", "index": 13}, {"value": "14", "text": "14", "index": 14}, {"value": "15", "text": "15", "index": 15}, {"value": "16", "text": "16", "index": 16}, {"value": "17", "text": "17", "index": 17}, {"value": "18", "text": "18", "index": 18}, {"value": "19", "text": "19", "index": 19}, {"value": "20", "text": "20", "index": 20}, {"value": "21", "text": "21", "index": 21}, {"value": "22", "text": "22", "index": 22}, {"value": "23", "text": "23", "index": 23}],
            minutes: [{"value": "00", "text": "00", "index": 0}, {"value": "30", "text": "30", "index": 1}],
            timezones: [{"text":"Hawaii Time","value":"Pacific/Honolulu","atpnum":"-6"},{"text":"Alaska Time","value":"America/Anchorage","atpnum":"-4"},{"text":"Pacific Time","value":"America/Los_Angeles","atpnum":"-3"},{"text":"Mountain Time","value":"America/Denver","atpnum":"-2"},{"text":"Central Time","value":"America/Chicago","atpnum":"-1"},{"text":"Eastern Time","value":"America/New_York","atpnum":"0"},{"text":"SE Asia Time","value":"Asia/Bangkok","atpnum":"11"}]
        };
    },
    computed: {
    },
    methods: {
        setSchedule (schedule) {
            // console.log('setSchedule: ', schedule);
            // if (typeof schedule.mode != "undefined") {
            //     this.schedule = JSON.parse(JSON.stringify(schedule));
            //     this.scheduleInit = JSON.parse(JSON.stringify(schedule));
            // } else {
            //     this.schedule = JSON.parse(JSON.stringify(this.defSchedule));
            //     this.scheduleInit = JSON.parse(JSON.stringify(this.defSchedule));
            // }
            this.schedule = { ...this.defSchedule, ...schedule};
            // this.$emit("canSave", false);
            // console.log('setSchedule: ', this.schedule);
            if (this.schedule.mode == 'weekly') {
                this.weeklyDays = (this.schedule.days.length == 0) ? ['1'] : this.schedule.days;
            } else if (this.schedule.mode == 'monthly') {
                this.monthlyDays = (this.schedule.days.length == 0) ? ['1'] : this.schedule.days;
            }
            if (this.schedule.time == '') {
                let hour = moment().hour();
                let minute = moment().minute();
                if (minute>=30){
                    hour++;
                    if (hour==24) hour = 0;
                    minute = 0;
                } else {
                    minute = 30;
                }
                this.hour = hour.toString().padStart(2, "0");
                this.minute = minute.toString().padStart(2, "0");
            } else {
                this.hour = this.schedule.time.split(":")[0];
                this.minute = this.schedule.time.split(":")[1];
            }
        },
        getSchedule() {
            if (this.schedule.mode == 'weekly') {
                this.schedule.days = this.weeklyDays;
            } else if (this.schedule.mode == 'monthly') {
                this.schedule.days = this.monthlyDays;
            }
            this.schedule.time = this.hour+':'+this.minute;
            return this.schedule;
        },
        initTime(){
            if (!this.schedule.time) {
                const now = moment();
                this.schedule.time = now.format('hh:mmA');
            }
        },
        formatTime(){
            console.log('format time');
            if (this.schedule.time) {
                let amPmRegex = /(AM|PM)/;
                let currentFormat = 'YYYY/MM/DD HH:mm';
                // Use test() method to check if the string contains AM or PM
                if (amPmRegex.test(this.schedule.time)) {
                    currentFormat = 'YYYY/MM/DD hh:mmA';
                }
                const now = moment();
                console.log(now.format('YYYY/MM/DD')+' '+this.schedule.time, currentFormat);
                const dt = moment(now.format('YYYY/MM/DD')+' '+this.schedule.time, currentFormat);
                this.schedule.time = dt.format('hh:mmA');
                console.log(this.schedule.time);
            }
        },
        correctMomentTime(timeStr) {
            let amPmRegex = /(AM|PM)/;
            let currentFormat = 'HH:mm';
            // Use test() method to check if the string contains AM or PM
            if (amPmRegex.test(timeStr)) {
                currentFormat = 'hh:mmA';
            }
            const dt = moment(timeStr, currentFormat);
            return dt.format('HH:mm');
        },
        calculateNextScheduleDateTime(schedule) {
            let nextScheduleDateTime = null;

            const timezone = schedule.timezone;
            const currentDT = moment().tz(timezone);
            const currentDateStr = currentDT.format("YYYY-MM-DD");
            const time = this.correctMomentTime(schedule.time);

            // Daily mode
            if (schedule.mode === "daily") {
                // console.log("calculateNextScheduleDateTime: ", `${currentDateStr} ${time}`);
                nextScheduleDateTime = moment.tz(`${currentDateStr} ${time}`, timezone);
                // console.log("calculateNextScheduleDateTime: 1");
                if (nextScheduleDateTime.isBefore(currentDT)) {
                    nextScheduleDateTime = moment.tz(`${currentDateStr} ${time}`, timezone).add(1, "day");
                    // console.log("calculateNextScheduleDateTime: 2");
                }
            }

            // Weekly mode
            else if (schedule.mode === "weekly") {
                const days = schedule.days; // ['Monday', 'Wednesday']
                let upcomingDay = null;

                days.forEach((day) => {
                    let scheduleDay = moment.tz(timezone).day(day).set({
                        hour: time.split(":")[0],
                        minute: time.split(":")[1],
                    });
                    if (scheduleDay.isBefore(currentDT)) scheduleDay = scheduleDay.add(7, 'days');
                    // console.log('calculateNextScheduleDateTime: ', currentDT.format('YYYY-MM-DD HH:mm'), scheduleDay.format('YYYY-MM-DD HH:mm'), scheduleDay.isAfter(currentDT), !upcomingDay, scheduleDay.isBefore(upcomingDay));
                    if (scheduleDay.isAfter(currentDT) && (!upcomingDay || scheduleDay.isBefore(upcomingDay))) {
                        upcomingDay = scheduleDay;
                    }
                });

                nextScheduleDateTime = upcomingDay;
            }

            // Monthly mode
            else if (schedule.mode === "monthly") {
                const days = schedule.days.map(Number);
                // console.log("calculateNextScheduleDateTime days: ", days);
                let nextDay = days.find((day) => day >= currentDT.date()) || days[0];
                // console.log("calculateNextScheduleDateTime nextDay: ", nextDay);
                let scheduleMonth = nextDay >= currentDT.date() ? currentDT.month() : currentDT.month() + 1;
                let scheduleYear = currentDT.year();
                if (scheduleMonth >= 12) {
                    scheduleMonth = 0;
                    scheduleYear++;
                }
                // console.log("calculateNextScheduleDateTime scheduleMonth: ", scheduleMonth, currentDT.month(), currentDT.date(), currentDT.format("YYYY-MM-DD HH:mm:ss"));
                nextScheduleDateTime = moment.tz([scheduleYear, scheduleMonth, nextDay, ...time.split(":")], timezone);
                // console.log('calculateNextScheduleDateTime nextScheduleDateTime: ', nextScheduleDateTime, nextScheduleDateTime.isValid());
                if (!nextScheduleDateTime.isValid()) {
                    nextScheduleDateTime = moment.tz([scheduleYear, 1], timezone).endOf('month').set({ hour: time.split(":")[0],minute: time.split(":")[1] });
                    // console.log('calculateNextScheduleDateTime nextScheduleDateTime2: ', nextScheduleDateTime);
                }
            }

            // Set final value
            return (nextScheduleDateTime) ? nextScheduleDateTime.format("YYYY-MM-DD HH:mm:00") : "Invalid Schedule";
        },
        checkQueue(schedule, currentTime=null, tz='UTC') {
            let currentDT = moment.tz(tz);
            if (currentTime) currentDT = moment.tz(currentTime, tz);
            let nextScheduleDT = this.calculateNextScheduleDateTime(schedule);
            console.log('checkQueue: ', moment.tz(nextScheduleDT, schedule.timezone).tz(tz).format("YYYY-MM-DD HH:mm:00"), currentDT.format("YYYY-MM-DD HH:mm:00"));
            const diff = Math.abs(currentDT.diff(moment.tz(nextScheduleDT, schedule.timezone).tz(tz), "minutes"));
            console.log('checkQueue: ', currentDT.diff(moment.tz(nextScheduleDT, schedule.timezone).tz(tz), "minutes"));
            return diff <= 5;
        }
    },
    created() {
    },
    destroyed() {
    },
    async mounted() {
    },
    beforeUnmount() { 
    },
    watch: {
    },
};
</script>