<template>
<div>
   <!-- TODO: 
   1- Remove v-model
   2- minutes validation
   - week validation
   - call getScheules() and getCron() in btnClick() event.
   - in getEvents(), manage not to call if data are already available.

   IMPROVEMENTS:
   - Don't pass is-json-server prop to the children if the improvement in the child component is done.

   //----------calendar bugs----------------------------------------------------
   * in weekly items are not aligned
   * Switch between cron and calendar screw the calendar rendering
   -->
   <!-- HISTORY:
   - V221117.1: Added debug switch for both Schedules and Crons and passed it to the BtCronSchedulerChild component.
   - V221027.1: Applied/Modified styles to display contents properly after Aref's upgrade + Replaced hasOwnProperty() with hasOwn from mixins +
      Removed 'on' from v-slot:activator.
   - 05/02/22(B1.3): Implemented logout feature.
   - 07/28/21(B1.2): Added getCronsOnBtnClick prop (for the export purposes) + Made dialog's style dynamic +
      Added small and xSmall props for the btn and passed them along with isJsonServer prop to all children.
   - 07/26/21(B1.1): Hid instruction error for export.
   - 07/19/21(B1.0): Moved it to the IAM project + Added disabled and consumerType props.
   - 01/15/21(B0.11): Added rdls prop to be passed to BtCronSchedulerChild.vue + added more logic to watch/user.
   - 09/01/20(B0.9): Moved user from data to computed.
   - 08/21/20(B0.8): Set values for new is-authorized and no-permission-message props.
   - 08/19/20(B0.7): Applied permissions (policies) + Wrapped v-slots with [``] +
      Added debug and isJsonServer props + Passed these props to APIService constructor.
   - 06/03/20(B0.6): Moved timezones from child component and passed them via a prop + Fixed event details width +
      Added a new layout for v-sheet to make it responsive.
   - 05/28/20(B0.5): Fixed a bug that after updating a cron didn't show cron details +
      Added refresh btn + Refreshed data on returning to the schedule tab + Removed the external schroll bar +
      Returned to Crons tab after a new cron is added + Displayed table footer + 
      Fixed an error in getEvents() that happened when there was no dates yet + Reset error messages after x secs +
      Added cronTZ.
   - 05/27/20(B0.4): Changed text of terminatedSwitchLabel + Removed upcoming schedules in waiting +
      Changed mdi icons to material.io + Showed runSequence in event details.
   - 05/22/20(B0.3): Applied new schedule structure changes + stopped refreshing. 
   - 05/22/20(B0.2): Remobed 'Repeating' + Changed legened font color. 
   - 05/22/20(B0.1): 1st release. 
   -->
   <v-col>
      <v-tooltip v-model="showTooltip" top>
         <template v-slot:activator="{}">
            <v-btn
               :small="small"
               :x-small="xSmall"
               :color="btnColor"
               :disabled="disabled"
               @click="btnClicked"
            >{{ btnLabel }}
               <v-icon right dark>schedule</v-icon>
            </v-btn>
         </template>
         <span>{{noPermissionMessage}}</span>
      </v-tooltip>               
   </v-col>

   <v-dialog v-model="dialog" persistent no-click-animation scrollable max-width="1200px">

      <v-card>
         <v-card-title class="title grey--text darken-4 font-weight-bold pb-2">
            {{ dialogTitle }}
            <div class="flex-grow-1"></div>
            <v-btn v-if="tab===0"
               x-small
               class="mr-2 mt-1"
               color="gray darken-1"
               :disabled="refreshBtnDisabled"
               @click="getEvents({})"
            >REFRESH
               <v-icon right dark>refresh</v-icon>
            </v-btn>
            <bt-cron-scheduler-child
               btn-label="ADD NEW CRON"
               btn-icon="add"
               btn-align="right"
               btn-role="edit"
               dialogTitle="Add a New Schedule:"
               :btn-disabled="!instruction || Object.keys(instruction).length===0"
               :consumer-type="consumerType"
               :name-validator="isNameDuplicate"
               :timezones="timezones"
               :is-authorized="canCreateCron"
               :is-json-server="isJsonServer"
               :no-permission-message="noPermissionMessage"
               :rdls="rdls"
               :debug-switch="debugSwitch"
               v-model="currCron"
               @click="editCron({ instruction: instruction })"
               @submit="addCron"
            ></bt-cron-scheduler-child>
         </v-card-title>

         <v-card-text class="pb-2" :style="dialogStyle">
            <!-- <div class="v-text-field__details" v-if="apiResult.message">
               <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                     <div class="v-messages__message pl-0 pb-2">{{apiResult.message}}</div>
                  </div>
               </div>
            </div>
            <div class="v-text-field__details" v-if="actionMsg">
               <div class="v-messages theme--light green--text" role="note">
                  <div class="v-messages__wrapper">
                     <div class="v-messages__message pl-0 pb-2">{{actionMsg}}</div>
                  </div>
               </div>
            </div> -->
            <div v-if="apiResult.message && typeof apiResult.message === 'string'"
               class="body-1 red--text font-weight-bold pb-2"
            >{{apiResult.message}}</div>
            <div v-if="actionMsg"
               class="body-1 green--text font-weight-bold pb-2"
            >{{actionMsg}}</div>
            <!-- <v-card> -->
               <!-- background-color="deep-purple accent-4" -->
               <v-tabs dark
                  class="elevation-2"
                  background-color="grey lighten-2 accent-4"
                  slider-color="black"
                  v-model="tab"
               >
                  <v-tab class="black--text">Schedules</v-tab>
                  <v-tab class="black--text">Crons</v-tab>
         
                  <v-tabs-items v-model="tab">
                     <v-tab-item>
                        <v-card flat tile>
                           <v-card-text>
                              <v-row>
                                 <v-col cols="auto"
                                    class="caption grey--text font-weight-bold pt-1 pb-5"
                                 >{{schedulesMsg}}</v-col>
                                 <v-spacer></v-spacer>
                                 <v-col cols="auto" class="pr-2 pt-0 pb-1">
                                    <v-chip v-for="i in scheduleStatusItems.length - 1" :key="i"
                                       label x-small
                                       class="ml-0 mr-1 mb-0 white--text"
                                       :color="`${getStatusColor(scheduleStatusItems[i-1].value)}`"
                                    >{{ scheduleStatusItems[i-1].value }}
                                    </v-chip>
                                 </v-col>
                              </v-row>
                              <v-sheet v-if="!isSmallViewport"
                                 tile
                                 height="54"
                                 color="grey lighten-3"
                                 class="d-flex"
                              >
                                 <v-btn icon
                                    class="ma-2"
                                    @click="$refs.calendar.prev()"
                                 >
                                    <v-icon>keyboard_arrow_left</v-icon>
                                 </v-btn>
                                 <v-select dense outlined hide-details
                                    class="ma-2"
                                    label="Schedule Status"
                                    :items="scheduleStatusItems"
                                    v-model="scheduleStatus"
                                    @change="getEvents"
                                 ></v-select>
                                 <v-select dense outlined hide-details
                                    v-model="calendarType"
                                    :items="calendarTypeItems"
                                    class="ma-2"
                                    label="Calendar Type"
                                 ></v-select>
                                 <v-select dense outlined hide-details
                                    class="ma-2"
                                    label="Week Days"
                                    :items="weekdayItems"
                                    :disabled="calendarType==='day'"
                                    v-model="calendarWeekday"
                                 ></v-select>
                                 <v-select dense outlined hide-details
                                    class="ma-2"
                                    label="Hours"
                                    :items="hourItems"
                                    :disabled="calendarType==='month'"
                                    v-model="calendarHours"
                                 ></v-select>
                                 <v-spacer></v-spacer>
                                 <v-btn icon
                                    class="ma-2"
                                    @click="$refs.calendar.next()"
                                 >
                                    <v-icon>keyboard_arrow_right</v-icon>
                                 </v-btn>
                              </v-sheet>
                              <div v-else style="background-color:#EEEEEE;">
                                 <v-row>
                                    <v-col cols="auto" class="py-0">
                                       <!-- <v-btn small left
                                          color="grey"
                                          @click="$refs.calendar.prev()"
                                       ><v-icon dark>keyboard_arrow_left</v-icon>
                                       </v-btn> -->
                                       <v-btn icon
                                          class="ma-2"
                                          @click="$refs.calendar.prev()"
                                       >
                                          <v-icon>keyboard_arrow_left</v-icon>
                                       </v-btn>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto" class="py-0">
                                       <!-- <v-btn fab small right
                                          color="grey"
                                          @click="$refs.calendar.next()"
                                       ><v-icon dark>keyboard_arrow_right</v-icon>
                                       </v-btn> -->
                                       <v-btn icon
                                          class="ma-2"
                                          @click="$refs.calendar.next()"
                                       >
                                          <v-icon>keyboard_arrow_right</v-icon>
                                       </v-btn>
                                    </v-col>
                                 </v-row>
                                 <v-row>
                                    <v-col cols="12" xs="12" sm="6" class="py-0">
                                       <v-select dense outlined hide-details
                                          class="ma-2"
                                          label="Schedule Status"
                                          :items="scheduleStatusItems"
                                          v-model="scheduleStatus"
                                          @change="getEvents"
                                       ></v-select>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" class="py-0">
                                       <v-select dense outlined hide-details
                                          v-model="calendarType"
                                          :items="calendarTypeItems"
                                          class="ma-2"
                                          label="Calendar Type"
                                       ></v-select>
                                    </v-col>
                                 </v-row>
                                 <v-row>
                                    <v-col cols="12" xs="12" sm="6" class="py-0">
                                       <v-select dense outlined hide-details
                                          class="ma-2"
                                          label="Week Days"
                                          :items="weekdayItems"
                                          :disabled="calendarType==='day'"
                                          v-model="calendarWeekday"
                                       ></v-select>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" class="py-0">
                                       <v-select dense outlined hide-details
                                          class="ma-2"
                                          label="Hours"
                                          :items="hourItems"
                                          :disabled="calendarType==='month'"
                                          v-model="calendarHours"
                                       ></v-select>
                                    </v-col>
                                 </v-row>
                              </div>
                              <v-sheet height="70vh"><!-- height="600" -->
                                 <v-calendar
                                    ref="calendar"
                                    event-overlap-mode="stack"
                                    :first-interval="firstInterval"
                                    :interval-count="intervalCount"
                                    v-model="calendarValue"
                                    :weekdays="calendarWeekday"
                                    :type="calendarType"
                                    :events="calendarEvents"
                                    :event-overlap-threshold="30"
                                    :event-color="getEventColor"
                                    @change="getEvents"
                                    @click:event="eventClicked"
                                    @click:date="viewDay"
                                    @click:more="viewDay"
                                 ></v-calendar>
                                 <v-menu offset-x loading
                                    max-width="400px"
                                    v-model="selectedOpen"
                                    :close-on-content-click="false"
                                    :activator="currElement"
                                 >
                                    <v-card flat
                                       color="grey lighten-4"
                                       min-width="400px"
                                    >
                                       <v-toolbar dark
                                          :color="currEvent.color"
                                       >
                                          <!-- <v-toolbar-title v-html="currEvent.name">{{getCurrEventTime()}}</v-toolbar-title> -->
                                          <v-toolbar-title>
                                             {{currEvent.name + ': ' + (currSchedule.status || 'upcoming') + (currSchedule.runSequence?` (${currSchedule.runSequence})` : '')}}
                                          </v-toolbar-title>
                                          <v-spacer></v-spacer>
                                          <v-icon @click="selectedOpen=false">close</v-icon>
                                       </v-toolbar>
                                       <v-card-text>
                                          <!-- <span v-html="currEvent"></span> -->
                                          <v-row>
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0 pr-0">
                                                <span :class="eventTitleClass">Scheduled: </span>
                                                <span :class="eventContentClass">{{formatDateForDetailView(currSchedule.scheduleDate || currSchedule.date, false)}}</span>
                                             </v-col>
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0 pr-0">
                                                <span :class="eventTitleClass">Cron ID: </span>
                                                <span :class="eventContentClass">{{currSchedule.cron._id}} </span>
                                                <bt-cron-scheduler-child
                                                   btn-label=""
                                                   btn-icon="visibility"
                                                   btn-align="left"
                                                   btn-role="view"
                                                   :btn-text="true"
                                                   :consumer-type="consumerType"
                                                   :dialogTitle="`View Your ${currCron.terminated?'Terminated':''} Schedule:`"
                                                   :is-json-server="isJsonServer"
                                                   :timezones="timezones"
                                                   :rdls="rdls"
                                                   :debug-switch="debugSwitch"
                                                   v-model="currCron"
                                                   @click="getCron()"
                                                ></bt-cron-scheduler-child>
                                             </v-col>
                                          </v-row>
                                          <v-row v-if="currSchedule.status && currSchedule.status != 'waiting'">
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0 pr-0">
                                                <span :class="eventTitleClass">Started: </span>
                                                <span :class="eventContentClass">{{formatDateForDetailView(currSchedule.startDate, true)}}</span>
                                             </v-col>
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0 pr-0">
                                                <span :class="eventTitleClass">Ended: </span>
                                                <span :class="eventContentClass">{{formatDateForDetailView(currSchedule.endDate, true)}}</span>
                                             </v-col>
                                          </v-row>
                                          <v-row v-if="currSchedule.status && currSchedule.status != 'waiting'">
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0 pr-0">
                                                <span :class="eventTitleClass">Processed Records: </span>
                                                <span :class="eventContentClass">{{currSchedule.processedRecords}}</span>
                                             </v-col>
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0 pr-0">
                                                <span :class="eventTitleClass">Recovery Count: </span>
                                                <span :class="eventContentClass">{{currSchedule.recoveryCount}}</span>
                                             </v-col>
                                          </v-row>
                                          <v-row v-if="currSchedule.fileTransferStatus">
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0 pr-0">
                                                <span :class="eventTitleClass">File Transfer Status: </span>
                                                <span :class="eventContentClass">{{currSchedule.fileTransferStatus}}</span>
                                             </v-col>
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0 pr-0">
                                                <span :class="eventTitleClass">File URL: </span>
                                                <span :class="eventContentClass">{{currSchedule.fileUrl}}</span>
                                             </v-col>
                                          </v-row>
                                          <v-row v-if="currSchedule.comment">
                                             <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="py-0 pr-0">
                                                <span :class="eventTitleClass">Comment: </span>
                                                <span :class="eventContentClass">{{currSchedule.comment}}</span>
                                             </v-col>
                                          </v-row>
                                       </v-card-text>
                                    </v-card>
                                 </v-menu>
                              </v-sheet>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item>
                        <v-card flat tile>
                           <v-card-text>
                              <!-- <v-container> -->
                                 <v-switch dense small hide-details
                                    class="mt-0 pb-2"
                                    :label="`${terminatedSwitchLabel} Crons`"                                    
                                    v-model="terminatedSwitch"
                                 >
                                    <!-- <template v-slot:label>
                                       <div class="body-2 pl-2">{{terminatedSwitchLabel}} Crons</div>
                                    </template> -->
                                 </v-switch>
                                 <!-- <v-select dense hide-details
                                    v-model="cronStatus"
                                    :items="cronStatusItems"
                                    class="ma-2"
                                    hint="Cron Status"
                                 ></v-select> -->
                                 <v-data-table dense light
                                    class="elevation-1 mt-2 mb-2 font-weight-light caption"
                                    item-key="_id"
                                    :loading="loadingCrons"
                                    :headers="cronHeaders"
                                    :items="cronsData"
                                    :hide-default-footer="hideTableFooter"
                                 >
                                    <template v-slot:[`header.name`]="{ header }">
                                       {{ header.text.toUpperCase() }}
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                       <!-- <v-card flat> -->
                                       <!-- <span style="background-color:#EEEEEE"> -->
                                       <bt-cron-scheduler-child
                                          btn-label=""
                                          btn-icon="visibility"
                                          btn-align="left"
                                          btn-role="view"
                                          :btn-text="true"
                                          :consumer-type="consumerType"
                                          :dialogTitle="`View Your ${currCron.terminated?'Terminated':''} Schedule:`"
                                          :is-json-server="isJsonServer"
                                          :next-schedule-data="nextScheduleData"
                                          :prev-schedule-data="prevScheduleData"
                                          :timezones="timezones"
                                          :rdls="rdls"
                                          :debug-switch="debugSwitch"
                                          v-model="currCron"
                                          @click="editCron(item, 'view')"
                                       ></bt-cron-scheduler-child>
                                       <bt-cron-scheduler-child v-if="!terminatedSwitch"
                                          btn-label=""
                                          btn-icon="edit"
                                          btn-align="left"                                             
                                          btn-role="edit"
                                          dialogTitle="Modify Your Schedule:"
                                          :btn-text="true"
                                          :consumer-type="consumerType"
                                          :name-validator="isNameDuplicate"
                                          :timezones="timezones"
                                          :is-authorized="canUpdateCron"
                                          :is-json-server="isJsonServer"
                                          :no-permission-message="noPermissionMessage"
                                          :rdls="rdls"
                                          :debug-switch="debugSwitch"
                                          v-model="currCron"
                                          @click="editCron(item)"
                                          @submit="saveCron"
                                       ></bt-cron-scheduler-child>
                                       <bt-cron-scheduler-child v-if="!terminatedSwitch"
                                          btn-label=""
                                          btn-icon="delete"
                                          btn-align="left"                                             
                                          btn-role="delete"
                                          :btn-text="true"
                                          :consumer-type="consumerType"
                                          :is-authorized="canUpdateCron"
                                          :is-json-server="isJsonServer"
                                          :no-permission-message="noPermissionMessage"
                                          :rdls="rdls"
                                          :debug-switch="debugSwitch"
                                          v-model="currCron"
                                          @click="editCron(item)"
                                          @submit="terminateCron(item)"
                                       ></bt-cron-scheduler-child>
                                       <!-- <v-icon v-if="!terminatedSwitch"
                                          @click="terminateCron(item)"
                                       >delete</v-icon> -->
                                    </template>
                                    <template v-slot:[`item.cronStart`]="{ item }">
                                       {{ formatDate(item.cronStart) }}
                                    </template>
                                    <template v-slot:[`item.cronEnd`]="{ item }">
                                       {{ formatDate(item.cronEnd) }}
                                    </template>
                                    <template v-slot:[`item.notificationEmails`]="{ item }">
                                       <p class="pb-0 mb-0" v-for="e in item.notificationEmails.split(',')" :key="e">
                                          {{ e }}
                                       </p>
                                    </template>
                                 </v-data-table>
                              <!-- </v-container> -->
                           </v-card-text>
                        </v-card>
                     </v-tab-item>
                  </v-tabs-items>
               </v-tabs>
            <!-- </v-card> -->
         </v-card-text>
         
         <v-card-actions>
            <v-switch v-show="user.isAdminUser"
               class="mx-0 my-0 pl-5 pt-3 pb-0"
               label="Debug"
               v-model="debugSwitch"
            ></v-switch>
            <div class="v-text-field__details">
               <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper" v-if="consumerType != 'export' && (!instruction || Object.keys(instruction).length === 0)">
                     <div class="v-messages__message pl-3">instruction has not been provided!</div>
                  </div>
               </div>
            </div>
            <div class="flex-grow-1"></div>
            <v-btn text
               color="blue darken-1"
               @click="dialog=false"
            >Close</v-btn>
         </v-card-actions>
      </v-card>
      
   </v-dialog>
</div>
</template>

<script>
import BtCronSchedulerChild from './BtCronSchedulerChild.vue';
import { format, parseISO, compareAsc, getMinutes, setMinutes, setSeconds, setMilliseconds, differenceInMilliseconds } from "date-fns";
import { APIService } from '../services/bt-cron-scheduler-api-service.js';
import countriesAndTimezones from 'countries-and-timezones';
import { hasOwn } from '../mixins/bt-mixin.js';

const CRON_HEADERS_CLASS = 'font-weight-bold body-2 font-italic';
const SCHEDULE_HEADERS_CLASS = 'font-weight-bold body-2 font-italic';
// const TIME_FORMAT = 'H:mm:ss a';

let DEBUG;

function _log(msg) {
  if (DEBUG) console.log(`BtCronScheduler V221117.1 says => ${msg}`);
}

function _isObjectEmpty(obj) {
   for(var key in obj) {
      //V221027.1: if (obj.hasOwnProperty(key))
      if (hasOwn(obj, key))
         return false;
   }
   return true;
}

export default {
   name: "BtCronScheduler",
   components: { BtCronSchedulerChild },

   props: {
      value: {
         type: Object,
         default: () => {}
      },
      instruction: {
         type: Object,
         default: () => {}
      },
      rdls: {
         type: Array
         // required: true
         //B1.0: default: () => []
      },
      btnColor: {
         type: String,
         default: "gray darken-1"
      },
      btnLabel: {
         type: String,
         default: "Schedule"
      },
      consumerType: {
         type: String
      },
      debug: {
         type: Boolean,
         default: false
      },
      dialogTitle: {
         type: String,
         default: "Manage Your Schedules:"
      },
      disabled: {
         type: Boolean,
         default: false
      },
      getCronsOnBtnClick: {
         type: Boolean,
         default: false
      },
      isAuthorized: {
         type: Boolean,
         required: true
      },
      isJsonServer: {
         type: Boolean,
         default: false
      },
      noPermissionMessage: {
         type: String,
         default: "Contact your admin to see how you can get this feature."
      },
      small: {
         type: Boolean,
         default: true
      },
      xSmall: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         showTooltip: false,
         canCreateCron: false,
         canUpdateCron: false,
         dialog: false,
         tab: null,
         apiService: new APIService(this.consumerType, this.debug, this.isJsonServer),
         apiResult: {},
         actionMsg: '',
         loadingCrons: false,
         loadingSchedules: false,
         terminatedSwitch: false,
         cronHeaders: [
            { text: 'Actions', value: 'actions', divider: true, class: CRON_HEADERS_CLASS, sort: false, width: '10%' },
            { text: 'Name', value: 'name', divider: true, class:CRON_HEADERS_CLASS },
            { text: 'Cron Description', value: 'cronDesc', divider: true, class:CRON_HEADERS_CLASS },
            { text: 'Start Date', value: 'cronStart', divider: true, class:CRON_HEADERS_CLASS },
            { text: 'End Date', value: 'cronEnd', divider: true, class:CRON_HEADERS_CLASS },
            { text: 'Repeat', value: 'cronRepeat', divider: true, class:CRON_HEADERS_CLASS },
            { text: 'Emails', value: 'notificationEmails', divider: true, class:CRON_HEADERS_CLASS }
         ],
         cronsData: [],
         currCron: {},
         scheduleHeaders: [
            { text: 'Actions', value: 'actions', divider: true, class: SCHEDULE_HEADERS_CLASS, sort: false },
            { text: 'ID', value: '_id', divider: true, class: SCHEDULE_HEADERS_CLASS, sort: false },
            { text: 'Cron Name', value: 'name', divider: true, class:SCHEDULE_HEADERS_CLASS },
            { text: 'Schedule Date', value: 'scheduleDate', divider: true, class:SCHEDULE_HEADERS_CLASS },
            { text: 'Recovery Count', value: 'recoveryCount', divider: true, class:SCHEDULE_HEADERS_CLASS },
         ],
         schedulesData: [
         ],
         //for calendar
         scheduleStatusItems: [
            { text: 'Waiting', value: 'waiting' },
            { text: 'Processing', value: 'processing' },
            { text: 'Recovery', value: 'recovery' },
            { text: 'Completed', value: 'completed' },
            { text: 'Terminated', value: 'terminated' },
            { text: 'Upcoming', value: 'upcoming' },
            { text: 'All', value: '' }
         ],
         calendarTypeItems: [
            { text: 'Monthly', value: 'month' },
            { text: 'Weekly', value: 'week' },
            { text: 'Daily', value: 'day' }
            // { text: '4day', value: '4day' }
         ],
         weekdayItems: [
            { text: 'All Week (Mon - Sun)', value: [1, 2, 3, 4, 5, 6, 0] },
            { text: 'Weekdays (Mon - Fri)', value: [1, 2, 3, 4, 5] },
            { text: 'Weekends (Sat - Sun)', value: [6, 0] },
            // { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
         ],
         hourItems: [
            { text: '12 - 5 AM', value: '0-6' },
            { text: '6 - 11 AM', value: '6-6' },
            { text: '12 - 11 AM', value: '0-12' },
            { text: '12 - 5 PM', value: '12-6' },
            { text: '6 - 11 PM', value: '18-6' },
            { text: '12 - 11 PM', value: '12-12' },
            { text: 'Whole Day', value: '0-24' }
         ],
         scheduleStatus: '',
         calendarValue: '',
         calendarType: '',
         calendarWeekday: [],
         calendarHours: '',
         calendarEvents: [],
         names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
         currEvent: {},
         currSchedule: { cron: {} },
         currElement: null,
         selectedOpen: false,
         eventTitleClass: 'font-weight-bold caption', //overline
         eventContentClass: 'caption',
         lastStart: {},
         lastEnd: {},
         timeoutVar: null,
         // secondsToRefresh: 0,
         lastRefreshTime: '',
         nextScheduleData: {},
         prevScheduleData: {},
         isFirstCalendarLoad: true,
         refreshBtnDisabled: true,
         timezones: [],
         dialogStyle: this.consumerType === 'export' ? '' : 'height: 95vh',
         debugSwitch: false
      }
   },

   computed: {
      // randomTextComputed: () => Math.random(100)
      user() {
         return this.$store.getters.user;
      },
      firstInterval() {
         return this.calendarHours ? this.calendarHours.split('-')[0] : 0;
      },
      intervalCount() {
         return this.calendarHours ? this.calendarHours.split('-')[1] : 24;
      },
      terminatedSwitchLabel() {
         return this.terminatedSwitch ? 'Terminated' : 'Active';
      },
      schedulesMsg() {
         return `${this.calendarEvents.length} schedule(s) were found as of ${this.lastRefreshTime}.`;
      },
      hideTableFooter() {
         return !(this.cronsData && this.cronsData.length > 10);
      },
      isSmallViewport() {
         const { xs, sm } = this.$vuetify.breakpoint;
         if (xs || sm) return true;
         else return false;
         // else if (md) return 'md';
         // else if (lg) return 'lg';
         // else return 'xl';
      }
   },

   watch: {
      terminatedSwitch: {
         immediate: true,
         handler(val) {
            this.getCrons(val);
         }
      },
      tab: {
         // immediate: true,
         handler(val) {
            if (val === 0 && !this.isFirstCalendarLoad);
               this.getEvents({});
         }
      },
      apiResult: {
         deep: true,
         handler(val) {
            if (val.message)
               setTimeout(() => {
                  val.message = '';
               }, 10000);
         }
      },
      user: {
         deep: true,
         immediate: true,
         handler(val) {
            // alert('in user watcher: val=' + JSON.stringify(val));
            this.canCreateCron = val && val.policies && val.policies.indexOf('scheduler-crons-create') > -1;
            this.canUpdateCron = val && val.policies && val.policies.indexOf('scheduler-crons-update') > -1;
            // alert('canCreateCron='val= + this.canCreateCron + ', canUpdateCron=' + this.canUpdateCron);
         }
      },
      debugSwitch: {
         immediate: false,
         handler() {
            if (this.tab === 0)  //schedules
               this.getEvents({});
            else  //crons
               this.getCrons(this.terminatedSwitch);
         }
      }
   },

   methods: {
      logout() {
         this.$router.push('/');
      },

      btnClicked() {
         if (this.isAuthorized) {
            this.$emit('click');
            if (this.getCronsOnBtnClick)
               this.getCrons(false);
            if (this.timezones.length === 0)
               this.getTimezones();
            this.dialog = true;
         } else {
            this.showTooltip = true;
            setTimeout(() => {
               this.showTooltip = false;
            }, 5000);
         }

      },

      async getTimezones() {
         const allTimezones = countriesAndTimezones.getAllTimezones();
         const stdOffset = new Date(new Date().getFullYear(), 0, 1).getTimezoneOffset() * -1;
         let currTz;
         Object.keys(allTimezones).forEach(key => {
            currTz = allTimezones[key];
            if (currTz.utcOffset === stdOffset && currTz.country && currTz.aliasOf) {
               if (this.timezones.findIndex(tz => tz.aliasOf === currTz.aliasOf) === -1)
                  this.timezones.push(currTz);
            }
         });

         _log('in getTimezones(): timezones=' + JSON.stringify(this.timezones));
      },

      async getCrons(status) {
         // alert('in getCrons(): status=' + status);
         this.cronsData = [];
         this.loadingCrons = true;
         this.apiResult = await this.apiService.getCrons('terminated=' + status, this.debugSwitch);
         this.loadingCrons = false;
         if (this.apiResult.logout)
            this.logout();
         else if (!this.apiResult.message)
            this.cronsData = this.apiResult.data;
      },

      formatDate(date, withTime) {
         if (date) {
            // alert('in formatDate(): date='+date+'\nwithTime=' + withTime + '\nparseISO='+parseISO(date));
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            if (withTime) return formatteddate;
            else return formatteddate.split(' ')[0];
         }
      },

      async addCron(newCron) {
         // this.apiResult = await this.apiService.createCron(newCron);
         // alert('in addCron(): result='+JSON.stringify(this.apiResult))
         // if (!this.apiResult.message) {
            if (this.tab === 0)
               this.tab = 1;
            if (this.terminatedSwitch)
               this.terminatedSwitch = false;
            else
               this.getCrons(false);
            // this.cronsData.push(newCron);
            this.actionMsg = `'${newCron.name}' was added.`;
            this.resetActionMsg();
         // }
      },

      async editCron(item, role) {
         // alert('in editCron(): role=' + role + '\nitem='+JSON.stringify(item))
         this.currCron = JSON.parse(JSON.stringify(item));
         if (role === 'view') {
            if (this.currCron.nextScheduleId)
               this.nextScheduleData = await this.getSchedule(this.currCron.nextScheduleId);
            else this.nextScheduleData = {};
            if (this.currCron.lastComletedScheduleId)
               this.prevScheduleData = await this.getSchedule(this.currCron.lastComletedScheduleId);
            else this.prevScheduleData = {};
         }
      },

      async saveCron(editedCron) {
         this.apiResult = await this.apiService.updateCron(editedCron);
         // alert('in saveCron(): result='+JSON.stringify(this.apiResult));
         if (this.apiResult.logout)
            this.logout();
         else if (!this.apiResult.message) {
            const currInd = this.cronsData.findIndex(c => c._id === editedCron._id);
            if (editedCron.terminated) {
               this.cronsData.splice(currInd, 1);
               this.actionMsg = `'${editedCron.name}' was terminated.`;
            } else {
               this.$set(this.cronsData, currInd, editedCron);
               this.actionMsg = `'${editedCron.name}' changes were saved.`;
               this.getCrons(false);
            }
            this.resetActionMsg();
         }
      },

      async terminateCron(item) {
         if (confirm(`Are you sure you want to terminate '${item.name}'?\nThis will also terminate all existing and future schedules.`)) {
            let cron = JSON.parse(JSON.stringify(item));
            cron.terminated = true;
            this.saveCron(cron);
            // this.apiResult = await this.apiService.updateCron(item);
            // // alert('in terminateCron(): result='+JSON.stringify(this.apiResult));
            // if (!this.apiResult.message) {
            //    const currInd = this.cronsData.findIndex(c => c._id === item._id);
            //    this.cronsData.splice(currInd, 1);
            // }
         }
      },

      isNameDuplicate(value) {
         //alert('isNameDuplicate:'+value + '\n' + this.cronsData.findIndex(c => c.name === value))
         const ind = this.cronsData.findIndex(c => c.name.toLowerCase() === value.toLowerCase());
         return ind === -1 || this.currCron._id === this.cronsData[ind]._id;
      },

      async getSchedule(scheduleId) {
         // alert('in getSchedule(): scheduleId='+scheduleId)
         this.apiResult = await this.apiService.getSchedule(scheduleId);
         if (this.apiResult.logout)
            this.logout();
         else if (!this.apiResult.message) {
            this.apiResult.data.scheduleDate = this.formatDate(this.apiResult.data.scheduleDate, true);
            this.apiResult.data.startDate = this.formatDate(this.apiResult.data.startDate, true);
            this.apiResult.data.endDate = this.formatDate(this.apiResult.data.endDate, true);
         }
         // alert('in getSchedule(): data='+JSON.stringify(this.apiResult.data))
         return this.apiResult.data || {};
      },

      /**********************************/
      /*          For Calendar          */
      /**********************************/

      async getEvents({ start, end }) {
         // alert(`in getEvents(): start=${start ? JSON.stringify(start) : start}\nend=${end ? JSON.stringify(end) : end}`);
         this.loadingSchedules = true;
         this.refreshBtnDisabled = true;
         this.isFirstCalendarLoad = false;
         clearTimeout(this.timeoutVar);
         const events = [];

         if (start) {
            this.lastStart = start;
            this.lastEnd = end;
         } else {
            start = this.lastStart;
            end = this.lastEnd;
         }

         // alert(JSON.stringify(start)+'\n'+JSON.stringify(end))
         if (!_isObjectEmpty(start)) {
            const fd = new Date(`${start.date}T00:00:00`);
            const td = new Date(`${end.date}T23:59:59`);
            const responses = await this.getSchedules(fd, td);
            this.lastRefreshTime = format(new Date(), 'h:mm:ss a');
            this.schedulesData = [];
            responses.forEach(response => {
               if (response.logout)
                  this.logout();
               else if (!response.message)
                  this.schedulesData.push(...response.data);
            });

            this.schedulesData.forEach(schedule => {
               const scheduleDate = parseISO(schedule.scheduleDate || schedule.date);
               if (compareAsc(fd, scheduleDate) < 1 && compareAsc(scheduleDate, td) < 1) {
                  events.push({
                     name: schedule.cron.name,
                     start: this.formatCalendarDate(scheduleDate, true),
                     end: schedule.endDate ? this.formatCalendarDate(parseISO(schedule.endDate), true) : '',
                     color: this.getStatusColor(schedule.status),
                     _id: schedule._id
                     // cronId: schedule.cron._id
                  });
               }
            });

            _log('in getEvents(): events=' + JSON.stringify(events));
            this.timeoutVar = setTimeout(() => {
               this.refreshBtnDisabled = false;
            }, this.getNextRefreshMilliseconds());
         }

         this.calendarEvents = events;
         this.loadingSchedules = false;
      },

      async getSchedules(fd, td) {
         const promises = [];
         if (this.scheduleStatus != 'upcoming')
            promises.push(await this.apiService.getSchedules(this.scheduleStatus, fd.toISOString(), td.toISOString(), this.debugSwitch));

         if (this.scheduleStatus === 'upcoming' || this.scheduleStatus === '')
            promises.push(await this.apiService.getUpcomingSchedules(td.toISOString(), this.debugSwitch));

         return Promise.all(promises);
      },

      getStatusColor(status) {
         // calendarColors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
         switch (status) {
            case 'waiting':
               return 'grey darken-1'
            case 'processing':
               return 'primary'  //blue
            case 'recovery':
               return 'orange'   //or yellow
            case 'completed':
               return 'success'  //green
            case 'terminated':
               return 'error' //red
            default:
               return 'grey lighten-1'
         }
      },

      eventClicked({ nativeEvent, event }) {
         // alert('nativeEvent='+JSON.stringify(nativeEvent)+'\n=event'+JSON.stringify(event));
         const open = () => {
            this.currEvent = event;
            this.currElement = nativeEvent.target;
            this.currSchedule = this.schedulesData.find(s => s._id === event._id);
            setTimeout(() => this.selectedOpen = true, 10);
         }

         if (this.selectedOpen) {
            this.selectedOpen = false;
            setTimeout(open, 10);
         } else open();

         nativeEvent.stopPropagation();
      },

      viewDay ({ date }) {
         this.focus = date;
         this.calendarType = 'day';
      },

      getEventColor (event) {
         return event.color
      },

      formatCalendarDate (a, withTime) {
         // alert('formatCalendarDate: ' + a + ' => ' + withTime)
         const result = withTime
            ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
            : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
         // alert('formatCalendarDate: ' + result)
         return result;
      },

      getCurrEventTime() {
         if (this.currEvent && this.currEvent.start)
            return this.currEvent.start.split(' ')[1];
      },

      formatDateForDetailView(date, withSeconds) {
         if (date) {
            // alert('in formatDate(): date='+date+'\nwithTime=' + withTime + '\nparseISO='+parseISO(date));
            // const formatteddate = format(parseISO(date), 'M/d h:mm:ss a');
            // return formatteddate;
            if (withSeconds)
               return format(parseISO(date), 'M/d h:mm:ssaaaaa');
            else
               return format(parseISO(date), 'M/d h:mmaaaaa');
         }
      },

      async getCron() {
         this.currCron = this.cronsData.find(c => c._id == this.currSchedule.cron._id);
         //alert('currCron='+JSON.stringify(this.currCron));
         if (this.currCron === undefined) {
            this.apiResult = await this.apiService.getCron(this.currSchedule.cron._id);
            if (this.apiResult.logout)
               this.logout();
            else if (!this.apiResult.message)
               this.currCron = this.apiResult.data;
         }
      },

      resetActionMsg() {
         setTimeout(() => {
            this.actionMsg = '';
         }, 5000);
      },
      getNextRefreshMilliseconds() {
         let date = new Date();
         let min = getMinutes(date);
         let interval = 15  // in minutes
         let newMin = min - min % interval + interval;
         let newDate = setMilliseconds(setSeconds(setMinutes(date, newMin), 0), 0)
         // alert('Orignal Date: ' + date + '\nNew Date: ' + newDate);
         return differenceInMilliseconds(newDate, date);
      },
      // CalendarTypeChanged(value) {
      //    this.calendarType
      // }
   },

   created() {
      // alert('in create(): rdls=' + JSON.stringify(this.rdls));
      DEBUG = this.debug;
   },
   mounted() {
      // alert('in mounted...')
      //TODO: move these in inti dialog or another event
      this.scheduleStatus = this.scheduleStatusItems[6].value; //All
      this.calendarType = this.calendarTypeItems[1].value;     //Weekly
      this.calendarWeekday = this.weekdayItems[0].value;       //All Week
      this.calendarHours = this.hourItems[6].value;            //Whole Day
      // if (parseInt(format(new Date(), 'HH')) < 12)
      //    this.calendarHours = '0-12';
      // else
      //    this.calendarHours = '12-12';
   }
};

// Once={"name":"cron-6_15-19-38","notificationEmails":"btalebpour@mindfireinc.com","instruction":{"comment":"this is instruction's comment","url":"mindfireinc.com?downloadName=--fileName--&downloadDelimiter=--delimiter--&downloadMultiple=--multipleFiles--&downloadZip=--zipped--&downloadQuotes=--addQuotes--&downloadOverwrite=--overwrite--","offset":"0","download":{"fileName":"filename-6_15-19-38","delimiter":",","multipleFiles":false,"zipped":false,"addQuotes":false,"overwrite":false}},"cronStart":"2020-05-07T17:00:00.000Z"}
// Daily={"name":"cron-6_15-23-11","notificationEmails":"btalebpour@mindfireinc.com","instruction":{"comment":"this is instruction's comment","url":"mindfireinc.com?downloadName=--fileName--&downloadDelimiter=--delimiter--&downloadMultiple=--multipleFiles--&downloadZip=--zipped--&downloadQuotes=--addQuotes--&downloadOverwrite=--overwrite--","offset":"1","download":{"fileName":"filename-6_15-23-11","delimiter":",","multipleFiles":false,"zipped":false,"addQuotes":false,"overwrite":false}},"cron":"15 11 * * *","cronEnd":"","cronStart":"2020-05-07T07:00:00.000Z"}
// Weekly={"name":"cron-6_15-24-57","notificationEmails":"btalebpour@mindfireinc.com","instruction":{"comment":"this is instruction's comment","url":"mindfireinc.com?downloadName=--fileName--&downloadDelimiter=--delimiter--&downloadMultiple=--multipleFiles--&downloadZip=--zipped--&downloadQuotes=--addQuotes--&downloadOverwrite=--overwrite--","offset":"0","download":{"fileName":"filename-6_15-24-57","delimiter":",","multipleFiles":false,"zipped":false,"addQuotes":false,"overwrite":false}},"cron":"30 11 * * 1,1,4,4","cronEnd":"","cronStart":"2020-05-07T07:00:00.000Z"}
// Monthly={"name":"cron-6_15-27-10","notificationEmails":"btalebpour@mindfireinc.com","instruction":{"comment":"this is instruction's comment","url":"mindfireinc.com?downloadName=--fileName--&downloadDelimiter=--delimiter--&downloadMultiple=--multipleFiles--&downloadZip=--zipped--&downloadQuotes=--addQuotes--&downloadOverwrite=--overwrite--","offset":9,"download":{"fileName":"filename-6_15-27-10","delimiter":",","multipleFiles":false,"zipped":false,"addQuotes":false,"overwrite":false}},"cron":"0 12 1,15,30,1,10,20 * *","cronEnd":"","cronStart":"2020-05-07T07:00:00.000Z"}
// WithFtp&Repeat={"name":"cron-6_15-29-52","notificationEmails":"btalebpour@mindfireinc.com","instruction":{"comment":"this is instruction's comment","url":"mindfireinc.com?downloadName=--fileName--&downloadDelimiter=--delimiter--&downloadMultiple=--multipleFiles--&downloadZip=--zipped--&downloadQuotes=--addQuotes--&downloadOverwrite=--overwrite--","offset":"0","download":{"fileName":"download-filename","delimiter":",","multipleFiles":true,"zipped":true,"addQuotes":true,"overwrite":true}},"cron":"0 9 * * *","cronRepeat":"15","cronStart":"2020-05-07T07:00:00.000Z","transferProtocol":"SFTP","ftpHost":"http://hostedftp.com","ftpPort":"","ftpUser":"behzad","ftpPass":"mypass"}
// noEnd={"name":"erertertertert","notificationEmails":"btalebpour@yahoo.com","instruction":{"comment":"this is instruction's comment","url":"mindfireinc.com?downloadName=--fileName--&downloadDelimiter=--delimiter--&downloadMultiple=--multipleFiles--&downloadZip=--zipped--&downloadQuotes=--addQuotes--&downloadOverwrite=--overwrite--","offset":"0","download":{"fileName":"ffdfdfdf","delimiter":",","multipleFiles":false,"zipped":false,"addQuotes":false,"overwrite":false}},"cron":"0 6 * * *","cronStart":"2020-05-07T07:00:00.000Z"}
</script>

<style lang="scss">
   .text-start.v-data-table__divider {
      padding-left: 4px; padding-right: 4px;
   }
</style>
