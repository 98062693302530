import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    portalState: [], // [ { aid: "", email: "", bi: {}, ls: {}} ]
    status: '',
    token: '',//localStorage.getItem('token') || '',
    user : {},
    photoURL: '',
    account : 0,
    accounts: null,
    loggedInWithAccount: false,
    deployment: {}
  },
  mutations: {
    initialiseStore(state) {
      
      // Check if the ID exists
			if(localStorage.getItem('store')) {

				// Replace the state object with the stored item
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('store')))
        );
        axios.defaults.headers.common['Authorization'] = 'bearer ' + state.token;
        
			}
		},
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, token){
      state.status = 'success'
      state.token = token
    },
    auth_setLoggedInWithAccount(state,loggedInWithAccount){
      state.status = 'success'
      state.loggedInWithAccount = loggedInWithAccount
    },
    auth_setUser(state, user){
      state.user = user
    },
    auth_setAccounts(state, accounts){
      state.accounts = accounts
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
      state.user = {}
      state.loggedInWithAccount= false
    },
    setAccount(state, account){
      state.account = account;
    },
    setPhotoURL(state, photoURL){
      //console.log('photoURL: ', photoURL)
      state.photoURL = photoURL;
      //console.log('state.photoURL:', state.photoURL)
    },
    setDeployment(state, settings){
      state.deployment = settings;
    },
    updatePortalState(state, portalState){
      state.portalState = portalState;
    }
  },
  actions: {
    setPortalState({commit}, portalState){
      commit('updatePortalState', portalState);
    },
    changeAccount({commit}, account){
      commit('setAccount', account);
    },
    loginWithAccount({commit}, accountId){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        //console.log(req);
        axios({url: process.env.VUE_APP_IDENTITY_SERVICE_ENDPOINT + '/api/identity/v1/login/'+ accountId })
        .then(resp => {
          //alert(JSON.stringify(resp.data));
          const token = resp.data.token
          let usr = resp.data.user
          usr.preferences = resp.data.preferences
          console.log(usr);

          axios.defaults.headers.common['Authorization'] = 'bearer ' + token;
          
          axios({url: process.env.VUE_APP_IDENTITY_SERVICE_ENDPOINT + '/api/identity/v1/identities/'+ usr.email + '/' + usr.account + '/' + usr.parent + '?justPolicies=true' })
          .then(response => {
            usr.policies = response.data;
            commit('auth_success', token);
            commit('auth_setLoggedInWithAccount', true);
            commit('auth_setUser', usr);
            resolve(response)
          })
          .catch(err => {
            console.log(err);
            router.push('/')
            commit('auth_error')
            commit('logout')
            localStorage.removeItem('token')
            reject(err)
          })

          
          

        })
        .catch(err => {
          console.log(err);
          router.push('/')
          commit('auth_error')
          commit('logout')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    loginWithToken({commit}, data){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        
        console.log('loginWithToken:', data)
        const token = data.token
        let usr = data.user
        usr.preferences = data.preferences
        

        if(data.accounts){
          commit('auth_setAccounts', data.accounts);
          commit('setAccount', usr.account);
        }

        if(usr && usr.policies && usr.policies.length > 0){
          //alert(JSON.stringify(usr));
          commit('auth_success', token);
          commit('auth_setLoggedInWithAccount', true);
          commit('auth_setUser', usr);
          resolve(usr)
        }
        else{
          axios({url: process.env.VUE_APP_IDENTITY_SERVICE_ENDPOINT + '/api/identity/v1/identities/'+ usr.email + '/' + usr.account + '/' + usr.parent + '?justPolicies=true' })
          .then(response => {

            usr.policies = response.data;
            commit('auth_success', token);
            commit('auth_setLoggedInWithAccount', true);
            commit('auth_setUser', usr);
            resolve(response)
          })
          .catch(err => {
            console.log(err);
            router.push('/')
            commit('auth_error')
            commit('logout')
            localStorage.removeItem('token')
            reject(err)
          })

        }
         
      })
    },
    login({commit}, user){
      commit('setAccount', 0);

      return new Promise((resolve, reject) => {
        // console.log(process.env.VUE_APP_IDENTITY_SERVICE_ENDPOINT);
        // console.log(process.env.VUE_APP_EDGE_SERVICE_ENDPOINT);
        commit('auth_request')
        axios({url: process.env.VUE_APP_IDENTITY_SERVICE_ENDPOINT + '/api/identity/v1/login', data: user, method: 'POST' })
        .then(resp => {

          //console.log('authenticate response:', resp.data);
          if(resp.data.isTwoFactor){
            resolve(resp);
            return;
          }

          const token = resp.data.token
          const usr = resp.data.user
          const accounts = resp.data.accounts
          //console.log('accounts: ', resp.data.accounts);
          
          if(accounts.length == 0 ){
            commit('auth_error')
            commit('logout')
            reject(new Error('No dashboard account is associated with the user'));
            return;
          }

          //localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = 'bearer ' + token;
          
          commit('auth_success', token);
          commit('auth_setLoggedInWithAccount', false);
          commit('auth_setUser', usr);
          
          //console.log('login response: ', resp.data.user);

          commit('setPhotoURL', resp.data.user.photoURL);
          commit('auth_setAccounts', accounts);
          
          resolve(resp)
        })
        .catch(err => {
          router.push('/')
          commit('auth_error')
          commit('logout')
          localStorage.removeItem('token')
          //console.log(err)
          reject(err)
        })
      })
    },
    register({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: process.env.VUE_APP_IDENTITY_SERVICE_ENDPOINT + '/api/identity/v1/register', data: user, method: 'POST' })
        .then(resp => {
          const token = resp.data.token
          const user = resp.data.user
          localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = token
          commit('auth_success', token, user)
          commit('auth_setLoggedInWithAccount', false);
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err)
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    logout({commit}){
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  },
  getters : {
    portalState: state => state.portalState, 
    isLoggedIn: state => state.loggedInWithAccount, //!!state.token, 
    isHalfLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user,
    account: state => state.account,
    accounts: state => state.accounts,
    loggedInWithAccount: state => state.loggedInWithAccount,
    token: state => state.token,
    photoURL: state => state.photoURL,
    deployment: state => state.deployment
  }
})

// Subscribe to store updates
store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('store', JSON.stringify(state));
});

export default store;