<!--
01-16-2025 (By Behzad): Added datePickerChanged() to save the last selected date picker ID + Used this id in dashboardChanged() instead of defaultId.
08-12-2024 (By Behzad): Added logic in dashboardChanged() to update the date range based on the current date.
08-09-2024 (By Behzad): Modified adjustFilters() to skip the aggregated KPI filter adjustment.
07-18-2024 (By Behzad): Added @chart-export event to <bt-chart> and exportCreated().
06-18-2024 (By Behzad): Modified adjustFiltersWithDate() and adjustFilters() to adjust the logic for CalculatedTable's 2nd filter by considering hasFilter2 switch.
06-17-2024 (By Behzad): Modified adjustFiltersWithDate(), adjustFilters() and resetFilters() to adjust the CalculatedTable's 2nd filter as well.
-->
<template>
<v-container fluid class="px-3 py-3">
   <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
      <v-icon class="pr-1">dashboard</v-icon>
      <span>Dashboards</span>
   </h1>

   <v-row  align="center" class="pl-3" justify= "start">  
      <v-col xl="3" lg="3" md="3" sm="12" xs="12" v-show="hasUserPermission('contact-dashboards-view')">
         <v-autocomplete
          item-text="name"
          item-value="_id"
          v-model="selectedDashboard"
          @change="dashboardChanged"
          :items="dashboardList"
          label="Dashboard"
          single-line

        ></v-autocomplete>
      </v-col>

      <v-col xl="1" lg="1" md="2" sm="2" xs="2" v-show="canModify">
         <v-switch 
            v-model="canEdit"
            label="Edit"

         ></v-switch>
      </v-col> 

      <v-col xl="1" lg="1" md="2" sm="2" xs="2" v-show="canCreate">
         <bt-dashboard-save
            btn-icon-left="add"
            btn-role="saveas"
            btn-title="New"
            btn-color= "warning"
            :submit-func="saveNewDashboard"
            v-model="ddlNew"
            @submit="showDashboardSaveNewMessage"
         ></bt-dashboard-save>
      </v-col>

      <v-col xl="1" lg="1" md="2" sm="2" xs="2" v-show="canSave">
         <bt-dashboard-save
            btn-icon-left="save"
            btn-role="save"
            btn-title="Update"
            :submit-func="saveDashboard"
            v-model="ddl"
            @submit="showDashboardSaveMessage"
         ></bt-dashboard-save>
      </v-col>

      <v-col xl="1" lg="1" md="2" sm="2" xs="2" v-show="canSaveAs">
         <bt-dashboard-save
            btn-icon-left="content_copy"
            btn-role="saveas"
            btn-title="Clone"
            btn-color= "success"
            :submit-func="saveAsDashboard"
            v-model="ddl"
            @submit="showDashboardSaveAsMessage"
         ></bt-dashboard-save>
      </v-col>

      <v-col xl="1" lg="1" md="2" sm="2" xs="2" v-show="canDelete">
         <bt-dashboard-save
            btn-icon-left="delete"
            btn-role="delete"
            btn-title="Delete"
            btn-color= "error"
            :submit-func="deleteDashboard"
            v-model="ddl"
            @submit="showDashboardDeleteMessage"
         ></bt-dashboard-save>
      </v-col>

      <v-col xl="1" lg="1" md="2" sm="2" xs="2" >
         <v-switch 
            v-model="distinct"
            label="Distinct"

         ></v-switch>
      </v-col>

      <v-col xl="1" lg="1" md="2" sm="2" xs="2">
         <v-btn @click="exportToPDF" small >Generate PDF</v-btn>
      </v-col>   

   </v-row> 

   <v-container fluid class="px-0 py-0" id="element-to-convert">
      <v-row v-for = "(r, rIndex) in dashboard.layout" :key="rIndex">
         <v-col v-for = "(c, cIndex) in r" :key="cIndex"  :xl="c.cols" :lg="c.cols" md="12" sm="12" xs="12" >
            <v-card class="pb-0">
               <v-card-text class="pt-0 pb-0 px-0">
                  <bt-chart
                     :distinct="distinct"
                     :std-field-values="stdFieldValues"
                     :can-edit-chart="canEdit"
                     :can-edit-filter="true"
                     :data="dashboard.layout[rIndex][cIndex].chartData"
                     :beh-field-values="fieldValues"
                     :fields="importHeaders"
                     :is-admin="$store.getters.user.isPowerAdmin"
                     :max="4"
                     :preselected-fields="selectedHeaders"
                     :should-init="shouldInitFilterDefiner"
                     :charts-settings="chartsSettings"
                     v-model="dashboard.layout[rIndex][cIndex].chart"
                     active-tab="groupby"
                     @filter-change="filterChanged(c)"
                     @date-picker-change="datePickerChanged"
                     @dropdown-change="adjustFilters('dropdown-change', rIndex, cIndex, ...arguments)"
                     @chart-click="adjustFilters('chart-click', rIndex, cIndex, ...arguments)"
                     @chart-reclick="resetFilters(['Dropdown','DatePicker'])"
					 @chart-export="exportCreated"
                  ></bt-chart>
               </v-card-text>
               <v-card-action v-if="canEdit">
                  <v-row class="pl-2 pt-4">
                     <v-col cols="4" class="pt-0 pb-0">
                        <!-- <v-icon v-show="!canEdit" @click="cell_tabular(rIndex, cIndex)">table_chart</v-icon> -->
                        <v-icon @click="cell_delete(rIndex, cIndex)" v-show="canEdit">delete_forever</v-icon>
                        <v-icon @click="cell_copy(rIndex, cIndex)" v-show="canEdit">file_copy</v-icon>
                        <v-icon @click="cell_add(rIndex, cIndex)" v-show="cIndex == r.length-1 && canEdit" >add</v-icon>
                        <v-icon @click="cell_left(rIndex, cIndex)" v-show="cIndex > 0 && canEdit">keyboard_arrow_left</v-icon>
                        <v-icon @click="cell_right(rIndex, cIndex)" v-show="cIndex < r.length-1 && canEdit">keyboard_arrow_right</v-icon>
                        <v-icon @click="cell_down(rIndex, cIndex)" v-show="rIndex < dashboard.layout.length - 1 && canEdit">keyboard_arrow_down</v-icon>
                        <v-icon @click="cell_up(rIndex, cIndex)" v-show="rIndex > 0 && canEdit">keyboard_arrow_up</v-icon>
                        <v-icon @click="cell_return(rIndex, cIndex)" v-show="cIndex == r.length-1 && rIndex == dashboard.layout.length - 1 && canEdit">keyboard_return</v-icon>
                     </v-col>
                     <v-col cols="8" class="pt-0 pb-0">
                        <v-slider v-show="canEdit" v-model="c.cols" min="4" max="12" step="1" thumb-label ticks  />
                     </v-col>
                  </v-row>
               </v-card-action>
            </v-card>
         </v-col>
      </v-row>
   </v-container>

</v-container>
</template>

<script>
import axios from 'axios';
import BtChart from '../components/BtChart.vue';
import BtDashboardSave from '../components/BtDashboardSave.vue';
import { BtHelpers } from '../services/bt-helpers.js';
import { adjustFilter, adjustFilterWithImportId } from '../mixins/bt-mixin.js';
import html2pdf from "html2pdf.js";
import {
   format, addSeconds, addDays, addMonths,
   startOfDay, startOfYesterday, endOfYesterday, startOfWeek, startOfMonth, endOfMonth
} from "date-fns";

export default {
   name: 'BtHelpersConsumer',

   components: {
      BtChart,
      BtDashboardSave
   },

   data() {
      return {
         distinct: false,
         chartsSettings: null,
         selectedImportId: null,
         stdFieldValues: {},
         resetBtnDisabled: true,
         canEdit: false,
         btHelpers: null,
         chartData: [],
         fieldValues: {},
         importHeaders: [],
         selectedHeaders: [],
         shouldInitFilterDefiner: false,
         chart: {
            title: '',
            type: '',   //ColumnChart
            options: {},
            filter: {}
         },
         dashboard: {layout: [[]]},
         selectedDashboard: 0,
         dashboardList: [],
         ddl: {},
         ddlNew: {},
		 lastDatePickerId: 0
      };
   },

   computed: {
      account : function(){
         //console.log('user object: ', this.$store.getters.user);
         return this.$store.getters.user.account;
      },
      parent: function(){
         if(this.$store.getters.user.parent == 0 || this.$store.getters.user.parent == null)
         return 'null';
         return this.$store.getters.user.parent;
      },
      token() {
         return this.$store.getters.token;
      },
      canCreate(){
         if(this.hasUserPermission('contact-dashboards-create') && this.hasUserPermission('contact-dashboards-update'))
            return true;
            
         return false;
      },
      canModify(){
         if(this.ddl.author && this.hasUserPermission('contact-dashboards-create') && this.hasUserPermission('contact-dashboards-update'))
            return true;
            
         return false;
      },
      canSave(){
         if(this.ddl.author && this.ddl.author.trim().toLowerCase() === this.$store.getters.user.email.trim().toLowerCase() && this.hasUserPermission('contact-dashboards-update'))
            return true;

         return false;
      },
      canSaveAs(){
         if(this.ddl.author && this.hasUserPermission('contact-dashboards-create'))
            return true;

         return false;
      },
      canDelete(){
         if(this.ddl.author && this.ddl.author.trim().toLowerCase() === this.$store.getters.user.email.trim().toLowerCase() && this.hasUserPermission('contact-dashboards-delete') && this.dashboardList.length > 1)
            return true;

         return false;
      }
   },

   watch: {
      account: {
         async handler(val){
            
            try{

               const response = await axios({
                  url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/contact-service/v1/dashboards',
                  method: 'GET' 
               });

               this.dashboardList = response.data.sort(this.compare);
               this.filterDashboardListAgainstPreferences();
               
               this.selectedDashboard = this.dashboardList[0]._id;
               //this.dashboardChanged();

            } catch (error) {
               alert(JSON.stringify(error));
            } 
            
         },
         deep: true
      },
      canEdit: {
         handler (val) {
            //made sure all the slicing injections are removed
            //this.resetFilters(['Dropdown','DatePicker']);

            // if(val){
            //    if(localStorage.currentDashboard)
            //       this.dashboard = JSON.parse(localStorage.getItem("currentDashboard"));
            // }
         }
      },
      chartsSettings: {
         async handler() {
            await this.dashboardChanged();
         }
      },
      token: {
         immediate: true,
         async handler() {
            await this.init();
         }
      },
      dashboard: {
         immediate: true,
         deep: true,
         handler (val) {
            this.resetBtnDisabled = !val.resetFilters;
         }
      }
   },

   methods: {
      compare( a, b ) {
         if ( a.name < b.name ){
            return -1;
         }
         if ( a.name > b.name ){
            return 1;
         }
         return 0;
      },
      exportToPDF() {
         html2pdf(document.getElementById("element-to-convert"), {
            margin:        0.1,
            filename:      'dashboard.pdf',
            image:         { type: 'jpeg', quality: 0.98 },
            html2canvas:   { scale: 1 },
            pagebreak:     {mode: 'avoid-all'},
            jsPDF:         { unit: 'in', format: 'letter', orientation: 'l' }
         });

         alert('Completed the PDF generation!');
      },
      hasUserPermission: function(policyName){
         if(this.$store.getters.user.policies.includes(policyName)){
         return true;
         }

         return false;
      },
      async saveNewDashboard(ddlObject){
         //perform your DB actions here

         try{

            ddlObject.definition = JSON.stringify({ layout:[[ {cols:12, sm:12, chartData:[]} ]] });

            const response = await axios({
               url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + `/api/contact-service/v1/dashboards`,
               data: ddlObject,
               method: 'POST' 
            });

            this.ddlNew = {};

            const getResponse = await axios({
               url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/contact-service/v1/dashboards',
               method: 'GET' 
            });

            this.dashboardList = getResponse.data.sort(this.compare);
            this.filterDashboardListAgainstPreferences();

            this.selectedDashboard = this.dashboardList.find( item => item.name === ddlObject.name)._id;

            this.dashboardChanged();

            
         } catch (error) {
            return error.message;
         }

         return '';
      },
      showDashboardSaveNewMessage(ddlObject){
         alert(`${ddlObject.name} was successfully created.`);
      },
      async saveDashboard(ddlObject){
         //perform your DB actions here
         try{

            //made sure all the slicing injections are removed
            //this.resetFilters(['Dropdown','DatePicker']);

            this.ddl.definition = JSON.stringify(this.dashboard);
            ddlObject.definition = JSON.stringify(this.dashboard);

            const response = await axios({
               url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + `/api/contact-service/v1/dashboards/${this.selectedDashboard}`,
               data: ddlObject,
               method: 'PUT' 
            });
         
            
         } catch (error) {
            return error.message;
         }

         return '';
      },
      showDashboardSaveMessage(ddlObject){
         alert(`${ddlObject.name} was successfully saved.`);
      },
      async saveAsDashboard(ddlObject){
         //perform your DB actions here
         try{

            //made sure all the slicing injections are removed
            this.resetFilters(['Dropdown','DatePicker']);

            this.ddl.definition = JSON.stringify(this.dashboard);
            ddlObject.definition = JSON.stringify(this.dashboard);

            delete ddlObject._id;

            const response = await axios({
               url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + `/api/contact-service/v1/dashboards`,
               data: ddlObject,
               method: 'POST' 
            });

            const getResponse = await axios({
               url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/contact-service/v1/dashboards',
               method: 'GET' 
            });

            this.dashboardList = getResponse.data.sort(this.compare);
            this.filterDashboardListAgainstPreferences();

            this.selectedDashboard = this.dashboardList.find( item => item.name === ddlObject.name)._id;

            this.dashboardChanged();
            
         } catch (error) {
            return error.message;
         }

         return '';
      },
      showDashboardSaveAsMessage(ddlObject){
         alert(`${ddlObject.name} was successfully created.`);
      },
      async deleteDashboard(ddlObject){
         //perform your DB actions here
         try{
            //this.ddl.definition = JSON.stringify(this.dashboard);

            const response = await axios({
               url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + `/api/contact-service/v1/dashboards/${ddlObject._id}`,
               method: 'DELETE' 
            });

            const getResponse = await axios({
               url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/contact-service/v1/dashboards',
               method: 'GET' 
            });

            this.dashboardList = getResponse.data.sort(this.compare);
            this.filterDashboardListAgainstPreferences();

            this.selectedDashboard = this.dashboardList[0]._id;

            this.dashboardChanged();
            
         } catch (error) {
            return error.message;
         }

         return '';
      },
      
      showDashboardDeleteMessage(ddlObject){
         alert(`${ddlObject.name} was successfully deleted.`);
      },

      async dashboardChanged(){
         try{
               
            const response = await axios({
               url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + `/api/contact-service/v1/dashboards/${this.selectedDashboard}`,
               method: 'GET' 
            });

            this.ddl = response.data;
            this.dashboard = JSON.parse(this.ddl.definition);
            let defaultDateRange;

            //clear all the original filters that is saved by mistake to database
            this.dashboard.layout.forEach(layouts => {
               layouts.forEach(layout => {
                  layout.originalFilter = null;
                  layout.beforeClickFilter = null;
				  
                  if (layout.chart.type === 'DatePicker') {
				     if (this.lastDatePickerId && typeof this.lastDatePickerId === typeof layout.chart.defaultId)
                        defaultDateRange = this.getDefaultDateRange(this.lastDatePickerId, layout.chart.datePickerType);
                     else
						defaultDateRange = this.getDefaultDateRange(layout.chart.defaultId, layout.chart.datePickerType);
                  }
               })
            })
			
            if (defaultDateRange) {
               //alert('adjustFiltersWithDate:' + JSON.stringify(defaultDateRange));
               this.adjustFiltersWithDate(defaultDateRange);
            }            
         } catch (error) {
            alert(error.message);
         }

      },
	  datePickerChanged(val, id) {
         this.lastDatePickerId = id;
         this.adjustFiltersWithDate(val);
      },
      adjustFiltersWithDate(val) {
         // alert('in adjustFiltersWithDate(): val=' + JSON.stringify(val));
         if (this.canEdit) return;

         this.dashboard.layout.forEach(layouts => {
            layouts.forEach(layout => {
               const chart = layout.chart;
               if (chart.type === 'DatePicker' || chart.isImport)
                  return;
                  
               if (val) {
                  chart.filter = adjustFilter(
                     layout.originalFilter ? JSON.parse(layout.originalFilter) : chart.filter,
                     chart.filter,
                     val,
                     chart.type
                  );
               }

               if (!layout.originalFilter) {
                  layout.originalFilter = JSON.stringify(chart.filter);
                  // alert('in adjustFiltersWithDate(): layout.originalFilter=' + layout.originalFilter);
               }

               if (chart.type === 'KPI' || (chart.type === 'CalculatedTable' && chart.hasFilter2)) {
                  if (val)
                     chart.filter2 = adjustFilter(
                        layout.originalFilter2 ? JSON.parse(layout.originalFilter2) : chart.filter2,
                        chart.filter2,
                        val,
                        chart.type
                     );

                  if (!layout.originalFilter)
                     layout.originalFilter2 = JSON.stringify(chart.filter2);
               }
            });
         });

         this.dashboard.originalFiltersSaved = true;
      },
      findSelectedImport(val){
         // if(!this.selectedImportId)
         //    this.selectedImportId = this.stdFieldValues["importId"][0].value;

         this.dashboard.layout.forEach((layouts, i) => {
            layouts.forEach((layout, j) => {
               const chart = layout.chart;
               if(chart.type === 'Dropdown'){
                  if(val.importId){
                     this.selectedImportId = val.importId[0];
                  }
               }
            });
         });
         
      },
      adjustFilters(event, row, col, val) {
         // alert('in adjustFilters(): event=' + event + ', row=' + row + ', col=' + col + ', val=' + JSON.stringify(val));
         if (this.canEdit) return;

         if (!this.dashboard.originalFiltersSaved)
            this.adjustFiltersWithDate();

         this.findSelectedImport(val);
         
         this.dashboard.layout.forEach((layouts, i) => {
            layouts.forEach((layout, j) => {

               const chart = layout.chart;
               // alert('in adjustFilters(): chart.type=' + chart.type);
               if ((i === row && j === col) || chart.type === 'DatePicker' || chart.isImport)
                  return;
               // else if (event === 'dropdown-change' && chart.type === 'KPI')
               //    return;
               // else if (event === 'chart-click' && ['Dropdown','KPI'].includes(chart.type))
               // V240809: else if (event === 'chart-click' && chart.type === 'Dropdown')
               else if (event === 'chart-click' && (chart.type === 'Dropdown' || (chart.type === 'KPI' ))) //&& chart.kpiType === 'with-groupby'
                  return;
               else if (chart.type) {
                  // alert('in adjustFilters(): current filter for ' + chart.type + '=' + JSON.stringify(chart.filter));
                  
                  
                  if (!layout.originalFilter)
                     layout.originalFilter = JSON.stringify(chart.filter);

                  // save filter befor click just first time
                  if(!layout.beforeClickFilter && event === 'chart-click'){
                     layout.beforeClickFilter = JSON.stringify(chart.filter);
                  }

                  chart.filter = adjustFilter(
                     JSON.parse(layout.originalFilter),
                     chart.filter,
                     val,
                     chart.type,
                     this.selectedImportId
                  );

                  
                  console.log('layout.originalFilter:', layout.originalFilter);
                  console.log('filter:', JSON.stringify(chart.filter));
                  console.log('val:', JSON.stringify(val));


                  this.dashboard.resetFilters = true;

                  if (!layout.originalFilter2)
                     layout.originalFilter2 = JSON.stringify(chart.filter2);

                  // save filter befor click just first time
                  if(!layout.beforeClickFilter2 && event === 'chart-click'){
                     if (chart.type === 'KPI' || (chart.type === 'CalculatedTable' && chart.hasFilter2))
                        layout.beforeClickFilter2 = JSON.stringify(chart.filter2);
                  }

                  if (chart.type === 'KPI' || (chart.type === 'CalculatedTable' && chart.hasFilter2)) {
                     chart.filter2 = adjustFilter(
                        JSON.parse(layout.originalFilter2),
                        chart.filter2,
                        val,
                        chart.type,
                        this.selectedImportId
                     );

                     // alert('in adjustFilters(): new filter2=' + JSON.stringify(chart.filter2));
                  }
               }
            });
         });
      },

      resetFilters(excludedTypes) {

         this.dashboard.layout.forEach(layouts => {
            layouts.forEach(layout => {
               if (!excludedTypes.includes(layout.chart.type) && layout.originalFilter) {
                  
                  if(layout.beforeClickFilter){
                     layout.chart.filter = adjustFilterWithImportId(JSON.parse(layout.beforeClickFilter), this.selectedImportId);
                     layout.beforeClickFilter = null;
                  } 

                  //layout.chart.filter = adjustFilterWithImportId(JSON.parse(layout.originalFilter), this.selectedImportId);

                  //By Behzad: 6/17/2024
                  if(layout.beforeClickFilter2){
                     layout.chart.filter2 = JSON.parse(layout.beforeClickFilter2);
                     layout.beforeClickFilter2 = null;
                  } 

               }
            });
         });

         if (excludedTypes.length === 1)
            this.dashboard.resetFilters = false;
      },
      async init() {
         // alert('in watch: token=' + val);s
         this.btHelpers = new BtHelpers(this.token, true, true);
         this.stdFieldValues = await this.btHelpers.getFieldValues(this.btHelpers.getInitialFilter());
         this.importHeaders = await this.btHelpers.getImportHeaders(this.btHelpers.getInitialFilter(), true);
         this.selectedHeaders = await this.btHelpers.getSelectedHeaders(this.importHeaders, 7);
         this.chartsSettings = await this.btHelpers.getChartsSettings();
         this.shouldInitFilterDefiner = true;

      },
      async filterChanged(c) {
         //alert('in filterChanged(): ' + JSON.stringify(c));
         //c.chartData = await this.btHelpers.getData(c.chart.filter);
         c.chartData = [[]];
      },
      cell_tabular(rIndex, cIndex) {
         //alert(`Requested tabular view for row: ${rIndex} and column: ${cIndex}`);
      },
      async cell_left(rIndex, cIndex){
         const temp = this.dashboard.layout[rIndex][cIndex];
         this.dashboard.layout[rIndex][cIndex] = this.dashboard.layout[rIndex][cIndex-1];
         this.dashboard.layout[rIndex][cIndex-1] = temp;
         await this.filterChanged(this.dashboard.layout[rIndex][cIndex]);
         await this.filterChanged(this.dashboard.layout[rIndex][cIndex-1]);

      },
      async cell_right(rIndex, cIndex){
         const temp = this.dashboard.layout[rIndex][cIndex];
         this.dashboard.layout[rIndex][cIndex] = this.dashboard.layout[rIndex][cIndex+1];
         this.dashboard.layout[rIndex][cIndex+1] = temp;
         await this.filterChanged(this.dashboard.layout[rIndex][cIndex]);
         await this.filterChanged(this.dashboard.layout[rIndex][cIndex+1]);
      },
      async cell_add(rIndex, cIndex){
         this.dashboard.layout[rIndex].push({
                     cols: "4",
                     sm: "12",
                     chartData: [],
                     chart: {
                        title: '',
                        type: '',
                        options: {},
                        filter: {}
                     }
                  });
      },
      cell_delete(rIndex, cIndex){
         this.dashboard.layout[rIndex].splice(cIndex,1);
         if(this.dashboard.layout[rIndex].length == 0){
            this.dashboard.layout.splice(rIndex,1);
         }
      },
      cell_down(rIndex, cIndex){
         const temp = this.dashboard.layout[rIndex][cIndex];
         this.dashboard.layout[rIndex].splice(cIndex,1);
         this.dashboard.layout[rIndex+1].push(temp);

      },
      cell_up(rIndex, cIndex){
         const temp = this.dashboard.layout[rIndex][cIndex];
         this.dashboard.layout[rIndex].splice(cIndex,1);
         this.dashboard.layout[rIndex-1].push(temp);
      },
      cell_return(rIndex, cIndex){
         this.dashboard.layout.push([{
                     cols: "4",
                     sm: "12",
                     chartData: [],
                     chart: {
                        title: '',
                        type: '',
                        options: {},
                        filter: {}
                     }
                  }])
      },
      cell_copy(rIndex, cIndex){
         const temp = this.dashboard.layout[rIndex][cIndex];
         this.dashboard.layout[rIndex].splice(cIndex, 0, JSON.parse(JSON.stringify(temp)));
      },
      filterDashboardListAgainstPreferences(){
         const preferences = this.$store.getters.user.preferences;
         if(preferences.lsDashboards && preferences.lsDashboards.length > 0 && !this.$store.getters.user.isPowerUser && !this.$store.getters.user.isPowerAdmin && !this.$store.getters.user.isAdminUser){ 
            this.dashboardList = this.dashboardList.filter( item => {
               if(preferences.lsDashboards.includes(item.name)){
                  return true;
               }
               return false;
            }).sort(this.compare);
         }
      },
	  
	  exportCreated(val) {
         this.$emit('snackbar-event', val);
      },
	  
      getDefaultDateRange(defaultId, type) {
         // alert(`in getDateRange(): val=${defaultId}, type=${type}`);
         var today = new Date();
         var fd;
         var td;

         switch (defaultId) {
            case 'CR':
               return null;
            case "T": // today
               fd = startOfDay(today);
               break;
            case "Y": // yesterday
               fd = startOfYesterday();
               td = endOfYesterday();
               break;
            case "TW": //from Monday till today
               fd = startOfWeek(new Date(), { weekStartsOn: 1 });
               break;
            case "TM": //from first day of this month till today
               fd = startOfMonth(today);
               break;
            case "LW": //from Monday to Sunday of previous week
               td = addSeconds(startOfWeek(today, { weekStartsOn: 1 }), -1);
               fd = startOfWeek(td, { weekStartsOn: 1 });
               break;
            case "LM": //from first to last day of previous month
               var lm = addMonths(today, -1);
               fd = startOfMonth(lm);
               td = endOfMonth(lm);
               break;
            default:
               fd = startOfDay(addDays(today, -parseInt(defaultId)));
               break;
         }

         if (!td) td = today;

         if (type === 'month') {
            return [format(fd, 'yyyy-MM-dd'), format(td, 'yyyy-MM-dd')];
         } else
            return format(fd, 'yyyy-MM-dd');
      }	  
   },

   async created() {
      // if(localStorage.currentDashboard)
      //    this.dashboard = JSON.parse(localStorage.getItem("currentDashboard"));

      try{
            
         const response = await axios({
            url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/contact-service/v1/dashboards',
            method: 'GET' 
         });

         this.dashboardList = response.data.sort(this.compare);
         this.filterDashboardListAgainstPreferences();
         this.selectedDashboard = this.dashboardList[0]._id;
         //this.dashboardChanged();
         
      } catch (error) {
         //alert(JSON.stringify(error));
      }   
   }

}
</script>

<style scoped>

</style>