<template>
   <!-- BUGS:
   - Set the dialog tabs colors properly
   - in ComboList. when there is a value, set the focus so that the value can be modified
   -->
   <!-- CHARTS:
      TODO: apply date formats + colors
      All Charts: backgroundColor object
      ColumnChart: colors, series
      ComboChart: areaOpacity, crosshair object, dataOpacity
   -->
   <!-- 
      WHAT: A component to set chart options for BtDashboardDesigner component.
      WHO: Behzad Talebpour (btalebpour@mindfireinc.com)
      HISTORY:
      02/08/22(B0.5): Added dense prop + Added v-if to the H-Axis/V-Axis's v-card.
      02/07/22(B0.4): Removed width property from the General tab + Hid unimplemented tabs: H-Axes, V-Axes, Series +
         Added a description to all tabs.
      02/03/22(B0.3): Fixed typo "margine" + In the General tab, removed % from width +
         In the Chart Area tab, removed pixels from width and height.
      01/20/22(B0.2): Added @change to the textarea to handle modifying options manually +
         Added right and bottom options + Removed rules.required.
      01/20/22(B0.1): 1st release.
   -->
<div>
   <v-textarea clearable outlined
      append-icon="edit"
      :auto-grow="autoGrow"
      :class="myClass"
      :dense="dense"
      :hide-details="false"
      :label="label"
      :read-only="readOnly"
      :rows="rows"
      :rules="[rules.validJson]"
      v-model="serializedOptions"
      @click:append="editOptions"
      @click:clear="clearOptions"
      @change="serializedOptionsChanged"
   ></v-textarea>

   <v-dialog no-click-animation persistent scrollable
      max-width="980px"
      v-model="dialog"
   >
      <v-card flat>
         <v-card-title class="title grey--text darken-4 font-weight-bold">{{chartType}} Options</v-card-title>
         <v-card-text class="px-4 pb-1">
            <v-form lazy-validation
               ref="form"
               v-model="isFormValid"
            >
               <!-- background-color="grey lighten-2 accent-4" -->
               <v-tabs dark
                  class="elevation-2"
                  background-color="grey lighten-2 accent-4"
                  slider-color="black"
                  v-model="tab"
               >
                  <v-tab class="black--text">General</v-tab>
                  <v-tab v-if="isPie" class="black--text">{{chartType.replace('Chart','')}}</v-tab>
                  <v-tab v-if="isGeo" class="black--text">Geo</v-tab>
                  <v-tab v-else class="black--text">Chart Area</v-tab>
                  <!-- <v-tab v-if="isBar" class="black--text">H-Axes</v-tab> -->
                  <v-tab v-if="!isPie && !isGeo" class="black--text">H-Axis</v-tab>
                  <!-- <v-tab v-if="!isPie && !isGeo" class="black--text">V-Axes</v-tab> -->
                  <v-tab v-if="!isPie && !isGeo" class="black--text">V-Axis</v-tab>
                  <!-- <v-tab v-if="!isPie && !isGeo" class="black--text">Series</v-tab> -->
                  <v-tab class="black--text">Legend</v-tab>

                  <v-tabs-items v-model="tab">
                     <v-tab-item><!-- General tab -->
                        <v-card flat tile>
                           <v-card-text class="pt-0">
                              <v-row>
                                 <v-col :class="tabDescClass">To configure the elements related to all charts.</v-col>
                              </v-row>
                              <v-row><!-- for all charts -->
                                 <v-col cols="6" class="pt-0">
                                    <!-- :rules="[rules.number]" -->
                                    <v-text-field clearable persistent-hint
                                       ref="height"
                                       class="pt-0"
                                       hint="Height of the chart: pixels"
                                       type="number"
                                       :min="0"
                                       v-model="options.height"
                                    ></v-text-field>
                                 </v-col>
                                 <!-- <v-col cols="4" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="width"
                                       class="pt-0"
                                       hint="Width of the chart: pixels"
                                       v-model="options.width"
                                    ></v-text-field>
                                 </v-col> -->
                                 <v-col cols="6" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="bgColor"
                                       class="pt-0"
                                       :color="options.backgroundColor || ''"
                                       hint="Background Color: English color name or hex string"
                                       placeholder="default: white"
                                       v-model="options.backgroundColor"
                                    ></v-text-field>
                                 </v-col>
                              </v-row>
                              <v-row v-if="!isGeo">
                                 <v-col cols="6" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="fontName"
                                       class="pt-0"
                                       hint="Font name"
                                       placeholder="default: Arial"
                                       v-model="options.fontName"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col cols="6" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="fontSize"
                                       class="pt-0"
                                       hint="Font size: pixels"
                                       v-model="options.fontSize"
                                    ></v-text-field>
                                 </v-col>
                              </v-row>

                              <v-row v-if="!isPie && !isGeo">
                                 <v-col cols="4" class="pt-0">
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-0"
                                       hint="Axis Title Position"
                                       placeholder="default: out"
                                       :items="axisTitlePositionItems"
                                       v-model="options.axisTitlePosition"
                                    ></v-select>
                                 </v-col>
                                 <v-col cols="4" class="pt-0">
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-0"
                                       hint="Orientation"
                                       placeholder="default: horizontal"
                                       :items="orientationItems"
                                       v-model="options.orientation"
                                    ></v-select>
                                 </v-col>
                                 <v-col cols="4" class="pt-0">
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-0"
                                       hint="Theme"
                                       :items="themeItems"
                                       v-model="options.theme"
                                    ></v-select>
                                 </v-col>
                              </v-row>
                              <v-row v-if="isArea || isCombo || isLine">
                                 <v-col cols="4" class="pt-1">
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-0"
                                       hint="Determines whether points will be displayed"
                                       placeholder="default: true"
                                       :items="booleanItemsT"
                                       v-model="options.pointsVisible"
                                    ></v-select>
                                 </v-col>
                                 <v-col cols="4" class="pt-1">
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-0"
                                       hint="The shape of individual data elements"
                                       placeholder="default: circle"
                                       :items="pointShapeItems"
                                       v-model="options.pointShape"
                                    ></v-select>
                                 </v-col>
                                 <v-col cols="4" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="pointSize"
                                       class="pt-0"
                                       hint="Diameter of displayed points: pixels (Use 0 to hide all points)"
                                       placeholder="default: 0"
                                       type="number"
                                       :min="0"
                                       v-model="options.pointSize"
                                    ></v-text-field>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col cols="4" class="pt-1" v-if="isArea || isBar || isCombo || isColumn">
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-0"
                                       hint="Elements Stacking"
                                       placeholder="default: false"
                                       :items="isStackedItems"
                                       v-model="options.isStacked"
                                    ></v-select>
                                 </v-col>
                                 <v-col cols="4" class="pt-0" v-if="isBar || isColumn">
                                    <!-- for Material Bar Chart not the Classic one -->
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-2"
                                       hint="Bars"
                                       placeholder="default: vertical"
                                       :items="barItems"
                                       v-model="options.bars"
                                    ></v-select>
                                 </v-col>
                                 <v-col cols="4" class="pt-0" v-if="isCombo || isLine">
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-1"
                                       :hint="curveTypeHint"
                                       placeholder="default: none"
                                       :items="curveTypeItems"
                                       v-model="options.curveType"
                                    ></v-select>
                                 </v-col>
                                 <v-col cols="4" class="pt-0" v-if="isArea || isLine">
                                    <v-text-field clearable persistent-hint
                                       ref="lineWidth"
                                       class="pt-0"
                                       hint="Data line width: pixels (Use 0 to hide lines and show points only)"
                                       placeholder="default: 2"
                                       type="number"
                                       :min="0"
                                       v-model="options.lineWidth"
                                    ></v-text-field>
                                 </v-col>
                              </v-row>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item v-if="isPie"><!-- Pie/Donut tab -->
                        <v-card flat tile>
                           <v-card-text class="pt-0">
                              <v-row>
                                 <v-col :class="tabDescClass">To configure the elements related to Pie/Donut charts only.</v-col>
                              </v-row>
                              <v-row>
                                 <v-col cols="4" class="pt-0" v-if="chartType === 'DonutChart'">
                                    <v-text-field persistent-hint
                                       ref="pieHole"
                                       class="pt-0"
                                       hint="Pie Hole: between 0 and 1"
                                       placeholder="default: 0"
                                       type="number"
                                       :min="0.1"
                                       :max="0.9"
                                       :step=".1"
                                       :rules="[rules.between0And1]"
                                       v-model="options.pieHole"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col cols="4" class="pt-2" v-else>
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-0"
                                       hint="If true, displays a three-dimensional chart"
                                       placeholder="default: false"
                                       :items="booleanItemsF"
                                       v-model="options.is3D"
                                    ></v-select>
                                 </v-col>
                                 <v-col cols="4" class="pt-2">
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-0"
                                       :hint="pieSliceTextHint"
                                       placeholder="default: percentage"
                                       :items="pieSliceTextItems"
                                       v-model="options.pieSliceText"
                                    ></v-select>
                                 </v-col>
                                 <v-col cols="4" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="pieSliceBorderColor"
                                       class="pt-0"
                                       hint="Slice Border Color (only for 2-dim charts)"
                                       placeholder="default: white"
                                       v-model="options.pieSliceBorderColor"
                                    ></v-text-field>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col cols="4" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="pieStartAngle"
                                       class="pt-0"
                                       hint="Pie Start Angle: degrees"
                                       placeholder="default: 0"
                                       type="number"
                                       :min="0"
                                       :step=".25"
                                       v-model="options.pieStartAngle"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col cols="4" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="sliceVisibilityThreshold"
                                       class="pt-0"
                                       hint="The fractional value of the pie, below which a slice will not show individually"
                                       placeholder="default: half degree (.0014)"
                                       type="number"
                                       :min=".0014"
                                       v-model="options.sliceVisibilityThreshold"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col cols="4" class="pt-2">
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-0"
                                       hint="If true, draws slices counterclockwise"
                                       placeholder="default: false"
                                       :items="booleanItemsF"
                                       v-model="options.reverseCategories"
                                    ></v-select>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col cols="6" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="pieResidueSliceColor"
                                       class="pt-0"
                                       hint="Color for the combination slice that holds all slices below sliceVisibilityThreshold"
                                       placeholder="default: #ccc"
                                       v-model="options.pieResidueSliceColor"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col cols="6" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="pieResidueSliceLabel"
                                       class="pt-0"
                                       hint="A label for the combination slice that holds all slices below sliceVisibilityThreshold"
                                       placeholder="default: Other"
                                       v-model="options.pieResidueSliceLabel"
                                    ></v-text-field>
                                 </v-col>
                              </v-row>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item v-if="isGeo"><!-- Geo tab -->
                        <v-card flat tile>
                           <v-card-text class="pt-0">
                              <v-row>
                                 <v-col :class="tabDescClass">To configure the elements related to Geo charts only.</v-col>
                              </v-row>
                              <v-row>
                                 <v-col cols="4" class="pt-2">
                                    <v-select clearable dense hide-selected persistent-hint
                                       ref="displayMode"
                                       class="pt-0"
                                       hint="Type of GeoChart"
                                       placeholder="default: auto"
                                       :items="displayModeItems"
                                       v-model="options.displayMode"
                                    ></v-select>
                                 </v-col>
                                 <v-col cols="4" class="pt-1">
                                    <v-text-field clearable persistent-hint
                                       ref="datalessRegionColor"
                                       class="pt-0"
                                       hint="Color to assign to regions with no associated data"
                                       placeholder="default: #F5F5F5"
                                       v-model="options.datalessRegionColor"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col cols="4" class="pt-1">
                                    <v-text-field clearable persistent-hint
                                       ref="defaultColor"
                                       class="pt-0"
                                       hint="Color to use when the location is present but there is no value"
                                       placeholder="default: #267114"
                                       v-model="options.defaultColor"
                                    ></v-text-field>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col cols="4" class="pt-2">
                                    <v-select clearable dense hide-selected persistent-hint
                                       ref="geochartVersion"
                                       class="pt-0"
                                       hint="Keep Aspect Ratio"
                                       placeholder="default: true"
                                       :items="booleanItemsT"
                                       v-model="options.keepAscpectRatio"
                                    ></v-select>
                                 </v-col>
                                 <v-col cols="4" class="pt-1">
                                    <bt-combolist
                                       :debug="debug"
                                       hint="The area to display on the GeoChart"
                                       placeholder="default: world"
                                       :list-items="regionMenuItems"
                                       :rules="regionRules"
                                       :should-init="shouldInit"
                                       v-model="region"
                                    ></bt-combolist>
                                 </v-col>
                                 <v-col cols="4" class="pt-2">
                                    <v-select clearable dense hide-selected persistent-hint
                                       ref="resolution"
                                       class="pt-0"
                                       hint="The resolution of the geochart borders"
                                       placeholder="default: countries"
                                       :items="resolutionItems"
                                       v-model="options.resolution"
                                    ></v-select>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col cols="4" class="pt-2">
                                    <v-select clearable dense hide-selected persistent-hint
                                       ref="geochartVersion"
                                       class="pt-0"
                                       hint="Version of the GeoChart border data"
                                       placeholder="default: 10"
                                       :items="geochartVersionItems"
                                       v-model="options.geochartVersion"
                                    ></v-select>
                                 </v-col>
                                 <v-col cols="4" class="pt-2">
                                    <v-select clearable dense hide-selected persistent-hint
                                       ref="regioncoderVersion"
                                       class="pt-0"
                                       hint="The version of the regioncoder data"
                                       placeholder="default: 0"
                                       :items="regioncoderVersionItems"
                                       v-model="options.regioncoderVersion"
                                    ></v-select>
                                 </v-col>
                              </v-row>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item v-else><!-- Chart Area tab: All but Geo -->
                        <v-card flat tile>
                           <v-card-text class="pt-0">
                              <v-row>
                                 <v-col :class="tabDescClass">To configure the placement and size of the chart area (where the chart itself is drawn, excluding axis and legends).</v-col>
                              </v-row>
                              <v-row>
                                 <v-col cols="6" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="caLeft"
                                       class="pt-0"
                                       hint="How far to draw chart from the LEFT border: pixels"
                                       placeholder="left margin"
                                       type="number"
                                       :min="0"
                                       v-model="options.chartArea.left"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col cols="6" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="caTop"
                                       class="pt-0"
                                       hint="How far to draw chart from the TOP border: pixels"
                                       placeholder="top margin"
                                       type="number"
                                       :min="0"
                                       v-model="options.chartArea.top"
                                    ></v-text-field>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col cols="6" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="caRight"
                                       class="pt-0"
                                       hint="How far to draw chart from the RIGHT border: pixels"
                                       placeholder="right margin"
                                       type="number"
                                       :min="0"
                                       v-model="options.chartArea.right"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col cols="6" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="caBottom"
                                       class="pt-0"
                                       hint="How far to draw chart from the BOTTOM border: pixels"
                                       placeholder="bottom margin"
                                       type="number"
                                       :min="0"
                                       v-model="options.chartArea.bottom"
                                    ></v-text-field>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col cols="6" class="pt-0">
                                    <!-- TODO: add validation -->
                                    <v-text-field clearable persistent-hint
                                       ref="caWidth"
                                       class="pt-0"
                                       hint="Chart area width: % (only if the left and right margins are not specified)"
                                       placeholder="width"
                                       v-model="options.chartArea.width"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col cols="6" class="pt-0">
                                    <!-- TODO: add validation -->
                                    <v-text-field clearable persistent-hint
                                       ref="caHeight"
                                       class="pt-0"
                                       hint="Chart area height: % (only if the top and bottom margins are not specified)"
                                       placeholder="height"
                                       v-model="options.chartArea.height"
                                    ></v-text-field>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col cols="6" class="pt-0">
                                    <v-text-field clearable persistent-hint
                                       ref="caBgColor"
                                       class="pt-0"
                                       hint="Background Color: English color name or hex string"
                                       placeholder="default: white"
                                       v-model="options.chartArea.backgroundColor"
                                    ></v-text-field>
                                 </v-col>
                              </v-row>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <!-- H-Axes tab -->
                     <!-- <v-tab-item v-if="isBar">
                        <v-card flat tile>
                           <v-card-text class="pt-2">
                              <p class="mb-0">Specify properties for individual horizontal axes, if the chart has multiple horizontal axes.</p>
                              <v-textarea clearable dense outlined
                                 class="pt-4 pb-0"
                                 label="Specify properties for individual horizontal axes, if the chart has multiple horizontal axes"
                                 rows="4"
                                 :hide-details="false"
                                 :rules="[rules.validJson]"
                                 v-model="hAxes"
                              ></v-textarea>
                           </v-card-text>
                        </v-card>
                     </v-tab-item> -->

                     <v-tab-item v-if="!isPie && !isGeo"><!-- H-Axis tab -->
                        <v-card flat tile v-if="options.hAxis && options.hAxis.gridlines">
                           <v-card-text class="pt-0">
                              <v-row>
                                 <v-col :class="tabDescClass">To configure various horizontal axis elements</v-col>
                              </v-row>
                              <v-row>
                                 <!-- textStyle: { color: <string>, fontName: <string>, fontSize: <number>, bold: <boolean>, italic: <boolean> } -->
                                 <v-col xs="12" sm="12" md="3" lg="3">
                                    <v-text-field clearable persistent-hint
                                       ref="hAxisTitle"
                                       class="pt-0"
                                       hint="Title"
                                       v-model="options.hAxis.title"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="3" lg="3">
                                    <v-select clearable dense hide-selected persistent-hint
                                       ref="hAxisTextPosition"
                                       class="pt-2"
                                       hint="Text Position"
                                       placeholder="default: out"
                                       :items="axisTitlePositionItems"
                                       v-model="options.hAxis.textPosition"
                                    ></v-select>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="6" lg="6">
                                    <bt-combolist
                                       :debug="debug"
                                       hint="Labels format"
                                       placeholder="default: auto"
                                       :list-items="hAxisFormatMenuItems"
                                       :should-init="shouldInit"
                                       v-model="hAxisFormat"
                                    ></bt-combolist>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col xs="12" sm="12" md="6" lg="6">
                                    <v-text-field clearable persistent-hint
                                       ref="hAxisGridlinesColor"
                                       class="pt-0"
                                       hint="Gridlines Color: English color name or hex string"
                                       placeholder="default: #CCC"
                                       v-model="options.hAxis.gridlines.color"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="6" lg="6">
                                    <bt-combolist
                                       :debug="debug"
                                       hint="Gridlines Count/Spacing"
                                       placeholder="select an option"
                                       :list-items="gridlinesMenuItems"
                                       :should-init="shouldInit"
                                       v-model="hAxisGridlines"
                                    ></bt-combolist>
                                 </v-col>
                              </v-row>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>
                  
                     <!-- V-Axes tab -->
                     <!-- <v-tab-item v-if="!isPie && !isGeo">
                        <v-card flat tile>
                           <v-card-text class="pt-2">
                              <p class="mb-0">Specify properties for individual vertical axes, if the chart has multiple vertical axes.</p>
                              <v-textarea clearable dense outlined
                                 class="pt-4 pb-0"
                                 label="Specify properties for individual vertical axes, if the chart has multiple vertical axes"
                                 rows="4"
                                 :hide-details="false"
                                 :rules="[rules.validJson]"
                                 v-model="vAxes"
                              ></v-textarea>
                           </v-card-text>
                        </v-card>
                     </v-tab-item> -->

                     <v-tab-item v-if="!isPie && !isGeo"><!-- V-Axis -->
                        <v-card flat tile v-if="options.vAxis && options.vAxis.gridlines">
                           <v-card-text class="pt-0">
                              <v-row>
                                 <v-col :class="tabDescClass">To configure various vertical axis elements.</v-col>
                              </v-row>
                              <v-row>
                                 <v-col xs="12" sm="12" md="3" lg="3">
                                    <!-- textStyle: { color: <string>, fontName: <string>, fontSize: <number>, bold: <boolean>, italic: <boolean> } -->
                                    <v-text-field clearable persistent-hint
                                       ref="vAxisTitle"
                                       class="pt-0"
                                       hint="Title"
                                       v-model="options.vAxis.title"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="3" lg="3">
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-2"
                                       hint="Text Position"
                                       placeholder="default: out"
                                       :items="axisTitlePositionItems"
                                       v-model="options.vAxis.textPosition"
                                    ></v-select>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="6" lg="6">
                                    <bt-combolist
                                       :debug="debug"
                                       hint="Labels format"
                                       placeholder="select an option"
                                       :list-items="vAxisFormatMenuItems"
                                       :should-init="shouldInit"
                                       v-model="vAxisFormat"
                                    ></bt-combolist>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col xs="12" sm="12" md="6" lg="6">
                                    <v-text-field persistent-hint
                                       ref="vAxisGridlinesColor"
                                       class="pt-0"
                                       hint="Gridlines Color: English color name or hex string"
                                       placeholder="default: #CCC"
                                       v-model="options.vAxis.gridlines.color"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="6" lg="6">
                                    <bt-combolist
                                       :debug="debug"
                                       hint="Gridlines Count/Spacing"
                                       placeholder="select an option"
                                       :list-items="gridlinesMenuItems"
                                       :should-init="shouldInit"
                                       v-model="vAxisGridlines"
                                    ></bt-combolist>
                                 </v-col>
                              </v-row>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <!-- Series tab -->
                     <!-- <v-tab-item v-if="!isPie && !isGeo">
                        <v-card flat tile>
                           <v-card-text class="pt-2 pb-0">
                              <v-row>
                                 <v-col cols="12" class="py-0">
                                    <v-textarea clearable dense outlined
                                       class="pt-4 pb-0"
                                       label="Series Format"
                                       rows="2"
                                       :hide-details="false"
                                       :rules="[rules.validJson]"
                                       v-model="series"
                                    ></v-textarea>
                                 </v-col>
                              </v-row>
                              <v-row v-if="isCombo">
                                 <v-col class="pt-0">
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-0"
                                       hint="Series Type"
                                       :items="seriesTypeItems"
                                       v-model="options.seriesType"
                                    ></v-select>
                                 </v-col>
                              </v-row>
                           </v-card-text>
                        </v-card>
                     </v-tab-item> -->

                     <v-tab-item><!-- Legend tab -->
                        <v-card flat tile>
                           <v-card-text class="pt-0">
                              <v-row>
                                 <v-col :class="tabDescClass">To configure various aspects of the legend.</v-col>
                              </v-row>
                              <v-row v-if="isGeo">
                                 <v-col>
                                    <v-text-field clearable persistent-hint
                                       ref="numberFormat"
                                       class="pt-0"
                                       hint="A format string for numeric labels"
                                       v-model="options.legend.numberFormat"
                                    ></v-text-field>
                                 </v-col>
                              </v-row>
                              <v-row v-else>
                                 <v-col>
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-0 ma-2"
                                       hint="Position"
                                       placeholder="default: right"
                                       :items="legendPositionItems"
                                       v-model="options.legend.position"
                                    ></v-select>
                                 </v-col>
                                 <v-col>
                                    <v-select clearable dense hide-selected persistent-hint
                                       class="pt-0 ma-2"
                                       hint="Alignment"
                                       :placeholder="legendAlignmentPlaceholder"
                                       :items="legendAlignmentItems"
                                       v-model="options.legend.alignment"
                                    ></v-select>
                                 </v-col>
                              </v-row>
                              <!-- textStyle object to be implemented -->
                           </v-card-text>
                        </v-card>
                     </v-tab-item>
                  </v-tabs-items>
               </v-tabs>
            </v-form>
         </v-card-text>

         <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn text small
               class="pr-0"
               color="blue darken-1"
               @click="cancelClicked"
            >Cancel</v-btn>
            <v-btn text small
               color="blue darken-1"
               :disabled="isSaveDisabled"
               @click="saveClicked"
            >Save
            </v-btn>
         </v-card-actions>

      </v-card>
   </v-dialog>
</div>
</template>

<script>
import BtCombolist from '../components/BtCombolist.vue';

const NAME = 'BtChartOptions';
const CONTINENT_PATTERN = /^[0-9]{3}$/;
const COUNTRY_PATTERN = /^[A-Z]{2}$/;
const STATE_PATTERN = /^US-[A-Z]{2}$/;

class Options {
   constructor(options, chartType) {
      // alert('options=' + JSON.stringify(options));

      // All charts
      this.backgroundColor = options.backgroundColor || ''; //white
      this.height = options.height || '';
      // this.width = options.width || '';
      this.legend = new Legend(options.legend || {}, chartType);

      if (chartType === 'GeoChart') {
         this.datalessRegionColor = options.datalessRegionColor || ''; //#F5F5F5
         this.defaultColor = options.defaultColor || ''; //#267114
         this.displayMode = options.displayMode || ''; //auto
         this.geochartVersion = options.geochartVersion || ''; //10
         this.keepAscpectRatio = options.keepAscpectRatio || ''; //true
         this.regioncoderVersion = options.regioncoderVersion || ''; //0
         this.resolution = options.resolution || ''; //countries
         //region: a combolist
         //colorAxis
         //sizeAxis
      } else {
         this.chartArea = new ChartArea(options.chartArea || {});
         //colors
         this.fontName = options.fontName || '';   //Arial
         this.fontSize = options.fontSize || '';

         if (chartType === 'PieChart' || chartType === 'DonutChart') {
            if (chartType === 'PieChart')
               this.is3D = options.hasOwnProperty('is3D') ? options.is3D : '';  //false
            else
               this.pieHole = options.pieHole || ''; //0
            
            this.pieResidueSliceColor = options.pieResidueSliceColor || ''; //#ccc
            this.pieResidueSliceLabel = options.pieResidueSliceLabel || ''; //Other
            this.pieSliceBorderColor = options.pieSliceBorderColor || ''; //white
            this.pieSliceText = options.pieSliceText || ''; //percentage
            //pieSliceTextStyle
            this.pieStartAngle = options.pieStartAngle || ''; //0
            this.reverseCategories = options.hasOwnProperty('reverseCategories') ? options.reverseCategories : ''; //false
            //slices
            this.sliceVisibilityThreshold = options.sliceVisibilityThreshold || ''; //0.0014
         } else {
            this.axisTitlePosition = options.axisTitlePosition || '';   //out
            this.hAxis = new Axis(options.hAxis || {});
            this.orientation = options.orientation || '';   //horizontal
            this.series = options.series || {};
            this.theme = options.theme || '';
            this.vAxes = options.vAxes || {};
            this.vAxis = new Axis(options.vAxis || {});

            switch (chartType) {
               case 'AreaChart':
                  //crosshair
                  this.isStacked = options.hasOwnProperty('isStacked') ? options.isStacked : ''; //false
                  this.lineWidth = options.lineWidth || ''; //2
                  this.pointShape = options.pointShape || ''; //circle
                  this.pointSize = options.pointSize || ''; //0
                  this.pointsVisible = options.hasOwnProperty('pointsVisible') ? options.pointsVisible : ''; //true
                  break;
               case 'BarChart':
                  this.bars = options.bars || '';  //vertical
                  this.hAxes = options.hAxes || {};
                  this.isStacked = options.hasOwnProperty('isStacked') ? options.isStacked : ''; //false
                  break;
               case 'ComboChart':
                  //crosshair
                  this.curveType = options.curveType || ''; //none
                  this.isStacked = options.hasOwnProperty('isStacked') ? options.isStacked : ''; //false
                  this.pointShape = options.pointShape || ''; //circle
                  this.pointSize = options.pointSize || ''; //0
                  this.pointsVisible = options.hasOwnProperty('pointsVisible') ? options.pointsVisible : ''; //true
                  this.seriesType = options.seriesType || '';  //line
                  break;
               case 'ColumnChart':
                  this.bars = options.bars || '';  //vertical
                  this.isStacked = options.hasOwnProperty('isStacked') ? options.isStacked : ''; //false
                  break;
               case 'LineChart':
                  //crosshair
                  this.curveType = options.curveType || ''; //none
                  this.lineWidth = options.lineWidth || ''; //2
                  this.pointShape = options.pointShape || ''; //circle
                  this.pointSize = options.pointSize || ''; //0
                  this.pointsVisible = options.hasOwnProperty('pointsVisible') ? options.pointsVisible : ''; //true
                  break;
               default:
                  break;
            }
         }
      }
   }
}

class ChartArea {
   constructor(chartArea) {
      // alert('chartArea=' + JSON.stringify(chartArea));
      this.backgroundColor = chartArea.backgroundColor || '';  //white
      this.left = chartArea.left || '';
      this.top = chartArea.top || '';
      this.right = chartArea.right || ''; //not in the documentation
      this.bottom = chartArea.bottom || ''; //not in the documentation
      this.width = chartArea.width || '';
      this.height = chartArea.height || '';
   }
}

class Axis {
   constructor(axis) {
      // alert('axis=' + JSON.stringify(axis));
      this.format = axis.format || '';
      this.gridlines = new Gridlines(axis.gridlines || {});
      this.textPosition = axis.textPosition || ''; //out
      this.title = axis.title || '';
   }
}

class Gridlines {
   constructor(gridlines) {
      // alert('gridlines=' + JSON.stringify(gridlines));
      this.color = gridlines.color || ''; //#CCC
      this.count = gridlines.count || ''; //-1 (default) or > 1
      this.minSpacing = gridlines.minSpacing || ''; //40 for linear scales, 20 for log scales
   }
}

class Legend {
   constructor(legend, chartType) {
      // alert('legend=' + JSON.stringify(legend));
      if (chartType === 'GeoChart')
         this.numberFormat = legend.numberFormat || '';
      else {
         this.position = legend.position || ''; //right
         this.alignment = legend.alignment || '';
      }
   }
}

class CombolistVmodel {
   constructor() {
      this.text = '';
      this.list = '';
   }
}

export default {
   name: NAME,

   components: { BtCombolist },

   props: {
      value: {
         type: String,
         default: ''
      },
      autoGrow: {
         type: Boolean,
         default: false
      },
      chartType: {
         type: String,
         required: true
      },
      debug: {
         type: Boolean,
         default: false
      },
      dense: {
         type: Boolean,
         default: true
      },
      label: {
         type: String,
         default: 'Chart Options in JSON Format'
      },
      myClass: {
         type: String,
         default: "pt-2"
      },
      readOnly: {
         type: Boolean,
         default: false
      },
      removeIconColor: {
         type: String,
         default: "grey darken-1"
      },
      removeIconName: {
         type: String,
         default: "delete_forever"
      },
      required: {
         type: Boolean,
         default: false
      },
      rows: {
         type: Number,
         default: 2
      },
   },

   data() {
      return {
         rules: {
            // required: value => !!value || "Value is required!",
            requiredValues: values => values.length > 0 || "At least one value is required!",
            validJson: value => {
               try {
                  if (value) {
                     if (value.trim().indexOf('"') === 0)
                        return 'SyntaxError: Unexpected token in JSON at position 0';
                     JSON.parse(value);
                  }
                  return true;
               } catch (error) {
                  return error.toString();
               }
            },
            // number: value => !value || value.replace(/\+/g, '').replace(/\-/g, '').replace(/\./g, '') == value || "Value should be a number!",
            between0And1: value => (value > 0 && value < 1) || 'Value should be greater than 0 and less than 1!',
            world: value => value === 'World (default)' || 'Value is invalid!',
            continent: value => CONTINENT_PATTERN.test(value) || 'Continent code is invalid!',
            country: value => COUNTRY_PATTERN.test(value) || 'Country code is invalid!',
            state: value => STATE_PATTERN.test(value) || 'US state code is invalid!'
            // :,
            //:
         },
         dialog: false,
         isFormValid: false,
         isFormChanged: false,
         tab: null,
         options: new Options({}),
         axisTitlePositionItems: [
            { text: 'Out (default)', value: "out" },
            { text: 'In', value: "in" },
            { text: 'None', value: "none" },
         ],
         barItems: [
            { text: 'Vertical (default)', value: "vertical" },
            { text: 'Horizontal', value: "horizontal" }
         ],
         isStackedItems: [
            { text: 'False (default)', value: false },
            { text: 'True', value: true },
            { text: 'Percent', value: "percent" },
            { text: 'Relative', value: "relative" }
         ],
         vAxisFormatMenuItems: [
            { text: "None", list: 'none', hint: 'Displays numbers with no formatting (e.g., 8000000)', needsInput: false },
            { text: "Decimal", list: 'decimal', hint: 'Displays numbers with thousands separators (e.g., 8,000,000)', needsInput: false },
            { text: "Scientific", list: "scientific", hint: 'Displays numbers in scientific notation (e.g., 8e6)', needsInput: false },
            { text: "Currency", list: "currency", hint: 'Displays numbers in the local currency (e.g., $8,000,000.00)', needsInput: false },
            { text: "Percent", list: "percent", hint: 'Displays numbers as percentages (e.g., 800,000,000%)', needsInput: false },
            { text: "Short", list: "short", hint: 'Displays abbreviated numbers (e.g., 8M)', needsInput: false },
            { text: "Long", list: "long", hint: 'Displays numbers as full words (e.g., 8 million)', needsInput: false }
         ],
         hAxisFormatMenuItems: [],
         hAxisFormat: null,
         gridlinesMenuItems: [
            { 
               text: "Count",
               list: 'count',
               hint: 'Gridlines Count: Number of horizontal gridlines inside the chart area',
               needsInput: true,
               placeholder: 'specify a number (default: -1)',
               type: 'number',
               min: 1
            },
            { 
               text: "Minimum Spacing",
               list: 'minSpacing',
               hint: 'Gridlines Min Spacing: Minimum screen space, in pixels, between gridlines',
               needsInput: true,
               placeholder: 'specify a number (default: 40)',
               type: 'number',
               min: 0
            }
         ],
         hAxisGridlines: null,
         legendPositionItems: [
            { text: 'Below the chart', value: "bottom" },
            { text: 'Inside the chart', value: "in" },
            { text: 'No legend', value: "none" },
            { text: 'Right of the chart (default)', value: "right" },
            { text: 'Above the chart', value: "top" }
         ],
         legendAlignmentItems: [
            { text: 'Aligned to the start', value: "start" },
            { text: 'Centered', value: "center" },
            { text: 'Aligned to the end', value: "end" }
         ],
         orientationItems: [
            { text: 'Horizontal (default)', value: "horizontal" },
            { text: 'Vertical', value: "vertical" }
         ],
         themeItems: [
            { text: 'Maximized chart area', value: "maximized" }
         ],
         seriesTypeItems: [
            { text: 'Line (default)', value: "line" },
            { text: 'Area', value: "area" },
            { text: 'Bars', value: "bars" },
            { text: 'Candle Sticks', value: "candlesticks" },
            { text: 'Stepped Area', value: "steppedArea" }
         ],
         curveTypeItems: [
            { text: 'None (default)', value: "none", hint: 'Straight lines without curve' },
            { text: 'Function', value: "function", hint: 'The angles of the line will be smoothed' }
         ],
         pointShapeItems: [
            { text: 'Circle (default)', value: "circle" },
            { text: 'Triangle', value: "triangle" },
            { text: 'Square', value: "square" },
            { text: 'Diamond', value: "diamond" },
            { text: 'Star', value: "star" },
            { text: 'Polygon', value: "polygon" }
         ],
         booleanItemsT: [
            { text: 'True (default)', value: true },
            { text: 'False', value: false }
         ],
         booleanItemsF: [
            { text: 'True', value: true },
            { text: 'False (default)', value: false }
         ],
         vAxisFormat: null,
         vAxisGridlines: null,
         series: '',
         shouldInit: false,
         hAxes: '',
         vAxes: '',
         pieSliceTextItems: [
            { text: 'Percentage (default)', value: "percentage", hint: 'The percentage of the slice size out of the total' },
            { text: 'Value', value: "value", hint: 'The quantitative value of the slice' },
            { text: 'Label', value: "label", hint: 'The name of the slice' },
            { text: 'None', value: "none", hint: 'No text is displayed' }
         ],
         displayModeItems: [
            { text: 'Auto (default)', value: "auto", hint: 'Choose based on the format of the DataTable' },
            { text: 'Regions', value: "regions", hint: 'Color the regions on the geochart' },
            { text: 'Markers', value: "markers", hint: 'Place markers on the regions' },
            { text: 'Text', value: "text", hint: 'Label the regions with text from the DataTable' }
         ],
         geochartVersionItems: [
            { text: '10 (default)', value: "10" },
            { text: '11', value: "11" },
         ],
         region: null,
         regionMenuItems: [
            { 
               text: "World (default)",
               list: 'world',
               hint: 'Region: Entire world',
               needsInput: false
            },
            { 
               text: "Continent/Sub-continent",
               list: 'continent',
               hint: 'Region: A continent (e.g., "011" for Western Africa)',
               needsInput: true,
               placeholder: 'specify a 3-digit code',
               type: 'text',
            },
            { 
               text: "Country",
               list: 'country',
               hint: 'Region: A country (e.g., "AU" for Australia)',
               needsInput: true,
               placeholder: 'specify an ISO 3166-1 alpha-2 code',
               type: 'text',
            },
            { 
               text: "US State",
               list: 'state',
               hint: 'Region: A state in the United States',
               needsInput: true,
               placeholder: 'specify an ISO 3166-2:US code',
               type: 'text',
            }
         ],
         regionRules: [],
         regioncoderVersionItems: [
            { text: '0 (default)', value: 0 },
            { text: '1', value: 1 },
         ],
         resolutionItems: [
            { text: 'Countries (default)', value: 'countries', hint: 'Supported for all regions, except for US state regions' },
            { text: 'Provinces', value: 'provinces', hint: 'Supported only for country regions and US state regions. Not supported for all countries; please test a country to see whether this option is supported' },
            { text: 'Metros', value: 'metros', hint: 'Supported for the US country region and US state regions only' }
         ],
         tabDescClass: "font-weight-bold font-italic"
      }
   },

   computed: {
      isArea() {
         return this.chartType === 'AreaChart';
      },
      isBar() {
         return this.chartType === 'BarChart';
      },
      isColumn() {
         return this.chartType === 'ColumnChart';
      },
      isCombo() {
         return this.chartType === 'ComboChart';
      },
      isGeo() {
         return this.chartType === 'GeoChart';
      },
      isLine() {
         return this.chartType === 'LineChart';
      },
      isPie() {
         return this.chartType === 'PieChart' || this.chartType === 'DonutChart';
      },

      isSaveDisabled() {
         // return (!this.isFormValid || !this.isFormChanged);
         return !this.isFormValid;
      },

      curveTypeHint() {
         return 'Curve Type' + 
            (this.options.curveType ? ': ' + this.curveTypeItems.find(item => item.value === this.options.curveType).hint : '');
      },

      pieSliceTextHint() {
         return 'Slice Text' + 
            (this.options.pieSliceText ? ': ' + this.pieSliceTextItems.find(item => item.value === this.options.pieSliceText).hint : '');
      },

      legendAlignmentPlaceholder() {
         return 'default: ' + 
            (this.options.legend.position === 'bottom' ? 'center' : 'start');
      }
   },

   watch: {
      value: {
         immediate: true,
         handler(val) {
            // alert('in watch.value(): val=' + val);
            this.serializedOptions = val ? val : '';
            this.$forceUpdate();
         }
      },

      region: {
         immediate: false,
         deep: true,
         handler(val) {
            // alert('in watch.region(): val=' + JSON.stringify(val));
            const menuItem = this.regionMenuItems.find(item => item.list === val.list);
            // alert('menuItem=' + JSON.stringify(menuItem));
            if (menuItem) {
               // this.regionRules = menuItem ? [this.rules[menuItem.value]] : [];
               this.regionRules = [this.rules[menuItem.list]];
               // alert('this.rules[' + menuItem.value + ']=' + JSON.stringify(this.rules[menuItem.value]));
            } else
               this.regionRules = [];

            // alert('this.rules in region=' + JSON.stringify(this.rules));
            // alert('world=' + JSON.stringify(this.rules.world));
            // alert('regionRules=' + JSON.stringify(this.regionRules));
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug) {
            console.log(`-----${NAME} B0.5 says => ${msg}`);
            // alert(msg);
         }
      },

      serializedOptionsChanged(val) {
         // alert('in serializedOptionsChanged(): val=' + val);
         this.$emit('input', val);
      },

      editOptions() {
         this.isFormChanged = false;
         // this.options = new Options(this.value ? JSON.parse(this.value) : {}, this.chartType);
         this.options = new Options(this.serializedOptions ? JSON.parse(this.serializedOptions) : {}, this.chartType);
         this.log('in editOptions(): options=' + JSON.stringify(this.options));
         let menuItem;

         if (this.isGeo) {
            this.region = new CombolistVmodel();
            if (this.options.region) {
               this.region.text = this.options.region;
               menuItem = this.regionMenuItems.find(item => item.list === this.options.region);
               if (menuItem)
                  this.region.list = menuItem.list;
               else if (this.options.region.indexOf('US-') > -1)
                  this.region.list = 'state';
               else if (this.options.region.length === 2)
                  this.region.list = 'country';
               else
                  this.region.list = 'continent';
            }
            // alert('region='+JSON.stringify(this.region));
         } else {
            this.region = null;
         }

         this.hAxes = this.options.hAxes ? JSON.stringify(this.options.hAxes) : '';
         if (this.options.hAxis) {
            this.hAxisFormat = new CombolistVmodel();
            this.hAxisGridlines = new CombolistVmodel();
            if (this.options.hAxis.format) {
               menuItem = this.hAxisFormatMenuItems.find(item => item.list === this.options.hAxis.format);
               if (menuItem)
                  this.hAxisFormat = { text: menuItem.text, list: menuItem.list };
               else
                  this.hAxisFormat = { text: this.options.hAxis.format, list: 'date' };
            }

            // alert('this.hAxisFormat=' + JSON.stringify(this.hAxisFormat));

            //minSpacing overrides count
            if (this.options.hAxis.gridlines) {
               if (this.options.hAxis.gridlines.minSpacing) {
                  this.hAxisGridlines.list = 'minSpacing';
                  this.hAxisGridlines.text = this.options.hAxis.gridlines.minSpacing;
               } else if (this.options.hAxis.gridlines.count) {
                  this.hAxisGridlines.list = 'count';
                  this.hAxisGridlines.text = this.options.hAxis.gridlines.count;
               }
            }
         } else {
            this.hAxisFormat = null;
            this.hAxisGridlines = null;
         }

         this.vAxes = this.options.vAxes ? JSON.stringify(this.options.vAxes) : '';
         if (this.options.vAxis) {
            this.vAxisFormat = new CombolistVmodel();
            this.vAxisGridlines = new CombolistVmodel();
            if (this.options.vAxis.format) {
               menuItem = this.vAxisFormatMenuItems.find(item => item.list === this.options.vAxis.format);
               if (menuItem)
                  this.vAxisFormat = { text: menuItem.text, list: menuItem.list };
               else
                  this.vAxisFormat = { text: this.options.vAxis.format, list: 'date' };
            }

            if (this.options.hAxis.gridlines) {
               //minSpacing overrides count
               if (this.options.vAxis.gridlines.minSpacing) {
                  this.vAxisGridlines.list = 'minSpacing';
                  this.vAxisGridlines.text = this.options.vAxis.gridlines.minSpacing;
               } else if (this.options.vAxis.gridlines.count) {
                  this.vAxisGridlines.list = 'count';
                  this.vAxisGridlines.text = this.options.vAxis.gridlines.count;
               }
            }
         } else {
            this.vAxisFormat = null;
            this.vAxisGridlines = null;
         }

         this.series = this.options.series ? JSON.stringify(this.options.series) : '';

         // this.setFormatHintAndPlaceholder();
         this.shouldInit = true;
         this.dialog = true;
      },

      clearOptions() {
         this.$emit('input', '');
      },

      // resetClicked() { },

      cancelClicked() { 
         this.shouldInit = false;
         this.dialog = false;
      },

      saveClicked() {
         if (this.$refs.form.validate()) {
            // alert('in saveClicked(): hAxisGridlines=' + JSON.stringify(this.hAxisGridlines));
            let menuItem;
            if (this.hAxes)
               this.options.hAxes = JSON.parse(this.hAxes);
            else
               this.options.hAxes = {};

            if (this.options.hAxis) {
               if (this.hAxisFormat.text) {
                  menuItem = this.hAxisFormatMenuItems.find(item => item.list === this.hAxisFormat.list);
                  // alert('menuItem=' + JSON.stringify(menuItem) + 
                  //    '\nhAxisFormat=' + JSON.stringify(this.hAxisFormat));
                  this.options.hAxis.format = menuItem.needsInput ? this.hAxisFormat.text : this.hAxisFormat.list;
               } else 
                  this.options.hAxis.format = '';

               delete this.options.hAxis.gridlines.count;
               delete this.options.hAxis.gridlines.minSpacing;
               if (this.hAxisGridlines.text)
                  this.options.hAxis.gridlines[this.hAxisGridlines.list] = this.hAxisGridlines.text;
            }

            if (this.vAxes)
               this.options.vAxes = JSON.parse(this.vAxes);
            else
               this.options.vAxes = {};

            if (this.options.vAxis) {
               if (this.vAxisFormat.text) {
                  menuItem = this.vAxisFormatMenuItems.find(item => item.list === this.vAxisFormat.list);
                  // alert('menuItem=' + JSON.stringify(menuItem) + '\nvAxisFormat=' + JSON.stringify(this.vAxisFormat));
                  this.options.vAxis.format = menuItem.needsInput ? this.vAxisFormat.text : this.vAxisFormat.list;
               } else
                  this.options.vAxis.format = '';

               delete this.options.vAxis.gridlines.count;
               delete this.options.vAxis.gridlines.minSpacing;
               if (this.vAxisGridlines.text)
                  this.options.vAxis.gridlines[this.vAxisGridlines.list] = this.vAxisGridlines.text;
            }

            if (this.region) {
               if (this.region.text) {
                  menuItem = this.regionMenuItems.find(item => item.list === this.region.list);
                  // alert('menuItem=' + JSON.stringify(menuItem)  + '\nregion=' + JSON.stringify(this.region));
                  this.options.region = menuItem.needsInput ? this.region.text : this.region.list;
               } else
                  this.options.region = '';
            }

            if (this.series)
               this.options.series = JSON.parse(this.series);

            this.outOptions = this.getKeysWithValue(this.options);
            this.serializedOptions = Object.keys(this.outOptions).length ? JSON.stringify(this.outOptions) : '';
            // alert('serializedOptions=' + this.serializedOptions);
            // this.$emit('input', this.serializedOptions);
            this.serializedOptionsChanged(this.serializedOptions);
            this.cancelClicked();
         }
      },

      getKeysWithValue(inObj) {
         this.log('inObj:' + JSON.stringify(inObj) + '*');
         const outObj = {};
         let result, val;
         Object.keys(inObj).forEach(key => {
            val = inObj[key];
            // alert('key=' + key + ', val=' + val + ', type=' + typeof val);
            if (val || val === false || val === 0 || val === '0') {
               // alert('val was true');
               if (typeof val === 'object') {
                  result = this.getKeysWithValue(val);
                  if (Object.keys(result).length)
                     outObj[key] = result;
               } else
                  outObj[key] =  val;
            }
         });
         this.log('outObj=' + JSON.stringify(outObj) + '*');
         return outObj;
      }
   },

   created() {
      // alert(`in ${NAME}.created()`);
      this.hAxisFormatMenuItems.push(...this.vAxisFormatMenuItems,
         { 
            text: "Date...",
            list: "date",
            hint: 'Displays dates in the specified format',
            needsInput: true,
            placeholder: "specify dates format (e.g., 'MMM d, y')",
            type: 'text'
         }
      );
   },

   mounted() {
      // alert(`in ${NAME}.mounted(): isFormValid=${this.$refs.form.validate()}`);
   }
}
</script>
