<template>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width=width
      :max-width=width
      :position-x="x"
      :position-y="y">      
    <v-card class="px-2 pb-3" >
        <v-row>
            <v-col cols="12">
                <v-text-field v-if="noSearch==false" ref="searchText" hide-details single-line v-model="searchText" class="py-1 shrink" label="Search"
                    @keydown="onKeyDown"
                >
                    <template v-slot:append>
                    <v-icon>
                        search
                    </v-icon>
                    </template>
                </v-text-field>
            </v-col>
        </v-row>
        <div  style="overflow-y: auto;" :style="listItemsStyle">
            <button style="text-align:left;display: block;width:100%;" ref="listItems" :class="item == selectedItem?'list-item-select':''" @mouseover="onMouseOver($event,item)" @click="click($event,item)" v-for="item in effectiveData" :key="item.data">{{item.data}}</button>
        </div>
        
    </v-card>
    </v-menu>
</template>
<style scoped>
.list-item-select {
    background-color: #eeeeee;
}
</style>
<script>
export default {
    props: {
        value: {
            default: false,
        },
        attachID: {
            default: null,
        },
        width: {
            default: 100,
        },
        listData: {
            default:[],
        },
        height:{
            default: 300,
        },
        pos: {
            default: 1,
        },
        listFontSize: {
            default: null,
        },
        noSearch: {
            default: false,
        },
        // textOff: {
        //     default: "OFF",
        // },
        // textOn: {
        //     default: "ON",
        // },
        // textFontSize: {
        //     default: "0.8em",
        // },
        // textColor: {
        //     default: "white",
        // },
        // textOnColor: {
        //     default: "",
        // },
        // textOffColor: {
        //     default: "black",
        // },
        // offColor: {
        //     default: "#ccc",
        // },
        // onColor: {
        //     default: "#42d8a4",
        // },
        // color: {
        //     default: "rgb(245, 245, 245)",
        // },
        // delay: {
        //     default: "0.4",
        // }
    },
    data() {
        //let data  =[];
        return {
            menu: this.value,
            x: 0,
            y: 0,
            searchText: "",
            data: [],
            benched: 0,
            selectedItem: null,
        }
    },
    computed:{
        listItemsStyle() {
            var ret =  {
                "max-height": `${this.height}px`,
            }
            if(this.listFontSize!=null){
                ret["font-size"] = `${this.listFontSize}px`;
            }
            return ret;
        },
        effectiveData(){
            let data = [];
            if(this.searchText.trim()!=""){
                var t= this.searchText.toLowerCase();
                for(let d of this.data){
                    if(d.data.toLowerCase().indexOf(t)>=0){
                        data.push(d);
                    }
                }
            }else{
                data = this.data;
            }
            return data;
        }
    },
    mounted() {
        this.computeLocation();
    },
    methods: {
        computeLocation(){
            var elm = document.getElementById(this.attachID);
            var bounds = elm.getBoundingClientRect();
            //console.log(bounds);
            if(this.pos == 1){
                this.x = bounds.left;
                this.y = bounds.top + bounds.height;
            }
            if(this.pos == 2){
                this.x = bounds.left - (this.width * 0.8);
                this.y = bounds.top + bounds.height;
            }
        },
        buildData(){
            this.data = [];
            for(let x of this.listData){
                this.data.push({
                    data: x,
                });
            }
        },

        click($event,item){
            console.log("clikc",item);
            $event.preventDefault();
            $event.stopPropagation();
            this.menu = false;
            this.$emit("select",item.data);
        },
        onKeyDown(e){
            //console.log(e);
            if(e.key == "ArrowUp"){
                //e.preventDefault();e.stopPropagation();
                let index = this.effectiveData.indexOf(this.selectedItem);
                index--;
                if(index <0){
                    index = 0;
                }
                this.selectedItem = this.effectiveData[index];
                this.$refs.listItems[index].scrollIntoView({block: "center"});
            }
            if(e.key == "ArrowDown"){
                //e.preventDefault();e.stopPropagation();
                let index = this.effectiveData.indexOf(this.selectedItem);
                index++;
                if(index > this.effectiveData.length -1){
                    index = this.effectiveData.length -1 ;
                }
                this.selectedItem = this.effectiveData[index];        
                this.$refs.listItems[index].scrollIntoView({block: "center"});
            }
            if(e.key == "Enter"){
                if(this.selectedItem!=null){
                    this.menu = false;
                    this.$emit("select",this.selectedItem.data);
                }
            }
        },
        onMouseOver(e,item){
            //console.log("onMouseOver",item);
            this.selectedItem = item;
        }
    },
    watch: {
        value(newValue){
            if(newValue != this.menu){
                this.menu = newValue;
                if(this.menu == true){
                    this.computeLocation();
                    this.buildData();
                    this.searchText = "";
                    this.selectedItem = null;
                    if(this.noSearch == false){
                        this.$nextTick(()=>{
                            let inputEl = this.$refs.searchText.$el.querySelector('input');
                            setTimeout(()=>{
                                inputEl.select();
                            },100);
                        })
                    }
                }
            }
        },
        menu(newValue){
            // console.log("menu",newValue)
            this.$emit("input",newValue);
        }
    }
}
</script>
