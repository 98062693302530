<template>
    <label class="switch" :style="switchStyle">
        <input v-model="checkbox" type="checkbox" @click="toggleCheckbox">
        <div :style="sliderStyle" class="slider round">
            <span v-if="checkbox" class="switch-text">{{textOn}}</span>
            <span v-else class="switch-text">{{textOff}}</span>
        </div>
    </label>
</template>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: var(--switch-width);
  height: var(--switch-height);
}

.switch input {
  display: none;
}

.switch-text {
  font-size: var(--switch-text-font-size);
  vertical-align: middle;
  line-height: var(--switch-text-line-height);
  margin-left: var(--switch-text-margin-left);  
  margin-right: var(--switch-text-margin-right);  
  float: var(--switch-text-float);
  caret-color: transparent;
  color: var(--switch-text-color);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--slider-off-color);
  -webkit-transition: var(--slider-delay);
  transition: var(--slider-delay);
}

.slider:before {
  position: absolute;
  content: "";
  height: var(--slider-before-size);
  width: var(--slider-before-size);
  left: var(--slider-before-margin);
  bottom: var(--slider-before-margin);
  background-color: var(--slider-before-color);
  -webkit-transition: var(--slider-delay);
  transition: var(--slider-delay);
}

input:checked + .slider {
  background-color: var(--slider-on-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(var(--slider-tx));
  -ms-transform: translateX(var(--slider-tx));
  transform: translateX(var(--slider-tx));
}

.slider.round {
  border-radius: var(--slider-radius);
}

.slider.round:before {
  border-radius: 50%;
}
</style>
<script>
export default {
    props: {
        value: {
            default: false,
        },
        width: {
            default: 90,
        },
        height: {
            default: 34,
        },
        textOff: {
            default: "OFF",
        },
        textOn: {
            default: "ON",
        },
        textFontSize: {
            default: "0.8em",
        },
        textColor: {
            default: "white",
        },
        textOnColor: {
            default: "",
        },
        textOffColor: {
            default: "black",
        },
        offColor: {
            default: "#ccc",
        },
        onColor: {
            default: "#42d8a4",
        },
        color: {
            default: "rgb(245, 245, 245)",
        },
        delay: {
            default: "0.4",
        }
    },
    data() {
        return {
            checkbox: false,
        }
    },
    computed:{
        margin(){
            return Math.min(this.height/10,4);
        },
        txtMargin(){
            if(this.checkbox){
                return [0,this.height + this.margin]
            }else{
                return [this.height + this.margin,0]
            }
        },
        switchStyle(){
            return {
                //height: `${this.height}px`,
                // width: `${this.width}px`,
                "--switch-width": `${this.width}px`,
                "--switch-height": `${this.height}px`,
            }
        },
        sliderStyle(){
            let mx = this.txtMargin;
            let txtColor = this.textColor;
            if(this.checkbox){
                if(this.textOnColor!=""){
                    txtColor = this.textOnColor;
                }
            }else{
                if(this.textOffColor!=""){
                    txtColor = this.textOffColor;
                }
            }
            return {
                "--slider-before-margin": `${this.margin}px`,
                "--slider-before-size": `${this.height - (2*this.margin)}px`,
                "--slider-radius":`${this.height}px`,
                "--slider-delay": `${this.delay}s`,
                "--slider-tx":`${this.width - this.height}px`,
                "--switch-text-margin-left":`${mx[0]}px`,
                "--switch-text-margin-right":`${mx[1]}px`,
                "--switch-text-font-size": this.textFontSize,
                "--switch-text-line-height": `${this.height}px`,
                "--switch-text-float": this.checkbox? "right": "left",
                "--switch-text-color": txtColor,
                // "--slider-bg-color": this.sliderBGColor,
                "--slider-off-color": this.offColor,
                "--slider-on-color": this.onColor,
                "--slider-before-color": this.color,
            }
        }
    },
    mounted() {
        this.checkbox = this.value;
    },
    methods: {
        toggleCheckbox() {
            this.checkbox = !this.checkbox
            this.$emit('input', this.checkbox)
            this.$emit('change', this.checkbox)
        }
    },
    watch: {
        value(newValue){
            if(newValue != this.checkbox){
                this.checkbox = newValue;
            }
        }
    }
}
</script>
