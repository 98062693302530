import axios from "axios";
import utils from './KCUtils.js'
import csvUtils from "./KCCSVUtils.js"
const app = {
  passUI: {
    appCode: "700",
    consumerCode:"7b0ce1aec8f6c28ee79ffbc8c28ec56a",
    pushMessageHistory: "pushMessageHistory",
    passData: "passData",
    config: "config",
    geofencing: "geofencing",
    locations: "location",
    preferences: "preference",
  }      
}

console.log("KCMixins loading");

export default {
  data: () => ({
    mixinsCount: 0,  
    app:app,
  }),
  methods: {
    getListServiceEndPoint(){  // store point to this.$store
      // var davinciUrl = store.getters.deployment.dvURL;
      // let server = this.getServer();
      // let lsEndPoint = "https://services.mdl.io";
      // if(server=="stage"){
      //     lsEndPoint = "https://services-stg.mdl.io";
      // }       
      // return lsEndPoint;
      return process.env.VUE_APP_EDGE_SERVICE_ENDPOINT;
    },
    getServer(){
      var davinciUrl = this.$store.getters.deployment.dvURL;
      let server = "prod";
      if(davinciUrl.indexOf("davincistage")>=0){
          server = "stage";
      }               
      return server;
    },    
    getAppDataEndpoint() {
      return `${this.getListServiceEndPoint()}/api/contact-service/v1/app-data`
    },
    getCSVProcessorEndpoint(){
      return utils.getServerConfig(utils.getServerMode("mindfireOne")).endPoints.mindfireOne + "/api/csvprocessor";
    },
    async getPreSignedURL(type,suffix,_keyPath="appData"){
      let csvProcessorEndpoint = this.getCSVProcessorEndpoint();
      try{  
        let keyPath = `${_keyPath}`;
        var resp = await axios.get(`${csvProcessorEndpoint}?cmd=presignedurl&contentType=${type}&suffix=${suffix}&keyPath=${keyPath}`);
        console.log("getPreSignedURL",resp);
        // this.dialog = false;
        // this.$emit('submit',{id:insertID});
        // run now 
        return resp.data.result;
      }catch(ex){
          console.log(ex);
      }                   
    },     
    getCleanAxios(){
      let axiosInstance = axios.create();
      delete axiosInstance.defaults.headers.common['Authorization'];
      return axiosInstance;
    },      
    async appUIHandleError(ex){
      console.log(ex);
      if(ex.response.status == 404){
          return [];
      }
      if(ex.response.status == 401){
          if(this.handler401){
              console.log("coll 401 handler")
              return await this.handler401();
          }
      }
      //if(ex.response.status == 401){
      var result = await this.$swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: ex.response.data,
        ... csvUtils.getSwalYesNoOption(),
        confirmButtonText: "Ok",
      });
      return null;
      //}
      
    },
    async appUIGet(type,_find = {},_param = {}, _headers={},_axios = null){ // {find,limit,skip}
      if(_axios == null){
        _axios = axios;
      }
      let params = {
        consumerCode: app[this.appName].consumerCode,
        find: {type, appCode: app[this.appName].appCode, isActive: true, ... _find} ,limit: 50, skip: 0,
        ... _param,
      }
      let appDataEndpoint = this.getAppDataEndpoint();
      console.log("appUIGet-params",params);
      //console.log("appUIGet","listServiceEndPoint",appDataEndpoint);
      try{
        const response = await _axios.get(appDataEndpoint,{params,headers:_headers});
        console.log("appUIGet",response.data);
        return response.data;
      }catch(ex){
        return await this.appUIHandleError(ex);
      }
    },    
    async appUIPost(type,name,definition = {},_param={},_headers={},_axios = null){ // {find,limit,skip}
      if(_axios == null){
        _axios = axios;
      }
      let data = {
        consumerCode: app[this.appName].consumerCode,
        appCode: app[this.appName].appCode,
        name,definition,
        type,
        isActive: true,
        ... _param,
      }
      let appDataEndpoint = this.getAppDataEndpoint();
      //console.log("appUIPost","listServiceEndPoint",appDataEndpoint);
      try{
        const response = await _axios.post(appDataEndpoint,data,{
          maxBodyLength: 104857600, //100mb
          maxContentLength: 104857600, //100mb
          headers: _headers,
        });
        console.log("appUIPost",response.data);
        return response.data;
      }catch(ex){
        return await this.appUIHandleError(ex);
      }
    },
    async appUIUpdate(_id,definition,_param = {},_headers={},_axios = null){ 
      if(_axios == null){
        _axios = axios;
      }
      let params = {
        consumerCode: app[this.appName].consumerCode,
      }
      let data = {
        ... _param,
      }
      if(definition!=null){
        data.definition = definition;
      }
      let appDataEndpoint = `${this.getAppDataEndpoint()}/${_id}`;
      //console.log("appUIDelete","listServiceEndPoint",appDataEndpoint);
      try{
        const response = await _axios.put(appDataEndpoint,data,{
          params,
          maxBodyLength: 104857600, //100mb
          maxContentLength: 104857600, //100mb
          headers: _headers,
        });
        console.log("appUIUpdate",response.data);
        return response.data;
      }catch(ex){
        return await this.appUIHandleError(ex);
      }
    },            
    async appUIDelete(_id){ // PUT isActive = false
      return await this.appUIUpdate(_id,null,{isActive: false})
    },
    async swalError(message){
      var result = await this.$swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: message,
        ... csvUtils.getSwalYesNoOption(),
        confirmButtonText: "Ok",
      });
    },
    showProgressSwal(title,_html,callback,showCancelButton = true){
      var html = _html?_html:""
      +'<div class="container" style="padding:0px;">'
      +   '<div style="padding-bottom:5px;"><span class="progress"></span></div>'
      +    '<div class="meter">'
      +        '<span style="width: 0%"></span>'
      +    '</div>'
      +'</div>';
      this.$swal.fire({
          title: title,
          html: html,
          showCancelButton: showCancelButton,
          showConfirmButton: false,
      }).then((result)=>{
          if(callback){
              callback(result);
          }
      });
      this.$swal.showLoading()
    },
      async updateProgressSwal(title,progressPercentage){
          if(title){
              this.$swal.getTitle().textContent = title;
          }
          if(progressPercentage){
            var span = this.$swal.getHtmlContainer().querySelector('span.progress');
            span.textContent = `${progressPercentage.toFixed(2)}%`;
            var meter = this.$swal.getHtmlContainer().querySelector('div.meter > span');
            meter.style.width = `${progressPercentage.toFixed(2)}%`;
          }
      },
      async closeProgressSwal(){
          this.$swal.close();
      },
    json2QueryString(jsonParam) {
      return Object.entries(jsonParam).map(([key, value]) => {
        if (Array.isArray(value)) {
          // Handle array values
          return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
        } else {
          // Handle non-array values
          return `${key}=${encodeURIComponent(value)}`;
        }
      }).join('&');
    },
    getImportsEndpoint() {
      return `${this.getListServiceEndPoint()}/api/contact-service/v1/imports`
    },
    async importsGet(_find = {},_param = {}){ // {find,limit,skip}
      let params = {
        find: [{$match:{status: 'completed', ... _find}}], 
        limit: 20, 
        skip: 0,
        ... _param,
      }
      const urlEncodedParam = this.json2QueryString(params)
      let importsEndpoint = this.getImportsEndpoint()+'?'+urlEncodedParam;
      console.log("importsEndpoint", importsEndpoint);
      try{
        const response = await axios.get(importsEndpoint);
        console.log("importsGet",response.data);
        return response.data;
      }catch(ex){
        return await this.appUIHandleError(ex);
      }
    },
    getExportsEndpoint() {
      return `${this.getListServiceEndPoint()}/api/contact-service/v1/exports`
    },
    async exportsGet(_find = {},_param = {}){ // {find,limit,skip}
      let params = {
        find: [{$match:{status: 'completed', ... _find}}], 
        limit: 20, 
        skip: 0,
        ... _param,
      }
      const urlEncodedParam = this.json2QueryString(params)
      let exportsEndpoint = this.getExportsEndpoint()+'?'+urlEncodedParam;
      console.log("exportsEndpoint", exportsEndpoint);
      try{
        const response = await axios.get(exportsEndpoint);
        console.log("exportsGet",response.data);
        return response.data;
      }catch(ex){
        return await this.appUIHandleError(ex);
      }
    },
    getDownloadsEndpoint() {
      return `${this.getListServiceEndPoint()}/api/contact-service/v1/downloads`
    },
    async downloadsGet(_id){ // {find,limit,skip}
      let downloadsEndpoint = this.getDownloadsEndpoint()+'/'+_id;
      console.log("downloadsEndpoint", downloadsEndpoint);
      try{
        const response = await axios.get(downloadsEndpoint);
        console.log("downloadsGet",response.data);
        return response.data;
      }catch(ex){
        return await this.appUIHandleError(ex);
      }
    },    
    fromNow(date){
      return utils.fromNow(date);
    },
    async ListServiceLogin(email="testuser@mindfiremail.info",password="MindFire2022@"){
      let loginEndpoint = this.getListServiceEndPoint() + "/api/identity/v1/login";
      console.log(loginEndpoint);
      let ret = await axios.post(loginEndpoint,{"email":email,"password":password,"isCode":false});
      return ret.data;
    },    
    async ListServiceLoginWithCode(email="testuser@mindfiremail.info",code){
      let loginEndpoint = this.getListServiceEndPoint() + "/api/identity/v1/login";
      console.log(loginEndpoint);
      let ret = await axios.post(loginEndpoint,{"email":email,"password":code,"isCode":true});
      return ret.data;
    },    
    // assume token in axios
    async ListServiceSelectAccount(accountID){
      let loginEndpoint = this.getListServiceEndPoint() + "/api/identity/v1/login";
      let ret = await axios.get(loginEndpoint + `/${accountID}`);
      // console.log(ret.data);
      return ret.data;
    },

    // set token at default axios
    async ListServiceSetAxiosToken(token){
        axios.defaults.headers.common['Authorization'] = `bearer ${token}`;
    },
  },

  computed: {
  },
  mounted() {
    console.log("mixin mounted",this.mixinsCount++);
  },
  watch: {
  }
}