/*jshint esversion: 11 */
// V240718.1: 1st release.

class ExportFormData {
   constructor(initData) {
      this.name = initData?.name || '';
      this.delimiter = initData?.delimiter || 'csv';
      // this.exportFields = initData?.exportFields || [];
      this.aggregateQuery = initData?.aggregateQuery || [];
      this.notifications = initData?.notifications || [];
      this.fieldDefinitions = initData?.fieldDefinitions || [];
      this.debug = false;
      this.shouldZip = initData?.shouldZip || false;
      // this.publicKey = initData?.publicKey || '';
      // this.actionIds = initData?.actions ? initData.actions.map(a => a.actionId) : [];
   }
}

export { ExportFormData };