<template>
<div>
   <!-- 
   WHAT: A child component to get export definitions (currently for the import's auto-export).
   HISTORY: no-version
   - V230411.1: Changed initData.zip to initData.shouldZip.
   - V230323.1: Consumed hasEvents prop of bt-export-field-definer component. 
   - V230315.1: 1st release. 
   -->
   <v-tooltip left>
      <template v-slot:activator="{ on }">
         <v-btn text icon
            v-on="on"
            @click="btnClicked"
         >
            <v-icon>{{btnIconName}}</v-icon>
         </v-btn>
      </template>
      <span>{{btnTooltip}}</span>
   </v-tooltip>


      <v-form lazy-validation
         ref="mainForm"
         v-model="isMainFormValid"
      >
      <v-dialog persistent no-click-animation
         max-width="1080px"
         v-model="dialog"
      >
         <v-card flat>
            <v-card-title class="title grey--text darken-4 font-weight-bold pb-2">{{dialogTitle}}:</v-card-title>
            <v-card-text class="pt-2 pb-0">
               <v-row>
                  <v-col xs="12" sm="12" md="12" class="py-0 pr-0">
                     <v-card flat class="py-0">
                        <v-card-title class="mx-0 my-0 px-0 py-0">
                           <div class="flex-grow-1"></div>
                           <bt-export-field-definer
                              :debug="debug"
                              :items="exportFields"
                              :has-events="false"
                              v-model="formData.fieldDefinitions"                        
                              @change="fieldDefinitionsChanged"
                           ></bt-export-field-definer>
                        </v-card-title>
                     </v-card>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col xs="12" sm="12" md="12" class="py-0">
                     <!-- text--disabled font-weight-thin font-weight-light -->
                     <v-textarea autofocus outlined readonly required hide-details
                        class="py-0 my-0 caption font-weight-thin"
                        ref="fieldDefinitions"
                        label="* Field Definitions:"
                        rows="3"
                        :rules="[rules.requiredStringArray]"
                        v-model="fieldDefinitions"
                     ></v-textarea>
                  </v-col>
               </v-row>

               <v-row class="pt-4">
                  <v-col xs="12" sm="12" md="12" class="py-0 pr-0">
                     <v-card flat class="py-0">
                        <v-card-title class="mx-0 my-0 px-0 py-0">
                           <div class="flex-grow-1"></div>
                           <bt-notifications
                              :debug="debug"
                              :max="1"
                              :required="false"
                              v-model="formData.notifications"
                              @change="notificationsChanged"
                           ></bt-notifications>
                        </v-card-title>
                     </v-card>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col xs="12" sm="12" md="12" class="py-0">
                     <v-textarea outlined readonly hide-details
                        class="py-0 my-0 caption font-weight-thin"
                        ref="notifications"
                        label="Notifications:"
                        rows="3"
                        v-model="notifications"
                     ></v-textarea>
                  </v-col>
               </v-row>

               <v-row class="pt-4">
                  <v-col xs="12" sm="12" md="3" class="pt-1 pb-0 pl-4">
                     <v-select dense persistent-hint hide-selected required
                        ref="delimiter"
                        hint="* File Delimiter"
                        :items="delimiterItems"
                        v-model="formData.delimiter"
                     ></v-select>
                  </v-col>
                  <v-col xs="12" sm="12" md="2" class="py-0">
                     <v-switch
                        :label="`Zip: ${formData.shouldZip ? 'Yes' : 'No'}`"
                        v-model="formData.shouldZip"
                     ></v-switch>
                  </v-col>
               </v-row>
            </v-card-text>

            <v-card-actions class="pt-0 pr-4">
               <div class="flex-grow-1"></div>
               <v-btn text
                  color="blue darken-1"
                  @click="cancelExport"
               >Cancel</v-btn>
               <v-btn text
                  color="blue darken-1"
                  :disabled="!isMainFormValid"
                  @click="saveExport"
               >Save</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-form>
</div>
</template>

<script>
import BtExportFieldDefiner from "./BtExportFieldDefiner.vue";
import BtNotifications from './BtNotifications.vue';

class FormData {
   constructor(initData) {
      // alert('initData=' + JSON.stringify(initData));
      this.fieldDefinitions = initData.fieldDefinitions || [];
      this.notifications = initData.notifications || [];
      this.delimiter = initData.delimiter || 'csv';
      this.shouldZip = initData.shouldZip || false;
   }
}

const NAME = 'BtExportChild';

export default {
   name: NAME,

   components: {
      BtExportFieldDefiner,
      BtNotifications
   },

   props: {
      value: {
         type: Object,
         default: () => {}
      },
      btnIconName: {
         type: String,
         default: "more_vert"
      },
      btnTooltip: {
         type: String,
         default: "click to define export"
      },
      debug: {
         type: Boolean,
         default: false
      },
      dialogTitle: {
         type: String,
         default: "Configure the Auto Export"
      },
      exportFields: {
         type: Array,
         required: true
      }
      // source: {
      //    type: String,
      //    default: 'import',
      //    validator: value => ['import', 'export'].indexOf(value) > -1
      // }
   },

   data() {
      return {
         delimiterItems: [
            { text: 'Comma', value: 'csv' },
            { text: 'Tab', value: 'tab' },
            { text: 'Pipe', value: 'pipe' }
         ],
         rules: {
            requiredStringArray: value => !!JSON.parse(value).length || "Field Definitions are required!",
         },
         dialog: false,
         formData: new FormData({}),
         isMainFormValid: true,
         fieldDefinitions: null,
         notifications: null,
      }
   },

   computed: {},

   watch: {},

   methods: {
      btnClicked() {
         this.formData = new FormData(this.value);
         // alert('formData=' + JSON.stringify(this.formData))
         this.fieldDefinitions = JSON.stringify(this.formData.fieldDefinitions);
         this.notifications = JSON.stringify(this.formData.notifications);
         this.dialog = true;
      },

      fieldDefinitionsChanged(fieldDefinitions) {
         this.fieldDefinitions = JSON.stringify(fieldDefinitions);
      },

      notificationsChanged(notifications) {
         this.notifications = JSON.stringify(notifications);
      },

      saveExport() {
         this.$emit('input', this.formData);
         this.$emit('change', this.formData);
         this.cancelExport();
      },

      cancelExport() {
         this.dialog = false;
      }
   },

   created() {},

   mounted() {}
};
</script>

<style scoped>
.v-text-field >>> .v-label {
    font-size: 20px;
    color: black;
    font-weight: 400 !important;
}
</style>
