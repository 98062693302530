<template>
   <!-- HISTORY:
   - 04/21/20(B0.4): Added style to v-card-text to remove the color's opacity.
   - 04/20/20(B0.3): Fixed the horizontal view bug.
   - 04/20/20(B0.2): Added horizontal view.
   - 04/20/20(B0.1): 1st release.
   -->
   <v-container fluid>
      <v-icon dark small
         :color="iconColor"
         @click="openDialog"
      >{{iconName}}</v-icon>

      <v-dialog v-model="dialog" persistent no-click-animation max-width="95wv" scrollable>
         <v-card class="pt-0 px-0">
            <v-card-title class="pt-2 pb-0">
               <span class="title grey--text darken-4 font-weight-bold pb-0">{{myCell.title}}:</span>
               <div class="flex-grow-1"></div>
               <span>
                  <v-select dense persistent-hint hide-selected
                     class="pt-0"
                     hint="View"
                     v-model="view"
                     :items="viewItems"
                     @change="handleResize"
                  ></v-select>
               </span>
            </v-card-title>

            <v-card-text class="pb-0" style="height: 95vh; color:rgba(0, 0, 0)">
               <!-- <v-container> -->
                  <v-row>
                     <v-col :cols="colSize" v-show="view != 'table'">
                        <GChart 
                           :type="myCell.chartType"
                           :data="myCell.chartData"
                           :options="myCell.chartOptions"
                           :settings="chartSettings"
                        />
                     </v-col>
                     <v-col :cols="colSize" v-show="view != 'chart'">
                        <GChart
                           type="Table"
                           :class="`${view==='both-h'?'pt-5':''}`"
                           :data="myCell.chartData"
                           :options="myCell.chartOptions"
                           :settings="chartSettings"
                        />
                     </v-col>
                  </v-row>
               <!-- </v-container> -->
            </v-card-text>

            <v-card-actions class="pt-0 pb-2 mr-8">
               <div class="flex-grow-1"></div>
               <v-btn text
                  color="blue darken-1"
                  @click="closeDialog"
               >Close</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

   </v-container>
</template>

<script>
import { GChart } from 'vue-google-charts'

export default {
   name: "BtGoogleChart",

   components: { GChart },

   props: {
      cellData: {
         type: Object,
         required: true
      },
      iconName: {
         type: String,
         default: "fullscreen"   //zoom_out_map | zoom_in
      },
      iconColor: {
         type: String,
         default: 'grey darken-1'
      }
   },

   data() {
      return {
         chartSettings: {
            packages: ['corechart', 'table', 'map', 'geochart'],
            mapsApiKey: process.env.VUE_APP_GOOGLE_API_KEY
         },
         dialog: false,
         dialogTitle: '',
         myCell: {},
         viewItems: [
            { text: 'Chart Only', value: 'chart' },
            { text: 'Table Only', value: 'table' },
            { text: 'Chart and Table (Vertical)', value: 'both-v' },
            { text: 'Chart and Table (Horizontal)', value: 'both-h' }
         ],
         view: 'chart',       
         window: {
            width: 0,
            height: 0
         },
         colSize: 12
      };
   },

   methods: {
      handleResize() {
         // alert(JSON.stringify(this.window))
         this.window.width = window.innerWidth;
         this.window.height = window.innerHeight;
         if (Object.keys(this.myCell).length > 0 && this.view) {
            this.myCell.chartOptions.width = '50%';

            if (this.view === 'both-v') {
               this.colSize = 12;
               this.myCell.chartOptions.height = this.window.height * 0.38;
            } else if (this.view === 'both-h') {
               this.colSize = 6;
               // this.myCell.chartOptions.height = this.window.height * 0.74;
               this.myCell.chartOptions.height = this.window.height * 0.68;
            } else {
               this.colSize = 12;
               // this.myCell.chartOptions.height = this.window.height * 0.74;
               this.myCell.chartOptions.height = this.window.height * 0.68;
            }

            this.myCell.chartOptions.width = '100%';
         }
      },

      openDialog() {
         this.myCell = JSON.parse(JSON.stringify(this.cellData));
         this.myCell.chartOptions.width = '100%';
         this.handleResize();
         this.dialog = true;
      },

      closeDialog() {
         this.dialog = false;
      }
   },

   created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },

    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    }
};
</script>

<style lang="scss" scoped>
   .v-card__title.title {
      padding-top: 8px; padding-bottom: 0px;
   }
   .v-toolbar__content {
      padding: 0 0;
   }
   divFilterIcons > .v-toolbar__content {
      display: block;
   }
   .theme--dark.v-timeline:before {
    background: grey;
   }
   .v-expansion-panel-header {
      min-height: 54px;
      /* font-weight: bold; */
   }
   .line-break span::before {
      content: '\A'
   }
</style>
