<!-- TODO:-->
<!-- BUSINESS LOGIC:
   domain can be localhost or an ip w/port, or a domain w/out port.
   ip can be an ip4 IP w/out port.<template>
   filter columns dropdown should be from indexed headers only.
-->
<!-- HISTORY:
   05/31/22(B0.6): In filterColumns, Included both fields that are part of an index + Added _id to the fields +
      Replaced BtAutocompleteWithAll with a single (for filterColumns) and multiple (for publicColumns) autocomplete component.
   05/02/22(B0.5): Implemented logout feature.
   04/07/22(B0.4): Added includeSubDomains.
   04/01/22(B0.3): Passed domainOrIpOption (type) to the create/update methods + Fixed domainOrIp validations +
      Checked that domainOrIp wasn't duplicate in the existing items.
   03/31/22(B0.2): Allowed domains to have port and be an IP address + Re-read items after each action +
      Wrapped columns in filter with double quotes.
   03/31/22(B0.1): 1st version/release.
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title class="pl-2 pb-2">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">vpn_key</v-icon>
            <span>Remote Accesses</span>
         </h1>
         <div class="flex-grow-1"></div>
         <v-btn v-if="canCreate"
            x-small
            class="mr-2 mt-1"
            color="gray darken-1"
            :disabled="loadingItems || !importHeadersForFilter.length"
            @click="newItemClicked()"
         >NEW ACCESS
            <v-icon right dark>add</v-icon>
         </v-btn>
      </v-card-title>
      <v-card-text class="py-0">
         <bt-filter-wrapper-with-panel
            :closed-on-load="true"
            :fields="searchFields"
            :included-tabs="['standard']"
            :is-admin="jwt.pa"
            :max="searchFields.length"
            :preselected-fields="[]"
            :should-init="shouldInitFilterDefiner"
            :std-field-values="searchFieldsValues"
            v-model="filter"
            @filter-change="filterChanged"
         ></bt-filter-wrapper-with-panel>
      </v-card-text>
      <v-card-text class="pt-2">
         <v-data-table dense fixed-header
            class="elevation-1"
            item-key="domainOrIp"
            :footer-props="{
               itemsPerPageOptions: [5, 10, 20],
               showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="itemsCount <= 5"
            :items="items"
            :items-per-page="5"
            :loading="loadingItems"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'items' })"
            :no-results-text="$t('no-results-text', { value: 'items' })"
            :options.sync="options"
            :search="search"
            :server-items-length="itemsCount"
         >
            <template v-slot:[`item.includeSubDomains`]="{ item }">
               {{ item.includeSubDomains ? 'True' : 'False' }}
            </template>
            <template v-slot:[`item.filter`]="{ item }">
               {{ parseFilter(item.filter) }}
            </template>
            <template v-slot:[`item.publicColumns`]="{ item }">
               {{ parsePublicColumns(item.publicColumns) }}
            </template>
            <template v-slot:[`item.creationDate`]="{ item }">
               {{ formatDate(item.creationDate, true) }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
               <v-icon v-if="canEdit"
                  small
                  @click="editItemClicked(item)"
               >edit</v-icon>
               <v-icon v-if="canDelete"
                  small
                  @click="deleteItemClicked(item)"
               >delete</v-icon>
            </template>
         </v-data-table>
      </v-card-text>
   </v-card>

   <v-dialog no-click-animation persistent
      max-width="720px"
      v-model="mainDialog"
   >
      <v-form lazy-validation
         ref="mainForm"
         v-model="isMainFormValid"
      >
         <v-card flat>
            <v-card-title class="title grey--text darken-4 font-weight-bold pb-2">Create a New Access:</v-card-title>
            <v-card-text :loading="loadingNewItem">
               <v-row>
                  <v-col cols="3" class="pt-3 pb-0">
                     <v-select dense hide-selected persistent-hint required
                        :items="domainOrIpItems"
                        hint="* Domain or IP Address"
                        placeholder="select an option"
                        :rules="[rules.required]"
                        v-model="domainOrIpOption"
                        @change="domainOrIpOptionChanged"
                     ></v-select>
                  </v-col>
                  <v-col v-if="domainOrIpOption" :cols="domainOrIpColSize" class="py-0 pr-0">
                     <v-text-field persistent-hint required
                        ref="domainOrIp"
                        autocomplete="off"
                        :hint="domainOrIpHint"
                        :placeholder="domainOrIpPlaceholder"
                        :rules="domainOrIpRules"
                        v-model="domainOrIp"
                     ></v-text-field>
                  </v-col>
                  <v-col v-show="domainOrIpOption==='domain'" cols="4" class="pt-4 pb-0 pl-5 pr-0">
                        <!-- :label="`Include Subdomains: ${includeSubDomains?'Yes':'No'}`" -->
                     <v-switch
                        v-model="includeSubDomains"
                        :label="'Include Subdomains'"
                     ></v-switch>
                  </v-col>
               </v-row>
               <v-row v-show="domainOrIpOption">
                  <v-col cols="12" class="pt-4 pb-0">
                     <!-- <bt-autocomplete-with-all chips deletable-chips dense persistent-hint required small-chips
                        ref="filterColumns"
                        hint="* Columns to Authenticate with"
                        :placeholder="filterColumns ? '' : 'select column(s) to authenticate with'"
                        :counter="importHeadersForFilter.length"
                        :items="importHeadersForFilter"
                        :rules="[rules.requiredArray]"
                        v-model="filterColumns"
                        @change="filterColumnsChanged"
                     ></bt-autocomplete-with-all> -->
                     <v-autocomplete dense persistent-hint required
                        ref="filterColumns"
                        hint="* Indexed (single|composite) column(s) to Authenticate with"
                        :placeholder="filterColumns ? '' : 'select indexed (single|composite) column(s) to authenticate with'"
                        :items="importHeadersForFilter"
                        :rules="[rules.required]"
                        v-model="filterColumns"
                     ></v-autocomplete>
                  </v-col>
               </v-row>
               <v-row v-show="domainOrIpOption">
                  <v-col cols="12" class="pt-4 pb-0">
                     <!-- <bt-autocomplete-with-all chips deletable-chips dense persistent-hint required small-chips
                        ref="publicColumns"
                        hint="* Public Columns"
                        :placeholder="publicColumns.length ? '' : 'select columns to be accessed publically'"
                        :counter="importHeadersForPublic.length"
                        :items="importHeadersForPublic"
                        :rules="[rules.requiredArray]"
                        v-model="publicColumns"
                     ></bt-autocomplete-with-all> -->
                     <v-autocomplete chips deletable-chips multiple persistent-hint required small-chips
                        ref="publicColumns"
                        hint="* Public Columns"
                        :placeholder="publicColumns.length ? '' : 'select column(s) to be accessed publically'"
                        :counter="importHeadersForPublic.length"
                        :items="importHeadersForPublic"
                        :rules="[rules.requiredArray]"
                        v-model="publicColumns"
                     ></v-autocomplete>
                  </v-col>
               </v-row>
            </v-card-text>

            <v-card-actions>
               <div class="flex-grow-1"></div>
               <v-btn text
                  color="blue darken-1"
                  @click="closeMainDialog"
               >Cancel</v-btn>
               <v-btn color="blue darken-1" text
                  :disabled="!isMainFormValid"
                  @click="saveItem"
               >Save</v-btn>
            </v-card-actions>
         </v-card>
      </v-form>
   </v-dialog>

   <v-dialog no-click-animation persistent scrollable
      max-width="560px"
      v-model="deleteDialog"
   >
      <v-card>
         <v-card-title class="title grey--text darken-4 font-weight-bold pt-3 pb-0">Deleting '{{currItem.domainOrIp}}':</v-card-title>
         <v-card-text class="pb-0">
            <v-form ref="deleteForm" lazy-validation>
               <v-row class="pt-5 pb-0">
                  <v-col cols="12" class="pb-0">
                     <v-text-field outlined required
                        class="pb-0"
                        ref="currDomainOrIp"
                        label="* Domain or IP Address"
                        placeholder="enter the domain or IP address of the desired remote access"
                        v-model="currDomainOrIp"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>

         <v-card-actions class="pt-0 pr-4">
            <div class="flex-grow-1"></div>
            <v-btn text
               color="blue darken-1"
               @click="closeDeleteDialog"
            >Cancel</v-btn>
            <v-btn text
               color="blue darken-1"
               :disabled="!currDomainOrIp || currDomainOrIp.toLowerCase() != currItem.domainOrIp.toLowerCase()"
               @click="deleteItem"
            >Delete</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</v-container>
</template>

<script>
// import BtAutocompleteWithAll from "./BtAutocompleteWithAll.vue";
import BtFilterWrapperWithPanel from './BtFilterWrapperWithPanel.vue';
import { APIService } from '../services/cs-api-service.js';
import { BtHelpers } from '../services/bt-helpers.js';
import { format, parseISO } from "date-fns";

const NAME = 'CsRemoteAccesses';
const IP4_PATTERN = /^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/;
const HOST_PATTERN = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
// const URL_PATTERN = /^((http|https):\/\/?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
// const DOMAIN_PATTERN = /^(((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4})|((?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(?:\d*)+$/;
const PORT_PATTERN = /^\d+$/;
const SEPARATOR = '_&_';

export default {
   name: NAME,

   components: {
      // BtAutocompleteWithAll,
      BtFilterWrapperWithPanel
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         jwt: {},
         apiService: null,
         btHelpers: null,
         items: [],
         rules: {
            required: value => !!value || "Value is required!",
            // domain: value => HOST_PATTERN.test(value) || 'Value is invalid!',
            domain: value => {
               const msg = 'Value is invalid!';
               const valParts = value.split(':');
               let result = valParts[0] === 'localhost' || IP4_PATTERN.test(valParts[0]);
               if (result)
                  return (valParts.length === 2 && PORT_PATTERN.test(valParts[1])) || msg;

               result = HOST_PATTERN.test(valParts[0]);
               if (valParts.length === 2)
                  result = result && PORT_PATTERN.test(valParts[1]);
               return result || msg;
            },
            ip4: value => value === '::1' || IP4_PATTERN.test(value) || 'Value is invalid!',
            requiredArray: value => !!value.length || "Value is required!"
            // duplicate: value => this.items.find(item => item.domainOrIp.toLowerCase() === value.toLowerCase() && item._id === value._id) || 'Value is duplicate!'
         },
         headers: [
            { text: 'Type', value: 'type', align: 'left', sortable: true },
            { text: 'Domain or IP Address', value: 'domainOrIp', align: 'left', sortable: true },
            { text: 'Include Subdomains', value: 'includeSubDomains', align: 'left', sortable: true },
            { text: 'Columns to Authenticate with', value: 'filter', align: 'left', sortable: true },
            { text: 'Public Columns', value: 'publicColumns', align: 'left', sortable: true },
            { text: 'Creation Date', value: 'creationDate', align: 'left', sortable: true },
            { text: 'Actions', value: 'action', align: 'right', sortable: false }
         ],
         search: '',
         loadingItems: true,
         loadingNewItem: false,
         mainDialog: false,
         isMainFormValid: false,
         importHeadersForFilter: [],
         importHeadersForPublic: [],
         shouldInitFilterDefiner: false,
         // stdFieldValues: {},
         itemsCount: 0,
         options: {},
         filter: {
            standard: [{ $match: {} }],
            // columns: []
         },
         searchFields: [
            { text: 'Type', value: 'type', type: 'string', isIndexed: true },
            { text: 'Domain or IP Address', value: 'domainOrIp', type: 'string', isIndexed: true },
            { text: 'Include Subdomains', value: 'includeSubDomains', type: 'boolean', isIndexed: true  },
            { text: 'Columns to Authenticate with', value: 'filter', type: 'string', isIndexed: true },
            { text: 'Public Columns', value: 'publicColumns', type: 'string', isIndexed: true },
            { text: 'Creation Date', value: 'creationDate', type: 'date', isIndexed: true }
         ],
         searchFieldsValues: {
            filter: this.importHeadersForFilter,
            publicColumns: this.importHeadersForPublic
         },
         domainOrIpOption: '',
         domainOrIpItems: [
            { text: "Domain", value: 'domain' },
            { text: "IP Address", value: 'ip' }
         ],
         domainOrIp: '',
         domainOrIpHint: '',
         domainOrIpPlaceholder: '',
         domainOrIpRules: [],
         publicColumns: [],
         // filterColumns: [],
         filterColumns: '',
         deleteDialog: false,
         currItem: {},
         currDomainOrIp: '',
         includeSubDomains: false
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      canCreate() {
         return this.$store.getters.user.policies && this.$store.getters.user.policies.includes('contact-access-create');
      },

      canEdit() {
         return this.$store.getters.user.policies && this.$store.getters.user.policies.includes('contact-access-update');
      },

      canDelete() {
         return this.$store.getters.user.policies && this.$store.getters.user.policies.includes('contact-access-delete');
      },

      domainOrIpColSize() {
         return this.domainOrIpOption === 'domain' ? 5 : 9;
      }
   },

   watch: {
      token() {
         this.init();
         this.nextAction();
      },

      options: {
         handler (val) {
            // alert('in watch: options=' + JSON.stringify(val));
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
               this.filter.sort = sort;
            }
            this.getItems();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug) {
            console.log(`-----${NAME} B0.6 says => ${msg}`);
            // alert(`-----${NAME} B0.6 says => ${msg}`);
         }
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         try {
            // alert('in init(): filter=' + JSON.stringify(this.filter));
            this.items = [];
            this.importHeadersForFilter = [];

            if (this.token) {
               const JWT = JSON.parse(atob(this.token.split('.')[1]));
               this.jwt = {
                  aid: JWT.aid,
                  paid: JWT.paid,
                  pa: JWT.pa,
                  pu: JWT.pu
               };
               this.log(`in ${NAME}.init(): jwt=${JSON.stringify(this.jwt)}`);
               this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
               this.btHelpers = new BtHelpers(this.token, this.isActualEndpoint, this.debug);
               this.importHeadersForPublic = (await this.btHelpers.getImportHeaders(this.filter, true))
                  .filter(h => !['basePurl','purlNumber'].includes(h.value));
               // alert('importHeadersForPublic=' + JSON.stringify(this.importHeadersForPublic));

               // this.importHeadersForFilter = this.importHeadersForPublic.filter(h => h.isIndexed);
               this.importHeadersForPublic.filter(header => header.isIndexed).forEach(header => {
                  this.importHeadersForFilter.push(header);
                  if (header.secondIndexes) {
                     header.secondIndexes.forEach(secondIndex => {
                        this.importHeadersForFilter.push({
                           text: header.text + ' & ' + secondIndex,
                           value: header.value + SEPARATOR + secondIndex
                        });
                     });
                  }
               });
               this.importHeadersForFilter.push({
                  text: 'ID',
                  value: '_id'
               });

               // alert('importHeadersForFilter=' + JSON.stringify(this.importHeadersForFilter));

               // if (this.importHeaders.length > 0) {
               //    this.stdFieldValues = await this.btHelpers.getFieldValues(this.filter);
               // }

               await this.getItemsCount();
               this.shouldInitFilterDefiner = true;
            } else
               this.jwt = {};
         } catch (error) {
            alert('Exception while parsing token: ' + error.message);
         }
      },

      async getItemsCount() {
         this.loadingItems = true;
         let result = await this.apiService.getRemoteAccessesCount(this.filter);
         if (result.logout)
            this.logout();

         this.itemsCount = result.message ? 0 : result.data;
         this.loadingItems = false;
      },

      async getItems() {
         this.loadingItems = true;
         let result = await this.apiService.getRemoteAccesses(this.filter, this.options.itemsPerPage, this.options.page);
         if (result.logout)
            this.logout();
         else if (result.message)
            this.items = [];
         else {
            this.items = result.data;
            this.$forceUpdate();
         }
         this.loadingItems = false;
      },

      parseFilter(filter) {
         if (filter) {
            const columns = [];
            filter.substring(1, filter.length - 1).split(',').forEach(pair => {
               columns.push(pair.split(':')[0].replace(/"/g, ''));
            });
            return this.getColDescriptions(columns, this.importHeadersForFilter);
         } else
            return '';
      },

      parsePublicColumns(publicColumns) {
         return publicColumns ? this.getColDescriptions(publicColumns.split(' '), this.importHeadersForPublic) : '';
      },

      getColDescriptions(columns, importHeaders) {
         let header;
         const headers = [];
         columns.forEach(col => {
            header = importHeaders.find(h => h.value === col);
            headers.push(header ? header.text : col);
         });
         return headers.join(', ');
      },

      formatDate(date, withTime) {
         if (date) {
            // alert('in formatDate(): date='+date+'\nwithTime=' + withTime + '\nparseISO='+parseISO(date));
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            if (withTime) return formatteddate;
            else return formatteddate.split(' ')[0];
         }
      },

      async filterChanged(filter) {
         // alert('in filterChanged(): filter=' + JSON.stringify(filter) + '\noptions=' + JSON.stringify(this.options));
         this.filter = filter;
         await this.getItemsCount();
         this.nextAction();
      },

      async nextAction() {
         const currOptions = JSON.stringify(this.options);
         const newOptions = JSON.parse(currOptions);
         newOptions.page = 1;
         if (JSON.stringify(newOptions) === currOptions)
            await this.getItems();
         else
            this.options = newOptions;
      },

      async newItemClicked() {
         // this.shouldInitCombolist = true;
         this.currItem = {};
         this.domainOrIpOption = '';
         this.mainDialog = true;
      },

      async editItemClicked(item) {
         // alert(`in editItemClicked(): item=${JSON.stringify(item)}`);
         this.currItem = JSON.parse(JSON.stringify(item));
         // this.domainOrIpOption = item.domainOrIp === '::1' || IP4_PATTERN.test(item.domainOrIp) ? 'ip' : 'domain';
         this.domainOrIpOption = item.type;
         this.domainOrIpOptionChanged();
         this.domainOrIp = item.domainOrIp;
         this.includeSubDomains = item.includeSubDomains ? true : false;
         // this.filterColumns = [];
         const filterCols = [];
         item.filter.substring(1, item.filter.length - 1).split(',').forEach(pair => {
            filterCols.push(pair.split(':')[0].replace(/"/g, ''));
         });
         this.filterColumns = filterCols.join(SEPARATOR);

         this.publicColumns = item.publicColumns.split(' ');
         this.mainDialog = true;
      },

      domainOrIpOptionChanged() {
         this.domainOrIp = '';
         this.includeSubDomains = false;
         this.$nextTick(() => {
            this.$refs.domainOrIp.focus();
         });

         // this.domainOrIpRules = [this.rules.required, this.rules.duplicate];
         this.domainOrIpRules = [this.rules.required];
         switch (this.domainOrIpOption) {
            case 'domain':
               this.domainOrIpHint = '* Domain (e.g., mindfireinc.com)';
               this.domainOrIpPlaceholder = 'specify a valid domain';
               this.domainOrIpRules.push(this.rules.domain)
               break;
            case 'ip':
               this.domainOrIpHint = '* IP Address in IPv4 Format (e.g., 192.0.1.146)';
               this.domainOrIpPlaceholder = 'specify a valid IPv4 IP address';
               this.domainOrIpRules.push(this.rules.ip4)
               break;
            default:
               this.domainOrIpRules = [];
               break;
         }
      },

      // filterColumnsChanged(val) {
      //    // alert(val);
      //    this.importHeadersForFilter.forEach(header => {
      //       header.disabled = false;
      //    });
      //    val.forEach(v => {
      //       const result = this.importHeadersForFilter.filter(h => 
      //          h.value != v && h.value.split('_&_')[0] === v.split('_&_')[0]);
      //       result.forEach(r => {
      //          r.disabled = true;
      //       });
      //    });
      // },

      async saveItem() {
         if (!this.$refs.mainForm.validate()) return;

         this.loadingNewItem = true;
         const accessData = this.getAccessData();
         if (this.currItem._id) {
            let result = await this.apiService.updateRemoteAccess(this.currItem, accessData);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$emit('snackbar-event', `The remote access for '${this.currItem.domainOrIp}' was updated.`);
               // await this.getItems();
            }
         } else {
            const result = await this.apiService.createRemoteAccess(accessData);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$emit('snackbar-event', `The new remote access for '${accessData.domainOrIp}' was created.`);
               // this.items.unshift(result.data);
               this.itemsCount++;
            }
         }
         await this.nextAction();
         this.closeMainDialog();
         this.loadingNewItem = false;
      },

      closeMainDialog() {
         // this.filterColumns = [];
         this.filterColumns = '';
         this.publicColumns = [];
         this.mainDialog = false;
      },

      deleteItemClicked(item) {
         this.currItem = item;
         this.deleteDialog = true;
         this.$nextTick(() => {
            this.currDomainOrIp = '';
            this.$refs.currDomainOrIp.focus();
         });
      },

      async deleteItem() {
         if (!this.$refs.deleteForm.validate()) return;

         this.overlay = true;
         this.log('in deleteItem(): currDomainOrIp=' + this.currDomainOrIp);
         const result = await this.apiService.deleteRemoteAccess(this.currItem._id);
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.$emit('snackbar-event', `The remote access for '${this.currItem.domainOrIp}' was deleted.`);
            // this.items.splice(this.items.indexOf(this.currItem), 1);
            this.itemsCount--;
            await this.nextAction();
            this.closeDeleteDialog();
         }
         this.overlay = false;
      },

      closeDeleteDialog () {
         this.currDomainOrIp = '';
         this.deleteDialog = false;
      },

      getAccessData() {
         // alert('filterColumns=' + JSON.stringify(this.filterColumns));
         // alert('publicColumns=' + JSON.stringify(this.publicColumns));
         const keyValPairs = [];
         this.filterColumns.split(SEPARATOR).forEach(col => {
            keyValPairs.push(`"${col}":input.${col}`);
         });
         const accessData = {
            type: this.domainOrIpOption,
            domainOrIp: this.domainOrIp,
            includeSubDomains: this.includeSubDomains,
            filter: `{${keyValPairs.join(',')}}`,
            publicColumns: this.publicColumns.join(' ')
         };
         // alert('accessData=' + JSON.stringify(accessData));
         return accessData;
      }
   },

   created() {
      this.init();
   }
}
</script>
