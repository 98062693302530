<template>
<!-- TODO: 
- display warning if an action or a condition is in middle (edit or partial).
- place actions and conditions in panels.
- add logic to populate DB if there is no policies and groups
-->
<!-- HISTORY:
   05/02/22(B0.10): Implemented logout feature.
   04/01/22(B0.9): Added remoteAccess to resourceItems + Sorted all dropdown items.
   06/24/21(B0.8): Replaced my file with Aref version:
      Added contact-service to dropdown_ms, and related else-if in msChanged().
   09/02/20(B0.7): Fixed issues with hide-default-footer by adding itemsPerPage variable + Handled search query string.
   08/27/20(B0.6): Applied some changes along with Aref + Removed main card's width.
   08/17/20(B0.5): Removed disabled prop from "key" in conditions to allow duplicate keys.
   08/10/20(B0.4): Hid table footers for < 11 records + Removed 'everyone' group from eligibleGroups +
      Hid top menus from unauthorized users + Fixed Eslint errors for v-slot + Added * to actions dropdowns.
   08/05/20(B0.3): Policies to be managed by power admin only + Added isAdmin to the model n UI +
      Added all methods to all options rather only GET.
-->

<v-container fluid class="px-3 py-3" v-if="isAuthorizedViewer">
   <v-card>
      <v-toolbar flat color="white" class="px-2">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">policy</v-icon>
            <span>Policies</span>
         </h1>
         <div class="flex-grow-1"></div>
         <v-dialog v-if="isAuthorizedUser"
            max-width="500px"
            :disabled="!policies"
            v-model="dialog"
         >
            <template v-slot:activator="{ on }">
               <!-- TODO: Adding disabled prop to v-btn hide it -->
               <v-btn dark 
                  color="#ff6633"
                  class="mb-2"
                  v-on="on"
               >
                  <v-icon left>add</v-icon>
                  <span>CREATE POLICY</span>
               </v-btn>
            </template>
         </v-dialog>
      </v-toolbar>

      <v-card-title class="pt-0">
         <div v-if="isAuthorizedUser">
            <v-btn text small
               :disabled="itemsToWorkWith.length!=1"
               @click="duplicateItemClicked()"
            >
               <v-icon>file_copy</v-icon> {{menuItems.duplicate}}
            </v-btn>
            <v-btn text small
               :disabled="isAddPoliciesDisabled"
               @click="dialogAddPoliciesClicked()"
            >
               <v-icon>add</v-icon> {{menuItems.addPolicies}}
            </v-btn>
            <v-btn text small
               :disabled="isMultiDeleteDisabled"
               @click="deleteItems()"
            >
               <v-icon>delete</v-icon> {{menuItems.delete}}
            </v-btn>
         </div>
         <div class="flex-grow-1"></div>
         <v-text-field single-line hide-details
            class="pt-0"
            label="Search"
            append-icon="search"
            :disabled="!policies || policies.length < 2"
            v-model="search"
         ></v-text-field>
      </v-card-title>

      <!-- <v-card-text> -->
         <v-data-table show-expand
            class="elevation-1"
            item-key="name"
            :headers="headers"
            :items="policies"
            :show-select="isAuthorizedUser"
            :single-select="singleSelect"
            :search="search"
            :hide-default-footer="policies.length < itemsPerPage + 1"
            :loading="loading"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'policies' })"
            :no-results-text="$t('no-results-text', { value: 'policies' })"
            :items-per-page.sync="itemsPerPage"
            :dense="settings.areTablesDensed"
            :single-expand="settings.singleExpand"
            v-model="selectedItems"
            @input="checkboxClicked"
         >
            <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                  <!-- :value="isEditDisabled(item) ? false : isSelected"
                  :disabled="isEditDisabled(item)"
                  :readonly="isEditDisabled(item)" -->
               <v-simple-checkbox v-ripple
                  :value="isSelected"
                  @input="select($event)"
               ></v-simple-checkbox>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
               {{ item.actions.join(', ') }}
            </template>

            <template v-slot:[`item.conditions`]="{ item }">
               {{ item.conditions.join(` ${item.conditionLogic.toUpperCase()} `) }}
            </template>

            <!-- <template v-slot:item.ownerPaid="{ item }">
               {{ item.ownerPaid == '*' ? 'Global' : 'Account' }}
            </template> -->

            <template v-slot:[`item.isAdmin`]="{ item }">
               {{ item.isAdmin.toString() }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
               <span flat v-if="isAuthorizedUser && !isEditDisabled(item)">
                  <v-icon small
                     class="mr-2"
                     @click="editItem(item)"
                  >edit</v-icon>
                  <v-icon small
                     :disabled="item.canBeDeleted===false"
                     @click="deleteItem(item)"
                  >delete</v-icon>
               </span>
            </template>

            <template v-slot:top>
               <v-dialog v-model="dialog" persistent no-click-animation max-width="960px">
                  <!-- <template v-slot:activator="{ on }"></template> -->
                  <v-card>
                     <v-card-title class="headline">{{ formTitle }}
                        <div class="flex-grow-1"></div>
                        <v-btn v-if="showPopulateLink" color="blue darken-1" text @click="populateTestData()">populate test data</v-btn>
                     </v-card-title>

                     <v-card-text class="pb-0">
                        <v-container>
                           <v-form ref="policyForm" v-model="isPolicyFormValid" lazy-validation>
                              <v-row dense>
                                 <v-col cols="10">
                                    <v-text-field outlined
                                       ref="policyName"
                                       label="* Policy Name"
                                       placeholder="Enter policy name"
                                       minlength="5" maxlength="50" counter="50"
                                       :rules="nameRules"
                                       v-model="editedItem.name"
                                       @input="nameChanged"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col cols="2" class="justify-right">
                                    <v-switch
                                       class="mx-2"
                                       label="Is Admin?"
                                       :disabled="editedIndex>-1"
                                       v-model="editedItem.isAdmin"                                 
                                    ></v-switch>
                                 </v-col>
                              </v-row>
                              <v-row dense no-gutters>
                                 <v-col cols="12" class="font-weight-bold title">* Actions:</v-col>
                              </v-row>

                              <v-row v-if="actionFormError">
                                 <v-col class="caption ml-1 py-0" style="color: #FF5252;">{{ actionFormError }}</v-col>
                              </v-row>

                              <v-row v-for="a in editedItem.actions" :key="a" dense>
                                 <v-col cols="auto">
                                    <v-icon small class="mr-1" @click="editAction(a)">edit</v-icon>
                                    <v-icon small @click="deleteAction(a)"
                                       :disabled="editedItem.actions.length===1 || actionIndex===editedItem.actions.indexOf(a)">delete
                                    </v-icon>
                                 </v-col>
                                 <v-col cols="auto">{{ a }}</v-col>
                              </v-row>
                              <v-form ref="actionForm" v-model="isActionFormValid" lazy-validation>
                                 <v-row align="center">
                                    <v-col cols="3">
                                       <v-select dense required
                                          label="Microservice"
                                          v-model="action.ms"
                                          :items="dropdown_ms"
                                          :rules="[rules.required]"
                                          @change="msChanged"
                                       ></v-select>
                                    </v-col>
                                    <v-col cols="3" v-if="action.ms">
                                          <!-- @change="resourceChanged" -->
                                       <v-select dense required
                                          label="Resource"
                                          v-model="action.resource"
                                          :items="resourceItems"
                                          :rules="[rules.required]"
                                       ></v-select>
                                    </v-col>
                                    <v-col cols="3" v-if="action.resource">
                                       <v-select dense required
                                          label="Method"
                                          v-model="action.method"
                                          :items="dropdown_methods"
                                          :rules="[rules.required]"
                                       ></v-select>
                                    </v-col>
                                    <!-- <v-spacer></v-spacer> -->
                                    <v-col cols="3" class="justify-right">
                                       <!-- <div class="flex-grow-1"></div>
                                       <v-spacer></v-spacer> -->
                                       <v-layout>
                                       <!-- <span > -->
                                          <v-btn x-small @click="cancelAction()" v-if="action.ms">CANCEL</v-btn>
                                       <!-- </span> -->
                                       <v-btn x-small @click="saveAction()" v-if="action.method" class="ml-2">
                                          <span>{{ actionBtnTitle }}</span>
                                       </v-btn>
                                       </v-layout>
                                    </v-col>
                                 </v-row>
                              </v-form>

                              <!-- <v-divider></v-divider> -->

                              <v-row>
                                 <v-col lg="2" sm="3"><span class="font-weight-bold title">Conditions:</span></v-col>
                                 <v-spacer></v-spacer>
                                 <v-col lg="3" sm="4" v-if="editedItem.conditions.length > 1" class="body-3 pt-2 pb-0 pl-0 pr-2">
                                    <!-- <v-select dense persistent-hint
                                       height="20" hint="Joint Logic"
                                       v-model="editedItem.conditionLogic"
                                       :items="dropdown_logics"
                                       :rules="[rules.required]"
                                    ></v-select> -->
                                    <v-switch
                                       class="mx-2"
                                       false-value="and"
                                       true-value="or"
                                       v-model="editedItem.conditionLogic"                               
                                    >
                                       <template v-slot:label>
                                          <div>Joint Logic: <strong>{{editedItem.conditionLogic==='and'?'AND (all)':'OR (any)'}}</strong></div>
                                       </template>
                                    </v-switch>
                                 </v-col>
                              </v-row>

                              <v-row v-if="conditionFormError">
                                 <v-col class="caption ml-1 py-0" style="color: #FF5252;">{{ conditionFormError }}</v-col>
                              </v-row>

                              <v-row v-for="c in editedItem.conditions" :key="c" dense>
                                 <v-col md="1" xs="2">
                                    <v-icon small class="mr-1" @click="editCondition(c)">edit</v-icon>
                                    <v-icon small @click="deleteCondition(c)"
                                       :disabled="conditionIndex===editedItem.conditions.indexOf(c)"
                                    >delete</v-icon>
                                 </v-col>
                                 <v-col md="11" xs="10">{{ c }}</v-col>
                              </v-row>

                              <v-form ref="conditionForm" v-model="isConditionFormValid" lazy-validation>
                                 <v-row align="center">
                                    <v-col cols="2">
                                       <v-select dense
                                          label="Option"
                                          v-model="condition.option"
                                          :items="dropdown_options"
                                          :rules="[rules.required]"
                                          @change="optionChanged"
                                       ></v-select>
                                    </v-col>
                                    <v-col cols="2" class="pt-1" v-if="condition.option">
                                       <!-- :disabled="condition.option==='param'" -->
                                       <v-text-field
                                          label="Key"
                                          :hint="`${condition.option==='param'?'':'Example: app'}`"
                                          v-model="condition.key"
                                          :rules="[rules.required]"
                                       ></v-text-field>
                                    </v-col>
                                    <v-col cols="2" v-if="condition.option">
                                       <v-select dense
                                          label="Operator"
                                          v-model="condition.operator"
                                          :items="dropdown_operators"
                                          :rules="[rules.required]"
                                       ></v-select>
                                    </v-col>
                                    <v-col cols="3" class="pt-1" v-if="condition.option">
                                       <v-text-field
                                          label="Value"
                                          hint="Example: 205"
                                          v-model="condition.value"
                                          :rules="[rules.required]"
                                       ></v-text-field>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="3">
                                       <v-btn x-small @click="cancelCondition()" v-if="condition.option">CANCEL</v-btn>
                                       <v-btn x-small @click="saveCondition()" class="ml-2" v-if="condition.value">
                                          <span>{{ conditionBtnTitle }}</span>
                                       </v-btn>
                                    </v-col>
                                 </v-row>
                              </v-form>

                           </v-form>
                        </v-container>
                     </v-card-text>

                     <v-card-actions class="pt-0">
                        <div class="flex-grow-1"></div>
                        <v-btn color="blue darken-1" text @click="cancelItem">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="saveItem">Save</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-dialog>

               <v-dialog v-model="dialogAddPolicies" persistent no-click-animation max-width="960px">
                  <!-- <template v-slot:activator="{ on }"></template> -->
                  <v-card>
                     <v-card-title class="headline">{{menuItems.addPolicies}}</v-card-title>

                     <v-card-text>
                        <v-container>
                           <v-layout class="mb-5">
                              <v-row no-gutters class="mb-6">
                                 <v-col cols="auto" class="font-weight-bold">{{$tc('this-policy', itemsToWorkWith.length)}} will be added to the selected group(s):</v-col>
                                 <v-col class="pl-1 font-italic">{{ getNames(itemsToWorkWith).join(', ') }}</v-col>
                              </v-row>
                           </v-layout>
                           <v-form ref="addPoliciesForm" lazy-validation mt-5>
                              <v-layout>
                              <v-row dense no-gutters mb-0 pb-0>
                                 <v-col cols="12" class="font-weight-bold title">
                                    <v-autocomplete multiple clearable open-on-clear outlined small-chips deletable-chips required
                                       ref="groups"
                                       label="* Groups"
                                       placeholder="select as many groups as needed"
                                       v-model="selectedGroups"
                                       :items="groupsDropdown"
                                       :rules="selectRules"
                                       :counter="groupsDropdown.length"
                                       @change="selectChanged"
                                    >
                                    </v-autocomplete>
                                 </v-col>
                              </v-row></v-layout><v-layout>
                              <v-row dense no-gutters pt-0 mt-0>
                                 <v-col cols="12">
                                    <v-checkbox mt-0
                                       label="Add all groups"
                                       v-model="isAllGroupsChecked"
                                       :readonly="isAllGroupsChecked"
                                       :disabled="isAllGroupsChecked"
                                       @change="allGroupsChecked"
                                    ></v-checkbox>
                                 </v-col>
                              </v-row></v-layout>
                           </v-form>
                        </v-container>
                     </v-card-text>

                     <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="blue darken-1" text @click="cancelAddPolicies">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="addPoliciesToGroups">Submit</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-dialog>
            </template>

            <template v-slot:expanded-item="{ item }">
               <!-- <td colspan="3">&nbsp;</td> -->
               <td colspan="2">
                  <span class="font-weight-bold font-italic">ID:<br/></span>
                  <span>{{item._id}}<br/></span>
               </td>
               <td>
                  <span class="font-weight-bold font-italic">Perform these actions:<br/></span>
                  <li v-for="a in item.actions" :key="a">{{ a }}</li>
               </td>
               <td class="pt-2" style="white-space:pre">
                  <span class="font-weight-bold font-italic">{{getConditionsDesc(item)}}<br/></span>
                  <li v-for="c in item.conditions" :key="c">{{ c }}</li>
               </td>
               <td>
                  <span class="font-weight-bold font-italic">LEVEL:<br/></span>
                  <span>{{item.ownerPaid == '*' ? 'Global' : 'Account'}}<br/></span>
               </td>
            </template>

            <!-- <template v-slot:no-results>
               <td colspan="6">No policy could be found!</td>
            </template> -->

         </v-data-table>
      <!-- </v-card-text> -->
   </v-card>
</v-container>
<v-container v-else>
   <div class="red--text" v-if="false">
      <p>Sorry, you are not authorized to access this page!</p>
      <ul>
         <li>Power Admin: {{ jwt.pa }}</li>
         <li>Power User: {{ jwt.pu }}</li>
         <li>Parent Account: {{ jwt.paid }}</li>
      </ul>
   </div>
</v-container>
</template>

<script>
import { APIService } from '../services/iam-api-service.js';
import { Policy } from '../models/iam-policy.js';
import { IamSettings } from '../models/iam-settings.js';

export default {
   name: 'IamPolicies',

   props: {
      debug: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         jwt: {},
         apiService: null,
         menuItems: { delete: 'DELETE POLICIES', addPolicies: 'Add Policies to Groups', duplicate: 'Duplicate Policy' },
         search: '',
         singleSelect: false,
         headers: [
            { text: 'Name', value: 'name', align: 'left', sortable: true, width: "20%" },
            { text: 'Actions', value: 'actions', align: 'left', sortable: false, width: "30%" },
            { text: 'Conditions', value: 'conditions', align: 'left', sortable: false, width: "30%" },
            // { text: 'Level', value: 'ownerPaid', align: 'left', sortable: true, width: "10%" },
            { text: 'Is Admin', value: 'isAdmin', align: 'left', sortable: true, width: "10%" },
            { text: '', value: 'action', align: 'right', sortable: false, width: "10%" }
         ],
         policies: [],
         groups: [],
         selectedItems: [],
         editedIndex: -1,
         editedItem: new Policy(),
         actionIndex: -1,
         conditionIndex: -1,
         action: { ms: '', resource: '', method: '' },
         condition: { option: '', key: '', operator: '', value: '' },
         isMultiActionsDisabled: true,
         dialog: false,
         dropdown_ms: [
            { text: 'BI', value: 'bi' },
            { text: 'Contact-Service', value: 'contact-service' },
            { text: 'Scheduler', value: 'scheduler' },
            { text: 'All', value: '*' }
         ],
         resourceItems: [],
         dropdown_methods: [
            { text: 'GET', value: 'get' },
            { text: 'POST', value: 'post' },
            { text: 'PUT', value: 'put' },
            { text: 'DELETE', value: 'delete' },
            { text: 'All', value: '*' }
         ],
         dropdown_logics: [
            { text: 'AND (all)', value: 'and' },
            { text: 'OR (any)', value: 'or' }
         ],
         dropdown_options: [ 'body', 'param', 'query' ],
         dropdown_operators: [
            { text: 'contains', value: 'ct' },
            { text: 'ends with', value: 'ew' },
            { text: 'equal', value: 'eq' },
            { text: 'in', value: 'in' },
            { text: 'not contains', value: 'nct' },
            { text: 'not ends with', value: 'new' },
            { text: 'not equal', value: 'neq' },
            { text: 'not in', value: 'nin' },
            { text: 'not starts with', value: 'nsw' },
            { text: 'regex', value: 'regex' },
            { text: 'starts with', value: 'sw' }
         ],
         isPolicyFormValid: true,
         isActionFormValid: true,
         isConditionFormValid: true,
         rules: {
            required: value => !!value || this.$t('required'),
            requiredArray: value => value.length > 0 || this.$t('required'),
            minLength: value => value.length >= 5 || this.$t('min5'),
            duplicate: value => this.policies.find(p => p.name.toLowerCase() === value.trim().toLowerCase() && p._id != this.editedItem._id) === undefined ||
               this.$t('duplicate-policy')
         },
         actionFormError: '',
         conditionFormError: '',
         nameRules: [],
         itemsToWorkWith: [],
         dialogAddPolicies: false,
         groupsDropdown: [],
         selectedGroups: [],
         selectRules: [],
         isAllGroupsChecked: false,
         duplicateItem: false,
         loading: true,
         settings: new IamSettings(this.debug),
         isMultiDeleteDisabled: true,
         isAddPoliciesDisabled: true,
         itemsPerPage: 10
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },
      isAuthorizedViewer() {
         // user is authorized to view if it is power admin or power user of any account.
         return this.jwt.pa || this.jwt.pu;
      },
      isAuthorizedUser() {
         // user is authorized if it is power admin or power user of the parent account
         // return this.jwt.pa || (!this.jwt.paid && this.jwt.pu);
         return this.jwt.pa;
      },
      formTitle () {
         return this.editedIndex === -1 ? 'Create a New Policy' : 'Edit Policy'
      },
      actionBtnTitle () {
         return this.actionIndex === -1 ? 'Add' : 'Save';
      },
      conditionBtnTitle () {
         return this.conditionIndex === -1 ? 'Add' : 'Save';
      },
      showPopulateLink () {
         return process.env.NODE_ENV != 'production' && this.editedIndex === -1 && !this.duplicateItem;
      }
      // pageWidth() {
      //    // alert('in pageWidth:'+this.settings.keepMenuExpanded ? '81vw' : '91vw');
      //    return this.settings.keepMenuExpanded ? '81vw' : '91vw';
      // }
   },

   watch: {
      token() {
         this.init();
      },
      dialog (val) {
         if (val) {
            setTimeout(() => {
               this.$refs.policyName.focus();
            }, 10);
         } else
            this.closeItem();
      },
      dialogAddPolicies (val) {
         if (val) {
            setTimeout(() => {
               this.$refs.groups.focus();
            }, 10);
         } else
            this.closeAddPolicies();
      },
      policies: {
         // immediate: true,
         // deep: true,
         handler(val) {
            this.itemsPerPage = isNaN(this.settings.rowsPerPage) ? val.length : this.settings.rowsPerPage;
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----IamPolicies B0.10 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      init() {
         if (!this.token) return;
         try {
            // alert(this.token);
            if (this.token) {
               const JWT = JSON.parse(atob(this.token.split('.')[1]));
               this.jwt = {
                  aid: JWT.aid,
                  paid: JWT.paid,
                  pa: JWT.pa,
                  pu: JWT.pu
               };
               this.log('in IamPolicies: jwt=' + JSON.stringify(this.jwt));
               if (this.isAuthorizedUser || this.isAuthorizedViewer) {
                  this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
                  this.getPolicies();
                  this.getGroups();
               }
            } else
               this.jwt = {};
         } catch (error) {
            alert('Exception in IamPolicies while parsing token: ' + JSON.stringify(error));
         }
      },

      async getPolicies() {
         let result = await this.apiService.getPolicies();
         this.loading = false;
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.policies = result.data;
         }
      },

      isEditDisabled (item) {
         // return item.ownerPaid == '*' ? !this.jwt.pa : this.jwt.pa;
         return !this.jwt.pa;
      },

      checkboxClicked () { //selectedRows
         this.isMultiDeleteDisabled = this.selectedItems.length === 0 || this.selectedItems.filter(item => item.canBeDeleted === false).length > 0;

         const isAdminTrue = this.selectedItems.filter(item => item.isAdmin);
         const isAdminFalse = this.selectedItems.filter(item => !item.isAdmin);
         this.isAddPoliciesDisabled = this.selectedItems.length === 0 || (isAdminTrue.length > 0 && isAdminFalse.length > 0);

         // this.itemsToWorkWith = this.selectedItems.filter(item => !this.isEditDisabled(item));
         // if (this.itemsToWorkWith.length == 0)
         //    this.selectedItems = [];

         this.itemsToWorkWith = JSON.parse(JSON.stringify(this.selectedItems));

         this.log(`in checkboxClicked(): this.selectedItems.len=${this.selectedItems.length}, this.itemsToWorkWith.len=${this.itemsToWorkWith.length}`);
      },

      getConditionsDesc(item) {
         if (item.conditions.length === 0) return '';
         else if (item.conditions.length === 1) return 'When the following condition is true:';
         else return `When ${item.conditionLogic==='and'?'ALL':'ANY'} of these conditions are true:`;
      },

      createItem () {
         this.dialog = true;
      },

      editItem (item) {
         // this.defaultItem = JSON.parse(JSON.stringify(item));
         // this.editedItem = Object.assign({}, item);
         this.editedItem = JSON.parse(JSON.stringify(item));
         this.editedIndex = this.policies.indexOf(item);
         this.dialog = true;
      },

      async deleteItem (item) {
         if (confirm(`Are you sure you want to delete '${item.name}'?`)) {
            let result = await this.apiService.deletePolicy(item);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.policies.splice(this.policies.indexOf(item), 1);
               this.$emit('snackbar-event', this.$t('deleted', { value: item.name }));
            }
         }
      },

      async deleteItems () {
         if (!confirm(`Are you sure you want to delete the ${this.$tc('policy-selected', this.itemsToWorkWith.length)}?`))
            return;

         let msg = '';
         let responses = await this.apiService.deletePolicies(this.itemsToWorkWith);
         this.log('in deleteItems(): results=' + JSON.stringify(responses));


         let results = {
            succeeded: [], //only names of deleted policies
            failed: []     //{ name: '', message: '' }
         };

         responses.forEach(response => {
            if (response.logout)
               this.logout();
            else if (response.message === null) {
               results.succeeded.push(response.data.name);
            } else {
               results.failed.push({ name: response.data.name, message: response.message });
            }
         });

         if (results.succeeded.length > 0) {
            msg = 'Following ' + this.$tc('policy-was', results.succeeded.length) + ' deleted:\n' + results.succeeded.join(', ') + '\n\n';
            results.succeeded.forEach (result => {
               this.log('result='+JSON.stringify(result));
               let item = this.itemsToWorkWith.find(i => i.name === result);
               this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
               this.policies.splice(this.policies.indexOf(item), 1);
            });
            this.checkboxClicked();
         }

         if (results.failed.length > 0) {
            msg += 'Following ' + this.$tc('policy', results.failed.length) + ' could not be deleted:\n';
            results.failed.forEach (result => {
               msg += '\n- ' + result.name + ' => ' + result.message;
            });
            alert(msg);
            this.$emit('snackbar-event', this.$t('action-finished', { action: this.menuItems.delete }));
         } else {
            this.$emit('snackbar-event', `${results.succeeded.length} ${this.$tc('policy-was', results.succeeded.length)} deleted.`);
         }
      },

      msChanged (value) {
         this.action.resource = '';
         this.action.method = '';

         if (value === 'bi')
            this.resourceItems = [
               { text: 'Dashboards', value: 'dashboards' },
               { text: 'Downloads', value: 'downloads' },
               { text: 'Reports', value: 'reports' },
            ];
         else if (value === 'scheduler')
            this.resourceItems = [
               { text: 'Crons', value: 'crons' },
               { text: 'Schedules', value: 'schedules' }
            ];
         else if (value === 'contact-service')
            this.resourceItems = [
               { text: 'Downloads', value: 'downloads' },
               { text: 'Events', value: 'events' },
               { text: 'Actions', value: 'actions' },
               { text: 'Triggers', value: 'triggers' },
               { text: 'Exports', value: 'exports' },
               { text: 'Imports', value: 'imports' },
               { text: 'Indexes', value: 'indexes' },
               { text: 'Offers', value: 'offers' },
               { text: 'RDLs', value: 'rdls' },
               { text: 'Remote Access', value: 'remoteAccess' },
               { text: 'Uploads', value: 'uploads' },
               { text: 'Settings', value: 'settings' },
               { text: 'Tags', value: 'tags' }
            ];
         else
            this.resourceItems = [];

         this.resourceItems.push({ text: 'All', value: '*' });
      },

      // resourceChanged (value) {
      //    if (value === 'crons') {
      //       this.action.method = '';
      //       this.dropdown_methods = [
      //          { text: 'GET', value: 'get' },
      //          { text: 'POST', value: 'post' },
      //          { text: 'PUT', value: 'put' },
      //          { text: 'DELETE', value: 'delete' }
      //          // { text: 'All', value: '*' }
      //       ];
      //    } else {
      //       this.action.method = 'get';
      //       this.dropdown_methods = [
      //          { text: 'GET', value: 'get' },
      //       ];
      //    }
      // },

      saveAction () {
         if (!this.$refs.actionForm.validate()) {
            return;
         }

         const actionStr = this.action.ms + '::' + this.action.resource.trim() + '::' + this.action.method;
         let index = this.editedItem.actions.findIndex(a => a === actionStr);
         if (index > -1 && index !== this.actionIndex) {
            this.actionFormError = this.$t('duplicate-action', {index: index + 1});
            return;
         }

         // let errMsg = '';
         // index = this.editedItem.actions.findIndex(a => {
         //    const aParts = a.split('::');

         //    // return aParts[0] === this.action.ms && aParts[2] === '*';
         //    if (aParts[0] === this.action.ms && aParts[1] === this.action.resource) {
         //       if (aParts[2] === '*') {
         //          errMsg = 'unnecessary-action';
         //          return true;
         //       } else if (this.action.method === '*') {
         //          errMsg = 'invalid-action';
         //          return true;
         //       }
         //    }
         //    if (aParts[0] === this.action.ms && aParts[2] === this.action.method) {
         //       if (aParts[1] === '*') {
         //          errMsg = 'unnecessary-action';
         //          return true;
         //       } else if (this.action.resource === '*') {
         //          errMsg = 'invalid-action';
         //          return true;
         //       }
         //    }
         //    if (aParts[1] === this.action.resource && aParts[2] === this.action.method) {
         //       if (aParts[0] === '*') {
         //          errMsg = 'unnecessary-action';
         //          return true;
         //       } else if (this.action.ms === '*') {
         //          errMsg = 'invalid-action';
         //          return true;
         //       }
         //    }
         //    return false;
         // });
         // if (index > -1 && index !== this.actionIndex) {
         //    this.actionFormError = this.$t(errMsg, {index: index + 1});
         //    return;
         // }

         this.actionFormError = '';
         if (this.actionIndex > -1) {
            this.$set(this.editedItem.actions, this.actionIndex, actionStr);
            this.actionIndex = -1;
         } else {
            this.editedItem.actions.push(actionStr);
         }

         this.editedItem.actions.sort();
         this.$refs.actionForm.reset();
      },

      editAction (a) {
         this.actionIndex = this.editedItem.actions.indexOf(a);
         const actionParts = a.split('::');
         this.action.ms = actionParts[0];
         this.msChanged(this.action.ms);
         this.action.resource = actionParts[1];
         // this.resourceChanged(this.action.resource);
         this.action.method = actionParts[2];
      },

      cancelAction() {
         this.actionIndex = -1;
         this.actionFormError = '';
         this.$refs.actionForm.reset();
      },

      deleteAction (a) {
         const index = this.editedItem.actions.indexOf(a);
         confirm(`Are you sure you want to delete '${a}'?`) && this.editedItem.actions.splice(index, 1);
      },

      optionChanged (value) {
         if (value === 'param') {
            let maxKey = -1;
            this.editedItem.conditions.forEach(a => {
               let aParts = a.split('::');
               if (aParts[0] === 'param' && aParts[1] > maxKey)
                  maxKey = aParts[1];
            });
            this.condition.key = (parseInt(maxKey) + 1).toString();
         }
      },

      saveCondition () {
         if (!this.$refs.conditionForm.validate()) {
            this.isConditionFormValid = false;
            return;
         }

         const conditionStr = this.condition.option + '::' + this.condition.key.trim() + '::' + this.condition.operator + '::' + this.condition.value.trim();
         const index = this.editedItem.conditions.findIndex(c => c.toLowerCase() === conditionStr.toLowerCase());

         if (index > -1 && index !== this.conditionIndex) {
            this.conditionFormError = this.$t('duplicate-condition', {index: index + 1});
            this.isConditionFormValid = false;
            return;
         }

         this.isConditionFormValid = true;
         this.conditionFormError = '';

         if (this.conditionIndex > -1) {
            this.reassignParamKeys();
            this.$set(this.editedItem.conditions, this.conditionIndex, conditionStr);
            this.conditionIndex = -1;
         } else {
            this.editedItem.conditions.push(conditionStr);
         }

         this.editedItem.conditions.sort();
         this.$refs.conditionForm.reset();
      },

      editCondition (c) {
         this.conditionIndex = this.editedItem.conditions.indexOf(c);
         const conditionParts = c.split('::');
         this.condition.option = conditionParts[0];
         this.condition.key = conditionParts[1];
         this.condition.operator = conditionParts[2];
         this.condition.value = conditionParts[3];
      },

      cancelCondition() {
         this.conditionIndex = -1;
         this.conditionFormError = '';
         this.$refs.conditionForm.reset();
      },

      deleteCondition (c) {
         const index = this.editedItem.conditions.indexOf(c);
         if (confirm(`Are you sure you want to delete '${c}'?`)) {
            this.editedItem.conditions.splice(index, 1);
            this.reassignParamKeys();
         }
      },

      reassignParamKeys() {
         let key = 0;
         for (let index = 0; index < this.editedItem.conditions.length; index++) {
            const eParts = this.editedItem.conditions[index].split('::');
            if (eParts[0] === 'param') {
               eParts[1] = key++;
               this.editedItem.conditions[index] = eParts.join('::');
            }
         }
      },

      nameChanged () {
         if (this.nameRules.length === 0)
            this.nameRules = [this.rules.required, this.rules.minLength, this.rules.duplicate];
      },

      async saveItem () {
         this.log('in saveItem()');
         this.isPolicyFormValid = true;
         this.nameChanged();

         if (!this.$refs.policyForm.validate()) {
            this.isPolicyFormValid = false;
         }

         if (this.editedItem.actions.length === 0) {
            this.isPolicyFormValid = false;

            if (this.$refs.actionForm.validate()) {
               this.actionFormError = 'Please add one action.';
            }
         }

         // if (this.editedItem.conditions.length === 0) {
         //    this.isPolicyFormValid = false;

            // if (this.$refs.conditionForm.validate()) {
            //    this.conditionFormError = 'Please add one condition.';
            // }
         // }

         if (!this.isPolicyFormValid) {
            return;
         }

         let result;
         const name = this.editedItem.name;
         if (this.editedIndex > -1) {
            // if (!_areObjectsEqual(this.editedItem, this.defaultItem)) {   //TODO: this is not working.
            this.log('updating policy...');
            result = await this.apiService.updatePolicy(this.editedItem);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$set(this.policies, this.editedIndex, this.editedItem);
               this.dialog = false;
               this.$emit('snackbar-event', this.$t('updated', { value: name }));
            }
         } else {
            this.log('creating policy...');
            result = await this.apiService.createPolicy(this.editedItem);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               if (this.duplicateItem) {
                  this.selectedItems = [];
                  this.checkboxClicked();
               }
               this.policies.push(result.data);
               this.dialog = false;
               this.$emit('snackbar-event', this.$t('created', { value: result.data.name, id: result.data._id }));
            }
         }
      },

      cancelItem () {
         this.log('in cancelItem()');
         // this.$set(this.policies, this.editedIndex, this.defaultItem);
         this.dialog = false;
         this.$emit('snackbar-event', this.$t('action-cancelled', { action: (this.editedIndex === -1 ? 'CREATE' : 'EDIT') + ' POLICY' }));
      },

      closeItem () {
         this.log('in closeItem()');
         setTimeout(() => {
            this.cancelAction();
            this.cancelCondition();
            this.editedItem = new Policy();
            this.editedIndex = -1;
            this.nameRules = [];
            this.duplicateItem = false;
         }, 100);
      },

      async getGroups() {
         let result = await this.apiService.getGroups();
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.groups = result.data;
            this.setPoliciesDeleteStatus();
         }
      },

      async setPoliciesDeleteStatus() {
         let ind = -1;
         this.groups.forEach(group => {
            group.policies.forEach(policy => {
               ind = this.policies.findIndex(p => p.name === policy.name);
               if (ind > -1)
                  this.policies[ind].canBeDeleted = false;
            });
         });
         // alert(this.groups.length+'\n' +JSON.stringify(this.policies));
      },

      dialogAddPoliciesClicked () {
         // this.groupsDropdown = this.getNames(this.groups.filter(g => !this.isEditDisabled(g)));
         this.groupsDropdown = this.getNames(this.groups.filter(g => g.isAdmin === this.itemsToWorkWith[0].isAdmin && g.name.toLowerCase() != 'everyone'));
         this.dialogAddPolicies = true;
      },

      allGroupsChecked(){
         this.selectedGroups = this.groupsDropdown;
      },

      cancelAddPolicies () {
         this.dialogAddPolicies = false;
         this.$emit('snackbar-event', this.$t('action-cancelled', { action: this.menuItems.addPolicies.toUpperCase() }));
      },

      closeAddPolicies () {
         this.log('in closeAddPolicies()');
         setTimeout(() => {
            this.selectedGroups = [];
            this.selectRules = [];
            this.isAllGroupsChecked = false;
         }, 100)
      },

      getNames(items) {
         return items.map(i => { return i.name; });
      },

      selectChanged () {
         if (this.selectRules.length === 0)
            this.selectRules.push(this.rules.requiredArray);

         this.isAllGroupsChecked = this.selectedGroups.length === this.groups.length;
      },

      async addPoliciesToGroups () {
         this.log('in addPoliciesToGroups()');
         this.selectChanged();

         if (!this.$refs.addPoliciesForm.validate()) {
            return;
         }

         // getting group objects from their names
         let groups2Add = this.selectedGroups.map(gn => {
            return this.groups.find(group => group.name === gn);
         });

         let responses = await this.apiService.addPoliciesToGroups(groups2Add, this.itemsToWorkWith);
         this.log('in addPoliciesToGroups(): results=' + JSON.stringify(responses));

         let results = {
            succeeded: [], //only names of deleted policies
            failed: []     //{ name: '', message: '' }
         };

         this.dialogAddPolicies = false;

         responses.forEach(response => {
            if (response.logout)
               this.logout();
            else if (response.message === null) {
               results.succeeded.push(response.data.name);
               //No update is needed for the policies
            } else {
               results.failed.push({ name: response.data.name, message: response.message });
            }
         });

         let msg = '';
         if (results.succeeded.length > 0) {
            msg = `Following ${this.$tc('group-was', results.succeeded.length)} updated:\n${results.succeeded.join(', ')}\n\n`;
         }

         if (results.failed.length > 0) {
            msg += `Following ${this.$tc('group', results.failed.length)} could not be updated:\n`;
            results.failed.forEach (result => {
               msg += '\n- ' + result.name + ' => ' + result.message;
            });
            alert(msg);
            this.$emit('snackbar-event', this.$t('action-finished', { action: this.menuItems.addPolicies.toUpperCase() }));
         } else {
            this.$emit('snackbar-event', `${results.succeeded.length} ${this.$tc('group-was', results.succeeded.length)} updated.`);
         }

         this.itemsToWorkWith = [];
      },

      duplicateItemClicked () {
         this.log('in duplicateItemClicked()');
         this.duplicateItem = true;
         const item = this.itemsToWorkWith[0];
         this.editedItem = new Policy(
            item.name,
            item.isAdmin,
            item.actions,
            item.conditions,
            item.conditionLogic
         );

         this.dialog = true;

         this.nameChanged();
         setTimeout(() => {
            if (!this.$refs.policyForm.validate()) {
               this.isPolicyFormValid = false;
            }
         }, 5);
      },

      populateTestData () {
         this.log('in populateTestData()');
         this.editedItem = new Policy(
            `New Policy ${this.policies.length+1}`,
            false,
            [ 'bi::dashboards::get', 'scheduler::crons::post' ],
            [ 'param::0::eq::v1', 'query::k2::in::v2', 'body::k3::sw::v3', 'param::1::eq::v4' ],
            'and'
         );
         this.editedItem.conditions.sort();
         this.nameChanged();
      }
   },

   created() {
      // alert('in created');
      //{"email":"rmemarian@mindfiremail.info","pu":true,"au":true,"pa":true,"aid":22953,"paid":null,"iss":"identity.mdl.io","iat":1594148859,"exp":1594177659}
      this.init();
      this.settings.saveLastPageViewed('policies');
      if (this.$route.query.search)
         this.search = this.$route.query.search;
   },
   mounted() {
   }
}
</script>

<style scoped>
   .form-error {
      font-size: 12px;
      color: #FF5252;
      background-color: none;
      /* pl-2 ml-5 */
   }
</style>