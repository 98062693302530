/*jshint esversion: 8*/
/* Common functions to be used in different components.
 * HISTORY: no-version
 * V240726.1: Added mergeArrays().
 * V240711.1: Modified adjustFilter() and adjustFilter_adjust$project() to support drilldown/click event for Yearly-Monthly and Monthly-Daily options.
 * V240708.1: [on Aref side] Modified adjustFilter() to add event_data field.
 * V240702.1: Modified adjustFilter() to add event_element and event_path fields.
 * V240628.2: Modified adjustFilter_adjust$project() to handle $lt on top of $lte + Changed 250000 limit to 3000000.
 * V240509.1: Modified adjustFilter()/$expr to fix the bug that included extra records by injecting the other dimension to $expr.
 * V240508.1: Modified adjustFilter()/$expr to inject $expr key in $match instead of $elemMatch.
 * V240507.1: Modified adjustFilter()/event_data to fixed a bug that didn't add event_data. to the field name.
 * V240425.2: Modified adjustFilter()/$expr to inject this key in $elemMatch as well.
 * V240424.1: Modified adjustFilter_adjust$project() to inject all event fields (on top of event_date).
 * V240423.2: Modified adjustFilterWithImportId() to inject $limit instead of importId +
 *    Modified adjustFilter() to inject importId only if it has value (by CsDashboard.vue).
 * V230823.1: Added formatNumber().
 * V230712.1: [on Aref side] Modified adjustFilter() to fix a bug from V230629.1.
 * V230629.1: [on Aref side] Modified adjustFilter() to fix a bug that removed event_date from $elemMatch.
 * V230531.1: Modified adjustFilter() to support event_data fields.
 * V230524.1: Added getEventDataFields().
 * V2305xx.1: Added event_data to BEH_FIELDS.
 * V230320.1: Changed getExportFields() to getEventFields().
 * V230315.1: Added getExportFields() [moved fields from CsExports.vue].
 * V230202.1: Added event_path and event_element fields to BEH_FIELDS + Replaced hasOwnProperty() with hasOwn().
 * V221101.1: Added generateUUID() method.
 * V221027.1: Added hasOwn() method.
 * V221006.1: Modified convertToISODate() by adding setSeconds param (to be used for adding events).
 * V221004.1: Modified convertToISODate() by removing -1 from month.
 * V221003.1: Added isDate(), isNumber(), isBoolean(), convertToBoolean(), and convertToISODate() methods.
 * V220922.1: Modified adjustFilter() to fix a bug that set importId with $nin (fixed it on Aref side).
 * 09/18/22(B0.15): Modified adjustFilterWithImportId() to bypass importId assignment if it has already a value.
 * 09/16/22(B0.14): Modified adjustFilter() and adjustFilterWithImportId() to set the filter's importId with $in.
 * 07/07/22(B0.13): Modified adjustFilter() to support click event for calculated tables with predefined filter.
 * 07/01/22(B0.12): Modified adjustFilter() and adjustFilter_adjust$project() to handle event_date's click event.
 * 06/28/22(B0.11): Added adjustFilter_adjust$project() to inject datePicker output into $project.
 * 06/21/22(B0.10): Modified adjustFilter() to always add importId if doesn't exist +
 *    Added adjustFilterWithImportId() to add importId to $match.
 * 06/17/22(B0.9): Modified adjustFilter() to always replacing importId value.
 * 06/14/22(B0.8): Added device and platform to BEH_FIELDS and adjustFilter().
 * 05/24/22(B0.7): Added BEH_FIELDS and getAllBehavioralFields().
 * 09/24/21(B0.6): In adjustFilter(), changed originalFilter to currentFilter and added originalFilter param +
 *    Added adjustFiletr_matchKeyExists() and adjustFiletr_eventKeyExists functions.
 * 09/21/21(B0.5): In adjustFilter(), added $addFields only if didn't exist.
 * 09/17/21(B0.4): In adjustFilter(), made event_date's values to be in ISO format.
 * 09/13/21(B0.3): Added adjustFilter() and adjustFilter_isEmpty().
 * 04/19/21(B0.2): Added isValidDate().
 * 11/24/20(B0.1): Creation date.
 */

// export default {
//    methods: {
//       clicked(value) {
//          alert(value);
//       }
//    }
//  };

const BEH_FIELDS = [
   { text: 'App Code', value: 'app_code', type: 'string', isEventField: true },
   { text: 'Browser', value: 'browser', type: 'string', isEventField: true },
   { text: 'Device', value: 'device', type: 'string', isEventField: true },
   { text: 'Event Code', value: 'event_code', type: 'string', isEventField: true },
   { text: 'Event Date', value: 'event_date', type: 'date', isEventField: true },
   { text: 'IP', value: 'ip', type: 'string', isEventField: true },
   // { text: 'Is Trigger', value: 'is_trigger', type: 'boolean', isEventField: true },
   { text: 'Operating System', value: 'os', type: 'string', isEventField: true },
   { text: 'Platform', value: 'platform', type: 'string', isEventField: true },
   // { text: 'Process Definition ID', value: 'pdid', type: 'string', isEventField: true }
   { text: 'Event Path', value: 'event_path', type: 'string', isEventField: true },
   { text: 'Event Element', value: 'event_element', type: 'string', isEventField: true },
   { text: 'Event Data', value: 'event_data', type: 'number', isEventField: true }  //TODO: type? isEventField?
];

//TODO: seems it is not in use!
const EVENT_FIELDS = [
   { text: '_id', value: '_id', type: 'string', isIndexed: true, internalField: true },
   { text: 'app_code', value: 'app_code', type: 'string', isIndexed: true, isEventField: true },
   { text: 'event_code', value: 'event_code', type: 'string', isIndexed: true, isEventField: true },
   { text: 'event_path', value: 'event_path', type: 'string', isIndexed: true, isEventField: true },
   { text: 'event_element', value: 'event_element', type: 'string', isIndexed: true, isEventField: true },
   { text: 'event_date', value: 'event_date', type: 'date', isIndexed: true, isEventField: true }
];

const EVENT_DATA_FIELDS = [
   { text: 'Program', value: '__program'},
   { text: 'Campaign', value: '__campaign'},
   { text: 'Outbound', value: '__outbound'},
   { text: 'Schedule', value: '__schedule'},
   { text: 'Goal', value: '__goal'},
   { text: 'Inbound', value: '__inbound'},
   { text: 'Inbound Child', value: '__inboundChild'}
];

 
// This function is used inside adjustFilter().
function adjustFilter_isEmpty(val) {
   if ((typeof val === 'string' && val === '') || (Array.isArray(val) && val.length === 0))
      return true;
   else
      return false;
}

function adjustFiletr_matchKeyExists(filter, key) {
   // alert('in adjustFiletr_matchKeyExists(): filter=' + JSON.stringify(filter) + '\n\tkey=' + key);
   const match = filter.standard.find(f => f.$match).$match;
   // const result = match.hasOwnProperty(key);
   const result = hasOwn(match, key);
   // alert('in adjustFiletr_matchKeyExists(): result=' + result);
   return result;
}

function adjustFiletr_eventKeyExists(filter, key) {
   // alert('in adjustFiletr_eventKeyExists(): filter=' + JSON.stringify(filter) + '\n\tkey=' + key);
   const eventsObj = filter.standard.find(f => f.$events);
   // const result = eventsObj ? eventsObj.events.$elemMatch.hasOwnProperty(key) : false;
   const result = eventsObj ? hasOwn(eventsObj.events.$elemMatch, key) : false;
   // alert('in adjustFiletr_eventKeyExists(): result=' + result);
   return result;
}

//stdFilters, behFilters
function adjustFilter_adjust$project(filter, injectingFilter, fieldName) {
   // alert(`in adjustFilter_adjust$project(): filter=${JSON.stringify(filter)}`);
   // alert(`in adjustFilter_adjust$project(): injectingFilter=${JSON.stringify(injectingFilter)}`);
   const $projectObj = filter.standard.find(f => f.$project);
   if ($projectObj) {
      if (!$projectObj.$project.events || $projectObj.$project.events === 1) {
         $projectObj.$project.events = {
            $filter: {
               input: '$events',
               as: 'event',
               cond: { $and: [] }
            }
         };
      }

      const allAnds = $projectObj.$project.events.$filter.cond.$and;
      if (fieldName === 'event_date') {
         let dateWasUpdated = false;
         const dateFilterKeys = Object.keys(injectingFilter);
         // alert('dateFilterKeys.length='+dateFilterKeys.length);
         if (dateFilterKeys.length === 1) {
            // const $and = allAnds.filter(and => Object.keys(and)[0] === '$eq');
            // console.warn('$and (1) for date:'+ JSON.stringify($and));
            
            const indexesToRemove = [];
            // // for (let i = 0; i < $and.length; i++) {
            for (let i = 0; i < $projectObj.$project.events.$filter.cond.$and.length; i++) {
               // // const and = $and[i];
               const and = $projectObj.$project.events.$filter.cond.$and[i];
               if (Object.keys(and)[0] != '$eq')
                  continue;

               // console.warn('origin and='+JSON.stringify(and));
               const eqPart1 = and.$eq[0];
               // console.warn('eqPart1='+JSON.stringify(eqPart1) + '\n'+typeof eqPart1);
               if (typeof eqPart1 === 'object') {
                  const eqKey = Object.keys(eqPart1)[0];
                  // console.warn('eqKey='+JSON.stringify(eqKey));
                  if (eqPart1[eqKey] === '$$event.event_date') {
                     // console.warn('injectingFilter.$expr.$eq=' + JSON.stringify(injectingFilter.$expr.$eq))
                     /* V240711 - start */
                     indexesToRemove.push(i);
                     // and.$eq[1] = injectingFilter.$expr.$eq[1];
                     // dateWasUpdated = true;
                     // break;
                     /* V240711 - end */
                  }
               }            
            }


            /* V240711 - start */
            if (indexesToRemove.length) {
               dateWasUpdated = true;

               indexesToRemove.sort((a, b) => b - a);
               indexesToRemove.forEach(index => {
                  // // $and.splice(index, 1);
                  $projectObj.$project.events.$filter.cond.$and.splice(index, 1);
               });

               const dateKey = Object.keys(injectingFilter.$expr.$eq[0])[0];
               const dateVal = injectingFilter.$expr.$eq[1];
               let dateValParts;
               if (typeof dateVal === 'string')
                  dateValParts = dateVal.split('-');
               else
                  dateValParts = [dateVal];

               if (dateValParts.length === 1) {
                  allAnds.push(injectingFilter.$expr);
               } else if (dateKey === '$year') {
                  allAnds.push({
                     "$eq":[
                        { "$year":"$$event.event_date" },
                        Number(dateValParts[0])
                     ]
                  });
                  allAnds.push({
                     "$eq":[
                        { "$month":"$$event.event_date" },
                        Number(dateValParts[1])
                     ]
                  });
               } else {
                  allAnds.push({
                     "$eq":[
                        { "$month":"$$event.event_date" },
                        Number(dateValParts[0])
                     ]
                  });
                  allAnds.push({
                     "$eq":[
                        { "$dayOfMonth":"$$event.event_date" },
                        Number(dateValParts[1])
                     ]
                  });
               }
            }
            /* V240711 - end */

   
            if (!dateWasUpdated) {
               // alert('before push: injectingFilter=' + JSON.stringify(injectingFilter));
               let allAndsPushed = false;
               const dateKey = Object.keys(injectingFilter.$expr.$eq[0])[0];
               if (dateKey === '$year' || dateKey === '$dayOfMonth') {
                  const dateVal = injectingFilter.$expr.$eq[1];
                  if (typeof dateVal === 'string') {
                     const dateValParts = dateVal.split('-');
                     if (dateValParts.length === 2) {
                        allAndsPushed = true;
                        if (dateKey === '$year') {
                           allAnds.push({
                              "$eq":[
                                 { "$year":"$$event.event_date" },
                                 Number(dateValParts[0])
                              ]
                           });
                           allAnds.push({
                              "$eq":[
                                 { "$month":"$$event.event_date" },
                                 Number(dateValParts[1])
                              ]
                           });
                        } else {
                           allAnds.push({
                              "$eq":[
                                 { "$month":"$$event.event_date" },
                                 Number(dateValParts[0])
                              ]
                           });
                           allAnds.push({
                              "$eq":[
                                 { "$dayOfMonth":"$$event.event_date" },
                                 Number(dateValParts[1])
                              ]
                           });
                        }
                     }
                  }
               }

               if (!allAndsPushed)
                  allAnds.push(injectingFilter.$expr);
            }   
         } else {
            let $and = allAnds.filter(and => ['$gte','$lte'].includes(Object.keys(and)[0]));
            if ($and.length != 2)
               $and = allAnds.filter(and => ['$gte','$lt'].includes(Object.keys(and)[0]));
            // alert('$and for date:'+ JSON.stringify($and));
            $and.forEach(and => {
               const key = Object.keys(and)[0];
               //V240628
               const newKey = key === '$lt' ? '$lte' : key;
               // alert('origin and='+JSON.stringify(and) + '\nkey='+key + ', and[key][0]=' + JSON.stringify(and[key][0]));
               if (and[key][0] === '$$event.event_date') {
                  // alert('inside if: key='+key);
                  and[key][1] = injectingFilter[newKey]; //V240628: key
                  dateWasUpdated = true;
                  // alert('new and=' + JSON.stringify(and) + '\ndateFilter='+JSON.stringify(injectingFilter));
               }
            });
      
            if (!dateWasUpdated) {
               dateFilterKeys.forEach(key => {
                  const item = {};
                  item[key] = [ '$$event.event_date', injectingFilter[key] ];
                  allAnds.push(item);
               });
            }
         }
      } else {
         const injectingKey = '$$event.' + fieldName;
         const injectingVal = [injectingKey, injectingFilter.$in];
         const andsWith$in = allAnds.filter(a => Object.keys(a)[0] === '$in');
         const existingAnd = andsWith$in.find(a => a.$in[0] === injectingKey);
         
         if (existingAnd)
            existingAnd.$in = injectingVal;
         else
            allAnds.push({ $in: injectingVal });
      }
   }
   // alert(`in adjustFilter_adjust$project(): filter=${JSON.stringify(filter)}`);
}

export function test(value) {
   alert(value);
}

//usage: await sleep(2000);
export function sleep(ms) {
   // alert('ms=' + ms);
   return new Promise(resolve => setTimeout(resolve, ms));
}

//to generate an integer random number between min and max.
export function getRandomInt(min, max) {
   return min + Math.floor(Math.random() * (max - min));
}


/*
 * To check if an item exists in an array or not.
 * src: array of strings to be searched in.
 * item: a string to be searched for.
 * returns true or false.
 */
export function isIncluded(src, item, isCaseSensitive) {
   // alert('src=' + JSON.stringify(src) + '\nitem=' + item + '\nisCaseSensitive=' + isCaseSensitive);
   if (isCaseSensitive)
      return src.filter(s => s.includes(item)).length > 0;
   else
      return src.filter(s => s.toLowerCase().includes(item.toLowerCase())).length > 0;
}

/*
 * https://stackoverflow.com/questions/6177975/how-to-validate-date-with-format-mm-dd-yyyy-in-javascript
 * To validate a date string
 * date: a date string in the form of 'mm/dd/yyyy'
 * returns true or false
 */
export function isValidDate(date) {
   // date should be in "mm/dd/yyyy" format
   if ( ! /^\d\d\/\d\d\/\d\d\d\d$/.test(date) )
       return false;

      //  const parts = date.split('/').map((p) => parseInt(p, 10));
      //  parts[0] -= 1;
      //  const newDate = new Date(parts[2], parts[0], parts[1]);
      //  return newDate.getMonth() === parts[0] && newDate.getDate() === parts[1] && newDate.getFullYear() === parts[2];

   let [mm, dd, yyyy] = date.split('/').map((p) => parseInt(p, 10));
   mm -= 1;
   const newDate = new Date(yyyy, mm, dd);
   return newDate.getMonth() === mm && newDate.getDate() === dd && newDate.getFullYear() === yyyy;
}

/*
 * Business Logics:
 * - No changes should affect itself and DatePicker.
 * - date-picker-change should update all other filters.
 * Note: What happens when a dropdown is already selected but this item is not available when date is changed?
 * Note: What happens to the originalFilter if date is changed again. 
 * - dropdown-change shouldn't affect KPIs.
 * - chart-click only affects other charts and tables.
 * Note: What happens if another chart is clicked.
 */
export function adjustFilter(originalFilter, currentFilter, adjustingFilter, chartType, importId) {
   // alert('clear concole...');
   // // console.log(`in adjustFilter() => originalFilter=${JSON.stringify(originalFilter)} => currentFilter=${JSON.stringify(currentFilter)} => adjustingFilter=${JSON.stringify(adjustingFilter)} => chartType=${chartType} => importId=${importId}`);
   // // alert('copy logs...');

   // originalFilter = {"standard":[{"$match":{"importId":{"$in":["6318cc0eb6c8e3a7005d8e62","6318b9c5865f1f17a7576e02","62f2eb2664ce543d7ede3ab1","62f2e93764ce54ffa9de3aad","62f2e2ac64ce548f5ade3aaa","62f2dfa61a1fe2622085528a"]}}}],"columns":["firstname","lastname","address1","address2","city","st","zip"]};
   // currentFilter = {"standard":[{"$match":{"importId":{"$in":["6"]},"events":{"$elemMatch":{"event_code":{"$in":["30500"]}}}}}],"columns":["firstname","lastname","address1","address2","city","st","zip"]};
   // adjustingFilter = {
   //    "importId":["62f2e2ac64ce548f5ade3aaa"],
   //    "event_code":"30500"
   // };
   // // adjustingFilter = {
   // //    "importId":{"$in": ["62f2e2ac64ce548f5ade3aaa"]},
   // //    "event_code":"30500"
   // // };
   // importId = "62f2e2ac64ce548f5ade3aaa";


   // alert('in bt-mixin.adjustFilter(): originalFilter=' + JSON.stringify(originalFilter) +
   //    '\n\tcurrentFilter=' + JSON.stringify(currentFilter) +
   //    '\n\tadjustingFilter=' + JSON.stringify(adjustingFilter) +
   //    '\n\tchartType=' + chartType + 
   //    '\n\timportId=' + importId);
   // console.log('in bt-mixin.adjustFilter(): ' +
   // '\n\tadjustingFilter=' + JSON.stringify(adjustingFilter) +
   // '\n\tchartType=' + chartType);
   const filter = JSON.parse(JSON.stringify(currentFilter));
   let partialFilter;
   if (Array.isArray(adjustingFilter) || typeof adjustingFilter === 'string')
      partialFilter = { event_date: adjustingFilter };
   else
      partialFilter = adjustingFilter;

   // alert('partialFilter=' + JSON.stringify(partialFilter));
   let eventKey = '';
   const newEvents = {};
   const $match = filter.standard.find(f => f.$match).$match;
   Object.keys(partialFilter).filter(k => !['$addFields','$dropdownSwitch'].includes(k.toString())).forEach(key => {
      const pfKey = key.startsWith('event_data') ? 'event_data' : key;
      const val = partialFilter[key];
      // alert('in bt-mixin.adjustFilter(): key=' + key + ', pfKey=' + pfKey + ', val=' + val);
      switch (pfKey) {
         //V240509:
         // case 'app_code':
         // case 'event_code':
         //    if (!adjustFiletr_eventKeyExists(originalFilter, key)) {
         //       if (adjustFilter_isEmpty(val))
         //          delete newEvents[key];
         //       else {
         //          newEvents[key] = { '$in': Array.isArray(val) ? val : [val] };
         //          adjustFilter_adjust$project(filter, newEvents[key], pfKey);
         //       }
         //    }            
         //    break;
         case 'event_date':
            if (!adjustFiletr_eventKeyExists(originalFilter, key)) {
               if (adjustFilter_isEmpty(val))
                  delete newEvents[key];
               else {
                  let val1, val2;
                  if (Array.isArray(val)) {
                     val1 = val[0];
                     val2 = val[1];
                  } else {
                     val1 = val;
                     val2 = val;
                  }
                  val1 += 'T00:00:00.000Z';
                  val2 += 'T23:59:59.999Z';
                  newEvents[key] = { '$gte': val1, '$lte': val2 };
                  // alert(`newEvents[${key}]=${JSON.stringify(newEvents[key])}`);

                  // alert('going to adjustFilter_adjust$project for event_date for ' + chartType);
                  adjustFilter_adjust$project(filter, newEvents[key], pfKey);
                  // alert('after adjusting $project: chartType=' + chartType + '\nfilter(event_date)=' + JSON.stringify(filter));
               }
            }
            break;
         case 'app_code':  //V240509
         case 'event_code':   //V240509
         case 'browser':
         case 'device':
         case 'is_trigger':
         case 'os':
         case 'platform':
         case 'event_element':   //V240702
         case 'event_path':      //V240702
         case 'event_data':      //V240708
            if (!adjustFiletr_eventKeyExists(originalFilter, key)) {
               if (adjustFilter_isEmpty(val))
                  delete newEvents[key];
               else {
                  // newEvents[key] = Array.isArray(val) ? { '$in': val } : [val];  //V240424:val;
                  // adjustFilter_adjust$project(filter, newEvents[key], pfKey);
                  newEvents[key] = { '$in': Array.isArray(val) ? val : [val] };
                  adjustFilter_adjust$project(filter, newEvents[key], key);   //V240507:pfKey
                  eventKey = key;
               }
            }
            break;
         case '$expr':  //V240425: Added $expr to $match as well
         if (!adjustFiletr_eventKeyExists(originalFilter, key)) {
            if (adjustFilter_isEmpty(val))
               delete newEvents[key];
            else {
               adjustFilter_adjust$project(filter, { $expr: val }, 'event_date');
               /* V240509 - start */
               let eventVal = null;
               if (eventKey) {
                  eventVal = partialFilter[eventKey];
               } else {
                  eventKey = Object.keys(partialFilter)[1];
                  if (['event_code','browser','device','is_trigger','os','platform'].includes(eventKey) || eventKey.startsWith('event_data.')) {
                     eventVal = partialFilter[eventKey];
                  }
               }

               let inVal;
               if (eventVal === null) {
                  inVal = val;
               } else {
                  /* V240711 - start */
                  // inVal = {
                  //    $and: [
                  //       val,
                  //       { $eq: ['$$event.' + eventKey, eventVal]}
                  //    ]
                  // };
                  let newVal = [];
                  const dateKey = Object.keys(val.$eq[0])[0];
                  if (dateKey === '$year' || dateKey === '$dayOfMonth') {
                     const dateVal = val.$eq[1];
                     if (typeof dateVal === 'string') {
                        const dateValParts = dateVal.split('-');
                        if (dateValParts.length === 2) {
                           if (dateKey === '$year') {
                              newVal.push({
                                 "$eq":[
                                    { "$year":"$$event.event_date" },
                                    Number(dateValParts[0])
                                 ]
                              });
                              newVal.push({
                                 "$eq":[
                                    { "$month":"$$event.event_date" },
                                    Number(dateValParts[1])
                                 ]
                              });
                           } else {
                              newVal.push({
                                 "$eq":[
                                    { "$month":"$$event.event_date" },
                                    Number(dateValParts[0])
                                 ]
                              });
                              newVal.push({
                                 "$eq":[
                                    { "$dayOfMonth":"$$event.event_date" },
                                    Number(dateValParts[1])
                                 ]
                              });
                           }
                        }
                     }
                  }

                  if (!newVal.length)
                     newVal.push(val);

                  inVal = {
                     $and: [
                        ...newVal,
                        { $eq: ['$$event.' + eventKey, eventVal]}
                     ]
                  };
                  /* V240711 - start */
               }

               /* V240508 - start */
               $match.$expr = {
                  $anyElementTrue: {
                     $map: {
                       input: "$events",
                       as: "event",
                       in: inVal
                     }
                  }
               };
               /* V240508 - end */
               /* V240509 - end (in:val => in:inVal) */
            }
         }
         break;
      default:
            // alert('originalFilter=' + JSON.stringify(originalFilter) + '\nkey=' + key + '\nval=' + JSON.stringify(val));
            if (adjustFiletr_matchKeyExists(originalFilter, key)) {
               // alert('matchKey exists: val=' + JSON.stringify(val));
               if (key === 'importId')
                  $match[key] = { '$in': [val[0]] };  //val[0];
            } else {
               if (adjustFilter_isEmpty(val)) {
                  delete $match[key];
               } else {
                  if (key === 'predefined') {
                     const predefinedKey = Object.keys(val)[0];
                     $match[predefinedKey] = val[predefinedKey];
                  // } else if (key === 'importId' || !partialFilter.hasOwnProperty('$dropdownSwitch') || partialFilter.$dropdownSwitch) {   //V220922.1
                  } else if (key === 'importId' || !hasOwn(partialFilter, '$dropdownSwitch') || partialFilter.$dropdownSwitch) {   //V230201.1
                     $match[key] = Array.isArray(val) ? { '$in': val } : val;
                  } else {
                     $match[key] = Array.isArray(val) ? { '$nin': val } : { '$ne': val };
                  }
               }
            }
            break;
      }
   });

   if (Object.keys(newEvents).length) {
      //V230629.1 (2 lines)
      const eventsObj = filter.standard.find(f => f.$match && f.$match.events);
      let events = eventsObj ? eventsObj.$match.events : { $elemMatch: {} };
      
      Object.keys(newEvents).forEach(key => {
         events.$elemMatch[key] = newEvents[key];
      });

      $match.events = events;
   }

   // if (partialFilter.hasOwnProperty('$addFields') && !filter.standard[0].hasOwnProperty('$addFields'))
   if (hasOwn(partialFilter, '$addFields') && !hasOwn(filter.standard[0], '$addFields'))
      filter.standard.unshift({ $addFields: partialFilter.$addFields });

   // if (partialFilter.hasOwnProperty('$expr')) {
   //    // if ($match.events) {
   //    //    $match.events = {
   //    //       $elemMatch: {}
   //    //    };
   //    // }

   //    // $match.events.$elemMatch.event_date = partialFilter;

   //    // const eqPart1 = partialFilter.$expr.$eq[0];
   //    // const eqKey = Object.keys(eqPart1)[0];
   //    // eqPart1[eqKey] = eqPart1[eqKey].replace('$', '$$event.');
   //    // alert('new partialFilter=' + JSON.stringify(partialFilter));
   //    adjustFilter_adjust$project(filter, partialFilter);
   // }

   // if (chartType != 'Dropdown' && !$match.importId) {
   if (!['Dropdown', 'DatePicker'].includes(chartType) && !$match.importId) {
      if (!originalFilter.standard.find(f => f.$group) || !originalFilter.standard.find(f => f.$group).$group._id.Import){
         if(importId){
            $match.importId = importId;
         }
      }
   }
      
   // alert('in bt-mixin.adjustFilter(): newFilter=' + JSON.stringify(filter));
   return filter;
}

export function adjustFilterWithImportId(filterIn, importId) {
   // alert('in bt-mixin.adjustFilterWithImportId(): filterIn=' + JSON.stringify(filterIn) +
   //    '\n\timportId=' + JSON.stringify(importId));
   const filterOut = JSON.parse(JSON.stringify(filterIn));

   // const $match = filterOut.standard.find(f => f.$match).$match;
   // if (!$match.importId)
   //    $match.importId = { '$in': [importId] };  //importId;

   //V240423
   if (filterOut.standard.findIndex(f => f.$limit) != 1)
      filterOut.standard.splice(1, 0, { $limit: 3000000 }); //V240628: 250000

   // console.log('in bt-mixin.adjustFilterWithImportId(): filterOut=' + JSON.stringify(filterOut));
   return filterOut;
}

export function getAllBehavioralFields() {
   return BEH_FIELDS;
}

export function isDate(dateString) {
   if (/^\d/.test(dateString.trim())) {
      let date = Date.parse(dateString.trim());

      if (date < 0 || isNaN(date))
         return false;
      else
         return true;
   }

   return false;
}

export function isNumber(strVal) {
   return !isNaN(strVal.trim());
}

export function isBoolean(strVal) {
   const newVal = strVal.trim().toLowerCase();
   return newVal === 'true' || newVal === 'false';
}

export function convertToBoolean(strVal) {
   const newVal = strVal.trim().toLowerCase();
   return newVal === 'true';
}

export function convertToISODate(date, setSeconds) {
   // const utcDate = Date.UTC(
   //    date.getUTCFullYear(),
   //    date.getUTCMonth(),// - 1, V221004.1
   //    date.getUTCDate(),
   //    date.getUTCHours(),
   //    date.getUTCMinutes()
   // );
   
   const year = date.getUTCFullYear();
   const month = date.getUTCMonth();
   const day = date.getUTCDate();
   const hour = date.getUTCHours();
   const min = date.getUTCMinutes();
   const sec = setSeconds ? date.getUTCSeconds() : 0;

   const utcDate = Date.UTC(year, month, day, hour, min, sec);
   return new Date(utcDate).toISOString();
}

export function hasOwn(obj, prop) {
   return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function generateUUID() {
   var d = new Date().getTime();
   var uuid = 'xxxXXxxx-Xxxx-4xxX-yxxx-xXxXXXxxxXxx'.replace(/[xXy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      if (c == 'x')
         return r.toString(16);
      else if (c == 'X')
         return r.toString(16).toUpperCase();
      else
         return (r & 0x7 | 0x8).toString(16);
   });

   return uuid;
}

//TODO: seems it is not in use!
export function getEventFields() {
   return EVENT_FIELDS;
}

export function getEventDataFields() {
   return EVENT_DATA_FIELDS;
}

export function formatNumber(number, maxFractionDigits) {
   return new Intl.NumberFormat('en-US', 
      { 
         maximumFractionDigits: maxFractionDigits || 0
      }).format(number);
}

// To merge one or more arrays and return their unique items
export function mergeArrays(...arrays) {
   let combinedArray = [].concat(...arrays);
   let uniqueArray = [...new Set(combinedArray)];
   return uniqueArray;
}