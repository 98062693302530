<template><!-- class="pt-0 mt-0"-->
<!-- TODO:
- in edit dialog, disable SAVE till a change happens.
- display err msg instead of alert
- make Create GROUP disabled if no policies exist
- in the dropdown, remove those items which are already existed in policies? and identitis?
-->
<!-- HISTORY:
   V230118.1: Modified v-slot:expanded-item to resolve the issue with displaying data.
   05/02/22(B0.8): Implemented logout feature.
   09/02/20(B0.7): Fixed issues with hide-default-footer by adding itemsPerPage variable + Handled search query string.
   08/27/20(B0.6): Applied some changes along with Aref + Temporarily removed power user create permission + Removed main card's width.
   08/10/20(B0.4): Hid table footers for < 11 records + Fixed filterPolicies() bug.
   08/05/20(B0.3): Introduced everyone group to be reserved for the power admin only + Added isAdmin to the model n UI +
      PA can add identities with aid='*'
-->
<v-container fluid class="px-3 py-3" v-if="isAuthorizedViewer">
   <v-card>
      <v-toolbar flat color="white" class="px-2">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">folder</v-icon>
            <span>Groups</span>
         </h1>
         <div class="flex-grow-1"></div>
         <v-dialog v-if="isAuthorizedUser"
            max-width="500px"
            :disabled="!policies || policies.length === 0"
            v-model="dialog"
         >
            <template v-slot:activator="{ on }">
               <!-- TODO: Adding disabled prop to v-btn hide it -->
               <v-btn dark 
                  color="#ff6633"
                  class="mb-2"
                  v-on="on"
               >
                  <v-icon left>add</v-icon>
                  <span>CREATE GROUP</span>
               </v-btn>
            </template>
         </v-dialog>
      </v-toolbar>

      <v-card-title class="pt-0">
         <div v-if="isAuthorizedUser">
            <v-btn text small
               :disabled="isAdd2IdentitiesDisabled" 
               @click="dialogAdd2IdentitiesClicked"
            >
               <v-icon>add</v-icon> {{menuItems.add2Identities}}
            </v-btn>
            <v-btn text small
               :disabled="isAddPoliciesDisabled"
               @click="dialogAddPoliciesClicked"
            >
               <v-icon>add</v-icon> {{menuItems.addPolicies}}
            </v-btn>
            <v-btn text small
               :disabled="itemsToWorkWith.length==0 || (!jwt.pa && itemsToWorkWith.filter(row => row.ownerPaid==='*' || row.canBeDeleted===false).length>0)"
               @click="deleteItems"
            >
               <v-icon>delete</v-icon> {{menuItems.delete}}
            </v-btn>
         </div>
         <div class="flex-grow-1"></div>
         <v-text-field single-line hide-details
            class="pt-0"
            label="Search"
            append-icon="search"
            :disabled="!groups || groups.length < 2"
            v-model="search"
         ></v-text-field>
      </v-card-title>

      <v-data-table fixed-header show-expand
         class="elevation-1"
         item-key="name"
         v-model="selectedItems"
         :headers="headers"
         :items="groups"
         :show-select="isAuthorizedUser"
         :single-select="singleSelect"
         :search="search"
         :hide-default-footer="groups.length < itemsPerPage + 1"
         :loading="loading"
         :loading-text="$t('loading-text')"
         :no-data-text="$t('no-data-text', { value: 'groups' })"
         :no-results-text="$t('no-results-text', { value: 'groups' })"
         :items-per-page.sync="itemsPerPage"
         :dense="settings.areTablesDensed"
         :single-expand="settings.singleExpand"
         @input="checkboxClicked"
      ><!--@toggle-select-all="selectAllClicked"-->

         <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
            <v-simple-checkbox v-ripple
               :value="isCheckboxDisabled(item) ? false : isSelected" 
               :disabled="isCheckboxDisabled(item)"
               :readonly="isCheckboxDisabled(item)"                
               @input="select($event)" 
            ></v-simple-checkbox>
         </template>

         <template v-slot:[`item.ownerPaid`]="{ item }">
            {{ item.ownerPaid == '*' ? 'Global' : 'Account' }}
         </template>

         <template v-slot:[`item.policies`]="{ item }">
            {{ getNames(item.policies).join(', ') }}
         </template>

         <template v-slot:top>

            <v-dialog v-model="dialog" persistent no-click-animation max-width="960px">
               <!-- <template v-slot:activator="{ on }"></template> -->
               <v-card>
                  <v-card-title class="headline">{{ formTitle }}
                     <div class="flex-grow-1"></div>
                     <v-btn v-if="showPopulateLink" color="blue darken-1" text @click="populateTestData()">populate test data</v-btn>
                  </v-card-title>

                  <v-card-text class="pb-0">
                     <v-container>
                        <v-form ref="groupForm" v-model="isGroupFormValid" lazy-validation>
                           <v-row dense>
                              <v-col cols="10">
                                 <v-text-field outlined autofocus
                                    ref="groupName"
                                    label="* Group Name"
                                    placeholder="Enter group name"
                                    minlength="5" maxlength="50" counter="50" 
                                    v-model="editedItem.name"
                                    :rules="nameRules"
                                    @input="nameChanged"
                                 ></v-text-field>
                              </v-col>
                                 <v-col cols="2" class="justify-right">
                                    <v-switch
                                       class="mx-2"
                                       label="Is Admin?"
                                       :disabled="!jwt.pa || editedIndex>-1"
                                       v-model="editedItem.isAdmin"
                                       @change="isAdminChanged"                               
                                    ></v-switch>
                                 </v-col>
                           </v-row>
                           <v-row><v-col cols="12"></v-col></v-row>
                           <v-row no-gutters>
                              <v-col cols="12" class="font-weight-bold title">
                                 <v-autocomplete multiple clearable open-on-clear outlined small-chips deletable-chips required
                                    label="* Policies"
                                    placeholder="select as many policies as needed"
                                    v-model="selectedDialogItems" 
                                    :items="policiesDropdown"
                                    :rules="selectRules"
                                    :counter="policies.length"
                                    @change="selectChanged()"
                                 ></v-autocomplete>
                              </v-col>
                           </v-row>
                           <v-row dense no-gutters pt-0 mt-0>
                              <v-col cols="12">
                                 <v-checkbox mt-0
                                    label="Add all policies"
                                    v-model="isAllPoliciesChecked"
                                    :readonly="isAllPoliciesChecked"
                                    :disabled="isAllPoliciesChecked"
                                    @change="selectAllChanged(policiesDropdown)"
                                 ></v-checkbox>
                              </v-col>
                           </v-row>
                        </v-form>
                     </v-container>
                  </v-card-text>

                  <v-card-actions class="pt-0">
                     <div class="flex-grow-1"></div>
                     <v-btn color="blue darken-1" text @click="cancelItem">Cancel</v-btn>
                     <v-btn color="blue darken-1" text @click="saveItem">Save</v-btn>
                  </v-card-actions>
               </v-card>
            </v-dialog>

            <v-dialog v-model="dialogAddPolicies" persistent no-click-animation max-width="960px">
               <!-- <template v-slot:activator="{ on }"></template> -->
               <v-card>
                  <v-card-title class="headline">{{menuItems.addPolicies}}</v-card-title>

                  <v-card-text class="pb-0">
                     <v-container>
                        <v-layout class="mb-5">
                           <v-row no-gutters class="mb-6">
                              <v-col cols="auto" class="font-weight-bold">Selected policies will be added to {{$tc('this-group', itemsToWorkWith.length)}}:&nbsp;</v-col>
                              <v-col class="font-italic">{{ getNames(itemsToWorkWith).join(', ') }}</v-col>
                           </v-row>
                        </v-layout>
                        <v-form ref="addPoliciesForm" lazy-validation mt-5>
                           <v-layout>
                           <v-row dense no-gutters mb-0 pb-0>
                              <v-col cols="12" class="font-weight-bold title">
                                    <!-- @change="selectChanged(policiesDropdown)" -->
                                 <v-autocomplete multiple clearable open-on-clear outlined small-chips deletable-chips required
                                    ref="policies"
                                    label="* Policies"
                                    placeholder="select as many policies as desired"
                                    v-model="selectedDialogItems" 
                                    :items="policiesDropdown"
                                    :rules="selectRules"
                                    :counter="policies.length"
                                    @change="selectChanged()"
                                 ></v-autocomplete>
                              </v-col>
                           </v-row>
                           </v-layout>
                           <v-layout>
                           <v-row dense no-gutters pt-0 mt-0>
                              <v-col cols="12">
                                 <v-checkbox mt-0
                                    label="Add all policies"
                                    v-model="isAllPoliciesChecked"
                                    :readonly="isAllPoliciesChecked"
                                    :disabled="isAllPoliciesChecked"
                                    @change="selectAllChanged(policiesDropdown)"
                                 ></v-checkbox>
                              </v-col>
                           </v-row>
                           </v-layout>
                        </v-form>
                     </v-container>
                  </v-card-text>

                  <v-card-actions class="pt-0">
                     <div class="flex-grow-1"></div>
                     <v-btn color="blue darken-1" text @click="cancelAddPolicies">Cancel</v-btn>
                     <v-btn color="blue darken-1" text @click="addPoliciesToGroups">Submit</v-btn>
                  </v-card-actions>
               </v-card>
            </v-dialog> 

            <v-dialog v-model="dialogAdd2Identities" persistent no-click-animation max-width="960px">
               <!-- <template v-slot:activator="{ on }"></template> -->
               <v-card>
                  <v-card-title class="headline">{{menuItems.add2Identities}}</v-card-title>

                  <v-card-text class="pb-0">
                     <v-container>
                        <v-layout class="mb-5">
                           <v-row no-gutters class="mb-6">
                              <v-col cols="auto" class="font-weight-bold">Selected identities will be added to these groups:&nbsp;</v-col>
                              <v-col class="font-italic">{{ getNames(itemsToWorkWith).join(', ') }}</v-col>
                           </v-row>
                        </v-layout>
                        <v-form ref="add2IdentitiesForm" lazy-validation mt-5>
                           <v-layout>
                              <v-row dense no-gutters mb-0 pb-0>
                                 <v-col cols="12" class="font-weight-bold title">
                                       <!-- @change="selectChanged(identitiesDropdown)" -->
                                    <v-autocomplete multiple clearable open-on-clear outlined small-chips deletable-chips required
                                       ref="identities"
                                       label="* Identities"
                                       placeholder="select as many identities as needed"
                                       v-model="selectedDialogItems" 
                                       :items="identitiesDropdown"
                                       :rules="selectRules"
                                       :counter="identities.length"
                                       @change="selectChanged()"
                                    ></v-autocomplete>
                                 </v-col>
                              </v-row></v-layout><v-layout>
                              <v-row dense no-gutters pt-0 mt-0>
                                 <v-col cols="12">
                                    <v-checkbox mt-0 
                                       label="Add all identities"
                                       v-model="isAllIdentitiesChecked"
                                       :readonly="isAllIdentitiesChecked"
                                       :disabled="isAllIdentitiesChecked"
                                       @change="selectAllChanged(identitiesDropdown)"
                                    ></v-checkbox>
                                 </v-col>
                              </v-row>
                           </v-layout>
                        </v-form>
                     </v-container>
                  </v-card-text>

                  <v-card-actions class="pt-0">
                     <div class="flex-grow-1"></div>
                     <v-btn color="blue darken-1" text @click="cancelAdd2Identities">Cancel</v-btn>
                     <v-btn color="blue darken-1" text @click="add2Identities">Submit</v-btn>
                  </v-card-actions>
               </v-card>
            </v-dialog>

         </template>

         <template v-slot:[`item.isAdmin`]="{ item }">
            {{ item.isAdmin.toString() }}
         </template>

         <template v-slot:[`item.action`]="{ item }">
            <span v-if="isAuthorizedUser && !isEditDisabled(item)">
               <v-icon small
                  class="mr-2"
                  @click="editItem(item)"
               >edit</v-icon>
               <v-icon small
                  :disabled="item.canBeDeleted===false"
                  @click="deleteItem(item)"
               >delete</v-icon>
            </span>
         </template>

         <template v-slot:expanded-item="{ item }">
            <td colspan="6">
               <!-- <table>
                  <thead width="100%">
                     <tr class="font-weight-bold font-italic align-left">
                        <th>&nbsp;</th>
                        <th width="20%">ID</th>
                        <th width="20%">Name</th>
                        <th width="20%">Actions</th>
                        <th width="35%">Conditions</th>
                        <th width="5%">Level</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr v-for="p in item.policies" :key="p.name">
                        <td>&nbsp;</td>
                        <td width="20%">{{p._id}}</td>
                        <td>{{p.name}}</td>
                        <td>{{p.actions.join(', ')}}</td>
                        <td>{{p.conditions.join(p.conditionLogic === 'and' ? ' AND ' : ' OR ')}}</td>
                        <td>{{p.ownerPaid === '*' ? 'G' : 'A'}}</td>
                     </tr>
                  </tbody>
               </table> -->
               <v-data-table dense hide-default-header hide-default-footer
                  class="elevation-1"
                  item-key="name"
                  :headers="itemHeaders"
                  :items="item.policies"
                  :items-per-page="-1"
               >
                  <template v-slot:header="{ props: { headers } }">
                     <thead>
                        <tr>
                           <th v-for="h in headers" :key="h.text" class="grey lighten-3">
                              <span class="font-weight-bold font-italic">{{h.text}}</span>
                           </th>
                        </tr>
                     </thead>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                     {{ item.actions.join(', ') }}
                  </template>
                  <template v-slot:[`item.conditions`]="{ item }">
                     {{ item.conditions.join(item.conditionLogic === 'and' ? ' AND ' : ' OR ') }}
                  </template>
                  <template v-slot:[`item.ownerPaid`]="{ item }">
                     {{ item.ownerPaid === '*' ? 'G' : 'A' }}
                  </template>
               </v-data-table>
            </td>
         </template>

         <!-- <template v-slot:no-results>
            <td colspan="6" class="text-align-center">No matching groups found!</td>
         </template> -->

      </v-data-table>

   </v-card>
</v-container>
<v-container v-else>
   <div class="red--text">
      <p>Sorry, you are not authorized to access this page!</p>
      <!-- TODO: temporary -->
      <ul>
         <li>Power Admin: {{ jwt.pa }}</li>
         <li>Power User: {{ jwt.pu }}</li>
         <li>Parent Account: {{ jwt.paid }}</li>
      </ul>
   </div>
</v-container>
</template>

<script>
import { APIService } from '../services/iam-api-service.js';
import { Group } from '../models/iam-group.js';
import { IamSettings } from '../models/iam-settings.js';

export default {
   name: 'IamGroups',

   props: {
      debug: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         jwt: {},
         apiService: null,
         menuItems: { 
            delete: 'DELETE GROUPS', 
            addPolicies: 'Add Policies to Groups', 
            add2Identities: 'Add Groups to Identities' 
         },
         search: '',
         singleSelect: false,
         headers: [
            { text: 'Name', value: 'name', align: 'left', sortable: true, width: "20%" },
            { text: 'Associated Policies', value: 'policies', align: 'left', sortable: false, width: "55%" },
            { text: 'Level', value: 'ownerPaid', align: 'left', sortable: true, width: "10%" },
            { text: 'Is Admin', value: 'isAdmin', align: 'left', sortable: true, width: "5%" },
            { text: '', value: 'action', align: 'right', sortable: false, width: "10%" },       
         ],
         itemHeaders: [
            { text: 'ID', value: '_id', align: 'left', sortable: false, width: "15%" },
            { text: 'Policy Name', value: 'name', align: 'left', sortable: false, width: "15%" },
            { text: 'Actions', value: 'actions', align: 'left', sortable: false, width: "15%" },
            { text: 'Conditions', value: 'conditions', align: 'left', sortable: false, width: "50%" },
            { text: 'Level', value: 'ownerPaid', align: 'left', sortable: false, width: "5%" },       
         ],
         groups: [],
         policies: [],
         identities: [],
         selectedItems: [],
         editedIndex: -1,
         editedItem: new Group(),
         dialog: false,
         dialogAddPolicies: false,
         dialogAdd2Identities: false,
         isGroupFormValid: true,
         rules: {
            required: value => !!value || this.$t('required'),
            requiredArray: value => value.length > 0 || this.$t('required'),
            minLength: value => value.length >= 5 || this.$t('min5'),
            duplicate: value => this.groups.find(g => g.name.toLowerCase() === value.toLowerCase() && g._id != this.editedItem._id) === undefined || 
               this.$t('duplicate-group'),
            reserved: value => value.toLowerCase() != 'everyone' || this.jwt.pa || this.$t('reserved'),
         },
         policiesDropdown: [],
         identitiesDropdown: [],
         selectedDialogItems: [],
         nameRules: [],
         selectRules: [],
         // isSelectAllChecked: false,
         itemsToWorkWith: [],
         loading: true,
         settings: new IamSettings(this.debug),
         isAdd2IdentitiesDisabled: true,
         isAddPoliciesDisabled: true,
         itemsPerPage: 10
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },
      isAuthorizedViewer() {
         // user is authorized to view if it is power admin or power user of any account.
         return this.jwt.pa || this.jwt.pu;
      },
      isAuthorizedUser() {
         // user is authorized if it is power admin or power user of the parent account
         return this.jwt.pa; //TODO: || (!this.jwt.paid && this.jwt.pu);   //temporary
      },
      // isAuthorizedIdentity() {
      //    // user is authorized if it is a power user only and not a power admin
      //    return !this.jwt.pa && this.jwt.pu;
      // },
      formTitle () {
         return this.editedIndex === -1 ? 'Create a New Group' : 'Edit Group'
      },
      showPopulateLink () {
         return process.env.NODE_ENV != 'production' && this.editedIndex === -1;
      },
      isAllPoliciesChecked() {
         return this.selectedDialogItems.length === this.policiesDropdown.length;
      },
      isAllIdentitiesChecked() {
         return this.selectedDialogItems.length === this.identitiesDropdown.length;
      },
      pageWidth() {
         return this.settings.keepMenuExpanded ? '81vw' : '91vw';
      }
   },

   watch: {
      token() {
         this.init();
      },
      dialog (val) {
         //val || this.closeItem()
         if (val) {
            setTimeout(() => {
               this.filterPolicies(this.editedItem);
               // if (this.editedItem.policies.length === this.policiesDropdown.length)
               //    this.isSelectAllChecked = true;
               this.$refs.groupName.focus();
            }, 10);
         } else
            this.closeItem();
      },
      dialogAddPolicies (val) {
         //val || this.closeAddPolicies()
         if (val) {
            setTimeout(() => {
               this.filterPolicies(this.selectedItems[0]);
               this.$refs.policies.focus();
            }, 10);
         } else
            this.closeAddPolicies();
      },
      dialogAdd2Identities (val) {
         //val || this.closeAdd2Identities()
         if (val) {
            setTimeout(() => {
               this.$refs.identities.focus();
            }, 10);
         } else
            this.closeAdd2Identities();
      },
      groups: {
      // immediate: true,
      // deep: true,
      handler(val) {
         this.itemsPerPage = isNaN(this.settings.rowsPerPage) ? val.length : this.settings.rowsPerPage;
      }
   }
},

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`IamGroups V230118.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      init() {
         try {
            if (this.token) {
               this.jwt = {};
               const JWT = JSON.parse(atob(this.token.split('.')[1]));
               // alert('in IamGroups: jwt=' + JSON.stringify(JWT));
               this.jwt = {
                  aid: JWT.aid,
                  paid: JWT.paid,
                  pa: JWT.pa,
                  pu: JWT.pu
               };
               this.log('in IamGroups: jwt=' + JSON.stringify(this.jwt));
               if (this.isAuthorizedUser || this.isAuthorizedViewer) {
                  this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
                  this.getIdentities();
                  this.getPolicies();
                  this.getGroups();
                  // this.getIdentities();
               }
            } else
               this.jwt = {};
         } catch (error) {
            alert('Exception in IamGroups while parsing token: ' + JSON.stringify(error));
         }
      },

      async getGroups() {
         let result = await this.apiService.getGroups();
         this.loading = false;
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.groups = result.data;
            this.setGroupsDeleteStatus();
         }
      },

      async getPolicies() {
         let result = await this.apiService.getPolicies();
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.policies = result.data;
         }
      },

      async getIdentities() {
         let result = await this.apiService.getIdentities();
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.identities = result.data;
            this.identitiesDropdown = this.getIdentityNames(this.identities);
            // this.setGroupsDeleteStatus();
         }
      },

      async setGroupsDeleteStatus() {
         let ind = -1;
         this.identities.forEach(identity => {
            identity.groups.forEach(group => {
               ind = this.groups.findIndex(g => g.name === group.name);
               if (ind > -1)
                  this.groups[ind].canBeDeleted = false;
            });
         });
         // alert(JSON.stringify(this.groups));
      },

      getNames(items) {
         this.log('in getNames(): items='+JSON.stringify(items));
         return items.map(i => { return i.name; });
      },

      getIdentityNames(identities) {
         return identities.map(i => { return i.email; });
      },

      isEditDisabled (item) {
         return item.ownerPaid == '*' ? !this.jwt.pa : this.jwt.pa;
      },

      isCheckboxDisabled (item) {
         if (this.jwt.pa) return this.isEditDisabled(item);
         else false;
      },

      // selectAllClicked(event) {
      //    this.log('in selectAllClicked: ' + JSON.stringify(event));
      // },

      checkboxClicked (selectedRows) {
         const isAdminTrue = this.selectedItems.filter(item => item.isAdmin);
         const isAdminFalse = this.selectedItems.filter(item => !item.isAdmin);
         this.isAdd2IdentitiesDisabled = this.selectedItems.length === 0 || 
            this.identities.length === 0 ||
            (this.jwt.pa && isAdminFalse.length > 0);
         this.isAddPoliciesDisabled = this.selectedItems.length === 0 || (isAdminTrue.length > 0 && isAdminFalse.length > 0);

         // if (this.jwt.pa)
         //    this.itemsToWorkWith = selectedRows.filter(row => !this.isEditDisabled(row));
         // else
         //    this.itemsToWorkWith = selectedRows;

         // if (this.itemsToWorkWith.length == 0)
         //    this.selectedItems = [];

         this.itemsToWorkWith = JSON.parse(JSON.stringify(this.selectedItems));
         this.log(`in checkboxClicked(): selectedRows.len=${selectedRows.length}, this.selectedItems.len=${this.selectedItems.length}, this.itemsToWorkWith.len=${this.itemsToWorkWith.length}`);
      },

      editItem (item) {
         // this.defaultItem = JSON.parse(JSON.stringify(item));
         // this.editedItem = Object.assign({}, item);
         this.editedItem = JSON.parse(JSON.stringify(item));
         this.editedIndex = this.groups.indexOf(item);
         this.selectedDialogItems = this.getNames(item.policies);
         this.dialog = true;
      },

      async deleteItem (item) {
         if (confirm(`Are you sure you want to delete '${item.name}'?`)) {
            let result = await this.apiService.deleteGroup(item);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.groups.splice(this.groups.indexOf(item), 1);
               this.$emit('snackbar-event', this.$t('deleted', { value: item.name }));
            }
         }
      },

      async deleteItems () {
         if (!confirm(`Are you sure you want to delete the ${this.$tc('group-selected', this.itemsToWorkWith.length)}?`))
            return;

         let msg = '';
         let responses = await this.apiService.deleteGroups(this.itemsToWorkWith);
         this.log('in deleteItems(): results=' + JSON.stringify(responses));

         let results = {
            succeeded: [], //only names of deleted policies
            failed: []     //{ name: '', message: '' }
         };

         responses.forEach(response => {
            if (response.logout)
               this.logout();
            else if (response.message === null) {
               results.succeeded.push(response.data.name);
            } else {
               results.failed.push({ name: response.data.name, message: response.message });
            }
         });

         //TODO: delete items which is not editable from selectedItems or make it empty
         
         if (results.succeeded.length > 0) {
            msg = 'Following ' + this.$tc('group-was', results.succeeded.length) + ' deleted:\n' + results.succeeded.join(', ') + '\n\n';
            results.succeeded.forEach (result => {
               this.log('result='+JSON.stringify(result));
               let item = this.selectedItems.find(i => i.name === result);
               this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
               this.groups.splice(this.groups.indexOf(item), 1);
            });
         }

         if (results.failed.length > 0) {
            msg += 'Following ' + this.$tc('group', results.failed.length) + ' could not be deleted:\n';
            results.failed.forEach (result => {
               msg += '\n- ' + result.name + ' => ' + result.message;
            });
            alert(msg);
            this.$emit('snackbar-event', this.$t('action-finished', { action: this.menuItems.delete }));
         } else {
            this.$emit('snackbar-event', `${results.succeeded.length} ${this.$tc('group-was', results.succeeded.length)} deleted.`);
         }
      },

      filterPolicies(item) {
         // let filteredPolicies;
         // if (this.jwt.pa) filteredPolicies = this.policies.filter(p => p.ownerPaid === '*');
         // else filteredPolicies = this.policies.filter(p => p.ownerPaid != '*');
         const filteredPolicies = this.policies.filter(p => p.isAdmin === item.isAdmin);
         this.policiesDropdown = this.getNames(filteredPolicies.filter(p => p.isAdmin === item.isAdmin));
      },

      isAdminChanged() {
         this.selectedDialogItems = [];
         // this.isSelectAllChecked = false;
         this.filterPolicies(this.editedItem);
      },

      nameChanged () {
         if (this.nameRules.length === 0)
            this.nameRules = [this.rules.required, this.rules.minLength, this.rules.duplicate, this.rules.reserved];
      },

      // selectChanged (dropdownItems) {
      selectChanged () {
         if (this.selectRules.length === 0)
            this.selectRules.push(this.rules.requiredArray);

         // if (dropdownItems)
         //    this.isSelectAllChecked = this.selectedDialogItems.length === dropdownItems.length;
      },

      async saveItem () {
         this.log('in saveItem()');
         this.isGroupFormValid = true;
         this.nameChanged();
         this.selectChanged();

         if (!this.$refs.groupForm.validate()) {
            this.isGroupFormValid = false;
            return;
         }

         let result;
         const name = this.editedItem.name;
         this.editedItem.policies = [];
         this.selectedDialogItems.map(pn => {
            this.editedItem.policies.push(this.policies.find(p => p.name === pn));  //._id
         });

         if (this.editedIndex > -1) {
            // if (!_areObjectsEqual(this.editedItem, this.defaultItem)) {   //TODO: this is not working.
               this.log('updating group...');
               result = await this.apiService.updateGroup(this.editedItem);
               if (result.logout)
                  this.logout();
               else if (!result.message) {
                  this.$set(this.groups, this.editedIndex, this.editedItem);
                  this.dialog = false;
                  this.$emit('snackbar-event', this.$t('updated', { value: name }));
               }
         } else {
            this.log('creating group...');
            result = await this.apiService.createGroup(this.editedItem);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               // const groupPolicies = [];
               // this.result.data.policies.forEach(id => {
               //    groupPolicies.push(this.policies.find(p => p._id === id));
               // });
               // result.data.policies = groupPolicies;
               this.groups.push(result.data);
               this.dialog = false;
               this.$emit('snackbar-event', this.$t('created', { value: result.data.name, id: result.data._id }));
            }
         }
      },

      cancelItem () {
         this.log('in cancelItem()');
         // this.$set(this.groups, this.editedIndex, this.defaultItem);
         this.dialog = false;
         this.$emit('snackbar-event', this.$t('action-cancelled', { action: (this.editedIndex === -1 ? 'CREATE' : 'EDIT') + ' GROUP' }));
      },

      closeItem () {
         this.log('in closeItem()');
         setTimeout(() => {
            this.editedItem = new Group();
            this.editedIndex = -1;
            this.nameRules = [];
            this.resetDialog();
         }, 100)
      },

      selectAllChanged(dropdownItems){
         this.selectedDialogItems = dropdownItems;
      },

      dialogAddPoliciesClicked () {
         this.dialogAddPolicies = true;
      },

      dialogAdd2IdentitiesClicked () {
         // if (this.identities.length === 0)
         //    this.getIdentities();

         this.dialogAdd2Identities = true;
      },

      async addPoliciesToGroups () {
         this.log('in addPoliciesToGroups()');
         this.selectChanged();

         if (!this.$refs.addPoliciesForm.validate()) {
            return;
         }

         // getting policy objects from their names
         let policies2Add = this.selectedDialogItems.map(pn => {
            return this.policies.find(policy => policy.name === pn);
         });

         let responses = await this.apiService.addPoliciesToGroups(this.itemsToWorkWith, policies2Add);
         this.log('in addPoliciesToGroups(): results=' + JSON.stringify(responses));

         let results = {
            succeeded: [], //only names of deleted policies
            failed: []     //{ name: '', message: '' }
         };

         this.dialogAddPolicies = false;

         responses.forEach(response => {
            if (response.logout)
               this.logout();
            else if (response.message === null) {
               results.succeeded.push(response.data.name);
               this.$set(this.groups, this.groups.findIndex(item => item._id === response.data._id), response.data);
            } else {
               results.failed.push({ name: response.data.name, message: response.message });
            }
         });

         let msg = '';
         if (results.succeeded.length > 0) {
            msg = `Following ${this.$tc('group-was', results.succeeded.length)} updated:\n${results.succeeded.join(', ')}\n\n`;
         }

         if (results.failed.length > 0) {
            msg += `Following ${this.$tc('group', results.failed.length)} could not be updated:\n`;
            results.failed.forEach (result => {
               msg += '\n- ' + result.name + ' => ' + result.message;
            });
            alert(msg);
            this.$emit('snackbar-event', this.$t('action-finished', { action: this.menuItems.addPolicies.toUpperCase() }));
         } else {
            this.$emit('snackbar-event', `${results.succeeded.length} ${this.$tc('group-was', results.succeeded.length)} updated.`);
         }

         this.selectedItems = [];
      },

      async add2Identities () {
         this.log('in add2Identities()');
         this.selectChanged();

         if (!this.$refs.add2IdentitiesForm.validate()) {
            return;
         }

         // getting identity objects from their names
         let identities2Add = this.selectedDialogItems.map(e => {
            return this.identities.find(identity => identity.email === e);
         });

         let responses = await this.apiService.addGroupsToIdentities(identities2Add, this.itemsToWorkWith);
         this.log('in add2Identities(): results=' + JSON.stringify(responses));

         let results = {
            succeeded: [], //only names of deleted policies
            failed: []     //{ name: '', message: '' }
         };

         this.dialogAdd2Identities = false;

         responses.forEach(response => {
            if (response.logout)
               this.logout();
            else if (response.message === null) {
               results.succeeded.push(response.data.name);
            } else {
               results.failed.push({ name: response.data.name, message: response.message });
            }
         });

         let msg = '';
         if (results.succeeded.length > 0) {
            msg = 'Following ' + this.$tc('group-was', results.succeeded.length) +' added:\n' + results.succeeded.join(', ') + '\n\n';
         }

         if (results.failed.length > 0) {
            msg += `Following ${this.$tc('group', results.failed.length)} could not be added:\n`;
            results.failed.forEach (result => {
               msg += '\n- ' + result.name + ' => ' + result.message;
            });
            alert(msg);
            this.$emit('snackbar-event', this.$t('action-finished', { action: this.menuItems.add2Identities.toUpperCase() }));
         } else {
            this.$emit('snackbar-event', `${results.succeeded.length} ${this.$tc('group-was', results.succeeded.length)} added to ${identities2Add.length} ${this.$tc('identity', identities2Add.length)}.`);
         }

         this.selectedItems = [];
      },

      cancelAddPolicies () {
         this.dialogAddPolicies = false;
         this.$emit('snackbar-event', this.$t('action-cancelled', { action: this.menuItems.addPolicies.toUpperCase() }));
      },

      cancelAdd2Identities () {
         this.dialogAdd2Identities = false;
         this.$emit('snackbar-event', this.$t('action-cancelled', { action: this.menuItems.add2Identities.toUpperCase() }));
      },

      closeAddPolicies () {
         this.log('in closeAddPolicies()');
         setTimeout(() => {
            this.resetDialog();
         }, 100)
      },

      closeAdd2Identities () {
         this.log('in closeAdd2Identities()');
         setTimeout(() => {
            this.resetDialog();
         }, 100)
      },

      // getActualItems (items) {
      //    let actualItems = [];
      //    items.forEach(item => {
      //       if (!this.isEditDisabled(item))
      //       actualItems.push(item);
      //    });
      //    return actualItems;
      // },

      resetDialog() {
         this.selectedDialogItems = [];
         this.selectRules = [];
         // this.isSelectAllChecked = false;
      },
         
      populateTestData () {
         this.log('in populateTestData()');
         const index = Math.floor(Math.random() * (this.policies.length - 1));
         this.selectedDialogItems = this.getNames([this.policies[index]]);
         this.editedItem = new Group(
            `New Group ${this.groups.length + 1}`,
            false,
            this.selectedDialogItems
         );
         this.nameChanged();
      }
   },

   created() {
      this.init();
      this.settings.saveLastPageViewed('groups');
      if (this.$route.query.search)
         this.search = this.$route.query.search;
   }
}
</script>

<style scoped>
.v-data-table-header {
   color: red;background: blue;
}
</style>