<template>
   <!-- 
      WHAT: A component to set Prefilters for BtDashboardDesigner component.
      WHO: Behzad Talebpour (btalebpour@mindfireinc.com)
      HISTORY:
      03/22/22(B0.4): Added @change to the textarea as the manual changes were not saved in the chart options.
      03/10/22(B0.3): Added $forcedUpdate in the watch for value as textarea wasn't updated.
      12/02/21(B0.2): In editPrefilters(), handled string version of pfJson[dim] as well.
      12/02/21(B0.1): 1st release.
   -->
<div>
   <v-textarea clearable dense outlined
      append-icon="edit"
      :auto-grow="autoGrow"
      :class="myClass"
      :hide-details="false"
      :label="label"
      :read-only="readOnly"
      :rows="rows"
      :rules="[rules.validJson]"
      v-model="serializedPrefilters"
      @click:append="editPrefilters"
      @click:clear="clearPrefilters"
      @change="serializedPrefiltersChanged"
   ></v-textarea>

   <v-dialog no-click-animation persistent scrollable
      max-width="980px"
      v-model="dialog"
   >
      <v-card flat>
         <v-card-title class="title grey--text darken-4 font-weight-bold">Prefilters</v-card-title>

         <v-card-text class="px-4 pb-2">
            <v-form lazy-validation
               ref="form"
               v-model="isFormValid"
            >
               <v-row v-for="(item,i) in prefilters" :key="i">
                  <v-col xs="12" sm="4" md="4" lg="4" class="pt-0 pb-2 pl-4">
                     <v-select dense hide-selected persistent-hint required
                        autocomplete="off"
                        class="pt-1 mt-0"
                        hint="Dimension"
                        :items="getDimensionItems(i)"
                        v-model="item.dimension"
                        @change="dimensionChanged(item)"
                     >
                        <template v-slot:prepend>
                           <v-icon
                              class="py-2"
                              :color="removeIconColor"
                              :disabled="!item.dimension"
                              @click="removeItem(i)"
                           >{{removeIconName}}</v-icon>
                        </template>
                     </v-select>
                  </v-col>
                  <v-col v-if="item.dimension"
                     xs="12" sm="8" md="8" lg="8" class="pt-0 pb-2">
                     <v-autocomplete deletable-chips dense multiple required persistent-hint small-chips
                        autocomplete="off"
                        class="pb-0 mb-0"
                        :counter="item.valueItems.length"
                        hint="Value(s)"
                        :items="item.valueItems"
                        :rules="[rules.requiredValues]"
                        v-model="item.values"
                        @change="valuesChanged"
                     ></v-autocomplete>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>

         <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn text small
               class="pr-0"
               color="blue darken-1"
               @click="cancelClicked"
            >Cancel</v-btn>
            <v-btn text small
               color="blue darken-1"
               :disabled="isSaveDisabled"
               @click="saveClicked"
            >Save
            </v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</div>
</template>

<script>
const NAME = 'BtPrefilters';

export default {
   name: NAME,

   props: {
      value: {
         type: String,
         default: ''
      },
      autoGrow: {
         type: Boolean,
         default: false
      },
      myClass: {
         type: String,
         default: "pt-4"
      },
      debug: {
         type: Boolean,
         default: false
      },
      label: {
         type: String,
         default: 'Prefilters in JSON Format'
      },
      readOnly: {
         type: Boolean,
         default: false
      },
      removeIconColor: {
         type: String,
         default: "grey darken-1"
      },
      removeIconName: {
         type: String,
         default: "delete_forever"
      },
      required: {
         type: Boolean,
         default: false
      },
      rows: {
         type: Number,
         default: 2
      },
      shouldInit: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         rules: {
            // required: value => !!value || "Value is required!",
            requiredValues: values => values.length > 0 || "At least one value is required!",
            validJson: value => {
               try {
                  if (value) {
                     if (value.trim().indexOf('"') === 0)
                        return 'SyntaxError: Unexpected token in JSON at position 0';
                     JSON.parse(value);
                  }
                  return true;
               } catch (error) {
                  return error.toString();
               }
            },
         },
         dialog: false,
         isFormValid: false,
         isFormChanged: false,
         prefilters: [],
         prefiltersMax: 0,
         dimensions: [
            { text: 'Service Type ID', value: "serviceType_ID" },
            { text: 'Event ID', value: "event_ID" }
         ],
         // serviceTypeDim: 'serviceType_ID',
         // serviceTypes: [],
         // eventDim: 'event_ID',
         // events: [],
         // eventItems: []
      }
   },

   computed: {
      isSaveDisabled() {
         return (!this.isFormValid || !this.isFormChanged);
      },
   },

   watch: {
      value: {
         immediate: true,
         // deep: true,
         handler(val) {
            // alert(`in watch.value(): val=${val}`);
            this.serializedPrefilters = val;
            this.$forceUpdate();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} B0.4 says => ${msg}`);
      },

      serializedPrefiltersChanged(val) {
         // alert('in serializedPrefiltersChanged(): val=' + val);
         this.$emit('input', val);
      },

      editPrefilters() {
         this.prefilters = [];
         const pfJson = this.serializedPrefilters ? JSON.parse(this.serializedPrefilters) : {};
         Object.keys(pfJson).forEach(dim => {
            //TODO: needs attention when new dimensions are added
            const pfValues = [];
            if (Array.isArray(pfJson[dim]))
               pfJson[dim].forEach(val => {
                  pfValues.push(parseInt(val));
               });
            else
               pfValues.push(parseInt(pfJson[dim]));

            this.prefilters.push({ dimension: dim, values: pfValues, valueItems: this.getValueItems(dim) });
         });
         this.addItem();
         this.isFormChanged = false;
         this.dialog = true;
      },

      clearPrefilters() {
         this.$emit('input', '');
      },

      getDimensionItems(ind) {
         const remainingDims = [];
         this.dimensions.forEach(dim => {
            if (dim.value === this.prefilters[ind].dimension ||
               this.prefilters.filter(f => f.dimension === dim.value).length === 0)
               remainingDims.push(dim);
         });

         return remainingDims;
      },

      getValueItems(dimension) {
         switch (dimension) {
            case 'serviceType_ID':
               return [
                  { text: 'Cloud Connect (2019)', value: 2019 },
                  { text: 'Data Processor (2023)', value: 2023 },
                  { text: 'DirectMail (205)', value: 205 },
                  { text: 'Email (201)', value: 201 },
                  { text: 'HP SmartStream (2009)', value: 2009 },
                  { text: 'Mailgun (2202)', value: 2202 },
                  { text: 'Microsite (305)', value: 305 },
                  { text: 'PurlDP (2028)', value:  2028},
                  { text: 'Raw Data Extract (3000)', value: 3000 },
                  { text: 'SendGrid (2030)', value: 2030 },
                  { text: 'SMS (202)', value: 202 },
                  { text: 'SMS (Text) (2018)', value: 2018 },
                  { text: 'Text2Voice (2016)', value: 2016 },
                  { text: 'Zapier (BT) (2026)', value: 2026 },
                  // { text: 'Enrich Contact', value: 2021 },
                  // { text: 'Facebook', value: 2020 },
                  // { text: 'LinkedIn', value: 2017 },
                  // { text: 'Mail Merge', value: 2024 },
                  // { text: 'Mandrill', value: 2201 },
                  // { text: 'SMS (AWS)', value: 2022 }
                  // { text: 'Twitter', value: 2204 }
               ];
            case 'event_ID':
               return [
                  { text: '----- Cloud Connect Events:', value: 2019, disabled: true },
                  // { text: 'Failed (201902)', value: 201902 },
                  // { text: 'InvalidData (201903)', value: 201903 },
                  { text: 'Http Success (201910)', value: 201910 },
                  { text: 'Http Fail (201911)', value: 201911 },
                  { text: '201912', value: 201912 },
                  { text: '201913', value: 201913 },
                  { text: '201914', value: 201914 },
                  { text: '201915', value: 201915 },
                  { text: '201916', value: 201916 },
                  { text: '201917', value: 201917 },
                  { text: '201918', value: 201918 },
                  { text: '201919', value: 201919 },
                  // { text: '----- Data Processor Events:', value: 2023, disabled: true },
                  // { text: 'Failed (202302)', value: 202302 },
                  // { text: 'InvalidData (202303)', value: 202303 },
                  { text: '----- DirectMail Events:', value: 205, disabled: true },
                  { text: 'Direct Mail IMB scanned (20502)', value: 20502 },
                  { text: 'Direct Mail returned (20503)', value: 20503 },
                  { text: 'Contact scanned a QR code (20504)', value: 20504 },
                  { text: 'Record Failed (20507)', value: 20507 },
                  { text: 'Record Succeeded (20508)', value: 20508 },
                  { text: '----- Email Events:', value: 201, disabled: true },
                  { text: 'Email sent to Contact (20102)', value: 20102 },
                  { text: 'Contact opened Email (20103)', value: 20103 },
                  { text: 'Contact opted-out of Email (20104)', value: 20104 },
                  { text: 'Contact clicked a link in Email (20105)', value: 20105 },
                  { text: 'Contact marked Email as spam (20106)', value: 20106 },
                  { text: 'Email soft bounced (20107)', value: 20107 },
                  { text: 'Email hard bounced (20108)', value: 20108 },
                  { text: 'Email failed to process (20109)', value: 20109 },
                  { text: 'Suppressed (20110)', value: 20110 },
                  // { text: '----- HP SmartStream Events:', value: 2009, disabled: true },
                  // { text: 'Failed (200902)', value: 200902 },
                  // { text: 'InvalidData (200903)', value: 200903 },
                  { text: '----- Mailgun Events:', value: 2202, disabled: true },
                  // { text: 'Failed (220202)', value: 220202 },
                  // { text: 'InvalidData (220203)', value: 220203 },
                  { text: 'Email failed to process (220210)', value: 220210 },
                  { text: 'Email queued for processing (220211)', value: 220211 },
                  { text: 'Email sent to Contact (220212)', value: 220212 },
                  { text: 'Contact opened Email (220213)', value: 220213 },
                  { text: 'Contact clicked a link in Email (220214)', value: 220214 },
                  { text: 'Contact opted-out of Email (220215)', value: 220215 },
                  { text: 'Contact marked Email as spam (220216)', value: 220216 },
                  { text: 'Email soft bounced (220217)', value: 220217 },
                  { text: 'Email hard bounced (220218)', value: 220218 },
                  { text: 'Suppressed (220219)', value: 220219 },
                  { text: '----- Microsite Events:', value: 305, disabled: true },
                  { text: 'Contact visited Microsite (30500)', value: 30500 },
                  { text: 'Contact Visited Page (30501)', value: 30501 },
                  { text: 'Contact Submitted Page (30502)', value: 30502 },
                  { text: 'Contact clicked an outside link (30503)', value: 30503 },
                  { text: 'New Contact added to database (30504)', value: 30504 },
                  { text: 'Contact provided form data (30505)', value: 30505 },
                  { text: 'Contact added a new Contact (30506)', value: 30506 },
                  { text: 'Contact failed to add new Contact (30507)', value: 30507 },
                  { text: 'Contact logged in successfully (30508)', value: 30508 },
                  { text: 'Contact failed to log in (30509)', value: 30509 },
                  { text: 'Contact used promo code successfully (30510)', value: 30510 },
                  { text: 'Contact used promo code unsuccessfully (30511)', value: 30511 },
                  // { text: '----- PurlDP Events:', value: 2028, disabled: true },
                  // { text: 'Failed (202802)', value: 202802 },
                  // { text: 'InvalidData (202803)', value: 202803 },
                  // { text: '----- Raw Data Extract Events:', value: 3000, disabled: true },
                  // { text: 'Failed (300002)', value: 300002 },
                  { text: '----- SendGrid Events:', value: 2030, disabled: true },
                  // { text: 'Failed (203002)', value: 203002 },
                  // { text: 'InvalidData (203003)', value: 203003 },
                  { text: 'Email failed to process (203010)', value: 203010 },
                  { text: 'Email queued for processing (203011)', value: 203011 },
                  { text: 'Email sent to contact (203012)', value: 203012 },
                  { text: 'Contact opened Email (203013)', value: 203013 },
                  { text: 'Contact clicked a link in Email (203014)', value: 203014 },
                  { text: 'Contact opted-out of Email (203015)', value: 203015 },
                  { text: 'Contact marked Email as spam (203016)', value: 203016 },
                  { text: 'Email soft bounced (203017)', value: 203017 },
                  { text: 'Email hard bounced (203018)', value: 203018 },
                  { text: 'Email not sent due to suppression list (203019)', value: 203019 },
                  { text: '----- SMS Events:', value: 202, disabled: true },
                  { text: 'SMS delivered to Provider (20202)', value: 20202 },
                  { text: 'SMS sent to Contact (20203)', value: 20203 },
                  { text: 'SMS failed to process (20204)', value: 20204 },
                  { text: 'Contact clicked a link in SMS (20205)', value: 20205 },
                  { text: 'Contact opted-out of SMS (20206)', value: 20206 },
                  { text: '----- SMS (Text) Events:', value: 2018, disabled: true },
                  // { text: 'Failed (201802)', value: 201802 },
                  // { text: 'InvalidData (201803)', value: 201803 },
                  { text: 'Queued for Delivery (201810)', value: 201810 },
                  { text: 'Delivered to the Contact (201811)', value: 201811 },
                  { text: 'Rejected (Undeliverable) (201812)', value: 201812 },
                  { text: 'Opted-Out (Unsubscribed) (201813)', value: 201813 },
                  { text: 'Contact Replied to the Message (201814)', value: 201814 },
                  { text: 'Invalid Record (201815)', value: 201815 },
                  { text: 'Link Click (201816)', value: 201816 },
                  { text: '----- Text2Voice Events:', value: 2016, disabled: true },
                  // { text: 'Failed (201602)', value: 201602 },
                  // { text: 'InvalidData (201603)', value: 201603 },
                  { text: 'Delivered (201610)', value: 201610 },
                  { text: 'CallFailed (201611)', value: 201611 },
                  { text: '----- Zapier (BT) Events:', value: 2026, disabled: true },
                  // { text: 'Failed (202602)', value: 202602 },
                  // { text: 'InvalidData (202603)', value: 202603 },
                  { text: 'Record Submitted to Zapier (202612)', value: 202612 },
                  { text: 'Record Rejected (202613)', value: 202613 },
                  { text: 'Contact was Added in Studio (202614)', value: 202614 },
                  { text: 'Failed to Add Contact (202615)', value: 202615 },
                  { text: 'Contact was Updated in Studio (202616)', value: 202616 },
                  { text: 'Failed to Update Contact (202617)', value: 202617 },
                  { text: '202620 (202620)', value: 202620 },
                  { text: '202621 (202621)', value: 202621 },
                  { text: '202622 (202622)', value: 202622 },
                  { text: '202623 (202623)', value: 202623 },
                  { text: '202624 (202624)', value: 202624 },
                  { text: '202625 (202625)', value: 202625 },
                  { text: '202626 (202626)', value: 202626 },
                  { text: '202627 (202627)', value: 202627 },
                  { text: '202628 (202628)', value: 202628 },
                  { text: '202629 (202629)', value: 202629 }
               ]
            default:
               return [];
         }
      },

      dimensionChanged(item) {
         this.isFormChanged = true;
         item.values = [];
         item.valueItems = this.getValueItems(item.dimension);
         this.addItem();
      },

      valuesChanged() {
         this.isFormChanged = true;
      },

      addItem() {
         const len = this.prefilters.length;
         if (len === 0 || (this.prefilters[len - 1].dimension && len < this.prefiltersMax)) {
            this.prefilters.push({
               dimension: '',
               values: []
            });
         }
      },

      removeItem(ind) {
         if (confirm('Are you sure?')) {
            this.prefilters.splice(ind, 1);
            this.addItem();
            this.isFormChanged = true;
         }
      },

      // resetClicked() { },

      cancelClicked() { 
         this.dialog = false;
      },

      saveClicked() {
         if (this.$refs.form.validate()) {
            const prefilterOut = {};
            const actualFilters = this.prefilters[this.prefilters.length - 1].dimension 
               ? this.prefilters : this.prefilters.slice(0, this.prefilters.length - 1);

            // alert(JSON.stringify(actualFilters))

            actualFilters.forEach(filter => {
               prefilterOut[filter.dimension] = filter.values;
            });
            this.serializedPrefilters = JSON.stringify(prefilterOut);
            // alert('serializedPrefilters=' + this.serializedPrefilters);
            this.$emit('input', this.serializedPrefilters);
            this.cancelClicked();
         }
      }
   },

   created() {
      // alert('in created()');
      // this.prefiltersMax = Math.min(this.dimensions.length, this.max);   //for after extension
      this.prefiltersMax = this.dimensions.length;
   },

   mounted() {
      // alert('in mounted(): isFormValid=' + this.$refs.form.validate());
   }
}
</script>

// <style lang="scss" scoped>
//    .v-autocomplete__content .v-list__tile .v-list-item__content {
//       height: 10%;
//    }
// </style>