<template>
   <!-- TODO:
   - Add RESET btn (raise an event?)
   -->
   <!-- HISTORY:
      03/19/21(B0.3): Added fieldDisabled prop + Removed save link and extra/commented codes.
      12/07/20(B0.2): Merged BtDynamicTextField and BtDynamicTextField2 into BtDynamicTextField + Added fieldMax prop.
      12/07/20(B0.1): Was submitted to Aref.
   -->
   <v-form lazy-validation
      ref="form"
      v-model="isFormValid"
   >

      <v-layout v-for="(item, i) in fieldValues" :key="i">
         <v-col xs="12" sm="12" md="12" class="py-0">
            <v-autocomplete v-if="validFields.length"
               v-model="fieldValues[i]"
               :dense="fieldDense"
               :disabled="fieldDisabled"
               :label="`${fieldLabel ? fieldLabel + ' ' + (i+1) + ':' : ''}`"
               :outlined="fieldOutlined"
               :placeholder="`${fieldPlaceholder ? fieldPlaceholder + ' ' + (i+1) : ''}`"
               :items="getFieldItems(i)"
               :rules="getFieldRules(i)"
               @change="addField"
            >
               <template v-slot:prepend>
                  <v-icon
                     class="py-2"
                     :disabled="!item"
                     @click="removeField(i)"
                  >{{removeIconName}}</v-icon>
               </template>
            </v-autocomplete>

            <v-text-field v-else
               v-model="fieldValues[i]"
               :dense="fieldDense"
               :disabled="fieldDisabled"
               :label="`${fieldLabel ? fieldLabel + ' ' + (i+1) + ':' : ''}`"
               :outlined="fieldOutlined"
               :placeholder="`${fieldPlaceholder ? fieldPlaceholder + ' ' + (i+1) : ''}`"
               :rules="getFieldRules(i)"
               @keyup="addField"
            >
               <template v-slot:prepend>
                  <v-icon
                     class="py-2"
                     :disabled="!item"
                     @click="removeField(i)"
                  >{{removeIconName}}</v-icon>
               </template>
            </v-text-field>
         </v-col>
      </v-layout>

   </v-form>
</template>

<script>
import { isIncluded } from '../mixins/bt-mixin.js';

const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
   name: "BtDynamicTextField",

   props: {
      value: {
         type: Array,
         default: () => []
      },
      debug: {
         type: Boolean,
         default: false
      },
      fieldDense: {
         type: Boolean,
         default: true
      },
      fieldDisabled: {
         type: Boolean,
         default: false
      },
      fieldLabel: {
         type: String,
         default: ""
      },
      fieldMax: {
         type: Number
      },
      fieldOutlined: {
         type: Boolean,
         default: false
      },
      fieldPlaceholder: {
         type: String,
         default: ""
      },
      fieldRequired: {
         type: Boolean,
         default: false
      },
      fieldType: {
         type: String,
         default: "string",
         validator: value => { return ['string', 'email'].indexOf(value.toLowerCase()) !== -1 }
      },
      fieldValidation: {
         type: String,
         default: ""
      },
      validFields: {
         type: Array,
         default: () => []
      },
      removeIconName: {
         type: String,
         default: "delete_forever"
      },
      removeIconColor: {
         type: String,
         default: "grey darken-1"
      },
      // sortFieldItems: {
      //    type: Boolean,
      //    default: true
      // },
      shouldInit: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         rules: {
            required: value => !!value || "Value is required!",
            duplicate: value => this.fieldValues.filter(fv => fv.toLowerCase() === value.toLowerCase()).length <= 1 || 
               'Value is duplicate!',
            validField: value => this.myValidFields.includes(value.toLowerCase()) || "Value is not allowed!",
            email: value => !value || EMAIL_PATTERN.test(value) || "Value is invalid!",
            custom: value => this.fieldValidation.test(value) || "Value is invalid!",
         },
         myValidFields: [],
         isFormValid: !this.fieldRequired,
         fieldValues: [],
         isSaved: !this.fieldRequired,
      };
   },

   computed: {
   },

   watch: {
      shouldInit: {
         immediate: true,
         handler(val) {
            // alert('in shouldInit: value=' + this.shouldInit);
            if (!val) return;

            //moved from created()
            if (this.validFields)
               this.myValidFields = this.validFields.map(vf => vf.toLowerCase());
            else this.myValidFields = [];

            if (this.fieldMax)
               this.myFieldMax = Math.min(this.myValidFields.length, this.fieldMax) || this.fieldMax;
            else
               this.myFieldMax = this.myValidFields.length || Number.MAX_VALUE;

            if (this.validFields.length > 0)
               this.fieldValues = this.value.filter(v => isIncluded(this.validFields, v));
            else
               this.fieldValues = [...this.value];

            this.addField();

            this.log('in shouldInit(): myFieldMax=' + this.myFieldMax);
         }
      },

      fieldValues: {
         immediate: true,
         deep: true,
         handler() {
            ////this.isSaved = false;  //because of new approach
            // if (this.$refs['form']) {
            //    this.isSaved = this.$refs.form.validate();
            //    if (this.isSaved) {
            //       // const val = this.fieldValues;
            //       const outFields = val.length === 0 || val[val.length - 1] ? val : val.slice(0, val.length - 1);
            //       this.$emit('input', outFields);
            //    }
            // }
            this.fieldValuesChanged();
         }
      },

      isSaved: {
         immediate: true,
         handler(val) {
            this.$emit('form-validation', val);
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----BtDynamicTextField B0.3 says => ${msg}`);
      },

      fieldValuesChanged() {
         if (this.$refs['form']) {
            this.isSaved = this.$refs.form.validate();
            if (this.isSaved) {
               const val = this.fieldValues;
               const outFields = val.length === 0 || val[val.length - 1] ? val : val.slice(0, val.length - 1);
               this.$emit('input', outFields);
            }
         }
      },

      getFieldItems(index) {
         var remainingFields = this.validFields.filter((item) => !this.fieldValues.includes(item));
         if (this.fieldValues[index])
            remainingFields.push(this.fieldValues[index]);
         // alert(index + ' => ' + 'validFields=' + JSON.stringify(this.validFields) +
         //    '\nfieldValues=' + JSON.stringify(this.fieldValues) + 
         //    '\nremainingFields=' + JSON.stringify(remainingFields));

         return remainingFields.sort();
      },

      getFieldRules(index) {
         const fieldRules = [];
         if (index < this.fieldValues.length - 1 || (this.fieldRequired && index === 0)) {
            fieldRules.push(this.rules.required);
            fieldRules.push(this.rules.duplicate);
            if (this.myValidFields.length > 0)
               fieldRules.push(this.rules.validField);
         }
         if (this.fieldValidation)
            fieldRules.push(this.rules.custom);
         else if (this.fieldType.toLowerCase() === 'email')
            fieldRules.push(this.rules.email);

         return fieldRules;
      },

      addField() {
         const len = this.fieldValues.length;
         if (len === 0 || (this.fieldValues[len - 1] && len < this.myFieldMax))
            this.fieldValues.push('');
      },

      removeField(index) {
         if (confirm('Are you sure?')) {
            this.fieldValues.splice(index, 1);
            this.addField();
         }
      }
   },

   created() {
   },

   mounted() {
      this.fieldValuesChanged();
      // if (this.$refs['form']) {
      //    this.isSaved = this.$refs.form.validate();
      //    if (this.isSaved) {
      //       const val = this.fieldValues;
      //       const outFields = val.length === 0 || val[val.length - 1] ? val : val.slice(0, val.length - 1);
      //       this.$emit('input', outFields);
      //    }
      // }
   }
}
</script>