<template>
    <v-container style="height: 100%; width: 100%;background: white;">
        <v-file-input  hide-details class="pt-1 shrink" v-model="localFileInput" label="Zip File" prepend-icon="upload_file"
            @change="loadFromLocal">
        </v-file-input>
        {{data}}
        <script type="application/javascript" defer src="zip.min.js"></script>
    </v-container>
    
</template>
<script>

//import { parse } from "csv-parse/sync";
export default {
    name: "Kwang-TestZip",
    components: {
    },
    props: {
    },
    data() {
        return {
            localFileInput: undefined,
            zip: undefined,
            data: undefined,
        };
    },
    computed: {
    
    },
    methods: {
        async loadFromLocal() {
            if (this.localFileInput) {
                if(this.zip == undefined){
                    // eslint-disable-next-line
                    this.zip = zip;
                }
                if(this.localFileInput.name.toLowerCase().endsWith(".zip")){
                    console.log("Zipfile found");
                    let info = {};
                    this.data = await this.unZippingDataBlobWithLimit(this.localFileInput,info);
                    console.log(info);
                    this.data = this.data.substring(0,1024*5);
                }
            }
        },
        async unZippingDataBlobWithLimit(blob,info){
            var stringResult = "";
            const writer = {        // custom text writer to keep ongoin result before abort 
                textDecoder: new TextDecoder(),
                writable: new WritableStream({
                    write(chunk) {
                        stringResult += writer.textDecoder.decode(chunk)
                        //console.log("writer",str.length,str);
                    },
                    close() {
                        //writerClosed = true;
                    }
                })
            };

            //configure({ chunkSize: 10000, useWebWorkers: false });
            //const zipFileReader = new BlobReader(blob);
            const zipFileReader = new this.zip.BlobReader(blob);
            const zipReader = new this.zip.ZipReader(zipFileReader);
            const firstEntry = (await zipReader.getEntries()).shift();
            // create controller and signal for aboring
            const controller = new AbortController();
            const signal = controller.signal;
            try{
            var ret = await firstEntry.getData(writer,{
                onstart: (total)=>{
                },
                onprogress: (progress, total)=>{
                    console.log("unZippingDataBlobWithLimit","onprogress",`${progress}/${total}`);
                    if(info){
                        info.totalSize = total;
                    }
                    controller.abort();
                },
                onend: (computedSize)=>{
                },
                signal,
            });
            }catch(ex){
                // will have error here from abort just ignore it
                //console.log(ex);
            }
            await zipReader.close();
            //console.log(stringResult);
            if(info){
                info.sampleSize = stringResult.length;
            }
            return stringResult;
        },        
    },
    created() {
    },
    destroyed() {
    },
    mounted() {
    },
    beforeUnmount() { 

    },
    watch: {
    },
};
</script>