import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import moment from 'moment'
import vuetify from './plugins/vuetify'
import * as VueGoogleMaps from "vue2-google-maps";
import i18n from "./i18n";
import VueSweetalert2 from 'vue-sweetalert2';
//import MyPlugin from 'my-plugin/src/plugin.js';


Vue.config.productionTip = false;

//console.log('process.env.VUE_APP_GOOGLE_API_KEY:', process.env.VUE_APP_GOOGLE_API_KEY);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: "places,drawing,visualization" // necessary for places input
  }
});

import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
//Vue.use(MyPlugin);

import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';
Vue.use(Croppa);

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm')
  }
})

Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] =  token;
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  beforeCreate() {
		this.$store.commit('initialiseStore');
	},
  render: h => h(App)
}).$mount('#app')

