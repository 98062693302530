<template>
    <v-container class="pass-builder" :class="cardTypeClass" :style=mainStyle>
        <v-form v-on:submit.prevent :disabled=readOnlyMode>
        <v-row v-if="applResouce && viewMode == false" class="mt-0 mb-1" style="width:600px;">
            <v-col class="pa-0" cols="7">
                <v-text-field width="300px" clearable hide-details v-model="applResouce.templateData.templateName" label="Name">
                </v-text-field>
            </v-col>
            <v-col v-if="debugMode" cols="5" class="pt-3">
                <v-btn @click="loadClick()">Load</v-btn>
                <v-btn @click="saveClick()">Save</v-btn>
                <v-btn @click="newClick()">New</v-btn>
                <!-- {{currentPassID}} - {{currentPass}} - {{activeObject}} -->
            </v-col>
            <v-col v-else>
                <v-btn :disabled=readOnlyMode  @click="loadClick()">Load</v-btn>
                <!-- <v-btn id="menu" @click="menu = !menu">Load From</v-btn>
                <KCSearchableSelect @select="onLoadFrom" attachID="menu" :height=200 v-model="menu" :listData=headers></KCSearchableSelect> -->
            </v-col>
        </v-row>
        <v-row class="mt-2 pb-6" v-if="isAddThisPassMode == true && debugMode==false">
            <!-- <v-form :disabled=readOnlyMode ref="selectForm" v-model="isFormValid"> -->
            <!-- {{applResouce.templateData.purlData}} -->
            <v-col class="pa-0" cols="3">
                <v-form  ref="qrCodeForm" :disabled=readOnlyMode v-model="isFormValid">
                <v-text-field width="300px" :rules=qrcodeColumnRule clearable hide-details v-model="applResouce.templateData.qrcodeColumn" label="QRCodeColumn">
                </v-text-field>
                </v-form>
            </v-col>
            <!-- <KCComputedInput :readOnlyMode="! (applResouce.templateData.purlData.columnName !='_id' || applResouce.templateData.purlData.chkCreateNewColumn ==true)" ref="purlInput" v-model=applResouce.templateData.purlData :needName=false label="Purl" :headers=headers :sampleData=sampleData></KCComputedInput>         -->
            <KCComputedInput ref="messageInput" v-model=applResouce.templateData.messageData :needName=false label="Message" :headers=headers :sampleData=sampleData></KCComputedInput>
            <KCComputedInput ref="linkInput" v-model=applResouce.templateData.linkData :needName=false label="Link" :headers=headers :sampleData=sampleData></KCComputedInput>
            <!-- </v-form> -->
        </v-row>
        <v-row class="mt-2 pb-6" v-if="isMindFireMode == true && debugMode==false">
            <v-col class="pa-0" cols="3">
                <v-form  ref="qrCodeForm" :disabled=readOnlyMode v-model="isFormValid">
                <v-text-field width="300px" :rules=qrcodeColumnRule clearable hide-details v-model="applResouce.templateData.qrcodeColumn" label="QRCodeColumn">
                </v-text-field>
                </v-form>
            </v-col>
        </v-row>        
        <v-row v-if="applResouce" class="mt-1 card-content">
            <div class="mr-3 pass-viewer"
                style="width:330px;position: relative;    transform-style: preserve-3d; perspective: 1000px;">
                <div v-if="cardLoading" class="px-3"><v-progress-linear 
                    indeterminate
                    height="3"
                ></v-progress-linear></div>
                <div v-show="cardLoading==false">
                <div class="pass-background-image-container" v-if="applResouce.chkBackgroundImage">
                    <div :style="passStyle" class="pass-background-image"></div>
                </div>
                <Transition mode="out-in">
                    <div v-if="showingFront" key="passFront" :style="passColorStyle" class="appl-pass pass-front">
                        <div class="pass-header">
                            <div @click="objClick('logo',null,$event)" :class="[activeObject == 'logo'? 'active':'']"
                                class="pass-logo clickable" :style="logoStyle"
                                style="color: transparent; display: inline-table; height: 42px;">
                                <img ref="logoImage" :style=logoStyle :src="applResouce.logo" style="height: 42px;">
                            </div>
                            <div @click="objClick('logoText',null,$event)"
                                :class="[activeObject == 'logoText'? 'active':'']" class="pass-logoText clickable"
                                :style="logoTextStyle" style="display: inline-block; height: 42px;width:100%;">
                                <div style="padding:10px;">{{applPassData.logoText}}</div>
                            </div>
                            <div  v-if="isMindFireMode == false" class="pass-headerFields">
                                <div @click="objClick('headerFields',i,$event)"
                                    :class="[`${activeObject}-${activeObjectIndex}` == `headerFields-${i}`? 'active':'']"
                                    class="clickable" v-for="(field,i ) in applPassData.coupon.headerFields" :key="i"
                                    style="display: inline-block; padding-top:5px;height: 42px;">
                                    <div v-if="field.label || field.value">
                                        <div class="pass-label label-color" style="text-align: right;">{{field.label}}
                                        </div>
                                        <div class="pass-value value-color" :style="headerStyle()"
                                            style="text-align: right;">{{field.value}}</div>
                                    </div>
                                    <div v-else class="pass-field-empty">
                                        Header
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="cardTypeClass.includes('coupon')">
                            <div @click="objClick('primary',null,$event)"
                                :class="[activeObject == 'primary'? 'active':'']" class="pass-stripSection clickable"
                                style="height: 123px; margin-left: -16px; margin-right: -16px;" :style="applStripImage">
                                <div class="pass-primary">
                                    <div
                                        v-if="applPassData.coupon.primaryFields[0].value || applPassData.coupon.primaryFields[0].label">
                                        <div :style="primaryStyle()" class="pass-primary-value value-color">
                                            {{applPassData.coupon.primaryFields[0].value}}</div>
                                        <div class="pass-primary-label value-color">
                                            {{applPassData.coupon.primaryFields[0].label}}</div>
                                    </div>
                                    <div v-else class="pass-field-empty">
                                        Primary
                                    </div>
                                </div>
                            </div>
                            <div class="pass-secondary" style="display: flex;justify-content: space-between;">
                                <div class="clickable" @click="objClick('morefield',i,$event)"
                                    :class="[`${activeObject}-${activeObjectIndex}` == `morefield-${i}`? 'active':'']"
                                    v-for="(field,i ) in moreFields" :key="i">
                                    <div v-if="field.label || field.value" :class="[i==2? 'text-left':'']">
                                        <div class="pass-label label-color">{{field.label}}</div>
                                        <div :style="valueStyle(moreFields)" class="pass-value value-color">
                                            {{field.value}}</div>
                                    </div>
                                    <div v-else class="pass-field-empty">
                                        Secondary
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="isMindFireMode">
                            <div @click="objClick('primary',null,$event)"
                                :class="[activeObject == 'primary'? 'active':'']" class="pass-stripSection clickable"
                                style="height: 89px; margin-left: -16px; margin-right: -16px;" :style="applStripImage">
                                <div class="pass-primary">
                                    <div
                                        v-if="applPassData.coupon.primaryFields[0].value || applPassData.coupon.primaryFields[0].label">
                                        <div :style="primaryStyle()" class="pass-primary-value value-color">
                                            {{applPassData.coupon.primaryFields[0].value}}</div>
                                        <div class="pass-primary-label value-color">
                                            {{applPassData.coupon.primaryFields[0].label}}</div>
                                    </div>
                                    <div v-else class="pass-field-empty">
                                        Primary
                                    </div>
                                </div>
                            </div>
                            <div class="pass-secondary" style="display: flex;justify-content: space-between;">
                                <div @click="objClick('secondary',0,$event)" class="pt-1 clickable"
                                    :class="[`${activeObject}-${activeObjectIndex}` == `secondary-0`? 'active':'', 0%3 == 2? 'right':'']">
                                    <div v-if="applPassData.coupon.secondaryFields[0].label || applPassData.coupon.secondaryFields[0].value" :class="[0==2? 'text-left':'']">
                                        <div class="pass-label label-color mb-n1">{{applPassData.coupon.secondaryFields[0].label}}</div>
                                        <div class="pass-value value-color">{{applPassData.coupon.secondaryFields[0].value}}</div>
                                    </div>
                                    <div v-else class="pass-field-empty" :style="{opacity: 0==1?0:1}">
                                        Secondary
                                    </div>
                                </div>
                            </div>
                            <div class="pass-auxiliary pt-1">
                                    <div @click="objClick('auxiliary',i,$event)"
                                        :class="[`${activeObject}-${activeObjectIndex}` == `auxiliary-${i}`? 'active':'', i%3 == 2? 'right':'']"
                                        class="clickable" v-for="(field,i ) in applPassData.coupon.auxiliaryFields"
                                        :key="i">
                                        <div v-if="field.label || field.value">
                                            <div class="pass-label label-color mb-n1">{{field.label}}</div>
                                            <div class="pass-value value-color">{{field.value}}</div>
                                        </div>
                                        <div v-else class="pass-field-empty">
                                            Push message
                                        </div>
                                    </div>
                                </div>
                        </template>                        
                        <template v-if="isAddThisPassMode">
                            <div>
                                <div style="width: 70%;display: inline-block;height: 40px;">
                                    <div @click="objClick('primary',null,$event)"
                                        :class="[activeObject == 'primary'? 'active':'']" class="clickable  pt-2">
                                        <div class="pass-primary">
                                            <div
                                                v-if="applPassData.coupon.primaryFields[0].value || applPassData.coupon.primaryFields[0].label">
                                                <div class="pass-label label-color mb-n1">
                                                    {{applPassData.coupon.primaryFields[0].label}}</div>
                                                <div :style="primaryStyle()" class="pass-value value-color">
                                                    {{applPassData.coupon.primaryFields[0].value}}</div>
                                            </div>
                                            <div v-else class="pass-field-empty">
                                                Primary
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pass-secondary">
                                        <div @click="objClick('secondary',i,$event)" class="pt-1 clickable"
                                            :class="[`${activeObject}-${activeObjectIndex}` == `secondary-${i}`? 'active':'', i%3 == 2? 'right':'']"
                                            v-for="(field,i ) in applPassData.coupon.secondaryFields" :key="i">
                                            <div v-if="field.label || field.value" :class="[i==2? 'text-left':'']">
                                                <div class="pass-label label-color mb-n1">{{field.label}}</div>
                                                <div class="pass-value value-color">{{field.value}}</div>
                                            </div>
                                            <div v-else class="pass-field-empty" :style="{opacity: i==1?0:1}">
                                                Secondary
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div @click="objClick('thumbnail',null,$event)"
                                    :class="[activeObject == 'thumbnail'? 'active':'']" class="pass-thumbnail clickable"
                                    :style="thumbnailStyle"
                                    style="color: transparent; display: inline-block; height: 42px;float:right;">
                                    <!-- <img height="40"  :src="applResouce.logo"> -->
                                    <template v-if="applResouce.chkThumbnail">
                                        <img :src="applResouce.thumbnail" height=42 />
                                    </template>
                                    <template v-else>
                                        <div class="pass-field-empty" style="color: black;font-size: 9px;">
                                            Thumbnail
                                        </div>
                                    </template>
                                </div>
                                <div class="pass-auxiliary pt-1">
                                    <div @click="objClick('auxiliary',i,$event)"
                                        :class="[`${activeObject}-${activeObjectIndex}` == `auxiliary-${i}`? 'active':'', i%3 == 2? 'right':'']"
                                        class="clickable" v-for="(field,i ) in applPassData.coupon.auxiliaryFields"
                                        :key="i">
                                        <div v-if="field.label || field.value">
                                            <div class="pass-label label-color mb-n1">{{field.label}}</div>
                                            <div class="pass-value value-color">{{field.value}}</div>
                                        </div>
                                        <div v-else class="pass-field-empty">
                                            Auxiliary
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </template>


                        <div class="pass-barcode-section">
                            <div @click="objClick('barcode',null,$event)"
                                :class="[activeObject == 'barcode'? 'active':'']" class="pass-barcode clickable">
                                <div :style="{'background-color':applPassData.barcode.format!='NoBardcode'?'white':'transparent'}"
                                    style="padding: 8px;border-radius: 4px;background-color: rgb(255, 255, 255);">
                                    <div v-if="applPassData.barcode.format!='NoBardcode'">
                                        <div>
                                            <span style="color: transparent; display: flex; justify-content: center;">
                                                <img :src="barcodeImage" style="max-height: 100px;">
                                            </span>
                                        </div>
                                        <template v-if="isAddThisPassMode==false">
                                        <div v-if="applPassData.barcode.altText" class="pass-barcode-alt-text">
                                            {{applPassData.barcode.altText}}
                                        </div>
                                        </template>
                                    </div>
                                    <div v-else class="pass-field-empty"
                                        style="min-width: 380px;padding-top: 25px;min-height: 80px;padding-left: 100px;">
                                        Barcode
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pass-info">
                            <span @click="flipPass()" href="#" class="pass-infoButton" style="cursor:pointer;">ℹ</span>
                        </div>
                    </div>
                    <div v-if="showingFront==false" key="passBack" :style="passColorStyle" class="appl-pass pass-back">
                        <div class="pass-backSection pt-5">
                            <div class="pass-backFields">
                                <template v-if="(isAddThisPassMode == false)">
                                <div  @click="objClick('backFields',i,$event)"
                                    :class="[`${activeObject}-${activeObjectIndex}` == `backFields-${i}`? 'active':'']"
                                    class="clickable" v-for="(field,i ) in applPassData.coupon.backFields" :key="i">
                                    <div v-if="field.label || field.value">
                                        <div class="pass-label value-color">{{field.label}}</div>
                                        <div v-if="hasATag(field.value) == false" class="pass-value value-color">{{field.value}}</div>
                                        <div v-else v-html="field.value" class="pass-value value-color disable-a"></div>
                                    </div>
                                    <!-- <div v-else class="pass-field-empty">
                                        BackField
                                    </div> -->
                                </div>
                                </template>
                                <template v-if="(isAddThisPassMode == true)">
                                <div  @click="objClick('backFields-addThisPass',0,$event)"
                                    :class="[`${activeObject}` == `backFields-addThisPass`? 'active':'']"
                                    class="clickable add-this-pass">
                                    <div v-if="(applResouce.backFields.phone || applResouce.backFields.email)" class="pass-label label-color">CONTACT US:</div>
                                    <div ref="backContact" class="pass-value value-color">
                                        <span v-if="applResouce.backFields.phone">-P:<span
                                            class="href">{{applResouce.backFields.phone}}</span><br></span>
                                        <span v-if="applResouce.backFields.email">-E:<span
                                            class="href">{{applResouce.backFields.email}}</span></span>
                                    </div>                                            
                                    <div v-if="applResouce.backFields.address" class="pass-label label-color">LOCATE US:</div>
                                    <div v-if="applResouce.backFields.address" ref="backAddress" class="pass-value value-color">-A:<span
                                            class="href">{{applResouce.backFields.address}}</span><br>
                                            <span v-if="applResouce.backFields.surrounding">({{applResouce.backFields.surrounding}})</span>
                                    </div>
                                    <div v-if="(applResouce.backFields.website || applResouce.backFields.link1 || applResouce.backFields.link2)" class="pass-label label-color">VISIT US:</div>
                                    <div ref="backLinks" class="pass-value value-color">
                                        <div v-if="(applResouce.backFields.website && applResouce.backFields.website_name)">
                                            - <a :href="applResouce.backFields.website"
                                            target="_blank">{{applResouce.backFields.website_name}}</a><br>
                                        </div>
                                        <div v-if="(applResouce.backFields.link1 && applResouce.backFields.link1_name)">
                                        - <a :href="applResouce.backFields.link1"
                                            target="_blank">{{applResouce.backFields.link1_name}}</a><br>
                                        </div>
                                        <div v-if="(applResouce.backFields.link2 && applResouce.backFields.link2_name)">
                                        - <a :href="applResouce.backFields.link2"
                                            target="_blank">{{applResouce.backFields.link2_name}}</a><br>
                                        </div>
                                    </div>
                                    <div v-if="applResouce.backFields.more" class="pass-label label-color">MORE:</div>
                                    <div v-if="applResouce.backFields.more" ref="backMore" class="pass-value value-color">
                                        {{applResouce.backFields.more}}
                                    </div>

                                </div>
                                </template>
                            </div>
                        </div>
                        <div class="pass-header">
                            <div style="float:right;"><button @click="flipPass()"><span style="text-shadow: 1px 1px 1px white;">Done</span></button></div>
                        </div>
                    </div>
                </Transition>
                </div>
            </div>
            <Transition name="slide">
            <div v-if="activeObject!=null" class="mr-3">
                <div class="properties" style="position:relative;overflow: auto;">
                    <div v-if="activeObject==null" style="padding-top: 200px;padding-left: 10px;padding-right: 10px;">
                        <div class="center">
                            <p>Select a field on the pass to edit the settings of it.</p>
                        </div>
                    </div>
                    <div v-if="activeObject=='logo'">
                        <div class="prop-line prop-title">Logo</div>
                        <!-- <div class="prop-line">
                        <v-text-field v-model="applResouce.logo" label="Logo">
                        </v-text-field>
                    </div> -->
                        <div class="prop-line">
                            <div class="pa-5">
                                <v-img class="clickable" contain :width=logoWidth style="margin:auto;" @click="showCropUI('logo')" :src=applResouce.logo>
                                </v-img>
                            </div>
                            <div style="font-size: 0.9em;">
                                <p class="pa-1">Logos are a strict requirement for a wallet pass. You can upload a
                                    square or rectangular logo. The recommended dimensions for a rectangular logo are
                                    480 pixels wide by 150 pixels high. A square logo should be 150 pixels wide by 150
                                    pixels high.</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="activeObject=='thumbnail'">
                        <div class="prop-line prop-title">Thumbnail</div>
                        <div class="prop-line pb-0">
                            <div class="prop-line thumbnail-image-checkbox" style="padding: 0px 10px;">
                                <v-checkbox hide-details dense v-model="applResouce.chkThumbnail" label="Use thumbnail">
                                </v-checkbox>
                            </div>
                        </div>
                        <div v-if="applResouce.chkThumbnail" class="prop-line pt-0">
                            <div class="pa-5">
                                <v-img class="clickable" contain width="80%" style="margin:auto;" @click="showCropUI('thumbnail')"
                                    :src=applResouce.thumbnail></v-img>
                            </div>
                            <div style="font-size: 0.9em;">
                                <p class="pa-1">Thumbnail are a optional requirement for a wallet pass. You can upload a
                                    square or rectangular logo. The recommended dimensions for a rectangular logo are
                                    480 pixels wide by 150 pixels high. A square logo should be 150 pixels wide by 150
                                    pixels high.</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="activeObject=='logoText'">
                        <div class="prop-line prop-title">Logo Text</div>
                        <div class="prop-line">
                            <v-text-field counter :maxlength="this.maxTextLength('logo')" v-model="applPassData.logoText" label="Text">
                            </v-text-field>
                        </div>
                    </div>
                    <div v-if="activeObject=='headerFields'">
                        <KCPassFieldInput :hideKey=true maxLabel=15 v-model="applPassData.coupon.headerFields[activeObjectIndex]"
                            title="Header"></KCPassFieldInput>
                    </div>
                    <div v-if="activeObject=='primary'">
                        <KCPassFieldInput v-model="applPassData.coupon.primaryFields[0]" title="Primary" maxLabel=45
                            maxValue=45 :hideLabel="isAddThisPassMode" :hideKey=true></KCPassFieldInput>
                        <div class="prop-line strip-image-checkbox" style="padding: 0px 10px;">
                            <v-checkbox hide-details dense v-model="applResouce.chkStripImage" label="Use strip image">
                            </v-checkbox>
                        </div>
                        <div v-if="applResouce.chkStripImage" class="ma-4 strip-image-image"
                            style="border: solid 1px #c3bcbc;border-radius: 10px;">
                            <!-- <div class="prop-line">
                            <v-file-input hide-details class="pt-2 shrink" accept=".jpg, .png" v-model="localStripFileInput" label="Strip Image" prepend-icon="upload_file"
                                @change="stripeImageChange" ></v-file-input>
                        </div>     -->
                            <div class="prop-line">
                                <div>
                                    <v-img @click="showCropUI('stripImage')" :src=applResouce.stripImage></v-img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="activeObject=='morefield'">
                        <!-- <KCPassFieldInput v-model="applPassData.coupon.secondaryFields[activeObjectIndex]" title="Secondary"></KCPassFieldInput> -->
                        <KCPassFieldInput :addButton="moreFields.length<3?true:false" @addClick="addClick"
                            :maxLabel="maxLabel(activeObjectIndex)" maxValue=100 v-model="moreFields[activeObjectIndex]"
                            title="Secondary" :hideLabel="isAddThisPassMode" :hideKey=true></KCPassFieldInput>
                        <div v-if="moreFields.length > 1">
                            <v-icon @click="deleteMoreField(activeObjectIndex)"
                                style="position: absolute;bottom: 10px;right: 10px;cursor: pointer;">delete</v-icon>
                        </div>
                    </div>
                    <div v-if="activeObject=='auxiliary'">
                        <KCPassFieldInput v-model="applPassData.coupon.auxiliaryFields[activeObjectIndex]"
                            :title=auxiliaryHeader :maxValue="maxLabel(activeObjectIndex)" :hideKey=true></KCPassFieldInput>
                    </div>
                    <div v-if="activeObject=='secondary'">
                        <KCPassFieldInput v-model="applPassData.coupon.secondaryFields[activeObjectIndex]"
                        :hideLabel="isAddThisPassMode" :hideKey=true title="Secondary" :maxValue="maxLabel(activeObjectIndex)"></KCPassFieldInput>
                    </div>

                    <div v-if="activeObject=='barcode'">
                        <div class="prop-line prop-title">Barcode</div>
                        <div class="prop-line">
                            <v-text-field clearable v-model="applPassData.barcode.messageEncoding"
                                label="MessageEncoding">
                            </v-text-field>
                        </div>
                        <div class="prop-line">
                            <!-- <v-text-field v-model="applPassData.barcode.format" label="Format">
                        </v-text-field> -->
                            <v-select @change="barcodeChange" label="Format" :items="calcBarcodeTypes"
                                v-model="applPassData.barcode.format"></v-select>
                        </div>
                        <div class="prop-line">
                            <v-text-field clearable v-model="applPassData.barcode.message" label="Message">
                            </v-text-field>
                        </div>
                        <div v-if="isAddThisPassMode == false" class="prop-line">
                            <v-text-field clearable v-model="applPassData.barcode.altText" label="Text below barcode">
                            </v-text-field>
                        </div>
                    </div>
                    <div v-if="activeObject=='backFields'">
                        <div class="mb-3 prop-line prop-title">BackFields</div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense
                                v-model="applPassData.coupon.backFields[activeObjectIndex].label" label="Label">
                            </v-text-field>
                        </div>
                        <div class="prop-line" style="height:300px;padding-bottom:10px;">
                            <v-textarea rows=14 row-height=16 outlined dense
                                v-model="applPassData.coupon.backFields[activeObjectIndex].value" label="Value">
                            </v-textarea>
                        </div>
                        <!-- <div class="prop-line">
                            <v-text-field hide-details clearable dense
                                v-model="applPassData.coupon.backFields[activeObjectIndex].key" label="Key">
                            </v-text-field>
                        </div> -->
                    </div>
                    <div class="add-this-pass" v-if="activeObject=='backFields-addThisPass'">
                        <div class="mb-3 prop-line prop-title">BackFields</div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.phone"
                                label="Phone"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.email"
                                label="Email"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.address"
                                label="Address"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.surrounding"
                                label="Surrounding"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.website_name"
                                label="Web"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.website"
                                label="URL"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.link1_name"
                                label="Link1"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.link1"
                                label="URL"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.link2_name"
                                label="Link2"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.link2"
                                label="URL"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-textarea rows=14 row-height=16 outlined dense v-model="applResouce.backFields.more"
                                label="More">
                            </v-textarea>
                        </div>

                    </div>

                </div>
            </div>
            </Transition>
            <div v-if="viewMode==false" class="mr-3">
                <div class="properties">
                    <div class="prop-line prop-title">Setting</div>
                    <v-row v-if="hideCardType==false" class="prop-line mt-0 pt-0">
                        <v-col col="12">
                            <v-select hide-details label="Card type" :items="cardTypes" item-text="name"
                                item-value="value" v-model="applResouce.templateData.cardType"></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="prop-line mt-0 pt-0">
                        <v-col col="1" class="py-1">Foreground color</v-col>
                        <v-col col="11" class="py-1">
                            <KCColorPicker v-model="applPassData.foregroundColor" :hideAlpha=true ouputFormat="rgb"
                                top="32px"></KCColorPicker>
                        </v-col>
                    </v-row>
                    <v-row class="prop-line mt-0 pt-0">
                        <v-col col="1" class="py-1">Label color</v-col>
                        <v-col col="11" class="py-1">
                            <KCColorPicker v-model="applPassData.labelColor" :hideAlpha=true ouputFormat="rgb"
                                top="32px"></KCColorPicker>
                        </v-col>
                    </v-row>
                    <template v-if="cardTypeClass.includes('event-ticket') == false">
                        <v-row class="prop-line mt-0 pt-0">
                            <v-col col="1" class="py-1">Background color</v-col>
                            <v-col col="11" class="py-1">
                                <KCColorPicker v-model="applPassData.backgroundColor" :hideAlpha=true ouputFormat="rgb"
                                    top="32px"></KCColorPicker>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-else>
                        <v-row class="prop-line mt-0 pt-0">
                            <v-col col="1" class="py-1"><label>Background</label></v-col>
                            <v-col col="11" class="py-1">
                                <v-checkbox class="mt-n1 ml-n1" hide-details dense
                                    v-model="applResouce.chkBackgroundImage" label="Use image"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row v-if="applResouce.chkBackgroundImage==false" class="prop-line mt-0 pt-0">
                            <v-col col="1" class="py-1">color</v-col>
                            <v-col col="11" class="py-1">
                                <KCColorPicker v-model="applPassData.backgroundColor" :hideAlpha=true ouputFormat="rgb"
                                    top="32px"></KCColorPicker>
                            </v-col>
                        </v-row>
                        <v-row v-if="applResouce.chkBackgroundImage" class="prop-line mt-0 pt-0">
                            <v-col col="1" class="py-1">Image</v-col>
                            <v-col col="11" class="py-1">
                                <div class="pa-1">
                                    <v-img width="90" height="110" class="clickable"
                                        @click="showCropUI('backgroundImage')" :src=applResouce.backgroundImage></v-img>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-if="isAddThisPassMode || isMindFireMode">
                        <v-row class="prop-line mt-0 pt-2">
                            <v-col cols="12" class="py-1">
                            <v-text-field hide-details clearable dense v-model="applResouce.templateData.greetingMessage"
                                label="Message when first scanning"></v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-if="isMindFireMode">
                        <v-row class="prop-line mt-0 pt-2">
                            <v-col cols="12" class="py-1">
                            <v-text-field hide-details clearable dense v-model="applResouce.templateData.redirectURL"
                                label="Redirect to"></v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                </div>
            </div>
            <div class="mr-3">
                <!-- <button @click="cropUI.show = !cropUI.show;">Crop</button>
                <img :src="imgDataUrl"> -->
            </div>
        </v-row>
        <v-row v-if="viewMode==false">
            <v-col cols="12">

                <div class="pb-3">
                    <template v-if="debugMode">
                        <v-row>
                            <v-col cols="9">
                            <v-btn @click="saveAsJson()">Save As Json</v-btn>
                            <v-btn v-show="isUpdateMode==false" @click="createPassClick()">Create Pass</v-btn>
                            <v-btn v-show="isUpdateMode==true" @click="createPassClick()">Update Pass</v-btn>
                            <!-- <v-text-field v-if="isAddThisPassMode==false && isUpdateMode==true" class="pl-2" style="display:inline-flex;width:500px;" hide-details clearable dense v-model="applResouce.templateData.pushMessage"
                                label="Push Message"></v-text-field> -->
                            </v-col>
                        </v-row>
                    <div class="pt-1" v-if="isAddThisPassMode">
                        <v-btn class="mr-3" @click="createAssThisPassClick()">Create/update Pass (AddThisPass)</v-btn>
                        <span style="display:inline-block;width:120px;"><v-select v-model="addThisPassMode" :items="['passinfo','pushmsg']"></v-select></span>
                    </div>
                    </template>                    
                </div>
                <div v-if="passQRCodeImage">
                    <div><img style="width:300px;" :src="passQRCodeImage" /></div>
                    <div><a :href="passUrl">Add this pass</a></div>
                </div>
            </v-col>
        </v-row>
        </v-form>
        <KCImageCropper ref="cropper" :key="cropUI.show" :width=cropUI.width :maxWidth=cropUI.maxWidth :maxHeight=cropUI.maxHeight
            :height=cropUI.height @crop="onCrop" v-model="cropUI.show" :src="initialImage"></KCImageCropper>
        <v-file-input ref="fileInput" style="display: none" @change="loadImageFile" />
        <!-- <button @click="$refs.file.click()">open file dialog</button> -->
        <v-dialog v-model="showLoadDialog" width="800">
              <v-sheet style="position:relative;" class="rounded-lg mx-auto pa-5" elevation="12" height="550" width="100%">
                  <v-row>
                    <v-col cols="12">
                        <label>Available Pass</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                        <v-data-table :loading="passDataLoading" dense :hide-default-footer="false" :headers="passDataTable.headers" :items="passDataTable.items" :items-per-page="10"
                        @click:row="passDataTableRowClick" 
                        sort-by="lastModified"
                        sort-desc
                        class="elevation-1">
                            <template v-slot:[`item.lastModified`]="props">
                                <div>{{ fromNow(props.item.lastModified)}}</div>
                            </template>
                        </v-data-table>
                    </v-col>
                  </v-row>
                  <div style="position: absolute;bottom: 15px;width: 95%;">
                     <v-btn style="float:right;" @click="showLoadDialog=false">Close</v-btn>
                  </div>
              </v-sheet>
        </v-dialog>
    </v-container>
</template>
<style>


@import '../assets/styles/cardViewer.css';
</style>
<script>
// passbook tutorial https://www.kodeco.com/2853-beginning-passbook-in-ios-6-part-2-2
// import contenteditable from 'vue-contenteditable';
// import 'babel-polyfill'; 
import axios from "axios";
import utils from '../services/KCUtils.js'
import csvUtils from "../services/KCCSVUtils.js";
import KCColorPicker from "../components/KCColorPicker.vue";
import KCPassFieldInput from "../components/KCPassFieldInput.vue";
import KCImageCropper from "../components/KCImageCropper.vue";
import KCComputedInput from "../components/KCComputedInput.vue";
//import KCSearchableSelect from "../components/KCSearchableSelect.vue";
import defaultPass from '../assets/json/cardDefault.json';

//var Jimp = require('jimp');

export default {
    name: "Kwang-CardBuilder",
    // directives: {
    //     ResizeText
    // },
    components: {
        // contenteditable
        KCColorPicker,
        KCPassFieldInput,
        // myUpload,
        KCImageCropper,
        KCComputedInput,
        //KCSearchableSelect,
        // VueCropper
    },
    props: {
        // value: null,
        serverMode: {
            default: "pass",
        },
        defaultCardType: {
            default: "event-ticket-add-this-pass",
            //default: "mindfire",
        },
        hideCardType: {
            default: false,
        },
        debugMode: {
            default: false,
        },
        headers: {
            default(){
                return [];    
            }
        },
        sampleData: {
            default(){
                return [];    
            }
        },
        autoLoadDefault: {
            default: true,
        },
        readOnlyMode: {
            default:false,
        },
        viewMode: {
            default:false,
        },
        backgroundColor: {
            default:"white",
        }

    },
    data() {
        var config = utils.getServerConfig(this.serverMode);
        console.log("data",defaultPass);
        console.log("config",config);
        return {
            menu: false,
            isFormValid:true,
            root: null,
            //endPoint: config.endPoints.mindfireOne + "/api/card",
            //endPoint: "http://localhost:3000/card",
            endPoint: config.endPoints.card,
            qrcodeEndPoint: config.endPoints.qrcode,
            showingFront: true,
            //cardType: "event-ticket-add-this-pass", //"coupon",
            cardTypes: [
                { name: "Coupon", value: "coupon" },
                { name: "MindFire", value: "mindfire" },
                { name: "AddThisPass", value: "event-ticket-add-this-pass" },

            ],
            //displayMode: "add-this-passX",
            cropUI: {
                show: false,
                mode: "",
                width: 200,
                height: 200,
                maxWidth: null,
            },
            initialImage: null,
            tabImageSize: {
                "coupon": {
                    width: 375,     //x3 
                    height: 89,  //144
                },
                "logo": {
                    // width: 150,     //480x150
                    // height: 150,
                    // maxWidth: 400, //300
                    width: 125,
                    height: 125,
                    maxWidth: 400,
                },
                "backgroundImage": {
                    width: 180,     //180x220 
                    height: 220,
                },
                "thumbnail":{
                    width: 200,     //480x150
                    height: 200,
                    maxWidth: 300,
                    maxHeight: 300,
                }

            },
            barcodeTypes: {
                "NoBardcode": "",
                "PKBarcodeFormatQR": "https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/barcode2.png" /*require("../assets/barcode2.png")*/,
                "PKBarcodeFormatPDF417": "https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/barcode1.png"/*require("../assets/barcode1.png")*/,
                "PKBarcodeFormatCode128": "https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/barcode4.png"/*require("../assets/barcode4.png")*/,
                "PKBarcodeFormatAztec": "https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/barcode3.png"/*require("../assets/barcode3.png")*/,
            },
            applResouce: defaultPass.resource,
            applPassData: defaultPass.passData,
            activeObject: null,
            activeObjectIndex: null,
            localStripFileInput: null,
            logoWidth: null,
            logoHeight: null,
            thumbnailWidth: null,
            thumbnailHieght: null,
            passUrl: null,
            passQRCodeImage: null,
            fitTextCanvasContext: null,
            backdoor: 0,
            moreFields: [
                {
                    "key": "expires1",
                    "label": "EXPIRES",
                    "value": "1234567890"
                },
                {
                    "key": "expires2",
                    "label": "EXPIRES",
                    "value": "1234567890"
                },
                // {
                //     "key": "ref",
                //     "label": "Booking Reference",
                //     "value": "LAX-123456789"
                // }                
            ],
            defaultImage: {

            },
            currentPassID: null,
            currentPass: null,
            addThisPassMode: "passinfo",
            cardLoading: false,
            showLoadDialog: false,
            passDataLoading: false,
            
            passDataTable: {
                headers: [
                    { text: "ID", value: "id", sortable: true},
                    { text: "Name", value: "name", sortable: true,/* width:"350px"*/},

                    { text: "LastModified", value: "lastModified", sortable: true},
                    // { text: "Action", value: "action", sortable: false, align:"right"},
                ],
                items: [

                ],
            },
            qrcodeColumnRule: [
                value => {
                    return (!!value) || "Required";
                },
                value => {
                    console.log("qrcodeColumnRule",value);
                    return ((this.headers.indexOf(value)<0)  ||  (this.readOnlyMode == true) )|| "Column exist";
                }
            ],
        };
    },
    computed: {
        // moreFields(){
        //     var ret = [];
        //     ret = [... this.applPassData.coupon.secondaryFields, ... this.applPassData.coupon.auxiliaryFields];
        //     return ret;
        // },
        mainStyle() {
            var ret = {
                height: "100%", 
                width: "100%",
                background: this.backgroundColor,
            }
            if(this.viewMode){
                delete ret.height;
            }
            return ret;
        },
        auxiliaryHeader(){
            return this.isMindFireMode?"Push message":"Auxiliary";
        },
        barcodeImage() {
            return this.barcodeTypes[this.applPassData.barcode.format];
        },
        calcBarcodeTypes() {
            var ret = [];
            for (var b in this.barcodeTypes) {
                if(this.isAddThisPassMode){
                    // "PKBarcodeFormatPDF417": require("../assets/barcode1.png"),
                // "PKBarcodeFormatCode128": require("../assets/barcode4.png"),
                // "PKBarcodeFormatAztec": require("../assets/barcode3.png"),
                    if((b == "PKBarcodeFormatPDF417") ||(b == "PKBarcodeFormatAztec")){
                        continue;
                    }
                }
                ret.push(b);
            }
            return ret;
        },
        applStripImage() {   // return object camelStyle
            if (this.cardTypeClass.includes("add-this-pass")) {
                return { "background-image": "none" }
            }
            if ((this.applResouce.chkStripImage == true) && this.applResouce.stripImage != "") {
                return { "background-image": `url(${this.applResouce.stripImage})` }
            } else {
                return { "background-image": "none" }
            }
        },
        passColorStyle() {
            if (this.cardTypeClass.includes("event-ticket")) {
                if (this.applResouce.chkBackgroundImage == true) {
                    return { "background-color": "transparent" }
                }
            }
            return { "background-color": this.applPassData.backgroundColor }
        },
        passStyle() {    // back-ground image only for event ticket 
            this.backdoor;  // reference backdoor to force update;
            if (this.cardTypeClass.includes("event-ticket")) {
                if ((this.applResouce.chkBackgroundImage == true) && this.applResouce.backgroundImage) {
                    return { "background-image": `url(${this.applResouce.backgroundImage})` }
                }
            }
            return { "background-image": "none" }
        },
        logoStyle() {
            if (this.logoWidth == null) {
                return null;
            } else {
                console.log("logoStyle",this.logoWidth,this.logoHeight);
                return {
                    "width": Math.min(112,Math.floor(42 * this.logoWidth / this.logoHeight)) + "px",
                    // "min-width": Math.floor(42) + "px",
                }
            }
        },
        thumbnailStyle(){
            if (this.thumbnailWidth == null) {
                return null;
            } else {
                return {
                    "width": Math.floor(42 * this.thumbnailWidth / this.thumbnailHieght) + "px",
                    // "min-width": 42 + "px",
                }
            }
        },
        logoTextStyle() {
            if (this.cardTypeClass.includes("event-ticket")) {
                return { "color": "var(--label-color)" }
            } else {
                return { "color": "var(--value-color)" }
            }
        },
        cardTypeClass() {
            var viewMode = this.viewMode?["view-mode"]:[];
            switch (this.applResouce.templateData.cardType) {
                case "coupon": return ["coupon",... viewMode];
                case "mindfire": return ["mindfire",... viewMode];
                case "event-ticket": return ["event-ticket",... viewMode];
                case "event-ticket-add-this-pass": return ["event-ticket", "add-this-pass",... viewMode];
            }
            return [... viewMode];
        },
        isAddThisPassMode(){
            return this.applResouce.templateData.cardType == "event-ticket-add-this-pass";
        },
        isMindFireMode(){
            return this.applResouce.templateData.cardType == "mindfire";
        },
        isUpdateMode(){
            return this.currentPassID!=null;
        }
        
    },
    methods: {
        fromNow(date){
            return utils.fromNow(date);
        },
        maxTextLength(){
            if(this.isAddThisPassMode){
                return 20;
            }else{
                return 15;
            }
        },
        objClick(type, index, event) {
            if(this.viewMode == true){
                return;
            }
            console.log("objClick", type);
            //this.activeObjectIndex = index;
            var actObjName = `${this.activeObject}`;
            if (this.activeObjectIndex != null) {
                actObjName = `${this.activeObject}-${this.activeObjectIndex}`;
            }
            var clickObjName = `${type}`;
            if (index != null) {
                clickObjName = `${type}-${index}`;
            }

            if (actObjName == clickObjName) {
                if(this.isAddThisPassMode == false){
                    this.activeObject = null;
                    this.activeObjectIndex = null;
                }
            } else {
                this.activeObject = type;
                this.activeObjectIndex = index;
            }

        },
        stripeImageChange(evt) {
            console.log("stripeImageChange", evt);
            if (evt == null) {
                this.localStripFileInput = null;
            } else {
                if (evt.type.split("/")[0] != "image") {
                    alert("Not a image file");
                    return
                }
            }
        },
        flipPass() {
            console.log("flipPass");
            this.showingFront = !this.showingFront;
            //if(this.showingFront){
                //this.objClick(this.activeObject, null);
            this.activeObject = null;
            if(this.showingFront == false){
                if(this.isAddThisPassMode){
                    this.objClick('backFields-addThisPass',null);
                }
            }
            //}
            // if (this.showingFront == false) {
                 
            //     this.objClick("backFields-addThisPass", null);
            // } else {
            //     if (this.activeObject == "backFields-addThisPass") {
            //         this.objClick("backFields-addThisPass", null);
            //     }
            // }
        },

        cropUploadFail(param) {
            console.log("cropUploadFail", param);
        },
        cropUploadSuccess(param) {
            console.log("cropUploadSuccess", param);
        },
        loadImageFile(e) {
            if (e) {
                console.log(e);
                // Encode the file using the FileReader API
                const reader = new FileReader();
                reader.onloadend = () => {
                    // Use a regex to remove data url part
                    const base64String = reader.result
                    // .replace('data:', '')
                    // .replace(/^.+,/, '');

                    //console.log(base64String);
                    this.initialImage = base64String;
                    this.cropUI.show = true;
                    //clear file Input 
                    this.$refs.fileInput.value = null;
                    // Logs wL2dvYWwgbW9yZ...
                };
                reader.readAsDataURL(e);
            }
        },
        // _showCropUI(){

        // },
        showCropUI(mode) {
            // this.$refs.file.click();
            // this.$refs.cropper.chooseFile();
            console.log("showCropUI", mode);
            this.cropUI.mode = mode;
            switch (mode) {
                case "stripImage": {
                    this.cropUI.width = this.tabImageSize.coupon.width;
                    this.cropUI.height = this.tabImageSize.coupon.height;
                    this.cropUI.maxWidth = null;
                    this.cropUI.maxHeight = null;
                    break;
                }
                case "logo": {
                    this.cropUI.width = this.tabImageSize.logo.width;
                    this.cropUI.maxWidth = this.tabImageSize.logo.maxWidth;
                    this.cropUI.height = this.tabImageSize.logo.height;
                    this.cropUI.maxHeight = null;
                    break;
                }
                case "thumbnail": {
                    this.cropUI.width = this.tabImageSize.thumbnail.width;
                    this.cropUI.maxWidth = this.tabImageSize.thumbnail.maxWidth;
                    this.cropUI.height = this.tabImageSize.thumbnail.height;
                    this.cropUI.maxHeight = this.tabImageSize.thumbnail.maxHeight;
                    break;
                }
                case "backgroundImage": {
                    this.cropUI.width = this.tabImageSize.backgroundImage.width;
                    this.cropUI.maxWidth = this.tabImageSize.backgroundImage.maxWidth;
                    this.cropUI.height = this.tabImageSize.backgroundImage.height;
                    this.cropUI.maxHeight = null;
                    break;
                }

            }
            console.log("showCropUI",this.cropUI);
            this.$refs.fileInput.$refs.input.click()
            return;

        },
        onCrop(param) {
            var dataUrl = param.dataUrl;
            // var width = param.width;
            console.log("onCrop", this.cropUI.mode, param);
            switch (this.cropUI.mode) {
                case "stripImage": {
                    this.applResouce.stripImage = dataUrl;
                    break;
                }
                case "logo": {
                    this.applResouce.logo = dataUrl;
                    this.logoWidth = param.width;
                    this.logogHeight = param.height;
                    break;
                }
                case "thumbnail": {
                    this.applResouce.thumbnail = dataUrl;
                    this.thumbnailWidth = param.width;
                    this.thumbnailHeight = param.height;
                    break;
                }
                case "backgroundImage": {
                    this.applResouce.backgroundImage = dataUrl;
                    this.backdoor++;
                    // this.logoWidth = width;
                    break;
                }

            }
            this.$forceUpdate();
        },
        barcodeChange(value) {
            console.log("barcodeChange", value)
        },
        getTextValue(refName) {
            switch (refName) {
                case "backContact": return `-P:${this.applResouce.backFields.phone}\n-E:${this.applResouce.backFields.email}`;
                case "backAddress": return `-P:${this.applResouce.backFields.address}\n(${this.applResouce.backFields.surrounding})`;
                case "backLinks": return `- ${this.applResouce.backFields.website_name}\n- ${this.applResouce.backFields.link1_name}\n- ${this.applResouce.backFields.link2_name}`;
                case "backMore": return this.applResouce.backFields.more;
            }
        },
        async getImageAsDataURL(url, callback) {
            try {
                //console.log("baseUrl:", process.env.BASE_URL);
                const result = await fetch(url);
                const blob = await result.blob();
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.addEventListener("load", () => {
                    // convert image file to base64 string
                    if (callback) {
                        callback(reader.result);
                    }
                }, false);
                console.log(result);
            } catch (error) {
                console.log(error);
            }
        },
        clone(obj) {
            return JSON.parse(JSON.stringify(obj))
        },
        renderString(value,header,data){
            var subCommand = csvUtils.createSubsitiueCommand(value,header);
            //console.log(subCommand);
            return csvUtils.subsituteValue(data,subCommand);
        },
        renderFields(fieldName,fieldData,header,data){
            console.log("renderField",fieldName,fieldData);
            for(let x in fieldData){
                let field = fieldData[x];
                if("value" in field){
                    field.value = this.renderString(field.value,header,data);
                    // if(field.value.indexOf("##")>=0){
                    //     console.log("found",field.value);
                    //     field.value = "[" + field.value + "]";
                    // }
                }
            }
            console.log("renderField done",fieldName,fieldData);
        },
        renderCardData(cardData,header,data){
            let ret = JSON.parse(JSON.stringify(cardData));
            for(let x in ret.passData.coupon){
                this.renderFields(x,ret.passData.coupon[x],header,data);
            }
            ret.passData.logoText = this.renderString(ret.passData.logoText,header,data);
            ret.resource.templateData.greetingMessage = this.renderString(ret.resource.templateData.greetingMessage,header,data);
            ret.resource.templateData.redirectURL = this.renderString(ret.resource.templateData.redirectURL,header,data);
            return ret;
        },
        setCardData(cardData) {
            this.applResouce = cardData.resource;
            console.log("setCardData", cardData.resource)
            console.log("setCardData", cardData.passData)
            //check ticket type
            if ("eventTicket" in cardData.passData) {
                cardData.passData.coupon = cardData.passData.eventTicket;
                delete cardData.passData.eventTicket;
            }
            // render personalize
            if(this.viewMode == true){
                if(this.sampleData.length > 0){
                    cardData = this.renderCardData(cardData,this.headers,this.sampleData[0]);
                }
            }

            this.applPassData = cardData.passData;
            
            switch (this.applResouce.templateData.cardType) {
                case "coupon": {
                    //create morefield from secondary
                    this.moreFields = [... this.applPassData.coupon.secondaryFields];
                    break;
                }
                case "mindfire": {
                    
                    break;
                }
            }
            return cardData;
        },
        hasATag(data){
            const regex = /<\s*a[^>]*>(.*?)<\s*\/\s*a>/gmi;
            let m = regex.exec(data);
            console.log("hasATag",data, m != null)
            return m != null;
        },
        getCardData() {
            var cardData = {
                resource: this.clone(this.applResouce),
                passData: this.clone(this.applPassData),
            }
            // correct image 

            switch (this.applResouce.templateData.cardType) {
                case "coupon": {
                    // rebuild secondary field
                    cardData.passData.coupon.secondaryFields = [... this.moreFields];
                    cardData.passData.coupon.auxiliaryFields = [];
                    // remove background image
                    delete cardData.resource.backgroundImage;
                    // remove thumbnail image
                    delete cardData.resource.thumbnail;
                    break;
                }
                case "event-ticket": {
                    // cardData.passData.coupon.secondaryFields = [];
                    // cardData.passData.coupon.auxiliaryFields = [];
                    break;
                }
                // case "mindfire":{
                //     // remove header 
                //     cardData.passData.coupon.headerFields = [];
                //     // make sure we have 1 secondardy fields
                //     delete cardData.passData.coupon.secondaryFields[1];
                // }
            }
            // rebuild back field
            var bf = cardData.passData.coupon.backFields;
            if(this.isAddThisPassMode){
                bf.length = 0;
                bf.push({
                    "key": "contact",
                    "label": "CONTACT US:",
                    "value": this.getTextValue("backContact"),
                })
                bf.push({
                    "key": "address",
                    "label": "LOCATE US:",
                    "value": this.getTextValue("backAddress"),
                })
                bf.push({
                    "key": "links",
                    "label": "VISIT US:",
                    "value": this.getTextValue("backLinks"),
                })
                bf.push({
                    "key": "more",
                    "label": "MORE:",
                    "value": this.getTextValue("backMore"),
                })
            }else{
                // Push notification message
                // if(this.isUpdateMode){
                //     for(let x in bf){
                //         if(bf[x].key == "notification"){
                //             bf[x].value = cardData.resource.templateData.pushMessage;
                //         }
                //     }
                //     // bf.push({
                //     //     "key": "notification",
                //     //     "value": cardData.resource.templateData.pushMessage,
                //     //     "changeMessage": "[%@]",
                //     // })
                // }else{
                //     bf.push({
                //         "key": "notification",
                //         "value": "",
                //         "changeMessage": "[%@]",
                //     })
                // }
                // if found <a> html tag add attributedValue also, if does not make sure 
                // to remove attributedValue
                //const regex = /<\s*a[^>]*>(.*?)<\s*\/\s*a>/gmi;
                for(let x in bf){
                    //let m = regex.exec(bf[x].value);
                    if(this.hasATag(bf[x].value)){
                        // copy attributedValue from value
                        bf[x].attributedValue = bf[x].value;
                    }else{
                        delete bf[x].attributedValue;
                    }
                }
            }

            console.log(cardData.passData.coupon.backFields);
            // correct barcodes
            cardData.passData.barcodes = [];
            cardData.passData.barcodes.push({ ...cardData.passData.barcode });

            if (this.cardTypeClass.includes("event-ticket")) {
                // remove strip image
                cardData.resource.stripImage = null;
                // change coupon to eventTicket 
                cardData.passData.eventTicket = cardData.passData.coupon;
                delete cardData.passData.coupon;

                // check thumbnail
                if (cardData.resource.chkThumbnail == false) {
                    delete cardData.resource.thumbnail;
                }
            }
            if (this.cardTypeClass.includes("add-this-pass")) {
                // remove header 
                cardData.passData.eventTicket.headerFields = [];
                // remove label
                for (let x in cardData.passData.eventTicket.primaryFields) {
                    cardData.passData.eventTicket.primaryFields[x].label = "";
                }
                for (let x in cardData.passData.eventTicket.secondaryFields) {
                    cardData.passData.eventTicket.secondaryFields[x].label = "";
                }
                for (let x in cardData.passData.eventTicket.auxiliaryFields) {
                    cardData.passData.eventTicket.auxiliaryFields[x].label = "";
                }
            }
            if (this.cardTypeClass.includes("mindfire")) {
                // change coupon to eventTicket 
                cardData.passData.eventTicket = cardData.passData.coupon;
                delete cardData.passData.coupon;

                // remove headerFields 
                cardData.passData.eventTicket.headerFields = [];
                // make sure we have 1 secondardy fields
                cardData.passData.eventTicket.secondaryFields = [cardData.passData.eventTicket.secondaryFields[0]];

                // remove thumbnail
                delete cardData.resource.thumbnail;
                
                if(cardData.resource.chkBackgroundImage == false){
                    delete cardData.resource.backgroundImage;
                }
            }
            return cardData;
        },
        saveAsJson() {
            var text = JSON.stringify(this.getCardData(), null, 4);
            var url = window.URL.createObjectURL(new Blob([text], { type: "text/plain" }));
            var anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "passData.json";
            // (E) "FORCE DOWNLOAD"
            // NOTE: MAY NOT ALWAYS WORK DUE TO BROWSER SECURITY
            // BETTER TO LET USERS CLICK ON THEIR OWN
            anchor.click();
            window.URL.revokeObjectURL(url);
            anchor.remove();
        },
        checkPass() {
            // has header field
            var headers = this.applPassData.coupon.headerFields;
            var primary = this.applPassData.coupon.primaryFields;
            var message = "";
            // if(message == "" && (!headers[0].value || !headers[0].label)){
            //     message = "Header field can not empty";
            // }
            // if(message == "" && (!primary[0].value || !headers[0].label)){
            //     message = "Primary field can not empty";
            // }
            if (message != "") {
                this.$swal.fire({
                    title: 'Oops!',
                    html: `<span>${message}</span>`,
                    ...csvUtils.getSwalYesNoOption(),
                });
                return false;
            }
            return true;
        },
        async createPassClick() {
            if (!this.checkPass()) {
                return;
            }
            await this.createPass();
        },
        async createAssThisPassClick(){
            this.$swal.fire({
                title: 'Creating pass',
                html: `<span></span>`,
            });
            this.$swal.showLoading();
            await this.saveClick();

            var passTagID = "";
            var csvData = "";
            var passKey = "";
            var passID = this.currentPassID;
            if(this.currentPass!=null ){
                passKey = this.currentPass.passKey;
                passID = this.currentPass.id;
            }
            //var mode = this.addThisPassMode;
            let formData = new FormData();           
            formData.append("cmd", 'passesUpdate');           
            formData.append("acctID", this.$store.getters.user.account);   //acctID         
            formData.append("progID", passID);   //id      // from addTHispass table
            formData.append("mode", this.addThisPassMode);       //"passinfo"  "onestop"
            formData.append("passKey", passKey);          //""
            formData.append("passTagID", passTagID);      //""    
            formData.append("passData", JSON.stringify(this.getCardData()));        //JSON   
            formData.append("tagsfile", csvData);    //CSV
            console.log(formData);
            var ret = await axios.post("https://davincistage.mindfireinc.com/admin/backend.php", formData);
            console.log(ret);
            //https://addthispass.com/pass/?pass=3nXK&qr=true&t=
            var qrCodeURL = `https://addthispass.com/pass/?pass=${ret.data.passKey}&qr=true&t=`;
            console.log("qrCodeURL",qrCodeURL,"passUrl",this.passUrl);
            this.passQRCodeImage = await this.getQRCodeImage(qrCodeURL);                
            this.$swal.close();
        },
        async saveToDB() {
            //save to database
            var cardData = this.getCardData();
            var ret = await axios.post(this.endPoint, {
                cmd: "dbAdd",
                acctID: this.$store.getters.user.account,
                data: cardData,
            });
            console.log("saveToDB", ret);
            ret.cardData = cardData;
            return ret;
        },
        async updateToDB(){
            var ret = await axios.post(this.endPoint, {
                cmd: "dbUpdateByID",
                acctID: this.$store.getters.user.account,
                data: this.getCardData(true),
                id: this.currentPassID,
            });
            console.log("updateToDB", ret);
            return ret;

        },
        async saveInstanceToDB(param) {
            //save to database
            var ret = await axios.post(this.endPoint, {
                cmd: "dbAddPassInstance",
                acctID: this.$store.getters.user.account,
                param: param,
            });
            console.log("saveInstanceToDB", ret);
            return ret;
        },        
        async saveClick() {
            var ret;
            console.log("saveClick","saving");
            if (this.currentPassID!=null) {
                // loading from id
                //this.loadByID(this.$route.query.id);
                //alert("need to update", this.$route.query.id);
                ret = await this.updateToDB();
                console.log(ret);
            } else {
                ret = await this.saveToDB();
                this.currentPassID = ret.data.result.insertId;
            }
            console.log("saveClick","done");
            return ret;
        },
        async newClick(){
            //this.$router.push('/passBuilder').catch(()=>{
                this.loadByDefault(this.applResouce.templateData.cardType);
            //});
        },
        async loadClick(){
            this.showLoadDialog = true;
            this.passDataLoading = true;
            var ret = await axios.post(this.endPoint, {
                cmd: "dbGetByAccount",
                acctID: this.$store.getters.user.account,
            });            
            this.passDataLoading = false;
            console.log(ret);
            // filter that table if in add this pass mode
            this.passDataTable.items = [];
            for(var x in ret.data.result){
                console.log(ret.data.result[x]);
                if(this.isAddThisPassMode){
                    if(ret.data.result[x].status == null){
                        this.passDataTable.items.push(ret.data.result[x]);    
                    }
                }
                if(this.isMindFireMode){
                    if(ret.data.result[x].status == "MF"){
                        this.passDataTable.items.push(ret.data.result[x]);    
                    }
                }
                
            }
            //this.passDataTable.items = ret.data.result;
        },
        async createPass() {
            var saveRet = await this.saveClick();
            console.log("saveRet",saveRet);
            var getPassInstanceByTemplateIDRet = await axios.post(this.endPoint, {
                cmd: "dbGetPassInstanceByTemplateID",
                acctID: this.$store.getters.user.account,
                id: this.currentPassID,
            });
            console.log("getPassInstanceByTemplateIDRet",getPassInstanceByTemplateIDRet);
            var cardData = this.getCardData();
            if(getPassInstanceByTemplateIDRet.data.result.length > 0){
                this.$swal.fire({
                    title: 'Updating pass',
                    html: `<span></span>`,
                });
                this.$swal.showLoading();
                console.log("update existing instance");
                var record = getPassInstanceByTemplateIDRet.data.result[0];
                //check if it a dynamic pass
                if(record.fileID == ""){
                    console.log("dynamic ndo nothing");
                }else{
                    var updatePassRet = await axios.post(this.endPoint, {
                        cmd: "updatePass",
                        acctID: this.$store.getters.user.account,
                        serial: record.cardSerial,
                        data: cardData,
                    });
                    console.log("updatePassRet",updatePassRet);
                    var updatePassInstanceByTemplateIDRet = await axios.post(this.endPoint, {
                        cmd: "dbUpdatePassInstanceByTemplateID",
                        acctID: this.$store.getters.user.account,
                        param: {
                            templateID: this.currentPassID,
                            fileID: updatePassRet.data.result.fileID,
                            data: {
                                "passURL": updatePassRet.data.result.Location,
                                "templateData": cardData.resource.templateData,
                            }
                        }
                    })
                    console.log("updatePassInstanceByTemplateIDRet",updatePassInstanceByTemplateIDRet);
                    await this.getQRCodeImageFromInstanceID(record.Id);
                }
                //sendNotification
                var sendNotificationRet = await axios.post(this.endPoint, {
                    cmd: "sendNotification",
                    acctID: this.$store.getters.user.account,
                    templateID: this.currentPassID,
                })
                console.log(sendNotificationRet);

                this.$swal.close();
                return;
            }
            this.$swal.fire({
                title: 'Creating pass',
                html: `<span></span>`,
            });
            this.$swal.showLoading();

            console.log("create new instance");
            let param = {
                cmd: "createPass",
                acctID: this.$store.getters.user.account,
                data: cardData,
            }
            console.log(this.endPoint);
            // var ret = {"success":true,"version":"1.20 [zip + huge file support + localprocess + subsitute]","handlerName":"cardwallet.js","handlerVersion":"1.01","cmd":"createPass","result":{"Expiration":"expiry-date=\"Fri, 16 Dec 2022 00:00:00 GMT\", rule-id=\"pass\"","ETag":"\"b810ee2cb755388947d60cecfa4dddc5\"","VersionId":"lWAqewh7Nml3dyPKETk88iVew48pzxxU","Location":"https://mfdavinci.s3.us-west-1.amazonaws.com/file/pass/7f20969b-f925-41cd-89a3-e6bc3affab18.pkpass","key":"file/pass/7f20969b-f925-41cd-89a3-e6bc3affab18.pkpass","Key":"file/pass/7f20969b-f925-41cd-89a3-e6bc3affab18.pkpass","Bucket":"mfdavinci"}}
            var ret = await axios.post(this.endPoint, param);
            var result = ret.data.result;
            this.passUrl = result.Location;
            var fileID = result.fileID;
            var serial = result.serial;
            console.log("createPass", this.passUrl,fileID,serial);            
            var saveInstanceRet = await this.saveInstanceToDB({
                templateID: this.currentPassID,
                fileID,serial,
                data:{
                    "passURL": this.passUrl,
                    "templateData": cardData.resource.templateData,
                    // passURLs: [
                    //     ret.data.result.Location
                    // ]
                },
            });
            console.log("saveInstanceRet",saveInstanceRet);
            await this.getQRCodeImageFromInstanceID(saveInstanceRet.data.result.insertId);
            this.$swal.close();
        },
        async getQRCodeImageFromInstanceID(instanceID){
            //var instanceID = saveInstanceRet.data.result.insertId;
            var qrCodeURL = `https://mf1-unziptest.s3.us-west-1.amazonaws.com/index.html#/passView/${instanceID}`;
            console.log("qrCodeURL",qrCodeURL,"passUrl",this.passUrl);
            this.passQRCodeImage = await this.getQRCodeImage(qrCodeURL);
        },
        async getQRCodeImage(qrcodeData) {
            var myOption = {
                margin: "0",
                color: {
                    dark: "#000000FF",
                    light: "#FFFFFFFF",
                    lefttop: "#000000FF",
                    righttop: "#000000FF",
                    leftbottom: "#000000FF",
                    svgLogo: "#000000FF",
                    lefttopeye: "#fa6e79FF",
                    righttopeye: "#00bfffFF",
                    leftbottomeye: "#2d7cdaFF",
                    frame: "#000000FF",
                    frameText: "#FFFFFFFF",
                },
                cellMode: "square",
                width: 300,
                frame: "",
                frameText: "Hello World",
                svgLogo: "",
            };
            const response = await axios.post(this.qrcodeEndPoint, JSON.stringify({
                command: "qrcode",
                text: qrcodeData,
                option: myOption,
                imageFormat: "jpg", //png,jpg
                outputFormat: "dataUrl", //buffer,dataUrl
            }));
            var url = response.data.data;
            return url;
        },
        headerStyle() {
            var fontSize = 18;   //px
            fontSize = this.getFitFontSize(this.applPassData.coupon.headerFields[0].value, 90, fontSize)
            return {
                "font-size": `${fontSize}px`,
            }
        },
        primaryStyle() {
            var fontSize = 62;   //px
            if (this.cardTypeClass.includes("event-ticket")) fontSize = 17;
            fontSize = this.getFitFontSize(this.applPassData.coupon.primaryFields[0].value, 296, fontSize)
            return {
                "font-size": `${fontSize}px`,
            }
        },
        valueStyle(d) {
            var fontSize = 21.12;   //px
            if (this.cardTypeClass.includes("event-ticket")) {
                fontSize = 16;
            }
            var text = "";
            for (var i = 0; i < d.length; i++) {
                if (i == 0) {
                    text += this.moreFields[i].value;
                } else {
                    text += "___" + this.moreFields[i].value; //"____" for simulating space betweeen fields
                }
            }
            fontSize = this.getFitFontSize(text, 296, fontSize)
            return {
                "font-size": `${fontSize}px`,
            }
        },
        // textWidth(){
        //     console.log(this.getTextWidth(this.moreFields[0].value,"Roboto",21.12));
        //     console.log(this.getFitFontSize(this.moreFields[0].value,296,21.12));
        // },
        getTextWidth(text, fontName, fontSize) {
            if (this.fitTextCanvasContext == null) {
                var canvas = document.createElement("canvas");
                this.fitTextCanvasContext = canvas.getContext("2d");
            }
            var font = `${fontSize}px ${fontName}`;
            this.fitTextCanvasContext.font = font;
            const { width } = this.fitTextCanvasContext.measureText(text);
            return width;
        },
        getFitFontSize(text, width, maxFontSize, step = 0.5, font = 'Roboto') {
            var fs = maxFontSize;
            var textWidth = this.getTextWidth(text, font, fs);
            while ((textWidth - width) > 0) {
                //console.log("getFitFontSize",fs,textWidth,width);
                if (textWidth > width) {
                    fs -= step;
                    textWidth = this.getTextWidth(text, font, fs);
                }
            }
            return fs;
        },
        maxLabel(index) {
            var ret = 45;
            if(this.addThisPassMode){
                return ret;
            }
            if (this.moreFields.length == 2) {
                ret = 20;
            }
            if (this.moreFields.length == 3) {
                ret = 12;
            }
            return ret;
        },
        addClick(param) {
            console.log("addClick", param);
            this.moreFields.push(
                {
                    "key": "",
                    "label": "",
                    "value": ""
                }
            )
            this.activeObjectIndex++;
        },
        deleteMoreField(index) {
            var ret = [];
            for (var x = 0; x < this.moreFields.length; x++) {
                if (x != index) {
                    ret.push(this.moreFields[x]);
                }
            }
            this.moreFields = ret;
            if (this.activeObjectIndex > 0) {
                this.activeObjectIndex--;
            }
            //delete this.moreFields[index];
        },
        async loadByID(id,copyMode = false) {
            console.log("loadByID",id,copyMode);
            this.cardLoading = true;
            var result = await axios.post(this.endPoint, {
                cmd: "dbGetByID",
                id: id,
                acctID: this.$store.getters.user.account,
            });
            console.log("loadByID", id, result);
            if (result.data.result.length > 0) {
                var record = result.data.result[0];
                var cardData = JSON.parse(record.data);
                // this.setCardData(cardData);
                // this.currentPassID = id;
                // this.applyCardData();
                this.loadByCardData(id,cardData);
            }
            if(copyMode == true){
                this.makeACopy();
            }
            this.cardLoading = false;
        },
        async loadByCardData(id,cardData){
            var ret = this.setCardData(cardData);
            this.currentPassID = id;
            this.applyCardData();
            return ret;
        },
        async loadByDefault(cardType = null){
            var data = this.clone(defaultPass);
            if(cardType!=null){
                data.resource.templateData.cardType = cardType;
            }else{
                data.resource.templateData.cardType = this.defaultCardType;
            }
            console.log("loadByDefault",data);
            //check if we have _id in sample data
            if(this.headers.indexOf("_id")>=0){
                data.resource.templateData.purlData.columnName = "_id";
            }
            this.setCardData(data);
            this.applResouce.templateData.templateName = "New Pass " + utils.UTCDateTime();  
            this.currentPassID = null;   
            this.currentPass = null;
            var lst = ["purlInput","messageInput","linkInput"];
            for(var x in lst){
                if(lst[x] in this.$refs){
                    var ref = this.$refs[lst[x]];
                    ref.init();
                }
            }
            this.applyCardData();       
        },
        getImageWidthFromDataURL(url,callback){
            let image = new Image();
            image.src = url;
            //let self=this;
            image.onload = function() {
                //console.log("applyCardData",this.width + 'x' + this.height);
                if(callback){
                    callback(this.width,this.height)
                }
                //self.logoWidth = this.width;
            }
        },
        applyCardData(){
            this.root.style.setProperty("--value-color", this.applPassData.foregroundColor);
            this.root.style.setProperty("--label-color", this.applPassData.labelColor);
            if(("thumbnail" in this.applResouce == false)||(this.applResouce.thumbnail == null)){
                this.applResouce.thumbnail = this.defaultImage.thumbnail;
                // adjust width of logo
                this.getImageWidthFromDataURL(this.applResouce.thumbnail,(width)=>{
                    this.thumbnailWidth = width;
                })
            }
            if(("backgroundImage" in this.applResouce == false)||(this.applResouce.backgroundImage == null)){
                this.applResouce.backgroundImage = this.defaultImage.backgroundImage;
            }
            if(("stripImage" in this.applResouce == false)||( this.applResouce.stripImage == null) ){
                this.applResouce.stripImage = this.defaultImage.stripImage;
            }
            this.getImageWidthFromDataURL(this.applResouce.logo,(width,height)=>{
                    this.logoWidth = width;
                    this.logoHeight = height;
            })
            // // adjust width of logo
            // let image = new Image();
            // image.src = this.applResouce.logo;
            // let self=this;
            // image.onload = function() {
            //     //console.log("applyCardData",this.width + 'x' + this.height);
            //     self.logoWidth = this.width;
            // }

        },
        makeACopy(){
            this.currentPassID = null;
            this.currentPass = null;
            this.applResouce.templateData.templateName = this.applResouce.templateData.templateName + " (copy)";
        },
        async passDataTableRowClick(param){
            console.log("passDataTableRowClick",param);
            this.showLoadDialog = false;
            if(this.currentPassID != param.id){
                await this.loadByID(param.id);
            }
            if(this.debugMode == false){ // davinci mode
                this.makeACopy();
            }else{
                this.currentPass = {... param};
            }
            
        },
        doCSVInput(callback){
            var lst = ["purlInput","messageInput","linkInput"];
            for(var x in lst){
                if(lst[x] in this.$refs){
                    var ref = this.$refs[lst[x]];
                    callback(ref,lst[x]);
                }
            }
        },
        canSave(){
            let ret = true;
            this.doCSVInput((ref,name)=>{
                if(ref != undefined){
                    if(ref.isValid()==false){
                        console.log("canSave false",name);
                        ret = false;
                    }
                }
            })
            console.log("canSave",ret,this.isFormValid);
            return ret && this.isFormValid;
            // var lst = ["purlInput","messageInput","linkInput"];
            // for(var x in lst){
            //     if(lst[x] in this.$refs){
            //         var ref = this.$refs[lst[x]];
            //         if(ref.isValid()==false){
            //             console.log("canSave false",lst[x]);
            //             return false;
            //         }
            //     }
            // }
            // // if(this.applResouce.templateData.uniqueIDColumn 
            // //     && this.applResouce.templateData.optionalMessageColumn 
            // //     && this.applResouce.templateData.optionalLinkColumn 
            // // ) return true;
            // return true;
        },
        // onLoadFrom(item){
        //     console.log("onLoadFrom",item);
        // }
    },

    created() {
    },
    destroyed() {
    },
    mounted() {
        console.log("mounted", "query", this.$route.query);
        
        this.root = document.documentElement;
        this.getImageAsDataURL("https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/logo.png" /*require("../assets/logo.png")*/, (result => {
            this.defaultImage.logo = result;
            this.defaultImage.thumbnail = result;
            this.getImageAsDataURL("https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/stripImage.png" /*require("../assets/stripImage.png")*/, (result => {
                this.defaultImage.stripImage = result;
                this.getImageAsDataURL("https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/background.png" /*require("../assets/background.png")*/, (result => {
                    this.defaultImage.backgroundImage = result;
                    if(this.autoLoadDefault){
                        // if ("id" in this.$route.query) {
                        //     // loading from id
                        //     this.loadByID(parseInt(this.$route.query.id));
                        // }else{
                            this.loadByDefault();
                            this.$nextTick(() => {
                                if(this.$refs.qrCodeForm){
                                    this.$refs.qrCodeForm.validate();
                                }
                            });
                        // }
                    }
                    this.root.style.setProperty("--main-bg-color",this.backgroundColor);
                }));
            }));
        }));

    },
    beforeUnmount() {

    },
    watch: {
        // value: function (newValue, oldValue) {
        //     console.log("CardBuilder watch value", newValue);
        //     if (newValue != null) {
        //         //this.setConfigByValue();
        //     }
        // },        
        "applPassData.backgroundColor": {
            handler: function (newValue, oldValue) {
                //console.log("backgroundColor",oldValue,"->",newValue);
                this.$nextTick(() => {
                    // this.root.style.setProperty("--bg-color", newValue);
                })
            }
        },
        "applPassData.foregroundColor": {
            handler: function (newValue, oldValue) {
                //console.log("foregroundColor",oldValue,"->",newValue);
                this.$nextTick(() => {
                    this.root.style.setProperty("--value-color", newValue);
                })
            }
        },
        "applPassData.labelColor": {
            handler: function (newValue, oldValue) {
                //console.log("foregroundColor",oldValue,"->",newValue);
                this.$nextTick(() => {
                    this.root.style.setProperty("--label-color", newValue);
                })
            }
        },
        "applResouce.templateData.purlData":{
            deep: true,
            handler: function (newValue, oldValue) {
                console.log("purlData change")
                this.$nextTick(() => {
                    this.$emit("canSave",this.canSave());
                })
            },
        },
        "applResouce.templateData.messageData":{
            deep: true,
            handler: function (newValue, oldValue) {
                console.log("messageData change")
                this.$nextTick(() => {
                this.$emit("canSave",this.canSave());
                })
            }
        },
        "applResouce.templateData.linkData":{
            deep: true,
            handler: function (newValue, oldValue) {
                console.log("linkData change")
                this.$nextTick(() => {
                this.$emit("canSave",this.canSave());
                });
            }
        },
        "applResouce.templateData.qrcodeColumn":{
            deep: true,
            handler: function (newValue, oldValue) {
                console.log("qrcodeColumn change");
                
                this.$nextTick(() => {
                    //console.log(this.applResouce.templateData.qrcodeColumn);
                    if(this.$refs.qrCodeForm){
                        this.$refs.qrCodeForm.validate();
                        this.$nextTick(() => {
                            this.$emit("canSave",this.canSave());
                        });
                    }
                });
            }
        },
        $route(to, from) {
            console.log("route change", from, "->", to);
            if ("id" in to.query) {
                this.loadByID(parseInt(to.query.id));
            }else{
                // new 
                this.loadByDefault();
            }
        }
    },
};
</script>