<!-- HISTORY:
   V230912.1: 1st release (copied from AsDDLs.vue V230911.1).
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">summarize</v-icon>
            <span>RDLs</span>
         </h1>
         <div class="flex-grow-1"></div>
      </v-card-title>
   
      <v-card-text>
         <v-tabs
            class="elevation-2"
            background-color="grey lighten-2 accent-4"
            slider-color="black"
            v-model="tab"
         >
            <v-tab>List</v-tab>

            <v-tabs-items v-model="tab">
               <v-tab-item class="pt-5"><!-- List -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-text-field clearable
                           class="py-0"
                           append-icon="search"
                           label="Search"
                           :disabled="!listItems.length"
                           v-model="searchList"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header show-expand single-expand
                     class="elevation-1"
                     item-key="_id"
                     :footer-props="footerPropsList"
                     :headers="listHeaders"
                     :hide-default-footer="listItems.length <= 5"
                     :items="listItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsList"
                     :search="searchList"
                     @update:expanded="itemExpanded"
                  >
                     <template v-slot:expanded-item="{ item }">
                        <td dense
                           colspan="4"
                           class="px-0"
                           valign="top"
                        >
                           <bt-json-viewer
                              :depth="2"
                              :data="item.details || {}"
                              :debug="debug"
                           ></bt-json-viewer>
                        </td>
                     </template>
                  </v-data-table>
               </v-tab-item>
            </v-tabs-items>
         </v-tabs>
      </v-card-text>
   </v-card>
</v-container>
</template>

<script>
import { APIService } from '../services/as-api-service.js';
import BtJsonViewer from './BtJsonViewer.vue';
// import { sleep } from '../mixins/bt-mixin.js';

const NAME = "AsRDLs";
// const TABS = { totals: 0, list: 1 };

export default {
   name: NAME,

   components: {
      BtJsonViewer
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         listHeaders: [
            { text: 'Name', value: 'name', align: 'left', sortable: true },
            { text: 'Author', value: 'author', align: 'left', sortable: true },
            { text: 'Audience', value: 'audience', align: 'left', sortable: true }
         ],
         apiService: null,
         tab: null,
         loading: false,
         lastItemsPerPage: 10,
         searchList: '',
         footerPropsList: {
            itemsPerPageOptions: [5, 10, 15, 20],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         optionsList: {},
         listItems: []
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      }
   },

   watch: {
      token() {
         this.init();
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V230912.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         if (this.token) {
            this.apiService = new APIService(this.token, this.debug, this.isActualEndpoint, '/api/bi/v1/admins/rdls');
            setTimeout(() => {
               this.getListItems();
            }, 100);
         }
      },

      async getListItems() {
         // alert(`in getListItems(): status=${this.status}\ndateRange=${JSON.stringify(this.dateRange)}`);
         this.loading = true;
         this.listItems = [];
            let result = await this.apiService.getListRDLs();
            if (result.logout)
               this.logout();
               
            if (!result.message) {
               this.listItems = result.data;
            }

         this.loading = false;
      },

      async itemExpanded(items) {
         // _alert('in itemExpanded(): items=' + JSON.stringify(items));
         if (items.length === 0 || items[0].details) return;

         this.loading = true;
         // const date = addDate ? convertToISODate(new Date(), true) : null;
         let result = await this.apiService.getRDL(items[0]._id);
         if (result.logout)
            this.logout();
         else if (!result.message) {
            items[0].details = result.data;
         }
         this.loading = false;
      }
   },

   created() {
      this.init();
   }

}
</script>