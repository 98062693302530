<!-- need cols="col-12" to fixed xs problem -->
<!-- xs seem not work -->
<template>
<v-container class="kc-home-page px-lg-7 pt-lg-0" style="height:100%;max-width:100%;">
    <!--<v-btn fab absolute @click="getAccountOverviewData()">xxxx</v-btn>-->
    <!--XXX {{kpi1.title1    }} YYY-->
    <iframe v-if=customHomePageUrl :src=customHomePageUrl allowfullscreen="allowfullscreen" scrolling="yes" frameborder="0" allowtransparency="true" style="width: 0px; min-width: 100% !important; height: 100%;"></iframe>
    <v-row v-else class="py-1">

        <v-col class=" home-page-card" cols="col-12" xl="6" lg="6" md="12" sm="12" xs="12">
            <KCVCardList :loading="card1Data.length == 0" :position=card1.position :src=card1.image :height=height :title=card1.title :tinelineTitle=card1.timelineTitle :list-data=card1Data />
        </v-col>
        <v-col class=" home-page-card" cols="col-12" xl="6" lg="6" md="12" sm="12" xs="12">
            <KCVCardList :position=card2.position :src=card2.image :height=height :title=card2.title :tinelineTitle=card2.timelineTitle :list-data=card2Data />
        </v-col>
        <v-col class=" home-page-card" cols="col-12" xl="6" lg="6" md="12" sm="12" xs="12">
            <KCVCardList :loading="card3Data.length == 0" :position=card3.position :src=card3.image :height=height :title=card3.title :consumerParam="{index:2}" :tinelineTitle=card3.timelineTitle :list-data=card3Data />
        </v-col>
        <v-col class=" home-page-card" cols="col-12" xl="6" lg="6" md="12" sm="12" xs="12">
            <v-container :style="{ height: (height) + 'px' }" max-height="height + 100" width="100%">
                <v-row justify="space-around" style="height:100%;">
                    <v-card xcolor="#385F73" style="width:100%;height:100%;">
                        <v-img :position=card4.position style="height:30%;" :src=card4.image>
                            <v-card-title class="white--text mt-8" style="height:100%;">
                                <p class="ml-3">
                                    {{card4.title}}
                                </p>
                            </v-card-title>
                        </v-img>

                        <v-card-text style="overflow-y:auto;height:70%;">
                            <v-row class="px-5 py-1">
                                <!-- <v-col style="p-1" cols="col-12" xl="3" lg="3" md="12" sm="12" xs="12">
                                    <KCVCardCenterNumber icon="home" :height=cardCenterNumberHeight title="Active Programs" :value="accountOverview.activeProgram" />
                                </v-col> -->
                                <v-col cols="col-12" xl="4" lg="4" md="12" sm="12" xs="12">
                                    <KCVCardCenterNumber :icon=kpi1.icon :loading="kpi1Data.length == 0" :height=cardCenterNumberHeight :title=kpi1.title1 :subTitle=kpi1.title2 :value=kpi1Data />
                                </v-col>
                                <v-col cols="col-12" xl="4" lg="4" md="12" sm="12" xs="12">
                                    <KCVCardCenterNumber :icon=kpi2.icon :loading="kpi2Data.length == 0" :height=cardCenterNumberHeight :title=kpi2.title1 :subTitle=kpi2.title2 :value=kpi2Data />
                                </v-col>
                                <v-col cols="col-12" xl="4" lg="4" md="12" sm="12" xs="12">
                                    <KCVCardCenterNumber :icon=kpi3.icon :loading="kpi3Data.length == 0" :height=cardCenterNumberHeight :title=kpi3.title1 :subTitle=kpi3.title2 :value=kpi3Data />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-row>
            </v-container>
        </v-col>
    </v-row>
</v-container>
</template>

<style>
</style>

<script>
/*
    card data format
    [
            {
                "title":"Supply Chain Shortages",
                "url":"https://mindfireinc.com/the-best-postcards-march-2022/",
                "subtitle":"",      
                "lastUpdate":"2022-03-22T00:00:00.000Z",
            },  
    ],
*/
import axios from "axios";
import KCVCardList from '../components/KCVCardList.vue';
import KCVCardCenterNumber from '../components/KCVCardCenterNumber.vue';
import utils from '../services/KCUtils.js'

//console.log("HomePage", process.env.VUE_APP_NOT_SECRET_CODE);

export default {
    
    name: "Kwang-HomePage",
    components: {
        KCVCardList,
        KCVCardCenterNumber,
    },
    props: {
        /*debug: {
            default: true,
        },*/
        serverMode: {
            default: "stage",
        },
    },
    data() {

        var now = Date.now();
        var config = utils.getServerConfig(this.serverMode);
        //console.log(config);
        return {
            portalSettings: null,
            config: config,
            height: 400,
            window: {
                width: 0,
                height: 0
            },
            //kb: [],
            //wp: [],
            card1Data: [],
            card2Data: [],
            card3Data: [],
            accountOverview: {
                activeProgram: null,
                activeContact: null,
                emailSent: null,
                pageVisitor: null,
            },
            kpi1Data: [],
            kpi2Data: [],
            kpi3Data: [],
        }
    },
    computed: {
        account: function () {
            //console.log('user object: ', this.$store.getters.user);
            return this.$store.getters.user.account;
        },
        parent: function () {
            if (this.$store.getters.user.parent == 0 || this.$store.getters.user.parent == null)
                return 'null';
            return this.$store.getters.user.parent;
        },
        customHomePageUrl:  function () {
            return  this.getHomeConfig("iframeURL");
        },
        cardCenterNumberHeight() {
            return this.height * 0.6;
        },
        card1:  function () {
            return  this.getHomeConfig("card1", {});
        },
        card2:  function () {
            return   this.getHomeConfig("card2", {});
        },
        card3:  function () {
            return  this.getHomeConfig("card3", {});        
        },
        card4:  function () {
            return  this.getHomeConfig("card4", {});             
        },
        kpi1:  function () {
            return  this.getHomeConfig("kpi1", {});
        },
        kpi2:  function () {
            return  this.getHomeConfig("kpi2", {});        
        },
        kpi3:  function () {
            return  this.getHomeConfig("kpi3", {});                    
        },
    },
    methods: {
        setThePortalSettings: async function(){
            try {
                //console.log("baseUrl:", process.env.BASE_URL);
                const result = await fetch(process.env.BASE_URL + "settings.json");
                this.portalSettings = await result.json();
                //console.log("portalSettings:", this.portalSettings);
            } catch (error) {
                console.log(error);
            }
        },
        getHomeConfig(name, defValue = null) {
            try {
                if (this.portalSettings.home[name] == undefined || this.portalSettings.home[name] == null) {
                    return defValue;
                }
                return this.portalSettings.home[name];
            } catch (ex) {
                return defValue;
            }
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            this.height = Math.max(400, (this.window.height - 100) / 2);
        },
        date_range(param){
            var unit = "days";
            var value = 0;
            if(typeof param == "number"){
                value = param;
            }
            if(typeof param == "string"){
                var match = param.match(/(\d+)\s*(\w+)?/);
                if(match){
                   value = parseInt(match[1]);
                   if(match[2]){
                    unit = match[2];
                   }
                }
                console.log("date_range",value,unit);
            }
            var now = new Date();
            var fromDate = utils.formatDate(now, "YYYY-MM-DD", -value,unit);
            var toDate = utils.formatDate(now, "YYYY-MM-DD");
            return fromDate + "," + toDate;
        },
        substituteEndPoint(endpoint,param = {}) {
            // iterate all store variable 
            var now = new Date();
            var props = {
                mindfireone: this.config.endPoints.mindfireOne,
                bi: this.config.endPoints.bi,
                "tz-offset": now.getTimezoneOffset(),
                ... this.$store.getters.user,
                ... param,
            }
            for (const prop in props) {
                endpoint = endpoint.replaceAll("{{" + prop + "}}", props[prop]);
            }
            var match = endpoint.match(/{{([^}]+)}}/);
            while(match){
                //console.log(match);
                console.log(this);
                var result =  eval("this." + match[1]);
                //console.log(this);
                endpoint = endpoint.replaceAll(match[0],result);
                console.log(endpoint);
                match = endpoint.match(/{{([^}]+)}}/);
            }
            return endpoint;
        },
        processCardData(data){
            var colors = ['deep-purple lighten-1', 'green'];
            for (var i = 0; i < data.length; i++) {
                var item = data[i];
                item.color = colors[i % colors.length];
                if (!item.subtitle) {
                    if (item.views != null) {
                        item.subtitle = utils.unitWord("view", item.views) + ": " + item.views;
                    }
                }
            }
        },
        async getCardDataFromEndpoint(endPoint) {
            endPoint = this.substituteEndPoint(endPoint);
            const response = await axios.get(endPoint);
            console.log("getCardDataFromEndpoint", endPoint, response.data);
            this.processCardData(response.data);
            return response.data;
        },
        async getCard1Data() {
            this.card1Data = [];
            this.card1Data = await this.getCardDataFromEndpoint(this.card1.endpoint);
        },
        async getCard2Data() {
            this.card2Data = [];
            this.card2Data = await this.getCardDataFromEndpoint(this.card2.endpoint);
            if(this.card2Data.length == 0){
                this.card2Data.push(
                    {
                        "title":"Sorry you do not have on boarding data",
                    },  
                );
                this.processCardData(this.card2Data);
            }
        },
        async getCard3Data() {
            this.card3Data = [];
            this.card3Data = await this.getCardDataFromEndpoint(this.card3.endpoint);
        },

        async getKpiData(endPoint, param) {
            var resp;
            console.log("getKpiData",endPoint);
            endPoint = this.substituteEndPoint(endPoint);
            console.log("getKpiData",endPoint);
            resp = await axios.get(endPoint);
            return resp;
        },

        async getKpi1Data() {
            this.kpi1Data = [];
            var resp = await this.getKpiData(this.kpi1.endpoint);
            console.log("getKpi1Data", resp);
            this.kpi1Data = resp.data[0].contactscount;
        },
        async getKpi2Data() {
            this.kpi2Data = [];
            var resp = await this.getKpiData(this.kpi2.endpoint);
            console.log("getKpi2Data", resp);
            this.kpi2Data = resp.data[0].contactscount;
        },
        async getKpi3Data() {
            this.kpi3Data = [];
            var resp = await this.getKpiData(this.kpi3.endpoint);
            console.log("getKpi3Data", resp);
            this.kpi3Data = resp.data[0].contactscount;
        },
        async getHomePageData() {
            this.getCard1Data();
            this.getCard2Data();
            this.getCard3Data();
            this.getKpi1Data();
            this.getKpi2Data();
            this.getKpi3Data();
        },
        async postChurnZeroEvent(){
            var endPoint =  this.config.endPoints.mindfireOne + "/api/churnzero";
            let acctID = this.$store.getters.account;
            let accounts = this.$store.getters.accounts;
            let accountName = "";
            for(let x in accounts){
                console.log(accounts[x]);
                if(accounts[x].Key == acctID){
                    accountName = accounts[x].Value;
                    break
                }
            }
            console.log("accountName",accountName,"parent",this.$store.getters.user.parent);  
            var parent =  this.$store.getters.user.parent;         
            var accountID = this.$store.getters.user.account;
            if(parent == null){
                parent = this.$store.getters.user.account;
                accountID = 0;
                accountName = "";
            }
            try{  
                var resp = await axios.post(endPoint,{
                    cmd:"postEvent",
                    params: {
                        accountExternalId: parent, //this.$store.getters.user.account
                        contactExternalId: this.$store.getters.user.email,
                        action: "trackEvent",
                        eventName: "Visit Portal",
                        description: "Visit Portal",
                        quantity: 1,
                        cf_ChildAccountId: accountID, //0
                        cf_ChildAccountName: accountName,
                        cf_CurrentPageURL: window.location.href,
                    },   
                });
                console.log("postChurnZeroEvent",resp.statusText);
            }catch(ex){
                console.log(ex);
            }            
        },
    },
    created() {
        console.log("mounted", this.$store);
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        //await this.setThePortalSettings();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    async mounted() {
        // load churnzero script 
        window.ChurnZero = window.ChurnZero || [];
        (function() {
            var cz = document.createElement('script'); cz.type = 'text/javascript';
            cz.async = true;
            cz.src = 'https://mindfireinc.us1app.churnzero.net/churnzero.js';
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(cz, s);
        })();        
        window.ChurnZero.push(['setAppKey','1!0R8Ajv4tadIeSy2KOaTKQxPtiVrQl6zzVzIV2xUsse4tF3E']);       
        window.ChurnZero.push(['setContact', this.$store.getters.user.account, this.$store.getters.user.email]);
        //console.log(this.$store.getters.user);
        //var ChurnZero = ChurnZero || []; (function(a,b,c,d){var cz=a.createElement(b); cz.type=c; cz.async=true; cz.src=d; var s=a.getElementsByTagName(b)[0];s.parentNode.insertBefore(cz, s);})(document, 'script','text/javascript', 'https://mindfireinc.us1app.churnzero.net/churnzero.js'); ChurnZero.push(['setAppKey','1!0R8Ajv4tadIeSy2KOaTKQxPtiVrQl6zzVzIV2xUsse4tF3E']); 
        //console.log(ChurnZero);
        // configure axios

        if (process.env.VUE_APP_JWT == "mockup") {
            utils.log("HomePage", "debug mode");
            axios.defaults.headers.common['Authorization'] = "bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpcCI6IjQ5LjIyOC4xMDIuMTE4IiwiZW1haWwiOiJrZHV0dGFAbWluZGZpcmVpbmMuY29tIiwicHUiOnRydWUsImF1Ijp0cnVlLCJwYSI6ZmFsc2UsImFpZCI6MzQzMDcsInBhaWQiOjE3NzIzLCJpc3MiOiJpZGVudGl0eS5tZGwuaW8iLCJpYXQiOjE2NDk2NjYxMjMsImV4cCI6MTY0OTY5NDkyM30.Z8bZvKeSg1I8L31F1LQY9AeEZu8gG3jYkzLAuOUQnn5lWjJmtb8Vqm3dPRbzhQKDYxqDyduCGoJUZ1AKkPH99K9qEZjFJdLzI_AoK6SHVjaBxC8PmKsm360-mNmsffbFCMZx7DVYwAuljmjNT-hKT3g-1mORLuSajgkvxz2M8KuGoZFyFvxRVKroQLBnVNE_YBJ64TZFZaBB0gDK9bVr-4pXz_wML2rxUbj9dL7L9ZOT1ZzbySpaS8zl7zx27PGdtWJQtNMTH5DGaH5Gx0gGRQOdJBQ9Oarb7OIRqnrD-rPr00Xl6rgght5l8Bedlz8ai2Vvmly6d-CJ0iifk9xHAQ";
        }
        await this.setThePortalSettings();

        this.getHomePageData();
        this.postChurnZeroEvent();
    },
    beforeUnmount() {},
    watch: {
        account: {
            async handler(val) {
                //this.getAllData();
                this.getHomePageData();
            },
            deep: true
        },
    },
}
</script>
