<!-- 
WHAT: A component to similuate BtDashboardSettings for csDashboard.
WHO: Behzad Talebpour (btalebpour@mindfireinc.com)
BUSINESS LOGIC:
- Display a btn. Click will open a dialog with designated fields.
- Actions: Save/Update, Saveas, Delete
- Initial fields from the provided dashboard object.
- @submit, return the updated object.
- Need 1 tag
- If pa==true, can be shared with everyone (empty array). Or else, 1 paid, any aid and/or email
- aids can be empty
- paids required and can be one only (for non-admin)
HISTORY:
- V230119.1: Applied/Modified styles to display contents properly after Aref's upgrade.
- 03/23/22(B0.2): Placed sharedWith section in <v-expansion-panels>.
- 03/22/22(B0.1): 1st release (Copied from BtDashboardSharing in the helpers project, then was modified according to the business logic).
-->
<template>
<v-container fluid class="py-0 px-0">
   <v-btn dark small
      :color="btnColor"
      :block="btnBlock"
      :disabled="btnDisabled || (btnRole === 'delete' && !value.name)"
      @click="btnClicked"
   >
      <v-icon v-if="btnIconLeft"
         dark small left
      >{{btnIconLeft}}</v-icon>
      {{btnTitle}}
      <v-icon v-if="btnIconRight"
         dark small right
      >{{btnIconRight}}</v-icon>
   </v-btn>

   <v-dialog no-click-animation persistent scrollable
      max-width="480px"
      v-model="dialogSave"
   >
      <v-card>
         <v-card-title class="title grey--text darken-4 font-weight-bold pt-3 pb-0">{{myDialogTitle}}</v-card-title>
         <v-card-text class="pb-0">
            <div v-if="errMsg" class="red--text"><pre>{{errMsg}}</pre></div>
            <v-form ref="formSave" lazy-validation>
               <v-row class="pt-3 pb-2">
                  <v-col cols="12" class="py-0">
                     <v-text-field dense persistent-hint required
                        ref="newDashName"
                        :hint="newDashName ? 'Dashbaord Name' :''"
                        placeholder="enter a new name for this dashboard"
                        :disabled="isNewDashNameDisabled"
                        :rules="newDashNameRules"
                        v-model="newDashName"
                     ></v-text-field>
                  </v-col>
               </v-row>

               <v-row class="pt-2 pb-0">
                  <v-col cols="12" class="pt-0">
                     <v-autocomplete dense hide-selected multiple persistent-hint small-chips deletable-chips solo
                        class="px-0"
                        :hint="newTags.length ? 'Tags' :''"
                        label="select one or more tags"
                        :counter="tagItems.length"
                        :items="tagItems"
                        :rules="[rules.arrayRequired]"
                        v-model="newTags"
                     ></v-autocomplete>
                  </v-col>
               </v-row>

               <v-expansion-panels multiple focusable v-model="panels" class="pt-3 pb-2">
                  <v-expansion-panel readonly>
                     <v-expansion-panel-header hide-actions>
                        <strong>Share Dashboard With:</strong>
                     </v-expansion-panel-header>
                     <v-expansion-panel-content class="py-5">
                        <v-row v-if="jwt.pa" class="py-0 px-0">
                           <v-col cols="12" class="py-0">
                              <v-select dense persistent-hint hide-selected required
                                 ref="paid"
                                 class="pt-0"
                                 hint="Parent Accounts"
                                 :items="paidItems"
                                 :rules="[rules.required]"
                                 v-model="formValues.paid"
                                 @change="paidChanged"
                              ></v-select>
                           </v-col>
                           <v-col v-if="formValues.paid === 'current' || formValues.paid === 'custom'"
                              cols="12" class="py-0"
                           >
                              <v-textarea outlined persistent-hint required
                                 ref="paidTextarea"
                                 class="pt-3 pb-0 body-2"
                                 label="Specified Parent Accounts"
                                 :hide-details="false"
                                 :rows="formValues.paid === 'current' ? 1 : 2"
                                 :hint="getHint(mySharedWith.paids, 'account ID')"
                                 :disabled="formValues.paid === 'current'"
                                 :rules="[rules.required, rules.numbers, rules.duplicate]"
                                 v-model="mySharedWith.paids"
                              ></v-textarea>
                           </v-col>
                        </v-row>

                        <v-row v-if="jwt.pa" class="py-0">
                           <v-col cols="12" class="py-0">
                              <v-select dense persistent-hint hide-selected required
                                 ref="said"
                                 class="pt-0"
                                 hint="Accounts"
                                 :items="saidItems"
                                 :rules="[rules.required]"
                                 v-model="formValues.said"
                                 @change="saidChanged"
                              ></v-select>
                           </v-col>
                           <v-col v-if="formValues.said === 'current' || formValues.said === 'custom'"
                              cols="12" class="py-0"
                           >
                              <v-textarea outlined persistent-hint required
                                 ref="saidTextarea"
                                 class="pt-3 pb-0 body-2"
                                 label="Specified Accounts"
                                 :disabled="formValues.said === 'current'"
                                 :hide-details="false"
                                 :hint="getHint(mySharedWith.saids, 'account ID')"
                                 :rows="formValues.said === 'current' ? 1 : 2"
                                 :rules="[rules.required, rules.numbers, rules.duplicate]"
                                 v-model="mySharedWith.saids"
                              ></v-textarea>
                           </v-col>
                        </v-row>

                        <v-row v-else class="py-0">
                           <v-col cols="12" class="py-0">
                              <v-select dense persistent-hint hide-selected
                                 ref="aid"
                                 class="pt-0"
                                 hint="Accounts"
                                 :items="aidItems"
                                 :rules="[rules.required]"
                                 v-model="formValues.aid"
                                 @change="aidChanged"
                              ></v-select>
                           </v-col>
                           <v-col v-if="formValues.aid === 'currentP' || formValues.aid === 'currentA' || formValues.aid === 'custom'"
                              cols="12" class="py-0"
                           >
                              <v-textarea outlined persistent-hint required
                                 ref="aidTextarea"
                                 class="pt-3 pb-0 body-2"
                                 label="Specified Account"
                                 :disabled="formValues.aid==='currentP' || formValues.aid === 'currentA'"
                                 :hide-details="false"
                                 :hint="getHint(mySharedWith.aid, 'account ID')"
                                 :rows="formValues.aid==='currentP' || formValues.aid === 'currentA' ? 1 : 2"
                                 :rules="[rules.required, rules.numbers, rules.duplicate]"
                                 v-model="mySharedWith.aid"
                              ></v-textarea>
                           </v-col>
                        </v-row>

                        <v-row class="pt-1 pb-0">
                           <v-col cols="12" class="py-0">
                              <v-select dense persistent-hint hide-selected required
                                 ref="user"
                                 class="pt-0"
                                 hint="Users"
                                 :items="userItems"
                                 :rules="[rules.required]"
                                 v-model="formValues.user"
                                 @change="userChanged"
                              ></v-select>
                           </v-col>
                           <v-col v-if="formValues.user==='current' || formValues.user==='custom'"
                              cols="12" class="py-0"
                           >
                              <v-textarea outlined persistent-hint required
                                 ref="userTextarea"
                                 class="pt-3 pb-0 body-2"
                                 label="Specified Users"
                                 :disabled="formValues.user==='current'"
                                 :hide-details="false"
                                 :hint="getHint(mySharedWith.users, 'email')"
                                 :rows="formValues.user === 'current' ? 1 : 3"
                                 :rules="[rules.required, rules.emails, rules.duplicate]"
                                 v-model="mySharedWith.users"
                              ></v-textarea>
                           </v-col>
                        </v-row>
                     </v-expansion-panel-content>
                  </v-expansion-panel>
               </v-expansion-panels>
            </v-form>
         </v-card-text>

         <v-card-actions class="pt-0 pb-0 pr-3">
            <div class="flex-grow-1"></div>
            <v-btn text
               color="blue darken-1"
               class="pr-0"
               @click="closeDialogSave"
            >Cancel</v-btn>
            <!-- :disabled="!newDashName || newDashName.toLowerCase() === value.name.toLowerCase()" -->
            <v-btn text
               color="blue darken-1"
               class="ml-0 pl-2"
               @click="saveClicked"
            >Save</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>

   <v-dialog no-click-animation persistent scrollable
      max-width="480px"
      v-model="dialogDelete"
   >
      <v-card>
         <v-card-title class="title grey--text darken-4 font-weight-bold pt-3 pb-0">Deleting '{{value.name}}':
         </v-card-title>
         <v-card-text class="pb-0">
            <div v-if="errMsg" class="red--text">{{errMsg}}</div>
            <v-form ref="formDelete" lazy-validation>
               <v-row class="pt-5 pb-0">
                  <v-col cols="12" class="pb-0">
                     <v-text-field outlined required
                        class="pb-0"
                        ref="currDashName"
                        label="* Dashboard Name"
                        placeholder="enter the name of dashboard that you want to delete"
                        v-model="currDashName"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>

         <v-card-actions class="pt-0 pr-4">
            <div class="flex-grow-1"></div>
            <v-btn text
               color="blue darken-1"
               @click="closeDialogDelete"
            >Cancel</v-btn>
            <v-btn text
               color="blue darken-1"
               :disabled="!currDashName || currDashName.toLowerCase() != value.name.toLowerCase()"
               @click="deleteClicked"
            >Delete</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>

   <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
   </v-overlay>

</v-container>
</template>

<script>
const NAME = 'BtDashboardSave';
const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NUMBER_PATTERN = /^[1-9]{1}[0-9]{0,}$/;
const FORM_VALUES = {
   paid: '',
   said: '',
   aid: '',
   user: '',
};
const FORM_RULES = {
   paids: [],
   saids: [],
   users: []
}
let DEBUG;

class SharedWith {
   constructor(initVal) {
      if (!initVal)
         initVal = {};
      this.paids = _setValue(initVal.paids);
      this.saids = _setValue(initVal.aids);
      this.users = _setValue(initVal.users);
   }
}

function _setValue(val) {
   if (Array.isArray(val) && val.length > 0)
      return val.join(', ');
   else return '';
}

function _log(msg, log) {
   if (DEBUG || log) console.log(`-----${NAME} V230119.1 says => ${msg}`);
}

function _stringToArray(strVal, type) {
   const result = [];
   if (strVal) {
      strVal.split(',').forEach(sv => {
         const svTrimmed = sv.replace(/\s/g,'');
         if (type==='int')
            result.push(Number(svTrimmed));
         else
            result.push(svTrimmed);
      });
   }
   return result;
}

export default {
   name: NAME,

   props: {
      // { name: '', author: '', tags: [], sharedWith: {aids: [String], paids: [String], users: []} }
      value: {
         type: Object,
         required: true
      },
      btnBlock: {
         type: Boolean,
         default: false
      },
      btnColor: {
         type: String,
         default: "primary"
      },
      btnDisabled: {
         type: Boolean,
         default: false
      },
      btnIconLeft: {
         type: String,
         default: "save"
      },
      btnIconRight: {
         type: String,
         default: ""
      },      
      btnRole: {
         type: String,
         required: true
         // validation: save,saveas,delete
      },
      btnTitle: {
         type: String,
         default: "Save"
      },
      debug: {
         type: Boolean,
         default: false
      },      
      dialogTitle: {
         type: String,
         default: ""
      },
      submitFunc: {
         type: Function,
         required: true
      }
   },

   data() {
      return {
         panels: [0],
         selectedItem: {},
         dialogSave: false,
         mySharedWith: new SharedWith({}),
         formValues: JSON.parse(JSON.stringify(FORM_VALUES)),
         formRules: JSON.parse(JSON.stringify(FORM_RULES)),
         rules: {
            required: value => !!value || "Value is required!",
            arrayRequired: value => value.length > 0 || "Value is required!",
            numbers: value => {
               const result = [];
               const ids = _stringToArray(value);
               ids.forEach(id => { 
                  if (!NUMBER_PATTERN.test(id) && result.findIndex(r => r === `'${id}' is invalid!`) === -1) 
                     result.push(`'${id}' is invalid!`);
               });
               return result.length === 0 || result.join(', ');
            },
            emails: value => {
               const result = [];
               const emails = _stringToArray(value);
               emails.forEach(e => {
                  if (!EMAIL_PATTERN.test(e) && result.findIndex(r => r === `'${e}' is invalid!`) === -1)
                     result.push(`'${e}' is invalid!`); });
               return result.length === 0 || result.join(', ');
            },
            duplicate: value => {
               const result = [];
               const vals = _stringToArray(value);
               const uniqueVals = new Set(vals);
               uniqueVals.forEach(val => { 
                  if (vals.filter(v => v === val).length > 1) result.push(`'${val}' is duplicate!`);
               });
               return result.length === 0 || result.join(', ');
            },
            differentName: value => (!!value && value.toLowerCase() != this.value.name.toLowerCase()) || 'The new name cannot be the same as the current name!'
         },
         paidItems: [],
         saidItems: [],
         aidItems: [],
         userItems: [
            { text: 'All', value: 'all', disabled: false },
            { text: 'Current User', value: 'current'},
            { text: 'Custom...', value: 'custom'}
         ],
         errMsg: '',
         overlay: false,

         newDashName: '',
         newDashNameRules: [],
         isNewDashNameDisabled: false,
         newTags: [],
         tagItems: [
            { text: "Channel", value: "channel" },
            { text: "Event", value: "event" },
            { text: "Form", value: "form" },
            { text: "Inbound", value: "inbound" },
            { text: "Goal", value: "goal" },
            { text: "Page", value: "page" },
            { text: "Program", value: "program" },
            { text: "Outbound", value: "outbound" },
            { text: "Schedule", value: "schedule" },
            { text: "Score", value: "score" }
         ],

         dialogDelete: false,
         currDashName: ''
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      myPaid() {
         /* Was modified here
         if (isNaN(this.jwt.paid)) return this.jwt.aid;
         else return Number(this.jwt.paid);
         */
         if (this.jwt.paid) return this.jwt.paid;
         else return this.jwt.aid;
      },

      myDialogTitle() {
         if (this.dialogTitle)
            return this.dialogTitle;
         else if (this.value.name)
            return this.btnRole === 'save' ? `Save '${this.value.name}' Dashboard` : `Save '${this.value.name}' as a New Dashboard:`;
         else
            return 'Save Your New Dashboard:';
      }
   },

   watch: {
      token: {
         immediate: true,
         handler(val) {
            try {
               this.jwt = {};
               if (val) {
                  const JWT = JSON.parse(atob(val.split('.')[1]));
                  // alert('JWT=' + JSON.stringify(JWT));
                  this.jwt = {
                     aid: JWT.aid,
                     paid: JWT.paid,
                     pa: JWT.pa,
                     pu: JWT.pu,
                     email: JWT.email
                  };
                  _log(`jwt=${JSON.stringify(this.jwt)}`);
               }
            } catch (error) {
               alert(`Exception while parsing token: ${JSON.stringify(error)}`);
            }
         }
      },

      dialogSave (val) {
         if (val) {
            setTimeout(() => {
               this.initDialogSave();
            }, 10);
         } 
      },

      dialogDelete (val) {
         if (val) {
            setTimeout(() => {
               this.initDialogDelete();
            }, 10);
         } 
      }
   },

   methods: {
      btnClicked() {
         if (this.value.name) {
            switch (this.btnRole) {
               case 'save':
                  this.newDashName = this.value.name;
                  this.newDashNameRules = [];
                  this.isNewDashNameDisabled = true;
                  this.dialogSave = true;
                  break;
               case 'saveas':
                  this.newDashName = 'Copy of ' + this.value.name;
                  this.newDashNameRules = [ this.rules.required, this.rules.differentName ];
                  this.isNewDashNameDisabled = false;
                  this.dialogSave = true;
                  break;
               case 'delete':
                  this.dialogDelete = true;
                  break;
               default:
                  break;
            }
         } else {
            switch (this.btnRole) {
               case 'save':
               case 'saveas':
                  this.newDashNameRules = [ this.rules.required ];
                  this.dialogSave = true;
                  break;
               default:
                  break;
            }
         }
      },

      initDialogSave() {
         this.newTags = this.value.tags ? [...this.value.tags] : [];
         if (!this.isNewDashNameDisabled && this.$refs.newDashName)
            this.$refs.newDashName.focus();

         this.mySharedWith = new SharedWith(this.value.sharedWith);
         _log('in initDialogShare(): ' +
            '\nthis.value.sharedWith=' + JSON.stringify(this.value.sharedWith) +
            '\nsharedWith=' + JSON.stringify(this.mySharedWith));
         if (this.jwt.pa) {
            this.paidItems = [
               { text: 'All', value: 'all'},
               { text: 'Current Parent', value: 'current'},
               { text: 'Custom...', value: 'custom'}
            ];
            this.saidItems = [
               { text: 'All', value: 'all'},
               { text: 'Current Account', value: 'current'},
               { text: 'Custom...', value: 'custom'}
            ];

            this.formValues.paid = this.getSelectedValue(this.value.sharedWith.paids, this.myPaid);
            this.formValues.said = this.getSelectedValue(this.value.sharedWith.aids, this.jwt.aid);
         } else {
            this.aidItems = [
               { text: 'Current Account', value: 'currentA' }
            ];

            if (this.jwt.paid != this.myPaid) {
               this.aidItems.unshift({ text: 'Current Parent', value: 'currentP' });
            }

            this.aidItems.push({ text: 'Custom...', value: 'custom' });

            if (this.value.sharedWith.paids.length === 1 && this.value.sharedWith.aids.length > 0) {
               this.formValues.aid = 'custom';
               this.mySharedWith.aid = _setValue(this.value.sharedWith.aids);
            } else if (this.value.sharedWith.paids.length === 1) {
               this.formValues.aid = 'currentP';
               this.mySharedWith.aid = this.value.sharedWith.paids[0].toString();
            } else if (this.value.sharedWith.aids.length === 1) {
               this.formValues.aid = 'currentA';
               this.mySharedWith.aid = this.value.sharedWith.aids[0].toString();
            } else {
               this.formValues.aid = '';
               this.mySharedWith.aid = '';
            }

            let permissionChanged = false;
            if (this.formValues.aid) {
               if (this.aidItems.filter(item => item.value === this.formValues.aid).length === 0)
                  permissionChanged = true;
               else if ((this.formValues.aid === 'currentP' && this.value.sharedWith.paids != this.myPaid) ||
                     (this.formValues.aid === 'currentA' && this.value.sharedWith.aids != this.jwt.aid))
                  permissionChanged = true;
            } else if (this.value.sharedWith.paids.length > 0 || this.value.sharedWith.aids.length > 0)
               permissionChanged = true;

            if (permissionChanged) {
               this.formValues.aid = '';
               this.errMsg = 'Your permission has changed since last sharing!' +
                  '\npaids=' + _setValue(this.value.sharedWith.paids) +
                  '\naids= ' + _setValue(this.value.sharedWith.aids);
            }
         }

         this.formValues.user = this.getSelectedValue(this.value.sharedWith.users, this.jwt.email);
      },

      getSelectedValue(arrVal, val) {
         // alert('in getSelectedValue(): arrVal=' + JSON.stringify(arrVal) + ', val=' + val);
         if (!arrVal || !arrVal.length) return 'all';
         if (arrVal.length === 1 && arrVal[0] == val) return 'current';
         return 'custom';
      },

      getHint(value, name) {
         if (this.mySharedWith) {
            if (value) {
               const len = _stringToArray(value).length;
               return `${len} ${name}${len > 1 ? 's have' : ' has'} been specified.`;
            } else
               return 'separate different ' + name + 's with comma.';
         } else return '';
      },

      paidChanged(value) {
         this.saidItems[1].disabled = Boolean(value === 'current');
         if (value === 'current')
            this.mySharedWith.paids = this.myPaid.toString();
         else if (value === 'all')
            this.mySharedWith.paids = '';
         else
            this.$nextTick(() => this.$refs.paidTextarea.focus());
      },

      saidChanged(value) {
         this.paidItems[1].disabled = Boolean(value === 'current');
         if (value === 'current')
            this.mySharedWith.saids = this.jwt.aid.toString();
         else if (value === 'all')
            this.mySharedWith.saids = '';
         else
            this.$nextTick(() => this.$refs.saidTextarea.focus());
      },

      aidChanged(value) {
         if (value === 'currentP')
            this.mySharedWith.aid = this.myPaid.toString();
         else if (value === 'currentA')
            this.mySharedWith.aid = this.jwt.aid.toString();
         else {
            this.mySharedWith.aid = '';
            this.$nextTick(() => this.$refs.aidTextarea.focus());
         }
      },

      userChanged(value) {
         if (value === 'current')
            this.mySharedWith.users = this.jwt.email;
         else if (value === 'all')
            this.mySharedWith.users = '';
         else
            this.$nextTick(() => this.$refs.userTextarea.focus());
      },

      async saveClicked() {
         if (!this.$refs.formSave.validate()) return;

         _log('in saveClicked(): this.mySharedWith=' + JSON.stringify(this.mySharedWith));
         this.overlay = true;

         let sharedWith = {};
         if (this.jwt.pa) {
            sharedWith.paids = _stringToArray(this.mySharedWith.paids);
            sharedWith.aids = _stringToArray(this.mySharedWith.saids);
         } else if (this.formValues.aid === 'currentP') {
            sharedWith.paids = _stringToArray(this.mySharedWith.aid);
            sharedWith.aids = [];
         /* Changed here because paid is required for non-admin users
         } else if (this.formValues.aid === 'currentA') {
            sharedWith.paids = [];
            sharedWith.aids = _stringToArray(this.mySharedWith.aid);
         } else if (this.formValues.aid === 'custom') {
         */
         } else if (this.formValues.aid === 'currentA' || this.formValues.aid === 'custom') {
            sharedWith.paids = [this.myPaid.toString()];
            sharedWith.aids = _stringToArray(this.mySharedWith.aid);
         } else {
            sharedWith.paids = [];
            sharedWith.aids = [];
         }
         sharedWith.users = _stringToArray(this.mySharedWith.users);

         _log('in saveClicked(): sharedWith=' + JSON.stringify(sharedWith));
         const dashboard = JSON.parse(JSON.stringify(this.value));
         dashboard.name = this.newDashName;
         dashboard.tags = this.newTags;
         dashboard.sharedWith = sharedWith;
         this.errMsg = await this.submitFunc(dashboard);
         if (!this.errMsg) {
            this.$emit('input', dashboard);
            this.$emit('submit', dashboard);
            this.closeDialogSave();
         }
         this.overlay = false;
      },

      closeDialogSave () {
         if (this.$refs.formSave)
            this.$refs.formSave.resetValidation();

         this.dialogSave = false;
         setTimeout(() => {
            this.newDashName = '';
            this.isNewDashNameDisabled = false;
            this.newTags = [];
            this.mySharedWith = new SharedWith({});
            this.formValues = JSON.parse(JSON.stringify(FORM_VALUES));
            this.formRules = JSON.parse(JSON.stringify(FORM_RULES));
            this.errMsg = '';
         }, 10);
      },

      initDialogDelete () {
         if (this.$refs.currDashName)
            this.$refs.currDashName.focus();
      },

      async deleteClicked() {
         if (!this.$refs.formDelete.validate()) return;

         this.overlay = true;
         _log('in deleteClicked(): currDashName=' + this.currDashName);
         this.errMsg = await this.submitFunc(this.value);
         if (!this.errMsg) {
            this.$emit('submit', this.value);
            this.closeDialogDelete();
         }
         this.overlay = false;
      },

      closeDialogDelete () {
         if (this.$refs.formDelete)
            this.$refs.formDelete.resetValidation();

         this.dialogDelete = false;
         setTimeout(() => {
            this.currDashName = '';
            this.errMsg = '';
         }, 10);
      }
   },

   mounted() {
      DEBUG = this.debug;
   }
}
</script>

<style scoped>
   .v-textarea textarea {
      line-height: 1rem;
      padding: 8px 8px 8px 0;
   }
   .v-expansion-panel-header, .v-expansion-panel-header--active {
      padding: 0 16px;
      min-height: 40px !important;
   }
   .v-expansion-panel-content__wrap {
      padding-top: 20px;
      padding-bottom: 20px;
   }
</style>