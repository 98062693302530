<template>
<!-- 
   CHANGE HISTORY:
   V240628.1: Rolled backed all the changes from V240510 as it created lots of bugs in both Bi and contact services.
   V230824.1: Added ddDailyItems and ddMonthlyItems props to accept custom dropdown items + Added date-range-change event.
   V230508.1: [on Aref side] Consumed val param in selectChanged() and raised date-change event + Added last 90, 180 and 365 days.
   08/27/21(v1.6): Reverted all changes from V1.5.
   08/26/21(v1.5): Removed the top-level <div> and moved <v-dialog> inside <v-select>.
   08/23/21(v1.4): Copied from Helpers project + Added ddIcon and debug props + Moved _log inside methods +
      Converted isRange to computed + Changed dpMaxRange validation to be from zero (instead of 30) + Added ddCustomLabel prop +
      Moved selectValue initialization from mounted() to watch.
   03/12/20(v1.3): Added prepend-inner-icon and readonly props to v-select + Some clean-up.
-->
<div>
   <v-select dense hide-selected persistent-hint single-line
      v-model="selectValue"
      :prepend-inner-icon="ddIcon"
      :items="selectItems"
      :hint="ddCustomLabel ? selectHint : ''"
      :label="ddCustomLabel ? ddLabel : selectHint"
      :placeholder="ddPlaceholder"
      :readonly="ddReadonly"
      :error-messages="ddErrorMessage"
      @change="selectChanged()"
   >
      <template v-slot:append-item v-if="selectValue == 'CR'">
         <v-divider class="mb-0"></v-divider>
         <v-list-item ripple @click="dialog = true">
            <v-list-item-avatar>
               <v-icon>{{ddIcon}}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
               <v-list-item-title>Custom Range...</v-list-item-title>
            </v-list-item-content>
         </v-list-item>
      </template>
   </v-select>

   <v-dialog v-model="dialog" persistent no-click-animation max-width="320px">
      <!-- <template v-slot:activator="{ on }"></template> -->
      <v-card class="px-0">
         <!-- <v-card-title class="headline pb-0">{{ dpDialogTitle }}</v-card-title> -->
         <!-- <v-card-title class="headline pb-0 blue--text" color="#1976D2">{{ dateRangeText }}</v-card-title> -->

         <v-card-text class="pb-0">
            <v-form>
               <!-- <v-row justify="center" class="pb-0 mb-0">
                  <v-col
                     cols="12"
                     class="text-center font-weight-bold"
                  >
                     <span class="v-picker__title primary" width="100%"><span >Range:</span> {{ dateRangeText }}</span>
                  </v-col>
               </v-row> -->
               <v-row justify="center" class="pb-0 mb-0">
                  <v-col
                     cols="12"
                     class="text-center font-weight-bold"
                  >
                     <!-- <span class="v-picker__title primary"><span >Range:</span> {{ dateRangeText }}</span><br /> -->
                     <v-date-picker range
                        class="mt-2"
                        v-if="isRange"
                        v-model="dateRange"
                        :min="pickerMin"
                        :max="pickerMax"
                        :no-title="dpNoTitle"
                        :selected-items-text="dateRangeText"
                        :locale="dpLocale"
                     ></v-date-picker>
                     <v-date-picker v-else
                        class="mt-2"
                        v-model="date"
                        :min="pickerMin"
                        :max="pickerMax"
                        :no-title="dpNoTitle"
                        :locale="dpLocale"
                     ></v-date-picker>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>

         <v-card-actions class="pt-0 pr-4">
            <div class="flex-grow-1"></div>
            <v-btn text
               color="blue darken-1"
               @click="closeDialog"
            >Cancel</v-btn>
            <v-btn text
               color="blue darken-1"
               :disabled="isRange && dateRange.length < 2"
               @click="applyRange"
            >Apply</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
   <!-- </v-select> -->
</div>
</template>

<script>
import {
   format, parseISO, isFuture, isBefore, addSeconds, addDays, addMonths,
   startOfDay, startOfYesterday, endOfYesterday, startOfWeek, startOfMonth, endOfMonth
} from "date-fns";

const NAME = "BtDatePicker";
const isoFormat = "yyyy-MM-dd";
const displayFormat = "MM/dd/yyyy";
const minDate = format(new Date(2012, 1, 1), isoFormat);
const defaultDate = format(new Date(), isoFormat);

export default {
   name: NAME,

   props: {
      //v-model
      value: {
         type: [Array, String],
         default: () => { 
            if (typeof value === String) return null;
            else return [null, null];
         }
      },
      debug: {
         type: Boolean,
         default: false
      },

      //for dropdown (select)
      ddCustomLabel: {
         type: Boolean,
         default: false
      },
      ddDailyItems: {
         type: Array,
         default: () => [
            { text: "Today", value: "T" },
            { text: "Yesterday", value: "Y" }
         ]
      },
      ddErrorMessage: {
         type: String,
         default: ""
      },
      ddIcon: {
         type: String,
         default: "date_range"
      },
      ddLabel: {
         type: String,
         default: typeof value === String ? "Date Selector:" : "Date Range Selector:"
      },
      ddMonthlyItems: {
         type: Array,
         default: () => [
            //{ text: "Last 2 Days", value: "1" },
            //{ text: "Last 3 Days", value: "2" },
            { text: "Last 7 Days", value: "6" },
            { text: "Last 14 Days", value: "13" },
            { text: "Last 30 Days", value: "29" },
            { text: "Last 90 Days", value: "89" },
            { text: "Last 180 Days", value: "179" },
            { text: "Last 365 Days", value: "364" },
            //{ text: "This Week", value: "TW" },
            { text: "Current Month", value: "TM" },
            //{ text: "Last Week", value: "LW" },
            { text: "Last Month", value: "LM" }
         ]
      },
      ddPlaceholder: {
         type: String,
         default: ""
      },
      ddReadonly: {
         type: Boolean,
         default: false
      },
      ddValue: {
         type: String,
         default: ""
      },

      //for date-picker
      // dpDialogTitle: {
      //    type: String,
      //    default: typeof value === String ? "Select Date:" : "Select Date Range:"
      // },
      dpNoTitle: {
         type: Boolean,
         default: true
      },
      dpLocale: {
         type: String,
         default: "en-us"
      },
      dpRange: {
         type: String,
         default: "month",
         validator: value => { return ['day', 'month'].indexOf(value.toLowerCase()) !== -1 }
      },
      dpMaxRange: {
         type: Number,
         default: 30,
         validator: value => { return value >= 0 && value <= 365 }
      }
   },

   data() {
      return {
         dialog: false,
         selectValue: "",
         selectHint: "",
         dateRange: [this.getValueOrDefault(0), this.getValueOrDefault(1)],
         lastSelectValue: "",
         lastSelectedRange: [],
         // isRange: this.dpRange === "month",
         date: this.value || defaultDate,
         lastSelectedDate: ""
      };
   },

   computed: {
      isRange() {
         return this.dpRange === "month";
      },
      pickerMin() {
         if (this.isRange && this.dateRange && this.dateRange.length === 1)
            return addDays(parseISO(this.dateRange[0]), -(this.dpMaxRange - 1)).toISOString().substr(0, 10);
         else
            return minDate;
      },
      pickerMax() {
         if (this.isRange && this.dateRange && this.dateRange.length === 1) {
            const max = addDays(parseISO(this.dateRange[0]), this.dpMaxRange - 1);
            if (!isFuture(max))
               return max.toISOString().substr(0, 10);
         }
         return defaultDate;
      },
      sortedDateRange() {
         if (this.dateRange && this.dateRange.length > 1) {
            if (isBefore(parseISO(this.dateRange[0]), parseISO(this.dateRange[1])))
               return [this.dateRange[0], this.dateRange[1]];
            else
               return [this.dateRange[1], this.dateRange[0]];
         }
         return this.dateRange;
      },
      dateRangeText() {
         let out = "";
         const dateFormat = "E, MMM d";   //E, 

         if (this.isRange && this.sortedDateRange && this.sortedDateRange.length > 0) {
            out = format(parseISO(this.sortedDateRange[0]), dateFormat);
            if (this.sortedDateRange.length === 2)
               out += " ~ " + format(parseISO(this.sortedDateRange[1]), dateFormat);
         } else
            out = format(parseISO(this.date), dateFormat);

         this.log('in dateRangeText(): ' + out);
         return out;
      },
      selectItems() {
         // let items;

         // if (this.isRange)
         //    items = [
         //       //{ text: "Last 2 Days", value: "1" },
         //       //{ text: "Last 3 Days", value: "2" },
         //       { text: "Last 7 Days", value: "6" },
         //       { text: "Last 14 Days", value: "13" },
         //       { text: "Last 30 Days", value: "29" },
         //       { text: "Last 90 Days", value: "89" },
         //       { text: "Last 180 Days", value: "179" },
         //       { text: "Last 365 Days", value: "364" },
         //       //{ text: "This Week", value: "TW" },
         //       { text: "Current Month", value: "TM" },
         //       //{ text: "Last Week", value: "LW" },
         //       { text: "Last Month", value: "LM" }
         //    ];
         // else items = [
         //    { text: "Today", value: "T" },
         //    { text: "Yesterday", value: "Y" }
         // ];

         const items = this.isRange ? [...this.ddMonthlyItems] : [...this.ddDailyItems];
         return items.concat([{ text: this.isRange ? "Custom Range..." : "Custom Date...", value: "CR" }]);
      }
   },

   watch: {
      dialog(val) {
         if (val) {
            if (this.isRange)
               this.dateRange = [this.getValueOrDefault(0), this.getValueOrDefault(1)];
            else
               this.date = this.value || defaultDate;
         }
      },
      
      ddValue: {
         immediate: true,
         deep: false,
         handler(val) {
            // alert('in ddValue');
            //in v1.4, moved from mounted()
            if (val) {
               this.selectValue = val;
               this.selectChanged();
            }
         }
      },
   },

   methods: {
      log(msg) {
        if (this.debug)
         console.log(`${NAME} V240628.1 says => ${msg}`);
      },

      getValueOrDefault(index) {
         return this.value.length > 0 && this.value[index] ? this.value[index] : defaultDate;
      },

      selectChanged(val) {
         this.log(`in selectChanged(): val=${val}, selectValue=${this.selectValue}`);
         var today = new Date();
         var fd;
         var td;

         switch (this.selectValue) {
            case 'CR':
               this.dialog = true;
               return;
            case "T": // today
               fd = startOfDay(today);
               break;
            case "Y": // yesterday
               fd = startOfYesterday();
               td = endOfYesterday();
               break;
            case "TW": //from Monday till today
               fd = startOfWeek(new Date(), { weekStartsOn: 1 });
               break;
            case "TM": //from first day of this month till today
               fd = startOfMonth(today);
               break;
            case "LW": //from Monday to Sunday of previous week
               td = addSeconds(startOfWeek(today, { weekStartsOn: 1 }), -1);
               fd = startOfWeek(td, { weekStartsOn: 1 });
               break;
            case "LM": //from first to last day of previous month
               var lm = addMonths(today, -1);
               fd = startOfMonth(lm);
               td = endOfMonth(lm);
               break;
            default:
               fd = startOfDay(addDays(today, -parseInt(this.selectValue)));
               break;
         }

         if (!td) td = today;

         this.setHint(fd, td);
         this.emitRange(format(fd, isoFormat), format(td, isoFormat));
         this.lastSelectValue = this.selectValue;
         this.log("in selectChanged(): fd=" + fd + ", td=" + td);
         // if (val) this.$emit("date-change", val);
         this.$emit("date-change", this.selectValue);
      },

      applyRange() {
         this.lastSelectValue = this.selectValue;

         if (this.isRange) {
            this.setHint(parseISO(this.sortedDateRange[0]), parseISO(this.sortedDateRange[1]));
            this.emitRange(this.sortedDateRange[0], this.sortedDateRange[1]);
            this.lastSelectedRange = this.dateRange;
         } else {
            this.setHint(parseISO(this.date));
            this.emitRange(this.date);
            this.lastSelectedDate = this.date;
         }
         this.dialog = false;
      },

      closeDialog() {
         this.selectValue = this.lastSelectValue;
         this.dialog = false;
      },

      setHint(fd, td) {
         this.log('in setHint(): fd=' + fd + ', td=' + td);
         let hint = format(fd, displayFormat);

         if (this.isRange) {
            let formattedTd = format(td, displayFormat);
            if (hint != formattedTd) hint += " ~ " + formattedTd;
         }

         this.selectHint = hint;
      },

      emitRange(fd, td) {
         this.log('in emitRange(): fd=' + fd + ', td=' + td);
         if (this.isRange) {
            this.$emit("input", [fd, td]);
            this.$emit("date-range-change", [fd, td]);
         } else
            this.$emit("input", fd);
      }
   },

   mounted() {
      // this.log('ddValue=' + this.ddValue);
      // if (this.ddValue) {
      //    this.selectValue = this.ddValue;
      //    this.selectChanged();
      // }
   }
};
</script>

<style>
   .v-date-picker-title__date {
      font-size: 18px !important;
   }
</style>