<template>
   <v-container fluid class="px-3 py-3">
      <v-card>
         <v-card-title>List Service: Import</v-card-title>
         <v-card-text></v-card-text>
      </v-card>
      <import-file-upload @uploaded="onFileUpload"  />

   </v-container>
</template>

<script>
import axios from 'axios';
import ImportFileUpload from '../components/ImportFileUpload';
export default {
   components: {
      ImportFileUpload
   },
   data() {
      return {
      }
   },
   methods: {
    onFileUpload: async function(arg){
      //alert(JSON.stringify(arg));
      let postObject = {
        name: arg.fileName,
        filePath: arg.fileName,
        latitudeColumn: 'latitude',
        longitudeColumn: 'longitude',
        purlColumns: ['name'],
        dedupColumns: ['name','city','state','country'],
      }

      const postResult = await axios.post(process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/contact-service/v1/imports/' , postObject);
      alert(JSON.stringify(postResult.data));
    }
  }

}
</script>