<!-- HISTORY:
   V230921.1: 1st release (copied from AsDDLs.vue V230911.1).
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">dashboard</v-icon>
            <span>Dashboards</span>
         </h1>
      </v-card-title>

      <v-card-text>
         <v-tabs
            class="elevation-2"
            background-color="grey lighten-2 accent-4"
            slider-color="black"
            v-model="tab"
         >
            <v-tab>Totals</v-tab>
            <v-tab>List</v-tab>

            <v-tabs-items v-model="tab">
               <v-tab-item class="pt-5"><!-- Totals -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-text-field clearable
                           class="py-0"
                           append-icon="search"
                           label="Search"
                           :disabled="!totalItems.length"
                           v-model="searchTotals"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header
                     class="elevation-1"
                     :footer-props="footerPropsTotals"
                     :headers="totalHeaders"
                     :hide-default-footer="totalItems.length <= 5"
                     :items="totalItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsTotals"
                     :search="searchTotals"
                     @current-items="tableCurrItems"
                  >
                     <template v-slot:[`item.Total`]="{item}">
                        {{ formatNumber(item.Total) }}
                     </template>
                     <template v-slot:[`body.append`] v-if="totalItemsTotal">
                        <tr class="font-weight-bold">
                           <td>Totals:</td>
                           <td align="right">{{formatNumber(totalItemsTotal)}}</td>
                        </tr>
                     </template>
                  </v-data-table>
               </v-tab-item>

               <v-tab-item class="pt-5"><!-- List -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-text-field clearable
                           class="py-0"
                           append-icon="search"
                           label="Search"
                           :disabled="!listItems.length"
                           v-model="searchList"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header show-expand single-expand
                     class="elevation-1"
                     item-key="_id"
                     :footer-props="footerPropsList"
                     :headers="listHeaders"
                     :hide-default-footer="listItems.length <= 5"
                     :items="listItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="optionsList"
                     :search="searchList"
                     @update:expanded="itemExpanded"
                  >
                     <template v-slot:expanded-item="{ item }">
                        <td dense
                           colspan="3"
                           class="px-0"
                           valign="top"
                        >
                           <bt-json-viewer
                              :depth="2"
                              :data="item.details || {}"
                              :debug="debug"
                           ></bt-json-viewer>
                        </td>
                     </template>
                  </v-data-table>
               </v-tab-item>
            </v-tabs-items>
         </v-tabs>
      </v-card-text>
   </v-card>
</v-container>
</template>

<script>
import { APIService } from '../services/as-api-service.js';
import BtJsonViewer from './BtJsonViewer.vue';
// import { sleep } from '../mixins/bt-mixin.js';

const NAME = "AsDDLsForCS";
const TABS = { totals: 0, list: 1 };

export default {
   name: NAME,

   components: {
      BtJsonViewer
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         totalHeaders: [
            { text: 'Author', value: '_id', align: 'left', sortable: true },
            { text: 'Total Dashboards', value: 'Total', align: 'right', sortable: true }
         ],
         listHeaders: [
            { text: 'Name', value: 'name', align: 'left', sortable: true },
            { text: 'Author', value: 'author', align: 'left', sortable: true }
         ],
         apiService: null,
         tab: null,
         loading: false,
         searchTotals: '',
         optionsTotals: {},
         lastItemsPerPage: 10,
         footerPropsTotals: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         totalItems: [],
         totalItemsTotal: 0,
         searchList: '',
         footerPropsList: {
            itemsPerPageOptions: [5, 10, 15, 20],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         optionsList: {},
         listItems: [],
         selectedAuthor: ''
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      }
   },

   watch: {
      token() {
         this.init();
      },

      async tab(val) {
         if (val === TABS.list && this.totalItems.length) {
            await this.getListItems();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V230921.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         if (this.token) {
            this.apiService = new APIService(this.token, this.debug, this.isActualEndpoint);
            setTimeout(() => {
               this.getTotalItems();
            }, 100);
         }
      },

      async getTotalItems() {
         // alert(`in getTotalItems(): status=${this.status}\ndateRange=${JSON.stringify(this.dateRange)}`);
         this.loading = true;
         this.totalItems = [];
         let result = await this.apiService.getTotalDDLsForCS();
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.totalItems = result.data;
         }

         this.loading = false;
      },

      formatNumber(number, maxFractionDigits) {
         return new Intl.NumberFormat('en-US', 
            { 
               maximumFractionDigits: maxFractionDigits || 0
            }).format(number);
      },

      tableCurrItems(val) {
         this.totalItemsTotal = 0;
         if (this.searchTotals) {
            if (!this.lastItemsPerPage) {
               this.lastItemsPerPage = this.optionsTotals.itemsPerPage;
               this.optionsTotals.itemsPerPage = -1;
               this.footerPropsTotals.disableItemsPerPage = true;
            }

            if (val && val.length) {
               val.forEach(v => {
                  this.totalItemsTotal += v.Total;
               });
            }
         } else {
            if (this.lastItemsPerPage) {
               this.footerPropsTotals.disableItemsPerPage = false;
               this.optionsTotals.itemsPerPage = this.lastItemsPerPage;
               this.lastItemsPerPage = 0;
            }
            this.totalItems.forEach(ti => {
               this.totalItemsTotal += ti.Total;
            });
         }
      },

      async getListItems() {
         // alert(`in getListItems(): status=${this.status}\ndateRange=${JSON.stringify(this.dateRange)}`);
         this.loading = true;
         this.listItems = [];
            let result = await this.apiService.getListDDLsForCS(this.selectedAuthor, this.optionsList.page, this.optionsList.itemsPerPage);
            if (result.logout)
               this.logout();
               
            if (!result.message) {
               this.listItems = result.data;
            }

         this.loading = false;
      },

      async itemExpanded(items) {
         // _alert('in itemExpanded(): items=' + JSON.stringify(items));
         if (items.length === 0 || items[0].details) return;

         this.loading = true;
         // const date = addDate ? convertToISODate(new Date(), true) : null;
         let result = await this.apiService.getDDLForCS(items[0]._id);
         if (result.logout)
            this.logout();
         else if (!result.message) {
            items[0].details = result.data;
         }
         this.loading = false;
      },
   },

   created() {
      this.init();
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>