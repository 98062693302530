<!-- HISTORY:
   V230911.1: 1st release (copied code from BtDashboardDesigner.vue).
-->
<template>
<!-- <v-container fluid class="px-3 py-3"> -->
   <v-card flat :loading="!Boolean(data)">
      <v-card-text>
   <v-row>
      <v-col :cols="colSize" class="pt-5 pb-0 mb-0">
         <v-slider dense
            class="pt-2 mx-0 body-2"
            min="1"
            max="10"
            tick-size="10"
            thumb-label="always"
            :thumb-size="16"
            :label="`Collapsing Depth: ${deep}`"
            v-model="deep"
         >
         </v-slider>
      </v-col>
      <v-col :cols="12 - colSize" class="pt-5 mt-1">
         <v-btn block v-if="isNavigator"
            color="primary"
            :hint="copyToClipboardHint"
            @click="copyToClipboard"
         >Copy to Clipboard
            <v-icon dark class="pl-1">content_copy</v-icon>
         </v-btn>
      </v-col>
   </v-row>
   <v-row>
      <v-col :cols="colSize" class="pt-0">
         <v-card>
            <json-pretty
               path=""
               :data="data"
               :deep="deep"
               :show-length="true"
               :show-line="true"
               :show-double-quotes="false"
               :highlight-mouseover-node="true"
               :show-select-controller="true"
               :select-on-click-node="true"
               :highlight-selected-node="true"
               v-model="stringifiedData"
               @click="jsonItemClicked"
            ></json-pretty>
         </v-card>
      </v-col>
      <v-col :cols="12 - colSize" class="pt-0">
         <v-textarea auto-grow counter dense outlined persistent-hint
            ref="jsonPretty"
            class="caption"
            v-model="stringifiedData"
         ></v-textarea>
      </v-col>
   </v-row>
      </v-card-text>
   </v-card>
<!-- </v-container> -->
</template>

<script>
import JsonPretty from 'vue-json-pretty';

const NAME = "BtJsonViewer";

export default {
   name: NAME,

   components: {
      JsonPretty
   },

   props: {
      colSize: {
         type: Number,
         default: 6,
         validator: value => { return value > 0 && value < 12 }
      },

      data: {
         type: Object,
         required: true
      },

      debug: {
         type: Boolean,
         default: false
      },

      depth: {
         type: Number,
         default: 2
      }
   },

   data() {
      return {
         deep: this.depth,
         isNavigator: false,
         stringifiedData: '',
         copyToClipboardHint: ''
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      }
   },

   watch: {
      data: {
         immediate: true,
         deep: true,
         handler(val) {
            this.init();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V230911.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         this.log(`in init(): data=${this.data}`);
         if (this.data && Object.keys(this.data).length > 0)
            this.stringifiedData = JSON.stringify(this.data);
      },

      async copyToClipboard() {
         if (this.isNavigator) {
            await navigator.clipboard.writeText(this.stringifiedData);
            this.copyToClipboardHint = 'JSON content was copied to clipboard at ' + new Date().toString().split(' ')[4];
         }
      },

      jsonItemClicked(key){
         const el = this.$refs.jsonPretty.$el.querySelector("textarea");
         el.setSelectionRange(0,0);
         let sInd = 0;
         let lastKeyLen = 0;
         key.split('.').forEach(k => {
            if (k) {
               const keyParts = k.split('[');
               sInd = this.stringifiedData.indexOf(`"${keyParts[0]}":`, sInd + lastKeyLen + 1);
               lastKeyLen = keyParts[0].length;
            }
         });
         const eInd = sInd + lastKeyLen;
         setTimeout(() => {
            el.select(sInd, eInd);
            el.setSelectionRange(0,0);
            el.setSelectionRange(sInd + 1, eInd + 1);
         }, 100);
      },
   },

   created() {
      this.isNavigator = navigator.clipboard;
   }
}
</script>