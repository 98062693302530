<!-- HISTORY:
   V230913.1: 1st release (copied from AsAccounts.vue V230913.1).
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">account_tree</v-icon>
            <span>Accounts</span>
         </h1>
      </v-card-title>

      <v-card-text class="ml-2 px-5 pb-0">
         <v-form lazy-validation
            ref="mainForm"
            v-model="isMainFormValid"
         >
            <v-row>
               <v-col xs="12" sm="12" md="3" class="py-2">
                  <v-select dense hide-selected persistent-hint
                     hint="* Find Activities By"
                     :items="findByItems"
                     v-model="findBy"
                     @change="findByChanged"
                  ></v-select>
               </v-col>
               <v-col v-if="findBy"
                  xs="12" sm="12" md="3" class="pt-2"
               >
                  <v-text-field dense persistent-hint required
                     ref="findByValue"
                     class="py-0"
                     :hint="findByValueHint"
                     :placeholder="findByValuePH"
                     :rules="[rules.required]"
                     v-model="findByValue"
                     @keyup="findByValueChanged"
                  ></v-text-field>
               </v-col>
               <v-col xs="12" sm="12" md="2" class="py-5 mt-1">
                  <v-btn small
                     color="primary"
                     :disabled="!isCriteriaChanged"
                     @click="getReport()"
                  >Get Report
                     <v-icon right dark>summarize</v-icon>
                  </v-btn>
               </v-col>
            </v-row>
         </v-form>
      </v-card-text>

      <v-card-text>
         <v-tabs
            class="elevation-2"
            background-color="grey lighten-2 accent-4"
            slider-color="black"
            v-model="tab"
         >
            <v-tab>Latest Activities</v-tab>

            <v-tabs-items v-model="tab">
               <v-tab-item class="pt-5"><!-- Latest Activities -->
                  <v-data-table dense fixed-header show-expand single-expand
                     class="elevation-1"
                     item-key="_id"
                     :expanded.sync="expandedItems"
                     :footer-props="laFooter"
                     :headers="laHeaders"
                     :hide-default-footer="laCount <= 10"
                     :items="laItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="laOptions"
                     :server-items-length="laCount"
                  >
                     <template v-slot:[`item.lastLoginDate`]="{ item }">
                        {{ formatDate(item.lastLoginDate) }}
                     </template>
                     <template v-slot:expanded-item="{ item }">
                        <td>&nbsp;</td>
                        <td dense colspan="7" class="px-0 py-2" valign="top">
                           <ul>
                              <li>
                                 <span class="expanded-header">Preferences: </span>
                                 <span class="expanded-content">{{JSON.stringify(item.preferences, null, 2)}}</span>
                              </li>
                           </ul>
                           <ul>
                              <li>
                                 <span class="expanded-header">ID: </span>
                                 <span class="expanded-content">{{item._id}}</span>
                              </li>
                           </ul>
                        </td>
                     </template>
                  </v-data-table>
               </v-tab-item>
            </v-tabs-items>
         </v-tabs>
      </v-card-text>
   </v-card>
</v-container>
</template>

<script>
import { APIService } from '../services/as-api-service.js';
import { format, parseISO } from "date-fns";
// import { sleep } from '../mixins/bt-mixin.js';

const NAME = "AsIamAccounts";

export default {
   name: NAME,

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         laHeaders: [
            { text: 'Name', value: 'accountName', align: 'left', sortable: false },
            { text: 'ID', value: 'accountId', align: 'left', sortable: false },
            { text: 'Parent Name', value: 'parentName', align: 'left', sortable: false },
            { text: 'Parent ID', value: 'parentId', align: 'left', sortable: false },
            { text: 'Power User Email', value: 'powerUserEmail', align: 'left', sortable: false },
            { text: 'Last Login User', value: 'lastLoginUser', align: 'left', sortable: false },
            { text: 'Last Login Date', value: 'lastLoginDate', align: 'left', sortable: false }
         ],
         findByItems: [
            { text: 'Account', value: 'account' },
            { text: 'User', value: 'user' }
         ],
         rules: {
            required: value => this.findBy === 'account' || !!value || "Value is required!"
         },
         isMainFormValid: false,
         isCriteriaChanged: false,
         findBy: 'account',
         findByValue: '',
         findByValueHint: '',
         findByValuePH: '',
         findByValueRules: [],
         apiService: null,
         tab: null,
         loading: false,
         laFooter: {
            itemsPerPageOptions: [5, 10, 15, 20],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         laOptions: {},
         laCount: 0,
         laItems: [],
         expandedItems: []
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      }
   },

   watch: {
      token() {
         this.init();
      },

      laOptions: {
         handler() {
            this.getLaItems();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V240313.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         if (this.token) {
            this.apiService = new APIService(this.token, this.debug, this.isActualEndpoint, '/api/identity/v1');
            setTimeout(() => {
               this.findByChanged('account');
               this.getReport();
            }, 10);
         }
      },

      findByChanged(val) {
         if (val === 'account') {
            this.findByValueHint = '(Parent) Account Name/ID';
            this.findByValuePH = 'specify a full ID or partial name';
            this.findByValueRules = [];
         } else {
            this.findByValueHint = 'User';
            this.findByValuePH = 'specify a full email address';
            this.findByValueRules = [this.rules.required];
         }

         this.findByValue = '';
         this.findByValueChanged();
         setTimeout(() => {
            this.$refs.findByValue.focus();
         }, 100);
      },

      findByValueChanged() {
         this.isCriteriaChanged = this.$refs.mainForm.validate();
      },

      async getReport() {
         let $match;
         const val = this.findByValue.trim();
         if (val) {
            if (this.findBy === 'account')
               $match = { $or: [
                  { accountId: val },
                  { parentId: val },
                  { accountName: { $regex: `.*${val}.*`, $options: 'i'}},
                  { parentName: { $regex: `.*${val}.*`, $options: 'i' }}
               ]};
            else
               $match = { lastLoginUser: val };
         } else
            $match = {};

         this.aggregate = [{ $match: $match }];
         await this.getLaCount();
         if (this.laCount > 0)
            await this.getLaItems();
      },

      async getLaCount() {
         // alert(`in getLaCount(): findBy=${this.findBy}\nfindByValue=${this.findByValue}`);
         this.loading = true;
         this.laCount = 0;
         this.laItems = [];
         this.expandedItems = [];
         const aggregate = [...this.aggregate, ...[{ $count: 'count' }]];
         // alert('aggregate=' + JSON.stringify(aggregate));
         const result = await this.apiService.getIdentityAccountsCount(aggregate);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.laCount = result.data;
         }

         this.isCriteriaChanged = false;
         this.loading = false;
      },

      async getLaItems() {
         // alert(`in getLaItems(): findBy=${this.findBy}\nfindByValue=${this.findByValue}`);
         this.loading = true;
         const skip = this.laOptions.page ? (this.laOptions.page - 1) * this.laOptions.itemsPerPage : 0;
         const aggregate = [...this.aggregate, ...[
            { $sort: { lastLoginDate: -1 }},
            { $skip: skip },
            { $limit: this.laOptions.itemsPerPage }
         ]];
         // alert('aggregate=' + JSON.stringify(aggregate));
         let result = await this.apiService.getIdentityAccounts(aggregate);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.laItems = result.data;
         }

         this.loading = false;
      },

      formatDate(date) {
         if (date) {
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            return formatteddate;
         }
      }
   },

   created() {
      this.init();
   }

}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>