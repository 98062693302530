<template>
<v-container fluid>
   <!-- HISTORY:
   - 11/02/22(B0.8): In saveClicked(), set paids in the case of 'currentP' (same as 'custom') + In initDialogShare(), changed the values initiation logic accordingly.
   - 09/27/21(B0.7): Allowed regular users also to edit and delete dashboards (by removing their v-if clauses).
   - 11/17/20(B0.6): Set DEBUG value + Allowed custom subaccounts for the PU of Parent/Sub Account +
      Added logic to notify if previous shared settings are different from current user permission.
   - 09/25/20(B0.5): Removed (commented) userItems[0] (all) disability conditions.
   - 09/25/20(B0.4): Removed ', int' from _stringToArray() called to wrap ids with quotations.
   - 09/24/20(B0.3): Added debug prop + Fixed an issue with accounts dropdown items for power user of the sub-account +
      Allowed regular users also to share dashboards.
   - 09/23/20(B0.2): Fixed an issue with user role which showed two accounts dropdown.
   - 09/16/20(B0.1): 1st release (Copied from BtDashboardSharing then extended by adding Edit and Delete features).
   -->
   <v-menu open-on-hover botton offset-y close-on-click>
      <template v-slot:activator="{ on, attrs }">
         <v-btn dark
            :color="btnColor"
            :block="btnBlock"
            :disabled="btnDisabled"
            v-bind="attrs"
            v-on="on"
         >{{btnTitle}}
            <v-icon dark x-small>{{btnIcon}}</v-icon>
         </v-btn>
      </template>

      <v-list dense>
         <v-list-item v-for="(item, index) in menuItems" :key="index"
            @click="menuItemClicked(item)"
         >
            <v-list-item-icon>
               <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
               <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
         </v-list-item>
      </v-list>
   </v-menu>

   <v-dialog v-model="dialogShare" persistent no-click-animation max-width="480px" scrollable>
      <v-card>
         <v-card-title class="title grey--text darken-4 font-weight-bold pt-3 pb-0">{{dialogTitle}}</v-card-title>
         <v-card-text class="pb-0">
            <div v-if="errMsg" class="red--text"><pre>{{errMsg}}</pre></div>
            <v-form ref="formShare" lazy-validation>
               <v-row v-if="isAdmin" class="pt-0 pb-2">
                  <v-col cols="12">
                     <v-select dense persistent-hint hide-selected required
                        ref="paid"
                        class="pt-0"
                        hint="Parent Accounts"
                        v-model="formValues.paid"
                        :items="paidItems"
                        :rules="[rules.required]"
                        @change="paidChanged"
                     ></v-select>
                  </v-col>
                  <v-col v-if="formValues.paid==='current' || formValues.paid==='custom'"
                     cols="12" class="py-0"
                  >
                     <v-textarea dense persistent-hint outlined required
                        class="py-0 body-2"
                        label="Specified Parent Accounts"
                        v-model="mySharedWith.paids"
                        :hide-details="false"
                        :rows="formValues.paid==='current'?1:3"
                        :hint="getHint(mySharedWith.paids, 'account ID')"
                        :disabled="formValues.paid==='current'"
                        :rules="[rules.required, rules.numbers, rules.duplicate]"
                     ></v-textarea>
                  </v-col>
               </v-row>

               <v-row v-if="isAdmin" class="pt-0 pb-2">
                  <v-col cols="12">
                     <v-select dense persistent-hint hide-selected required
                        ref="said"
                        class="pt-0"
                        hint="Accounts"
                        v-model="formValues.said"
                        :items="saidItems"
                        :rules="[rules.required]"
                        @change="saidChanged"
                     ></v-select>
                  </v-col>
                  <v-col v-if="formValues.said==='current' || formValues.said==='custom'"
                     cols="12" class="py-0"
                  >
                     <v-textarea dense outlined required persistent-hint
                        class="py-0 body-2"
                        label="Specified Accounts"
                        v-model="mySharedWith.saids"
                        :hide-details="false"
                        :rows="formValues.said==='current' ? 1 : 3"
                        :hint="getHint(mySharedWith.saids, 'account ID')"
                        :disabled="formValues.said==='current'"
                        :rules="[rules.required, rules.numbers, rules.duplicate]"
                     ></v-textarea>
                  </v-col>
               </v-row>

               <v-row v-else class="pt-0 pb-2">
                  <v-col cols="12">
                     <v-select dense persistent-hint hide-selected
                        ref="aid"
                        class="pt-0"
                        hint="Accounts"
                        v-model="formValues.aid"
                        :items="aidItems"
                        :rules="[rules.required]"
                        @change="aidChanged"
                     ></v-select>
                  </v-col>
                  <v-col v-if="formValues.aid==='currentP' || formValues.aid==='currentA' || formValues.aid==='custom'"
                     cols="12" class="py-0"
                  >
                     <v-textarea dense outlined required persistent-hint
                        class="py-0 body-2"
                        label="Specified Accounts"
                        v-model="mySharedWith.aid"
                        :hide-details="false"
                        :rows="formValues.aid==='currentP' || formValues.aid==='currentA' ? 1 : 3"
                        :hint="getHint(mySharedWith.aid, 'account ID')"
                        :disabled="formValues.aid==='currentP' || formValues.aid==='currentA'"
                        :rules="[rules.required, rules.numbers, rules.duplicate]"
                     ></v-textarea>
                  </v-col>
               </v-row>

               <v-row class="pt-0 pb-2">
                  <v-col cols="12">
                     <v-select dense persistent-hint hide-selected required
                        ref="user"
                        class="pt-0"
                        hint="Users"
                        v-model="formValues.user"
                        :items="userItems"
                        :rules="[rules.required]"
                        @change="userChanged"
                     ></v-select>
                  </v-col>
                  <v-col v-if="formValues.user==='current' || formValues.user==='custom'"
                     class="py-0" cols="12"
                  >
                     <v-textarea dense outlined required persistent-hint
                        class="py-0 body-2"
                        label="Specified Users"
                        v-model="mySharedWith.users"
                        :hide-details="false"
                        :rows="formValues.user==='current'?1:4"
                        :hint="getHint(mySharedWith.users, 'email')"
                        :disabled="formValues.user==='current'"
                        :rules="[rules.required, rules.emails, rules.duplicate]"
                     ></v-textarea>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>

         <!-- <v-card-text v-else class="title d-flex text-center red--text pt-5">
            <div>Sorry!<br>You are not authorized to share dashboards!</div>
         </v-card-text> -->

         <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn text
               color="blue darken-1"
               @click="closeDialogShare"
            >Cancel</v-btn>
            <v-btn
               text
               color="blue darken-1"
               @click="saveClicked"
            >Save</v-btn>
         </v-card-actions>
      </v-card>

   </v-dialog>

   <v-dialog v-model="dialogEdit" persistent no-click-animation max-width="480px" scrollable>
      <v-card>
         <v-card-title class="title grey--text darken-4 font-weight-bold pt-3 pb-0">Edit '{{value.name}}' Dashboard:
         </v-card-title>
          <!-- v-if="isAdmin || isPowerUser" -->
         <v-card-text class="pb-0">
            <div v-if="errMsg" class="red--text">{{errMsg}}</div>
            <v-form ref="formEdit" lazy-validation>
               <v-row class="pt-5 pb-0">
                  <v-col cols="12">
                     <v-text-field required
                        ref="newDashName"
                        label="* Dashbaord Name"
                        placeholder="enter a new name for this dashboard"
                        :outlined="true"
                        :rules="[rules.required,rules.differentName]"
                        v-model="newDashName"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row class="pt-0 pb-2">
                  <v-col cols="12">
                     <v-autocomplete dense persistent-hint hide-selected multiple small-chips deletable-chips
                        hint="Tags"
                        v-model="newTags"
                        :counter="tagItems.length"
                        :items="tagItems"
                     ></v-autocomplete>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>

         <!-- <v-card-text v-else class="title d-flex text-center red--text pt-5">
            <div>Sorry!<br>You are not authorized to edit dashboards!</div>
         </v-card-text> -->

         <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn text
               color="blue darken-1"
               @click="closeDialogEdit"
            >Cancel</v-btn>
            <!-- v-if="isAdmin || isPowerUser" -->
            <v-btn
               text
               color="blue darken-1"
               :disabled="!newDashName || newDashName.toLowerCase() === value.name.toLowerCase()"
               @click="editClicked"
            >Update</v-btn>
         </v-card-actions>
      </v-card>

   </v-dialog>

   <v-dialog v-model="dialogDelete" persistent no-click-animation max-width="480px" scrollable>
      <v-card>
         <v-card-title class="title grey--text darken-4 font-weight-bold pt-3 pb-0">Deleting '{{value.name}}':
         </v-card-title>
         <!-- v-if="isAdmin || isPowerUser"  -->
         <v-card-text class="pb-0">
            <div v-if="errMsg" class="red--text">{{errMsg}}</div>
            <v-form ref="formDelete" lazy-validation>
               <v-row class="pt-5 pb-0">
                  <v-col cols="12">
                     <v-text-field required
                        ref="currDashName"
                        label="* Dashboard Name"
                        placeholder="enter the name of dashboard that you want to delete"
                        :outlined="true"
                        :rules="[rules.required,rules.sameName]"
                        v-model="currDashName"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>

         <!-- <v-card-text v-else class="title d-flex text-center red--text pt-5">
            <div>Sorry!<br>You are not authorized to delete dashboards!</div>
         </v-card-text> -->

         <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn text
               color="blue darken-1"
               @click="closeDialogDelete"
            >Cancel</v-btn>
            <!-- v-if="isAdmin || isPowerUser" -->
            <v-btn
               text
               color="blue darken-1"
               :disabled="!currDashName || currDashName.toLowerCase() != value.name.toLowerCase()"
               @click="deleteClicked"
            >Delete</v-btn>
         </v-card-actions>
      </v-card>

   </v-dialog>

   <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
   </v-overlay>

</v-container>
</template>

<script>
const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NUMBER_PATTERN = /^[1-9]{1}[0-9]{0,}$/;
const FORM_VALUES = {
   paid: '',
   said: '',
   aid: '',
   user: '',
};
const FORM_RULES = {
   paids: [],
   saids: [],
   users: []
}
let DEBUG;

class SharedWith {
   constructor(initVal) {
      if (!initVal)
         initVal = {};
      this.paids = _setValue(initVal.paids);
      this.saids = _setValue(initVal.aids);
      this.users = _setValue(initVal.users);
   }
}

function _setValue(val) {
   if (Array.isArray(val) && val.length > 0)
      return val.join(', ');
   else return '';
}

function _log(msg, log) {
   if (DEBUG || log) console.log(`-----BtDashboardSettings B0.8 says => ${msg}`);
}

function _stringToArray(strVal, type) {
   const result = [];
   if (strVal) {
      strVal.split(',').forEach(sv => {
         const svTrimmed = sv.replace(/\s/g,'');
         if (type==='int')
            result.push(Number(svTrimmed));
         else
            result.push(svTrimmed);
      });
   }
   return result;
}

export default {
   name: "BtDashboardSettings",

   props: {
      value: {
         type: Object,
         required: true
      },
      btnTitle: {
         type: String,
         default: ''
      },
      btnIcon: {
         type: String,
         default: "more_horiz"
      },
      btnColor: {
         type: String,
         default: "blue darken-1"
      },
      btnBlock: {
         type: Boolean,
         default: false
      },
      btnDisabled: {
         type: Boolean,
         default: false
      },
      dialogTitle: {
         type: String,
         default: "Share your dashboard:"
      },
      isPowerUser: {
         type: Boolean,
         default: false
      },
      isAdmin: {
         type: Boolean,
         default: false
      },
      paid: {
         type: [String, Number],
         required: true
      },
      said: {
         type: Number,
         required: true
      },
      author: {
         type: String,
         required: true
      },
      updateSharedWith: {
         type: Function,
         required: true
      },
      editDashboard: {
         type: Function,
         required: true
      },
      deleteDashboard: {
         type: Function,
         required: true
      },
      debug: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         menuItems: [
            { icon: 'share', title: 'Share', role: 'share'},
            { icon: 'edit', title: 'Edit', role: 'edit'},
            { icon: 'delete', title: 'Delete', role: 'delete'},
         ],
         selectedItem: {},
         dialogShare: false,
         mySharedWith: new SharedWith({}),
         formValues: JSON.parse(JSON.stringify(FORM_VALUES)),
         formRules: JSON.parse(JSON.stringify(FORM_RULES)),
         rules: {
            required: value => !!value || "Value is required!",
            numbers: value => {
               const result = [];
               const ids = _stringToArray(value);
               ids.forEach(id => { 
                  if (!NUMBER_PATTERN.test(id) && result.findIndex(r => r === `'${id}' is invalid!`) === -1) 
                     result.push(`'${id}' is invalid!`);
               });
               return result.length === 0 || result.join(', ');
            },
            emails: value => {
               const result = [];
               const emails = _stringToArray(value);
               emails.forEach(e => {
                  if (!EMAIL_PATTERN.test(e) && result.findIndex(r => r === `'${e}' is invalid!`) === -1)
                     result.push(`'${e}' is invalid!`); });
               return result.length === 0 || result.join(', ');
            },
            duplicate: value => {
               const result = [];
               const vals = _stringToArray(value);
               const uniqueVals = new Set(vals);
               uniqueVals.forEach(val => { 
                  if (vals.filter(v => v === val).length > 1) result.push(`'${val}' is duplicate!`);
               });
               return result.length === 0 || result.join(', ');
            },
            differentName: value => (!!value && value.toLowerCase() != this.value.name.toLowerCase()) || 'The new name cannot be the same as the current name!',
            sameName: value => (!!value && value.toLowerCase() === this.value.name.toLowerCase()) || 'The name should be the same as the current name!'
         },
         paidItems: [],
         saidItems: [],
         aidItems: [],
         userItems: [
            { text: 'All', value: 'all', disabled: false },
            { text: 'Current User', value: 'current'},
            { text: 'Custom...', value: 'custom'}
         ],
         errMsg: '',
         overlay: false,

         dialogEdit: false,
         newDashName: '',
         newTags: [],
         tagItems: [
            { text: "Channel", value: "channel" },
            { text: "Event", value: "event" },
            { text: "Form", value: "form" },
            { text: "Inbound", value: "inbound" },
            { text: "Goal", value: "goal" },
            { text: "Page", value: "page" },
            { text: "Program", value: "program" },
            { text: "Outbound", value: "outbound" },
            { text: "Schedule", value: "schedule" },
            { text: "Score", value: "score" }
         ],

         dialogDelete: false,
         currDashName: ''
      }
   },

   computed: {
      myPaid() {
         if (isNaN(this.paid)) return this.said;
         else return Number(this.paid);
      }
   },

   watch: {
      dialogShare (val) {
         if (val) {
            setTimeout(() => {
               this.initDialogShare();
            }, 10);
         } 
      },

      dialogEdit (val) {
         if (val) {
            setTimeout(() => {
               this.initDialogEdit();
            }, 10);
         } 
      },

      dialogDelete (val) {
         if (val) {
            setTimeout(() => {
               this.initDialogDelete();
            }, 10);
         } 
      }
   },

   methods: {
      menuItemClicked(item) {
         this.selectedItem = item;
         switch (item.role) {
            case 'share':
               this.dialogShare = true;
               break;
            case 'edit':
               this.dialogEdit = true;
               break;
            case 'delete':
               this.dialogDelete = true;
               break;
            default:
               break;
         }
      },

      initDialogShare() {
         this.mySharedWith = new SharedWith(this.value.sharedWith);
         _log('in initDialogShare(): isAdmin=' + this.isAdmin + ', isPowerUser=' + this.isPowerUser +
            ', paid=' + this.paid + ', said=' + this.said +
            '\nthis.value.sharedWith=' + JSON.stringify(this.value.sharedWith) +
            '\nsharedWith=' + JSON.stringify(this.mySharedWith));
         if (this.isAdmin) {
            this.paidItems = [
               { text: 'All', value: 'all'},
               { text: 'Current Parent', value: 'current'},
               { text: 'Custom...', value: 'custom'}
            ];
            this.saidItems = [
               { text: 'All', value: 'all'},
               { text: 'Current Account', value: 'current'},
               { text: 'Custom...', value: 'custom'}
            ];

            this.formValues.paid = this.getSelectedValue(this.value.sharedWith.paids, this.myPaid);
            this.formValues.said = this.getSelectedValue(this.value.sharedWith.aids, this.said);
         } else { //isPowerUser
            this.aidItems = [
               { text: 'Current Account', value: 'currentA' }
            ];

            if (this.paid != this.myPaid) {
               this.aidItems.unshift({ text: 'Current Parent', value: 'currentP' });
               // this.aidItems.push({ text: 'Custom...', value: 'custom' });
            }

            this.aidItems.push({ text: 'Custom...', value: 'custom' });

            /* B0.8 - start */
            // if (this.value.sharedWith.paids.length === 1 && this.value.sharedWith.aids.length > 0) {
            //    this.formValues.aid = 'custom';
            //    this.mySharedWith.aid = _setValue(this.value.sharedWith.aids);
            // } else if (this.value.sharedWith.paids.length === 1) {
            //    this.formValues.aid = 'currentP';
            //    this.mySharedWith.aid = this.value.sharedWith.paids[0].toString();
            // } else if (this.value.sharedWith.aids.length === 1) {
            //    this.formValues.aid = 'currentA';
            //    this.mySharedWith.aid = this.value.sharedWith.aids[0].toString();
            // } else {
            //    this.formValues.aid = '';
            //    this.mySharedWith.aid = '';
            // }

            if (this.value.sharedWith.aids.length === 0) {
               if (this.value.sharedWith.paids.length === 1 && this.value.sharedWith.paids[0] == this.myPaid) {
                  this.formValues.aid = 'currentP';
                  this.mySharedWith.aid = this.value.sharedWith.paids[0].toString();
               } else {
                  this.formValues.aid = '';
                  this.mySharedWith.aid = '';
               }
            } else if (this.value.sharedWith.aids.length === 1 && this.value.sharedWith.aids[0] == this.said) {
               this.formValues.aid = 'currentA';
               this.mySharedWith.aid = this.value.sharedWith.aids[0].toString();
            } else {
               this.formValues.aid = 'custom';
               this.mySharedWith.aid = _setValue(this.value.sharedWith.aids);
            }
            /* B0.8 - end */

            let permissionChanged = false;
            if (this.formValues.aid) {
               if (this.aidItems.filter(item => item.value === this.formValues.aid).length === 0)
                  permissionChanged = true;
               else if ((this.formValues.aid === 'currentP' && this.value.sharedWith.paids != this.myPaid) ||
                     (this.formValues.aid === 'currentA' && this.value.sharedWith.aids != this.said))
                  permissionChanged = true;
            } else if (this.value.sharedWith.paids.length > 0 || this.value.sharedWith.aids.length > 0)
               permissionChanged = true;

            if (permissionChanged) {
               this.formValues.aid = '';
               this.errMsg = 'Your permission has changed since last sharing!' +
                  '\npaids=' + _setValue(this.value.sharedWith.paids) +
                  '\naids= ' + _setValue(this.value.sharedWith.aids);
            }
         }

         this.formValues.user = this.getSelectedValue(this.value.sharedWith.users, this.author);
      },

      getSelectedValue(arrVal, val) {
         // alert('in getSelectedValue(): arrVal=' + JSON.stringify(arrVal) + ', val=' + val);
         if (arrVal.length === 0) return 'all';
         if (arrVal.length === 1 && arrVal[0] == val) return 'current';
         return 'custom';
      },

      getHint(value, name) {
         if (this.mySharedWith) {
            if (value) {
               const len = _stringToArray(value).length;
               return `${len} ${name}${len > 1 ? 's have' : ' has'} been specified.`;
            } else
               return 'separate different ' + name + 's with comma.';
         } else return '';
      },

      paidChanged(value) {
         this.saidItems[1].disabled = Boolean(value === 'current');
         if (value === 'current')
            this.mySharedWith.paids = this.myPaid.toString();
         else if (value === 'all')
            this.mySharedWith.paids = '';
      },

      saidChanged(value) {
         this.paidItems[1].disabled = Boolean(value === 'current');
         if (value === 'current')
            this.mySharedWith.saids = this.said.toString();
         else if (value === 'all')
            this.mySharedWith.saids = '';
      },

      aidChanged(value) {
         // this.userItems[0].disabled = Boolean(!value);
         if (value === 'currentP')
            this.mySharedWith.aid = this.myPaid.toString();
         else if (value === 'currentA')
            this.mySharedWith.aid = this.said.toString();
         else
            this.mySharedWith.aid = '';
      },

      userChanged(value) {
         if (value === 'current')
            this.mySharedWith.users = this.author;
         else if (value === 'all')
            this.mySharedWith.users = '';
      },

      async saveClicked() {
         if (!this.$refs.formShare.validate()) return;

         _log('in saveClicked(): this.mySharedWith=' + JSON.stringify(this.mySharedWith));
         this.overlay = true;
         let sharedWith = {};
         if (this.isAdmin) {
            sharedWith.paids = _stringToArray(this.mySharedWith.paids);
            sharedWith.aids = _stringToArray(this.mySharedWith.saids);
         } else if (this.formValues.aid === 'currentP') {
            sharedWith.paids = _stringToArray(this.mySharedWith.aid);
            sharedWith.aids = [];
         } else if (this.formValues.aid === 'currentA') {
            /* B0.8 - start */
            // sharedWith.paids = [];
            sharedWith.paids = [this.myPaid.toString()];
            /* B0.8 - end */
            sharedWith.aids = _stringToArray(this.mySharedWith.aid);
         } else if (this.formValues.aid === 'custom') {
            sharedWith.paids = [this.myPaid.toString()];
            sharedWith.aids = _stringToArray(this.mySharedWith.aid);
         } else {
            sharedWith.paids = [];
            sharedWith.aids = [];
         }
         sharedWith.users = _stringToArray(this.mySharedWith.users);

         _log('in saveClicked(): sharedWith=' + JSON.stringify(sharedWith));
         this.errMsg = await this.updateSharedWith(sharedWith);
         this.overlay = false;
         if (!this.errMsg) {
            const dashboard = JSON.parse(JSON.stringify(this.value));
            dashboard.sharedWith = sharedWith;
            this.$emit('input', dashboard);
            this.closeDialogShare();
         }
      },

      closeDialogShare () {
         if (this.$refs.formShare)
            this.$refs.formShare.resetValidation();
         this.dialogShare = false;
         setTimeout(() => {
            this.mySharedWith = new SharedWith({});
            this.formValues = JSON.parse(JSON.stringify(FORM_VALUES));
            this.formRules = JSON.parse(JSON.stringify(FORM_RULES));
            this.errMsg = '';
         }, 10);
      },

      initDialogEdit () {
         this.newTags = [...this.value.tags];
         if (this.$refs.newDashName)
            this.$refs.newDashName.focus();
      },

      async editClicked() {
         if (!this.$refs.formEdit.validate()) return;

         _log('in editClicked(): newDashName=' + this.newDashName + ', tags=' + this.newTags.join(', '));
         this.overlay = true;
         const dashboard = JSON.parse(JSON.stringify(this.value));
         dashboard.name = this.newDashName;
         dashboard.tags = this.newTags;
         this.errMsg = await this.editDashboard(dashboard);
         this.overlay = false;
         if (!this.errMsg) {
            this.$emit('input', dashboard);
            this.closeDialogEdit();
         }
      },

      closeDialogEdit () {
         if (this.$refs.formEdit)
            this.$refs.formEdit.resetValidation();
         this.dialogEdit = false;
         setTimeout(() => {
            this.newDashName = '';
            this.newTags = [];
            this.errMsg = '';
         }, 10);
      },

      initDialogDelete () {
         if (this.$refs.currDashName)
            this.$refs.currDashName.focus();
      },

      async deleteClicked() {
         if (!this.$refs.formDelete.validate()) return;

         _log('in deleteClicked(): currDashName=' + this.currDashName);
         this.overlay = true;
         this.errMsg = await this.deleteDashboard(this.value);
         this.overlay = false;
         if (!this.errMsg)
            this.closeDialogDelete();
      },

      closeDialogDelete () {
         if (this.$refs.formDelete)
            this.$refs.formDelete.resetValidation();
         this.dialogDelete = false;
         setTimeout(() => {
            this.currDashName = '';
            this.errMsg = '';
         }, 10);
      }
   },

   mounted() {
      DEBUG = this.debug;
   }
}
</script>

<style>
.v-textarea textarea {
    line-height: 1rem;
    padding: 8px 8px 8px 0;
}
</style>