import { render, staticRenderFns } from "./AsDeletes.vue?vue&type=template&id=30a57c15&scoped=true&"
import script from "./AsDeletes.vue?vue&type=script&lang=js&"
export * from "./AsDeletes.vue?vue&type=script&lang=js&"
import style0 from "./AsDeletes.vue?vue&type=style&index=0&id=30a57c15&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30a57c15",
  null
  
)

export default component.exports