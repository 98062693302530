<template>
    <div>
      <!-- Support type [export|trigger] -->
    <v-tooltip left>
       <template v-slot:activator="{ on }">
          <v-btn text icon
             v-on="on"
             @click="btnClicked"
          >
             <v-icon>{{btnIconName}}</v-icon>
          </v-btn>
       </template>
       <span>{{btnTooltip}}</span>
    </v-tooltip>
    <v-dialog no-click-animation persistent scrollable
       max-width="1080px"
       v-model="triggerDialog"
    >
     <v-card flat>
          <v-card-title style="position:relative;" class="title grey--text darken-4 font-weight-bold">Push message
            <!-- <v-btn @click="addPassClick" small style="display: block;position: absolute;right: 40px;top: 32px;">+</v-btn> -->
          </v-card-title>
          <v-card-text>
          </v-card-text>
      <v-card-actions class="pt-0">
         <div class="flex-grow-1"></div>
         <v-btn text small
            class="px-0"
            color="blue darken-1"
            @click="triggerDialog = false;"
         >Cancel</v-btn>
         <v-btn v-if="selected.length != 0" text small
            class="px-0 mx-0"
            color="blue darken-1"
            @click="saveClicked"
         >Save</v-btn>
      </v-card-actions>      
      </v-card>
    </v-dialog>
 
    <v-dialog no-click-animation persistent scrollable
       max-width="1080px"
       v-model="dialog"
    >
       <v-card flat>
          <v-card-title style="position:relative;" class="title grey--text darken-4 font-weight-bold">Please select {{appName}}
            <v-btn @click="addPassClick" small style="display: block;position: absolute;right: 40px;top: 32px;">+</v-btn>
          </v-card-title>
 
          <v-card-text>
             <v-row class="pt-2 mt-0">
                <v-col cols="12">
                    <v-data-table v-model="selected" :loading="passDataLoading" dense :hide-default-footer="false" :headers="passDataTable.headers" :items="passDataTable.items" :items-per-page="5"
                    @click:row="passDataTableRowClick" :single-select="true" show-select
                    sort-by="lastModified"
                    sort-desc
                    class="elevation-1">
                        <template v-slot:[`item.lastModified`]="props">
                            <div>{{ fromNow(props.item.lastModified)}}</div>
                        </template>
                        <template v-slot:[`item.action`]="props">
                           <!-- <v-tooltip v-if="readOnlyMode==false  && replayMode==false" top>
                              <template v-slot:activator="{ on , attrs}">
                           <v-icon v-bind="attrs" v-on="on" :disabled="readOnlyMode == true" v-if="props.index != 0"  class="mr-2" @click="doAction('up', props)">
                              arrow_drop_up
                           </v-icon>
                              </template>
                              <span>Move up</span>
                           </v-tooltip>
                           <v-tooltip v-if="readOnlyMode==false  && replayMode==false" top>
                              <template v-slot:activator="{ on , attrs}">
                                 <v-icon v-bind="attrs" v-on="on" :disabled="readOnlyMode == true" v-if="props.index != configItems.length - 1"  class="mr-2"
                                       @click="doAction('down', props)">
                                       arrow_drop_down
                                 </v-icon>
                              </template>
                              <span>Move down</span>                                
                           </v-tooltip>
                           <v-tooltip  v-if="props.item.error && replayMode==false" top>
                              <template v-slot:activator="{ on, attrs }">
                                 <v-icon v-bind="attrs" v-on="on" color="red" small class="mr-2"
                                       @click="doAction('edit', props)">
                                       mode
                                 </v-icon>
                              </template>
                              <span>{{ props.item.error }}</span>
                           </v-tooltip>-->
                           <v-tooltip  top>
                              <template v-slot:activator="{ on, attrs }">
                                 <v-icon  v-bind="attrs" v-on="on" :disabled="readOnlyMode == true" small class="mr-2" @click="editPassClick(props)">
                                       mode
                                 </v-icon>
                                 <!-- <v-icon  v-bind="attrs" v-on="on"  v-if="replayMode==true" small class="mr-2" @click="doAction('view', props)">
                                       remove_red_eye
                                 </v-icon> -->
                              </template>
                              <span>Edit</span>
                           </v-tooltip>
                           <v-tooltip top>
                              <template v-slot:activator="{ on , attrs}">
                                 <v-icon v-bind="attrs" v-on="on" :disabled="readOnlyMode == true" small class="mr-2" @click="deletePassClick(props)">
                                       delete
                                 </v-icon> 
                              </template>
                              <span>Remove</span>
                           </v-tooltip>
                        </template>                        
                    </v-data-table>
                </v-col>
            </v-row>
          </v-card-text>
 
          <v-card-actions class="pt-0">
             <div class="flex-grow-1"></div>
             <v-btn text small
                class="px-0"
                color="blue darken-1"
                @click="cancelClicked"
             >Cancel</v-btn>
             <v-btn v-if="selected.length != 0" text small
                class="px-0 mx-0"
                color="blue darken-1"
                @click="saveClicked"
             >Save</v-btn>
          </v-card-actions>
       </v-card>
    </v-dialog>
    <v-dialog  class="pa-2" persistent :width=1080 v-model="cardBuilderDlg">
      <v-sheet style="padding-left: 15px;padding-top: 5px;">
            <KCCardBuilderView ref="cardBuilder" :loadPassCommand=loadPassCommand :templateName=taskName :editMode=editMode :readOnlyMode=readOnlyMode @canSave="(n)=> shouldShowWalletPassSaveButton = n" :headers="headers" :sampleData="sampleData" :hideCardType=true :debugMode=false :autoLoadDefault=true></KCCardBuilderView>
            <v-row style="width:98%;margin-top:-30px;" class="justify-end">
               <v-btn color="blue darken-1" text @click="closePassClick">
                  Close
               </v-btn>
               <v-btn v-if="shouldShowWalletPassSaveButton && (readOnlyMode==false)" color="blue darken-1" text  @click="savePassClick">
                  Save
               </v-btn>
               <v-btn v-if="shouldShowWalletPassSaveButton && (readOnlyMode==true && editMode==true)" color="blue darken-1" text  @click="updateWallet()">
                  Update
               </v-btn>
            </v-row>                
      </v-sheet>        
    </v-dialog>
 </div>
</template>

<script>
 import axios from "axios";
 import utils from '../services/KCUtils.js'
 import KCCardBuilderView from '../views/KCCardBuilderView.vue';
 var crypto = require('crypto');

 const NAME = "KCWalletPassSelector";

const serverMode="pass";
let config = utils.getServerConfig(serverMode);
import csvUtils from "../services/KCCSVUtils.js";
// let davinciUrl = this.$store.getters.deployment.dvURL;
// let davinciUrl = "https://davincistage.mindfireinc.com/admin/portalLogin.php";
// let server = "prod";
// if(davinciUrl.indexOf("davincistage")>=0){
//    server = "stage";
// }       

 export default {
    name: NAME,
    components: {
        KCCardBuilderView
    },
    props: {
       value: {
          type: Object,
          default: () => {return {}}
       },
       appName: {
          type: String,
          default: "WalletPass"
       },
       btnIconName: {
          type: String,
          default: "more_vert"
       },
       btnTooltip: {
          type: String,
          default: "click to configure"
       },
       debug: {
          type: Boolean,
          default: true
       },
       isActualEndpoint: {
          type: Boolean,
          default: true
       },
       dbgAccountID: {
          type: Boolean,
          default: false             
       },
       type: {       // export or trigger
          type: String,
          default: "export"             
       },
       pullMode: {
          type: Boolean,
          default: true             
       }
    //    serverMode: {
    //         default: "pass",
    //    },
    },
 
    data() {
       return {
          davinciUrl: null,
          server: null,
          endPoint: null,
          selected: [],
          formData: {},
          dialog: false,
          triggerDialog: false,
          cardBuilderDlg: false,
          passDataTable: {
                headers: [
                    { text: "ID", value: "id", sortable: true },
                    { text: "Name", value: "name", sortable: true },
                    { text: "LastModified", value: "lastModified", sortable: true },
                    { text: "Action", value: "action", sortable: false },
                ],
                items: []
            },
          showLoadDialog: false,
          passDataLoading: false,
          shouldShowWalletPassSaveButton: true,
          readOnlyMode: false,
          editMode: false,
          headers: [],
          sampleData: [],
          taskName: "",
          loadPassCommand: null,
          // this will be override by getConfig();
          walletPassConfig: 
         //  {
         //    "version": 0,
         //    "addThisPassScanUrl":"https://addthiscard.com/?pass=2X9ZOg==@{_id}{exportId}{actionId}{purl}&a=scan{purl}&a=scan"   
         //  }
         {
            "version": 1,
            "addThisPassScanUrl":"https://addthiscard.com/?pass=${passKey}@{_id}{exportId}{actionId}{purl}&a=scan"
         }
       }
    },
 
    computed: {},
 
    watch: {},
 
    methods: {
       log() {
          if (this.debug) {
             console.log(`- ${NAME} -`,arguments);
          }
       },
 
       async btnClicked() {
         if(this.type == "export"){
            this.dialog = true;
            this.populatePass();
         }else{
            this.triggerDialog = true;
         }
       },

       async populatePass(){

            this.showLoadDialog = true;
            this.passDataLoading = true;
            var ret = await axios.post(this.endPoint, {
                cmd: "dbGetByAccount",
                acctID: this.dbgAccountID?35917:this.$store.getters.user.account,
            });            
            this.passDataLoading = false;
            this.log(ret);
            // selected
            let selectedID = null;
            if("instruction" in this.value){
               if("selected" in this.value.instruction){
                  selectedID = this.value.instruction.selected.id;
               }
            }
            // filter that table if in add this pass mode
            this.passDataTable.items = [];
            for(var x in ret.data.result){
                //console.log(ret.data.result[x]);
                if(ret.data.result[x].status == null){
                  //make sure we have passkey
                  if(ret.data.result[x].passKey != null){
                     this.passDataTable.items.push(ret.data.result[x]);   
                     if(selectedID == ret.data.result[x].id){
                        this.selected = [ret.data.result[x]];
                     }
                  }
                }
            }
       },
        fromNow(date){
            return utils.fromNow(date);
        },

        async passDataTableRowClick(param){
            this.log("passDataTableRowClick",param);
            // this.showLoadDialog = false;
        },
        addPassClick() {
            // set new task name
            this.taskName = "New Pass " + utils.UTCDateTime();
            this.cardBuilderDlg = true;
            this.$nextTick(() => {
               if(this.$refs.cardBuilder){
                  this.$refs.cardBuilder.loadByDefault();
               }
            });
        },
        closePassClick() {
            this.cardBuilderDlg = false;
        },
        async savePassClick(){
            console.log("Saving Mode");
            this.$swal.fire({
                title: 'Creating pass',
                html: `<span></span>`,
            });
            this.$swal.showLoading();
            let saveRet = await this.$refs.cardBuilder.saveClick();
            console.log(saveRet);
            
            // call JEW to register this pass
            var param = new URLSearchParams({
               cmd:'passesUpdate',
               acctID:this.$store.getters.user.account,
               progID:saveRet.data.result.insertId, //detail.passID,
               mode:"onestop",
               tagsfile:"", //csvData,
               tagsfileType: "data",  //link|data
               taskID:"",
            });
            const url = new URL(this.davinciUrl);
            url.pathname = "/admin/backend.php";
            const backendEndpoint = url.toString();
            //console.log(backendUrl)
            // if(saveRet.data.result.insertId){
            //     this.walletPassConfig.passID = saveRet.data.result.insertId;
            // }
            // // clear copy flag if have
            // delete this.walletPassConfig.copy;
            // this.walletPassConfig.templateData = this.$refs.cardBuilder.getCardData().resource.templateData;
            // this.$emit("commit",["WalletPass",this.walletPassConfig]);
            var ret = await axios.post(backendEndpoint, 
            param,{
                  maxBodyLength: 104857600, //100mb
                  maxContentLength: 104857600, //100mb
            });
            console.log(ret);
            this.$swal.close();
            // re populate the pass
            this.populatePass();
            // close dialog
            this.cardBuilderDlg = false;
        },   
        async deletePassClick(props){
            var result = await this.$swal.fire({
               title: 'Are you sure you want to remove this pass?',
               text: "You won't be able to revert this!",
               icon: 'warning',
               showCancelButton: true,
               // confirmButtonColor: '#3085d6',
               // cancelButtonColor: '#d33',
               // confirmButtonText: 'Yes',
               // cancelButtonText: 'No',
               ... csvUtils.getSwalYesNoOption()
            });
            console.log(result);
            if (result.isConfirmed) {
               let id = props.item.id;
               console.log(id,props);
               var ret = await axios.post(this.endPoint, {
                  cmd: "dbDeleteByID",
                  acctID: this.dbgAccountID?35917:this.$store.getters.user.account,
                  id: id,
               });            
               this.populatePass();
               // await this.deleteTaskStatus(Id);
               // await this.loadTaskStatus();
               //this.dialog = false;
            }   
        },
        async getConfig(){
            let csvProcessorEndpoint = utils.getServerConfig(utils.getServerMode("mindfireOne")).endPoints.mindfireOne + "/api/csvprocessor";
            var ret = await axios.get(`${csvProcessorEndpoint}?cmd=getDocument&name=walletPassConfig`);
            console.log("getConfig", ret.data);
            return ret.data.result;
        },
        async editPassClick(props){
            this.cardBuilderDlg = true;
            this.$nextTick(() => {
               this.$refs.cardBuilder.loadByID(props.item.id);
            });
        },
        getColumnsFromTemplateValue(value){
            let ret = [];    
            value.match(/\{([^}]+)\}/g).map(match => {
               // Remove the curly braces
                  console.log(match);
                  const withoutBraces = match.substring(1, match.length - 1);
                  //console.log(withoutBraces);
                  if(ret.indexOf(withoutBraces)<0){
                     ret.push(withoutBraces);
                  }
                  //return withoutBraces;
            });
            return ret;
         },        
         getBarcodeOtherFromCardData(cardData){
            let other=null;
            let columns=[];
            //let cardData = JSON.parse(ret.data.result[0].data);
            if("barcodeOther" in cardData.resource.templateData && cardData.resource.templateData.barcodeOther.enabled == true){
               // using barcode
               let bc = cardData.resource.templateData.barcodeOther;
               if(bc.chkCreateNewColumn == false && bc.columnName!=""){
                  other = `{${bc.columnName}}`;
                  columns.push(bc.columnName);
                   return {other,columns};
               }
               if(bc.chkCreateNewColumn == true && bc.value !=""){
                  other = bc.value;
                  columns = this.getColumnsFromTemplateValue(bc.value);
                  return {other,columns};
               }
            }
            return null;

        },
        async saveClicked() {
            if(this.selected.length>0){
               this.$swal.fire({
                  title: 'Checking pass',
                  html: `<span></span>`,
               });
               this.$swal.showLoading();
               let passID = this.selected[0].passKey;
               let id = this.selected[0].id;
               
               var ret = await axios.post(this.endPoint, {
                  cmd: "dbGetByID",
                  id: id,
                  acctID: this.dbgAccountID?35917:this.$store.getters.user.account,
               });  
               let passData = JSON.parse(ret.data.result[0].data);
               let otherData = this.getBarcodeOtherFromCardData(passData);
               //console.log(passData);
               //alert(`passID: ${passID}`);
               let consumerCode = "7b0ce1aec8f6c28ee79ffbc8c28ec56a";//crypto.createHash('md5').update(passID).digest("hex");
               let passUrl = this.walletPassConfig.addThisPassScanUrl.replace("${passKey}",passID);
               let columns = ['_id', 'purl'];
               if(otherData!=null){
                  passUrl = passUrl + `&O-other=${otherData.other}&O-registered=true`;
                  otherData.columns.map(x =>{
                     if(columns.indexOf(x)<0){
                        columns.push(x);
                     }
                  })
               }
               let returnObj = {
                  instruction: {
                     fieldDefinitions: [
                           //{ label: 'passQRCode', format: [`https://addthiscard.com/?pass=${passID}@{_id}{purl}&a=scan`,'_id', 'purl'], default: ''}
                           { label: 'passQRCode', format: [passUrl,...columns], default: ''}
                     ],
                     selected: this.selected[0],
                     server: this.server
                  },
                  appEndpoint: this.pullMode?" ":utils.getServerConfig(utils.getServerMode("mindfireOne")).endPoints.mindfireOne + "/api/csvprocessor2",
                  consumerCode: consumerCode,
               }
               this.$emit('change', returnObj);
               this.$emit('input', returnObj);
               this.selected = [];
               this.$swal.close();
            }
            this.dialog = false;
        },
 
       cancelClicked() {
          this.dialog = false;
       }
    },
 
    created() {
      this.davinciUrl = this.$store.getters.deployment.dvURL;
      // alert(`in created(): davinciUrl=${this.davinciUrl}`);
      if (this.davinciUrl.indexOf("davincistage") >= 0)
         this.server = "stage";
      else
         this.server = "prod";
      this.endPoint = config.endPoints.mindfireOne + `/api/card/${this.server}`;
    },
 
    async mounted() { 
      let newConfig = await this.getConfig();
      if(newConfig){
         this.walletPassConfig = newConfig;
      }
      console.log("walletPassConfig",this.walletPassConfig);
    },

   //  watch: {
   //    "type": {
   //          handler: function (newValue, oldValue) {
   //              console.log("messageData change")
   //              this.$nextTick(() => {
   //              this.$emit("canSave",this.canSave());
   //              })
   //          }
   //    },
   //  }
 }
</script>