<template>
<!-- HISTORY:
   09/07/21(B0.2): Added Preferences menu.
   08/24/20(B0.1): Changed v-navigation-drawer props with Aref.
-->
   <nav><!--style="height: 87vh" class="grey lighten-2 full-height px-0"-->
      <v-navigation-drawer permanent
         class="lighten-2 full-height px-0"
         style="background-color: #f6f6f6; height: 91vh; position:sticky; top:50px; z-index:2"
         mini-variant-width="60"
         width="220"
         :mini-variant="miniVariant"
      >
         <v-list class="py-0">
            <v-divider color="white"></v-divider>
            <v-list-item :dense="true">
               <v-list-item-icon>
                  <v-icon class="grey--text">vpn_key</v-icon>
               </v-list-item-icon>
               <v-list-item-content>
                  <v-list-item-title class="body-2 grey--text font-italic">Identity &amp; Access</v-list-item-title>
               </v-list-item-content>
            </v-list-item>
         </v-list>

         <v-divider calss="my-2" dark></v-divider>

         <v-list class="py-0" flat>
            <!-- <v-divider color="white"></v-divider> -->
            <v-list-item v-for="item in items" :key="item.title" link :to="getLink(item.route)">
               <v-list-item-icon>
                  <v-icon >{{ item.icon }}</v-icon>
               </v-list-item-icon>
               <v-list-item-content>
                  <v-list-item-title class="caption font-weight-bold" >{{ item.title }}</v-list-item-title>
               </v-list-item-content>
            </v-list-item>
            <v-divider color="white"></v-divider>
            <v-list-item @click="toggleMenu()">
               <v-list-item-icon>
                  <v-icon>more_horiz</v-icon>
               </v-list-item-icon>
            </v-list-item>
         </v-list>
      </v-navigation-drawer>
   </nav>
</template>

<script>
import { IamSettings } from '../models/iam-settings.js';

export default {
   // props: [ 'baseRoute' ],
   props: {
      baseRoute: {
         type: String,
         required: true
      },
      debug: {
         type: Boolean,
         default: false
      },
      miniVariant: {
         type: Boolean,
         default: false
      }
   },
   data() {
      return {
         // drawer: true,
         items: [
            { icon: 'perm_identity', title: 'Identities', route: 'identities' },
            { icon: 'folder', title: 'Groups', route: 'groups' },
            { icon: 'policy', title: 'Policies', route: 'policies' },
            { icon: 'supervised_user_circle', title: 'Impersonation', route: 'impersonation' },
            { icon: 'settings', title: 'Settings', route: 'settings' },
            { icon: 'room_preferences', title: 'Preferences', route: 'preferences' }
         ],
         settings: new IamSettings(this.debug),
         myStyle: 'background-color: #f6f6f6; height: 91vh; overflow-y:unset; position:sticky; top:0'
      }
   },
   computed: { },
   watch: { },
   methods: {
      getLink(route) {
         return "/" + this.baseRoute + "/" + route;
      },
      toggleMenu() {
         this.$emit('more-click');
      },
      onScroll(e) {
         this.myStyle = 'background-color: #f6f6f6; height: 91vh; overflow-y:unset; position:sticky; top:' + e.target.scrollTop + 'px';
         alert(this.myStyle);
      }
   }
}
</script>

<style scope>
   .v-list .v-list-item--active {
      color:#ff6633;
      background: #f6f6f6;
  }
</style>