import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
//import Dashboard from './views/Dashboard.vue'
//import Projects from './views/Projects.vue'
//import Team from './views/Team.vue'
//import LogIn from './views/LogIn.vue'
//import Downloads from './views/Downloads.vue'
//import BI from './views/BI.vue'
import IAM from "./views/Iam.vue";
import IamPolicies from "./components/IamPolicies.vue";
import IamGroups from "./components/IamGroups.vue";
import IamIdentities from "./components/IamIdentities.vue";
import IamSettings from "./components/IamSettings.vue";
import IamImpersonation from "./components/IamImpersonation.vue";
import IamPreferences from "./components/IamPreferences.vue";

import ContactService from "./views/ContactService.vue";
import CsConfig from "./components/CsConfig.vue";
import CsImport from "./components/CsImports.vue";
import CsExport from "./components/CsExports.vue";
import CsIndex from "./components/CsIndexes.vue";
import CsDashboards from "./components/CsDashboards.vue";
import CsDocuments from "./components/CsDocuments.vue";
import CsDeletes from "./components/CsDeletes.vue";
import CsRemoteAccesses from "./components/CsRemoteAccesses.vue";
import CsSettings from "./components/CsSettings.vue";
import CsActions from "./components/CsActions.vue";
import CsTriggers from "./components/CsTriggers";
import CsTags from "./components/CsTags.vue";

import AdminService from "./views/AdminService.vue";
import AsImports from "./components/AsImports.vue";
import AsExports from "./components/AsExports.vue";
import AsDeletes from "./components/AsDeletes.vue";
import AsSchedules from "./components/AsSchedules.vue";
import AsRDLs from "./components/AsRDLs.vue";
import AsDDLs from "./components/AsDDLs.vue";
import AsDownloads from "./components/AsDownloads.vue";
import AsAccounts from "./components/AsAccounts.vue";
import AsRemoteAccesses from "./components/AsRemoteAccesses.vue";
import AsRDLsForCS from "./components/AsRDLsForCS.vue";
import AsDDLsForCS from "./components/AsDDLsForCS.vue";
import AsSettings from "./components/AsSettings.vue";
import AsApiCalls from "./components/AsApiCalls.vue";
import AsIamAccounts from "./components/AsIamAccounts.vue";

Vue.use(Router)

function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

//console.log('user: ', store.getters.user);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: lazyLoad('KCHomePage')
    },
    {
      path: '/dashboards',
      name: 'dashboards',
      component: lazyLoad('Dashboard')
    },
    {
      path: '/csv-processor',
      name: 'csv-processor',
      component: lazyLoad('KCCSVProcessorView')
    },
    // {
    //   path: '/bi',
    //   name: 'bi',
    //   component: lazyLoad('BI')
    // },
    {
      path: '/projects',
      name: 'projects',
      component: lazyLoad('Projects')
    },
    {
      path: '/team',
      name: 'team',
      component: lazyLoad('Team')
    },
    {
      path: '/',
      name: 'login',
      component: lazyLoad('LogIn')
    },
    {
      path: '/downloads',
      name: 'downloads',
      component: lazyLoad('Downloads')
    },
    {
      path: '/module/:id',
      name: 'module',
      component: lazyLoad('IFrame'),
      props: true
    },
    {
      path: '/iam',
      component: IAM,
      name: 'iam',
      children: [
        {
          path: '',
          name: 'iamHome',
          component: IamPolicies
        },
        {
          path: 'policies',
          name: 'Policies',
          component: IamPolicies
        },
        {
          path: 'groups',
          name: 'Groups',
          component: IamGroups
        },
        {
          // UserPosts will be rendered inside User's <router-view> when /user/:id/posts is matched
          path: 'identities',
          name: 'identities',
          component: IamIdentities
        },
        {
          path: 'impersonation',
          name: 'impersonation',
          component: IamImpersonation
        },
        {
          path: 'settings',
          name: 'settings',
          component: IamSettings
        },
        {
          path: 'preferences',
          name: 'preferences',
          component: IamPreferences
       }
      ]
    },
    {
      path: '/contact-service',
      component: ContactService,
      children: [
         {
            path: '',
            name: 'csHome',
            component: CsDashboards
         },
        //  {
        //     path: 'config',
        //     name: 'csConfig',
        //     component: CsConfig
        //  },
         {
            path: 'imports',
            name: 'csImport',
            component: CsImport
         },
         {
            path: 'actions',
            name: 'csActions',
            component: CsActions
         },
         {
            path: 'triggers',
            name: 'csTriggers',
            component: CsTriggers
         },
         {
            path: 'exports',
            name: 'csExport',
            component: CsExport
         },
		 {
		    path: 'tags',
		    name: 'csTags',
		    component: CsTags
		 },
         {
            path: 'indexes',
            name: 'CsIndex',
            component: CsIndex
         },
         {
            path: 'dashboards',
            name: 'CsDashboards',
            component: CsDashboards
         },
         {
            path: 'documents',
            name: 'csDocuments',
            component: CsDocuments
         },
         {
            path: 'deletes',
            name: 'csDeletes',
            component: CsDeletes
        },
        {
            path: 'access',
            name: 'CsRemoteAccesses',
            component: CsRemoteAccesses
        },
        {
            path: 'settings',
            name: 'CsSettings',
            component: CsSettings
        }
      ]
    },
    {
      path: '/admin-service',
      component: AdminService,
      children: [
		 {
			path: '',
			name: 'asHome',
			component: AsImports
		 },
		 {
			path: 'imports',
			name: 'asImports',
			component: AsImports
		 },
		 {
			path: 'exports',
			name: 'asExports',
			component: AsExports
		 },
		 {
		    path: 'deletes',
		    name: 'asDeletes',
		    component: AsDeletes
		 },
         {
            path: 'schedules',
            name: 'asSchedules',
            component: AsSchedules
         },
		 {
		   path: 'rdls',
		   name: 'asRDLs',
		   component: AsRDLs
		 },
		 {
		   path: 'ddls',
		   name: 'asDDLs',
		   component: AsDDLs
		 },
         {
            path: 'downloads',
            name: 'asDownloads',
            component: AsDownloads
         },
		 {
		   path: 'accounts',
		   name: 'asAccounts',
		   component: AsAccounts
		 },
         {
            path: 'remote-accesses',
            name: 'asRemoteAccesses',
            component: AsRemoteAccesses
         },
         {
            path: 'cs-ddls',
            name: 'asDDLsForCS',
            component: AsDDLsForCS
         },
         {
            path: 'cs-rdls',
            name: 'ssRDLsForCS',
            component: AsRDLsForCS
         },
         {
            path: 'settings',
            name: 'asSettings',
            component: AsSettings
         },
		 {
		    path: 'api-calls',
		    name: 'asApiCalls',
		    component: AsApiCalls
		 },
		 {
			path: 'iam-accounts',
			name: 'asIamAccounts',
			component: AsIamAccounts
		 }
      ]
   }
 ]
})
