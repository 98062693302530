/*jshint esversion: 8*/
// HISTORY:
// V221011.1: In createIdentity() and updateIdentity(), commented hasApiAccess.
// 08/13/20(B0.6): Modified add/update/delete credentials methods + Added body (description).
// 05/02/22(B0.5): Implemented logout feature.
// 08/13/20(B0.4): Fixed add/update/delete credentials methods.
// 08/05/20(B0.3): Passed debug and isActualEndpoint to the constructor + Modified how aid t be set in addIdentity().

import axios from 'axios';
import { Policy } from '../models/iam-policy';
import { Group } from '../models/iam-group';

let _debug;

function _log(msg) {
   if (_debug)
      console.log(`-----iam-api-service V221011.1 says => ${msg}`);
}

function _initResult(data, message) {
   return {
      data: data || null,
      message: message || null,
      logout: false
   };
}

function _parseError(error, displayError =true) {
   _log(`in _parseError(): error=${JSON.stringify(error)}`);
   const result = _initResult();
   if (error.response) {
      if (error.response.status === 401)  //unauthorized
         result.logout = true;
      else {
         // if (error.response.status === 404)  //not found
         //    result.data = data;
         // if (result.data === null)
            result.message = `${JSON.stringify(error.response.data)} (status=${error.response.status})`;
      }
   } else if (error.request)
      result.message = JSON.stringify(error.request); //error.request.data + ' (' + error.request.status + ')';
   else
      result.message = error.message;

   if (displayError && result.message)
      alert(result.message);

   return result;
}

export class APIService {

   constructor(jwt, token, debug, isActualEndpoint) {
      // alert('in APIService: token=' + token);
      _debug = debug;
      this.jwt = jwt;
      this.isActualEndpoint = isActualEndpoint;
      this.$http = axios.create({
         baseURL: process.env.VUE_APP_IDENTITY_SERVICE_ENDPOINT + (isActualEndpoint ? '/api/identity/v1' : ''),
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
         }
      });
   }

   /********************************/
   /*           POLICIES           */
   /********************************/

   async getPolicies() {
      _log('getPolicies() started...');
      let result;// = _initResult();

      try {
         let url = '/policies';
         if (!this.isActualEndpoint && !this.jwt.pa) {
            url += '?isAdmin=false';
         }

         let response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         // result.data = response.data;
         result = _initResult(response.data);
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async createPolicy(policy) {
      _log('createPolicy(): policy=' + JSON.stringify(policy));
      let result;// = _initResult();

      try {
         // delete policy._id;
         if (!this.isActualEndpoint) {
            policy.ownerPaid = '*';
         }

         const response = await this.$http.post('/policies', policy);
         _log(`response=${JSON.stringify(response)}`);
         // result.data = response.data;
         result = _initResult(response.data);
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async updatePolicy(policy) {
      _log('updatePolicy(): policy=' + JSON.stringify(policy));
      let result;// = _initResult();

      try {
         const policy2Update = new Policy(policy.name, policy.isAdmin, policy.actions, policy.conditions, policy.conditionLogic);
         const response = await this.$http.put(`/policies/${policy._id}`, policy2Update);
         _log(`response=${JSON.stringify(response)}`);
         // result.data = response.data;
         result = _initResult(response.data);
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async deletePolicy(policy, displayError) {
      _log('in deletePolicy(): policy=' + JSON.stringify(policy));
      let result;// = _initResult();

      try {
         var response = await this.$http.delete(`/policies/${policy._id}`);
         _log(`response for ${policy.name}=${JSON.stringify(response)}`);
         result = _initResult();
         result.data = await response.data != {} || policy;
      } catch (error) {
         result = _parseError(error, displayError);
      }

      return result;
   }

   async deletePolicies(policies) {
      _log(`in deletePolicies(): policies2delete=${JSON.stringify(policies)}`);
      const promises = policies.map(async policy => {
         const promise = await this.deletePolicy(policy, false);
         return promise;
      });

      return Promise.all(promises);
    }

   /********************************/
   /*             GROUPS           */
   /********************************/

    async getGroups() {
      _log('getGroups() started');
      let result;// = _initResult();

      try {
         let url = '/groups';
         if (!this.isActualEndpoint && !this.jwt.pa) {
            url += '?isAdmin=false';
         }
         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         // result.data = response.data;
         result = _initResult(response.data);
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async createGroup(group) {
      _log('in createGroup(): group=' + JSON.stringify(group));
      let result;// = _initResult();

      try {
         let groupToCreate;
         if (this.isActualEndpoint) {
            groupToCreate = new Group(group.name, group.isAdmin, group.policies.map(p => p._id));
         } else {
            groupToCreate = new Group(group.name, group.isAdmin, group.policies);
            groupToCreate.ownerPaid = this.jwt.pa ? '*' : this.jwt.paid || this.jwt.aid; //only parent account can create groups
         }

         const response = await this.$http.post('/groups', groupToCreate);
         _log(`response=${JSON.stringify(response)}`);
         // result.data = response.data;
         result = _initResult(response.data);
         result.data.policies = group.policies;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async updateGroup(group) {
      _log('in updateGroup(): group='+JSON.stringify(group));
      let result;// = _initResult(group);

      try {
         let groupToUpdate;
         if (this.isActualEndpoint) {
            const policyIds = [];
            group.policies.forEach(p => { policyIds.push(p._id); });
            groupToUpdate = new Group(group.name, group.isAdmin, policyIds);
         } else {
            groupToUpdate = new Group(group.name, group.isAdmin, group.policies);
            groupToUpdate.ownerPaid = group.ownerPaid;
         }

         const response = await this.$http.put(`/groups/${group._id}`, groupToUpdate);
         _log(`response=${JSON.stringify(response)}`);
         // result.data = response.data;
         result = _initResult(response.data);
         result.data.policies = group.policies;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async deleteGroup(group, displayError) {
      _log('in deletePolicy(): group=' + JSON.stringify(group));
      let result;// = _initResult();

      try {
         var response = await this.$http.delete(`/groups/${group._id}`);
         _log(`response for ${group.name}=${JSON.stringify(response)}`);
         result = _initResult();
         result.data = await response.data != {} || group;
      } catch (error) {
         result = _parseError(error, displayError);
      }

      return result;
   }

   async deleteGroups(groups) {
      _log(`in deleteGroups(): groups=${JSON.stringify(groups)}`);
      const promises = groups.map(async group => {
         const promise = await this.deleteGroup(group, false);
         return promise;
      });

      return Promise.all(promises);
   }

   async addPoliciesToGroups(groups, policies) {
      // _log(`in addPoliciesToGroups(): groups=${JSON.stringify(groups)}, policies=${JSON.stringify(policies)}`);
      _log(`in addPoliciesToGroups(): groups.len=${groups.length}, policies.len=${policies.length}`);
      const promises = groups.map(async group => {
         let legitPolicies = [];

         policies.forEach(policy => {
            if (!group.policies.find(p => p._id === policy._id)) {
               legitPolicies.push(policy);
            }
         });

         let promise;
         if (legitPolicies.length > 0) {
            Array.prototype.push.apply(group.policies, legitPolicies);
            _log('new group: ' + JSON.stringify(group));
            promise = await this.updateGroup(group, false);
         } else {
            promise = _initResult(group, `already has all selected policies.`);
         }
         return promise;
      });

      _log('addPoliciesToGroups() ended');
      return Promise.all(promises);
   }

   /********************************/
   /*           IDENTITIES         */
   /********************************/

   async getIdentities() {
      _log('getIdentities() started');
      let result;// = _initResult();

      try {
         const response = await this.$http.get('/identities');
         _log(`response=${JSON.stringify(response)}`);
         // result.data = response.data;
         result = _initResult(response.data);
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async createIdentity(identity) {
      _log('in createIdentity(): identity=' + JSON.stringify(identity));
      let result;// = _initResult();

      try {
         //delete identity._id;
         let newIdentity;
         if (this.isActualEndpoint) {
            newIdentity = {
               email: identity.email,
               groups: identity.groups.map(g => g._id),
               accessToAllSubAccounts: identity.accessToAllSubAccounts,
               // hasApiAccess: identity.hasApiAccess
            };
            if (identity.userName) newIdentity.userName = identity.userName;
            if (identity.cidr) newIdentity.cidr = identity.cidr;
            if (identity.hours && identity.hours.length > 0) newIdentity.hours = identity.hours;
            if (identity.hoursEffect) newIdentity.hoursEffect = identity.hoursEffect;
            if (identity.days && identity.days.length > 0) newIdentity.days = identity.days;
            if (identity.daysEffect) newIdentity.daysEffect = identity.daysEffect;
            if (identity.userFilter) newIdentity.userFilter = identity.userFilter;
         } else  {
            newIdentity = identity;
            if (this.jwt.pa) newIdentity.aid = '*';
            else newIdentity.aid = this.jwt.aid;
         }

         const response = await this.$http.post('/Identities', newIdentity);
         _log(`response=${JSON.stringify(response)}`);
         // result.data = response.data;
         result = _initResult(response.data);
         result.data.groups = identity.groups;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async updateIdentity(identity) {
      _log('in updateIdentity(): identity='+JSON.stringify(identity));
      let result;// = _initResult(identity);

      try {
         let newIdentity;
         if (this.isActualEndpoint) {
            newIdentity = {
               email: identity.email,
               groups: identity.groups.map(g => g._id),
               accessToAllSubAccounts: identity.accessToAllSubAccounts,
               // hasApiAccess: identity.hasApiAccess
            };
            if (identity.userName) newIdentity.userName = identity.userName;
            if (identity.cidr) newIdentity.cidr = identity.cidr;
            if (identity.hours && identity.hours.length > 0) newIdentity.hours = identity.hours;
            if (identity.hoursEffect) newIdentity.hoursEffect = identity.hoursEffect;
            if (identity.days && identity.days.length > 0) newIdentity.days = identity.days;
            if (identity.daysEffect) newIdentity.daysEffect = identity.daysEffect;
            if (identity.userFilter) newIdentity.userFilter = identity.userFilter;

         } else  {
            newIdentity = identity;
         }

         const response = await this.$http.put(`/Identities/${identity._id}`, newIdentity);
         _log(`response=${JSON.stringify(response)}`);
         result = _initResult();
         result.data = response.data || identity;
         result.data.groups = identity.groups;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async deleteIdentity(identity, displayError) {
      _log('in deleteIdentity(): identity=' + JSON.stringify(identity));
      let result;// = _initResult();
      // result.data = identity;

      try {
         var response = await this.$http.delete(`/Identities/${identity._id}`);
         _log(`response for ${identity.email}=${JSON.stringify(response)}`);
         result = _initResult(identity);
      } catch (error) {
         result = _parseError(error, displayError);
      }

      return result;
   }

   async deleteIdentities(identities) {
      _log(`in deleteIdentities(): identities=${JSON.stringify(identities)}`);
      const promises = identities.map(async identity => {
         const promise = await this.deleteIdentity(identity, false);
         return promise;
      });

      return Promise.all(promises);
   }

   async addGroupsToIdentities(identities, groups) {
      _log(`in addGroupsToIdentities(): identities=${JSON.stringify(identities)}\n\tgroups=${JSON.stringify(groups)}`);
      const promises = identities.map(async identity => {
         let legitGroups = [];

         groups.forEach(group => {
            if (!identity.groups.find(g => g._id === group._id)) {
               legitGroups.push(group);
            }
         });

         let promise;
         if (legitGroups.length > 0) {
            Array.prototype.push.apply(identity.groups, legitGroups);
            promise = await this.updateIdentity(identity, false);
         } else {
            promise = _initResult(identity, `already has all selected groups.`);
         }
         return promise;
      });

      _log('addGroupsToIdentities() ended');
      return Promise.all(promises);
   }

   async addCredential(identity, cred) {
      if (this.isActualEndpoint) {
         const result = await this.updateCredential(
            identity,
            'addapi',
            0,
            null
         );
         return result;
      } else {
         identity.apiCredentials.push(cred);
         // identity.hasApiAccess = true;
         const result = await this.updateIdentity(identity);
         // alert('result=' + JSON.stringify(result));
         return result;
      }
   }

   async editCredential(identity, cred, isDescChanged) {
      if (this.isActualEndpoint) {
         const result = await this.updateCredential(
            identity,
            cred.isActive ? 'reactiveapi' : 'inactiveapi',
            cred._id,
            isDescChanged ? cred.description : null
         );
         // if (!result.logout && !result.message)
         //    cred.isActive = !cred.isActive;
         return result;
      } else {
         // cred.isActive = !cred.isActive;
         const currCred = identity.apiCredentials.find(c => c._id === cred._id);
         currCred.isActive = cred.isActive;
         currCred.description = cred.description;
         return await this.updateIdentity(identity);
      }
   }

   async deleteCredential(identity, cred) {
      if (this.isActualEndpoint) {
         const result = await this.updateCredential(
            identity,
            'deleteapi',
            cred._id,
            null
         );
         return result;
      } else {
         identity.apiCredentials.splice(identity.apiCredentials.indexOf(cred), 1);
         // identity.hasApiAccess = identity.apiCredentials.length > 0;
         return await this.updateIdentity(identity);
      }
   }

   async updateCredential(identity, action, actionId, desc) {
      _log(`in updateCredential(): identity=${JSON.stringify(identity)}\n\taction=${action}\n\tactionId=${actionId}\n\tdesc=${desc}`);
      let result;// = _initResult();

      try {
         var url = `/Identities/${identity._id}/${action}/${actionId}`;
         // var response = await this.$http.put(url);
         let response;
         if (desc === null)
            response = await this.$http.put(url);
         else
            response = await this.$http.put(url, { description: desc });

         _log(`response for ${action}/${actionId}=${JSON.stringify(response)}`);
         identity.apiCredentials = await response.data.apiCredentials;
         // identity.hasApiAccess = identity.apiCredentials.filter(cred => cred.isActive).length > 0;
         // result.data = identity;
         result = _initResult(identity);
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*        IMPERSONATION         */
   /********************************/

   async getImpersonationUrl(ip, expiry, policies) {
      _log(`in getImpersonationUrl(): ip=${ip}, expiry=${expiry}, policies=${JSON.stringify(policies)}`);
      let result;// = _initResult();

      try {
         if (this.isActualEndpoint) {
            let url = `/login/${ip}/${expiry*60}`;
            if (policies.length > 0)
               url += `/${policies.join(',')}`;

            _log(`in getImpersonationUrl(): url=${url}`);

            const response = await this.$http.get(url);
            _log(`response=${JSON.stringify(response)}`);
            // result.data = response.data;
            result = _initResult(response.data);
         } else  {
            // result.data = 'a url';
            result = _initResult({token: 'a url'});
         }
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }


   // async initial(identity, cred, action) {
   //    _log(`in updateCredential(): identity._id=${identity._id}, cred._id=${cred._id}, action=${action}`);
   //    let result = _initResult(cred);

   //    try {
   //       var url = `/Identities/${identity._id}/${action}` + (cred._id ? `/${cred._id}` : '');
   //       var response = await this.$http.put(url, cred);
   //       _log(`response for ${action}/${cred._id}=${JSON.stringify(response)}`);
   //       //result.data = await response.data != {} || identity;
   //    } catch (error) {
   //       result.message = _parseError(error);
   //    } finally {
   //       //_log('in deleteIdentity() finally');
   //    }

   //    return result;
   // }

}