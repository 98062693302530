// Support {=expression}
import utils from "./KCUtils.js";
export default {
    getSwalYesNoOption() {
        return {
            confirmButtonColor: '#FF6633',
            cancelButtonColor: 'grey',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }
    },
    getColumnIndex(header,columnName,ignoreCase = false){
        if(ignoreCase){
            columnName = columnName.toLowerCase();
        }
        for(var x in header){
            let h = header[x];
            if(ignoreCase){
                h = h.toLowerCase();
            }
            if(h == columnName){
                return x;
            }
        }
        return -1;
    },
    createSubsitiueCommand(str,header){
        //console.log("createSubsitiueCommand",str,header);
        var ret = [];
        const regex = /\{([^}]+)\}/gm;
        let m;
        var start = 0;
        while ((m = regex.exec(str)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            //console.log(m);
            if(m.index != start){
                ret.push({
                    cmd: "keep",
                    value: str.slice(start,m.index),
                })
            }
            // support = syntax
            //console.log(m[0]);
            if(m[1].startsWith("=")){
                // =substr(colname,1,2)
                ret.push({
                    cmd: "eval",
                    value: m[1].substring(1),
                    header: header,
                    //index: getColumnIndex(header,m[1],true), //header.indexOf(m[1]),
                })
            }else{
                ret.push({
                    cmd: "replace",
                    value: m[0],
                    index: this.getColumnIndex(header,m[1],true), //header.indexOf(m[1]),
                })
            }
            start = m.index+m[0].length;
        }
        if(start < str.length){
            ret.push({
                cmd: "keep",
                value: str.slice(start),
            })
        }
        //console.log(ret);
        return ret;
    },
    // // support ${x}   x-> column index start from 0
    // createSubsitiueCommand(str,header){
    //     var ret = [];
    //     const regex = /\{([^}]+)\}/gm;
    //     let m;
    //     var start = 0;
    //     while ((m = regex.exec(str)) !== null) {
    //         // This is necessary to avoid infinite loops with zero-width matches
    //         if (m.index === regex.lastIndex) {
    //             regex.lastIndex++;
    //         }
    //         //console.log(m);
    //         if(m.index != start){
    //             ret.push({
    //                 cmd: "keep",
    //                 value: str.slice(start,m.index),
    //             })
    //         }
    //         ret.push({
    //             cmd: "replace",
    //             value: m[0],
    //             index: this.getColumnIndex(header,m[1],true),
    //         })
    //         start = m.index+m[0].length;
    //     }
    //     if(start < str.length){
    //         ret.push({
    //             cmd: "keep",
    //             value: str.slice(start),
    //         })
    //     }
    //     //console.log(ret);
    //     return ret;
    // },
    subsituteValue(row, cmd) {
        var ret = "";
        for(var i =0;i<cmd.length;i++){
            if(cmd[i].cmd == "keep"){
                ret += cmd[i].value;
            } else if(cmd[i].cmd == "eval"){
                let calcv = this.calculatedValueEx(row,cmd[i].value,cmd[i].header);
                ret += calcv;
                //ret += cmd[i].value;
                //console.log(cmd[i],calcv);
                continue;
            }else{
                if(cmd[i].index >=0){
                    ret += row[cmd[i].index];
                }else{
                    ret += cmd[i].value;
                }
            }
        }
        return ret;
    },
    isValidVariableName(name) {
        // Regex to validate JavaScript variable names
        const variableNameRegex = /^[a-zA-Z_$][a-zA-Z0-9_$]*$/;
        
        // Check if name matches the regex and isn't a reserved keyword
        return variableNameRegex.test(name) && !this.isReservedKeyword(name);
    },
    
    // Helper function to check reserved keywords
    isReservedKeyword(name) {
        const reservedKeywords = new Set([
            "break", "case", "catch", "class", "const", "continue", "debugger", "default",
            "delete", "do", "else", "export", "extends", "finally", "for", "function",
            "if", "import", "in", "instanceof", "new", "return", "super", "switch",
            "this", "throw", "try", "typeof", "var", "void", "while", "with", "yield",
            "let", "enum", "await", "implements", "package", "protected", "static",
            "interface", "private", "public"
        ]);
        return reservedKeywords.has(name);
    },
    
    calculatedValueEx(row, calculatedData, header, ignoreEmpty=true, currentValue = undefined) {
        var script = ""
        if (currentValue != undefined) {
            var x = currentValue;
            var $ = currentValue;
        }
        // for (var i = 0; i < row.length; i++) {
        //     var value = addslashes(row[i]);
        //     script += `var $${(i + 1)} = "${value}";\n`;
        // }
        for(var i =0;i<row.length;i++){
            var value = utils.addslashes(row[i]);
            if(this.isValidVariableName(header[i])){
                script += `var ${header[i]} = "${value}";\n`;
            }
        }
        script += calculatedData;
        //console.log(script);
        var ret = eval(script);
        return ret;
    },
    calculatedValue(row, calculatedData, ignoreEmpty=true, currentValue = undefined) {
        var script = ""
        if (currentValue != undefined) {
            var x = currentValue;
            var $ = currentValue;
        }
        for (var i = 0; i < row.length; i++) {
            var value = utils.addslashes(row[i]);
            script += `var $${(i + 1)} = "${value}";\n`;
        }
        script += calculatedData;
        //console.log(script);
        var ret = eval(script);
        return ret;
    },
    convertColNameToColNum(script,header){
        var out = script;
        for (var i = 0; i < header.length; i++) {
            out = utils.ReplaceAll(out,`$["${header[i]}"]`,`$${(i + 1)}`);
        }
        return out;
    },
    convertColNumToColName(script,header){
        var out = script;
        for (var i = 0; i < header.length; i++) {
            out = utils.ReplaceAll(out,`$${(i + 1)}`,`$["${header[i]}"]`);
        }
        return out;
    },

}