<template>
    <v-content v-show="!hideLogin" :style="{'background-image':'url(' + loginBackgroudImage + ')','height':'100%', 'background-position':  '' +  loginBackgroudImagePosition + '', 'background-size':'cover'}" >
      <!-- <div>
        <csv-preview @preview-ready="previewReady"></csv-preview>
      </div> -->
      <!-- <hello-world meg="hi aref" /> -->
      <v-container fluid fill-height v-if="showLogin">
        <v-layout pt-5 align-top justify-center>
          <v-flex xs12 sm6 md4 lg4 xl3>
            <v-card class="elevation-12">
              <!-- <v-toolbar dark color="grey lighten-1">
                <v-toolbar-title>Log In</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <v-btn
                    icon
                    large
                    :href="source"
                    target="_blank"
                    slot="activator"
                  >
                    <v-icon large>code</v-icon>
                  </v-btn>
                  <span>Source</span>
                </v-tooltip> 
              </v-toolbar>-->

              <a :href="loginImageLink" target="_blank">
                <v-img
                  height="250"
                  :src="loginImage"
                ></v-img>
              </a>

              <v-card-title style="color:gray" >{{loginTitle}}</v-card-title>
              
              <v-card-text>
                <v-form>
                  <v-text-field @keyup.enter="login" prepend-icon="person" v-model="email" name="email" label="Email" type="email"  v-show="!isHalfLoggedIn && !isCode && !azureSignIn" ></v-text-field>
                  <v-text-field hide-details @keyup.enter="login" prepend-icon="lock" v-model="password" name="password" :label="(!isCode) ? 'Password' : 'Code'" id="password" type="password"  v-show="!isHalfLoggedIn && !azureSignIn"></v-text-field>
                  <p class="text-right pt-1 mb-2" ><a v-if="!isHalfLoggedIn  && !isCode && !azureSignIn" :href="forgotPasswordURL" style="padding-left: 40px;color:grey;font-size:14px" target="_blank">Forgot Password</a></p>
                  <p class="text-right pt-1 mb-2" ><a v-if="!isHalfLoggedIn  && isCode" @click="backToLogin" style="padding-left: 40px;color:grey;font-size:14px" >Start over</a></p>
                  <v-autocomplete
                    ref="accounts"
                    name="accounts"
                    item-text="Value"
                    item-value="Key"
                    v-model="selectedAccount"
                    :items="accounts"
                    label="Account"
                    prepend-icon="home"
                    @change="this.accountChanged"
                    v-show="isHalfLoggedIn"
                  ></v-autocomplete>
                </v-form>
                <v-alert
                  :value="true"
                  color="error"
                  icon="warning"
                  v-if="error"
                  outlined
                >
                  {{ error }}
                </v-alert>
                <v-alert
                  :value="true"
                  color="primary"
                  icon="info"
                  v-if="message"
                  outlined
                >
                  {{ message }}
                </v-alert>
              </v-card-text>
              <v-card-actions class="pt-0 pb-3">
                <v-spacer style="margin:10px"></v-spacer>
                <v-btn @click="azureLogin" v-if="!isHalfLoggedIn && !isCode && azureSignIn" ><v-icon right dark>domain</v-icon>Azure Sign In</v-btn>
                <GoogleLogin :params="params" :renderParams="renderParams" :onSuccess="onSuccess" :onFailure="onFailure"  v-if="!isHalfLoggedIn && !isCode && !azureSignIn"></GoogleLogin>
                &nbsp;&nbsp;
                <v-btn class="white--text" color="grey lighten-1" @keyup.enter="login" @click="login" v-if="!isHalfLoggedIn && !azureSignIn">Log In</v-btn>
                <v-btn class="white--text" color="grey lighten-1" @keyup.enter="accountChanged" @click="accountChanged" v-if="isHalfLoggedIn">Select</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      
      <div class="text-center">
        <v-dialog
          v-model="passwordPolicy"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red lighten-2"
              dark
              v-bind="attrs"
              v-on="on"
              v-show="false"
            >
              Click Me
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Password Policy
            </v-card-title>

            <v-card-text>
              <br>
              MindFire SOC-2 compliance enforce the users to change their password every 90 days unless they make their two factor authentication enabled.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="changePassword(email)"
              >
                Accept
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-content>
</template>

<script>
import json from '../assets/clients.json';
import GoogleLogin from 'vue-google-login';
//import CsvPreview from '../components/CsvPreview.vue'
import axios from 'axios';
import * as msal from "@azure/msal-browser";

export default {
    components: {
        GoogleLogin//,
        //CsvPreview
    },
    computed : {
        loginImageLink: function(){
          return (this.portalSettings && this.portalSettings.loginImageLink) ? this.portalSettings.loginImageLink : 'https://mindfireinc.com';
        },
        loginImage: function(){
          return (this.portalSettings && this.portalSettings.loginImage) ? this.portalSettings.loginImage : 'https://i0.wp.com/mindfireinc.com/wp-content/uploads/2020/10/iphone-and-macbook-mockup-of-a-young-man-at-the-cafeteria-a4635.png?resize=2048%2C1536&ssl=1';
        },
        loginBackgroudImage: function(){
          return (this.portalSettings && this.portalSettings.loginBackgroundImage) ? this.portalSettings.loginBackgroundImage : 'https://s3.us-west-2.amazonaws.com/client.mdl.io/mindfire-bg-image.png';
        },
        loginTitle: function(){
          return (this.portalSettings && this.portalSettings.loginTitle) ? this.portalSettings.loginTitle : 'MindFire Sign in';
        },
        loginBackgroudImagePosition: function(){
          return (this.portalSettings && this.portalSettings.loginBackgroudImagePosition) ? this.portalSettings.loginBackgroudImagePosition : 'top';
        },
        forgotPasswordURL: function(){
          //const logoURL = `${window.location.protocol}://${window.location.host}:${window.location.port}/img/mindfire_logo.6113fc6b.png`;

          if(this.portalSettings.logoURL) {
            if(this.portalSettings.dvURL.includes("davincistage"))
              return `https://studioweb-prd.mdl.io/login?fp=true&logoURL=${encodeURIComponent(this.portalSettings.logoURL)}`;
            else
              return `https://studioweb.mdl.io/login?fp=true&logoURL=${encodeURIComponent(this.portalSettings.logoURL)}`;
          }
          else {
            if(this.portalSettings.dvURL.includes("davincistage"))
              return `https://studioweb-prd.mdl.io/login?fp=true`;
            else
              return `https://studioweb.mdl.io/login?fp=true`;
          }
        },
        isHalfLoggedIn : function(){ 
          return this.$store.getters.isHalfLoggedIn;
        },
        isLoggedIn : function(){ 
          //console.log(this.$store.getters.isLoggedIn);
          return this.$store.getters.isLoggedIn;
        },
        // loggedInWithAccount: function () {
        //     return this.$store.getters.loggedInWithAccount;
        // },
        accounts: function() {
          //return this.$store.getters.accounts;
          if(this.$store.getters.accounts)
          {

            function compare(a, b) {
              if (a.Value.toLowerCase() < b.Value.toLowerCase())
                return -1;
              if (a.Value.toLowerCase() > b.Value.toLowerCase())
                return 1;
              return 0;
            }

            let sorted = JSON.parse(JSON.stringify(this.$store.getters.accounts));
            return sorted.sort(compare);
          }
          else{
            try {
              this.logout();
            } catch (error) {
              
            }
            
          }

          return [];

        }
    },
    data(){
      return {
        hideLogin: true,
        azureSignIn: false,
        msalInstance: null,
        passwordPolicy: false,
        portalSettings: {},
        showLogin: true,
        clients: json,
        email : "",
        password : "",
        message: "",
        error: "",
        isCode: false,
        selectedAccount:0,
        // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
        params: {
            client_id: "809056976879-pqtdatpp7fhl8tugcrntq46tc7sagqg6.apps.googleusercontent.com"
        },
        // only needed if you want to render the button with the google ui
        renderParams: {
            width: 180,
            height: 35,
            longtitle: true
        }
      }
    },
    methods: {
      previewReady: function(previewData){
        console.log(JSON.stringify(previewData));
        alert(JSON.stringify(previewData));
      },
      changePassword: function(email){
        this.passwordPolicy = false;
        window.open(`https://studioweb.mdl.io/user?email=${email}&close=true`, "_blank");
            
      },
      backToLogin: function(){
        this.isCode = false;
        this.message = "";
        this.password = "";
      },
      setThePortalSettings: async function(){
        this.portalSettings = await (await fetch(process.env.BASE_URL + "settings.json")).json();
		    this.$store.commit('setDeployment', this.portalSettings);
        //alert(`portal settings: ${JSON.stringify(this.$store.getters.deployment)}`);

      },
      onSuccess: function(googleUser) {
          //console.log(googleUser);

          // This only gets the user information: id, name, imageUrl and email
          //console.log(googleUser.getBasicProfile());
          //console.log(googleUser.getAuthResponse().id_token)
          this.login(googleUser.getAuthResponse().id_token);
      },
      onFailure: function(failure){
          //console.log(failure);
          alert('Google authentication failed!');
      },
      accountChanged: function(){
        this.$store.dispatch('changeAccount', this.selectedAccount).then((resp) => {
          this.$store.dispatch('loginWithAccount', this.$store.getters.account).then((res) => {
            const clientId = this.$route.query.clientid;
            if(clientId){
              //console.log(clientId);
              var found = this.clients.find(function(client) {
                //console.log(client.id);
                return client.id == clientId;
              });

              //console.log(found);

              const token = this.$store.getters.token;
              this.$store.dispatch('logout');

              if(found)
                window.location = found.url +  '?token=' + token;
            } else {
              this.conditionalRouting();
            }
          })
        })
        
      },
      conditionalRouting: function(){
        if(this.$route.query.route && this.$route.query.route == 'downloads'){
          this.$router.push({ name: 'downloads', query: this.$route.query});
        }
        else if(this.$route.query.route && this.$route.query.route == 'contact-service-dashboards'){
          //alert('contact-service' + JSON.stringify(this.$route.query));
          if(this.$route.query.name){
            this.$router.push('/contact-service/dashboards?name=' + this.$route.query.name + '&token=exist');
          } else {
            this.$router.push({ path: '/contact-service/dashboards' , query: this.$route.query});
          }
        }
        else if(this.$route.query.route && this.$route.query.route == 'iam-groups'){
          if(this.$route.query.route.search)
            this.$router.push('/iam?route=groups&search=' + this.$route.query.search);
          else
            this.$router.push('/iam?route=groups');
        }
        else if(this.$route.query.route && this.$route.query.route == 'iam-policies'){
          if(this.$route.query.route.search)
            this.$router.push('/iam?route=policies&search=' + this.$route.query.search);
          else
            this.$router.push('/iam?route=policies');
        }
        else if(this.$route.query.route && this.$route.query.route == 'iam-identities'){
          if(this.$route.query.route.search)
            this.$router.push('/iam?route=identities&search=' + this.$route.query.search);
          else
            this.$router.push('/iam?route=identities');
        }
        else if(this.$route.query.route && this.$route.query.route == 'iam-settings'){
          if(this.$route.query.route.search)
            this.$router.push('/iam?route=settings&search=' + this.$route.query.search);
          else
            this.$router.push('/iam?route=settings');
        }
        else{
          //alert(JSON.stringify(this.$store.getters.user.preferences));
          //alert('login conditional else')
          if(this.$store.getters.user.preferences.landingPage){
            this.$router.push({ path: this.$store.getters.user.preferences.landingPage , query: this.$route.query});
          } else {
            this.$router.push({ path: this.portalSettings.landingRoute , query: this.$route.query});
          }
        }
      },
      azureLogin: function(){
        const loginScope = {
          scope: ["User.Read"]
        }
        this.msalInstance.loginRedirect(loginScope);
      },
      login: function (googleJWT, azureAuthResponse) {
        //console.log('googleJWT: ', googleJWT);
        let email = this.email 
        let password = this.password
        let isCode = this.isCode
        this.error = "";
        this.message = "";

        let user = { email, password, isCode };
        if(azureAuthResponse){
          user = { azureAuthResponse }
        }
        else if(typeof googleJWT === 'string'){
          user = { googleJWT};
        }

        if(!user.googleJWT && email.toLowerCase() === 'rmemarian@mindfiremail.info'){
          this.error = "Invalid credentials!!!!!!!!!";
          return;
        }

        //alert(JSON.stringify(user));
        this.$store.dispatch('login', user)
       .then((resp) => {
          //console.log(resp.data);
          if(resp.data.isTwoFactor){
            console.log("resp.data.isTwoFactor:" + resp.data.isTwoFactor)
            this.isCode = true;
            this.error = "";
            this.message = "Enter the code that is sent to your email. The code is valid for 5 minutes.";
            this.password = ""
            console.log("if")
          }
          else if(this.accounts && this.accounts.length == 1){
            this.selectedAccount = this.accounts[0].Key;
            this.accountChanged();
          }
          
       })
       .catch(err => {
          //alert(JSON.stringify(err));
          if(this.isCode)
            this.error = "Invalid code!";
          else if(err.response && err.response.data.toLowerCase() === "passwordexpired"){
            this.passwordPolicy = true;
            this.password = "";
            this.error = "Login again once you have changed your password.";
          }
          else if (err.message.includes("429")){
            //alert("429");
            this.password = "";
            this.error = "Too many attempts, try again later!";
          } 
          else {
            this.password = "";
            this.error = "Invalid credentials!";
          }
       })
      }

    },
    mounted(){
      if(this.$route.query.token)
        return;

      setTimeout(() => {
        document.getElementsByName("email")[0].setAttribute('autocomplete', 'off');
        document.getElementsByName("email")[0].value = "";
        document.getElementsByName("password")[0].setAttribute('autocomplete', 'off');
        document.getElementsByName("password")[0].value = "";
        document.getElementsByName("accounts")[0].setAttribute('autocomplete', 'off');
        
        // const frm = document.querySelector('form');
        // frm.reset();

      }, 1000);
    },
    async created(){
      await this.setThePortalSettings();
      if(this.portalSettings.azureSignIn){
        this.azureSignIn = true;
        const msalConfig = {
          auth: {
              clientId: this.portalSettings.azureSignIn.clientId,
              authority: `https://login.microsoftonline.com/${this.portalSettings.azureSignIn.authority}`,
              redirectUri: this.portalSettings.redirectUri
          }
        }

        this.msalInstance = new msal.PublicClientApplication(msalConfig);
        this.msalInstance.handleRedirectPromise().then((response)=>{
          if(!response){
            return;
          }

          //alert(JSON.parse(atob(response.accessToken.split('.')[1])).aud);

          response.audience = JSON.parse(atob(response.accessToken.split('.')[1])).aud;
          
          this.login(null, response);
          console.log('Azure Login response:', response);
        }).catch((error)=>{
          console.log('Azure Login error:', error);
        })
      } else {
        this.azureSignIn = false;
      }

      if(this.$route.query.token && this.$route.query.aid){

        try {
          this.showLogin = false;
          axios.defaults.headers.common['Authorization'] = 'bearer ' + this.$route.query.token;
          const result = await axios({url: process.env.VUE_APP_IDENTITY_SERVICE_ENDPOINT + '/api/identity/v1/login/' + this.$route.query.aid, method: 'GET' });
          //console.log('bypassed login: ', result);
          this.selectedAccount = this.$route.query.aid;
          

          //alert(JSON.stringify(result.data));

          await this.$store.dispatch('loginWithToken', result.data);
          await this.$store.dispatch('changeAccount', this.selectedAccount)

          if(this.$route.query.route){
            //alert('login conditionalRouting');
            this.conditionalRouting();
          }
          else {
            //alert(JSON.stringify(this.$store.getters.user.preferences));
            if(this.$store.getters.user.preferences.landingPage){
              this.$router.push({ path: this.$store.getters.user.preferences.landingPage});
            } else {
              this.$router.push({ path: this.portalSettings.landingRoute});
            }
          }
            //this.$router.push({ path: this.portalSettings.landingRoute });
          
        } catch (error) {
          alert(error);
        }

      } else {
        this.hideLogin = false;
      }
    }
}
</script>
