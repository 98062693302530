<template>
    <v-btn @click="onClick();" :small="small" absolute floating fab>
        <v-icon v-show="state==0">{{icons[0]}}</v-icon>
        <v-icon v-show="state==1">{{icons[1]}}</v-icon>
    </v-btn>
</template>
<script>
export default {
    props: {
        icons: {
            type: Array,
            default() {
                return ["mdi-arrow-right","mdi-arrow-left"];
            }
        },
        index: {
            type: Number,
            default: 0,
        },
        small: {
            type: Boolean,
            default: true,
        },

    },   
    data() {
        return {
            state: this.index,
        }
    },
    methods:{
        onClick(){
            this.state+=1;
            this.state=this.state % this.icons.length;
            console.log("click emit",this.state,this);
            this.$emit("click",this.state,this);
        }
    } 
}
</script>