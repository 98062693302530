<template>
    <v-container style="height: 100%; width: 100%;background: white;">
        <!-- {{ selected }}
        {{ accountsDataTable.items }}
        {{ rawRet._id }}
        {{ filteredItemCount }} -->
        <v-row>
            <v-col cols="12">
                <v-data-table v-model="accountSelected" :loading="accountsDataLoading" item-key="acctID" dense :hide-default-footer="false" :headers="accountsDataTable.headers" :items="accountsDataTable.items" 
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-options': [5, 10, 20, 30, 40, 50]
                    }"
                    @click:row="accountsDataTableRowClick" :single-expand="singleExpand" show-expand :expanded.sync="expanded" :single-select=true
                    sort-by="acctID"
                    sort-desc
                    class="elevation-1">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>WalletPass Accounts</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-tooltip v-if="isSuperAdmin" top hide-on-click hide-on-hover open-on-hover>
                                    <template v-slot:activator="{ on}">
                                        <v-btn v-on="on" small class="btn-icon mt-1 mr-2" @click="refreshClick()">                        
                                            <v-icon>
                                                refresh
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    Reload
                                </v-tooltip>
                                <v-dialog content-class="addAccountDlg" v-model="dialog" max-width="540px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-show="isSuperAdmin || filteredItemCount==0" v-on="on" v-bind="attrs" small class="btn-icon mt-1 mr-2" @click="addClick()">                        
                                            <v-icon>
                                                add
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card height="441">
                                        <v-card-text>
                                            <v-row class="pt-2">
                                                <v-col cols="6">
                                                    <v-text-field :disabled="!isSuperAdmin" clearable hide-details v-model="editedItem.acctID" label="AccountId"></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field clearable hide-details v-model="editedItem.parentAcctID" label="Parent AccountId"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field clearable hide-details v-model="editedItem.accessKey" label="Access Key"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field clearable hide-details v-model="editedItem.secretKey" label="Secret Key"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-text-field clearable hide-details v-model="editedItem.passKey" label="Account Key"></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field clearable hide-details v-model="editedItem.defSuppression" label="Default Suppression"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-text-field clearable hide-details v-model="editedItem.guestImportId">
                                                        <template v-slot:label><span>Guest ImportId</span></template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field clearable hide-details readonly label="Guests in list" append-icon="group_add" @click:append="addGuestClick(editedItem)" :value="showGuestCnt(editedItem)"></v-text-field>
                                                </v-col>
                                            </v-row> 
                                        </v-card-text>                                      
                                        <v-card-actions>
                                            <v-row>
                                                <v-spacer></v-spacer>
                                                <v-col cols="6">
                                                    <v-checkbox style="margin-top: 0;" hide-details dense v-model="allowAPIAccess" @change="allowAPIAccessChange($event)" label="Allow API Access"></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" style="text-align: right;">
                                                    <v-btn color="blue darken-1" text @click="dialog=false;">
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn color="blue darken-1" text @click="save">
                                                        <span v-if="rawRet._id">Save</span>
                                                        <span v-else>Add</span>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>                                            
                                        </v-card-actions>
                                    </v-card>                                    
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:no-data>
                            <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
                        </template>
                        <template v-slot:[`item.updatedAt`]="props">
                            <div>{{ fromNow(props.item.updatedAt)}}</div>
                            <!-- {{props.item.lastModified}} -->
                        </template>
                        <template v-slot:[`item.enable`]="props">
                            <v-switch @click.native.stop class="my-1" v-model="props.item.enable" xcolor="success" hide-details>
                            </v-switch>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-row>
                                <v-col ><b>Guest ImportId:</b> {{item.guestImportId}}<br><b>Guest:</b> {{JSON.stringify(item.guest)}}<br><b>Allow API Access:</b> {{showAllowAPIAccess(item)}}</v-col>
                                </v-row>
                            </td>
                        </template>
                        <template v-slot:[`item.action`]="props">
                           <v-tooltip  top hide-on-click hide-on-hover open-on-hover>
                              <template v-slot:activator="{ on, attrs }">
                                 <v-icon   :disabled="props.item.enable==false"  v-bind="attrs" v-on="on" small class="mr-2" @click="editClick(props)">
                                       mode
                                 </v-icon>
                              </template>
                              <span>Edit</span>
                           </v-tooltip>
                           <v-tooltip v-if="isSuperAdmin" top hide-on-click hide-on-hover open-on-hover>
                              <template v-slot:activator="{ on , attrs}">
                                 <v-icon @click.native.stop v-bind="attrs" v-on="on" small class="mr-2" @click="deleteClick(props)">
                                       delete
                                 </v-icon> 
                              </template>
                              <span>Remove</span>
                           </v-tooltip>
                        </template>                        
                </v-data-table>
            </v-col>                    
        </v-row>
        <v-dialog persistent v-model="showGuestDlg" width="600">
            <v-sheet style="position:relative;" class="rounded-lg mx-auto pa-5" elevation="12" height="550" width="100%">
                <JWGuestDialog ref="guestDialog" @canSave="(n)=> shouldShowGuestSaveButton = n"></JWGuestDialog>
                <div style="position: absolute;bottom: 15px;width: 95%;">
                    <v-btn style="float:right;right: 30px;" @click="closeClick()">Close</v-btn>
                    <v-btn v-show="shouldShowGuestSaveButton" style="float:right;margin-right:10px;" @click="saveGuestClick()">Confirm</v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </v-container>
</template>
<style>
.addAccountDlg {
    overflow-y: auto;
    overflow-x: hidden;
    height: 430px;
}
</style>
<style scoped>
</style>
<script>
import axios from "axios";
import utils from '../services/KCUtils.js';
import JWGuestDialog from "./JWGuestDialog.vue";
var crypto = require('crypto');
const NAME = "KCWalletPassAdmin";
// const serverMode="pass";
// let config = utils.getServerConfig(serverMode);
// import csvUtils from "../services/KCCSVUtils.js";

export default {
    name: NAME,
    components: {
        JWGuestDialog
    },
    props: {
        passUIEndpoint: {
          type: String,
          default: utils.getPassUIEndpoint()
        },
        isSuperAdmin: {
            type: Boolean,
            default: false
        }    
    },
    data() {
        return {
            davinciUrl: null,
            accountSelected: [],
            dialog: false,
            accountsDataLoading: false,
            accountsDataTable: {
                headers: [
                    // { text: "ID", value: "id", sortable: true },
                    { text: "accountID", value: "acctID", sortable: false, filter: value=>{
                        if (this.isSuperAdmin) return true;
                        return value == this.account;
                    }},
                    { text: "parentID", value: "parentAcctID", sortable: false, /*width: '150px'*/ },
                    // { text: "Enable", value: "enable", sortable: false },                    
                    { text: "accessKey", value: "accessKey", sortable: false},
                    { text: "secretKey", value: "secretKey", sortable: false},
                    { text: "accountKey", value: "passKey", sortable: false},
                    { text: "suppression", value: "defSuppression", sortable: false},
                    // { text: "Guest Id", value: "g_id", sortable: false},
                    // { text: "Guest PURL", value: "gurl", sortable: false},
                    // { text: "Guest ImportId", value: "gimportId", sortable: false},
                    { text: "action", value: "action", sortable: false,cellClass:'action-class', align: 'center' },
                ],
                items: []
            },
            default_editedItem: {
                acctID: "",
                accessKey: "",
                parentAcctID: "",
                secretKey: "",
                passKey: "",
                defSuppression: 360,
                guestImportId: "",
                guest: [],
                allowAPIAccessEmail: []
            },
            editedItem: {... this.default_editedItem},
            rawRet: {},
            singleExpand: false,
            expanded: [],
            showGuestDlg: false,
            shouldShowGuestSaveButton: false,
            allowAPIAccess: false,
            loginEmail: this.$store.getters.user.email
        };
    },
    computed: {
        account: function () {
            //console.log('user object: ', this.$store.getters.user);
            return this.$store.getters.user.account;
        },
        filteredItemCount() {
            // Assuming you have access to the original data source.
            // Replace 'dataSource' with your actual data source.
            let filteredItems = this.accountsDataTable.items.filter(item => {
                // Apply your filter logic here
                if (this.isSuperAdmin) return true;
                return item.acctID == this.account;
            });
            return filteredItems.length;
        }
    },
    methods: {
        async loadData(){
            this.accountsDataLoading = true;
            var ret = await axios.get(this.passUIEndpoint+`?cmd=getMaster&stage=${this.server}`);
            console.log("loadData", ret.data);
            this.accountsDataTable.items = ret.data.result.definition.data;
            // merge new fields
            for(let x in this.accountsDataTable.items){
                this.accountsDataTable.items[x] = {...this.default_editedItem, ...this.accountsDataTable.items[x]};
                // delete this.accountsDataTable.items[x].allowAPIAccess;
                // delete this.accountsDataTable.items[x].allowAPIAccessEmail;
            }
            console.log("accountsDataTable", this.accountsDataTable.items[0]);
            this.accountsDataLoading = false;
            this.rawRet = ret.data.result;
            //return ret.data.result;
        },
        async refreshClick(){
            this.loadData();
        },
        async syncToDB(){
            // persist it
            var postRet = await axios.post(this.passUIEndpoint+`?cmd=saveMaster&stage=${this.server}`,{
                _id: this.rawRet._id,
                data: this.accountsDataTable.items,
            });
            console.log(postRet);
            this.loadData();
        },
        async save(){
            //check if acctID = what we have
            let update = false;
            for(let x in this.accountsDataTable.items){
                if(this.accountsDataTable.items[x].acctID ==  this.editedItem.acctID){
                    this.accountsDataTable.items[x] = {... this.editedItem}
                    update = true;
                    break;
                }
            }
            if(update == false){
                this.accountsDataTable.items.push({... this.editedItem});
            }
            this.dialog = false;
            console.log("Save: ",this.accountsDataTable.items);
            this.syncToDB();
        },
        accountsDataTableRowClick(param){
            console.log("accountsDataTableRowClick",param);
            this.accountSelected = [param];
        },
        addClick(){
            this.editedItem = {... this.default_editedItem};
            if (!this.isSuperAdmin) this.editedItem.acctID = this.account;
        },
        editClick(props){
            console.log("editClick: ",props);
            this.editedItem = JSON.parse(JSON.stringify({... props.item}));
            this.allowAPIAccess = this.showAllowAPIAccess(this.editedItem);
            this.dialog = true;
        },
        deleteClick(props){
            console.log("delete",props);
            this.accountsDataTable.items = this.accountsDataTable.items.filter(item => item != props.item);
            this.syncToDB();
        },
        addGuestClick(editedItem) {
            this.showGuestDlg = true;
            this.shouldShowGuestSaveButton = false;
            this.$nextTick(async () => {
                this.$refs.guestDialog.initialize(editedItem.guest);
            });
        },
        showGuestCnt(editedItem) {
            console.log('showGuestCnt', typeof editedItem.guest, editedItem);
            if (typeof editedItem.guest === 'undefined') {
                return 0;
            } else {
                return editedItem.guest.length;
            }
        },
        showAllowAPIAccess(editedItem) {
            console.log('showAllowAPIAccess', typeof editedItem.allowAPIAccessEmail, editedItem);
            if (typeof editedItem.allowAPIAccessEmail !== 'undefined' && editedItem.allowAPIAccessEmail.length > 0 && editedItem.allowAPIAccessEmail.includes(this.loginEmail)) {
                return true;
            } else {
                return false;
            }
        },
        closeGuestDlg() {
            console.log('closeGuestDlg');
        },
        saveGuestClick() {
            console.log('Save Guest Clicked');
            this.editedItem.guest = this.$refs.guestDialog.getGuests();
            console.log('saveGuestClick editedItem', this.editedItem);
            this.showGuestDlg = false;
        },
        async closeClick() {
            if(this.shouldShowGuestSaveButton){
                let result = await this.$swal.fire({
                    title: 'Are you sure you want to close this without saving your work?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    showCancelButton: true,
                    showConfirmButton: true,
                });
                if(result.isConfirmed){
                    this.showGuestDlg = false;            
                }
            }else{
                this.showGuestDlg = false;     
            }
        },

        allowAPIAccessChange(evt){
            console.log("allowAPIAccessChange",evt,this.accountsDataTable.items);
            if(evt){
                if (this.editedItem.allowAPIAccessEmail.length == 0 || !this.editedItem.allowAPIAccessEmail.includes(this.loginEmail)){
                    this.editedItem.allowAPIAccessEmail.push(this.loginEmail);
                }
            } else {
                if (this.editedItem.allowAPIAccessEmail.length > 0 && this.editedItem.allowAPIAccessEmail.includes(this.loginEmail)){
                    this.editedItem.allowAPIAccessEmail = this.editedItem.allowAPIAccessEmail.filter(item => item !== this.loginEmail);
                }
            }
            console.log("allowAPIAccessChange2",evt,this.accountsDataTable.items);
        }
    },
    created() {
        this.davinciUrl = this.$store.getters.deployment.dvURL;
        // alert(`in created(): davinciUrl=${this.davinciUrl}`);
        if (this.davinciUrl.indexOf("davincistage") >= 0)
            this.server = "stage";
        else
            this.server = "prod";
        // this.endPoint = config.endPoints.mindfireOne + `/api/card/${this.server}`;
    },
    destroyed() {
    },
    async mounted() {
        this.loadData();
    },
    beforeUnmount() { 
    },
    watch: {
        showGuestDlg (val) {
            val || this.closeGuestDlg()
        },
        account: {
            async handler(val) {
            },
            deep: true
        },
    },
};
</script>