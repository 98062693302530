<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>List Service: Search</v-card-title>
      <v-card-text></v-card-text>
   </v-card>
</v-container>
</template>

<script>
import axios from 'axios';
export default {
   async created(){
      const filter = { name: { $regex: '^car.*', $options: 'i'}, price: { $gte: 1200}, country: 'Germany'};

      const getOffers = await axios.get(process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + `/api/contact-service/v1/offers?find=${JSON.stringify(filter)}&limit=5&skip=0`);
      console.log('getOffers:', getOffers.data);
   }
}
</script>