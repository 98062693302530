<template>
   <v-container fluid>
      <!-- HISTORY:
      - 06/12/20(B0.1): 1st release.
      -->
      <v-btn dark
         :color="btnColor"
         :block="btnBlock"
         :disabled="btnDisabled"
         @click="dialog=true"
      >{{btnTitle}}
         <v-icon dark x-small>{{btnIcon}}</v-icon>
      </v-btn>

      <v-dialog v-model="dialog" persistent no-click-animation max-width="480px" scrollable>
         <v-card>
            <v-card-title class="title grey--text darken-4 font-weight-bold pt-3 pb-0">{{dialogTitle}}
               <!-- <div class="flex-grow-1"></div>
               <div class="body-2 font-weight-regular">{{authorRole}} User: {{author}}</div> -->
            </v-card-title>
            <v-card-text v-if="isAdmin || isPowerUser" class="pb-0">
               <div v-if="errMsg" class="red--text">{{errMsg}}</div>
               <v-form ref="formMain" lazy-validation>
                  <v-row v-if="isAdmin"
                     class="pt-0 pb-2">
                     <v-col cols="12">
                        <v-select dense persistent-hint hide-selected required
                           ref="paid"
                           class="pt-0"
                           hint="Parent Accounts"
                           v-model="formValues.paid"
                           :items="paidItems"
                           :rules="[rules.required]"
                           @change="paidChanged"
                        ></v-select>
                     </v-col>
                     <v-col v-if="formValues.paid==='current' || formValues.paid==='custom'"
                        cols="12" class="py-0"
                     >
                        <v-textarea dense persistent-hint outlined required
                           class="py-0 body-2"
                           label="Specified Parent Accounts"
                           hide-details="auto"
                           v-model="mySharedWith.paids"
                           :rows="formValues.paid==='current'?1:3"
                           :hint="getHint(mySharedWith.paids, 'account ID')"
                           :disabled="formValues.paid==='current'"
                           :rules="[rules.required, rules.numbers, rules.duplicate]"
                        ></v-textarea>
                     </v-col>
                  </v-row>

                  <v-row v-if="isAdmin"
                     class="pt-0 pb-2">
                     <v-col cols="12">
                        <v-select dense persistent-hint hide-selected required
                           ref="said"
                           class="pt-0"
                           hint="Accounts"
                           v-model="formValues.said"
                           :items="saidItems"
                           :rules="[rules.required]"
                           @change="saidChanged"
                        ></v-select>
                     </v-col>
                     <v-col v-if="formValues.said==='current' || formValues.said==='custom'"
                        cols="12" class="py-0"
                     >
                        <v-textarea dense outlined required
                           class="py-0 body-2"
                           label="Specified Accounts"
                           hide-details="auto"
                           v-model="mySharedWith.saids"
                           :rows="formValues.said==='current'?1:3"
                           :hint="getHint(mySharedWith.saids, 'account ID')"
                           :disabled="formValues.said==='current'"
                           :rules="[rules.required, rules.numbers, rules.duplicate]"
                        ></v-textarea>
                     </v-col>
                  </v-row>

                  <v-row v-if="isPowerUser"
                     class="pt-0 pb-2">
                     <v-col cols="12">
                        <v-select dense persistent-hint hide-selected clearable
                           ref="aid"
                           class="pt-0"
                           hint="Accounts"
                           v-model="formValues.aid"
                           :items="aidItems"
                           @change="aidChanged"
                        ></v-select>
                     </v-col>
                     <v-col v-if="formValues.aid==='currentP' || formValues.aid==='currentA'"
                        cols="12" class="py-0"
                     >
                        <v-textarea dense outlined required disabled
                           class="py-0 body-2"
                           label="Specified Account"
                           hide-details="auto"
                           rows="1"
                           v-model="mySharedWith.aid"
                           :hint="getHint(mySharedWith.aid, 'account ID')"
                        ></v-textarea>
                     </v-col>
                  </v-row>

                  <v-row class="pt-0 pb-2">
                     <v-col cols="12">
                        <v-select dense persistent-hint hide-selected required
                           ref="user"
                           class="pt-0"
                           hint="Users"
                           v-model="formValues.user"
                           :items="userItems"
                           :rules="[rules.required]"
                           @change="userChanged"
                        ></v-select>
                     </v-col>
                     <v-col v-if="formValues.user==='current' || formValues.user==='custom'"
                        class="py-0" cols="12"
                     >
                        <v-textarea dense outlined required
                           class="py-0 body-2"
                           label="Specified Users"
                           hide-details="auto"
                           v-model="mySharedWith.users"
                           :rows="formValues.user==='current'?1:4"
                           :hint="getHint(mySharedWith.users, 'email')"
                           :disabled="formValues.user==='current'"
                           :rules="[rules.required, rules.emails, rules.duplicate]"
                        ></v-textarea>
                     </v-col>
                  </v-row>
               </v-form>
            </v-card-text>

            <v-card-text v-else class="title d-flex text-center red--text pt-5">
               <div>Sorry!<br>You are not authorized to share dashboards!</div>
            </v-card-text>

            <v-card-actions>
               <div class="flex-grow-1"></div>
               <v-btn text
                  color="blue darken-1"
                  @click="dialog=false"
               >{{isAdmin || isPowerUser ? 'Cancel' : 'Close'}}</v-btn>
               <v-btn v-if="isAdmin || isPowerUser"
                  text
                  color="blue darken-1"
                  @click="saveClicked"
               >Save</v-btn>
            </v-card-actions>
         </v-card>

         <!-- <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
         </v-overlay> -->

      </v-dialog>
   </v-container>
</template>

<script>
const DEBUG = false;
const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NUMBER_PATTERN = /^[1-9]{1}[0-9]{0,}$/;
const FORM_VALUES = {
   paid: '',
   said: '',
   aid: '',
   user: '',
};
const FORM_RULES = {
   paids: [],
   saids: [],
   users: []
}

class SharedWith {
   constructor(initVal) {
      if (!initVal)
         initVal = {};
      this.paids = this.setValue(initVal.paids);
      this.saids = this.setValue(initVal.aids);
      this.users = this.setValue(initVal.users);
   }

   setValue(val) {
      if (Array.isArray(val) && val.length > 0)
         return val.join(', ');
      else return '';
   }
}

function _log(msg, log) {
   if (DEBUG || log) console.log(`BtDashboardSharing B0.1 says => ${msg}`);
}

function _stringToArray(strVal, type) {
   const result = [];
   if (strVal) {
      strVal.split(',').forEach(sv => {
         const svTrimmed = sv.replace(/\s/g,'');
         if (type==='int')
            result.push(Number(svTrimmed));
         else
            result.push(svTrimmed);
      });
   }
   // alert('result='+JSON.stringify(result))
   return result;
}

export default {
   name: "BtDashboardSharing",

   props: {
      value: {
         type: Object,
         default: () => new SharedWith({})
      },
      btnTitle: {
         type: String,
         default: 'SHARE'
      },
      btnIcon: {
         type: String,
         default: "share"
      },
      btnColor: {
         type: String,
         default: "blue darken-1"
      },
      btnBlock: {
         type: Boolean,
         default: false
      },
      btnDisabled: {
         type: Boolean,
         default: false
      },
      dialogTitle: {
         type: String,
         default: "Share your dashboard:"
      },
      isPowerUser: {
         type: Boolean,
         default: false
      },
      isAdmin: {
         type: Boolean,
         default: false
      },
      paid: {
         type: String,
         required: true
      },
      said: {
         type: Number,
         required: true
      },
      author: {
         type: String,
         required: true
      },
      updateSharedWith: {
         type: Function,
         required: true
      }
   },

   data() {
      return {
         dialog: false,
         mySharedWith: new SharedWith({}),
         formValues: JSON.parse(JSON.stringify(FORM_VALUES)),
         formRules: JSON.parse(JSON.stringify(FORM_RULES)),
         rules: {
            required: value => !!value || "Value is required!",
            numbers: value => {
               const result = [];
               const ids = _stringToArray(value);
               ids.forEach(id => { 
                  if (!NUMBER_PATTERN.test(id) && result.findIndex(r => r === `'${id}' is invalid!`) === -1) 
                     result.push(`'${id}' is invalid!`);
               });
               return result.length === 0 || result.join(', ');
            },
            emails: value => {
               const result = [];
               const emails = _stringToArray(value);
               emails.forEach(e => {
                  if (!EMAIL_PATTERN.test(e) && result.findIndex(r => r === `'${e}' is invalid!`) === -1)
                     result.push(`'${e}' is invalid!`); });
               return result.length === 0 || result.join(', ');
            },
            duplicate: value => {
               const result = [];
               const vals = _stringToArray(value);
               const uniqueVals = new Set(vals);
               uniqueVals.forEach(val => { 
                  if (vals.filter(v => v === val).length > 1) result.push(`'${val}' is duplicate!`);
               });
               return result.length === 0 || result.join(', ');
            }
         },
         paidItems: [],
         saidItems: [],
         aidItems: [],
         userItems: [
            { text: 'All', value: 'all', disabled: false },
            { text: 'Current User', value: 'current'},
            { text: 'Custom...', value: 'custom'}
         ],
         errMsg: ''
      }
   },

   computed: {
      myPaid() {
         if (isNaN(this.paid)) return this.said;
         else return Number(this.paid);
      }
   },

   watch: {
      dialog(val) {
         if (val) {
            setTimeout(() => {
               this.initDialog();
            }, 10);
         } else {
            setTimeout(() => {
               this.closeDialog();
         }, 100);

         }
      }
   },

   methods: {
      initDialog() {
         this.mySharedWith = new SharedWith(this.value);
         _log('in initDialog(): isAdmin=' + this.isAdmin + ', isPowerUser=' + this.isPowerUser +
            '\nthis.value=' + JSON.stringify(this.value) +
            '\nsharedWith=' + JSON.stringify(this.mySharedWith));
         if (this.isAdmin) {
            this.paidItems = [
               { text: 'All', value: 'all'},
               { text: 'Current Parent', value: 'current'},
               { text: 'Custom...', value: 'custom'}
            ];
            this.saidItems = [
               { text: 'All', value: 'all'},
               { text: 'Current Account', value: 'current'},
               { text: 'Custom...', value: 'custom'}
            ];
            // if (this.paid === this.said)
            //    this.saidItems.splice(1, 1);

            this.formValues.paid = this.getSelectedValue(this.value.paids, this.myPaid);
            this.formValues.said = this.getSelectedValue(this.value.aids, this.said);
         } else if (this.isPowerUser) {
            this.aidItems = [
               { text: 'Current Parent', value: 'currentP' },
               { text: 'Current Account', value: 'currentA' }
            ];
            // if (this.myPaid != this.said)
            //    this.aidItems.push({ text: 'Current Account', value: 'currentA'});
            this.userItems[0].disabled = Boolean(!this.formValues.aid);

            if (this.value.paids.length === 1) {
               this.formValues.aid = 'currentP';
               this.mySharedWith.aid = this.value.paids[0].toString();
            } else if (this.value.aids.length === 1) {
               this.formValues.aid = 'currentA';
               this.mySharedWith.aid = this.value.aids[0].toString();
            } else {
               this.formValues.aid = '';
               this.mySharedWith.aid = '';
            }
         }

         this.formValues.user = this.getSelectedValue(this.value.users, this.author);
      },

      getSelectedValue(arrVal, val) {
         // alert('arrVal=' + JSON.stringify(arrVal) + ', val=' + val);
         if (arrVal.length === 0) return 'all';
         if (arrVal.length === 1 && arrVal[0] === val) return 'current';
         return 'custom';
      },

      getHint(value, name) {
         if (this.mySharedWith) {
            if (value) {
               const len = _stringToArray(value).length;
               return `${len} ${name}${len > 1 ? 's have' : ' has'} been specified.`;
            } else
               return 'separate different ' + name + 's with comma.';
         } else return '';
      },

      paidChanged(value) {
         this.saidItems[1].disabled = Boolean(value === 'current');
         if (value === 'current')
            this.mySharedWith.paids = this.myPaid.toString();
         else if (value === 'all')
            this.mySharedWith.paids = '';
      },

      saidChanged(value) {
         this.paidItems[1].disabled = Boolean(value === 'current');
         if (value === 'current')
            this.mySharedWith.saids = this.said.toString();
         else if (value === 'all')
            this.mySharedWith.saids = '';
      },

      aidChanged(value) {
         this.userItems[0].disabled = Boolean(!value);
         if (value === 'currentP')
            this.mySharedWith.aid = this.myPaid.toString();
         else if (value === 'currentA')
            this.mySharedWith.aid = this.said.toString();
         else
            this.mySharedWith.aid = '';
      },

      userChanged(value) {
         if (value === 'current')
            this.mySharedWith.users = this.author;
         else if (value === 'all')
            this.mySharedWith.users = '';
      },

      async saveClicked() {
         if (this.$refs.formMain.validate()) {
            _log('in saveClicked(): this.mySharedWith=' + JSON.stringify(this.mySharedWith));
            let sharedWith = {};
            if (this.isAdmin) {
               sharedWith.paids = _stringToArray(this.mySharedWith.paids, 'int');
               sharedWith.aids = _stringToArray(this.mySharedWith.saids, 'int');
            } else if (this.formValues.aid === 'currentP') {
               sharedWith.paids = _stringToArray(this.mySharedWith.aid, 'int');
               sharedWith.aids = [];
            } else if (this.formValues.aid === 'currentA') {
               sharedWith.paids = [];
               sharedWith.aids = _stringToArray(this.mySharedWith.aid, 'int');
            } else {
               sharedWith.paids = [];
               sharedWith.aids = [];
            }
            sharedWith.users = _stringToArray(this.mySharedWith.users);

            _log('in saveClicked(): sharedWith=' + JSON.stringify(sharedWith));
            this.errMsg = await this.updateSharedWith(sharedWith);
            if (!this.errMsg) {
               this.$emit('input', sharedWith);
               this.dialog = false;
            }
         }
      },

      closeDialog() {
         this.mySharedWith = new SharedWith({});
         this.formValues = JSON.parse(JSON.stringify(FORM_VALUES));
         this.formRules = JSON.parse(JSON.stringify(FORM_RULES));
         this.errMsg = '';
      },

   },

   mounted() {
   }
}
</script>

<style>
.v-textarea textarea {
    line-height: 1rem;
    padding: 8px 8px 8px 0;
}
</style>