<template>
   <!-- TODO:
   - Switching between methods doesn't reset the body validation properly (try to bring endpoint 1st).
   - Implement reset (add classes).
   - Support multi notifications.
   -->
   <!-- HISTORY:
      V230411.1: Moved through tabs to make Cancel btn works without clicking on them.
      V221128.1: Applied/Modified styles to display contents properly after Aref's upgrade + Replaced hasOwnProperty() with hasOwn from mixins +
         Added PGP publicKey to the SFTP tab.
      07/26/21(B0.4): Hid body for get method + Set 22 as default value for the port.
      07/19/21(B0.3): Changed 'endpoint' type to 'http' + Added signedUrlExpiryBySecond for the post methods +
         Changed headers to be an object instead of an Array + Removed index/seq from labels + Added SFTP tab +
         In emails, replaced body with htmlBody and textBody.
      07/07/21(B0.2): Added labels.
      07/02/21(B0.1): 1st release.
   -->
<div>
   <v-tooltip left>
      <template v-slot:activator="{ on }">
         <v-btn text icon
            v-on="on"
            @click="btnClicked"
         >
            <v-icon>{{btnIconName}}</v-icon>
         </v-btn>
      </template>
      <span>{{btnTooltip}}</span>
   </v-tooltip>

   <v-dialog no-click-animation persistent scrollable
      max-width="1280px"
      v-model="dialog"
   >
      <v-card flat>
         <v-card-title class="title grey--text darken-4 font-weight-bold">Notifications</v-card-title>

         <v-card-text class="px-4 pb-2">
            <v-tabs
               class="elevation-2"
               background-color="grey lighten-2 accent-4"
               slider-color="black"
               v-model="tab"
            >
               <v-tab :class="isEmailFormValid ? '' : 'red--text'">Email</v-tab>
               <v-tab :class="isEndpointFormValid ? '' : 'red--text'">HTTP</v-tab>
               <v-tab :class="isSftpFormValid ? '' : 'red--text'">SFTP & PGP</v-tab>

               <v-tabs-items v-model="tab">
                  <v-tab-item><!-- Email tab -->
                     <!-- { type: "email", to: "to@address.com", from: "from@address.com", fromName: "from name", subject: "subject", htmlBody: "html body", textBody: "text body"} -->
                     <v-form lazy-validation
                        ref="emailForm"
                        v-model="isEmailFormValid"
                     >
                        <v-card v-for="(item, i) in emails" :key="i">
                           <v-card-text class="pt-5 pb-2">
                              <v-row class="pt-2">
                                 <!-- <v-col xs="1" sm="1" md="1" class="py-0">
                                    <v-icon
                                       class="py-2"
                                       :disabled="i === emails.length - 1"
                                       @click="removeEmail(i)"
                                    >delete_forever</v-icon>
                                 </v-col> -->
                                 <v-col xs="12" sm="6" md="6" class="pt-0 pb-1">
                                    <!-- <v-row>
                                       <v-col xs="12" sm="6" md="6" class="py-0"> -->
                                          <v-text-field dense persistent-hint
                                             v-model="item.from"
                                             autocomplete="off"
                                             ref="fromEmail"
                                             label="* From Email"
                                             :clearable="Boolean(i===0 & !required)"
                                             :rules="getFromEmailRules(i)"
                                             @keyup="addEmail()"
                                          ></v-text-field>
                                       </v-col>
                                       <v-col v-if="item.from"
                                          xs="12" sm="6" md="6" class="pt-0 pb-1"
                                       >
                                          <v-text-field clearable dense persistent-hint
                                             v-model="item.fromName"
                                             autocomplete="off"
                                             :ref="`fromName${i}`"
                                             label="From Name"
                                          ></v-text-field>
                                       </v-col>
                                    </v-row>
                                    <v-row v-if="item.from">
                                       <v-col xs="12" sm="12" md="6" class="py-1">
                                          <v-text-field dense persistent-hint
                                             v-model="item.to"
                                             autocomplete="off"
                                             :ref="`toEmails${i}`"
                                             hint="separate emails with comma"
                                             label="* To Email(s)"
                                             :rules="[rules.required, rules.emails]"
                                          ></v-text-field>
                                       </v-col>
                                       <v-col xs="12" sm="12" md="6" class="py-1">
                                          <v-text-field dense persistent-hint
                                             v-model="item.subject"
                                             autocomplete="off"
                                             :ref="`subject${i}`"
                                             label="* Subject"
                                             :rules="[rules.required]"
                                          ></v-text-field>
                                       </v-col>
                                    </v-row>
                                    <v-row v-if="item.from">
                                       <v-col xs="12" sm="12" md="6" class="pt-4 pb-0">
                                          <v-textarea outlined persistent-hint
                                             v-model="item.htmlBody"
                                             autocomplete="off"
                                             :ref="`htmlBody${i}`"
                                             label="* HTML Body"
                                             rows="4"
                                             :rules="[rules.required]"
                                          ></v-textarea>
                                       </v-col>
                                       <v-col xs="12" sm="12" md="6" class="pt-4 pb-0">
                                          <v-textarea outlined persistent-hint
                                             v-model="item.textBody"
                                             autocomplete="off"
                                             :ref="`textBody${i}`"
                                             label="Text Body"
                                             rows="4"
                                          ></v-textarea>
                                       </v-col>
                                    </v-row>
                                 <!-- </v-col>
                              </v-row> -->
                           </v-card-text>
                        </v-card>
                     </v-form>
                  </v-tab-item>

                  <v-tab-item><!-- HTTP tab -->
                     <!-- { type: "http", method: "get|put|post", endpoint: "https://endpoint/param?querystrings", body: {}, headers: {}} -->
                     <v-form lazy-validation
                        ref="endpointForm"
                        v-model="isEndpointFormValid"
                     >
                        <v-card v-for="(item, i) in endpoints" :key="i">
                           <v-card-text class="pt-5 pb-2">
                              <v-row class="pt-2">
                                 <!-- <v-col xs="1" sm="1" md="1" class="py-0">
                                    <v-icon
                                       class="py-2"
                                       :disabled="i === emails.length - 1"
                                       @click="removeEmail(i)"
                                    >delete_forever</v-icon>
                                 </v-col> -->
                                 <v-col xs="12" sm="2" class="pt-0 pb-2">
                                    <!-- <v-row>
                                       <v-col xs="12" sm="6" md="6" class="py-0"> -->
                                          <!-- @keyup="addEndpoint()" -->
                                          <v-select dense hide-selected persistent-hint required
                                             v-model="item.method"
                                             autocomplete="off"
                                             class="pt-1 mt-0"
                                             :ref="`method_${i}`"
                                             label="* Method"
                                             :clearable="Boolean(i===0 & !required)"
                                             :items="[
                                                { text: 'GET', value: 'get' },
                                                { text: 'POST', value: 'post' },
                                                { text: 'PUT', value: 'put' }
                                             ]"
                                             :rules="getMethodRules(i)"
                                             @change="methodChanged"
                                          ></v-select>
                                       </v-col>
                                       <v-col v-if="item.method"
                                          xs="12" :sm="item.method==='post'?8:10" class="pt-0 pb-2"
                                       >
                                          <v-text-field dense persistent-hint required
                                             v-model="item.endpoint"
                                             autocomplete="off"
                                             ref="endpoint"
                                             label="* Endpoint"
                                             :rules="[rules.required]"
                                          ></v-text-field>
                                       </v-col>
                                       <v-col v-if="item.method"
                                          xs="12" sm="2" class="pt-0 pb-2"
                                       >
                                          <v-text-field v-if="item.method==='post'"
                                             dense persistent-hint required
                                             v-model="item.signedUrlExpiryBySecond "
                                             autocomplete="off"
                                             ref="signedUrlExpiryBySecond"
                                             label="* Signed URL Expiry"
                                             hint="in seconds"
                                             type="number"
                                             :rules="[rules.required]"
                                          ></v-text-field>
                                       </v-col>
                                    </v-row>
                                    <v-row v-if="item.method">
                                       <v-col xs="12" sm="12" md="6" class="pt-2 pb-2">
                                          <v-textarea outlined persistent-hint
                                             v-model="item.headers"
                                             autocomplete="off"
                                             ref="headers"
                                             hint="in JSON format"
                                             label="Headers"
                                             rows="3"
                                             :rules="[rules.validJson]"
                                          ></v-textarea>
                                       </v-col>
                                       <v-col v-if="item.method != 'get'"
                                          xs="12" sm="12" md="6" class="pt-2 pb-2"
                                       >
                                          <v-textarea outlined persistent-hint
                                             v-model="item.body"
                                             autocomplete="off"
                                             ref="body"
                                             hint="in JSON format"
                                             label="Body"
                                             rows="3"
                                             :rules="bodyRules"
                                          ></v-textarea>
                                       </v-col>
                                    </v-row>
                                 <!-- </v-col>
                              </v-row> -->
                           </v-card-text>
                        </v-card>
                     </v-form>
                  </v-tab-item>

                  <v-tab-item><!-- SFTP & PGP tab -->
                     <v-expansion-panels multiple focusable
                        class="px-4 py-4"
                        v-model="panels"
                     >
                        <v-expansion-panel readonly
                           class="px-0"
                        >
                           <v-expansion-panel-header hide-actions
                              class="py-0 font-weight-bold"
                           >SFTP Settings</v-expansion-panel-header>
                           <v-expansion-panel-content>
                              <!-- { type: "sftp", host: "", port: "", user: "", password: "", folder: "", pgpPublicKey: "" } -->
                              <v-form lazy-validation
                                 ref="sftpForm"
                                 v-model="isSftpFormValid"
                              >
                                 <v-card v-for="(item, i) in ftps" :key="i" flat tile>
                                    <v-card-text class="pt-5 pb-4 px-0">
                                          <v-row class="pt-2">
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0">
                                                <v-text-field clearable dense persistent-hint
                                                   v-model="item.host"
                                                   :ref="`host_${i}`"
                                                   autocomplete="off"
                                                   label="Host"
                                                   @keyup="addFtp()"
                                                ></v-text-field>
                                             </v-col>
                                             <v-col v-if="item.host"
                                                cols="12" xs="12" sm="12" md="4" lg="4" class="py-0">
                                                <v-text-field dense persistent-hint
                                                   v-model="item.folder"
                                                   ref="folder"
                                                   autocomplete="off"
                                                   label="Folder"
                                                ></v-text-field>
                                             </v-col>
                                             <v-col v-if="item.host"
                                                cols="12" xs="12" sm="12" md="2" lg="2" class="py-0">
                                                <v-text-field dense persistent-hint required
                                                   v-model="item.port"
                                                   ref="port"
                                                   autocomplete="off"
                                                   label="Port"
                                                   type="number"
                                                   min="1"
                                                   :rules="[rules.required]"
                                                ></v-text-field>
                                             </v-col>
                                          </v-row>
                                          <v-row v-if="item.host" class="pt-2">
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="pt-0 pb-2">
                                                <v-text-field dense persistent-hint required
                                                   v-model="item.user"
                                                   ref="user"
                                                   autocomplete="off"
                                                   label="User"
                                                   :rules="[rules.required]"
                                                ></v-text-field>
                                             </v-col>
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0">
                                                <v-text-field counter dense persistent-hint required
                                                   v-model="item.password"
                                                   ref="password"
                                                   autocomplete="off"
                                                   label="Password"
                                                   :type="showPassword ? 'text' : 'password'"
                                                   :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                                   :rules="[rules.required]"
                                                   @click:append="showPassword = !showPassword"
                                                ></v-text-field>
                                             </v-col>
                                          </v-row>
                                    </v-card-text>
                                 </v-card>
                              </v-form>
                           </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel
                           readonly
                           class="px-0 pb-0"
                        >
                           <v-expansion-panel-header hide-actions
                              class="py-0 font-weight-bold"
                           >PGP Settings</v-expansion-panel-header>
                           <v-expansion-panel-content class="px-0">
                              <v-card flat tile v-for="(item, i) in ftps" :key="i">
                                 <v-card-text class="px-0 pt-5 pb-2">
                                    <v-row>
                                       <v-col cols="12" class="py-0">
                                          <h4 class="pt-0 pb-3">
                                             {{item.host && isSftpFormValid ? "Enter your PGP Public Key in the box below if you'd like to encrypt your export files" : "Complete the SFTP settings in order to enable the box below"}}:
                                          </h4>
                                          <v-textarea clearable dense outlined
                                             class="py-0 my-0 caption"
                                             rows="5"
                                             label="PGP Public Key"
                                             :disabled="!item.host || !isSftpFormValid"
                                             v-model="item.pgpPublicKey"
                                          ></v-textarea>
                                       </v-col>
                                    </v-row>
                                 </v-card-text>
                              </v-card>
                           </v-expansion-panel-content>
                        </v-expansion-panel>
                     </v-expansion-panels>
                  </v-tab-item>
               </v-tabs-items>
            </v-tabs>
         </v-card-text>

         <v-card-actions>
            <div class="flex-grow-1"></div>
            <!-- <v-btn text small
               color="blue darken-1"
               @click="resetClicked"
            >Reset</v-btn> -->
            <v-btn text small
               color="blue darken-1"
               @click="cancelClicked"
            >Cancel</v-btn>
            <v-btn text small
               color="blue darken-1"
               :disabled="isSaveDisabled"
               @click="saveClicked"
            >Save</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</div>
</template>

<script>
import { isIncluded, hasOwn } from '../mixins/bt-mixin.js';
// import { revokeKey, readKey } from 'openpgp';

const NAME = 'BtNotifications';
const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const URL_PATTERN = /^((http|https):\/\/?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;

export default {
   name: NAME,

   props: {
      value: {
         type: Array,
         default: () => []
      },
      btnIconName: {
         type: String,
         default: "more_vert"
      },
      btnTooltip: {
         type: String,
         default: "click to set notifications"
      },
      debug: {
         type: Boolean,
         default: false
      },
      max: {
         type: Number
      },
      required: {
         type: Boolean,
         default: false
      },
      shouldInit: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         rules: {
            required: value => !!value || "Value is required!",
            email: value => !value || EMAIL_PATTERN.test(value) || "Invalid email address!",
            emails: value => { 
               let result = [];
               value.split(',').forEach(e => { if (!EMAIL_PATTERN.test(e)) result.push(`'${e}' is invalid!`); });
               return Boolean(result.length === 0) || result.join(', ');
            },
            endpoint: value => !value || URL_PATTERN.test(value) || "Invalid endpoint!",
            requiredJson: value => {
               try {
                  if (value) {
                     if (value.trim().indexOf('"') === 0)
                        return 'SyntaxError: Unexpected token in JSON at position 0';
                     const jsonVal = JSON.parse(value);
                     if (Object.keys(jsonVal).length > 0)
                        return true;
                  }
                  return 'Value is required!';
               } catch (error) {
                  return error.toString();
               }
            },
            validJson: value => {
               try {
                  if (value) {
                     if (value.trim().indexOf('"') === 0)
                        return 'SyntaxError: Unexpected token in JSON at position 0';
                     JSON.parse(value);
                  }
                  return true;
               } catch (error) {
                  return error.toString();
               }
            },
            notRequired: value => !value || "Value is not required!"
         },
         emails: [],
         endpoints: [],
         ftps: [],
         dialog: false,
         tab: 2,  //null,
         isEmailFormValid: false,    //false
         isEndpointFormValid: false, //false
         isSftpFormValid: false,     //false
         validations: [],
         myValidFields: [],
         isSaved: false,
         bodyRules: [],
         showPassword: false,
         panels: [0, 1]
      }
   },

   computed: {
      isSaveDisabled() {
         return !this.isEmailFormValid ||
            !this.isEndpointFormValid ||
            !this.isSftpFormValid ||
            !this.notifications;
      },
      notifications() {
         return this.emails[0].from || this.endpoints[0].method || this.ftps[0].host;
      }
   },

   watch: {
      shouldInit: {
         immediate: true,
         handler(val) {
            if (!val) return;

            if (this.validFields)
               this.myValidFields = this.validFields.map(vf => vf.toLowerCase());
            else this.myValidFields = [];
           
            if (this.value.length > 0)
               this.validations = this.value.filter(v => isIncluded(this.validFields, v.column));
            else
               this.validations = [];

            this.addValidation();
            this.log('in shouldInit(): value=' + JSON.stringify(this.value) +  '\nvalidations=' + JSON.stringify(this.validations));
         }
      },

      // tab: {
      //    // immediate: true,
      //    handler(val) {
      //       let form, field;
      //       switch (val) {
      //          case 0:
      //             form = 'emailForm';
      //             field = 'fromEmail_0';
      //             setTimeout(() => {
      //                this.$refs.emailForm.fromEmail.focus();
      //             }, 10);
      //             break;
      //          case 1:
      //             form = 'endpointForm';
      //             field = 'method_0';
      //             break;
      //          case 2:
      //             form = 'sftpForm';
      //             field = 'host_0';
      //             break;
      //          default:
      //             break;
      //       }

      //       if (form) {
      //          const self = this;
      //          this.$nextTick(() => {
      //             self.$refs[form][field].focus();
      //          })
      //       }
      //    }
      // },

      isSaved: {
         immediate: true,
         handler(val) {
            this.$emit('form-validation', val);
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----BtNotifications V230411.1 says => ${msg}`);
      },

      async btnClicked() {

         // const publicKeyArmored = `-----BEGIN PGP PUBLIC KEY BLOCK-----

         // xlIEY367cRMIKoZIzj0DAQcCAwRx2QJPT4AQ2xAPpQQvg6jJ0lQUO4TzwY2E
         // hX0s5hGK1fBFxEBwYv5FN1dXIUKk8Dcs8HUlanVZzJSjNczjwAatzS1kYXZl
         // cit3ZWJpbmFyIDxkYXZlcit3ZWJpbmFyQG1pbmRmaXJlaW5jLmNvbT7CjAQQ
         // EwgAPgUCY367cQQLCQcICRB5y3JijLG9ggMVCAoEFgACAQIZAQIbAwIeARYh
         // BJpXudKFgHIrLi2Bd3nLcmKMsb2CAAB/vwEAuoxNIA8+xUFWNbZl1hWeJQPT
         // VCcG5VXwieHNSDCX2TgBANojdXqpJI9JRdSDX6OljJ3Zzj1XXo0mDwThvGtI
         // UwfNzlYEY367cRIIKoZIzj0DAQcCAwRgjxDiLQWCjAWUecWdemcR3dE5sQfw
         // CQXOo+/qQ1ttqsBcucz0m9ttdvO0l84mzSfVOS+WrO1nsI0dery289KlAwEI
         // B8J4BBgTCAAqBQJjfrtxCRB5y3JijLG9ggIbDBYhBJpXudKFgHIrLi2Bd3nL
         // cmKMsb2CAABTegD7BSMjYgS06mFBJIh86PGNlA0kD2pF4PdX1DJU1ET1k+AA
         // /1AQOBV6K+K4vM/E1ERx8DrLtZpLWd2Bu7GglkOEH5jv
         // =uYzU
         // -----END PGP PUBLIC KEY BLOCK-----
         // `;
         // revocationCertificate also can be added to the end of const.
         // const { publicKey: revokedKeyArmored } = await revokeKey({
         //    key: await readKey({ armoredKey: publicKeyArmored }),
         //    format: 'armored' // output key format, defaults to 'armored' (other options: 'binary' or 'object')
         // });

         // alert(revokedKeyArmored); // '-----BEGIN PGP PUBLIC KEY BLOCK ... '

         // alert('in btnClicked');
         // this.shouldInit = true;
         const notifications = JSON.parse(JSON.stringify(this.value));
         this.emails = notifications.filter(n => n.type === 'email');
         this.endpoints = [];
         notifications.filter(n => n.type === 'http').forEach(e => {
            // alert(JSON.stringify(e));
            const endpoint = {
               type: e.type,
               method: e.method,
               endpoint: e.endpoint,
               body: e.method === 'get' ? '' : JSON.stringify(e.body),
               headers: JSON.stringify(e.headers)
            };
            if (hasOwn(e, 'signedUrlExpiryBySecond'))
               endpoint.signedUrlExpiryBySecond = e.signedUrlExpiryBySecond;
            else
               endpoint.signedUrlExpiryBySecond = 600;

            this.endpoints.push(endpoint);
         });
         this.ftps = notifications.filter(n => n.type === 'sftp');

         this.addEmail();
         this.addEndpoint();
         this.addFtp();
         this.dialog = true;

         // alert(this.tab)
         // this.tab = 1;
         setTimeout(() => {
            this.tab = 1;
         }, 1);
         setTimeout(() => {
            this.tab = 0;
         }, 1);
      },

      getFromEmailRules(index) {
         const fieldRules = [];
         // if (index < this.emails.length - 1 
         //    || (this.required && index === 0)
         //    || this.emails[index].from
         // ) {
         //    fieldRules.push(this.rules.required);
         //    // fieldRules.push(this.rules.email);
         // }

         if (this.required && !index && !this.notifications)
            fieldRules.push(this.rules.required);

         fieldRules.push(this.rules.email);
         return fieldRules;
      },

      getMethodRules(index) {
         const fieldRules = [];
         if (this.required && !index && !this.notifications)
            fieldRules.push(this.rules.required);
         return fieldRules;
      },

      addEmail() {
         const len = this.emails.length;
         if (len === 0 || (this.emails[len - 1].from && len < this.max)) {
            this.emails.push({
               type: 'email',
               from: '',
               fromName: '',
               to: '',
               subject: '',
               htmlBody: '',
               textBody: ''
            });
         }

         if (this.emails[0].from)
            this.$nextTick(() => {
               if (this.$refs.emailForm)
                  this.$refs.emailForm.validate()
            });
      },

      removeEmail(index) {
         if (confirm('Are you sure?')) {
            this.emails.splice(index, 1);
            this.addEmail();
         }
      },

      addEndpoint() {
         const len = this.endpoints.length;
         if (len === 0 || (this.endpoints[len - 1].method && len < this.max)) {
            this.endpoints.push({
               type: 'http',
               method: '',
               endpoint: '',
               signedUrlExpiryBySecond: 600,
               body: '{}',
               headers: '{}'
            });
         }
      },

      methodChanged(val) {
         this.bodyRules = [];
         if (val) {
            if (val === 'get')
               this.bodyRules.push(this.rules.notRequired);
            else
               this.bodyRules.push(this.rules.requiredJson);
         }

         this.addEndpoint();

         if (this.endpoints[0].method) {
            this.$nextTick(() => {
               if (this.$refs.endpointForm)
               this.$refs.endpointForm.validate();
            })
         }
      },

      removeEndpoint() {},

      addFtp() {
         const len = this.ftps.length;
         if (len === 0 || (this.ftps[len - 1].method && len < this.max)) {
            this.ftps.push({
               type: 'sftp',
               host: '',
               folder: '',
               port: '22',
               user: '',
               password: '',
               pgpPublicKey: ''
            });
         }

         if (this.ftps[0].host)
            this.$nextTick(() => {
               if (this.$refs.sftpForm)
                  this.$refs.sftpForm.validate()
            });
      },
     
      resetClicked() { },

      cancelClicked() {
         this.$refs.emailForm.resetValidation();
         this.$refs.endpointForm.resetValidation();
         this.$refs.sftpForm.resetValidation();
         this.tab = null;
         this.dialog = false;
      },

      saveClicked() {
         if (this.$refs.emailForm.validate() && this.$refs.endpointForm.validate() && this.$refs.sftpForm.validate()) {
            const notifications = [...this.emails.filter(e => e.from)];
            this.endpoints.filter(e => e.method).forEach(e => {
               const endpoint = {
                  type: e.type,
                  method: e.method,
                  endpoint: e.endpoint,
                  body: e.method != 'get' && e.body ? JSON.parse(e.body) : {},
                  headers: e.headers ? JSON.parse(e.headers) : {}
               };
               if (e.method === 'post')
                  endpoint.signedUrlExpiryBySecond = e.signedUrlExpiryBySecond;
               
               notifications.push(endpoint);
            });
            this.ftps.filter(f => f.host).forEach(ftp => {
               notifications.push(ftp);
            });
            // alert('notifications=' + JSON.stringify(notifications));
            this.$emit('change', notifications);
            this.$emit('input', notifications);
            this.cancelClicked();
         }
      }
   },

   created() {
      // alert('in created()');
   },

   mounted() {
   }
}
</script>

<style scoped>
.v-expansion-panel-header, .v-expansion-panel-header--active {
   padding: 0 16px;
   min-height: 40px !important;
}
</style>
