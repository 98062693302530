<template>
    <div :style="{height:iframeHeight + 'px'}"  style="margin-top:0px;margin-bottom:0px;">
        <iframe
            ref="moduleFrame"  
            :src="iframeSRC" allowfullscreen
            scrolling="yes"
            frameborder="0"
            allowtransparency="true"
            style="width: 0; min-width: 100% !important; height:100%"

            >
        </iframe>
    </div>
</template>

<script>
export default {
    props: ['id'],
    data () {
        return {
            portalSettings: {},
            iframeSRC: '',
            iframeHeight: '0px',
            moduleHeightFactor: 0.885
        }
    },
    methods: {
        setThePortalSettings: async function(){
            this.portalSettings = this.$store.getters.deployment;
            //this.portalSettings = await (await fetch(process.env.BASE_URL + "settings.json")).json();
        },
        getSingleSignOnQueryString: function(){
            const ssoQuery = '?token=' + this.token + '&aid=' + this.account;
            console.log('ssoQuery: ', ssoQuery);
            return ssoQuery;
        },
        dvURLQueryString: function(){
            return `&dvURL=${encodeURIComponent(this.portalSettings.dvURL)}`;
        },
        updateIframe: function(id){
            if(id === 'workflows'){
                //this.iframeSRC = 'https://studioweb-prd.mdl.io/login' + this.getSingleSignOnQueryString() + this.dvURLQueryString();
                this.iframeSRC = '//studioweb.mdl.io/login' + this.getSingleSignOnQueryString() + this.dvURLQueryString();
                this.moduleHeightFactor = 0.885;
            }
            else if(id === 'objectives'){
                //this.iframeSRC = "https://studioweb-prd.mdl.io/mycampaigns" + this.getSingleSignOnQueryString() + this.dvURLQueryString();
                this.iframeSRC = "https://studioweb.mdl.io/mycampaigns" + this.getSingleSignOnQueryString() + this.dvURLQueryString();
                this.moduleHeightFactor = 2;
            }
            else if(id === 'assets'){
                this.iframeSRC = this.portalSettings.dvURL + this.getSingleSignOnQueryString() + "&goto=assetLibrary.php";
                this.moduleHeightFactor = 2;
            }
            else if(id === 'crm'){
                this.iframeSRC = this.portalSettings.dvURL + this.getSingleSignOnQueryString() + "&goto=crm.php";
                this.moduleHeightFactor = 2;
            }
            else if(id === 'market-intel'){
                this.iframeSRC = this.portalSettings.dvURL + this.getSingleSignOnQueryString() + "&goto=marketIntel.php";
                this.moduleHeightFactor = 2;
            }
            else if(id === 'user-profile'){
                this.iframeSRC = "https://studioweb.mdl.io/user" + this.getSingleSignOnQueryString();
                this.moduleHeightFactor = 2;
            }
            else if(id === 'settings'){
                this.iframeSRC = this.portalSettings.dvURL + this.getSingleSignOnQueryString() + "&goto=accountSetting_tabbed_settings_subs.php";
                this.moduleHeightFactor = 2;
            }
        },
        handleWindowResize: function(){
            this.iframeHeight = window.innerHeight - 48; //* this.moduleHeightFactor;
        },
        iframeLoaded: function(){
            //console.log('iframe url: ', this.$refs.moduleFrame.contentWindow.location);
        },
        receiveMessage: function(event){
            //below should be place in iframe
            //window.parent.postMessage('click', '*');
            
            //use below if you want to capture specific iframe domain event
            //if (event.origin == 'http://iframe.example.com') {}
            //alert("got message: " + event.data);

            console.log(`iframe messaged '${event.data}' at ${new Date()}`);

            if(event.data === 'click'){
                console.log(`iframe click signal at ${new Date()}`);

                var someLink = document.querySelector('iframe');
                this.simulateClick(someLink);
            }
        },
        simulateClick: function (elem) {
            // Create our event (with options)
            var evt = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            });
            // If cancelled, don't dispatch our event
            var canceled = !elem.dispatchEvent(evt);
        }
    },
    watch: { 
      	id: function(newVal, oldVal) { 
            this.updateIframe(newVal);
        },
        account: {
          async handler(val){
            this.updateIframe(this.id);
          },
          deep: true
        }
    },
    async created() {
        await this.setThePortalSettings();
        this.updateIframe(this.id);
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();

    },
    mounted(){
        this.$refs.moduleFrame.addEventListener('load', this.iframeLoaded, true);
    },
    beforeMount(){
        window.addEventListener('message', this.receiveMessage, false);
    },
    beforeDestroy(){
        this.$refs.moduleFrame.removeEventListener('load', this.iframeLoaded);
        window.removeEventListener('message', this.receiveMessage);

    },
    destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
    },
    computed: {
        account : function(){
            return this.$store.getters.user.account;
        },
        token: function(){
            return this.$store.getters.token;
        }
    }
}
</script>


