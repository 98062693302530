<template>
    <v-card :loading=loading :color=color :dark=dark :height="height - 20">
    <template slot="progress">
      <v-progress-linear
        indeterminate
      ></v-progress-linear>
    </template>        
    <v-toolbar class="pl-4 pr-4">
      <v-toolbar-title >
        <div class="subtitle-1">{{title}}</div>
        <div class="subtitle-2 font-weight-light" v-if="subTitle">{{subTitle}}</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>{{icon}}</v-icon>
      </v-btn>
    </v-toolbar>         
        <!--<v-card-title style="height:50%;" class="text-h6" outlined >
            {{title}}
        </v-card-title>-->
        <v-card-text style="height:80%;" class="full-height">
            <v-row style="height:100%;" align="center" justify="center" class="px-5 py-1">
                <v-col class="display-1 text-center">
                    {{shortenNumber(value)}}
                </v-col>
            </v-row>            
        </v-card-text>
        <!--<v-container style="height:0%;">
            <v-row align="center" justify="center" class="px-5 py-1">
                <v-col class="text-h4 text-center">
                    {{shortenNumber(value)}}
                </v-col>
            </v-row>
        </v-container>               -->
    </v-card>
    
</template>
<style scoped>
.v-application .subtitle-2 {
    margin-top: -2px;
    font-size: 0.75rem !important;
    font-weight: normal;
    letter-spacing: 0.009375em !important;
    line-height: 0.875rem;
    font-family: "Roboto", sans-serif !important;
}
</style>
<script>
import utils from '../services/KCUtils.js'
export default {
    props: {
        height: {
            type: Number,
            default: 350,
        },
        title: {
            type: String,
            default: 'TITLE',
        },
        subTitle: {
            type: String,
        },
        value: {
            default: 0,
        },
        color: {
            type: String,
            default: "white",
        },
        dark: {
            type: Boolean,
        },
        icon: {
            default: "mdi-account-multiple",
        },
        loading: {
            default: false,
        },
    },
    methods:{
        shortenNumber: utils.shortenNumber,
    }    
};
</script>
