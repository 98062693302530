<template>
<!-- 
   WHAT: This page was re-developed from Aref's page.
HISTORY: no-version
   V230306.1: Sorted records descending (newer file on the top).
   V221024.2: Fixed the delete issue that showed counts incorrectly (used the table's search).
   V221024.1: 1st release.
-->
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title class="pl-2">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pl-0 pr-2">cloud_download</v-icon>
            <span>Downloads</span>
         </h1>
         <div class="flex-grow-1"></div>
            <!-- @keyup="searchChanged"
            @click:clear="searchCleared" -->
         <v-text-field clearable
            label="Search"
            append-icon="search"
            :disabled="!downloads.length"
            v-model="searchString"
         ></v-text-field>
      </v-card-title>

      <v-card-text class="pt-0 pb-2">
         <v-data-table dense
            class="elevation-1 mb-2"
            :footer-props="{
               itemsPerPageOptions:[5, 10, 20],
               showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="downloads.length <= 5"
            item-key="identifier"
            :items="downloads"
            :items-per-page="5"
            :loading="loading"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'downloads' })"
            :no-results-text="$t('no-results-text', { value: 'downloads' })"
            :search="searchString"
         >
            <template v-slot:[`item.size`]="{ item }">
               {{ item.size | kb }}
            </template>
            <template v-slot:[`item.date`]="{ item }">
               {{ item.date | formatDate }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
               <v-chip small
                  class="complete white--text my-2 caption"
                  style='cursor: pointer;'
                  :disabled="!hasUserPermission('bi-downloads-download')"
                  @click='signS3URL(item.identifier)'
               >download</v-chip>
               &nbsp;
               <v-chip small
                  class="overdue white--text my-2 caption"
                  style='cursor: pointer;'
                  :disabled="!hasUserPermission('bi-downloads-delete')"
                  @click='deleteS3Resource(item.identifier)'
               >delete</v-chip>
            </template>
         </v-data-table>
      </v-card-text>
   </v-card>
</v-container>
</template>

<script>
import axios from 'axios';
import { format, parseISO } from "date-fns";

const NAME = "Downloads";

export default {
   name: NAME,

   filters: {
      kb(value) {
         if (!value)
            return '';
         else if (Number(value) > 1000000) {
            value = (Number(value) / 1024 / 1024).toFixed(2);
            return value + ' MB';
         } else if (Number(value) > 1000) {
            value = (Number(value) / 1024).toFixed(2);
            return value + ' KB';
         } else
            return value;
      },

      formatDate(date) {
         // alert('in formatDate(): date=' + date);
         if (date)
            return format(parseISO(date), 'MM/dd/yyyy hh:mm:ss a');
         else
            return '';
      }
   },

   data() {
      return {
         headers: [
            { text: 'Identifier', value: 'identifier', align: 'left', sortable: true },
            { text: 'Size', value: 'size', align: 'left', sortable: true },
            { text: 'Date', value: 'date', align: 'left', sortable: true },
            { text: '', value: 'actions', align: 'right', sortable: false }
         ],
         loading: false,
         downloads: [],
         searchString: ''
      }
   },

   computed: {
      account() {
         return this.$store.getters.account;
      }
   },

   watch: {
      account() {
         // alert('in watch for account');
         this.init();
      }
   },

   methods: {
      parseError(err, errMsg) {
         if (err && err.response) {
            if (err.response.status === 401)
               this.logout();
            else if (err.response.status === 403)
               alert(errMsg);
            else if (err.request)
               alert(JSON.stringify(err.request));
            else
               alert(err.message);
         }
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         this.loading = true;
         this.downloads = [];

         if (this.account) {
            try {
               const resp = await this.$http.get();
               // alert('in init(): resp=' + JSON.stringify(resp));
               const data = Array.isArray(resp.data) ? resp.data : resp.data.files;
               this.downloads = data.filter(file => !file.identifier.endsWith("000") && !file.identifier.endsWith("000.gz"));
               this.downloads.sort((d1, d2) => d1.date < d2.date ? 1 : -1);
               // alert('in init(): downloads=' + JSON.stringify(this.downloads));
            } catch (err) {
               this.parseError(err, 'User has no permission to access downloads.');
            }
         }
         
         this.loading = false;         
      },

      hasUserPermission(policyName) {
         //alert(this.$store.getters.user.policies);
         return this.$store.getters.user.policies.includes(policyName);
      },

      // searchChanged() {
      //    const searchString = this.searchString.trim().toLowerCase();

      //    if (searchString)
      //       this.tableItems = this.downloads.filter(d => d.identifier.toLowerCase().indexOf(searchString) > -1);
      //    else
      //       this.tableItems = this.downloads;
      // },

      // searchCleared() {
      //    this.searchString = '';
      //    this.searchChanged();
      // },

      async signS3URL(identifier) {
         try {
            const resp = await this.$http.get('/' + identifier);
            // alert('in signS3URL(): resp=' + JSON.stringify(resp));
            window.open(resp.data.url, '_blank'); 
         } catch (err) {
            this.parseError(err, 'User has no permission to download.')
         }
      },

      async deleteS3Resource(identifier) {
         try {
            const resp = await this.$http.delete('/' + identifier);
            // alert('in deleteS3Resource(): resp=' + JSON.stringify(resp));
            this.downloads.splice(this.downloads.findIndex(v => v.identifier === identifier), 1);
         } catch (err) {
            this.parseError(err, 'User has no permission to delete a download.')
         }
      }
   },

   created() {
      this.$http = axios.create({
         baseURL: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/downloads'
         //baseURL: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/downloads'
      });

      this.init();
   }
}
</script>

<style>
.v-chip.complete {
   background: #3cd1c2 !important; 
}
.v-chip.overdue {
   background: #f83e70  !important;
}
</style>