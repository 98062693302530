<template>
    <v-content>
      <v-container fill-height>
        <v-row justify="center">
          <v-col cols="4">
            <v-file-input
                accept=".csv"
                label="Click here to select a .csv file"
                outlined
                show-size
                v-model="chosenFile"
                @change="importTxt"
            >
            </v-file-input>  
          </v-col>
        </v-row>
      </v-container>
    </v-content>
</template>

<script>
//import { parse } from "csv-parse/sync";
export default {
    emits: ['previewReady'],
    data() {
        return {
            chosenFile: null,
            previewData: null,
            headers: ['latitude','longitude']
        }
    },
    methods: {
        csvToJson(input){
            const lines = input.split('\n') // 1️⃣
            const header = lines[0].split(',') // 2️⃣
            const output = lines.slice(1).map(line => {
                const fields = line.split(',') // 3️⃣
                return Object.fromEntries(header.map((h, i) => [h, fields[i]])) // 4️⃣
            })

            //console.log(output)
            return output;
        },
        importTxt() {
            if (!this.chosenFile) {this.previewData = "No File Chosen"}
            var reader = new FileReader();
            var blob = this.chosenFile.slice(0,1024);
            // Use the javascript reader object to load the contents
            // of the file in the v-model prop
            reader.readAsText(blob);
            reader.onload = () => {
                let result = reader.result;
                this.previewData = this.csvToJson(result)[0];
                this.$emit('preview-ready', this.previewData);
            }
        }
    }
}
</script>