<template>
   <!-- 
   * WHAT: autocomplete component with 'All' option.
   * HISTORY: no-version
   - 03/24/22(B0.3): Fixed a bug in switchChanged() that didn't set selectedItems properly +
      Added returnObject prop and related logic.
   - 07/28/21(B0.2): Added watch for value (v-model) to reset selected fields and all.
   - 07/20/21(B0.1): 1st Release.
   -->
   <v-autocomplete multiple
      v-model="selectedItems"
      autocomplete="off"
      :append-icon="appendIcon"
      :append-outer-icon="appendOuterIcon"
      :auto-focus="autoFocus"
      :auto-select-first="autoSelectFirst"
      :background-color="backgroundColor"
      :chips="chips"
      :clearable="clearable"
      :color="color"
      :counter="counter"
      :dark="dark"
      :deletable-chips="deletableChips"
      :dense="dense"
      :disable-lookup="disableLookup"
      :disabled="disabled"
      :filled="filled"
      :error-messages="errorMessages"
      :flat="flat"
      :full-width="fullWidth"
      :height="height"
      :hide-details="hideDetails"
      :hide-no-data="hideNoData"
      :hide-selected="hideSelected"
      :hint="hint"
      :id="id"
      :item-color="itemColor"
      :item-text="itemText"
      :item-value="itemValue"
      :items="items"
      :label="label"
      :light="light"
      :loading="loading"
      :open-on-clear="openOnClear"
      :outlined="outlined"
      :persistent-hint="persistentHint"
      :placeholder="placeholder"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :readonly="readonly"
      :return-object="returnObject"
      :reverse="reverse"
      :rounded="rounded"
      :rules="rules"
      :single-line="singleLine"
      :small-chips="smallChips"
      :solo="solo"
      :solo-inverted="soloInverted"
      @change="selectedItemsChanged"
   >
      <template v-slot:prepend-item>
         <v-switch
            class="pl-4 py-0"
            v-model="all"                                 
            @change="switchChanged"
         >
            <template v-slot:label class="py-0">
               <div class="px-1 py-0 my-0 body-2 font-weight-bold font-italic text-no-wrap">{{switchLabel}}</div>
            </template>
         </v-switch>
         <!-- <v-checkbox
            class="pl-4 py-0"
            v-model="all"
            @change="allChanged"
         >
            <template v-slot:label class="py-0">
               <div class="px-1 py-0 my-0 body-2 font-weight-bold font-italic text-no-wrap">Select All</div>
            </template>
         </v-checkbox> -->
         <v-divider class="my-0 py-0"></v-divider>
      </template>
   </v-autocomplete>
</template>

<script>
export default {
   name: "BtAutocompleteWithAll",

   props: {
      value: {
         type: Array,
         default: () => []
      },
      allSelected: {
         type: Boolean,
         default: false
      },
      selectAllLabel: {
         type: String,
         default: 'Select All Items'
      },
      deselectAllLabel: {
         type: String,
         default: 'Deselect All Items'
      },

      appendIcon: {
         type: String,
         default: '$dropdown'
      },
      
      appendOuterIcon: {
         type: String
      },

      autoFocus: {
         type: Boolean,
         default: false
      },

      autoSelectFirst: {
         type: Boolean,
         default: false
      },
      
      backgroundColor: {
         type: String
      },

      chips: {
         type: Boolean,
         default: false
      },
      
      clearable: {
         type: Boolean,
         default: false
      },
      
      color: {
         type: String
      },

      counter: {
         type: [Boolean, Number, String]
      },
      
      dark: {
         type: Boolean,
         default: false
      },
      
      deletableChips: {
         type: Boolean,
         default: false
      },
      
      dense: {
         type: Boolean,
         default: false
      },
      
      disableLookup: {
         type: Boolean,
         default: false
      },

      disabled: {
         type: Boolean,
         default: false
      },
      
      errorMessages: {
         type: [String, Array],
         default: () => []
      },
      
      filled: {
         type: Boolean,
         default: false
      },
      
      flat: {
         type: Boolean,
         default: false
      },
      
      fullWidth: {
         type: Boolean,
         default: false
      },
      
      height: {
         type: [Number, String]
      },

      hideDetails: {
         type: [Boolean, String]
      },
      
      hideNoData: {
         type: Boolean,
         default: false
      },
      
      hideSelected: {
         type: Boolean,
         default: false
      },
       
      hint: {
         type: String
      },
      
      id: {
         type: String
      },
       
      itemColor: {
         type: String,
         default: 'primary'
      },
       
      itemText: {
         type: String,
         default: 'text'
      },
       
      itemValue: {
         type: String,
         default: 'value'
      },
       
      items: {
         type: Array,
         default: () => []
      },
       
      label: {
         type: String,
         default: ''
      },
       
      light: {
         type: Boolean,
         default: false
      },
      
      loading: {
         type: [Boolean, String],
         default: false
      },
      
      // multiple: {
      //    type: [Boolean, String],
      //    default: true
      // },
       
      openOnClear: {
         type: Boolean,
         default: false
      },
      
      outlined: {
         type: Boolean,
         default: false
      },
      
      persistentHint: {
         type: Boolean,
         default: false
      },
      
      placeholder: {
         type: String
      },
      
      prependIcon: {
         type: String
      },
      
      prependInnerIcon: {
         type: String
      },
      
      readonly: {
         type: Boolean,
         default: false
      },
      
      returnObject: {
         type: Boolean,
         default: false
      },
      
      reverse: {
         type: Boolean,
         default: false
      },
      
      rounded: {
         type: Boolean,
         default: false
      },
      
      rules: {
         type: Array,
         default: () => []
      },
      
      singleLine: {
         type: Boolean,
         default: false
      },
      
      smallChips: {
         type: Boolean,
         default: false
      },

      solo: {
         type: Boolean,
         default: false
      },
      
      soloInverted: {
         type: Boolean,
         default: false
      },
      
   },

   data() {
      return {
         selectedItems: this.value,
         all: false
      }
   },

   computed: {
      switchLabel() {
         return this.all ? this.deselectAllLabel : this.selectAllLabel;
      }
   },

   watch: { 
      value: {
         // deep: true,
         handler (val) {
            // alert('in watch: value=' + JSON.stringify(val));
            this.selectedItems = val;
            this.all = val.length === this.items.length;
         }
      }
   },

   methods: {
      switchChanged(val) {
         // if (val) this.selectedItems = [...this.items];
         if (val) 
            this.selectedItems = this.returnObject ? [...this.items] : this.items.map(item => item.value);
         else 
            this.selectedItems = [];
         
         this.selectedItemsChanged(this.selectedItems);
      },

      selectedItemsChanged(val) {
         this.all = val.length === this.items.length;
         this.$emit('input', val);
         this.$emit('change', val);
      }
   },

   mounted() {
      // alert('in mounted()');
      if (this.allSelected)
         this.selectedItems = this.returnObject ? [...this.items] : this.items.map(item => item.value);
   }
}
</script>