<template>

    <div class="file">

        <v-container>
            <v-layout row align-center>
                <upload-btn ref="uploadButton" @file-update="update" title="File to Upload" accept=".csv,.zip,.gz,.txt" v-if="!noUpload" >
                    <template slot="icon-left">
                        <v-icon left>add</v-icon>
                    </template>
                </upload-btn>
                <v-btn color="primary" @click="onSubmit" :disabled="!canSubmit">Submit</v-btn>
                <!-- <v-btn color="success" @click="onSubmit" :disabled="true">Submit</v-btn> -->
                
            </v-layout>
            <v-layout row align-right>
                <v-progress-circular v-if="inProgress"
                    
                    color="primary"
                    :value="progress"
                >{{ progress }}</v-progress-circular>
                <h5>{{ message }}</h5>
            </v-layout>

        </v-container>

    </div>
    
</template>

<script>
import axios from 'axios';
import UploadButton from 'vuetify-upload-button';
import Papa from 'papaparse';
//import * as zip from "@zip.js/zip.js";

export default {
    name: 'ImportFileUpload',
    components: {
        'upload-btn': UploadButton
    },
    props: {
        notActive: Boolean,
        fileName: String,
        noUpload: Boolean,
        tagUpload: Boolean
    },
    data: function() {
        return {
            file: '',
            message: '',
            inProgress: false,
            progress: 0
        }
    },
    methods: {
        unzip: async function(blob, size){

            let text = null;

            // create a BlobReader to read with a ZipReader the zip from a Blob object
            const reader = new window.zip.ZipReader(new window.zip.BlobReader(blob));

            // get all entries from the zip
            const entries = await reader.getEntries();
            if (entries.length) {

                // get first entry content as text by using a TextWriter
                text = await entries[0].getData(
                    // writer
                    new window.zip.TextWriter(),
                    // options
                    { 
                    onprogress: (index, max) => {
                        // onprogress callback
                    }
                    }
                );
                // text contains the entry data as a String
                //console.log(text);

            }

            // close the ZipReader
            await reader.close();
            return text.substring(0, size);
        },
        unzipPartial: async function(blob, size){

            const allowedExtensions = /(\.zip|\.csv|\.txt)$/i;
            let stringResult = "";

            const writer = {        // custom text writer to keep ongoin result before abort 
                textDecoder: new TextDecoder(),
                writable: new WritableStream({
                    write(chunk) {
                        stringResult += writer.textDecoder.decode(chunk)
                    },
                    close() {
                    }
                })
            };

            const zipFileReader = new window.zip.BlobReader(blob);
            const zipReader = new window.zip.ZipReader(zipFileReader);
            const firstEntry = (await zipReader.getEntries()).shift();
            
            if (!allowedExtensions.exec(firstEntry.filename)) {
                throw new Error(`First file in zip (${firstEntry.filename})  is not .csv or .txt.`);
            }

            // create controller and signal for aboring
            const controller = new AbortController();
            const signal = controller.signal;

            try{
                var ret = await firstEntry.getData(writer,{
                    onstart: (total)=>{
                    },
                    onprogress: (progress, total)=>{
                        controller.abort();
                    },
                    onend: (computedSize)=>{
                    },
                    signal,
                });
            } catch(ex){
            }

            await zipReader.close();

            return stringResult.substring(0, size);
        },
        update: async function(file){

            this.file = file;
            const self = this;

            if(file && (file.name.toLowerCase().endsWith('.csv') || file.name.toLowerCase().endsWith('.txt'))){

                Papa.parse(file.slice(0, 5 * 1024), {
                    complete: function(results, slicedFile) {
                        //console.log("Parsing complete:", results, slicedFile);
                        const fileObject = {fileName: file.name, fileSize: file.size, fileType: file.type, fileData: results};
                        console.log("Parsing csv complete:", fileObject);
                        this.message = "";
                        self.$emit("file-selected", fileObject);
                    }
                })

            }  else if(file && file.name.toLowerCase().endsWith('.zip')){

                try {
                    Papa.parse(await this.unzipPartial(file, 5 * 1024), {
                        complete: function(results, slicedFile) {
                            //console.log("Parsing complete:", results, slicedFile);
                            const fileObject = {fileName: file.name, fileSize: file.size, fileType: file.type, fileData: results};
                            console.log("Parsing zip complete:", fileObject);
                            this.message = "";
                            self.$emit("file-selected", fileObject);
                        }
                    })
                } catch (error) {
                    const fileObject = {fileName: file.name, fileSize: file.size, fileType: file.type};
                    console.log("Parsing zip error");
                    this.message = "";
                    self.$emit("file-selected", fileObject);
                }

            } else if(file){
                this.message = "";
                this.$emit("file-selected", {fileName: file.name, fileSize: file.size, fileType: file.type});
            }
            
        },
        onSubmit: async function() {
            if(this.noUpload){
                this.$emit("uploaded", {fileName: 'overrided-by-sftp-file'});
                return;
            }

            const formdata = new FormData();
            formdata.append('file', this.file);

            try{
                
                const result = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/contact-service/v1/uploads/' + ((this.fileName) ? this.fileName : this.file.name) + "?type=" + this.file.type + ((this.folder) ? "&folder=" + this.folder : "") + ((this.isPublic) ? "&isPublic=" + this.isPublic : "")+ ((this.tagUpload) ? "&tagUpload=" + this.tagUpload : ""), method: 'GET' })
                
                this.inProgress = true;
                
                let xhr = new XMLHttpRequest();
                let self = this;
                xhr.upload.addEventListener("progress", function(evt){
                    if (evt.lengthComputable) {
                        //console.log("add upload event-listener " + evt.loaded + "/" + evt.total);
                        self.progress = ((evt.loaded / evt.total) * 100).toFixed(0);
                    }
                }, false);

                xhr.onloadstart = function (e) {
                    console.log("start")
                }
                xhr.onloadend = function (e) {
                    self.$emit("uploaded", {fileName: self.file.name, fileSize: self.file.size, fileType: self.file.type});
                    self.inProgress = false;
                    let elapsed = new Date().getTime() - start;
                    //self.message = "Uploaded " + (self.file.size/1024).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " KB in " + elapsed/1000 + " seconds.";
                    try{
                        self.$refs.uploadButton.clear();
                    }
                    catch(err){

                    }
                    self.progress =0;
                    console.log("end")
                }
                let start = new Date().getTime();
                xhr.open('PUT', result.data.url, true);
                xhr.send(this.file);
            }
            catch(err){
                console.log(err);
                this.message = "Something went wrong!"
            }

        }
    },
    computed: {
        canSubmit(){
            //inProgress || !file || notActive
            if(this.noUpload){
                if(!this.notActive)
                    return true;
                else
                    return false;
            } else {
                if(this.inProgress || !this.file || this.notActive)
                    return false;
                else
                    return true
            }
        }
    },
    created() {      
      this.message = '';
      //alert(this.noUpload);
    }
    
}
</script>

