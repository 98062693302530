<!-- HISTORY:
- 11/16/21(B0.3): Implemented group slot instead of item slot + Changed eventField to eventFields.
- 11/12/21(B0.2): Added tableData to data().
- 11/10/21(B0.1): 1st release.
-->
<template>
   <v-data-table dense :hide-default-header="false"
      class="elevation-1 mb-2"
      :footer-props="footerProps"
      :group-by="groupbyField"
      :headers="tableHeaders"
      :hide-default-footer="tableData.length <= footerProps.itemsPerPageOptions[0]"
      item-key="id"
      :items="tableData"
      :items-per-page="itemsPerPage"
      :loading="loadingTableData"
      loading-text="Loading data. Please wait..."
      no-data-text="No data are available."
      no-results-text="No matching data were found."
   >
      <template v-slot:group="props">
         <tr v-if="props.group && props.group != 'undefined'" class="v-row-group__header">
            <td :colspan="props.headers.length">{{ props.group }}</td>
         </tr>
         <tr v-for="(item, i) in props.items" :key="props.group + i || i">
            <td v-for="header in props.headers" :key="header.value + i">
               <!-- :class="`d-flex ${isNaN(item[header.value])?'justify-start':'justify-end'} ${item[eventField] === lastClick ? 'blue--text font-italic' : 'black--text'}`" -->
               <a v-if="header.value === clickableField && item[header.value]"
                  style="text-decoration: none;"
                  :class="`d-flex ${isNaN(item[header.value])?'justify-start':'justify-end'} ${areObjectsEqual(getClickObject(item), lastClick) ? 'blue--text font-italic' : 'black--text'}`"
                  href="#"
                  @click="cellClicked(item)"
               >{{ formatValue(item[header.value]) }}
               </a>
               <span v-else>{{item[header.value]}}</span>
            </td>
         </tr>
      </template>
   </v-data-table>
</template>

<script>
const NAME = "BtClickableTable";
const MSG = `-----${NAME} B0.3 says => `;

export default {
   name: NAME,

   props: {
      chartData: {
         type: Array,
         required: true,
         default: () => []
      },
      clickableField: {
         type: String,
         default: ''
      },
      debug: {
         type: Boolean,
         default: false
      },
      eventFields: {
         type: Array,
         default: () => []
      },
      footerProps: {
         type: Object,
         default: () => { 
            return {
               itemsPerPageOptions: [5, 10, 20],
               showFirstLastPage: true
            }
         }
      },
      groupbyField: {
         type: String,
         default: ''
      },
      headers: {
         type: Array,
         default: () => [] //[{text:'',value:''}]
      },
      itemsPerPage: {
         type: Number,
         default: 5
      },
      options: {
         type: Object,
         default: () => {
            return {
               "class":"elevation-1 mt-2 mb-2 font-weight-light caption",
               // "class":"font-weight-bold body-2 font-italic"
               "align": "right"
            }
         }
      }
   },

   data() {
      return {
         tableData: [],
         loadingTableData: true,
         backgroundClass: 'grey lighten-3',
         lastClick: '',
         tableGroups: []
      }
   },

   computed: { },

   watch: {
      chartData: {
         immediate: false,
         deep: true,
         handler() {
            // this.log('in chartData watch');
            this.init();
         }
      }
   },

   methods: {
      log(msg, isError) {
         if (isError)
            console.error(`${MSG}${msg}`);
         else if (this.debug) {
            console.log(`${MSG}${msg}`);
            // alert(`${MSG}${msg}`);
         }
      },

      formatValue(val) {
         if (isNaN(val))
            return val;
         else
            return new Intl.NumberFormat().format(val);
      },

      init() {
         // alert('init() started...');
         this.tableHeaders = [];
         this.tableData = [];
         if (!this.chartData || !this.chartData.length)
            return;

         this.loadingTableData = true;
         if (this.headers && this.headers.length)
            this.headers.forEach(h => {
               this.tableHeaders.push(this.getHeader(h.text, h.value));
            });
         else
            Object.keys(this.chartData[0]).forEach(key => {
               this.tableHeaders.push(this.getHeader(key.toString(), key.toString()));
            });

         // if (this.groupbyField)
         //    this.tableHeaders.unshift(this.getHeader('Question', ''));

         this.tableData = [...this.chartData]

         if (this.groupbyField)
            this.tableGroups = new Set(this.tableData.map(d => d[this.groupbyField]));

         this.loadingTableData = false;
         this.log(`in init(): tableHeaders=${JSON.stringify(this.tableHeaders)}`);
         this.log(`in init(): tableData=${JSON.stringify(this.tableData)}`);
      },

      getHeader(label, id) {  //, addClass
         const header = { text: label, value: id, sortable: Boolean(id)};
         // if (this.options.hasOwnProperty('class'))
         //    header.class = this.options.class;
         // if (this.options.hasOwnProperty('align'))
         //    header.align = this.options.align;
         // else
            header.align = 'start';
         // if (addClass)
         //    header.class = this.backgroundClass;
         return header;
      },

      cellClicked(item) {
         // alert('item=' + JSON.stringify(item));
         let clickData = null;
         // const currClick = item[this.eventField];
         const currClick = this.getClickObject(item);

         if (this.areObjectsEqual(currClick, this.lastClick))
            this.lastClick = {};
         else {
            // clickData = {};
            // clickData[this.eventField] = currClick;
            clickData = currClick;
            this.lastClick = currClick;
         }

         this.log('in cellClicked(): clickData=' + JSON.stringify(clickData));
         this.$emit('click', clickData);
      },

      displayMe(item) {
         return JSON.stringify(item);
      },

      getClickObject(item) {
         const clickObj = {};
         this.eventFields.forEach(fld => {
            clickObj[fld] = item[fld];
         });
         return clickObj;
      },

      areObjectsEqual(obj1, obj2) {
         let result = true;
         Object.getOwnPropertyNames(obj1).forEach(prop => {
            if (obj1[prop] !== obj2[prop]) {
               result = false;
               return;
            }
         });
         return result;
      }
   },

   created() {
      // alert('groupbyField=' + this.groupbyField + ', clickableField=' + this.clickableField + ', eventField=' + this.eventField);
      this.log(`in created(): chartData=${JSON.stringify(this.chartData)}`);
      this.init();
   }
}
</script>
