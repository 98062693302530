<template>
    <Dash />
</template>

<script>
import Dash from '../components/Dash'
export default {
    components: {
           Dash
    }
    
}
</script>