<!-- HISTORY:
removing by partner
moving by user after accounts
with suspicious activity => suspicious
add Calls
   V231013.1: Added Service dropdown and passed the value to all methods but with suspicious activity.
   V231012.1: Added By Most Delay tab + Removed By Partner tab and moved By User after By Account.
   V231011.1: Added By User and By Partner tabs.
   V231010.1: Added source dropdown and passed the value to all methods but with suspicious activity.
   V231006.1: Added With Suspicious Activity tab.
   V231005.2: Replaced search field of byResource tab to the accounts dropdown and passed aid to its method.
   V231005.1: Added By Resource tab + Put back the Totals rows for By Service/Http Method/Status that was accidentally removed.
   V231004.1: Added 48 hours to the timespan and removed 7 days option + Formatted ISO dates of inStatusItems +
      Replaced search field of byService, byHttpMethod and byStatus tabs to the accounts dropdown and passed aid to their methods.
   V231003.2: Passed fromTo to getApiCallsInStatus();
   V231003.1: Added byStatus details (inStatusItems as expanded item).
   V231002.1: Changed all variable and method names + Added By Service, By HTTP Method and By Status tabs.
   V230929.1: 1st release (copied file from AsRemoteAccesses.vue V230920.1).
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">api</v-icon>
            <span>API Calls</span>
         </h1>
      </v-card-title>

      <v-card-text class="ml-2 px-5 pb-0">
         <v-row>
            <v-col xs="12" sm="12" md="3" class="py-2">
               <v-select dense hide-selected persistent-hint
                  :hint="timespanHint"
                  :items="timespanItems"
                  v-model="timespan"
                  @change="timespanChanged"
               ></v-select>
            </v-col>
            <v-col xs="12" sm="12" md="1" class="py-2">
               <v-select dense hide-selected persistent-hint
                  hint="Source"
                  :items="sourceItems"
                  :disabled="isSourceDisabled"
                  v-model="source"
                  @change="sourceChanged"
               ></v-select>
            </v-col>
            <v-col xs="12" sm="12" md="2" class="py-2">
               <v-select dense hide-selected persistent-hint
                  hint="Service"
                  :items="serviceItems"
                  :disabled="isServiceDisabled"
                  v-model="service"
                  @change="serviceChanged"
               ></v-select>
            </v-col>
            <v-col xs="12" sm="12" md="2" class="py-5 mt-1">
               <v-btn small
                  color="primary"
                  :disabled="!isCriteriaChanged"
                  @click="btnReportClicked"
               >Get Report
                  <v-icon right dark>summarize</v-icon>
               </v-btn>
            </v-col>
         </v-row>
      </v-card-text>

      <v-card-text>
         <v-tabs
            class="elevation-2"
            background-color="grey lighten-2 accent-4"
            slider-color="black"
            v-model="tab"
         >
            <v-tab>By Account</v-tab>
            <v-tab v-show="showByUser">By User</v-tab>
            <v-tab>By Service</v-tab>
            <v-tab>By HTTP Method</v-tab>
            <v-tab>By Status</v-tab>
            <v-tab>By Resource</v-tab>
            <v-tab>Calls</v-tab>
            <v-tab v-show="showWithSuspicious">Suspicious</v-tab>

            <v-tabs-items v-model="tab">
               <v-tab-item class="pt-5"><!-- Totals by Account -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-text-field clearable
                           class="py-0"
                           append-icon="search"
                           label="Search"
                           :disabled="!byAccountItems.length"
                           v-model="byAccountSearch"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header
                     class="elevation-1"
                     :footer-props="byAccountFooter"
                     :headers="byAccountHeaders"
                     :hide-default-footer="byAccountItems.length <= 5"
                     :items="byAccountItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="byAccountOptions"
                     :search="byAccountSearch"
                     @current-items="byAccountCurrItems"
                  >
                     <template v-slot:[`item.min`]="{item}">
                        {{ formatNumber(item.min) }}
                     </template>
                     <template v-slot:[`item.max`]="{item}">
                        {{ formatNumber(item.max) }}
                     </template>
                     <template v-slot:[`item.avg`]="{item}">
                        {{ formatNumber(item.avg) }}
                     </template>
                     <template v-slot:[`item.calls`]="{item}">
                        {{ formatNumber(item.calls) }}
                     </template>
                     <template v-slot:[`body.append`] v-if="byAccountTotals">
                        <tr class="font-weight-bold">
                           <td>Totals:</td>
                           <td colspan="7" align="right">{{formatNumber(byAccountTotals)}}</td>
                        </tr>
                     </template>
                  </v-data-table>
               </v-tab-item>

               <v-tab-item class="pt-5"><!-- Totals by User -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-autocomplete clearable dense hide-selected
                           append-icon="search"
                           placeholder="search for a specific account"
                           :items="accounts"
                           v-model="byUserAccount"
                           @change="byUserAccountChanged"
                        ></v-autocomplete>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header
                     class="elevation-1"
                     :footer-props="byUserFooter"
                     :headers="byUserHeaders"
                     :hide-default-footer="byUserItems.length <= 5"
                     :items="byUserItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="byUserOptions"
                  >
                     <template v-slot:[`item.min`]="{item}">
                        {{ formatNumber(item.min) }}
                     </template>
                     <template v-slot:[`item.max`]="{item}">
                        {{ formatNumber(item.max) }}
                     </template>
                     <template v-slot:[`item.avg`]="{item}">
                        {{ formatNumber(item.avg) }}
                     </template>
                     <template v-slot:[`item.calls`]="{item}">
                        {{ formatNumber(item.calls) }}
                     </template>
                     <template v-slot:[`body.append`] v-if="byUserTotals">
                        <tr class="font-weight-bold">
                           <td>Totals:</td>
                           <td colspan="7" align="right">{{formatNumber(byUserTotals)}}</td>
                        </tr>
                     </template>
                  </v-data-table>
               </v-tab-item>

               <v-tab-item class="pt-5"><!-- Totals by Service -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-autocomplete clearable dense hide-selected
                           append-icon="search"
                           placeholder="search for a specific account"
                           :items="accounts"
                           v-model="byServiceAccount"
                           @change="byServiceAccountChanged"
                        ></v-autocomplete>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header
                     class="elevation-1"
                     :footer-props="byServiceFooter"
                     :headers="byServiceHeaders"
                     :hide-default-footer="byServiceItems.length <= 5"
                     :items="byServiceItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="byServiceOptions"
                  >
                     <template v-slot:[`item.min`]="{item}">
                        {{ formatNumber(item.min) }}
                     </template>
                     <template v-slot:[`item.max`]="{item}">
                        {{ formatNumber(item.max) }}
                     </template>
                     <template v-slot:[`item.avg`]="{item}">
                        {{ formatNumber(item.avg) }}
                     </template>
                     <template v-slot:[`item.calls`]="{item}">
                        {{ formatNumber(item.calls) }}
                     </template>
                     <template v-slot:[`body.append`] v-if="byServiceTotals">
                        <tr class="font-weight-bold">
                           <td>Totals:</td>
                           <td colspan="7" align="right">{{formatNumber(byServiceTotals)}}</td>
                        </tr>
                     </template>
                  </v-data-table>
               </v-tab-item>

               <v-tab-item class="pt-5"><!-- Totals by HTTP Method -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-autocomplete clearable dense hide-selected
                           append-icon="search"
                           placeholder="search for a specific account"
                           :items="accounts"
                           v-model="byHttpMethodAccount"
                           @change="byHttpMethodAccountChanged"
                        ></v-autocomplete>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header
                     class="elevation-1"
                     :footer-props="byHttpMethodFooter"
                     :headers="byHttpMethodHeaders"
                     :hide-default-footer="byHttpMethodItems.length <= 5"
                     :items="byHttpMethodItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="byHttpMethodOptions"
                  >
                     <template v-slot:[`item.min`]="{item}">
                        {{ formatNumber(item.min) }}
                     </template>
                     <template v-slot:[`item.max`]="{item}">
                        {{ formatNumber(item.max) }}
                     </template>
                     <template v-slot:[`item.avg`]="{item}">
                        {{ formatNumber(item.avg) }}
                     </template>
                     <template v-slot:[`item.calls`]="{item}">
                        {{ formatNumber(item.calls) }}
                     </template>
                     <template v-slot:[`body.append`] v-if="byHttpMethodTotals">
                        <tr class="font-weight-bold">
                           <td>Totals:</td>
                           <td colspan="7" align="right">{{formatNumber(byHttpMethodTotals)}}</td>
                        </tr>
                     </template>
                  </v-data-table>
               </v-tab-item>

               <v-tab-item class="pt-5"><!-- Totals by Status -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-autocomplete clearable dense hide-selected
                           append-icon="search"
                           placeholder="search for a specific account"
                           :items="accounts"
                           v-model="byStatusAccount"
                           @change="byStatusAccountChanged"
                        ></v-autocomplete>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header show-expand single-expand
                     class="elevation-1"
                     item-key="_id"
                     :expanded="byStatusExpandedItem"
                     :footer-props="byStatusFooter"
                     :headers="byStatusHeaders"
                     :hide-default-footer="byStatusItems.length <= 5"
                     :items="byStatusItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="byStatusOptions"
                     @update:expanded="byStatusItemExpanded"
                  >
                     <template v-slot:[`item.min`]="{item}">
                        {{ formatNumber(item.min) }}
                     </template>
                     <template v-slot:[`item.max`]="{item}">
                        {{ formatNumber(item.max) }}
                     </template>
                     <template v-slot:[`item.avg`]="{item}">
                        {{ formatNumber(item.avg) }}
                     </template>
                     <template v-slot:[`item.calls`]="{item}">
                        {{ formatNumber(item.calls) }}
                     </template>
                     <template v-slot:[`body.append`] v-if="byStatusTotals">
                        <tr class="font-weight-bold">
                           <td>Totals:</td>
                           <td colspan="7" align="right">{{formatNumber(byStatusTotals)}}</td>
                        </tr>
                     </template>
                     <template v-slot:expanded-item="{ item }">
                        <td colspan="6" class="py-2 px-2" valign="top" dense v-if="item.inStatusItems">
                           <v-data-table dense fixed-header show-expand single-expand
                              class="elevation-1"
                              item-key="_id"
                              :expanded.sync="inStatusExpandedItems"
                              :footer-props="inStatusFooterProps"
                              :headers="inStatusHeaders"
                              :hide-default-footer="item.calls <= 5"
                              :items="item.inStatusItems"
                              :items-per-page="5"
                              :loading="loading"
                              :loading-text="$t('loading-text')"
                              :no-data-text="$t('no-data-text', { value: 'records' })"
                              :no-results-text="$t('no-results-text', { value: 'records' })"
                              :options.sync="inStatusOptions"
                              :server-items-length="item.calls"
                           >
                              <template v-slot:[`item.timestamp`]="{item}">
                                 {{ formatDate(item.timestamp) }}
                              </template>
                              <template v-slot:expanded-item="{ item }">
                                 <td>&nbsp;</td>
                                 <td colspan="6" class="py-2" valign="top" dense>
                                    <ul class="pl-3">
                                       <li>
                                          <span class="expanded-header">ID: </span>
                                          <span class="expanded-content">{{item._id}}</span>
                                       </li>
                                       <li>
                                          <span class="expanded-header">Message: </span>
                                          <span class="expanded-content">{{item.message}}</span>
                                       </li>
                                    </ul>
                                 </td>
                              </template>
                           </v-data-table>
                        </td>
                     </template>
                  </v-data-table>
               </v-tab-item>

               <v-tab-item class="pt-5"><!-- Calls by Resource -->
                  <v-row>
                     <v-col xs="12" sm="12" md="4" class="pl-5 py-1">
                        <v-radio-group dense row
                           v-model="selectedResource"
                           @change="resourceChanged"
                        >
                           <v-radio v-for="r in resources"
                              dense
                              :key="r"
                              :label="r"
                              :value="r"
                           ></v-radio>
                        </v-radio-group>
                     </v-col>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-autocomplete clearable dense hide-selected
                           append-icon="search"
                           placeholder="search for a specific account"
                           :items="accounts"
                           v-model="byResourceAccount"
                           @change="byResourceAccountChanged"
                        ></v-autocomplete>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header
                     class="elevation-1"
                     :footer-props="byResourceFooter"
                     :headers="byResourceHeaders"
                     :hide-default-footer="byResourceItems.length <= 5"
                     :items="byResourceItems"
                     :items-per-page="10"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="byResourceOptions"
                  >
                     <template v-slot:[`item.min`]="{item}">
                        {{ formatNumber(item.min) }}
                     </template>
                     <template v-slot:[`item.max`]="{item}">
                        {{ formatNumber(item.max) }}
                     </template>
                     <template v-slot:[`item.avg`]="{item}">
                        {{ formatNumber(item.avg) }}
                     </template>
                     <template v-slot:[`item.calls`]="{item}">
                        {{ formatNumber(item.calls) }}
                     </template>
                     <template v-slot:[`body.append`] v-if="byResourceTotals">
                        <tr class="font-weight-bold">
                           <td>Totals:</td>
                           <td colspan="7" align="right">{{formatNumber(byResourceTotals)}}</td>
                        </tr>
                     </template>
                  </v-data-table>
               </v-tab-item>

               <v-tab-item class="pt-5"><!-- by Most Delay -->
                  <v-row>
                     <v-spacer></v-spacer>
                     <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                        <v-autocomplete clearable dense hide-selected
                           append-icon="search"
                           placeholder="search for a specific account"
                           :items="accounts"
                           v-model="withMostDelayAccount"
                           @change="withMostDelayAccountChanged"
                        ></v-autocomplete>
                     </v-col>
                  </v-row>
                  <v-data-table dense fixed-header show-expand single-expand
                     class="elevation-1"
                     item-key="_id"
                     :footer-props="withMostDelayFooterProps"
                     :headers="withMostDelayHeaders"
                     :hide-default-footer="withMostDelayCount <= 5"
                     :items="withMostDelayItems"
                     :items-per-page="5"
                     :loading="loading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="withMostDelayOptions"
                     :server-items-length="withMostDelayCount"
                  >
                     <template v-slot:[`item.timestamp`]="{item}">
                        {{ formatDate(item.timestamp) }}
                     </template>
                     <template v-slot:[`item.responseTime`]="{item}">
                        {{ formatNumber(item.responseTime) }}
                     </template>
                     <template v-slot:expanded-item="{ item }">
                        <td>&nbsp;</td>
                        <td colspan="6" class="py-2" valign="top" dense>
                           <ul class="pl-3">
                              <li>
                                 <span class="expanded-header">ID: </span>
                                 <span class="expanded-content">{{item._id}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Message: </span>
                                 <span class="expanded-content">{{item.message}}</span>
                              </li>
                           </ul>
                        </td>
                     </template>
                  </v-data-table>
               </v-tab-item>

               <v-tab-item class="py-3 px-3"><!-- Calls with Suspicious Activity -->
                  <v-tabs
                     class="elevation-2"
                     background-color="grey lighten-2 accent-4"
                     slider-color="black"
                     v-model="suspiciousTab"
                  >
                     <v-tab>List</v-tab>
                     <v-tab>By IP</v-tab>

                     <v-tabs-items v-model="suspiciousTab">
                        <v-tab-item class="pt-5"><!-- With Suspicious List -->
                           <v-data-table dense fixed-header show-expand single-expand
                              class="elevation-1"
                              item-key="_id"
                              :footer-props="withSuspiciousFooter"
                              :headers="withSuspiciousHeaders"
                              :hide-default-footer="withSuspiciousCount <= 5"
                              :items="withSuspiciousItems"
                              :items-per-page="5"
                              :loading="loading"
                              :loading-text="$t('loading-text')"
                              :no-data-text="$t('no-data-text', { value: 'records' })"
                              :no-results-text="$t('no-results-text', { value: 'records' })"
                              :options.sync="withSuspiciousOptions"
                              :server-items-length="withSuspiciousCount"
                           >
                              <template v-slot:[`item.timestamp`]="{item}">
                                 {{ formatDate(item.timestamp) }}
                              </template>
                              <template v-slot:expanded-item="{ item }">
                                 <td>&nbsp;</td>
                                 <td colspan="6" class="py-2" valign="top" dense>
                                    <ul class="pl-3">
                                       <li>
                                          <span class="expanded-header">ID: </span>
                                          <span class="expanded-content">{{item._id}}</span>
                                       </li>
                                    </ul>
                                 </td>
                              </template>
                           </v-data-table>
                        </v-tab-item>

                        <v-tab-item class="pt-5"><!-- With Suspicious by IP -->
                           <v-row>
                              <v-spacer></v-spacer>
                              <v-col xs="12" sm="12" md="3" class="py-1 pr-5 mr-5">
                                 <v-text-field clearable
                                    class="py-0"
                                    append-icon="search"
                                    label="Search"
                                    :disabled="!byIpItems.length"
                                    v-model="byIpSearch"
                                 ></v-text-field>
                              </v-col>
                           </v-row>
                           <v-data-table dense fixed-header
                              class="elevation-1"
                              :footer-props="byIpFooter"
                              :headers="byIpHeaders"
                              :hide-default-footer="byIpItems.length <= 5"
                              :items="byIpItems"
                              :items-per-page="10"
                              :loading="loading"
                              :loading-text="$t('loading-text')"
                              :no-data-text="$t('no-data-text', { value: 'records' })"
                              :no-results-text="$t('no-results-text', { value: 'records' })"
                              :options.sync="byIpOptions"
                              :search="byIpSearch"
                              @current-items="byIpCurrItems"
                           >
                              <template v-slot:[`item.calls`]="{item}">
                                 {{ formatNumber(item.calls) }}
                              </template>
                              <template v-slot:[`body.append`] v-if="byIpTotals">
                                 <tr class="font-weight-bold">
                                    <td>Totals:</td>
                                    <td align="right">{{formatNumber(byIpTotals)}}</td>
                                 </tr>
                              </template>
                           </v-data-table>
                        </v-tab-item>
                     </v-tabs-items>
                  </v-tabs>
               </v-tab-item>
            </v-tabs-items>
         </v-tabs>
      </v-card-text>
   </v-card>
</v-container>
</template>

<script>
import { APIService } from '../services/as-api-service.js';
import { format, parseISO } from "date-fns";
// import { sleep } from '../mixins/bt-mixin.js';

const NAME = "AsApiCalls";
const TABS = { byAccount: 0, byUser: 1, byService: 2, byHttpMethod: 3, byStatus: 4, byResource: 5, withMostDelay: 6, withSuspicious: 7 };
const SUSPICIOUS_TABS = { list: 0, byIp: 1 };
const DATE_FORMAT = 'M/d/yyyy h:mm:ss a';
const CELL_CLASS = 'grey lighten-3';
const INSTATUS_CLASS = 'font-italic';

function _getUTCDateTime(dtIn) {
   const dtOut = dtIn.getUTCFullYear() + '-' +
      (dtIn.getUTCMonth() + 1).toString().padStart(2, "0") + '-' +
      dtIn.getUTCDate().toString().padStart(2, "0") + 'T' +
      dtIn.getUTCHours().toString().padStart(2, "0") + ":" +
      dtIn.getUTCMinutes().toString().padStart(2, "0") + ':' +
      dtIn.getUTCSeconds().toString().padStart(2, "0") + '.' +
      dtIn.getUTCMilliseconds().toString().padStart(3, "0") + 'Z';

   return dtOut;
}

function _compareItems (e1, e2) {
   return e1.text > e2.text ? 1 : -1;
}

export default {
   name: NAME,

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         timespanItems: [
            { text: 'hour', value: 1 },
            { text: '3 hours', value: 3 },
            { text: '6 hours', value: 6 },
            { text: '12 hours', value: 12 },
            { text: '24 hours', value: 24 },
            { text: '48 hours', value: 48 },
            { text: '72 hours', value: 72 }
         ],
         timespan: 1,
         timespanHint: '',         
         sourceItems: [
            { text: 'All', value: '' },
            { text: 'User', value: 'user' },
            { text: 'Partner', value: 'partner' },
            { text: 'JS SDK', value: 'jsSDK' }
         ],
         source: '',
         serviceItems: [
            { text: 'All', value: '' },
            { text: 'BI Service', value: 'bi' },
            { text: 'List Service', value: 'list' },
            { text: 'Scheduler Service', value: 'scheduler' }
         ],
         service: '',
         isCriteriaChanged: true,
         apiService: null,
         tab: null,
         loading: false,

         /* By Account */
         byAccountHeaders: [
            { text: 'Account Name', value: 'accountname', align: 'left', sortable: true },
            { text: 'Account ID', value: 'accountid', align: 'left', sortable: true },
            { text: 'Parent Name', value: 'parentname', align: 'left', sortable: true },
            { text: 'Parent ID', value: 'parentid', align: 'left', sortable: true },
            { text: 'Min Res Time (ms)', value: 'min', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Max Res Time (ms)', value: 'max', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Avg Res Time (ms)', value: 'avg', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Calls', value: 'calls', align: 'right', sortable: true, cellClass: CELL_CLASS }
         ],
         byAccountSearch: '',
         byAccountOptions: {},
         byAccountLastItemsPerPage: 10,
         byAccountFooter: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         byAccountItems: [],
         byAccountTotals: 0,
         accounts: [],

         /* By Service */
         byServiceHeaders: [
            { text: 'Service', value: '_id', align: 'left', sortable: true },
            { text: 'Min Res Time (ms)', value: 'min', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Max Res Time (ms)', value: 'max', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Avg Res Time (ms)', value: 'avg', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Calls', value: 'calls', align: 'right', sortable: true, cellClass: CELL_CLASS }
         ],
         byServiceAccount: '',
         byServiceOptions: {},
         byServiceFooter: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         byServiceItems: [],
         byServiceTotals: 0,

         /* By HTTP Method */
         byHttpMethodHeaders: [
            { text: 'Method', value: '_id', align: 'left', sortable: true },
            { text: 'Min Res Time (ms)', value: 'min', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Max Res Time (ms)', value: 'max', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Avg Res Time (ms)', value: 'avg', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Calls', value: 'calls', align: 'right', sortable: true, cellClass: CELL_CLASS }
         ],
         byHttpMethodAccount: '',
         byHttpMethodOptions: {},
         byHttpMethodLastItemsPerPage: 10,
         byHttpMethodFooter: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         byHttpMethodItems: [],
         byHttpMethodTotals: 0,

         /* By Status */
         byStatusHeaders: [
            { text: 'Status', value: '_id', align: 'left', sortable: true },
            { text: 'Min Res Time (ms)', value: 'min', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Max Res Time (ms)', value: 'max', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Avg Res Time (ms)', value: 'avg', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Calls', value: 'calls', align: 'right', sortable: true, cellClass: CELL_CLASS }
         ],
         byStatusExpandedItem: [],
         byStatusAccount: '',
         byStatusOptions: {},
         byStatusLastItemsPerPage: 10,
         byStatusFooter: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         byStatusItems: [],
         byStatusTotals: 0,
         currByStatusItem: {},
         inStatusHeaders: [
            { text: 'Date', value: 'timestamp', align: 'left', sortable: false, class: INSTATUS_CLASS },
            { text: 'Account ID', value: 'aid', align: 'left', sortable: false, class: INSTATUS_CLASS },
            { text: 'Parent ID', value: 'paid', align: 'left', sortable: false, class: INSTATUS_CLASS },
            { text: 'Email', value: 'email', align: 'left', sortable: false, class: INSTATUS_CLASS },
            { text: 'Method', value: 'method', align: 'left', sortable: false, class: INSTATUS_CLASS },
            { text: 'IP Address', value: 'ip', align: 'left', sortable: false, class: INSTATUS_CLASS }
         ],
         inStatusFooterProps: {
            itemsPerPageOptions: [5, 10, 15, 20],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         inStatusOptions: {},
         inStatusExpandedItems: [],

         /* By Resource */
         byResourceHeaders: [
            { text: 'Resource', value: '_id', align: 'left', sortable: true },
            { text: 'Min Res Time (ms)', value: 'min', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Max Res Time (ms)', value: 'max', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Avg Res Time (ms)', value: 'avg', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Calls', value: 'calls', align: 'right', sortable: true, cellClass: CELL_CLASS }
         ],
         byResourceAccount: '',
         byResourceOptions: {},
         byResourceLastItemsPerPage: 10,
         byResourceFooter: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         byResourceItems: [],
         byResourceTotals: 0,
         resources: ['Get', 'Post', 'Put', 'Delete'],
         selectedResource: 'Get',

         /* With Suspicious */
         suspiciousTab: 0,
         withSuspiciousHeaders: [
            { text: 'Date', value: 'timestamp', align: 'left', sortable: false, class: INSTATUS_CLASS },
            { text: 'Method', value: 'method', align: 'left', sortable: false, class: INSTATUS_CLASS },
            { text: 'Status', value: 'status', align: 'left', sortable: false, class: INSTATUS_CLASS },
            { text: 'Message', value: 'message', align: 'left', sortable: false, class: INSTATUS_CLASS },
            { text: 'IP Address', value: 'ip', align: 'left', sortable: false, class: INSTATUS_CLASS }
         ],
         withSuspiciousOptions: {},
         withSuspiciousFooter: {
            itemsPerPageOptions: [5, 10, 15, 20],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         withSuspiciousCount: 0,
         withSuspiciousItems: [],

         /* By IP */
         byIpHeaders: [
            { text: 'IP Address', value: '_id', align: 'left', sortable: true },
            { text: 'Calls', value: 'calls', align: 'right', sortable: true, cellClass: CELL_CLASS }
         ],
         byIpSearch: '',
         byIpOptions: {},
         byIpLastItemsPerPage: 10,
         byIpFooter: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         byIpItems: [],
         byIpTotals: 0,

         /* By User */
         byUserHeaders: [
            { text: 'User', value: '_id', align: 'left', sortable: true },
            { text: 'Min Res Time (ms)', value: 'min', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Max Res Time (ms)', value: 'max', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Avg Res Time (ms)', value: 'avg', align: 'right', sortable: true, cellClass: CELL_CLASS },
            { text: 'Calls', value: 'calls', align: 'right', sortable: true, cellClass: CELL_CLASS }
         ],
         byUserAccount: '',
         byUserOptions: {},
         byUserFooter: {
            itemsPerPageOptions: [5, 10, 15, 20, -1],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         byUserItems: [],
         byUserTotals: 0,

         /* By Most Delay */
         withMostDelayHeaders: [
            { text: 'Date', value: 'timestamp', align: 'left', sortable: false },
            { text: 'Account ID', value: 'aid', align: 'left', sortable: false },
            { text: 'Parent ID', value: 'paid', align: 'left', sortable: false },
            { text: 'Email', value: 'email', align: 'left', sortable: false },
            { text: 'Method', value: 'method', align: 'left', sortable: false },
            { text: 'IP Address', value: 'ip', align: 'left', sortable: false },
            { text: 'Response Time (ms)', value: 'responseTime', align: 'right', sortable: false, cellClass: CELL_CLASS }
         ],
         withMostDelayAccount: '',
         withMostDelayCount: 0,
         withMostDelayItems: [],
         withMostDelayFooterProps: {
            itemsPerPageOptions: [5, 10, 15, 20],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         withMostDelayOptions: {},
         withMostDelayExpandedItems: []
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      isSourceDisabled() {
         return this.tab === TABS.withSuspicious || this.tab === TABS.byUser;
      },

      isServiceDisabled() {
         return this.tab === TABS.withSuspicious;
      },

      showByUser() {
         return !this.source || this.source === 'user';
      },

      showWithSuspicious() {
         return !this.source && !this.service;
      }
   },

   watch: {
      token() {
         this.init();
      },

      async tab(val) {
         if (val) {
            await this.getReport();
         }
      },

      inStatusOptions: {
         handler() {
            this.getInStatusItems();
         }
      },

      async suspiciousTab() {
         await this.getReport();
      },

      async withSuspiciousOptions() {
         await this.getWithSuspiciousItems();
      },

      async withMostDelayOptions() {
         await this.getWithMostDelayItems();
      },
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V231013.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      formatNumber(number, maxFractionDigits) {
         return new Intl.NumberFormat('en-US', 
            { 
               maximumFractionDigits: maxFractionDigits || 0
            }).format(number);
      },

      async init() {
         // _alert('in init(): token=' + this.token);
         if (this.token) {
            this.apiService = new APIService(this.token, this.debug, this.isActualEndpoint, '/api/edge-service/v1/admins');
            setTimeout(() => {
               // this.timespanChanged(this.timespan);
               this.btnReportClicked();
            }, 100);
         }
      },

      timespanChanged() {
         this.isCriteriaChanged = true;
      },

      sourceChanged() {
         this.isCriteriaChanged = true;
      },

      serviceChanged() {
         this.isCriteriaChanged = true;
      },

      btnReportClicked() {
         Object.keys(TABS).forEach(tab => {
            this[`${tab}Items`] = [];
         });
         this.byIpItems = [];

         const to = new Date();
         const from = new Date();
         from.setHours(from.getHours() - this.timespan);
         this.timespanHint = `${format(from, DATE_FORMAT)} ~ ${format(to, DATE_FORMAT)}`;
         this.fromTo = `from=${_getUTCDateTime(from)}&to=${_getUTCDateTime(to)}`;

         this.getReport();
         this.isCriteriaChanged = false;
      },

      async getReport(tab) {
         if (tab == undefined)
            tab = this.tab;

         // alert(`in getReport(): tab=${tab}`);
         switch (tab) {
            case TABS.byAccount:
               if (!this.byAccountItems.length)
                  await this.getByAccountItems();
               break;
            case TABS.byService:
               if (!this.byServiceItems.length) {
                  await this.getByServiceItems();
                  await this.getReport(TABS.byAccount);
               }
               break;
            case TABS.byHttpMethod:
               if (!this.byHttpMethodItems.length) {
                  await this.getByHttpMethodItems();
                  await this.getReport(TABS.byAccount);
               }
               break;
            case TABS.byStatus:
               if (!this.byStatusItems.length) {
                  await this.getByStatusItems();
                  await this.getReport(TABS.byAccount);
               }
               break;
            case TABS.byResource:
               if (!this.byResourceItems.length) {
                  await this.getByResourceItems();
                  await this.getReport(TABS.byAccount);
               }
               break;
            case TABS.withSuspicious:
               if (this.suspiciousTab === SUSPICIOUS_TABS.list) {
                  if (!this.withSuspiciousItems.length) {
                     await this.getWithSuspiciousCount();
                  }
               } else if (this.suspiciousTab === SUSPICIOUS_TABS.byIp) {
                  if (!this.byIpItems.length)
                     await this.getByIpItems();
               }
               break;
            case TABS.byUser:
               if (!this.byUserItems.length) {
                  await this.getByUserItems();
                  await this.getReport(TABS.byAccount);
               }
               break;
            case TABS.withMostDelay:
               if (!this.withMostDelayItems.length) {
                  await this.getWithMostDelayCount();
                  await this.getReport(TABS.byAccount);
               }
               break;
            default:
               break;
         }
      },

      async getByAccountItems() {
         // alert(`in getByAccountItems()...`);
         this.loading = true;
         this.byAccountItems = [];
         let result = await this.apiService.getApiCallsByAccount(this.fromTo, this.source, this.service);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.byAccountItems = result.data;

            const accounts = [];
            result.data.forEach(d => {
               accounts.push({
                  text: `${d.accountname}: ${d.accountid}`,
                  value: d.accountid
               });
            });
            this.accounts = accounts.sort(_compareItems);
         }

         this.loading = false;
      },

      byAccountCurrItems(val) {
         this.byAccountTotals = 0;
         if (this.byAccountSearch) {
            if (!this.byAccountLastItemsPerPage) {
               this.byAccountLastItemsPerPage = this.byAccountOptions.itemsPerPage;
               this.byAccountOptions.itemsPerPage = -1;
               this.byAccountFooter.disableItemsPerPage = true;
            }
            if (val && val.length) {
               val.forEach(v => {
                  this.byAccountTotals += v.calls;
               });
            }
         } else {
            if (this.byAccountLastItemsPerPage) {
               this.byAccountFooter.disableItemsPerPage = false;
               this.byAccountOptions.itemsPerPage = this.byAccountLastItemsPerPage;
               this.byAccountLastItemsPerPage = 0;
            }
            this.byAccountItems.forEach(item => {
               this.byAccountTotals += item.calls;
            });
         }
      },

      async getByServiceItems() {
         // alert(`in getByServiceItems()...`);
         this.loading = true;
         this.byServiceItems = [];
         this.byServiceTotals = 0;
         let result = await this.apiService.getApiCallsByService(this.fromTo, this.source, this.service, this.byServiceAccount);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.byServiceItems = result.data;
            result.data.forEach(d => {
               this.byServiceTotals += d.calls;
            });
         }

         this.loading = false;
      },

      byServiceAccountChanged() {
         this.byServiceItems = [];
         this.getReport();
      },

      async getByHttpMethodItems() {
         // alert(`in getByHttpMethodItems()...`);
         this.loading = true;
         this.byHttpMethodItems = [];
         this.byHttpMethodTotals = 0;
         let result = await this.apiService.getApiCallsByHttpMethod(this.fromTo, this.source, this.service, this.byHttpMethodAccount);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.byHttpMethodItems = result.data;
            result.data.forEach(d => {
               this.byHttpMethodTotals += d.calls;
            });
         }

         this.loading = false;
      },

      byHttpMethodAccountChanged() {
         this.byHttpMethodItems = [];
         this.getReport();
      },

      async getByStatusItems() {
         // alert(`in getByStatusItems()...`);
         this.loading = true;
         this.byStatusItems = [];
         this.byStatusTotals = 0;
         let result = await this.apiService.getApiCallsByStatus(this.fromTo, this.source, this.service, this.byStatusAccount);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.byStatusItems = result.data;
            result.data.forEach(d => {
               this.byStatusTotals += d.calls;
            });
         }

         this.loading = false;
      },

      byStatusAccountChanged() {
         this.byStatusItems = [];
         this.inStatusExpandedItems = [];
         this.currByStatusItem.inStatusItems = {};
         this.getReport();
      },

      async byStatusItemExpanded(items) {
         // alert(`in byStatusItemExpanded(): item=${JSON.stringify(items)}`);
         if (items.length === 0) return;

         this.currByStatusItem = items[0];
         this.inStatusOptions.page = 1;
         this.inStatusOptions.itemsPerPage = 5;
         await this.getInStatusItems();

      },

      async getInStatusItems() {
         this.loading = true;
         let result = await this.apiService.getApiCallsInStatus(
            this.fromTo,
            this.source,
            this.service,
            this.currByStatusItem._id,
            this.byStatusAccount,
            this.inStatusOptions.page,
            this.inStatusOptions.itemsPerPage
         );
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.currByStatusItem.inStatusItems = result.data;
         }

         this.loading = false;
      },

      formatDate(date) {
         if (date) {
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            return formatteddate;
         }
      },

      async getByResourceItems() {
         // alert(`in getByResourceItems()...`);
         this.loading = true;
         this.byResourceItems = [];
         this.byResourceTotals = 0;
         let result = await this.apiService.getApiCallsByResource(this.selectedResource, this.fromTo, this.source, this.service, this.byResourceAccount);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.byResourceItems = result.data;
            result.data.forEach(d => {
               this.byResourceTotals += d.calls;
            });
         }

         this.loading = false;
      },

      async resourceChanged() {
         this.byResourceItems = [];
         await this.getReport();
      },

      byResourceAccountChanged() {
         this.byResourceItems = [];
         this.getReport();
      },

      async getWithSuspiciousCount() {
         // alert('in getWithSuspiciousCount()...')
         this.loading = true;
         let result = await this.apiService.getApiCallsWithSuspiciousActivityCount(this.fromTo);
         if (result.logout)
            this.logout();
            
         this.withSuspiciousCount = result.message ? 0 : result.data;
         this.withSuspiciousOptions.page = 1;
         this.withSuspiciousOptions.itemsPerPage = this.withSuspiciousOptions.itemsPerPage || 5;
         this.loading = false;

         if (this.withSuspiciousCount) {
            await this.getWithSuspiciousItems();
         }
      },

      async getWithSuspiciousItems() {
         // alert('in getWithSuspiciousItems()...')
         this.loading = true;
         let result = await this.apiService.getApiCallsWithSuspiciousActivity(
            this.fromTo,
            this.withSuspiciousOptions.page,
            this.withSuspiciousOptions.itemsPerPage
         );

         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.withSuspiciousItems = result.data;
         }

         this.loading = false;
      },

      async getByIpItems() {
         // alert('in getByIpItems()...')
         this.loading = true;
         this.byIpItems = [];
         let result = await this.apiService.getApiCallsWithSuspiciousActivityByIp(this.fromTo);

         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.byIpItems = result.data;
         }

         this.loading = false;
      },

      byIpCurrItems(val) {
         this.byIpTotals = 0;
         if (this.byIpSearch) {
            if (!this.byIpLastItemsPerPage) {
               this.byIpLastItemsPerPage = this.byIpOptions.itemsPerPage;
               this.byIpOptions.itemsPerPage = -1;
               this.byIpFooter.disableItemsPerPage = true;
            }
            if (val && val.length) {
               val.forEach(v => {
                  this.byIpTotals += v.calls;
               });
            }
         } else {
            if (this.byIpLastItemsPerPage) {
               this.byIpFooter.disableItemsPerPage = false;
               this.byIpOptions.itemsPerPage = this.byIpLastItemsPerPage;
               this.byIpLastItemsPerPage = 0;
            }
            this.byIpItems.forEach(item => {
               this.byIpTotals += item.calls;
            });
         }
      },

      async getByUserItems() {
         // alert(`in getByUserItems()...`);
         this.loading = true;
         this.byUserItems = [];
         this.byUserTotals = 0;
         let result = await this.apiService.getApiCallsByUser(this.fromTo, this.service, this.byUserAccount);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.byUserItems = result.data;
            result.data.forEach(d => {
               this.byUserTotals += d.calls;
            });
         }

         this.loading = false;
      },

      byUserAccountChanged() {
         this.byUserItems = [];
         this.getReport();
      },


      async getWithMostDelayCount() {
         // alert('in getWithMostDelayCount()...')
         this.loading = true;
         let result = await this.apiService.getApiCallsWithMostDelayCount(this.fromTo, this.source, this.service, this.withMostDelayAccount);
         if (result.logout)
            this.logout();
            
         this.withMostDelayCount = result.message ? 0 : result.data;
         this.withMostDelayOptions.page = 1;
         this.withMostDelayOptions.itemsPerPage = this.withMostDelayOptions.itemsPerPage || 5;
         this.loading = false;

         if (this.withMostDelayCount) {
            await this.getWithMostDelayItems();
         }
      },

      async getWithMostDelayItems() {
         // alert('in getWithMostDelayItems()...')
         this.loading = true;
         let result = await this.apiService.getApiCallsWithMostDelay(
            this.fromTo,
            this.source,
            this.service,
            this.withMostDelayAccount,
            this.withMostDelayOptions.page,
            this.withMostDelayOptions.itemsPerPage
         );

         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.withMostDelayItems = result.data;
         }

         this.loading = false;
      },

      withMostDelayAccountChanged() {
         this.withMostDelayItems = [];
         this.withMostDelayExpandedItems = [];
         this.getReport();
      }
   },

   created() {
      this.init();
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
::v-deep .v-data-table > .v-data-table__wrapper > table {
   border-spacing: 0 0.08em;
   /* height: 97% */
}
</style>>
