<template>
<!-- TODO:
- when an identity is edited, it cannot show new policy names in the expanded view
- make Create IDENTITY disabled if no groups exist
- Hide checkboxes in the table if user is not authorized
- Make the search field smaller
-->
<!-- HISTORY:
   V230728.1: Added userFilter + Modified the condition for the showPopulateLink computed (populate test data) +
      Some style adjustments (like removing dense and paddings) + Modified token parsing method.
   V221011.1: Removed hasApiAccess from edit dialog.
   V221007.1: Made hasApiAccess field disabled and allowed adding credentials unconditionally.
   07/28/22(B0.11): Implemented API Credentials feature.
   05/02/22(B0.10): Implemented logout feature.
   10/12/21(B0.9): Changed the item-key of data-table from email to _id.
   08/25/21(B0.8): Changed duplicate rule to allow adding one email with different account permissions.
   09/02/20(B0.7): Fixed issues with hide-default-footer by adding itemsPerPage variable + Handled search query string.
   08/27/20(B0.6): Applied some changes along with Aref + Temporarily removed power user create permission + Removed main card's width.
   08/14/20(B0.5): Hid hasApiAccess from the UI (it'll be set automatically).
   08/10/20(B0.4): Hid table footers for < 11 records + Removed 'everyone' group from eligibleGroups +
      Fixed a bug in getNames() that caused an error with empty arrays + Fixed and adjusted all cred operations and accesses.
   08/05/20(B0.3): Policies to be managed by power admin only + Added isAdmin to the model n UI.
-->
<v-container fluid class="px-3 py-3" v-if="isAuthorizedViewer">
   <v-card>
      <v-toolbar flat color="white" class="px-2">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">perm_identity</v-icon>
            <span>Identities</span>
         </h1>
         <div class="flex-grow-1"></div>
         <v-dialog v-if="isAuthorizedUser"
            max-width="500px"
            :disabled="!groups || groups.length === 0"
            v-model="dialog" 
         >
            <template v-slot:activator="{ on }">
               <v-btn dark 
                  color="#ff6633"
                  class="mb-2"
                  v-on="on"
               >
                  <v-icon left>add</v-icon>
                  <span>CREATE IDENTITY</span>
               </v-btn>
            </template>
         </v-dialog>
      </v-toolbar>

      <v-card-title class="pt-0">
         <!-- <div v-if="isAuthorizedUser"> -->
         <div>
            <v-btn text small
               :disabled="selectedItems.length !=1"
               @click="duplicateItemClicked()"
            >
               <v-icon>file_copy</v-icon> {{menuItems.duplicate}}
            </v-btn>
            <v-btn text small
               :disabled="selectedItems.length==0" 
               @click="dialogAddGroups = true"
            >
               <v-icon>add</v-icon> {{menuItems.addGroups}}
            </v-btn>
            <v-btn text small
               :disabled="selectedItems.length==0"
               @click="deleteItems()"
            >
               <v-icon>delete</v-icon> {{menuItems.delete}}
            </v-btn>
         </div>
         <div class="flex-grow-1"></div>
         <v-text-field single-line hide-details
            class="pt-0"
            append-icon="search"
            label="Search"
            :disabled="!identities || identities.length < 2"
            v-model="search"
         ></v-text-field>
      </v-card-title>

      <!-- @item-expanded="itemExpanded" -->
      <v-data-table show-select show-expand fixed-header
         class="elevation-1"
         item-key="_id"
         :headers="headers"
         :items="identities"
         :single-select="singleSelect"
         :search="search"
         :hide-default-footer="identities.length < itemsPerPage + 1"
         :loading="loading"
         :loading-text="$t('loading-text')"
         :no-data-text="$t('no-data-text', { value: 'identities' })"
         :no-results-text="$t('no-results-text', { value: 'identities' })"
         :items-per-page.sync="itemsPerPage"
         :dense="settings.areTablesDensed"
         :single-expand="settings.singleExpand"            
         v-model="selectedItems"
      >
         <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
            <v-simple-checkbox v-ripple
               :value="isAuthorizedAction(item) ? isSelected : false" 
               :disabled="!isAuthorizedAction(item)"
               :readonly="!isAuthorizedAction(item)"                
               @input="select($event)" 
            ></v-simple-checkbox>
         </template>

         <template v-slot:[`item.groups`]="{ item }">
            {{ getNames(item.groups).join(', ') }}
         </template>

         <!-- TODO: data-table not showing boolean. this is a work around till the issue is fixed -->
         <template v-slot:[`item.accessToAllSubAccounts`]="{ item }">
            {{ item.accessToAllSubAccounts ? 'Yes' : 'No' }}
         </template>

         <template v-slot:[`item.hasApiAccess`]="{ item }">
            {{ item.hasApiAccess ? 'Yes' : 'No' }}
         </template>

         <template v-slot:[`item.action`]="{ item }">
            <!--V221007.1 v-if="item.hasApiAccess || item.apiCredentials.length" -->
            <v-tooltip top v-if="item.aid != '*'">
               <template v-slot:activator="{ on }">
                  <v-icon small
                     class="mr-2"
                     @click="manageCreds(item)"
                     v-on="on"
                  >api</v-icon>
               </template>
               <span>{{getApiAccessTooltip(item)}}</span>
            </v-tooltip>

            <span v-if="isAuthorizedAction(item)">
               <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
               <v-icon small @click="deleteItem(item)">delete</v-icon>
            </span>
         </template>

         <template v-slot:expanded-item="{ item }">
            <td colspan="1"></td>
            <td colspan="3" class="py-2 pl-0" valign="top" dense>
               <ul>
                  <li>
                     <span class="expanded-header">ID: </span>
                     <span class="expanded-content">{{item._id}}</span>
                  </li>
                  <li>
                     <span class="expanded-header">Days: </span>
                     <span class="expanded-content text-capitalize">{{$t(item.daysEffect)}} {{item.days.join(', ')}}</span>
                  </li>
                  <li>
                     <span class="expanded-header">Hours: </span>
                     <span class="expanded-content">{{$t(item.hoursEffect)}} {{item.hours.join(', ')}}</span>
                  </li>
                  <li>
                     <span class="expanded-header">User Filter: </span>
                     <span class="expanded-content">{{item.userFilter}}</span>
                  </li>
               </ul>
            </td>
            <td colspan="1" class="py-2 pl-0" valign="top" dense>
               <ul>
                  <li v-for="g in item.groups" :key="g.name">
                     <!-- <span class="expanded-header">{{JSON.stringify(g)}}: </span> -->
                     <span class="expanded-header">{{g.name}}: </span>
                     <span class="expanded-content">{{getPolicyNames(g).join(', ')}}</span>
                     <!-- <span class="expanded-content">{{policyNames.join(', ')}}</span> -->
                  </li>
               </ul>
            </td>
            <td colspan="3" class="py-2 pl-0" valign="top" dense>
               <ul>
                  <li>
                     <span class="expanded-header">User Name: </span>
                     <span class="expanded-content">{{item.userName}}</span>
                  </li>
                  <li>
                     <span class="expanded-header">CIDR: </span>
                     <span class="expanded-content">{{item.cidr}}</span>
                  </li>
                  <li>
                     <span class="expanded-header">API Credentials: </span>
                     <span class="expanded-content">{{item.apiCredentials.length}}</span>
                  </li>
               </ul>
            </td>
            <td colspan="1"></td>
         </template>

         <template v-slot:top>
            <v-dialog v-model="dialog" persistent no-click-animation max-width="960px">
               <!-- <template v-slot:activator="{ on }"></template> -->
               <v-card>
                  <v-card-title class="headline">{{ formTitle }}
                     <div class="flex-grow-1"></div>
                     <v-btn v-if="showPopulateLink" color="blue darken-1" text @click="populateTestData()">populate test data</v-btn>
                  </v-card-title>

                  <v-card-text class="pb-0">
                     <v-container>
                        <v-form ref="identityForm" v-model="isIdentityFormValid" lazy-validation>
                           <v-row dense>
                              <v-col cols="6">
                                 <v-text-field outlined
                                    ref="email"
                                    label="* Email"
                                    placeholder="Enter identity's email address"
                                    v-model="editedItem.email"
                                    :rules="emailRules"
                                    @input="emailChanged"
                                 ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                 <v-text-field outlined
                                    label="User Name"
                                    placeholder="Enter identity's user name"
                                    v-model="editedItem.userName"
                                 ></v-text-field>
                              </v-col>
                           </v-row>
                           <v-row no-gutters>
                              <v-col cols="12"><!--class="font-weight-bold title"-->
                                 <v-autocomplete multiple clearable open-on-clear outlined small-chips deletable-chips required 
                                    label="* Groups"
                                    placeholder="select as many groups as desired"
                                    v-model="selectedGroups" 
                                    :items="groupsDropdown"
                                    :rules="selectRules"
                                    :counter="groups.length"
                                    @change="selectChanged"
                                 ></v-autocomplete>
                              </v-col>
                           </v-row>
                           <v-row pb-0>
                              <v-col cols="2">
                                 <v-select dense
                                    label="Days Effect"
                                    v-model="editedItem.daysEffect" 
                                    :items="dropdown_effect"
                                    :rules="daysEffectRules"
                                 ></v-select>
                              </v-col>
                              <v-col cols="4" class="pr-1">
                                 <v-select multiple clearable small-chips outlined persistent-hint
                                    label="Days"
                                    hint="leave empty for all days"
                                    counter="7"
                                    v-model="editedItem.days"
                                    :items="dropdown_days"
                                    @change="daysChanged"
                                 ></v-select>
                              </v-col>
                              <v-col cols="2">
                                 <v-select dense
                                    label="Hours Effect"
                                    v-model="editedItem.hoursEffect" 
                                    :items="dropdown_effect"
                                    :rules="hoursEffectRules"
                                 ></v-select>
                              </v-col>
                              <v-col cols="4">
                                 <v-select multiple clearable small-chips outlined persistent-hint
                                    label="Hours"
                                    hint="leave empty for all hours"
                                    counter="24"
                                    v-model="editedItem.hours"
                                    :items="dropdown_hours"
                                    @change="hoursChanged"
                                 ></v-select>
                              </v-col>
                           </v-row>
                           <v-row dense pt-0 mt-0>
                              <v-col cols="6">
                                 <v-text-field outlined clearable persistent-hint
                                    label="CIDR"
                                    placeholder="Enter your cidr block"
                                    hint="Example: 192.168.100.0 /22 OR 2001:db8::/48"
                                    v-model="editedItem.cidr"
                                    :rules="cidrRules"
                                    @input="cidrChanged"
                                 ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                 <v-textarea clearable persistent-hint outlined
                                    ref="userFilter"
                                    class="pt-0"
                                    label="User Filter"
                                    :hint="userFilterHint"
                                    placeholder="Specify a filter to limit the user's access"
                                    rows="1"
                                    :rules="[rules.validJson]"
                                    v-model="editedItem.userFilter"
                                 ></v-textarea>
                              </v-col>
                           </v-row>
                           <v-row>
                              <!-- <v-col cols="2">
                                 V221007.1 :disabled="!editedItem._id || editedItem.aid === '*' || editedItem.apiCredentials.length"
                                 <v-checkbox mt-0 disabled
                                    label="Access to API"
                                    v-model="editedItem.hasApiAccess"
                                 ></v-checkbox>
                              </v-col> -->
                              <v-col v-if="!jwt.paid"
                                 cols="4"
                                 class="pt-0"
                              >
                                 <v-checkbox
                                    class="mt-0 pt-0"
                                    label="Access to All Subaccounts"
                                    v-model="editedItem.accessToAllSubAccounts"
                                 ></v-checkbox>
                              </v-col>
                           </v-row>
                        </v-form>
                     </v-container>
                  </v-card-text>

                  <v-card-actions>
                     <div class="flex-grow-1"></div>
                     <v-btn color="blue darken-1" text @click="cancelItem">Cancel</v-btn>
                     <v-btn color="blue darken-1" text @click="saveItem">Save</v-btn>
                  </v-card-actions>
               </v-card>
            </v-dialog>

            <v-dialog v-model="dialogAddGroups" persistent no-click-animation max-width="960px">
               <!-- <template v-slot:activator="{ on }"></template> -->
               <v-card>
                  <v-card-title class="headline">{{menuItems.addGroups}}</v-card-title>

                  <v-card-text>
                     <v-container>
                        <v-layout class="mb-5">
                           <v-row no-gutters class="mb-6">
                              <v-col cols="auto" class="font-weight-bold">Selected group(s) will be added to {{$tc('this-identity', selectedItems.length)}}:&nbsp;</v-col>
                              <v-col class="font-italic">{{ getIdentityNames(selectedItems).join(', ') }}</v-col>
                           </v-row>
                        </v-layout>
                        <v-form ref="addGroupsForm" lazy-validation mt-5>
                           <v-layout>
                           <v-row dense no-gutters mb-0 pb-0>
                              <v-col cols="12">
                                 <v-autocomplete multiple clearable open-on-clear outlined chips deletable-chips required
                                    ref="groups"
                                    label="* Groups"
                                    placeholder="select as many groups as desired"
                                    v-model="selectedGroups" 
                                    :items="groupsDropdown"
                                    :rules="selectRules"
                                    :counter="groups.length"                                      
                                    @change="selectChanged"
                                 ></v-autocomplete>
                              </v-col>
                           </v-row></v-layout><v-layout>
                           <v-row dense no-gutters pt-0 mt-0>
                              <v-col cols="12">
                                 <v-checkbox mt-0
                                    label="Add all groups"
                                    v-model="isAllGroupsChecked"
                                    :readonly="isAllGroupsChecked"
                                    :disabled="isAllGroupsChecked"
                                    @change="allGroupsChecked"
                                 ></v-checkbox>
                              </v-col>
                           </v-row></v-layout>
                        </v-form>
                     </v-container>
                  </v-card-text>

                  <v-card-actions>
                     <div class="flex-grow-1"></div>
                     <v-btn color="blue darken-1" text @click="cancelAddGroups">Cancel</v-btn>
                     <v-btn color="blue darken-1" text @click="addGroupsToIdentities">Submit</v-btn>
                  </v-card-actions>
               </v-card>
            </v-dialog>

            <v-dialog v-model="dialogCreds" persistent no-click-animation max-width="960px">
               <v-card>
                  <v-toolbar flat color="white" class="px-3">
                     <v-toolbar-title class="headline">{{isAuthorizedAction(editedItem) ? 'Manage' : 'View'}} API Credentials</v-toolbar-title>
                     <div class="flex-grow-1"></div>
                     <template>
                        <v-btn dark v-if="isAuthorizedAction(editedItem)"
                           color="red"
                           class="my-4"
                           :disabled="editedItem.apiCredentials.length >= 5"
                           @click="addCred"
                        >
                           <v-icon left>add</v-icon>
                           <span>ADD CREDENTIAL</span>
                        </v-btn>
                     </template>
                  </v-toolbar>

                  <v-card-text v-if="showSecretKey" class="pt-5">
                     <v-expansion-panels focusable v-model="panel">
                        <v-expansion-panel readonly>
                           <v-expansion-panel-header hide-actions class="py-0 px-0">
                              <v-btn block v-if="isNavigator"
                                    color="grey lighten-3"
                                    :hint="copyToClipboardHint"
                                    @click="copyToClipboard"
                                 >
                                    <v-icon dark left class="pl-1">content_copy</v-icon>
                                    <span>{{copyToClipboardHint}}</span>
                                 </v-btn>
                              </v-expansion-panel-header>
                           <v-expansion-panel-content>
                           <v-row>
                              <v-col>Here is your new Secret Key for '{{editedItem.apiCredentials[editedItem.apiCredentials.length - 1].accessKey}}'. <span class="font-weight-bold font-italic">PLEASE COPY THIS KEY AND SAVE IT IN A SECURE PLACE.</span> For security reasons, we won't be able to show this key again: <span class="font-weight-bold font-italic">{{editedItem.apiCredentials[editedItem.apiCredentials.length - 1].secretKey}}</span></v-col>
                           </v-row>
                           </v-expansion-panel-content>
                        </v-expansion-panel>
                     </v-expansion-panels>
                  </v-card-text>
               
                  <v-card-text class="pt-4 pb-0">
                     <v-data-table fixed-header dense disable-pagination hide-default-footer light
                        :headers="credsHeaders"
                        :items="editedItem.apiCredentials"
                        :single-select="singleSelect"
                        item-key="_id"
                        class="elevation-1"
                        no-data-text="This identity has no API credentials."
                        calculate-widths=""
                        :expanded.sync="expanded"
                     >

                        <template v-slot:[`item.secretKey`]="{ item }">
                           {{ item.secretKey.split('-')[4] }}
                        </template>

                        <!-- TODO: data-table not showing boolean. this is a work around till the issue is fixed -->
                        <template v-slot:[`item.isActive`]="{ item }">
                           {{ item.isActive ? 'Yes' : 'No' }}
                           <!-- <span v-if="item.isActive" class="success--text darken-1">true</span>
                           <span v-else class="primary--text darken-1">false</span> -->
                        </template>

                        <template v-slot:[`item.actionCreds`]="{ item }" v-if="isAuthorizedAction(editedItem)">
                           <v-icon small
                              class="mr-2"
                              @click="editCredClicked(item)"
                           >edit</v-icon>
                           <v-icon small 
                              @click="deleteCred(item)"
                           >delete</v-icon>
                        </template>

                     </v-data-table>
                  </v-card-text>

                  <v-card-actions>
                     <p v-if="editedItem.apiCredentials.length === 5" class="red--text pl-4 mb-0">You have reached your max!</p>
                     <div class="flex-grow-1"></div>
                     <v-btn text
                        color="blue darken-1"
                        @click="dialogCreds = false"
                     >Close</v-btn>
                  </v-card-actions>
               </v-card>
            </v-dialog>

            <v-dialog v-model="dialogEditCred" persistent no-click-animation max-width="840px">
               <v-card>
                  <v-card-title class="headline">Edit API Credential</v-card-title>
                  <v-card-text class="pb-0">
                     <v-container class="pb-0">
                        <v-row no-gutters class="mb-6">
                           <v-col cols="auto"
                              class="font-weight-bold"
                           >Id:
                           </v-col>
                           <v-col
                              class="font-italic"
                           >{{editedCred._id}}
                           </v-col>
                           <v-col cols="auto"
                              class="font-weight-bold"
                           >Access Key:
                           </v-col>
                           <v-col
                              class="font-italic"
                           >{{editedCred.accessKey}}
                           </v-col>
                        </v-row>

                        <v-row dense no-gutters mb-0 pb-0>
                           <v-col cols="12">
                              <v-textarea clearable hide-details outlined
                                 class="pt-0 pr-0 mb-0"
                                 ref="editedCred_desc"
                                 label="Description"
                                 rows="3"
                                 v-model="editedCred.description"
                              ></v-textarea>
                           </v-col>
                        </v-row>

                        <v-row dense no-gutters pt-0 mt-0>
                           <v-col cols="2" pt-0>
                              <v-checkbox mt-0
                                 label="Is Active?"
                                 v-model="editedCred.isActive"
                              ></v-checkbox>
                           </v-col>
                           <v-col cols="10" class="pt-2"  v-if="defaultCred.isActive && !editedCred.isActive">
                              <v-alert outlined prominent
                                 border="left"
                                 type="warning"
                              >
                                 WARNING: Please note that by inactivating this API credential, no more access will be granted to its API User as soon as you submit this form.
                              </v-alert>                              
                           </v-col>
                        </v-row>
                     </v-container>
                  </v-card-text>

                  <v-card-actions>
                     <div class="flex-grow-1"></div>
                     <v-btn text
                        color="blue darken-1"
                        @click="cancelEditCred"
                     >Cancel</v-btn>
                     <v-btn text
                        color="blue darken-1"
                        :disabled="editedCred.description === defaultCred.description && editedCred.isActive === defaultCred.isActive"
                        @click="editCred"
                     >Submit</v-btn>
                  </v-card-actions>
               </v-card>
            </v-dialog>
         </template>

      </v-data-table>
   </v-card>
</v-container>
<v-container v-else>
   <div class="red--text">
      <p>Sorry, you are not authorized to access this page!</p>
      <!-- TODO: temporary -->
      <ul>
         <li>Power Admin: {{ jwt.pa }}</li>
         <li>Power User: {{ jwt.pu }}</li>
         <li>Parent Account: {{ jwt.paid }}</li>
         <li>Account: {{ jwt.aid }}</li>
      </ul>
   </div>
</v-container>
</template>

<script>
import { APIService } from '../services/iam-api-service.js';
import { Identity } from '../models/iam-identity.js';
// import { Credential } from '../models/iam-credential.js';
import { IamSettings } from '../models/iam-settings.js';

const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const CIDR_PATTERN = /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;

function _generateUUID() {
   var d = new Date().getTime();
   var uuid = 'xxxXXxxx-Xxxx-4xxX-yxxx-xXxXXXxxxXxx'.replace(/[xXy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      if (c == 'x')
         return r.toString(16);
      else if (c == 'X')
         return r.toString(16).toUpperCase();
      else
         return (r & 0x7 | 0x8).toString(16);
   });
   return uuid;
}

export default {
   name: 'IamIdentities',

   props: {
      debug: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         jwt: {},
         apiService: null,
         menuItems: { delete: 'DELETE IDENTITIES', addGroups: 'Add Groups To Identities', duplicate: 'Duplicate Identity' },
         search: '',
         singleSelect: false,
         headers: [
            { text: 'Email', value: 'email', align: 'left', sortable: true, width: "" },
            { text: 'Account ID', value: 'aid', align: 'left', sortable: false, width: "10%" },
            { text: 'Associated Groups', value: 'groups', align: 'left', sortable: false, width: "38%" },
            { text: 'Access to All Subaccounts', value: 'accessToAllSubAccounts', align: 'left', sortable: true, width: "10%" },
            { text: 'Access to API', value: 'hasApiAccess', align: 'left', sortable: true, width: "10%" },
            { text: '', value: 'action', align: 'right', sortable: false, width: "10%" }    
         ],
         dropdown_effect: [ 
            { text: 'In', value: 'in' },
            { text: 'Not In', value: 'nin' }
         ],
         dropdown_hours: [ 
            { text: '12 AM', value: 0 },
            { text: ' 1 AM', value: 1 },
            { text: ' 2 AM', value: 2 },
            { text: ' 3 AM', value: 3 },
            { text: ' 4 AM', value: 4 },
            { text: ' 5 AM', value: 5 },
            { text: ' 6 AM', value: 6 },
            { text: ' 7 AM', value: 7 },
            { text: ' 8 AM', value: 8 },
            { text: ' 9 AM', value: 9 },
            { text: '10 AM', value: 10 },
            { text: '11 AM', value: 11 },
            { text: '12 PM', value: 12 },
            { text: ' 1 PM', value: 13 },
            { text: ' 2 PM', value: 14 },
            { text: ' 3 PM', value: 15 },
            { text: ' 4 PM', value: 16 },
            { text: ' 5 PM', value: 17 },
            { text: ' 6 PM', value: 18 },
            { text: ' 7 PM', value: 19 },
            { text: ' 8 PM', value: 20 },
            { text: ' 9 PM', value: 21 },
            { text: '10 PM', value: 22 },
            { text: '11 PM', value: 23 },
         ],
         dropdown_days: [ 
            // { text: 'Weekday', value: 'mon,tue,wed,thu,fri' },
            // { text: 'Weekend', value: 'sat,sun' },
            { text: 'Monday', value: 'mon' },
            { text: 'Tuesday', value: 'tue' },
            { text: 'Wednesday', value: 'wed' },
            { text: 'Thursday', value: 'thu' },
            { text: 'Friday', value: 'fri' },
            { text: 'Saturday', value: 'sat' },
            { text: 'Sunday', value: 'sun' }
         ],
         identities: [],
         groups: [],
         policies: [],
         eligibleGroups: [],
         selectedItems: [],
         editedIndex: -1,
         editedItem: new Identity(),
         defaultItem: new Identity(),
         dialog: false,
         dialogAddGroups: false,
         isIdentityFormValid: true,
         rules: {
            required: value => !!value || this.$t('required'),
            requiredArray: value => value.length > 0 || this.$t('required'),
            minLength: value => value.length >= 5 || this.$t('min5'),
            email: value => EMAIL_PATTERN.test(value) || this.$t('invalid'),
            // duplicate: value => this.identities.find(i => i.email.toLowerCase() === value.toLowerCase() && i._id != this.editedItem._id) === undefined || 
            //    this.$t('duplicate-email'),
            duplicate: value => {
               const identity = this.identities.find(i => i.email.toLowerCase() === value.toLowerCase() && i._id != this.editedItem._id);
               if (identity === undefined)
                  return true;
               else if ((this.jwt.pa && identity.aid != '*') || (this.jwt.pu && identity.aid != this.jwt.aid))
                  return true;
               else
                  return this.$t('duplicate-email');
            },
            cidr: value => CIDR_PATTERN.test(value) || this.$t('invalid'),
            validJson: value => {
               try {
                  if (value) {
                     if (value.trim().indexOf('"') === 0)
                        return 'SyntaxError: Unexpected token in JSON at position 0';
                     const uf = JSON.parse(value);
                     return Object.keys(uf).length > 0 || 'Object cannot be empty!';
                  }
                  return true;
               } catch (error) {
                  return error.toString();
               }
            },
            //test: (v1, v2) => v2 ? (v1 ? true : 'Value is required!') : true
         },
         groupsDropdown: [],
         selectedGroups: [],
         emailRules: [],
         selectRules: [],
         daysEffectRules: [],
         hoursEffectRules: [],
         cidrRules: [],
         isAllGroupsChecked: false,
         isAllItemsChecked: false,
         dialogCreds: false,
         credIndex: -1,
         credsHeaders: [
            { text: 'ID', value: '_id', align: 'left', sortable: true },//, width: "20%"
            { text: 'Access Key', value: 'accessKey', align: 'left', sortable: true },//, width: "30%"
            { text: 'Secret Key', value: 'secretKey', align: 'left', sortable: true },//, width: "10%"
            { text: 'Is Active', value: 'isActive', align: 'left', sortable: true },//, width: "30%"
            { text: 'Description', value: 'description', align: 'left', sortable: true },//, width: "10%"
            { text: '', value: 'actionCreds', align: 'right', sortable: false, width: "10%"}//,        
         ],
         duplicateItem: false,
         loading: true,
         settings: new IamSettings(this.debug),
         policyNames: [],
         expanded:[],
         isApiCredentialsChanged: false,
         itemsPerPage: 10,
         isNavigator: false,
         copyToClipboardHint: '',
         showSecretKey: false,
         dialogEditCred: false,
         defaultCred: {},
         editedCred: {},
         panel: 0,
         userFilterHint: 'Example: {"csv-column-name": "user-email-address"}'
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      isAuthorizedViewer() {
         // user is authorized to view if it is power admin or power user of any account.
         return this.jwt.pa || this.jwt.pu;
      },

      isAuthorizedUser () {
         // user is authorized if it is a power user
         // return this.jwt.pu;
         return this.jwt.pa || this.jwt.pu;
      },

      formTitle () {
         return this.editedIndex === -1 ? 'Create a New Identity' : 'Edit Identity';
      },

      showPopulateLink () {
         // return process.env.NODE_ENV != 'production' && this.editedIndex === -1 && !this.duplicateItem;
         return !this.isActualEndpoint;
      },

      pageWidth() {
         return this.settings.keepMenuExpanded ? '81vw' : '91vw';
      }
   },

   watch: {
      token() {
         this.init();
      },

      dialog (val) {
         if (val) {
            setTimeout(() => {
               this.groupsDropdown = this.getNames(this.eligibleGroups);
               this.$refs.email.focus();
            }, 10);
         } else
            this.closeItem();
      },

      dialogAddGroups (val) {
         if (val) {
            const usedGroups = [];
            this.selectedItems.forEach(element => {
               if (usedGroups.indexOf(element) === -1)
                  usedGroups.push(element);
            });
            const filteredGroups = [];
            this.eligibleGroups.forEach(element => {
               if (usedGroups.indexOf(element) === -1) filteredGroups.push(element);
            });
            this.groupsDropdown = this.getNames(this.filteredGroups);
            setTimeout(() => {
               this.$refs.groups.focus();
            }, 10);
         } else
            this.closeAddGroups();
      },

      dialogCreds (val) {
         this.showSecretKey = false;
         val || this.closeCreds()
      },
      
      identities: {
         // immediate: true,
         // deep: true,
         handler(val) {
            this.itemsPerPage = isNaN(this.settings.rowsPerPage) ? val.length : this.settings.rowsPerPage;
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`IamIdentities V230728.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      init() {
         try {
            // alert(this.token);
            if (this.token) {
               // const JWT = JSON.parse(atob(this.token.split('.')[1]));
               // this.jwt = {
               //    // email: JWT.email,
               //    aid: JWT.aid,
               //    paid: JWT.paid,
               //    pa: JWT.pa,
               //    pu: JWT.pu
               // };
               this.jwt = JSON.parse(Buffer.from(this.token.split('.')[1], 'base64'));
               this.log('in IamIdentities: jwt=' + JSON.stringify(this.jwt));
               if (this.isAuthorizedUser || this.isAuthorizedViewer) {
                  this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
                  this.getItems();
                  this.getGroups();
                  this.getPolicies();
               }
            } else
               this.jwt = {};
         } catch (error) {
            alert('Exception in IamIdentities while parsing token: ' + JSON.stringify(error));
         }
      },

      async getItems() {
         let result = await this.apiService.getIdentities();
         this.loading = false;
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.identities = result.data;
         }
      },

      async getGroups() {
         let result = await this.apiService.getGroups();
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.groups = result.data;
            this.eligibleGroups = this.groups.filter(g => g.isAdmin === this.jwt.pa && g.name.toLowerCase() != 'everyone');
         }
      },

      async getPolicies() {
         let result = await this.apiService.getPolicies();
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.policies = result.data;
         }
      },

      getNames(items) {
         //return items.map(item => { return item.name; });
         return (items && items.length > 0) ? items.map(item => item.name) : [];
      },

      //  itemExpanded({item, value}) {
      //    //  alert(JSON.stringify(item));
      //     if (value);
      //       return this.getPolicyNames(item.policies);
      //  },

      getPolicyNames(group) {
         // alert('in getPolicyNames: group.policies=' + JSON.stringify(group.policies));
         const policyNames = [];
         if (this.policies.length > 0) {
            group.policies.forEach(policy => {
               // alert('policy=' + JSON.stringify(policy));
               policyNames.push(policy.name || this.policies.find(p => p._id === policy).name);
            });
         }
         return policyNames;
      },

      getIdentityNames(identities) {
         return identities.map(i => { return i.email; });
      },

      getApiAccessTooltip(item) {
         return `Has ${item.apiCredentials.length || 'no'} credentials. Click to ${this.isAuthorizedAction(item) ? 'manage' : 'view'}`;
      },

      editItem (item) {
         this.defaultItem = JSON.parse(JSON.stringify(item));
         this.editedItem = Object.assign({}, item);
         this.editedIndex = this.identities.indexOf(item);
         this.selectedGroups = this.getNames(item.groups);
         this.dialog = true;
      },

      async deleteItem (item) {
         if (confirm(`Are you sure you want to delete '${item.email}'?`)) {
            let result = await this.apiService.deleteIdentity(item);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.identities.splice(this.identities.indexOf(item), 1);
               this.$emit('snackbar-event', this.$t('deleted', { value: item.email }));
            }
         }
      },

      async deleteItems () {
         if (!confirm(`Are you sure you want to delete the ${this.$tc('identity-selected', this.selectedItems.length)}?`))
            return;

         let msg = '';
         let responses = await this.apiService.deleteIdentities(this.selectedItems);
         this.log('in deleteItems(): results=' + JSON.stringify(responses));

         let results = {
            succeeded: [], //only emails of deleted identities
            failed: []     //{ email: '', message: '' }
         };

         responses.forEach(response => {
            if (response.logout)
               this.logout();
            else if (response.message === null) {
               results.succeeded.push(response.data.email);
            } else {
               results.failed.push({ email: response.data.email, message: response.message });
            }
         });

         //TODO: delete items which is not editable from selectedItems or make it empty
         
         if (results.succeeded.length > 0) {
            msg = 'Following ' + this.$tc('identity-was', results.succeeded.length) + ' deleted:\n' + results.succeeded.join(', ') + '\n\n';
            results.succeeded.forEach (result => {
               this.log('result='+JSON.stringify(result));
               let item = this.selectedItems.find(i => i.email === result);
               this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
               this.identities.splice(this.identities.indexOf(item), 1);
            });
         }

         if (results.failed.length > 0) {
            msg += 'Following ' + this.$tc('identity-was', results.failed.length) + ' could not be deleted:\n';
            results.failed.forEach (result => {
               msg += '\n- ' + result.email + ' => ' + result.message;
            });
            alert(msg);
            this.$emit('snackbar-event', this.$t('action-finished', { action: this.menuItems.delete }));
         } else {
            this.$emit('snackbar-event', `${results.succeeded.length} ${this.$tc('identity-was', results.succeeded.length)} deleted.`);
         }
      },

      emailChanged () {
         if (this.emailRules.length === 0)
            this.emailRules = [this.rules.required, this.rules.email, this.rules.duplicate];
      },

      selectChanged () {
         if (this.selectRules.length === 0)
            this.selectRules.push(this.rules.requiredArray);

         if (this.selectedGroups.length === this.groups.length)
            this.isAllGroupsChecked = true;
         else
            this.isAllGroupsChecked = false;
      },

      daysChanged () {
         if (this.editedItem.days.length === 1) {
            this.daysEffectRules = [this.rules.required];
         } else if (this.editedItem.days.length === 0) {
            this.daysEffectRules = [];
            this.editedItem.daysEffect = '';
         }
      },

      hoursChanged () {
         if (this.editedItem.hours.length === 1) {
            this.hoursEffectRules = [this.rules.required];
         } else if (this.editedItem.hours.length === 0) {
            this.hoursEffectRules = [];
            this.editedItem.hoursEffect = '';
         }
      },

      cidrChanged () {
         if (this.editedItem.cidr) {
            if (this.cidrRules.length === 0)
               this.cidrRules = [this.rules.cidr];
         } else {
            this.cidrRules = [];
         }
      },

      async saveItem () {
         this.log('in saveItem()');
         this.isIdentityFormValid = true;
         this.emailChanged();
         this.selectChanged();
         this.daysChanged();
         this.hoursChanged();
         this.cidrChanged();

         if (!this.$refs.identityForm.validate()) {
            this.isIdentityFormValid = false;
            return;
         }

         let result;
         const email = this.editedItem.email;
         // const originalGroups = JSON.parse(JSON.stringify(this.editedItem.groups));

         // alert('groups before:'+JSON.stringify(this.editedItem.groups));
         this.editedItem.groups = [];
         this.selectedGroups.map(gn => {
            this.editedItem.groups.push(this.groups.find(g => g.name === gn));
         });

         if (this.editedItem.userFilter === null)
            this.editedItem.userFilter = '';

         // alert('groups after:'+JSON.stringify(this.editedItem.groups));

         if (this.editedIndex > -1) {
            // if (!_areObjectsEqual(this.editedItem, this.defaultItem)) {   //TODO: this is not working.
               this.log('updating identity...');
               result = await this.apiService.updateIdentity(this.editedItem);
               this.log('in updateIdentity: editedItem='+JSON.stringify(this.editedItem)+'\nresult='+JSON.stringify(result));
               if (result.logout)
                  this.logout();
               else if (!result.message) {
                  // alert('result after edit: ' + JSON.stringify(result));
                  this.dialog = false;
                  // for (let index = 0; index < result.data.groups.length; index++) {
                  //    result.data.groups[index].policies = this.getPolicyIds(result.data.groups[index].policies);
                  // }
                  // this.editedItem.groups = originalGroups;

                  
                  // // this.$set(this.identities, this.editedIndex, this.editedItem);
                  this.getItems();
                  this.$emit('snackbar-event', this.$t('updated', { value: email }));
               }
         } else {
            this.log('creating identity...');
            result = await this.apiService.createIdentity(this.editedItem);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               if (this.duplicateItem) {
                  this.selectedItems = [];
               }
               this.dialog = false;
               // for (let index = 0; index < result.data.groups.length; index++) {
               //    const policyIds = this.getPolicyIds(result.data.groups[index].policies);
               //    if (policyIds.length === 0)
               //       result.data.groups[index].policies = policyIds;
               // }
                  // result.data.groups = originalGroups;

               
               // // this.identities.push(result.data);
               this.getItems();
               this.$emit('snackbar-event', this.$t('created', { value: result.data.email, id: result.data._id }));
            }
         }

         // this.$forceUpdate();
      },

      getPolicyIds (policies) {
         // alert(JSON.stringify(policies));
         const ids = [];
         policies.forEach(policy => {
            if (policy.hasOwnProperty('_id'))
               ids.push(policy._id);
         });
         return ids;
      },

      cancelItem () {
         this.log('in cancelItem()');
         this.$set(this.identities, this.editedIndex, this.defaultItem);
         this.dialog = false;
         this.$emit('snackbar-event', this.$t('action-cancelled', { action: (this.editedIndex === -1 ? 'CREATE' : 'EDIT') + ' IDENTITY' }));
      },

      closeItem () {
         this.log('in closeItem()');
         setTimeout(() => {
            this.editedItem = new Identity();
            this.editedIndex = -1;
            this.selectedGroups = [];
            this.emailRules = [];
            this.selectRules = [];
            this.daysEffectRules = [];
            this.hoursEffectRules = [];
            this.cidrRules = [];
            this.duplicateItem = false;
         }, 100)
      },

      isAuthorizedAction(item) {
         // the user is authorized to perform actions if it is a PA with aid='*' or is a PU with aid!='*'
         return (this.jwt.pa && item.aid === '*') || (this.jwt.pu && item.aid != '*');
      },

      allGroupsChecked(){
         this.selectedGroups = this.groupsDropdown;
      },

      async addGroupsToIdentities () {
         this.log('in addGroupsToIdentities()');
         this.selectChanged();

         if (!this.$refs.addGroupsForm.validate()) {
            return;
         }

         // getting group objects from their names
         let groups2Add = this.selectedGroups.map(gn => {
            return this.groups.find(group => group.name === gn);
         });

         let responses = await this.apiService.addGroupsToIdentities(this.selectedItems, groups2Add);
         this.log('in addGroupsToIdentities(): results=' + JSON.stringify(responses));

         let results = {
            succeeded: [], //only emails of deleted identities
            failed: []     //{ email: '', message: '' }
         };

         this.dialogAddGroups = false;

         responses.forEach(response => {
            if (response.logout)
               this.logout();
            else if (response.message === null) {
               results.succeeded.push(response.data.email);
               this.$set(this.identities, this.identities.findIndex(item => item._id === response.data._id), response.data);
            } else {
               results.failed.push({ email: response.data.email, message: response.message });
            }
         });

         let msg = '';
         if (results.succeeded.length > 0) {
            msg = 'Following ' + this.$tc('identity-was', results.succeeded.length) + ' updated:\n' + results.succeeded.join(', ') + '\n\n';
         }

         if (results.failed.length > 0) {
            msg += `Following ${this.$tc('identity', results.failed.length)} could not be updated:\n`;
            results.failed.forEach (result => {
               msg += '\n- ' + result.email + ' => ' + result.message;
            });
            alert(msg);
            this.$emit('snackbar-event', this.$t('action-finished', { action: this.menuItems.addGroups.toUpperCase() }));
         } else {
            this.$emit('snackbar-event', `${results.succeeded.length} ${this.$tc('identity-was', results.succeeded.length)} updated.`);
         }

         this.selectedItems = [];
      },

      cancelAddGroups () {
         this.dialogAddGroups = false;
         this.$emit('snackbar-event', this.$t('action-cancelled', { action: this.menuItems.addGroups.toUpperCase() }));
      },

      closeAddGroups () {
         this.log('in closeAddGroups()');
         setTimeout(() => {
            this.selectedGroups = [];
            this.selectRules = [];
            this.isAllGroupsChecked = false;
         }, 100)
      },

      manageCreds (item) {
         this.defaultItem = JSON.parse(JSON.stringify(item));
         this.editedItem = Object.assign({}, item);
         this.editedIndex = this.identities.indexOf(item);
         this.dialogCreds = true;
      },

      async addCred () {
         this.log('in addCred()');
         let result;
         const cred = {
            _id: this.editedItem._id + '-' + (this.editedItem.apiCredentials.length + 1),
            accessKey: _generateUUID(),
            secretKey: _generateUUID(),
            isActive: true,
            description: ''
         }

         result = await this.apiService.addCredential(this.editedItem, cred);
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.isApiCredentialsChanged = true;
            this.$emit('snackbar-event', `A new credential with id# ${result.data._id} was added to ${this.editedItem.email}.`);
            this.copyToClipboardHint = 'Copy Secret Key to the Clipboard';
            this.showSecretKey = true;
         }
      },

      async copyToClipboard() {
         if (this.isNavigator) {
            await navigator.clipboard.writeText(this.editedItem.apiCredentials[this.editedItem.apiCredentials.length - 1].secretKey);
            this.copyToClipboardHint = 'Secret Key was copied to the clipboard at ' + new Date().toString().split(' ')[4];
         }
      },

      editCredClicked (cred) {
         this.showSecretKey = false;
         this.defaultCred = cred;
         this.editedCred = JSON.parse(JSON.stringify(cred));
         this.dialogEditCred = true;
         setTimeout(() => {
            if (!this.editedCred.description)
               this.$refs.editedCred_desc.focus();
         }, 10);

      },

      async editCred () {
         const what = this.$t('is-active-' + this.editedCred.isActive.toString());
         if (this.editedCred.description === null)
            this.editedCred.description = '';
         // if (confirm(`Are you sure you want to ${what} '${this.editedCred._id}'?`)) {
            let result = await this.apiService.editCredential(
               this.editedItem,
               this.editedCred,
               this.editedCred.description != this.defaultCred.description
            );
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.isApiCredentialsChanged = true;
               this.$emit('snackbar-event', `API credential with id# "${this.editedCred._id}" was ${what}d.`);
               this.dialogEditCred = false;
            }
         // }
      },

      cancelEditCred() {
         // this.$emit('snackbar-event', `Editing API credential "${this.defaultCred._id}" was cancelled.`);
         this.dialogEditCred = false;
      },

      async deleteCred (cred) {
         if (confirm(`WARNING: Please note that by deleting this API credential, no more access will be granted to its API User as soon as you click on the OK button.\n\nAre you sure you want to delete '${cred._id}'?`)) {
            this.showSecretKey = false;
            let result = await this.apiService.deleteCredential(this.editedItem, cred);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.isApiCredentialsChanged = true;
               this.$emit('snackbar-event', this.$t('deleted', { value: cred._id }));
            }
         }
      },

      closeCreds () {
         this.log('in closeCreds()');
         if (this.isApiCredentialsChanged) {
            this.getItems();
            this.isApiCredentialsChanged = false;
         }
         setTimeout(() => {
            this.editedItem = new Identity();
            this.editedIndex = -1;
            this.$forceUpdate();
         }, 100)
      },

      duplicateItemClicked () {
         this.log('in duplicateItemClicked()');
         this.duplicateItem = true;
         const item = this.selectedItems[0];
         this.selectedGroups = this.getNames(item.groups);
         this.editedItem = new Identity(
            item.email,
            item.days,
            item.daysEffect,
            item.hours,
            item.hoursEffect,
            item.cidr,
            // item.hasApiAccess
         );

         this.dialog = true;
         this.emailChanged();

         setTimeout(() => {
            if (!this.$refs.identityForm.validate()) {
               this.isIdentityFormValid = false;
            }
         }, 5);
      },

      populateTestData () {
         this.log('in populateTestData()');
         const index = Math.floor(Math.random() * (this.eligibleGroups.length - 1));
         this.selectedGroups = this.getNames([this.eligibleGroups[index]]);
         this.editedItem = new Identity(
            `new_idntity_${this.identities.length + 1}@mindfireinc.com`,
            `New Idntity ${this.identities.length + 1}`,
            ['sat', 'sun'],
            'nin',
            [9,10,11,12,13,14,15,16,17],
            'in'
         );
      }
   },

   created() {
      this.init();
      this.settings.saveLastPageViewed('identities');
      if (this.$route.query.search)
         this.search = this.$route.query.search;
      this.isNavigator = navigator.clipboard;
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>
