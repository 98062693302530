/*jshint esversion: 6 */
export class Policy {
   constructor(name, isAdmin, actions, conditions, conditionLogic) {
      // this._id = '';
      // this.ownerPaid = '';
      this.name = name || '';
      this.isAdmin = isAdmin || false;
      this.actions = actions || [];
      this.conditions = conditions || [];
      this.conditionLogic = conditionLogic || 'and';
   }
}