/*jshint esversion: 8*/

/* HISTORY:
- 11/17/22(B1.3): Added _getConsumerCode() function + Added isDebug param to the getCrons(), getSchedules() and getUpcomingSchedules() methods +
   Changed _parseError() to check error.request after error.message.
- 08/16/22(B1.2): Added isDebug param to the createCron() method.
- 05/02/22(B1.1): Implemented logout feature.
- 07/19/21(B1.0): Passed consumerType to the constructor.
- 08/19/20(B0.6): Added constructor to set DEBUG and IS_JSON_SERVER dynamically.
- 08/17/20(B0.5): Handled 403 errors separately.
- 05/27/20(B0.4): Removed terminated and cronDesc in createCron()
- 05/22/20(B0.3): Modified getUpcomingSchedules() to make data object same as other schedues +
- 05/22/20(B0.2): added consumerCode to all get and put string queries + made date in getUpcomingSchedules() be iso +
   exempt 404 from being error for getCrons() and getSchedules(). 
- 05/22/20(B0.1): 1st release. 
*/

import axios from 'axios';

// const CONSUMER_CODE = 'eb775df1-8fdd-44a5-9d34-da9b8ee55d57';
let CONSUMER_CODE, DEBUG, IS_JSON_SERVER, API_URL;

function _log(msg) {
   if (DEBUG)
      console.log(`bt-cron-scheduler-api-service B1.3 says => ${msg}`);
}

function _initResult(data, message) {
   return {
      data: data || null,
      message: message || null,
      logout: false
   };
}

// function _parseError(method, error, displayError =false) {
//    _log('error=' + JSON.stringify(error));
//    const errMsg = `Error in '${method}':\n` + error.message;

//    if (displayError)
//       alert(errMsg);

//    return errMsg;
// }
function _parseError(method, error, data =null, displayError =false) {
   _log(`method=${method}, error=${JSON.stringify(error)}`);

   const result = _initResult();
   if (error.response) {
      if (error.response.status === 401)  //unauthorized
         result.logout = true;
      else if (error.response.status == 403) //forbidden
         result.message = "You don't have permission to view crons!";
      else {
         if (error.response.status === 404)  //not found
            result.data = data;
         if (result.data === null)
            result.message = `${JSON.stringify(error.response.data)} (status=${error.response.status})`;
      }
   } else if (error.message)
      result.message = error.message;
   else if (error.request) {
      if (typeof error.request === 'string')
         result.message = error.request;
      else
         result.message = JSON.stringify(error.request);
   }

   if (result.message)
      result.message = `Error in '${method}':\n${result.message}`;

   if (displayError && result.message)
      alert(result.message);

   return result;
}

//This is only for the use in development
function _createSchedule(cron) {
   //POST to /api/scheduler/v1/schedules
   _log('createSchedule(): cron=' + JSON.stringify(cron));
   let result = _initResult();

   try {
      const schedule = {
            "scheduleDate": "2022-06-01T10:30:00.000Z",
            "status": "waiting",
            "cron": {
              "_id": cron._id,
              "name": cron.name,
              "cronDesc": cron.cronDesc
            },
            "consumerCode": CONSUMER_CODE,
            "accountId": "23"
      };
      const response = axios.post(API_URL + '/schedules', schedule);
      _log(`response=${JSON.stringify(response)}`);
   } catch (error) {
      // result.message = _parseError('createCron', error, true);
      result.message = _parseError('createCron', error, null, true);
   }
}

function _getConsumerCode(isDebug) {
   if (isDebug)
      return CONSUMER_CODE.substr(0, CONSUMER_CODE.length - 4) + '-dev';
   else
      return CONSUMER_CODE;
}

export class APIService {
   constructor(consumerType, debug, isJsonServer) {
      // alert(`in APIService constructor: consumerType=${consumerType}, debug=${debug}, isJsonServer=${isJsonServer}`)
      if (consumerType === 'export')
         CONSUMER_CODE = 'b4e6b535-7819-4fbe-bed5-0e215f437d89';
      else
         CONSUMER_CODE = 'eb775df1-8fdd-44a5-9d34-da9b8ee55d57';
      DEBUG = debug;
      IS_JSON_SERVER = isJsonServer;
      API_URL = process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + (isJsonServer ? '' : '/api/scheduler/v1');
   }

   /********************************/
   /*            CRONS             */
   /********************************/

   async getCrons(paramStr, isDebug) {
      //GET to /api/scheduler/v1/crons?from=&to=&skip=0&limit=10&terminated=true|false&instances=isoDate
      //instances param will provide future schedules till isoDate
      _log(`APIService.getCrons() started: paramStr=${paramStr}, isDebug=${isDebug}`);
      let result = _initResult();

      try {
         const params = new URLSearchParams();
         const paramParts = paramStr.split('=');
         params.append(paramParts[0], paramParts[1]);
         //B1.3: params.append('consumerCode', CONSUMER_CODE);
         params.append('consumerCode', _getConsumerCode(isDebug));

         let response = await axios({
            url: API_URL + '/crons',
            method: 'GET',
            params: params
         });

         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result.message = _parseError('getCrons', error, []);
      }
   
      return result;
   }

   async getCron(id) {
      //GET /api/scheduler/v1/crons/:id (switch to cron tab)
      _log('APIService.getCron() started for id ' + JSON.stringify(id));
      let result = _initResult();

      try {
         const params = new URLSearchParams();
         params.append('consumerCode', CONSUMER_CODE);

         let response = await axios({
            url: API_URL + '/crons/' + id,
            method: 'GET',
            params: params
         });

         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // if (error.response.status == 403)
         //    result.message = "You don't have permission to view crons!";
         // else
            result.message = _parseError('getCron', error);
      }
   
      return result;
   }

   async createCron(cron, isDebug) {
      //POST to /api/scheduler/v1/crons
      _log('createCron(): cron=' + JSON.stringify(cron));
      let result = _initResult();

      try {
         // if (isDebug)
         //    cron.consumerCode = CONSUMER_CODE.substr(0, CONSUMER_CODE.length - 4) + '-dev';
         // else
         //    cron.consumerCode = CONSUMER_CODE;
         cron.consumerCode = _getConsumerCode(isDebug);

         if (IS_JSON_SERVER)
            cron.createdAt = new Date().toISOString();
         else {
            delete cron.cronDesc;
            delete cron.terminated;
         }
         const response = await axios.post(API_URL + '/crons', cron);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
         if (IS_JSON_SERVER) {
            _createSchedule(result.data);
         }
      } catch (error) {
         // if (error.response.status == 403)
         //    result.message = "You don't have permission to create crons!";
         // else
            result.message = _parseError('createCron', error);
      }
   
      return result;
   }

   async updateCron(cron) {
      //PUT to /api/scheduler/v1/crons/:id
      _log('in APIService.updateCron(): cron='+JSON.stringify(cron));
      let result = _initResult();
      
      try {
         const params = new URLSearchParams();
         params.append('consumerCode', CONSUMER_CODE);

         const response = await axios.put(
            API_URL + '/crons/' + cron._id,
            cron,
            { params: params }
         );
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // if (error.response.status == 403)
         //    result.message = "You don't have permission to update crons!";
         // else
            result.message = _parseError('updateCron', error);
      }

      return result;
   }

   /********************************/
   /*          SCHEDULES           */
   /********************************/

   async getSchedules(status, fd, td, isDebug) {
      //GET /api/scheduler/v1/schedules?skip=0&limit=10&status=&from=&to=
      _log(`APIService.getSchedules() started: status=${status}, fd=${fd}, td=${td}, isDebug=${isDebug}`);
      let result = _initResult();

      try {
         const params = new URLSearchParams();
         //B1.0 if (!IS_JSON_SERVER) {
            //B1.3: params.append('consumerCode', CONSUMER_CODE);
            params.append('consumerCode', _getConsumerCode(isDebug));
            params.append('from', fd);
            params.append('to', td);
         // }
         if (status)
            params.append('status', status);
         
         let response = await axios({
            url: API_URL + '/schedules',
            method: 'GET',
            params: params
         });

         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result.message = _parseError('getSchedules', error, []);
      }
   
      return result;
   }

   async getSchedule(scheduleId) {
      //GET /api/scheduler/v1/schedules/:id
      _log('APIService.getSchedule() started');
      let result = _initResult();

      try {
         const params = new URLSearchParams();
         params.append('consumerCode', CONSUMER_CODE);

         let response = await axios({
            url: API_URL + '/schedules/' + scheduleId,
            method: 'GET',
            params: params
         });

         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // if (error.response.status == 403)
         //    result.message = "You don't have permission to view schedules!";
         // else
            result.message = _parseError('getSchedule', error);
      }

      return result;
   }

   async getCronSchedules(cronId) {
      //GET /api/scheduler/v1/schedules?cronId (switch to schedule tab)
      _log('APIService.getCronSchedules() started');
      let result = _initResult();

      try {
         const params = new URLSearchParams();
         if (!IS_JSON_SERVER)
            params.append('consumerCode', CONSUMER_CODE);
         params.append('cronId', cronId);

         let response = await axios({
            url: API_URL + '/schedules/',
            method: 'GET',
            params: params
         });

         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // if (error.response.status == 403)
         //    result.message = "You don't have permission to view this cron's schedules!";
         // else if (error.response.status == 404)
         //    result.data = [];
         // else
         //    result.message = _parseError('getCronSchedules', error);
         result.message = _parseError('getCronSchedules', error, []);
      }
   
      return result;
   }

   async getUpcomingSchedules(endDate, isDebug) {
      let result;
      const schedules = [];
      if (IS_JSON_SERVER) {
         result = _initResult();
         result.data = [
            { name: 'Daily-1', date: '2022-06-02T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-03T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-04T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-05T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-06T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-07T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-08T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-09T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-10T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-11T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-12T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-13T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-14T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-15T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-11-16T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-11-17T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-11-18T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-11-19T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-11-20T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-11-21T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-11-22T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-11-23T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-11-24T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-11-25T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-11-26T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-27T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-28T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-29T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-06-30T16:15:00.000Z', cron: '3' },
            { name: 'Daily-1', date: '2022-07-01T16:15:00.000Z', cron: '3' },
            { name: 'Weekly with FTP', date: '2022-12-01T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2022-12-04T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2022-12-08T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2022-12-11T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2022-12-15T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2022-11-18T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2022-11-22T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2022-11-25T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2022-11-29T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2023-01-02T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2023-01-06T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2023-01-09T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2023-01-13T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2023-01-16T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2023-01-20T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2023-01-23T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2023-01-27T20:00:00.000Z', cron: '4' },
            { name: 'Weekly with FTP', date: '2023-01-30T20:00:00.000Z', cron: '4' },
         ];
      } else {
         // return await this.getCrons('instances=' + endDate);
         result = await this.getCrons('instances=' + endDate, isDebug);
      }

      if (result.data) {
         result.data.forEach(item => {
            schedules.push({
               "_id": new Date().getTime(),
               "scheduleDate": item.date,
               "status": "upcoming",
               "cron": {
                  "_id": item.cron,
                  "name": item.name,
                  "cronDesc": ""
               }
            });
         });

         result.data = schedules;
      }
      _log('in getUpcomingSchedules(): result=' + JSON.stringify(result));
      return result;
   }
}
