<template>
   <div class="iam">
      <v-content class="px-0 pt-0 pb-0">
         <!-- <div>IAM props: aid={{aid}}, paid={{paid}}, au={{au}}, pu={{pu}}</div> -->

         <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
            <span>{{ this.snackbarMsg }}</span>
            <v-btn text color="white" @click="snackbar = false">Close</v-btn>
         </v-snackbar>

         <v-layout>
            <Menu
               :baseRoute="baseRoute"
               :mini-variant="miniVariant"
               @more-click="moreClicked"
            />
            <router-view 
               :debug="false"
               :is-actual-endpoint="true"
               @snackbar-event="setSnackbar"
            ></router-view>
         </v-layout>
      </v-content>
   </div>
</template>

<script>
import Menu from '../components/IamMenu.vue';
import { IamSettings } from '../models/iam-settings.js';

export default {
   components: {
      Menu
   },

   data() {
      return {
         baseRoute: "iam",
         snackbar: false,
         snackbarMsg: '',
         settings: new IamSettings()
      }
   },

   computed: {
      miniVariant() {
         return !this.settings.keepMenuExpanded;
      }
   },
   methods: {
      setSnackbar (msg) {
         this.snackbarMsg = msg;
         this.snackbar = true;
      },
      moreClicked() {
         this.settings.keepMenuExpanded = !this.settings.keepMenuExpanded;
         this.settings.save();
      }
   },
   created() {
      if(this.$route.query.route){
         if(this.$route.query.search)
            this.$router.push('/iam/' + this.$route.query.route + '?search=' + this.$route.query.search);
         else
            this.$router.push('/iam/' + this.$route.query.route);
      }
      else
         this.$router.push(`/${this.baseRoute}/${this.settings.lastPageViewed}`);
   }
};
</script>
