<template>
   <!-- TODO: 
   - adjust photo and info
   1- @vue/eslint-config-prettier@5.0.0 requires a peer of prettier@>= 1.13.0 but none is installed. You must install peer dependencies yourself.
   2- sass-loader@8.0.0 requires a peer of node-sass@^4.0.0 but none is installed. You must install peer dependencies yourself.
   3- sass-loader@8.0.0 requires a peer of fibers@>= 3.1.0 but none is installed. You must install peer dependencies yourself.
   -->
   <!-- HISTORY:
   - V240821.2: Consumed BtKpi.vue component for the KPIs + Moved chart/table toggle icons inside <v-expansion-panel-header>.
   - V240820.1: Added settings and kpisData props and consumed them to add a row of KPIs on the top +
      Added an icon to toggle between chart and table + Used paginaTableSettings.events to filter specified events.
   - V240816.1: Copied from BtContactProfile and modified accordingly to be used in BtChart's paginated table.
   -->
   <v-container fluid>
      <v-icon dark
         :color="iconColor"
         @click="iconClicked"
      >{{iconName}}</v-icon>

      <v-dialog no-click-animation persistent scrollable
         max-width="1200px"
         v-model="dialog"
      >
         <v-card>
            <v-card-text class="pb-0" style="height: 95vh">
               <v-container>

                  <v-row v-if="kpisData.length" class="pt-5">
                     <v-col v-for="(kpiData,i) in kpisData" :key="i" :cols="12/kpisData.lenght">
                        <v-card
                           :loading="cfLoading"
                        >
                           <v-card-title
                              class="title grey--text darken-4 font-weight-bold pl-3 pr-0 pt-2 pb-2">
                              <v-icon>{{getKpiIcon(i)}}</v-icon>
                              <span class="pl-1">{{getKpiTitle(i)}}</span>
                           </v-card-title>
                           <v-card-text class="px-3 py-0 pb-2">
                              <bt-kpi
                              :format-numbers="false"
                                 :filter="kpiFilter"
                                 :icon="getKpiIcon(i)"
                                 :kpi1="getKpiData(kpiData)"
                                 template-name="master-date-range"
                              ></bt-kpi>
                           </v-card-text>
                        </v-card>
                     </v-col>
                  </v-row>

                  <v-row class="py-0">
                     <v-col cols="12" xs="12" sm="12" md="5" lg="5" class="pb-0">
                        <v-expansion-panels multiple focusable
                           class="pb-3"
                           :expand-icon="weeklyChartIcon"
                           v-model="panelsChartL"
                           @click="changeWeeklyChartType"
                        >
                           <v-expansion-panel readonly>
                              <v-card :loading="cfLoading">
                                 <v-expansion-panel-header
                                    @click="changeWeeklyChartType"
                                 >
                                    <strong>Weekly</strong>
                                    <template v-slot:actions>
                                       <v-icon>{{weeklyChartIcon}}</v-icon>
                                    </template>
                                 </v-expansion-panel-header>
                                 <v-expansion-panel-content>
                                    <GChart
                                       :type="weeklyChartType"
                                       :data="weeklyChartData"
                                       :options="chartOptions"
                                       :settings="chartSettings"
                                    />
                                 </v-expansion-panel-content>
                              </v-card>
                           </v-expansion-panel>
                        </v-expansion-panels>
                     </v-col>

                     <v-col cols="12" xs="12" sm="12" md="7" lg="7" class="pb-0">
                        <v-expansion-panels multiple focusable
                           class="pb-3"
                           v-model="panelsChartL"
                        >
                           <v-expansion-panel readonly>
                              <v-card :loading="cfLoading">
                                 <v-expansion-panel-header
                                    @click="changeMonthlyChartType"
                                 >
                                    <strong>Monthly</strong>
                                    <template v-slot:actions>
                                       <v-icon>{{monthlyChartIcon}}</v-icon>
                                    </template>
                                 </v-expansion-panel-header>
                                 <v-expansion-panel-content>
                                    <GChart
                                       :type="monthlyChartType"
                                       :data="monthlyChartData"
                                       :options="chartOptions"
                                       :settings="chartSettings"
                                    />
                                 </v-expansion-panel-content>
                              </v-card>
                           </v-expansion-panel>
                        </v-expansion-panels>
                     </v-col>
                  </v-row>

                  <v-row class="pt-0">
                     <v-col xs="12" sm="12" md="5" lg="5"><!-- contact fields -->
                        <v-expansion-panels multiple focusable v-model="panelsFields">
                           <v-expansion-panel readonly>
                              <v-card :loading="cfLoading">
                                 <v-expansion-panel-header hide-actions>
                                    <strong>Record Fields</strong>
                                    <v-spacer></v-spacer>
                                    {{contactFieldsTooltip}}
                                    <!-- <span class="font-weight-black mx-0">Contact Fields</span>
                                    <span class="caption pl-1">({{contactFieldsTooltip}})</span> -->
                                 </v-expansion-panel-header>
                                 <v-expansion-panel-content class="pt-2">
                                    <v-row v-if="fieldFormat=='2-col'">
                                       <v-col v-for="i in standardProps.length" :key="i" :cols="12 / numOfCols">
                                          <v-row v-for="p in standardProps[i-1]" :key="p" dense>
                                             <v-col cols="6" :class="headerClass">{{p}}:</v-col>
                                             <v-col cols="6">{{cf.standard[p]}}</v-col>
                                          </v-row>
                                       </v-col>
                                    </v-row>
                                    <v-row v-else>
                                       <v-col v-for="i in standardProps.length" :key="i" :cols="12 / numOfCols">
                                          <v-row v-for="p in standardProps[i-1]" :key="p" dense>
                                             <v-col cols="12">
                                                <span :class="headerClass">{{getDesc(p)}}:&nbsp;</span>
                                                <span>{{cf.standard[p]}}</span>
                                             </v-col>
                                          </v-row>
                                       </v-col>
                                    </v-row>
                                 </v-expansion-panel-content>
                              </v-card>

                           </v-expansion-panel>
                        </v-expansion-panels>
                     </v-col>

                     <v-col xs="12" sm="12" md="7" lg="7"><!-- events -->
                        <v-expansion-panels multiple focusable
                           v-model="panelsEvents"
                        >
                           <v-expansion-panel readonly>
                              <v-card :loading="cfLoading">
                                 <v-expansion-panel-header hide-actions>
                                    <strong>Events</strong>
                                    <v-spacer></v-spacer>
                                    {{eventsTooltip}}
                                 </v-expansion-panel-header>
                                 <v-expansion-panel-content>
                                    <v-timeline dark dense color="blue">
                                       <v-timeline-item fill-dot
                                          v-for="(e, i) in cf.events" :key="i"                                        
                                          :color="getColor(e.app_code)"
                                       >
                                          <template v-slot:icon>
                                             <v-avatar size="32">
                                                <img :src="getIcon(e.app_code)">
                                             </v-avatar>
                                          </template>
                                          <v-card dark :color="getColor(e.app_code)" class="pb-0">
                                             <v-card-title class="pt-2" style="height:46px;">
                                                <span class="title">{{getAppDesc(e.app_code)}}: {{getEventDesc(e.event_code)}}</span>
                                                <v-spacer></v-spacer>
                                                <span class="body-2">{{formatTimestamp(e.event_date).dateTime}}</span><br>
                                             </v-card-title>

                                             <v-card-text class="white text--primary pt-2 pb-1">
                                                <p v-for="prop in getEventProps(e)" :key="prop" class="mb-0">
                                                   <span :class="headerClass">{{prop}}: </span>
                                                   <span>{{e[prop]}}</span>
                                                </p>
                                                <v-expansion-panels v-if="e.event_data"
                                                   multiple focusable
                                                   class="py-3"
                                                   v-model="e.panelsEventData"
                                                >
                                                   <v-expansion-panel>
                                                      <v-card>
                                                         <v-expansion-panel-header :color="getColorForEventData(e.app_code)">
                                                            <strong>Event Data</strong>
                                                         </v-expansion-panel-header>
                                                         <v-expansion-panel-content class="white text--primary px-0 pt-2 pb-0">
                                                            <v-row v-for="ed in Object.keys(e.event_data)" :key="ed" dense>
                                                               <v-col cols="12">
                                                                  <span :class="headerClass">{{ed}}:&nbsp;</span>
                                                                  <span>{{e.event_data[ed]}}</span>
                                                               </v-col>
                                                            </v-row>
                                                         </v-expansion-panel-content>
                                                      </v-card>
                                                   </v-expansion-panel>
                                                </v-expansion-panels>
                                             </v-card-text>
                                          </v-card>
                                       </v-timeline-item>
                                    </v-timeline>
                                 </v-expansion-panel-content>
                              </v-card>
                           </v-expansion-panel>
                        </v-expansion-panels>
                     </v-col>
                  </v-row>

               </v-container>
            </v-card-text>

            <v-card-actions class="mr-8">
               <div class="flex-grow-1"></div>
               <v-btn text
                  color="blue darken-1"
                  @click="closeDialog"
               >Close</v-btn>
            </v-card-actions>
         </v-card>

         <!-- <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
         </v-overlay> -->

      </v-dialog>
   </v-container>
</template>

<script>
import { format } from "date-fns";
import { GChart } from 'vue-google-charts';
import { hasOwn } from '../mixins/bt-mixin.js';
import BtKpi from './BtKpi.vue';

const NAME = "BtContactProfileForCs";
const COLORS = ['red darken-4','teal darken-1','amber accent-2','lime lighten-3','deep-purple accent-4','blue accent-3','yellow darken-3','deep-orange lighten-2','light-blue darken-2','purple lighten-3','brown darken-3'];

class ContactFields {
   constructor() {
      this.standard = {};
      this.system = {}
      this.empty = [];
      this.events = [];
   }
}

let DEBUG;

function _log(msg, log) {
  if (DEBUG || log) console.log(`${NAME} V240821.2 says => ${msg}`);
}

export default {
   name: NAME,

   components: { 
      GChart,
      BtKpi
   },

   props: {
      contactData: {
         type: Object,
         default: () => {}
      },
      eventDataFields: {
         type: Array
      },
      monthlyChartData: {
         type: Array,
         default: () => []
      },
      weeklyChartData: {
         type: Array,
         default: () => []
      },
      debug: {
         type: Boolean,
         default: false
      },
      iconName: {
         type: String,
         default: "person"
      },
      iconColor: {
         type: String,
         default: "grey darken-1"
      },
      importHeaders: {
         type: Array,
         default: () => []
      },
      kpisData: {
         //sample: {"_id":{"Event Code":"30500"},"Average":247.33333333333334}
         type: Array,
         default: () => []
      },
      labels: {
         type: Array,
         default: () => []
      },
      fieldFormat: {
         type: String,
         default: '1-col',
         validator: value => { return ['1-col', '2-col'].indexOf(value.toLowerCase()) !== -1 }
      },
      settings: {
         type: Object,
         default: () => {}
      },
   },

   data() {
      return {
         dialog: false,
         panelsChartL: [0],
         panelsChartR: [0],
         panelsFields: [0],
         panelsEvents: [0],
         panelsEventData: [],
         cf: new ContactFields(),
         headerClass: 'font-weight-bold flex-grow-1 flex-shrink-0',
         standardProps: [],
         customProps: [],
         // overlay: true,
         contactFieldsTooltip: '',
         eventsTooltip: '',
         cfLoading: true,
         chartOptions: {
            "height":"230",//250
            "width":"100%",//370,100%
            "legend":{"position":"none"},
            "chartArea":{"left":"40","right":"20"},
            "hAxis":{"format":"MMM dd","gridlines":{"count":5}}
         },
         chartSettings: {
            packages: ['corechart', 'table', 'map', 'geochart'],
            mapsApiKey: process.env.VUE_APP_GOOGLE_API_KEY
         },
         unknownApps: [],
         weeklyChartType: 'LineChart',
         weeklyChartIcon: 'table_chart',
         monthlyChartType: 'LineChart',
         monthlyChartIcon: 'table_chart',
         kpiFilter: {
            standard:[{$match:{}}],
            columns:[]
         }
      }
   },

   computed: {
      headerCols() {
         return 3;
      },

      numOfCols() {
         return 1;
      },

      showDivider() {
         return this.standardProps.length > 0 && this.standardProps[0].length > 0 
            && this.customProps.length > 0 && this.customProps[0].length > 0;
      }
   },

   watch: {
      contactData() {
         this.initDialog();
      }
   },

   methods: {
      iconClicked() {
         this.dialog = true;
         this.initDialog();
      },

      initDialog() {
         _log(`in initDialog(): contactData=${JSON.stringify(this.contactData)}`, true);

         // this.overlay = true;
         this.cfLoading = true;

         if (!this.contactData || !Object.keys(this.contactData).length) {
            return;
         }

         this.cf = new ContactFields();

         for (var prop in this.contactData) {
            const val = this.contactData[prop];
            if (val) {
               if (['__v'].includes(prop))
                  continue;
               else if (prop === 'events')
                  this.processEvents(val);
               else if (['basePurl', 'purlNumber', 'dedupHash', 'rowNumber'].includes(prop))
                  this.cf.system[prop] = val;
               else {
                  const header = this.importHeaders.find(h => h.value === prop);
                  // alert('prop=' + prop + '\nheader=' + JSON.stringify(header))
                  if (header)
                     this.cf.standard[header.text] = val;
                  else
                     this.cf.standard[this.getDesc(prop)] = val;
               }
            } else
               this.cf.empty[prop];
         }

         this.standardProps = this.getProps(this.cf.standard);
         // alert('standardProps=' + JSON.stringify(this.standardProps));

         if (this.standardProps[0].length > 0)
            this.contactFieldsTooltip = `${this.standardProps[0].length} fields`;

         _log(`in initDialog(): cf=${JSON.stringify(this.cf)}`);
         this.cfLoading = false;
         // this.overlay = false;
      },

      processEvents(events) {
         // alert('in processEvents: events=' + JSON.stringify(events));
         let inbound = 0;
         let outbound = 0;

         events.forEach(event => {
            //alert(JSON.stringify(this.settings))
            if (this.settings?.events?.length && !this.settings.events.includes(event.event_code))
               return;

            if (event.app_code === '305') inbound++;
            else outbound++;

            var e = { panelsEventData: [] };
            const eventData = {};

            for (var prop in event) {
               if (hasOwn(event, prop)) {
                  const val = event[prop];
                  if (prop === 'event_data' && Object.keys(prop).length) {
                     Object.keys(val).forEach(key => {
                        if (hasOwn(val, key)) {
                           const fld = this.eventDataFields.find(f => f.value === key);
                           eventData[fld?.text || key] = val[key];
                        }
                     });
                  } else {
                     e[prop] = val;
                  }
               }
            }
            if (Object.keys(eventData).length)
               e.event_data = eventData;
            this.cf.events.push(e);
         });

         let iTooltip = '';
         let oTooltip = '';
         if (inbound > 0) iTooltip = `${inbound} inbound`;
         if (outbound > 0) oTooltip = `${outbound} outbound`;
         this.eventsTooltip = this.joinStrings(iTooltip, oTooltip);
      },

      joinStrings(s1, s2) {
         let joint = s1;
         if (s2) {
            if (s1) joint += ', ' + s2;
            else joint = s2;
         }

         return joint;
      },

      getProps(fields) {
         var arrProps = [];
         for (var i = 0; i < this.numOfCols; i++) {
            arrProps[i] = [];
         }

         var seq = 0;

         for (var prop in fields) {
            if (hasOwn(fields, prop)) {
               arrProps[(seq + this.numOfCols) % this.numOfCols].push(prop);
               seq++;
            }
         }
      
         for (i = 0; i < this.numOfCols; i++)
            arrProps[i].sort();

         _log('in getProps(): arrProps=' + JSON.stringify(arrProps));
         return arrProps;
      },

      getDesc(prop) {
         switch (prop) {
            case '_id':
               return 'Record ID'
            case 'importId':
               return 'Import ID'
            case 'purl':
               return 'PURL';
            case 'isseed':
               return 'Is Seed';
            default:
               return prop;
         }
      },

      getAppDesc(appCode) {
         let appLabel;
         switch (appCode) {
            case '201':
               return 'Email';
            case '205':
               return 'DirectMail';
            case '305':    //
               return 'Microsite';
            case '2009':   //
               return 'HP SmartStream';
            case '2016':   //
               return 'Text2Voice';
            case '2018':   //
               return 'SMS (Text)';
            case '2019':   //
               return 'Cloud Connect';
            case '2023':   //
               return 'Data Processor';
            case '2024':   //
               return 'Mail Merge';
            case '2026':   //
               return 'Zapier';
            case '2028':   //
               return 'PurlDP';
            case '2030':   //
               return 'SendGrid';
            case '3000':   //
               return 'RDE';
            default:
               appLabel = this.labels?.find(l => l.id == appCode)?.label;
               return appLabel || appCode;
         }
      },
      getEventDesc(eventCode) {
         _log(eventCode);
         const eventLabel = this.labels?.find(l => l.id == eventCode)?.label;
         return eventLabel || eventCode;
      },

      getColor(id) {
         let unknownApp, ind;
         switch (id) {
            case '201':    //Email
               return 'indigo';
            case '205':    //DirectMail
               return 'green lighten-1';
            case '305':    //Microsite
               return 'purple darken-1';
            case '2009':   //HP SmartStream
               return 'cyan lighten-2';
            case '2016':   //Text2Voice
               return 'gray darken-2';
            case '2018':   //SMS (Text)
               return 'pink darken-2';
            case '2019':   //Cloud Connect
               return 'brown lighten-1';
            case '2023':   //Data Processor
               return 'blue-grey lighten-1';
            case '2024':   //Mail Merge
               return 'green darken-4';
            case '2026':   //Zapier
               return 'blue lighten-2';
            case '2028':   //PurlDP
               return 'indigo lighten-2';
            case '2030':   //SendGrid
               return 'orange lighten-1';
            case '3000':   //RDE
               return 'green accent-2';
            default:
               unknownApp = this.unknownApps.find(a => a.id === id);
               if (unknownApp)
                  return unknownApp.color;
               else {
                  if (this.unknownApps.length < COLORS.length)
                     ind = this.unknownApps.length;
                  else
                     ind = this.unknownApps.length - COLORS.length;

                  this.unknownApps.push({ id: id, color: COLORS[ind] });
                  return COLORS[ind];
               }
         }
      },

      getColorForEventData(id) {
         const color = this.getColor(id).split(' ')[0];
         return color + ' lighten-4';
      },

      getIcon(id) {
         if (id === '2030')
            return 'https://s3-us-west-2.amazonaws.com/behzad-mindfire-bucket/sgLogo.png';
         else
            return 'https://mindfirestudio.s3.us-west-1.amazonaws.com/App/assets/' + id + '.png';
      },

      getEventProps (e) {
         var arrProps = [];

         for (var prop in e) {
            if (!['_id','app_code','event_code','event_data','event_date','panelsEventData'].includes(prop) && hasOwn(e, prop)) {
               arrProps.push(prop);
            }
         }
      
         _log('in getEventProps(): arrProps=' + JSON.stringify(arrProps));
         return arrProps;
      },
  
      getTimezoneOffset(timeZone, date = new Date()) {
         const tz = date.toLocaleString("en", {timeZone, timeStyle: "long"}).split(" ").slice(-1)[0];
         const dateString = date.toString();
         const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);
         // alert(`tz=${tz}\ndateString=${dateString}\noffset=${offset}`);

         // return UTC offset in minutes
         return offset / 60000;
      },

      formatTimestamp(timestamp) {
         const tzOffset = this.getTimezoneOffset("America/Los_Angeles", new Date(timestamp));
         const utc = new Date(new Date(timestamp).toUTCString());
         utc.setMinutes(utc.getMinutes() + Math.abs(tzOffset));
         // alert(`tzOffset=${tzOffset}\nutc=${utc}\n${format(utc, 'M/d/yyyy h:mm:ss a')}`);

         var parts = format(utc, 'M/d/yyyy h:mm:ss a').split(' ');
         var dt = { date: parts[0], time: parts[1] + ' ' + parts[2] };
         dt.dateTime = dt.date + ' ' + dt.time;
         return dt;
      },

      closeDialog() {
         this.dialog = false;
      },

      changeWeeklyChartType() {
         if (this.weeklyChartType === 'LineChart') {
            this.weeklyChartType = 'Table';
            this.weeklyChartIcon = 'bar_chart';
         } else {
            this.weeklyChartType = 'LineChart';
            this.weeklyChartIcon = 'table_chart';
         }
      },

      changeMonthlyChartType() {
         if (this.monthlyChartType === 'LineChart') {
            this.monthlyChartType = 'Table';
            this.monthlyChartIcon = 'bar_chart';
         } else {
            this.monthlyChartType = 'LineChart';
            this.monthlyChartIcon = 'table_chart';
         }
      },

      getKpiData(data) {
         const keys = Object.keys(data);
         if (keys.length)
            return data[keys[1]];
         else
            return 'No Data';
      },

      getKpiIcon(i) {
         return this.settings?.kpis[i]?.icon || '';
      },

      getKpiTitle(i) {
         return this.settings?.kpis[i]?.title || '';
      }
   },

   created() {},

   mounted() {
      DEBUG = this.debug;
      // alert('in mounted: labels=' + JSON.stringify(this.labels))
      // alert('in mounted: eventDataFields=' + JSON.stringify(this.eventDataFields))
   }
};
</script>

<style>
   .v-card__title.title {
      padding-top: 8px; padding-bottom: 8px;
   }
   .v-toolbar__content {
      padding: 0 0;
   }
   .theme--dark.v-timeline:before {
    background: grey;
   }
   .v-expansion-panel-header {
      min-height: 48px;
      /* font-weight: bold; */
   }
   .line-break span::before {
      content: '\A'
   }
   .v-expansion-panel-content__wrap {
      padding-bottom: 10px;
   }
</style>
