/*jshint esversion: 8*/
/* API calls for admin-service components.
 * HISTORY:
 * V240313.3: Added getIdentityAccountsCount() and getIdentityAccounts().
 * V240110.1: Added getEvents().
 * V231013.1: Accepted service param in the all methods except withSuspicious ones.
 * V231012.1: Removed getApiCallsByPartner() and added getApiCalls() and getApiCallsCount().
 * V231011.1: Added getApiCallsByUser() and getApiCallsByPartner().
 * V231010.1: Accepted source param in getApiCallsByAccount(), getApiCallsByService(), getApiCallsByHttpMethod(), getApiCallsByStatus(), getApiCallsInStatus() and getApiCallsByResource() methods.
 * V231006.1: Added suspicious activity methods().
 * V231005.2: Passed accountId to getApiCallsByResource() for the sake of added accounts dropdowns.
 * V231005.1: Added getApiCallsByResource().
 * V231004.1: Passed accountId to getApiCallsByService(), getApiCallsByHttpMethod(), getApiCallsByStatus() and getApiCallsInStatus() for the sake of added accounts dropdowns.
 * V231003.2: Passed fromTo to getApiCallsInStatus().
 * V231003.1: Added getApiCallsInStatus().
 * V231002.2: Fixed a bug in API call methods that didn't add fromTo param to the URL.
 * V231002.1: Changed getTotalApiCalls() to getApiCallsByAccount() +
 *    Added getApiCallsByService(), getApiCallsByHttpMethod() and getApiCallsByStatus().
 * V230929.1: Added getTotalApiCalls().
 * V230927.1: Passed sftpStatus to all export methods.
 * V230921.2: Added getListSettings() and getSetting() methods.
 * V230921.1: Added getTotalDDLsForCS(), getListDDLsForCS(), getDDLForCS(), getListRDLsForCS() and getRDLForCS() methods.
 * V230920.2: Passed accountId to getListRemoteAccessesCount() + Fixed the url in getListRemoteAccesses() to pass params properly.
 * V230920.1: Added getTotalRemoteAccesses(), getListRemoteAccessesCount() and getListRemoteAccesses().
 * V230915.1: Added getTotalDeletes(), getListDeletesCount() and getListDeletes.
 * V230913.1: Added getListAccounts() and getListAccount().
 * V230912.1: Added getListRDLs() and getRDL().
 * V230911.1: Added getTotalDDLs(), getListDDLs() and getDDL().
 * V230905.1: In _getUTCDateParam(), fixed the bug that calculated last day incorrectly + Added getTotalSchedules().
 * V230901.2: Put back getListExportsCount() and getListExports() which were removed accidentally +
 *    Accepted a path in the constructor for the scheduler methods.
 * V230901.1: Added getSchedulesCount() and getSchedules().
 * V230831.1: Added getListImportsCount() and getListImports().
 * V230824.2: Added getTotalExports().
 * V230824.1: 1st release.
 */

import axios from 'axios';
// import { sleep } from '../mixins/bt-mixin.js';

const BASE_MSG = '-----as-api-service V240313.3 says => ';
let _debug;

function _log(msg, showAlert) {
   if (_debug) {
      console.log(`${msg}`);
      if (showAlert)
         alert(`${BASE_MSG}${msg}`);
   }
}

function _getFromToParams(dateRange) {
   return _getUTCDateParam(dateRange[0], 'from') + '&' + _getUTCDateParam(dateRange[1], 'to');
}

// function _getUTCDateParam(dateIn, paramName) {
//    _log(`in _getUTCDateParam(): dateIn=${dateIn}, paramName=${paramName}`);
//    const dateParts = dateIn.split('-');
//    let dateTime;
//    if (paramName === 'from')
//       dateTime = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]), parseInt(dateParts[2]), 0, 0, 0);
//    else
//       dateTime = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]), parseInt(dateParts[2]), 23, 59, 59);

//    const dateOut = dateTime.getUTCFullYear() + '-' +
//       dateTime.getUTCMonth().toString().padStart(2, "0") + '-' +
//       dateTime.getUTCDate().toString().padStart(2, "0") + 'T' +
//       dateTime.getUTCHours().toString().padStart(2, "0") + ":" +
//       dateTime.getUTCMinutes().toString().padStart(2, "0") + ':' +
//       dateTime.getUTCSeconds().toString().padStart(2, "0") + 'Z';

//    return paramName + '=' + dateOut;
// }
function _getUTCDateParam(dateIn, paramName) {
   _log(`in _getUTCDateParam(): dateIn=${dateIn}, paramName=${paramName}`);
   const dateParts = dateIn.split('-');
   let dateTime;
   if (paramName === 'from')
      dateTime = new Date(parseInt(dateParts[0]), parseInt(dateParts[1] - 1), parseInt(dateParts[2]), 0, 0, 0);
   else
      dateTime = new Date(parseInt(dateParts[0]), parseInt(dateParts[1] - 1), parseInt(dateParts[2]), 23, 59, 59, 999);

   const dateOut = dateTime.getUTCFullYear() + '-' +
      (dateTime.getUTCMonth() + 1).toString().padStart(2, "0") + '-' +
      dateTime.getUTCDate().toString().padStart(2, "0") + 'T' +
      dateTime.getUTCHours().toString().padStart(2, "0") + ":" +
      dateTime.getUTCMinutes().toString().padStart(2, "0") + ':' +
      dateTime.getUTCSeconds().toString().padStart(2, "0") + '.' +
      dateTime.getUTCMilliseconds().toString().padStart(3, "0") + 'Z';

   return paramName + '=' + dateOut;
}

function _initResult() {
   return {
      data: null,
      message: null,
      logout: false
   };
}

function _parseError(error, data =null, displayError =true) {
   _log(`in _parseError(): error=${JSON.stringify(error)}, data=${data}`);
   const result = _initResult();
   if (error.response) {
      if (error.response.status === 401)  //unauthorized
         result.logout = true;
      else {
         if (error.response.status === 404)  //not found
            result.data = data;
         if (result.data === null)
            result.message = `${JSON.stringify(error.response.data)} (status=${error.response.status})`;
      }
   } else if (error.request)
      result.message = JSON.stringify(error.request); //error.request.data + ' (' + error.request.status + ')';
   else
      result.message = error.message;

   if (displayError && result.message)
      alert(result.message);

   return result;
}

export class APIService {

   constructor(token, debug, isActualEndpoint, path) {
      // alert('in APIService: debug=' + debug +
      //    '\nisActualEndpoint=' + isActualEndpoint +
      //    '\ntoken=' + token
      // );
      _debug = debug;
      this.jwt = JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
      this.isActualEndpoint = isActualEndpoint;

      let endpoint;
      if (path && path.indexOf('identity') > -1)
         endpoint = process.env.VUE_APP_IDENTITY_SERVICE_ENDPOINT;
      else
         endpoint = process.env.VUE_APP_EDGE_SERVICE_ENDPOINT;

      this.$http = axios.create({
         baseURL: endpoint +
            (isActualEndpoint ? (path ? path : '/api/contact-service/v1/admins') : ''),
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
         }
      });
   }

   /********************************/
   /*            IMPORTS           */
   /********************************/

   async getTotalImports(status, dateRange) {
      _log(`in getTotalImports(): status=${status}\ndateRange=${JSON.stringify(dateRange)}`);
      let result = _initResult();

      try {
         let url = this.isActualEndpoint ? 'imports/totalsByAccount' : 'importTotalsByAccount';
         url += `?status=${status}&${_getFromToParams(dateRange)}`;

         _log('in getTotalImports(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      // if (!this.isActualEndpoint)
      //    await sleep(10000);
      return result;
   }

   async getListImportsCount(status, dateRange, accountId) {
      _log(`in getListImportsCount(): status=${status}\ndateRange=${JSON.stringify(dateRange)}\naccountId=${accountId}`);
      let result = _initResult();

      try {
         let url;
         let params = `?status=${status}&${_getFromToParams(dateRange)}`;

         if (this.isActualEndpoint)
            url = 'imports/countInStatus' + params + (accountId ? `&account=${accountId}` : '');
         else
            url = 'importListInStatus' + params + '&_limit=1';

         _log(`in getListImportsCount(): url=${url}`);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);

         if (this.isActualEndpoint)
            result.data = Array.isArray(response.data) ? response.data[0].count : response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);

         _log(`result.data=${result.data}`);
         } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getListImports(status, dateRange, accountId, page, limit) {
      _log(`in getListImports(): status=${status}\ndateRange=${JSON.stringify(dateRange)}\naccountId=${accountId}\npage=${page}\nlimit=${limit}`);
      let result = _initResult();

      try {
         let url;
         let params = `?status=${status}&${_getFromToParams(dateRange)}`;

         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            params += (accountId ? `&account=${accountId}` : '') + `&skip=${skip}&limit=${limit}`;
            url = 'imports/listInStatus' + params;
         } else {
            params += `&_page=${page || 1}&_limit=${limit}`;
            url = 'importListInStatus' + params;
         }
        
         _log('in getListImports(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getEvents(accountId, dateRange) {
      _log(`in getEvents(): accountId=${accountId}\ndateRange=${JSON.stringify(dateRange)}`);
      let result = _initResult();

      try {
         if (this.isActualEndpoint) {
            let url = `events/countByEvents?accountId=${accountId}&${_getFromToParams(dateRange)}`;

            _log('in getEvents(): url=' + url);

            const response = await this.$http.get(url);
            _log(`response=${JSON.stringify(response)}`);
            result.data = [];
            response.data.forEach(data => {
               result.data.push({
                  eventCode: data._id['Event Code'],
                  count: data.Count
               });
            });
         } else {
            if (accountId == 36048)
               result.data = [
                  // { "_id": {"Event Code":"30500"}, "Count":12408 },
                  // { "_id": {"Event Code":"30501"}, "Count":12385 },
                  // { "_id": {"Event Code":"30502"}, "Count":3378 }
                  { eventCode: "30500", count: 12408 },
                  { eventCode: "30501", count: 12385 },
                  { eventCode: "30502", count: 3378 },
               ];
            else if (accountId == 35608)
               result.data = [
                  { eventCode: "203010", count: 1234 },
                  { eventCode: "203011", count: 56789 }
               ];
            else {
               result.data = [];
               const cnt = Math.floor(Math.random() * 10);
               for (let index = 0; index < cnt; index++) {
                  result.data.push({
                     eventCode: "305-" + (index+1).toString(),
                     count: Math.floor(Math.random() * 10000)
                  });
               }
            }
            // await sleep(3000);
         }
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   /********************************/
   /*            EXPORTS           */
   /********************************/

   async getTotalExports(status, sftpStatus, dateRange) {
      _log(`in getTotalExports(): status=${status}\nsftpStatus=${sftpStatus}\ndateRange=${JSON.stringify(dateRange)}`);
      let result = _initResult();

      try {
         let url = this.isActualEndpoint ? 'exports/totalsByAccount' : 'exportTotalsByAccount';
         url += `?status=${status}&${_getFromToParams(dateRange)}` +
            (sftpStatus ? `&sftpStatus=${sftpStatus}` : '');
         
         _log('in getTotalExports(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getListExportsCount(status, sftpStatus, dateRange, accountId) {
      _log(`in getListExportsCount(): status=${status}\nsftpStatus=${sftpStatus}\ndateRange=${JSON.stringify(dateRange)}\naccountId=${accountId}`);
      let result = _initResult();

      try {
         let url;
         let params = `?status=${status}&${_getFromToParams(dateRange)}` +
            (sftpStatus ? `&sftpStatus=${sftpStatus}` : '');

         if (this.isActualEndpoint)
            url = 'exports/countInStatus' + params +
               (accountId ? `&account=${accountId}` : '');
         else
            url = 'exportListInStatus' + params + 
               (accountId ? `&databaseName=${accountId.toString()}` : '') +
               '&_limit=1';

         _log(`in getListExportsCount(): url=${url}`);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);

         if (this.isActualEndpoint)
            result.data = Array.isArray(response.data) ? response.data[0].count : response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);

         _log(`result.data=${result.data}`);
         } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getListExports(status, sftpStatus, dateRange, accountId, page, limit) {
      _log(`in getListExports(): status=${status}\nsftpStatus=${sftpStatus}\ndateRange=${JSON.stringify(dateRange)}\naccountId=${accountId}\npage=${page}\nlimit=${limit}`);
      let result = _initResult();

      try {
         let url;
         let params = `?status=${status}&${_getFromToParams(dateRange)}` +
            (sftpStatus ? `&sftpStatus=${sftpStatus}` : '');

         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            params += (accountId ? `&account=${accountId}` : '') + `&skip=${skip}&limit=${limit}`;
            url = 'exports/listInStatus' + params;
         } else {
            params += `&_page=${page || 1}&_limit=${limit}`;
            url = 'exportListInStatus' + params;
         }
        
         _log('in getListExports(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   /********************************/
   /*           SCHEDULES          */
   /********************************/

   async getTotalSchedules(consumerCode, status, dateRange) {
      _log(`in getTotalSchedules(): consumerCode=${consumerCode}, status=${status}, dateRange=${JSON.stringify(dateRange)}`);
      let result = _initResult();

      try {
         let url = this.isActualEndpoint ? 'schedules/totalsByAccount' : 'scheduleTotalsByAccount';
         url += `?consumerCode=${consumerCode}&status=${status}&${_getFromToParams(dateRange)}`;
         
         _log('in getTotalSchedules(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      // if (!this.isActualEndpoint)
      //    await sleep(10000);

      return result;
   }

   async getListSchedulesCount(consumerCode, status, dateRange, accountId) {
      _log(`in getSchedulesCount(): consumer=${consumerCode}\nstatus=${status}\ndateRange=${JSON.stringify(dateRange)}\naccountId=${accountId}`);
      let result = _initResult();

      try {
         let url;
         let params = `?consumerCode=${consumerCode}&status=${status}&${_getFromToParams(dateRange)}`;

         if (this.isActualEndpoint)
            url = 'schedules/countInStatus' + params + (accountId ? `&account=${accountId}` : '');
         else
            url = 'scheduleListInStatus' + params + '&_limit=1';

         _log(`in getSchedulesCount(): url=${url}`);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);

         if (this.isActualEndpoint)
            result.data = Array.isArray(response.data) ? response.data[0].count : response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);

         _log(`result.data=${result.data}`);
         } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getListSchedules(consumerCode, status, dateRange, accountId, page, limit) {
      _log(`in getSchedules(): consumer=${consumerCode}\nstatus=${status}\ndateRange=${JSON.stringify(dateRange)}\naccountId=${accountId}\npage=${page}\nlimit=${limit}`);
      let result = _initResult();

      try {
         let url;
         let params = `?consumerCode=${consumerCode}&status=${status}&${_getFromToParams(dateRange)}`;

         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            params += (accountId ? `&account=${accountId}` : '') + `&skip=${skip}&limit=${limit}`;
            url = 'schedules/listInStatus' + params;
         } else {
            params += `&_page=${page || 1}&_limit=${limit}`;
            url = 'scheduleListInStatus' + params;
         }
        
         _log('in getSchedules(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      // if (!this.isActualEndpoint)
      //    await sleep(10000);
      
      return result;
   }

   /********************************/
   /*           BI-DDLs            */
   /********************************/

   async getTotalDDLs() {
      _log(`in getTotalDDLs()`);
      let result = _initResult();

      try {
         let url = this.isActualEndpoint ? '/totalsByAuthor' : 'ddlsTotalByAuthor';
         
         _log('in getTotalDDLs(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      // if (!this.isActualEndpoint)
      //    await sleep(10000);

      return result;
   }

   async getListDDLs(author) {
      _log(`in getListDDLs(): author=${author}`);
      let result = _initResult();

      try {
         const params = author ? `?author=${author}` : '';
         const url = (this.isActualEndpoint ? '/listByAuthor' : 'ddlsListByAuthor') + params;
        
         _log('in getListDDLs(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      // if (!this.isActualEndpoint)
      //    await sleep(10000);
      
      return result;
   }

   async getDDL(id) {
      _log('getDDL(): id=' + id);
      let result = _initResult();

      // if (!this.isActualEndpoint)
      //    await sleep(3000);

      try {
         // let url = '/viewById/' + id;
         const url = `${this.isActualEndpoint ? '/viewById' : 'ddlsViewById'}/${id}`;
         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*           BI-RDLs            */
   /********************************/

   async getListRDLs() {
      _log(`in getListRDLs()`);
      let result = _initResult();

      try {
         const url = this.isActualEndpoint ? '/list' : 'rdlsList';
        
         _log('in getListRDLs(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      // if (!this.isActualEndpoint)
      //    await sleep(10000);
      
      return result;
   }

   async getRDL(id) {
      _log('getRDL(): id=' + id);
      let result = _initResult();

      // if (!this.isActualEndpoint)
      //    await sleep(3000);

      try {
         const url = `${this.isActualEndpoint ? '/viewById' : 'rdlsViewById'}/${id}`;
         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*           Accounts           */
   /********************************/

   async getListAccounts(path) {
      _log(`in getListAccounts(): path=${path}`);
      let result = _initResult();

      try {
         const url = this.isActualEndpoint ? `/accounts/${path}` : 'accountList';
        
         _log('in getListAccounts(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      // if (!this.isActualEndpoint)
      //    await sleep(10000);
      
      return result;
   }

   async getListAccount(id) {
      _log('getAccount(): id=' + id);
      let result = _initResult();

      // if (!this.isActualEndpoint)
      //    await sleep(3000);

      try {
         const url = `${this.isActualEndpoint ? '/account' : 'accountViewById'}/${id}`;
         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = Array.isArray(response.data) ? response.data[0] : response.data;
         // result.data = response.data[0] || response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*            DELETES           */
   /********************************/

   async getTotalDeletes(status, dateRange) {
      _log(`in getTotalDeletes(): status=${status}, dateRange=${JSON.stringify(dateRange)}`);
      let result = _initResult();

      try {
         let url = this.isActualEndpoint ? 'deletes/totalsByAccount' : 'deleteTotalsByAccount';
         url += `?status=${status}&${_getFromToParams(dateRange)}`;
         
         _log('in getTotalDeletes(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      // if (!this.isActualEndpoint)
      //    await sleep(10000);
      return result;
   }

   async getListDeletesCount(status, dateRange, accountId) {
      _log(`in getListDeletesCount(): status=${status}\ndateRange=${JSON.stringify(dateRange)}\naccountId=${accountId}`);
      let result = _initResult();

      try {
         let url;
         let params = `?status=${status}&${_getFromToParams(dateRange)}`;

         if (this.isActualEndpoint)
            url = 'deletes/countInStatus' + params + (accountId ? `&account=${accountId}` : '');
         else
            url = 'deleteListInStatus' + params + '&_limit=1';

         _log(`in getListDeletesCount(): url=${url}`);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);

         if (this.isActualEndpoint)
            result.data = Array.isArray(response.data) ? response.data[0].count : response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);

         _log(`result.data=${result.data}`);
         } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getListDeletes(status, dateRange, accountId, page, limit) {
      _log(`in getListDeletes(): status=${status}\ndateRange=${JSON.stringify(dateRange)}\naccountId=${accountId}\npage=${page}\nlimit=${limit}`);
      let result = _initResult();

      try {
         let url;
         let params = `?status=${status}&${_getFromToParams(dateRange)}`;

         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            params += (accountId ? `&account=${accountId}` : '') + `&skip=${skip}&limit=${limit}`;
            url = 'deletes/listInStatus' + params;
         } else {
            params += `&_page=${page || 1}&_limit=${limit}`;
            url = 'deleteListInStatus' + params;
         }
        
         _log('in getListDeletes(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   /********************************/
   /*           DOWNLOADS          */
   /********************************/

   async getTotalDownloads(dateRange) {
      _log(`in getTotalDownloads(): dateRange=${JSON.stringify(dateRange)}`);
      let result = _initResult();

      try {
         let url = this.isActualEndpoint ? '/totalsByAccount' : 'downloadTotalsByAccount';
         url += `?${_getFromToParams(dateRange)}`;
         
         _log('in getTotalDownloads(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      // if (!this.isActualEndpoint)
      //    await sleep(10000);
      return result;
   }

   async getListDownloadsCount(dateRange, accountId) {
      _log(`in getListDownloadsCount(): dateRange=${JSON.stringify(dateRange)}\naccountId=${accountId}`);
      let result = _initResult();

      try {
         let url;
         let params = `?${_getFromToParams(dateRange)}`;

         if (this.isActualEndpoint)
            url = '/count' + params + (accountId ? `&account=${accountId}` : '');
         else
            url = 'downloadListInStatus' + params + '&_limit=1';

         _log(`in getListDownloadsCount(): url=${url}`);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);

         if (this.isActualEndpoint)
            result.data = Array.isArray(response.data) ? response.data[0].count : response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);

         _log(`result.data=${result.data}`);
         } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getListDownloads(dateRange, accountId, page, limit) {
      _log(`in getListDownloads(): dateRange=${JSON.stringify(dateRange)}\naccountId=${accountId}\npage=${page}\nlimit=${limit}`);
      let result = _initResult();

      try {
         let url;
         let params = `?${_getFromToParams(dateRange)}`;

         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            params += (accountId ? `&account=${accountId}` : '') + `&skip=${skip}&limit=${limit}`;
            url = '/list' + params;
         } else {
            params += `&_page=${page || 1}&_limit=${limit}`;
            url = 'downloadListInStatus' + params;
         }
        
         _log('in getListDownloads(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   /********************************/
   /*        REMOTE ACCESSES       */
   /********************************/

   async getTotalRemoteAccesses() {
      _log(`in getTotalRemoteAccesses()`);
      let result = _initResult();

      try {
         const url = this.isActualEndpoint ? 'remote-accesses/totalsByAccount' : 'remoteAccessTotalsByAccount';
         
         _log('in getTotalRemoteAccesses(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getListRemoteAccessesCount(accountId) {
      _log(`in getListRemoteAccessesCount()`);
      let result = _initResult();

      try {
         let url;
         if (this.isActualEndpoint)
            url = 'remote-accesses/count' + (accountId ? `?account=${accountId}` : '');
         else
            url = 'remoteAccessList?_limit=1';

         _log(`in getListRemoteAccessesCount(): url=${url}`);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);

         if (this.isActualEndpoint)
            result.data = Array.isArray(response.data) ? response.data[0].count : response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);

         _log(`result.data=${result.data}`);
         } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getListRemoteAccesses(accountId, page, limit) {
      _log(`in getListRemoteAccesses(): page=${page}\nlimit=${limit}`);
      let result = _initResult();

      try {
         let url;
         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            url = `remote-accesses/list?skip=${skip}&limit=${limit}` +
               (accountId ? `&account=${accountId}` : '');
         } else {
            url = `remoteAccessList?_page=${page || 1}&_limit=${limit}`;
         }
        
         _log(`in getListRemoteAccesses(): url=${url}`);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   /********************************/
   /*           CS-DDLs            */
   /********************************/

   async getTotalDDLsForCS() {
      _log(`in getTotalDDLsForCS()`);
      let result = _initResult();

      try {
         let url = this.isActualEndpoint ? '/ddls/totalsByAuthor' : 'ddlsForCsTotalByAuthor';
         
         _log('in getTotalDDLsForCS(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      // if (!this.isActualEndpoint)
      //    await sleep(10000);

      return result;
   }

   async getListDDLsForCS(author) {
      _log(`in getListDDLsForCS(): author=${author}`);
      let result = _initResult();

      try {
         const params = author ? `?author=${author}` : '';
         const url = (this.isActualEndpoint ? '/ddls/listByAuthor' : 'ddlsForCsListByAuthor') + params;
        
         _log('in getListDDLsForCS(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      // if (!this.isActualEndpoint)
      //    await sleep(10000);
      
      return result;
   }

   async getDDLForCS(id) {
      _log('getDDLForCS(): id=' + id);
      let result = _initResult();

      // if (!this.isActualEndpoint)
      //    await sleep(3000);

      try {
         const url = `${this.isActualEndpoint ? '/ddls/viewById' : 'ddlsForCsViewById'}/${id}`;
         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*           CS-RDLs            */
   /********************************/

   async getListRDLsForCS() {
      _log(`in getListRDLsForCS()`);
      let result = _initResult();

      try {
         const url = this.isActualEndpoint ? '/rdls/list' : 'rdlsForCsList';
        
         _log('in getListRDLsForCS(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      // if (!this.isActualEndpoint)
      //    await sleep(10000);
      
      return result;
   }

   async getRDLForCS(id) {
      _log('getRDLForCS(): id=' + id);
      let result = _initResult();

      // if (!this.isActualEndpoint)
      //    await sleep(3000);

      try {
         const url = `${this.isActualEndpoint ? '/rdls/viewById' : 'rdlsForCsViewById'}/${id}`;
         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*           SETTINGS           */
   /********************************/

   async getListSettings() {
      _log(`in getListSettings()`);
      let result = _initResult();

      try {
         const url = this.isActualEndpoint ? '/settings/list' : 'settingList';
        
         _log('in getListSettings(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      // if (!this.isActualEndpoint)
      //    await sleep(10000);
      
      return result;
   }

   async getSetting(id) {
      _log('getSetting(): id=' + id);
      let result = _initResult();

      // if (!this.isActualEndpoint)
      //    await sleep(3000);

      try {
         const url = `${this.isActualEndpoint ? '/settings/viewById' : 'settingViewById'}/${id}`;
         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*           API CALLS          */
   /********************************/

   async getApiCallsByAccount(fromTo, source, service) {
      _log(`in getApiCallsByAccount(): ${fromTo}\nsource=${source}, service=${service}`);
      let result = _initResult();

      try {
         let url = (this.isActualEndpoint ? '/logs/callsByAccount' : 'apiCallsByAccount') +
            `?${fromTo}${source ? '&source=' + source : ''}${service ? '&service=' + service : ''}`;

         _log('in getApiCallsByAccount(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getApiCallsByService(fromTo, source, service, accountId) {
      _log(`in getApiCallsByService(): ${fromTo}\nsource=${source}, service=${service}, accountId=${accountId}`);
      let result = _initResult();

      try {
         let url = (this.isActualEndpoint ? '/logs/callsByService' : 'apiCallsByService') +
            `?${fromTo}${source ? '&source=' + source : ''}${service ? '&service=' + service : ''}${accountId ? '&aid=' + accountId : ''}`;

         _log('in getApiCallsByService(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getApiCallsByHttpMethod(fromTo, source, service, accountId) {
      _log(`in getApiCallsByHttpMethod(): ${fromTo}\nsource=${source}, service=${service}, accountId=${accountId}`);
      let result = _initResult();

      try {
         let url = (this.isActualEndpoint ? '/logs/callsByHttpMethod' : 'apiCallsByHttpMethod') +
            `?${fromTo}${source ? '&source=' + source : ''}${service ? '&service=' + service : ''}${accountId ? '&aid=' + accountId : ''}`;

         _log('in getApiCallsByHttpMethod(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getApiCallsByStatus(fromTo, source, service, accountId) {
      _log(`in getApiCallsByStatus(): ${fromTo}\nsource=${source}, service=${service}, accountId=${accountId}`);
      let result = _initResult();

      try {
         let url = (this.isActualEndpoint ? '/logs/callsByStatus' : 'apiCallsByStatus') +
            `?${fromTo}${source ? '&source=' + source : ''}${service ? '&service=' + service : ''}${accountId ? '&aid=' + accountId : ''}`;

         _log('in getApiCallsByStatus(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getApiCallsInStatus(fromTo, source, service, status, accountId, page, limit) {
      _log(`in getApiCallsByStatus(): ${fromTo}\nsource=${source}, service=${service}, status=${status}, accountId=${accountId}, page=${page}, limit=${limit}`);
      let result = _initResult();

      try {
         const params = `${fromTo}${source ? '&source=' + source : ''}${service ? '&service=' + service : ''}&status=${status}${accountId ? '&aid=' + accountId : ''}`;
         let url;
         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            url = `/logs/callsInStatus?${params}&skip=${skip}&limit=${limit}`;
         } else {
            url = `apiCallsInStatus?${params}&_page=${page || 1}&_limit=${limit}`;
         }
   
         _log('in getApiCallsByStatus(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getApiCallsByResource(resource, fromTo, source, service, accountId) {
      _log(`in getApiCallsByResource(): resource=${resource}\n${fromTo}\nsource=${source}, service=${service}, accountId=${accountId}`);
      let result = _initResult();

      try {
         let url = (this.isActualEndpoint ? '/logs/callsBy' : 'apiCallsBy') +
            `${resource}Resource?${fromTo}${source ? '&source=' + source : ''}${service ? '&service=' + service : ''}${accountId ? '&aid=' + accountId : ''}`;

         _log('in getApiCallsByResource(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getApiCallsWithSuspiciousActivityCount(fromTo) {
      _log(`in getApiCallsWithSuspiciousActivityCount(): ${fromTo}`);
      let result = _initResult();

      try {
         let url;
         const params = `?${fromTo}`;

         if (this.isActualEndpoint)
            url = '/logs/callsWithSuspiciousActivityCount' + params;
         else
            url = 'apiCallsWithSuspiciousActivity' + params + '&_limit=1';

         _log(`in getApiCallsWithSuspiciousActivityCount(): url=${url}`);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);

         if (this.isActualEndpoint)
            result.data = Array.isArray(response.data) ? response.data[0].calls : response.data.calls;
         else
            result.data = parseInt(response.headers['x-total-count']);

            _log(`result.data=${result.data}`);
      } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getApiCallsWithSuspiciousActivity(fromTo, page, limit) {
      _log(`in getApiCallsWithSuspiciousActivity(): ${fromTo}\npage=${page}\nlimit=${limit}`);
      let result = _initResult();

      try {
         let url;

         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            url = `/logs/callsWithSuspiciousActivity?${fromTo}&skip=${skip}&limit=${limit}`;
         } else
            url = `apiCallsWithSuspiciousActivity?${fromTo}&_page=${page || 1}&_limit=${limit}`;

         _log(`in getApiCallsWithSuspiciousActivity: url=${url}`);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);

         result.data = response.data;
      } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getApiCallsWithSuspiciousActivityByIp(fromTo) {
      _log(`in getApiCallsWithSuspiciousActivityByIp(): ${fromTo}`);
      let result = _initResult();

      try {
         const url = (this.isActualEndpoint ? '/logs/callsWithSuspiciousActivityByIP' : 'apiCallsWithSuspiciousActivityByIP') +
            `?${fromTo}`;

         _log(`in getApiCallsWithSuspiciousActivityByIp: url=${url}`);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getApiCallsByUser(fromTo, service, accountId) {
      _log(`in getApiCallsByUser(): ${fromTo}\nservice=${service}, accountId=${accountId}`);
      let result = _initResult();

      try {
         let url = (this.isActualEndpoint ? '/logs/callsByUser' : 'apiCallsByUser') +
            `?${fromTo}${service ? '&service=' + service : ''}${accountId ? '&aid=' + accountId : ''}`;

         _log('in getApiCallsByUser(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getApiCallsWithMostDelayCount(fromTo, source, service, accountId) {
      _log(`in getApiCallsWithMostDelayCount(): ${fromTo}\nsource=${source}, service=${service}, accountId=${accountId}`);
      let result = _initResult();

      try {
         let url;
         const params = `?${fromTo}${source ? '&source=' + source : ''}${service ? '&service=' + service : ''}${accountId ? '&aid=' + accountId : ''}`;

         if (this.isActualEndpoint)
            url = '/logs/callsWithMostDelayCount' + params;
         else
            url = 'apiCallsWithMostDelay' + params + '&_limit=1';

         _log(`in getApiCallsWithMostDelayCount(): url=${url}`);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);

         if (this.isActualEndpoint)
            result.data = Array.isArray(response.data) ? response.data[0].calls : response.data.calls;
         else
            result.data = parseInt(response.headers['x-total-count']);

            _log(`result.data=${result.data}`);
      } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getApiCallsWithMostDelay(fromTo, source, service, accountId, page, limit) {
      _log(`in getApiCallsWithMostDelay(): ${fromTo}\nsource=${source}, service=${service}, accountId=${accountId}, page=${page}, limit=${limit}`);
      let result = _initResult();

      try {
         const params = `${fromTo}${source ? '&source=' + source : ''}${service ? '&service=' + service : ''}${accountId ? '&aid=' + accountId : ''}`;
         let url;
         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            url = `/logs/callsWithMostDelay?${params}&skip=${skip}&limit=${limit}`;
         } else {
            url = `apiCallsWithMostDelay?${params}&_page=${page || 1}&_limit=${limit}`;
         }
   
         _log('in getApiCallsWithMostDelay(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   /********************************/
   /*           Accounts           */
   /********************************/

   async getIdentityAccountsCount(aggregate) {
      _log(`in getIdentityAccountsCount(): aggregate=${JSON.stringify(aggregate)}`);
      let result = _initResult();

      try {
         const url = this.isActualEndpoint ? `/accounts?aggregate=${JSON.stringify(aggregate)}` : '/identityAccounts?_limit=1';
         _log('in getIdentityAccountsCount(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)

            result.data = response.data.length ? response.data[0].count : 0;
         else
            result.data = parseInt(response.headers['x-total-count']);
      } catch (error) {
         result = _parseError(error, 0);
      }
      
      return result;
   }

   async getIdentityAccounts(aggregate) {
      _log(`in getIdentityAccounts(): aggregate=${JSON.stringify(aggregate)}`);
      let result = _initResult();

      try {
         let url;
         if (this.isActualEndpoint)
            url = `/accounts?aggregate=${JSON.stringify(aggregate)}`;
         else {
            const limit = aggregate.filter(a => a.$limit)[0].$limit;
            const skip = aggregate.filter(a => a.$skip >= 0)[0].$skip / limit + 1;
            url = `/identityAccounts?_page=${skip}&_limit=${limit}`;
         }
         _log('in getListAccounts(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }
}