<template>
<!-- HISTORY:
   08/27/20(B0.1): Applied some changes along with Aref + Added 'keep menu expanded' + Removed main card's width.
-->
<v-container fluid class="px-3 py-3">
   <v-card>

      <v-toolbar flat color="white">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">settings</v-icon>
            <span>Settings</span>
         </h1>
      </v-toolbar>

      <v-card-text>
         <v-expansion-panels multiple focusable               
            v-model="panels"
         >
            <v-expansion-panel>
               <v-expansion-panel-header>General</v-expansion-panel-header>
               <v-expansion-panel-content>
                  <v-row dense>
                     <v-col cols="4">
                        <v-switch
                           class="mx-2"
                           v-model="settings.keepLastPageViewed"                                 
                           @change="settingsChanged"
                        >
                           <template v-slot:label>
                              <div>Keep Last Page Viewed: <strong>{{settings.keepLastPageViewed}}</strong></div>
                           </template>
                        </v-switch>
                     </v-col>
                     <!-- <v-col cols="4">
                        <v-switch disabled
                           class="mx-2"
                           v-model="keepMenuExpanded"                                 
                           @change="settingsChanged"
                        >
                           <template v-slot:label>
                              <div>Keep Menu Expanded: <strong>{{keepMenuExpanded}}</strong></div>
                           </template>
                        </v-switch>
                     </v-col> -->
                  </v-row>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
               <v-expansion-panel-header>Tables</v-expansion-panel-header>
               <v-expansion-panel-content>
                  <v-row dense>
                     <v-col cols="4">
                        <v-switch
                           class="mx-2"
                           v-model="settings.areTablesDensed"
                           @change="settingsChanged"
                        >
                           <template v-slot:label>
                              <div>Descrease the height of rows in tables: <strong>{{settings.areTablesDensed}}</strong></div>
                           </template>
                        </v-switch>
                     </v-col>
                     <v-col cols="4">
                        <v-switch
                           class="mx-2"
                           v-model="settings.singleExpand"
                           @change="settingsChanged"
                        >
                           <template v-slot:label>
                              <div>Make tables single-expand: <strong>{{settings.singleExpand}}</strong></div>
                           </template>
                        </v-switch>
                     </v-col>
                     <v-col cols="4">
                        <v-select
                           label="Rows Per Page"
                           v-model="settings.rowsPerPage" 
                           :items="dropdown_rowsPerPage"
                           @change="settingsChanged"
                        ></v-select>
                     </v-col>
                  </v-row>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </v-card-text>

      <v-card-actions class="pt-0">
         <div class="flex-grow-1"></div>
         <v-btn text
            color="blue darken-1"
            :disabled="isSaveDisabled"
            @click="saveSettings"
         >Save</v-btn>
      </v-card-actions>

   </v-card>
</v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import { IamSettings } from '../models/iam-settings.js';


export default Vue.extend({
   props: {
      debug: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         panels: [0, 1],
         settings: new IamSettings(this.debug),
         dropdown_rowsPerPage: [ 
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '15', value: 15 },
            { text: 'All', value: 'All' }
         ],
         isSaveDisabled: true
      }
   },

   computed: {
   },

   methods: {
      settingsChanged() {
         this.isSaveDisabled = false;
      },
      saveSettings() {
         this.settings.save();
         this.isSaveDisabled = true;
      }
   },

   mounted() {
   },
   
   beforeRouteLeave (to, from, next) {
      if (this.isSaveDisabled || window.confirm('Do you really want to leave? you have unsaved changes!'))
         next();
      else
         next(false);
      // const answer = 
      // if (answer) {
      //    next()
      // } else {
      //    next(false)
      // }
   }
})
</script>